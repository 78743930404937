import { Chip, useTheme } from "@mui/material";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import { createStyles, joinSx } from "src/main/utils";

interface TaskExpiryChipProps extends React.PropsWithChildren {
  expiry?: Moment | string;
}

const TaskExpiryChip: React.FC<TaskExpiryChipProps> = (props) => {
  const { expiry } = props;
  const { palette } = useTheme();

  const timeLeft = useMemo(() => {
    if (!expiry || !moment(expiry).isValid()) return null;

    const now = moment();
    const end = moment(expiry);

    if(end.isBefore(now)) return null;

    const duration = moment.duration(end.diff(now));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let timeLeft = "";

    if (days > 0) timeLeft += (days + "d");

    timeLeft += (" " + hours + "h");
    timeLeft += (" " + minutes + "m");

    return timeLeft;

  }, [expiry])


  if (!timeLeft) return null;


  return (
    <Chip
      variant="filled"
      label={timeLeft}
      sx={joinSx(styles.root, {
        '& .MuiChip-label': {
          pr: 1,
          color: palette.text.secondary,
        }
      })}
    />
  );
};

const styles = createStyles({
  root: {
    '&.MuiChip-root': {
      backgroundColor: "inherit",
    },
  }
})

export default TaskExpiryChip;
