import { IconButton, Box } from "@mui/material";
import BigNumber from "bignumber.js";
import React, { useMemo, useState, useCallback } from "react";
import { accountApi } from "src/main/api";
import { ListPayoutItemResponse } from "src/main/api/account/Payout";
import {
  ListingComp,
  Page,
  Table,
  MonoLabel,
  LoadingButton,
  DateDisplay,
  WorkerNameDisplay,
} from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { Meta } from "src/main/types";
import { exportList, Paths, truncate } from "src/main/utils";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

interface PayoutItemsPageProps extends React.PropsWithChildren {}

const PAYOUT_ITEMS_HEADER = [
  { value: "ID" },
  { value: "Worker" },
  { value: "Amount" },
  { value: "GCash Account" },
  { value: "Type" },
  { value: "Status" },
  { value: "Date" },
  { value: "" },
];

const PayoutItemsPage: React.FC<PayoutItemsPageProps> = () => {
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 });
  const result = accountApi.useListPayoutItemQuery({ meta });
  const payoutItems: ListPayoutItemResponse | undefined = useMemo(
    () => result.data ?? undefined,
    [result.data]
  );
  const persistMeta = useMemo(
    () => ({ ...meta, ...result.data?.meta }),
    [result.data, meta]
  );

  const [listPayoutItem, { isLoading: exportListLoading }] =
    accountApi.useLazyListPayoutItemQuery();

  const onExport = useCallback(async () => {
    const exportResult = await listPayoutItem({
      meta: { ...meta, limit: 1000000 },
    });
    exportList(
      exportResult.data?.entries ?? [],
      [
        { key: "id" },
        { key: "amount", type: "amount" },
        { key: "currency" },
        { key: "amountInUsd", type: "amount" },
        { key: "worker.gCashAccount", type: "forceString" },
        { key: "message" },
        { key: "notes" },
        { key: "referenceNo" },
        { key: "status" },
        { key: "type" },
        { key: "workerId" },
      ],
      [
        "ID",
        "Amount",
        "Currency",
        "Amount (USD)",
        "GCash Account",
        "Message",
        "Notes",
        "Ref No.",
        "Status",
        "Type",
        "Worker ID",
      ],
      `PAYOUTS_RECORDS_${moment().format("YYYY-MM-DD")}.csv`
    );
  }, [listPayoutItem, meta]);

  return (
    <Page>
      <Page.TopSection title="Payouts">
        <LoadingButton
          onClick={() => onExport()}
          loading={exportListLoading}
          variant="contained"
        >
          Export
        </LoadingButton>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading}
        title="Payouts"
        updateList={(newMeta) => {
          setMeta(newMeta);
        }}
        meta={persistMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
          <ListingComp.DateFilter dateSearchKey="createdAt" />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          <Table>
            <Table.Head headers={PAYOUT_ITEMS_HEADER} />
            <Table.Body>
              {payoutItems?.entries.map((payoutItem) => (
                <Table.Row key={payoutItem.id}>
                  <Table.Cell>{truncate(payoutItem.id, 8)}</Table.Cell>
                  <Table.Cell>
                    <WorkerNameDisplay person={payoutItem.worker?.person} />
                  </Table.Cell>
                  <Table.Cell>
                    <Box display="flex" flexDirection="column">
                      <MonoLabel sx={{ py: 0, my: 0 }}>
                        {BigNumber(payoutItem.amount).toFixed(2)}{" "}
                        {payoutItem.currency}
                      </MonoLabel>
                      <MonoLabel sx={{ fontSize: 12, py: 0, my: -1 }}>
                        {BigNumber(payoutItem.amountInUsd).toFixed(2)} USD
                      </MonoLabel>
                    </Box>
                  </Table.Cell>
                  <Table.Cell>{payoutItem.worker?.gCashAccount}</Table.Cell>
                  <Table.Cell>
                    <MonoLabel>{payoutItem.type?.toUpperCase()}</MonoLabel>
                  </Table.Cell>
                  <Table.Cell>
                    <StatusChip status={payoutItem.status} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateDisplay stacking={true} value={payoutItem.createdAt} />
                  </Table.Cell>
                  <Table.Cell align="right">
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.PayoutItemDetail.replace(
                        ":payoutItemId",
                        payoutItem.id
                      )}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default PayoutItemsPage;
