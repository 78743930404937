import { CloseOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogProps, IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useDispatch } from 'react-redux';
import { Typography } from 'src/main/components';
import preference from 'src/main/store/preference';
import { LANGUAGE_OPTIONS, commonStyles } from "src/main/utils";
export interface SimpleDialogProps extends Omit<DialogProps, "open"> {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const SelectLanguage: React.FC<SimpleDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, selectedValue, ...dialogProps } = props;

  const handleUpdateLanguage = (value: string) => {
    onClose(value);
    dispatch(preference.slice.actions.setLanguage(value))
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog fullWidth {...dialogProps} onClose={handleClose} open={open}>
      <IconButton sx={commonStyles.dialogCloseButton} onClick={handleClose}>
        <CloseOutlined />
      </IconButton>
      <DialogContent>
        <Typography formatId="topBar.choose_a_language" variant="h6">Choose a language</Typography>
        <List sx={{ pt: 2 }}>
          {LANGUAGE_OPTIONS.map((opt, index) => (
            <ListItem disableGutters sx={{ py: 0 }} key={index}>
              <ListItemButton sx={{ px: 1 }} onClick={() => handleUpdateLanguage(opt.value)} key={index}>
                {opt.icon}
                <ListItemText sx={{ px: 1 }} primary={(<Typography variant="body2">{opt.label}</Typography>)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectLanguage;