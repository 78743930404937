import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { CheckCircleIcon, ClipboardViewIcon } from "src/assets";

interface TaskSubmittedCompProps extends React.PropsWithChildren {
  children: React.ReactNode | undefined;
  displayWrapper?: boolean;
}

const TaskSubmittedComp: React.FC<TaskSubmittedCompProps> = (props) => {
  const { children, displayWrapper = true } = props;
  const { palette } = useTheme();
  const [displayContent, setDisplayContent] = useState(displayWrapper);

  if (!displayContent) {
    return (
      <>
        {children}
      </>
    )
  }


  return (
    <Box p="10px">
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        background: palette.success.gradient,
        padding: [4, 2],
        borderRadius: "8px",
        textAlign: "center"
      }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h5" alignItems="center">Task Submitted&nbsp;</Typography><CheckCircleIcon color="success" />
        </Box>
        <Typography color="text.secondary" variant="caption">Your submitted task is under review. Your earnings will be updated in your wallet once the task has been approved.</Typography>

        <Button
          variant="outlined"
          color="secondary"
          sx={{ mt: 1 }}
          onClick={() => setDisplayContent(false)}
        >
          <ClipboardViewIcon fontSize="small" sx={{ mt: -0.5 }} /> &nbsp;Review My Work
        </Button>
      </Box>
    </Box>
  );
};

export default TaskSubmittedComp;

