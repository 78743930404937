import { Box, Card, CardProps, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import React from "react";
import { EmptyState, MonoLabel } from "src/main/components";
import { WalletModel } from "src/main/types";

interface WalletInfoCardProps extends Omit<CardProps, "children"> {
  wallet?: WalletModel;
}

const WalletInfoCard: React.FC<WalletInfoCardProps> = (props) => {
  const { wallet, ...rest } = props;

  if (!wallet) return <EmptyState message="No wallet details" />

  return (
    <Card  {...rest}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column'
        }}
      >
        <Typography color="textSecondary" variant="body2" gutterBottom><MonoLabel>Balance</MonoLabel></Typography>
        <Typography variant="h4" textAlign="center" gutterBottom> {BigNumber(wallet.balance).toFixed(2)} {wallet.currency} </Typography>
      </Box>
    </Card>
  );
};

export default WalletInfoCard;
