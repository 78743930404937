
import { BuilderProp, require200Status } from "src/main/api/account";
import { AccountVariableModel, AccountVariableType, Meta, QueryResult } from "src/main/types";
import { SimpleMap } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";

export interface MetaRequest {
  meta?: Meta;
}

export interface GetAccountVariableResponse {
  model: AccountVariableModel
}

export interface GetAccountVariableRequest {
  accountVariableId: string
}

export interface ListAccountVariableResponse {
  entries: AccountVariableModel[];
  meta: Meta;
}

export interface AccountVariableResponse {
  model: AccountVariableModel
}



export interface DeleteAccountVariableResponse {
}

export const getAccountVariable = (builder: BuilderProp) => {
  return builder.query<AccountVariableResponse, GetAccountVariableRequest>({
    providesTags: ["variable"],
    query: ({ accountVariableId }) => ({
      url: `/account/variable/${accountVariableId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<AccountVariableResponse>) => {
      return response.result;
    },
  })
}

export const listAccountVariable = (builder: BuilderProp) => {
  return builder.query<ListAccountVariableResponse, MetaRequest | undefined>({
    providesTags: ["variable"],
    query: ({ meta } = {}) => ({
      url: `/account/variable/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListAccountVariableResponse>) => {
      return response.result;
    },
  })
}

export interface CreateAccountVariableRequest {
  key: string;
  type: AccountVariableType;
  content: SimpleMap<string | number | SimpleMap<string>[] | boolean | SimpleMap<string | number>>;
}

export const createAccountVariable = (builder: BuilderProp) => {
  return builder.mutation<AccountVariableResponse, CreateAccountVariableRequest>({
    invalidatesTags: ["variable"],
    query: (data) => ({
      url: `/account/variable/create`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<AccountVariableResponse>) => {
      return response.result;
    },
  })
}

export interface UpdateAccountVariableRequest {
  data: {
    key?: string;
    type?: AccountVariableType;
    content: SimpleMap<string | number | SimpleMap<string>[] | boolean | SimpleMap<string | number>>;
  },
  accountVariableId: string;
}

export interface DeleteAccountVariableRequest {
  accountVariableId: string;
}

export const updateAccountVariable = (builder: BuilderProp) => {
  return builder.mutation<AccountVariableResponse, UpdateAccountVariableRequest>({
    invalidatesTags: ["variable"],
    query: ({ accountVariableId, data }) => ({
      url: `/account/variable/${accountVariableId}/update`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<AccountVariableResponse>) => {
      return response.result;
    },
  })
}

export const deleteAccountVariable = (builder: BuilderProp) => {
  return builder.mutation<DeleteAccountVariableResponse, DeleteAccountVariableRequest>({
    invalidatesTags: ["variable"],
    query: ({ accountVariableId }) => ({
      url: `/account/variable/${accountVariableId}/delete`,
      method: "DELETE",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<DeleteAccountVariableResponse>) => {
      return response.result;
    },
  })
}
