import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { AuthLayout } from "src/main/components";
import { RootState } from "src/main/store";
import { SelfEntityModel } from "src/main/types";
import { makeRelativePath, Paths } from "src/main/utils";
import AdminInvite from "./AdminInvite";
import LoginPage from "./LoginPage";
import PasswordRequest from "./PasswordRequest";
import PasswordReset from "./PasswordReset";
import WorkerOnboard from "./WorkerOnboard";

interface MainProps extends React.PropsWithChildren {}
const Main: React.FC<MainProps> = (props) => {
  const navigate = useNavigate();
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>(
    (state) => state.auth.profile
  );

  useEffect(() => {
    if (profile?.person?.worker) {
      navigate("/portal");
    } else if (profile) {
      navigate("/general");
    }
  }, [navigate, profile]);

  const p = makeRelativePath(Paths.Auth.Index);

  return (
    <AuthLayout.Content>
      <Routes>
        <Route path={p(Paths.Auth.Login)} element={<LoginPage />} />
        <Route path={p(Paths.Auth.Onboard)} element={<WorkerOnboard />} />
        <Route path={p(Paths.Auth.Invite)} element={<AdminInvite />} />
        <Route
          path={p(Paths.Auth.RequestPassword)}
          element={<PasswordRequest />}
        />
        <Route path={p(Paths.Auth.ResetPassword)} element={<PasswordReset />} />
        <Route path="*" element={<Navigate to={Paths.Auth.Login} replace />} />
      </Routes>
    </AuthLayout.Content>
  );
};

export default Main;
