import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, CircularProgress, IconButton } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { authApi } from "src/main/api";
import { ListingComp, DateDisplay, Table, DetailCard } from "src/main/components";
import { Meta, SelfEntityModel } from "src/main/types";
import { RootState } from "src/main/store";
import { useSelector } from 'react-redux';
import StatusChip from "src/main/components/StatusChip";
import { useAsyncTask } from 'src/main/hooks';
import { handleApiResponse, createStyles } from "src/main/utils";
import { toast } from 'react-hot-toast';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const TaskHeaders = [
  { value: "Account" },
  { value: "Date" },
  { value: "Invited by" },
  { value: "Status" },
  { value: "" },
]
const styles = createStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})
interface InviteNotificationDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;

}
const InviteNotificationDialog: React.FC<InviteNotificationDialogProps> = (props) => {
  const { open = false, onClose } = props;
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 1000, offset: 0 })
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>((state) => state.auth.profile);
  const result = authApi.usePendingNotificationQuery({ meta, ...profile });
  const pendingInvite = useMemo(() => result.data ?? undefined, [result.data]);
  const persisMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])
  const [runAcceptPending, loading] = useAsyncTask("self/account/accept");
  const [acceptPendingMutation] = authApi.useAcceptPendingNotificationMutation();

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  const acceptPending = (acctId) => {
    onAcceptPending(acctId);
  }

  const onAcceptPending = useCallback(async (acctId: string) => {
    runAcceptPending(async () => {
      const acceptResult = await acceptPendingMutation({ accountInviteId: acctId });

      const { error } = handleApiResponse(acceptResult);

      if (error?.data?.error) {
        throw new Error(error.data?.error?.message)
      }

      toast.success("Invitation accepted successfully!");
    })
  }, [runAcceptPending, acceptPendingMutation]);

  return (
    <Dialog open={open} onClose={_onClose} fullWidth>
      <DialogContent sx={{ p: 0 }}>
        <DetailCard.Header
          sx={{ pb: 0 }}
          header="User Account Invitation"
        />
        <DetailCard.Divider />
        <ListingComp hidePagination
          title="Pending List"
          loading={result.isLoading}
          updateList={(newMeta) => setMeta(newMeta)}
          meta={persisMeta}
          sx={{ m: 0 }}
        >
          <ListingComp.Content>
            <Table>
              <Table.Head headers={TaskHeaders} />
              <Table.Body>
                {pendingInvite?.entries?.map((invite) => (
                  <Table.Row>
                    <Table.Cell value={invite.account.name} />
                    <Table.Cell value={<DateDisplay value={invite.createdAt} />} />
                    <Table.Cell value={invite.inviter?.firstname} />
                    <Table.Cell>
                      <StatusChip status={invite.status} />
                    </Table.Cell>
                    <Table.Cell align="right">
                      {(!loading && invite.status === "pending") && (
                        <IconButton onClick={() => { acceptPending(invite.id) }} disabled={loading}>
                          <CheckCircleIcon color="warning" />
                        </IconButton>
                      )}
                      {loading &&
                        <Box sx={styles.loaderContainer}>
                          <CircularProgress thickness={5} size={20} sx={{ mr: .5 }} />
                        </Box>
                      }
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </ListingComp.Content>
        </ListingComp>
      </DialogContent>
      <DetailCard.Divider />
      <DialogActions>
        <Button
          variant="contained"
          sx={{ mr: 2, flexShrink: 0 }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InviteNotificationDialog;
