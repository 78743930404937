import { fork, put } from "@redux-saga/core/effects";
import { publicApi } from "src/main/api";
import { StatusResponse } from "src/main/api/public/endpoints";
import { QueryResult } from "src/main/types";
import { execQuery } from "src/main/utils/saga";
import layoutSlice from "./slice";

function* init() {
  const response: QueryResult<StatusResponse> = yield* execQuery(publicApi.endpoints.status.initiate(undefined));
  yield put(layoutSlice.actions.updateApiHealth(response?.result?.status === "ok"));
}

function* saga() {
  yield fork(init)
}

export default saga;
