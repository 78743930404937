import { Box, BoxProps, Divider, LinearProgress, LinearProgressProps } from "@mui/material";
import React, { useMemo } from "react";
import { TaskObjectiveModel, TaskProofModel } from "src/main/types";
import { CashBillIcon } from "src/assets";
import { createStyles, joinSx } from "src/main/utils";
import { TaskObjective, Typography } from "src/main/components";


export interface GoalTaskObjectModel {
  objective: TaskObjectiveModel;
  proof?: TaskProofModel | undefined
}
interface TaskGoalProps extends BoxProps {
  goals: GoalTaskObjectModel[];
  pay?: string;
  onClickEvt?: (obj: TaskObjectiveModel) => void;
  editable?: boolean;
  status?: string;
  isExpired?: boolean;
}
const TaskGoal: React.FC<TaskGoalProps> = (props) => {
  const { goals, pay, status = "in-progress", editable = false, onClickEvt, isExpired } = props;

  const goalPct = useMemo(() => {
    let startedGoalCount = 0;
    goals.forEach(gProof => {
      if (gProof.proof) startedGoalCount += 1;
    })

    return (startedGoalCount * 100) / goals.length;
  }, [goals]);


  const colors = useMemo<{
    perColor: string;
    progressColor: LinearProgressProps["color"];
    statusProgressStyles: any;
  }>(() => {
    let perColor = ["approved", "submitted", "in-progress"].includes(status)
      ? "secondary.main"
      : status === "rejected"
      ? "warning.main"
      : "error.main";
    let progressColor = ["approved", "submitted", "in-progress"].includes(
      status
    )
      ? "secondary"
      : status === "rejected"
      ? "warning"
      : "error";

    let statusProgressStyles = [
      "approved",
      "submitted",
      "in-progress",
    ].includes(status)
      ? styles.progressBarStripedInProgress
      : status === "rejected"
      ? styles.progressBarStripedRejected
      : styles.progressBarStripedTerminated;

    if (isExpired) {
      perColor = "error.main";
      progressColor = "error";
      statusProgressStyles = styles.progressBarStripedTerminated;
    }

    return {
      perColor,
      progressColor: progressColor as LinearProgressProps["color"],
      statusProgressStyles,
    };
  }, [status, isExpired]);

  return (
    <>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Typography formatId="taskGoalComponent.task_goals" variant="h6">Task Goals</Typography>
          {goals.length > 0 && <>
            <Typography formatId="taskGoalComponent.progress" ml={1} mr={1}>Progress: </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
              color={colors.perColor}
            >
              {Math.ceil(goalPct)}%
            </Typography>
          </>}
        </Box>
        {
          (goals.length > 0) &&
          <Box>
            <LinearProgress
              variant="determinate"
              value={goalPct < 2 ? 2 : goalPct}
              color={colors.progressColor}
              sx={joinSx(styles.progressBarStriped, colors.statusProgressStyles)}
            />
            <Box display="flex" alignItems="center" justifyContent="space-between" my={1}>
              <Typography formatId="taskGoalComponent.complete_all_goals_earn_reward" variant="caption">Complete all goals to earn your reward</Typography>
              <Box display="flex" alignItems="center">
                <CashBillIcon fontSize="small" />
                <Typography variant="subtitle2" sx={{ fontWeight: 700, pl: .5 }}>+{pay}</Typography>
              </Box>
            </Box>
          </Box>
        }
        {goals.length === 0 && <Typography formatId="taskGoalComponent.task_has_no_objective_goal" >This task has no objective goal.</Typography>}
      </Box>

      <Divider />
      <Box sx={styles.verticalTimeline}>
        {goals.map(g =>
        (
          <Box key={g.objective.id} sx={{ p: 2, pl: 0, zIndex: 1, position: "relative" }}>
            <TaskObjective editable={editable} objectiveModel={g} sx={{ p: 0, alignItems: "start" }} circleIconFlag={true} onClick={onClickEvt} />
          </Box>
        )
        )}

      </Box>
    </>
  );
}

const styles = createStyles({
  verticalTimeline: {
    position: "relative",
    "::after": {
      content: "''",
      position: "absolute",
      width: "1px",
      backgroundColor: "neutral.200",
      top: 20,
      bottom: 45,
      ml: 3
    }
  },
  taskGoalBox: {
    p: 2, pl: 6,
    position: "relative"
  },
  progressBarStriped: {
    height: "8px",
    borderRadius: "8px",
  },
  progressBarStripedInProgress: {
    '& .MuiLinearProgress-bar': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      backgroundImage: 'repeating-linear-gradient(-45deg, #46CF96, #46CF96 5px, #29A688 0px, #29A688 10px)',
    },
  },
  progressBarStripedRejected: {
    '& .MuiLinearProgress-bar': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      backgroundImage: 'repeating-linear-gradient(-45deg, #D5A451, #D5A451 5px, #C37E07 0px, #C37E07 10px)',
    },
  },
  progressBarStripedTerminated: {
    '& .MuiLinearProgress-bar': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      backgroundImage: 'repeating-linear-gradient(-45deg, #E5787A, #E5787A 5px, #DA3E41 0px, #DA3E41 10px)',
    },
  },
});

export default TaskGoal;
