import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { makeRelativePath, Paths } from "src/main/utils";
import { MainDashboardLayout } from "../../components";
import { AccountSetting } from "./Account";
import { InvitesPage } from "./Invites";
import { OverViewPage } from "./Overview";
import {
  UserCreatePage,
  UserDetailPage,
  UserInvitePage,
  UserPage,
} from "./Users";

interface MainProps extends React.PropsWithChildren {}

const Main: React.FC<MainProps> = () => {
  const p = makeRelativePath(Paths.Overview.Index);
  return (
    <MainDashboardLayout>
      <Routes>
        <Route path={p(Paths.Overview.Overview)} element={<OverViewPage />} />

        <Route
          path={p(Paths.Overview.InviteUser)}
          element={<UserInvitePage />}
        />
        <Route path={p(Paths.Overview.Invites)} element={<InvitesPage />} />
        <Route path={p(Paths.Overview.Users)} element={<UserPage />} />
        <Route
          path={p(Paths.Overview.AccountSetting)}
          element={<AccountSetting />}
        />
        <Route
          path={p(Paths.Overview.UserDetail)}
          element={<UserDetailPage />}
        />
        <Route
          path={p(Paths.Overview.CreateUser)}
          element={<UserCreatePage />}
        />
        <Route
          path="*"
          element={<Navigate to={Paths.Overview.Overview} replace />}
        />
      </Routes>
    </MainDashboardLayout>
  );
};

export default Main;
