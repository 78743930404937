import React from "react";
import { ManagementLayout, Page } from "src/main/components";

interface PlatformAdministratorPageProps extends React.PropsWithChildren {

}

const PlatformAdministratorPage: React.FC<PlatformAdministratorPageProps> = (props) => {
  return (
    <Page>
      <ManagementLayout.WorkInProgress />
    </Page>
  );
};

export default PlatformAdministratorPage;
