import { GbIcon, BrIcon } from "src/assets";
import English from 'src/lang/en.json';
import Portugese from 'src/lang/pt.json';

export const browserLanguageSetting = navigator.language.split(/[-_]/)[0];

type MessageTranslations = {
  [key: string]: string;
};

export const messages: Record<string, MessageTranslations> = {
  en: English,
  pt: Portugese,
};

export const getLanguageLabel = (value: string) => {
  const languageOption = LANGUAGE_OPTIONS.find(option => option.value === value);
  return { label: languageOption ? languageOption.label : '', icon: languageOption ? languageOption.icon : '' }
};

export const packageFormatMessage = (id: string, defaultMessage: string) => {
  return { id: id, defaultMessage: defaultMessage }
}

export const LANGUAGE_OPTIONS = [
  { value: "en", label: "English", icon: <GbIcon fontSize='small' /> },
  { value: "pt", label: "Português", icon: <BrIcon fontSize='small' /> },
]