import { Box, Chip, SxProps, useTheme } from "@mui/material";
import { GroupPeopleIcon } from "src/assets";
import { commonStyles, joinSx, IntlFormatter } from "src/main/utils";

interface TaskSubmissionSlotProps {
  submissionSlot: string;
  sx?: SxProps;
}

const TaskSubmissionSlot: React.FC<TaskSubmissionSlotProps> = (props) => {
  const { submissionSlot, sx } = props;

  const slots: string[] = submissionSlot.split("/");
  const theme = useTheme();

  return (
    <Chip
      sx={joinSx(commonStyles.borderlessChip, sx)}
      icon={(<GroupPeopleIcon fontSize="small" style={{ color: theme.palette.neutral?.[500] }} />)}
      label={(
        <Box>
          {(slots[1] === "0") ? "--" : `${parseInt(slots[1]) - parseInt(slots[0])} ${IntlFormatter.getMessage("taskAttemptPage.spotsLeft", "Spots Left")}`}
        </Box>
      )}
      variant="outlined"
    />
  )
}

export default TaskSubmissionSlot;