import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { QueryTags } from "src/main/constants";
import { queryWithAuth } from "../base";
import { queryStatus, queryPageContent } from "./endpoints";

const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: queryWithAuth,
  tagTypes: QueryTags.Worker,
  endpoints: (builder) => ({
    status: queryStatus(builder),
    queryPageContent: queryPageContent(builder),
  }),
});

export default publicApi;
