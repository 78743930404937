import { createSvgIcon } from "@mui/material/utils";

const RefreshIcon = createSvgIcon(
  <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.27504 3.5636C6.40929 2.90127 7.73124 2.63398 9.03371 2.80361C10.3362 2.97325 11.5456 3.57023 12.4723 4.501C13.3991 5.43176 13.9909 6.64371 14.1549 7.9469C14.1963 8.27568 13.9633 8.57575 13.6345 8.61713C13.3057 8.65851 13.0057 8.42553 12.9643 8.09675C12.8334 7.0571 12.3613 6.09024 11.622 5.34769C10.8826 4.60515 9.91781 4.1289 8.87873 3.99356C7.83965 3.85823 6.78503 4.07147 5.88015 4.59987C5.24557 4.97043 4.70978 5.4813 4.31084 6.08849H6.26758C6.59895 6.08849 6.86758 6.35712 6.86758 6.68849C6.86758 7.01987 6.59895 7.28849 6.26758 7.28849L2.93424 7.28849C2.60287 7.28849 2.33424 7.01987 2.33424 6.68849L2.33424 3.35516C2.33424 3.02379 2.60287 2.75516 2.93424 2.75516C3.26561 2.75516 3.53424 3.02379 3.53424 3.35516V5.10877C4.00378 4.48786 4.59402 3.96128 5.27504 3.5636ZM2.90022 8.75991C3.22897 8.7183 3.52921 8.95106 3.57083 9.27981C3.70239 10.3191 4.17487 11.2854 4.91429 12.0275C5.65371 12.7695 6.61833 13.2455 7.65713 13.3808C8.69593 13.5161 9.75027 13.3031 10.6551 12.7751C11.2893 12.4051 11.825 11.8949 12.2241 11.2885H10.2676C9.93621 11.2885 9.66758 11.0199 9.66758 10.6885C9.66758 10.3571 9.93621 10.0885 10.2676 10.0885L13.6009 10.0885C13.9323 10.0885 14.2009 10.3571 14.2009 10.6885V14.0218C14.2009 14.3532 13.9323 14.6218 13.6009 14.6218C13.2695 14.6218 13.0009 14.3532 13.0009 14.0218V12.2676C12.5312 12.8881 11.9408 13.4143 11.2598 13.8116C10.1257 14.4733 8.80421 14.7403 7.50216 14.5707C6.20011 14.4012 4.99104 13.8046 4.06424 12.8745C3.13744 11.9444 2.54523 10.7332 2.38033 9.43052C2.33871 9.10177 2.57148 8.80153 2.90022 8.75991Z"
      fill="currentColor"
    />
  </svg>,

  "RefreshIcon"
);

export default RefreshIcon;
