import React from "react";
import { ManagementLayout, Page } from "src/main/components";


interface CreateAccountPageProps extends React.PropsWithChildren {

}

const CreateAccountPage: React.FC<CreateAccountPageProps> = () => {
  return (
    <Page>
      <ManagementLayout.WorkInProgress />
    </Page>
  );
};

export default CreateAccountPage;
