
import { BuilderProp, require200Status } from "src/main/api/account";
import { Meta, ActivityLogModel, QueryResult } from "src/main/types";
import { parseMeta } from "src/main/utils/parseMeta";

export interface ActivityLogResponse {
  entries: ActivityLogModel[];
  meta: Meta;
}
export interface ActivityLogRequest {
  meta: Meta;
}


export const listActivityLog = (builder: BuilderProp) => {
  return builder.query<ActivityLogResponse, ActivityLogRequest>({
    providesTags: ["activitylog"],
    query: ({ meta }) => ({
      url: `/account/activity/log/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ActivityLogResponse>) => {
      return response.result
    }
  })
}