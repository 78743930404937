import { APIResult, QueryError, ErrorResponse } from "./api";
import { capitalize } from "@mui/material";
import { toast } from "react-hot-toast";

const handleFormikApiResponse = <T = unknown>(result: APIResult<T>) => {
  let isToasted = false;
  if ("data" in result) {
    return { data: result.data as T, error: null, isToasted };
  } else if ("error" in result) {
    const error = result.error as QueryError<ErrorResponse>;
    if (error.data?.error) {
      let apiErr = error.data.error;
      if (apiErr.type === "ValidationError") {
        for (const error of Object.values<any>(apiErr.errors)) {
          toast.error(capitalize(error.msg));
          isToasted = true;
        }
      }
    }
    return { data: null, error, isToasted };
  }
  throw new Error("unable to parse api response");
};

export default handleFormikApiResponse;
