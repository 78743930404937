import { CheckRounded } from "@mui/icons-material";
import {
  Badge,
  Box, ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, ReactNode, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { accountApi, authApi } from "src/main/api";
import { accountTags } from "src/main/api/account";
import { useAsyncTask } from "src/main/hooks";
import { AuthToken, RootState } from 'src/main/store';
import auth from 'src/main/store/auth';
import { AccountModel } from 'src/main/types';
import { handleApiResponse } from "src/main/utils";

interface SwitchAccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  currentAccount?: AccountModel | null;
}

interface ItemWrapperProps extends React.PropsWithChildren {
  children: ReactNode;
  acc: AccountModel;
}


const ItemWrapper = (props: ItemWrapperProps) => {
  const { children, acc } = props;


  if (acc.status === "suspended") {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent="SUSPENDED"
        color="primary"
      >
        {children}
      </Badge>
    )
  }
  return <>{children}</>
}

const SwitchAccountPopover: FC<SwitchAccountPopoverProps> = (props) => {
  const { anchorEl, onClose, currentAccount, open, ...other } = props;

  const accounts = useSelector<RootState, AccountModel[] | undefined | null>((state) => state.auth.accounts);
  const [runLoginSubmit] = useAsyncTask("login/submit");
  const [accessTokenMutation] = authApi.useAccessTokenMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector<RootState, AuthToken | null | undefined>(state => state.auth.oauth);

  const onSubmit = useCallback((selectedAcc: AccountModel) => {
    runLoginSubmit(async () => {
      if (!selectedAcc || !accessToken?.accessToken) return;

      const accountLoginResult = await accessTokenMutation({
        grantType: "refresh_token",
        refresh_token: accessToken!.accessToken,
        account: selectedAcc!.id,
      });

      const { data, error } = handleApiResponse<AuthToken>(accountLoginResult);
      if (data) {
        let authToken = data;
        dispatch(auth.slice.actions.updateAuthToken(authToken));
        dispatch(accountApi.util.invalidateTags(accountTags))
        toast.success("Login success!");
        navigate("/overview");
      } else {
        if (error?.data?.error) {
          throw new Error(error.data?.error?.message)
        }
      }

      if (error?.data?.error) {
        toast.error(error.data.error.message ?? "");
        dispatch(auth.slice.actions.logout());
      }

    })
  }, [runLoginSubmit, accessTokenMutation, dispatch, navigate, accessToken]);


  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
      onMouseLeave={() => onClose?.()}
    >
      <Box sx={{ my: 1 }}>
        {accounts?.map((acc => (
          <ItemWrapper
            acc={acc}
          >

            <MenuItem
              sx={{
                width: 300,
                pointerEvents: currentAccount?.id === acc.id ? "none" : "all",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={() => currentAccount?.id === acc.id ? undefined : onSubmit(acc)}
            >
              <ListItemText
                primary={(
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="body1"
                  >
                    {acc.name}
                  </Typography>
                )}
              />
              {currentAccount?.id === acc.id && (
                <ListItemIcon sx={{ justifySelf: "flex-end" }}>
                  <CheckRounded color="success" fontSize="small" />
                </ListItemIcon>
              )}
            </MenuItem>
          </ItemWrapper>
        )))}
      </Box>
    </Popover>
  );
};

SwitchAccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default SwitchAccountPopover
