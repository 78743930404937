import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { QueryTags } from "src/main/constants";
import { QueryResult } from "src/main/types";
import { QueryFn } from "../../base";

type PublicApiBuilder<A extends string> = EndpointBuilder<QueryFn, typeof QueryTags.Worker[number], A>;

export interface StatusResponse {
  status: string;
}

export const queryStatus = <A extends string>(builder: PublicApiBuilder<A>) => builder.query({
  query: () => ({
    url: `/status`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<StatusResponse>) => {
    return response;
  },
})
