import { Box, Typography, Button, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "src/assets";
import { packageFormatMessage, Paths } from "src/main/utils";

export interface BaseContentProps {
  children?: React.ReactNode;
  status?: "success" | "error" | "warning";
  withMoreTasks?: boolean;
}

export function BaseContent({
  children,
  status,
  withMoreTasks,
}: BaseContentProps) {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const intl = useIntl();

  let bg = palette.success.alert.background;

  if (status === "error") {
    bg = palette.error.alert.background;
  } else if (status === "warning") {
    bg = palette.warning.alert.background;
  }

  return (
    <>
      <Box
        sx={{
          background: bg,
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Typography variant="body2">{children}</Typography>
      </Box>
      {withMoreTasks && (
        <Box textAlign="right" mt="4px">
          <Button
            endIcon={<ChevronRight />}
            onClick={() => {
              navigate(Paths.Portal.TasksAvailable);
            }}
            variant="text"
          >
            {intl.formatMessage(
              packageFormatMessage(
                "terminatedContentComp.more_available_tasks",
                "More Available Tasks"
              )
            )}
          </Button>
        </Box>
      )}
    </>
  );
}
