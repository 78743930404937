import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Checkbox, Divider, Drawer, List, ListItem, ListItemButton, Stack, StackProps } from "@mui/material";
import React, { useState } from "react";
import { Adjustment } from "src/assets";
import { ActionButton, LoadingButton, Typography } from "src/main/components";
import { createStyles, IntlFormatter } from "src/main/utils";

interface TaskFilterProps extends StackProps {
  noTaskByStatus?: number;
}

const TaskFilter: React.FC<TaskFilterProps> = (props) => {
  const { sx, noTaskByStatus, ...rest } = props;

  const categoryList = ['Instagram', 'Facebook', 'TikTok', 'Twitter', 'Telegram', 'SMS', 'Others'];
  const difficultyList = ['Easy', 'Moderate', 'Challenging'];
  const osList = ['Android', 'iOS', 'Windows'];
  const deviceList = ['Mobile', 'Desktop'];

  const [launchDrawer, setLaunchDrawer] = useState({ open: false });
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const handleCheckboxClick = (category: string) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((c) => c !== category)
      : [...selectedCategories, category];
    setSelectedCategories(newSelectedCategories);
  };

  const handleReset = () => {
    setSelectedCategories([]);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setLaunchDrawer({ ...launchDrawer, open });
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <CloseIcon sx={{ position: 'absolute', top: 15, right: 10 }} onClick={() => setLaunchDrawer({ ...launchDrawer, open: false })} />

      <Typography formatId="taskFilterCompo.filterBy" variant="h6" p={2}>
        Filter by
      </Typography>

      <List>
        <Typography formatId="taskFilterCompo.category" variant="overline" color="text.secondary" sx={{ padding: 2 }}>
          CATEGORY:
        </Typography>
        {categoryList.map((text) => (
          <ListItem key={text} disablePadding dense>
            <ListItemButton>
              <Checkbox color="secondary" checked={selectedCategories.includes(text)} onClick={() => handleCheckboxClick(text)}
              />
              <Typography variant="body1">{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <Typography formatId="taskFilterCompo.difficulty" variant="overline" color="text.secondary" sx={{ padding: 2 }}>
          DIFFICULTY:
        </Typography>
        {difficultyList.map((text) => (
          <ListItem key={text} disablePadding dense>
            <ListItemButton>
              <Checkbox color="secondary" checked={selectedCategories.includes(text)} onClick={() => handleCheckboxClick(text)} />
              <Typography variant="body1">{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <Typography variant="overline" color="text.secondary" sx={{ padding: 2 }}>
          OS:
        </Typography>
        {osList.map((text) => (
          <ListItem key={text} disablePadding dense>
            <ListItemButton>
              <Checkbox color="secondary" checked={selectedCategories.includes(text)} onClick={() => handleCheckboxClick(text)} />
              <Typography variant="body1">{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <Typography formatId="taskFilterCompo.device" variant="overline" color="text.secondary" sx={{ padding: 2 }}>
          DEVICE:
        </Typography>
        {deviceList.map((text) => (
          <ListItem key={text} disablePadding dense>
            <ListItemButton>
              <Checkbox color="secondary" checked={selectedCategories.includes(text)} onClick={() => handleCheckboxClick(text)} />
              <Typography variant="body1">{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={styles.buttonBox}>
        <ActionButton variant="text" color="secondary" onClick={handleReset}>{IntlFormatter.getMessage("taskFilterCompo.filter", "Reset")}</ActionButton>
        <LoadingButton disabled fullWidth color="secondary" variant="contained" loading={false}>{IntlFormatter.getMessage("taskFilterCompo.apply", "Apply")}</LoadingButton>
      </Box>
    </Box>
  );

  return (
    <Stack
      {...rest}
      direction="row"
      alignItems="center"
      pr={3}
    >
      <Adjustment fontSize='small' onClick={toggleDrawer(true)} />
      <Drawer
        anchor="right"
        open={launchDrawer.open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
      <Typography formatId="taskFilterCompo.filter" variant="subtitle2" paddingRight={2}>
        Filter
      </Typography>
      <Badge badgeContent={noTaskByStatus} color="success"></Badge>
    </Stack>
  )
}


const styles = createStyles({
  container: {
    pt: 1,
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    p: 2,
  }

});

export default TaskFilter;
