import { Typography } from "@mui/material";
import React from "react";
import { TaskAttemptModel } from "src/main/types";
import { packageFormatMessage } from "src/main/utils";
import { useIntl } from "react-intl";
import { BaseContent } from "../BaseContent";

interface CancelContentProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
}
const CancelContent: React.FC<CancelContentProps> = (props) => {
  const intl = useIntl();

  const getCancelText = () => {
    return intl.formatMessage(
      packageFormatMessage(
        "cancelContentComp.task_been_cancelled",
        "Task has been Cancelled"
      )
    );
  };

  return (
    <>
      <BaseContent status="error" withMoreTasks={true}>
        <Typography variant="body2">{getCancelText()}</Typography>
      </BaseContent>
    </>
  );
};

export default CancelContent;
