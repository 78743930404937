import {
  Box, IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useState } from "react";
import type { DropzoneOptions, FileRejection, FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { AddFile } from "src/assets";
import { CrossIcon, DuplicateIcon } from "src/main/images/icons";
import * as XLSX from "xlsx";

export type File = FileWithPath;

interface FileDropzoneProps extends DropzoneOptions {
  files?: any[];
  onRemove?: (file: File) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
  fileRegex?: RegExp;

  setFiles?: (files: any[]) => void;
  setImportFiles?: (file: any) => void;
}
const REMOVE_TEXT = [".csv", ".xlsx", ".xls", ".png", ".jpeg", ".jpg"];

const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    // Own props
    files = [],
    fileRegex = /(csv|xlsx)$/i,
    onRemove,
    onRemoveAll,
    onUpload,
    // DropzoneOptions props
    accept,
    disabled,
    getFilesFromEvent,
    maxSize,
    minSize,
    multiple,
    maxFiles = 1,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onFileDialogOpen,
    useFsAccessApi,
    autoFocus,
    preventDropOnDocument,
    noClick,
    noKeyboard,
    noDrag,
    noDragEventsBubbling,
    onError,
    validator,
    setFiles,
    setImportFiles,
    ...other
  } = props;

  const [filesSize, setFilesSize] = useState(0);

  const handleDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length) {
      toast.error("Too much files!");
      return;
    }
    const filteredFiles = acceptedFiles.filter((file, index) => {
      const fileLoader = new FileReader();
      if (!fileRegex.test((file as any).path) || (maxSize && filesSize + file.size > maxSize)) {
        toast.error("Format not accepted");
        return false;
      }
      fileLoader.onloadend = () => {
        const readdata = XLSX.read(fileLoader.result, { type: "binary" });
        let name = file.name;
        REMOVE_TEXT.forEach((tx) => {
          name = name.replace(tx, "");
        });
        setImportFiles && setImportFiles({ ...readdata, name });
      };

      fileLoader.readAsBinaryString(file);

      setFilesSize((prevSize) => prevSize + file.size);

      return true;
    });
    setFiles && setFiles((files || []).concat(filteredFiles));
  };

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop: handleDrop,
  });

  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          borderColor: 'divider',
          display: files.length === maxFiles ? "none" : 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 6,
          ...(
            isDragActive && {
              backgroundColor: 'action.active',
              opacity: 0.5
            }
          ),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
            opacity: 0.5
          }
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            '& img': {
              width: 100
            }
          }}
        >
          <AddFile style={{ width: "80px", maxWidth: "100%", height: "auto", margin: "0 auto" }} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">
            {`Select file${(
              maxFiles && maxFiles === 1
            ) ? '' : 's'}`}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              {`Drop file${(
                maxFiles && maxFiles === 1
              ) ? '' : 's'}`}
              {' or '}
              <Link underline="always">
                browse
              </Link>
              {' '}
              thorough your machine
            </Typography>
          </Box>
        </Box>
      </Box>
      {files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  '& + &': {
                    mt: 1
                  }
                }}
              >
                <ListItemIcon>
                  <DuplicateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                />
                <Tooltip title="Remove">
                  <IconButton
                    edge="end"
                    onClick={() => onRemove?.(file)}
                  >
                    <CrossIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
};

FileDropzone.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  accept: PropTypes.oneOfType<any>([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool
};

export default FileDropzone;