import { Box, BoxProps, Tab, Tabs, useMediaQuery, useTheme, Badge, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "src/main/store";
import { WorkerTaskStatistic } from "src/main/types";
import { Paths, createStyles, IntlFormatter } from "src/main/utils";

interface TaskTabsProps extends BoxProps {

}

enum Page {
  Available = "available",
  Ongoing = "ongoing",
  Submitted = "submitted",
  Rejected = "rejected",
  Closed = "closed",
}

const TaskTabs: React.FC<TaskTabsProps> = (props) => {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();

  const taskStats = useSelector<RootState, WorkerTaskStatistic | null>(state => state.layout.taskStats);

  const onTabChange = (evt: React.SyntheticEvent, value: Page) => {
    navigate(Paths.Portal.TasksTab.replace(":tab", value));
  };

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const getColor = (status: string) => {
    switch (status) {
      case "inprogress":
      case "submitted":
        return "primary";

      case "rejected":
        return "warning";
      default: return undefined
    }
  }

  const getLabelStats = (label: string, status: string) => {
    let count = 0;
    switch (status) {
      case "inprogress":
      case "rejected":
        count = parseInt((taskStats?.[status] ?? 0) + "");
        break;
    }

    const hasPr = !["available", "closed", "submitted"].includes(status) && count > 0;

    const getPadding = () => {
      if (!hasPr) return 0;

      switch (true) {
        case count > 99:
          return 2.1;
        case count > 9:
          return 1.8;
        default:
          return 1.4;
      }
    }

    return (
      <Stack direction="row" alignItems="center" pr={getPadding()}>
        <Box component="span" sx={{ pr: hasPr ? 1 : 0 }}>{label}&nbsp;&nbsp;</Box>
        {hasPr && (
          <Badge badgeContent={count} color={getColor(status)} />
        )}
      </Stack>
    )
  }

  return (
    <Box sx={
      {
        borderBottom: 1,
        borderColor: 'divider',
        mb: 2,
        ...(isMobile && {
          backgroundColor: "background.paper", ml: -2, mr: -2
        })
      }}>
      <Tabs
        value={tab}
        onChange={onTabChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label={getLabelStats(`${IntlFormatter.getMessage("taskAvailableTabs.available", "Available")}`, "available")} value={Page.Available} sx={styles.tabs} />
        <Tab label={getLabelStats(`${IntlFormatter.getMessage("taskAvailableTabs.inprogress", "In Progress")}`, "inprogress")} value={Page.Ongoing} sx={styles.tabs} />
        <Tab label={getLabelStats(`${IntlFormatter.getMessage("taskAvailableTabs.underReview", "Under Review")}`, "submitted")} value={Page.Submitted} sx={styles.tabs} />
        <Tab label={getLabelStats(`${IntlFormatter.getMessage("taskAvailableTabs.resubmit", "To Resubmit")}`, "rejected")} value={Page.Rejected} sx={styles.tabs} />
        <Tab label={getLabelStats(`${IntlFormatter.getMessage("taskAvailableTabs.closed", "Closed")}`, "closed")} value={Page.Closed} sx={styles.tabs} />
      </Tabs>
    </Box>
  );
};

const styles = createStyles({
  tabs: {
    "&:first-child": {
      "&.MuiButtonBase-root": {
        pl: 2,
      }
    },
    "&:last-child": {
      "&.MuiButtonBase-root": {
        pr: 1,
      }
    }
  },
});

export default TaskTabs;
