import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, } from "@mui/material";
import React from "react";
import { DetailCard } from "src/main/components";
import { TaskObjectiveModel } from "src/main/types";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { SimpleMap } from "src/main/utils";

interface TaskObjectiveDetailProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  handleChange?: (e: string | React.ChangeEvent<any>) => void;
  errors?: SimpleMap<any>;
  touched?: SimpleMap<any>;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const PROOF_TYPE = [
  { value: "screenshot", label: "Screenshot" },
  { value: "link", label: "Link" },
  { value: "text", label: "Text" },
]


const TaskObjectiveDetailComponent = (props: TaskObjectiveDetailProps) => {
  const { values, setFieldValue } = props;

  const objectives = [...values.objectives] ?? [];


  const onAddObjective = () => {
    const newObjs = [...objectives];
    const maxPriority = Math.max(0, ...newObjs.map((ob) => ob.sortPriority ?? 0));

    newObjs.push({
      type: "screenshot",
      instruction: "",
      sortPriority: maxPriority + 1,
    })

    setFieldValue("objectives", newObjs);
  }

  const onRemoveObjective = (obj: Partial<TaskObjectiveModel>, index: number) => {
    const newObjs = [...objectives];

    if (obj.id) {
      const target = { taskObjectiveId: obj.id };
      newObjs[index] = target;

      setFieldValue("objectives", newObjs);
    } else {
      newObjs.splice(index, 1);

      setFieldValue("objectives", newObjs);
    }
  }

  const onTypeChange = (obj: Partial<TaskObjectiveModel>, index: number, newType: string) => {
    const newObj = { ...obj, type: newType };

    const newObjectives = [...objectives];
    newObjectives[index] = newObj;

    setFieldValue("objectives", newObjectives);
  }

  const onDescriptionChange = (obj: Partial<TaskObjectiveModel>, index: number, newInstruction: string) => {
    const newObj = { ...obj, instruction: newInstruction };

    const newObjectives = [...objectives];
    newObjectives[index] = newObj;

    if ((newObj.instruction + "").length > 250) {
      if (!newObjectives[index].error) newObjectives[index].error = "Instruction is too long."
    } else {
      delete newObjectives[index].error;
    }

    setFieldValue("objectives", newObjectives);
  }

  return (
    <DetailCard>
      <DetailCard.Header header="Task Objectives" />
      <DetailCard.Divider />
      <Box p={2} gap={2} >
        <Typography color="text.secondary" variant="body2" pb={2}>
          Specify what the worker will have to provide in order to submit the task for approval.
        </Typography>
        {objectives.sort((objA, objB) => { return (parseInt(objA.sortPriority) ?? 0) - (parseInt(objB.sortPriority) ?? 0) }).map((objective, index) => (
          <>
            {objective.type && (
              <Box display="flex" flexDirection="column" py={2}>
                <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">
                    Objective  {index + 1}
                  </Typography>
                  <Box display="flex">
                    <FormControl variant="outlined" sx={{ flex: 1 }} fullWidth>
                      <InputLabel shrink>Proof type</InputLabel>
                      <Select
                        input={<OutlinedInput notched label="Proof Type" />}
                        sx={{ flex: 1, minWidth: "150px", }}
                        value={objective.type}
                        onChange={(ev) => onTypeChange(objective, index, ev.target.value)}
                      >
                        {PROOF_TYPE.map((option) => (
                          <MenuItem value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <IconButton
                      onClick={() => onRemoveObjective(objective, index)}
                    >
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>
                <TextField
                  label="Instruction"
                  value={objective.instruction}
                  required
                  multiline
                  onChange={(ev) => onDescriptionChange(objective, index, ev.target.value)}
                  sx={{ marginTop: 2 }}
                  error={!!objective.error}
                  helperText={
                    <Box display="flex" justifyContent="space-between">
                      <Typography sx={{
                        fontSize: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}>{objective.error}</Typography>
                      <Typography sx={{ fontSize: 12, textAlign: "end" }}>
                        {`${(objective.instruction + "").length}/250 characters`}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            )}

          </>
        ))}

        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={() => onAddObjective()}
        >
          <AddCircleOutlineIcon />&nbsp;Add Objective
        </Button>
      </Box>
    </DetailCard>
  )
}

export default TaskObjectiveDetailComponent;