import { ArchiveIcon, AvatarIcon, CashBillIcon, CubeTransparentIcon, HomeIcon, LightningBoltIcon } from "src/assets";
import { Paths } from "src/main/utils";

const HIDE_AFFILIATE = process.env.REACT_APP_AFFILIATE_HIDE

export const SideBarNav = [
  {
    title: "",
    items: [
      {
        title: "Overview",
        path: Paths.Overview.Overview,
        icon: <HomeIcon />,
      },
      {
        title: "Account",
        icon: <AvatarIcon />,
        items: [{
          title: "Users",
          path: Paths.Overview.Users,
        }, {
          title: "Invites",
          path: Paths.Overview.Invites,
        }, {
          title: "Settings",
          path: Paths.Overview.AccountSetting,
        }]
      },
      {
        title: "Taskers",
        icon: <LightningBoltIcon />,
        items: [{
          title: "All Taskers",
          path: Paths.Management.Workers,
        }, {
          title: "Teams",
          path: Paths.Management.Teams,
        }, {
          title: "Export",
          path: Paths.Management.WorkerExport,
        }]
      },
      {
        title: "Tasks",
        icon: <ArchiveIcon />,
        items: [{
          title: "All Tasks",
          path: Paths.Management.Tasks,
        }, {
          title: "All Submissions",
          path: Paths.Management.TaskSubmissionList,
        }]
      },
      {
        title: "Affiliate",
        icon: <CubeTransparentIcon />,
        items: HIDE_AFFILIATE ? undefined : [{
          title: "Landing Pages",
          path: Paths.Management.AffiliatePageHome,
        }, {
          title: "New Landing Page",
          path: Paths.Management.AffiliatePageCreate,
        }, {
          title: "Affiliate Tracker",
          path: Paths.Management.AffiliateTrackerList,
        }]
      },
      {
        title: "Payouts",
        icon: <CashBillIcon />,
        items: [{
          title: "Payouts",
          path: Paths.Management.Payouts,
        },
        {
          title: "Withdraw Request",
          path: Paths.Management.WithdrawRequest,
        },
        {
          title: "Process Withdraw",
          path: Paths.Management.BulkWithdraw,
        }]
      },
    ]
  },
  // {
  //   title: "PLATFORM",
  //   items: [
  //     {
  //       title: "Accounts",
  //       items: [
  //         {
  //           title: "All Accounts",
  //           path: Paths.Platform.AllAccounts,
  //         },
  //         {
  //           title: "Create Account",
  //           path: Paths.Platform.CreateAccount,
  //         }
  //       ],
  //       icon: <AccountBox />
  //     },
  //     {
  //       title: "Administrators",
  //       path: Paths.Platform.Administrators,
  //       icon: <People />,
  //     },
  //     {
  //       title: "Workers",
  //       path: Paths.Platform.Workers,
  //       icon: <Groups />,
  //     },
  //   ]
  // }
]
