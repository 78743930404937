import { createSvgIcon } from '@mui/material/utils';

const Home = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 10.1216L3 8.12158M3 8.12158L10 1.12158L17 8.12158M3 8.12158V18.1216C3 18.3868 3.10536 18.6412 3.29289 18.8287C3.48043 19.0162 3.73478 19.1216 4 19.1216H7M17 8.12158L19 10.1216M17 8.12158V18.1216C17 18.3868 16.8946 18.6412 16.7071 18.8287C16.5196 19.0162 16.2652 19.1216 16 19.1216H13M7 19.1216C7.26522 19.1216 7.51957 19.0162 7.70711 18.8287C7.89464 18.6412 8 18.3868 8 18.1216V14.1216C8 13.8564 8.10536 13.602 8.29289 13.4145C8.48043 13.2269 8.73478 13.1216 9 13.1216H11C11.2652 13.1216 11.5196 13.2269 11.7071 13.4145C11.8946 13.602 12 13.8564 12 14.1216V18.1216C12 18.3868 12.1054 18.6412 12.2929 18.8287C12.4804 19.0162 12.7348 19.1216 13 19.1216M7 19.1216H13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  ,
  'Home'
);

export default Home;
