import { Box, BoxProps, Card, CardContent, Container, Typography } from "@mui/material";
import React from "react";
import { WorkInProgressGraphic } from "src/assets";
import { createStyles, joinSx } from "src/main/utils";

interface WorkInProgressProps extends BoxProps {

}

const WorkInProgress: React.FC<WorkInProgressProps> = (props) => {
  const { sx, ...rest } = props;
  return (
    <Box p={6} width="100%" {...rest} sx={joinSx(styles.root, sx)}>
      <Container maxWidth="sm">
        <Card>
          <CardContent sx={styles.content}>
            <Box justifyContent="center" textAlign="center" display="flex" flexDirection="column">
              <Typography lineHeight="33px" color="textSecondary" variant="h5" marginBottom="16px">Work-in-progress</Typography>
              <WorkInProgressGraphic style={{ width: "200px", maxWidth: "100%", height: "auto", margin: "0 auto" }} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

const styles = createStyles({
  root: {
  },
  content: {
    padding: 2,
  },
});

export default WorkInProgress;
