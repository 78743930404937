import { BoxProps, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CashBillIcon } from "src/assets";
import { workerApi } from "src/main/api";
import { LoadingContainer, NavigateButton, Typography } from "src/main/components";
import { BN_ZERO } from "src/main/constants";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { Paths, IntlFormatter } from "src/main/utils";

interface WalletBalanceProps extends BoxProps {

}

const WalletBalance: React.FC<WalletBalanceProps> = (props) => {
  const { sx, ...boxProps } = props;
  const { currency } = useSelector<RootState, AuthState>(state => state.auth)
  const walletsResult = workerApi.useWorkerWalletListQuery(undefined);
  const default_currency = currency?.default ?? "PHP";
  const default_wallet = useMemo(() => walletsResult.data?.entries.find(wallet => wallet.currency === default_currency), [walletsResult, default_currency]);

  return (
    <LoadingContainer loading={walletsResult.isLoading} sx={sx} {...boxProps}>
      <NavigateButton.Vertical
        icon={(<CashBillIcon color="primary" />)}
        title={IntlFormatter.getMessage("overview.walletBalance", "wallet balance")}
        content={(
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="h6">{(default_wallet?.balance ?? BN_ZERO).toFormat(0)}</Typography>
            <Typography variant="caption">{default_currency}</Typography>
          </Stack>
        )}
        linkTitle={IntlFormatter.getMessage("overview.withdraw", "Withdraw")}
        path={Paths.Portal.Withdraw}
      />
    </LoadingContainer>

  );
};

export default WalletBalance;