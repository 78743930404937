import { useTheme } from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { accountApi } from "src/main/api";
import { ListTaskSubmissionSeriesResponse } from "src/main/api/account/Stats";
import { Chart } from "src/main/components";
import { ChartProps } from "src/main/components/Chart/Chart";
import { RootState } from "src/main/store";
import { Paths } from "src/main/utils";

interface SubmissionsChartProps extends Partial<ChartProps> {}
const SubmissionsChart: React.FC<SubmissionsChartProps> = (props) => {
  const theme = useTheme();
  const currentTheme = useSelector<RootState>(
    (state) => state.preference.theme
  );

  const [startQueryDate] = useState(moment().add(-29, "day"));
  const [endQueryDate] = useState(moment().endOf("day"));

  const result = accountApi.useListTaskSubmissionSeriesQuery({
    meta: { date: `${startQueryDate.unix()},${endQueryDate.unix()}` },
  });
  const responseOnboardSeries: ListTaskSubmissionSeriesResponse | undefined =
    useMemo(() => result.data ?? undefined, [result.data]);

  const { chartOpts, series } = useMemo(() => {
    const chartOpts: ApexCharts.ApexOptions = {
      chart: {
        stacked: true,
        background: "transparent",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "55%" },
      },
      // colors: [theme.palette.primary.dark, theme.palette.primary.light],
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: { fontSize: "12px", colors: ["#fff"] },
      },
      legend: {
        show: true,
        position: "top",
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      theme: {
        mode: currentTheme === "light" ? "light" : "dark",
        monochrome: {
          enabled: true,
          color:
            currentTheme === "light"
              ? theme.palette.primary.light
              : theme.palette.primary.dark,
          shadeTo: "dark",
          shadeIntensity: 0.65,
        },
      },
      xaxis: { categories: [] as string[] } /** */,
    };
    const series = [
      { data: [] as number[], name: "In-Progress" },
      { data: [] as number[], name: "Approved" },
      { data: [] as number[], name: "Submitted" },
    ];

    if (responseOnboardSeries) {
      const newSeries = [...responseOnboardSeries?.series];

      const Date_Format = "DD MMM";

      let currentDate = moment(startQueryDate);

      while (
        currentDate.format(Date_Format) !==
        moment(endQueryDate).add(1, "day").format(Date_Format)
      ) {
        let dateKey = currentDate.format(Date_Format);

        const item = newSeries.find(
          (serie) => serie.date.format(Date_Format) === dateKey
        );

        if (chartOpts["xaxis"]?.categories.indexOf(dateKey) < 0) {
          series[0].data.push(item?.inprogress ?? 0);
          series[1].data.push(item?.approved ?? 0);
          series[2].data.push(item?.submitted ?? 0);

          chartOpts["xaxis"]?.categories.push(dateKey);
        }
        currentDate = currentDate.add(1, "day");
      }
    }

    return { chartOpts, series };
  }, [
    responseOnboardSeries,
    theme,
    currentTheme,
    startQueryDate,
    endQueryDate,
  ]);

  return (
    <Chart
      {...props}
      title="Task Submissions"
      subtitle="Last 30 days"
      chartOptions={chartOpts}
      chartSeries={series}
      height={330}
      type="bar"
      shortcuts={{
        header: {
          label: "All Taskers",
          to: Paths.Management.Workers,
        },
      }}
    />
  );
};

export default SubmissionsChart;
