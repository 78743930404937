import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, DialogContent, DialogTitle, Paper, Typography } from "@mui/material";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { ActionButton, InputField, LoadingButton, MonoLabel } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { TaskAttemptModel, TaskProofModel } from "src/main/types";
import { createStyles, handleApiResponse, joinSx, SimpleMap } from "src/main/utils";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskObjectiveProofCard from "../../../TaskObjectiveProofCard";
import { RejectTaskAttemptRequest } from "src/main/api/account/Task";
import { CheckCircleOutline } from "@mui/icons-material"

interface RejectContentProps extends BoxProps {
  taskAttempt: TaskAttemptModel;
  onClose: () => void;
  onCancel: () => void;
}

const RejectContent: React.FC<RejectContentProps> = (props) => {
  const { taskAttempt, onCancel, onClose, sx } = props;
  const [reason, setReason] = React.useState<string>("");
  const [selectedTaskProof, setSelectedTaskProof] = useState<TaskProofModel | null>(null);
  const [selectedTaskIndex, setSelectedTaskProofIndex] = useState<number | undefined>(undefined);
  const [expanded, setExpanded] = useState(false);

  const [runRejectTask] = useAsyncTask("reject/task");
  const [rejectTaskAttemptMutation, { isLoading: isRejectLoading }] = accountApi.useRejectTaskAttemptMutation();

  const [taskProofReasons, setTaskProofReasons] = useState<SimpleMap<string>>({});
  const [errors, setErrors] = useState<SimpleMap<string>>({});

  useEffect(() => {
    if (!reason && !!Object.keys(taskProofReasons).length) {
      setReason("Please resubmit the rejected objectives.");
    }
    // eslint-disable-next-line
  }, [taskProofReasons])

  const hasError = useMemo(() => {
    return !!Object.keys(errors).length;
  }, [errors])

  const handleConfirm = useCallback(() => {
    runRejectTask(async () => {
      if (!taskAttempt) return;

      const rejectContent = {
        attemptId: taskAttempt.id,
        data: {
          reason
        }
      } as RejectTaskAttemptRequest;

      if (Object.keys(taskProofReasons).length) {
        let newProofs: SimpleMap<SimpleMap<string>> = {}
        Object.entries(taskProofReasons).forEach(([key, value]) => {
          newProofs[key] = { reason: value };
        });
        rejectContent.data.proofs = newProofs;
      }

      const result = await rejectTaskAttemptMutation(rejectContent);
      const { data, error } = handleApiResponse(result);

      if (data) {
        toast.success("Task Rejected!");
        onClose?.();
      } else {
        toast.error(error?.data?.error?.message ?? "")
        throw new Error(error?.data?.error?.message)
      }
    })
  }, [taskAttempt, reason, onClose, runRejectTask, rejectTaskAttemptMutation, taskProofReasons])

  const handleExpand = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
  }

  return (
    <>
      {!selectedTaskProof && (<DialogTitle>Reject Submission?</DialogTitle>)}
      <DialogContent sx={joinSx(styles.root, sx)}>

        {!selectedTaskProof && (
          <Box display="flex" p={1}>
            <InputField
              multiline
              label="Message to worker"
              value={reason}
              onChange={(ev) => {
                if (ev?.target?.value?.length > 255) {
                  setErrors((prev) => {
                    return {
                      ...prev,
                      reason: "over word limit",
                    }
                  })
                } else {
                  setErrors((prev) => {
                    let newErr = { ...prev }
                    delete newErr["reason"];
                    return { ...newErr }
                  })
                }
                setReason(ev.target.value)
              }}
              required
              error={!!errors["reason"]}
              helperText={
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={{
                    fontSize: 12,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}>{errors?.["reason"]}</Typography>
                  <Typography sx={{ fontSize: 12, textAlign: "end" }}>
                    {`${reason?.length ?? 0}/255 characters`}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )}

        {!!selectedTaskProof && (
          <Box display="flex" flexDirection="column" gap={2}>
            <MonoLabel sx={{ cursor: "pointer" }} onClick={() => {
              setSelectedTaskProof(null);
            }}>Back</MonoLabel>
            {!!taskAttempt && !!taskAttempt.task && !!taskAttempt.task.taskObjectives && <TaskObjectiveProofCard taskAttempt={taskAttempt} taskObjs={taskAttempt.task?.taskObjectives} index={selectedTaskIndex ?? 0} taskProof={selectedTaskProof} />}
          </Box>
        )}

        {!selectedTaskProof && !!taskAttempt.taskProofs?.length && (
          <Box p={1}>
            <Accordion expanded={expanded} onChange={handleExpand}>
              <AccordionSummary
                component={Paper}
                expandIcon={<ExpandMoreIcon />}
              >
                <Box>
                  <Typography>
                    Reject reason for Task Objectives <b>({Object.keys(taskProofReasons).length}/{Object.keys(taskAttempt.taskProofs).length} rejected)</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Only enters the field for objectives to reject. Leave blank if not applicable.
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 2 }}>
                {taskAttempt.task?.taskObjectives && taskAttempt.taskProofs?.map((tproof) => {
                  const task = taskAttempt.task!.taskObjectives!.find((obj => obj.id === tproof.taskObjectiveId));
                  return {
                    ...tproof,
                    sortPriority: task?.sortPriority ?? 0,
                  }
                }).sort((proofA, proofB) => proofA.sortPriority - proofB.sortPriority).map((proof, index) => (
                  <Accordion elevation={1}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Box width="100%" display="flex" justifyContent="space-between">
                        <Box>
                          <Typography>Objective {index + 1}</Typography>
                          <Typography fontSize="10px" color="text.secondary">Click to enter reason</Typography>
                        </Box>
                        <MonoLabel sx={{ cursor: "pointer", fontSize: "11px" }} onClick={(ev) => {
                          ev.preventDefault();
                          setSelectedTaskProof(proof);
                          setSelectedTaskProofIndex(index);
                        }}>View Submitted</MonoLabel>
                        <Box flexGrow={1} />
                        <Box display="flex" alignItems="center" pr={2}>
                          {
                            !!taskProofReasons[proof.id] ? <CheckCircleOutline color="success" fontSize="small" /> : null
                          }
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column" gap={2} pb={2}>
                        <Box>
                          <InputField
                            multiline
                            label={`Reject objective ${index + 1} reason`}
                            value={taskProofReasons[proof.id] ?? ""}
                            onChange={(ev) => {
                              let newReasons = { ...taskProofReasons };
                              if (!ev.target.value) {
                                delete newReasons[proof.id]
                              } else {
                                newReasons[proof.id] = ev.target.value
                              }

                              if (ev.target.value?.length > 255) {
                                setErrors((prev) => {
                                  return {
                                    ...prev,
                                    [proof.id]: "over word limit",
                                  }
                                })
                              } else {
                                setErrors((prev) => {
                                  let newErr = { ...prev }
                                  delete newErr[proof.id];
                                  return { ...newErr }
                                })
                              }
                              setTaskProofReasons(newReasons);
                            }}
                            error={!!errors[proof.id]}
                            helperText={
                              <Box display="flex" justifyContent="space-between">
                                <Typography sx={{
                                  fontSize: 12,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}>{errors[proof.id]}</Typography>
                                <Typography sx={{ fontSize: 12, textAlign: "end" }}>
                                  {`${taskProofReasons?.[proof.id]?.length ?? 0}/255 characters`}
                                </Typography>
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  // </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {!selectedTaskProof && (
          <Box sx={styles.buttonBox}>
            <ActionButton disabled={isRejectLoading} color="inherit" onClick={onCancel}>Cancel</ActionButton>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={isRejectLoading}
              disabled={hasError || !reason}
              onClick={handleConfirm}>Confirm</LoadingButton>
          </Box>
        )}
      </DialogContent>
    </>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 2
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    mt: 3,
  }
})

export default RejectContent;
