import React from "react";
import { Page } from "src/main/components";
import { AffiliateTrackerListComp } from "./components";


interface AffiliateTrackerListProps extends React.PropsWithChildren {

}

const AffiliateTrackerList: React.FC<AffiliateTrackerListProps> = () => {
  return (
    <Page>
      <Page.TopSection title="Affiliate" />
      <AffiliateTrackerListComp />
    </Page>
  );
};

export default AffiliateTrackerList;
