import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import queryString from "query-string";
import { QueryTags } from "src/main/constants";
import { QueryFn } from "../../base";

type AuthApiBuilder<A extends string> = EndpointBuilder<QueryFn, typeof QueryTags.Worker[number], A>;

export interface QueryResult<T> {
  result: T;
}
export interface QueryResultEntries<T> {
  entries: T;
  meta: any; // TODO: type meta
}
export interface QueryResultModel<T> {
  model: T;
}

const getQueryString = (query?: object) => {
  if (!query) return "";
  if (!Object.keys(query).length) return "";
  return "?" + queryString.stringify(query);
}

export interface OnboardTokenQueryResponse {
  name: string;
  account: string;
}

export const queryOnboardToken = <A extends string>(builder: AuthApiBuilder<A>) => builder.query({
  query: (token: string) => ({
    url: `onboard/query${getQueryString({ token })}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<OnboardTokenQueryResponse>>) => {
    console.log("onboard/query", response);
    return response;
  },
})

export interface OnboardTokenRequest {
  token: string;
  accessKey: string;
  secret: string;
}
export const submitOnboard = <A extends string>(builder: AuthApiBuilder<A>) => builder.mutation({
  query: (req: OnboardTokenRequest) => ({
    url: `onboard/register`,
    method: "POST",
    body: req,
    validateStatus: (response: Response) => response.status === 200,
  }),
})


export interface InviteTokenQueryResponse {
  inviter: string;
  name: string;
  account: string;
}

export const queryInviteToken = <A extends string>(builder: AuthApiBuilder<A>) => builder.query({
  query: (token: string) => ({
    url: `invite/query${getQueryString({ token })}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<InviteTokenQueryResponse>>) => {
    console.log("invite/query", response);
    return response;
  },
})

export interface InviteTokenRequest {
  token: string;

  accessKey: string;
  secret: string;

  primaryEmail: string;
  firstname: string;
  middlename?: string;
  lastname?: string;
  nickname?: string;
  salutation?: string;
  gender?: string;

  primaryPhone?: string;
}
export const submitInvite = <A extends string>(builder: AuthApiBuilder<A>) => builder.mutation({
  query: (req: InviteTokenRequest) => ({
    url: `invite/accept`,
    method: "POST",
    body: req,
    validateStatus: (response: Response) => response.status === 200,
  }),
})
