import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { ChatIcon } from "src/assets";
import { Scrollbar } from "src/main/components";
import Branding from "../Branding";
import { SideBarNav } from "./SideBarNav";
import { SideBarSection } from "./components";
import { SUPPORT_LINK } from "src/constant";

interface SideBarProps extends DrawerProps {}

const SideBar: React.FC<SideBarProps> = (props) => {
  const { children, onClose, open, ...rest } = props;
  const location = useLocation();
  const muiTheme = useTheme();
  const isLg = useMediaQuery(muiTheme.breakpoints.up("lg"));

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: {
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: "textPrimary",
            width: 280,
            display: "flex",
          },
        }}
        variant={isLg ? "permanent" : "temporary"}
        {...rest}
      >
        <Scrollbar
          sx={{
            height: "100%",
            "& .simplebar-content": {
              height: "100%",
              display: "flex!important",
              flexDirection: "column",
            },
          }}
        >
          <Box
            px={3}
            py={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={styles.brand}
          >
            <Box flex={1}>
              <Branding />
            </Box>
          </Box>

          <Box flex={1}>
            {SideBarNav.map((section) => (
              <SideBarSection
                key={section.title}
                path={location.pathname}
                length={section.items?.length}
                sx={{
                  mt: 2,
                  "& + &": {
                    mt: 2,
                  },
                }}
                {...section}
              />
            ))}
            {children}
          </Box>

          <Box display="flex" flex={1} />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={4}
          >
            <Typography variant="body2" color="neutral.700">
              Need help?
            </Typography>
            <Button
              href={SUPPORT_LINK}
              target="_blank"
              startIcon={
                <ChatIcon
                  fontSize="small"
                  sx={{ color: muiTheme.palette.text.secondary, mr: -0.5 }}
                />
              }
              size="small"
              variant="text"
            >
              Contact Support
            </Button>
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
};

const styles: { [index: string]: SxProps } = {
  iconStyle: {
    cursor: "pointer",
  },
  brand: {
    color: "primary.main",
    backgroundColor: "rgba(255,255,255,.05)",
    borderRadius: 2,
  },
};

export default SideBar;
