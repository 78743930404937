import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Link, LinkProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import React from "react";
import { Renderable, toast, ToastBar, Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { RootState } from "src/main/store";
import { ThemeMode } from "src/main/store/preference/types";


interface ToasterProps extends React.PropsWithChildren {
  theme: Theme;
}

interface LinkWrapperProps extends LinkProps {
  link?: string;
}

const LinkWrapper = (props: LinkWrapperProps) => {
  const { link, children, ...rest } = props;
  if (link) {
    return (
      <Link sx={{ padding: "0 8px" }} component={RouterLink} to={link} {...rest}>
        {children}
      </Link>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}


const ToasterComponent: React.FC<ToasterProps> = ({ theme }) => {
  const savedTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        className: "",
        style: {
          backgroundColor: theme.palette.snackbar,
          color: savedTheme === "dark" ? theme.palette.primary.light : theme.palette.primary.contrastText,
          fontWeight: 400,
          fontSize: "14px",
          padding: "16px",
        }
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {

            let displayMessage: Renderable;
            let link: string | undefined;

            try {
              const parsed = JSON.parse((message as any).props.children);
              if (parsed.link && parsed.message) {
                displayMessage = parsed.message;
                link = parsed.link;
              } else {
                displayMessage = message;
              }
            } catch (error) {
              displayMessage = message;
            }
            return (
              <>
                {icon}
                <LinkWrapper color={savedTheme === "dark" ? theme.palette.primary.light : theme.palette.primary.contrastText} link={link}>
                  <Typography component="div" color={savedTheme === "dark" ? theme.palette.primary.light : theme.palette.primary.contrastText}>
                    {displayMessage}
                  </Typography>
                </LinkWrapper>
                {t.type !== 'loading' && (
                  <IconButton sx={{ color: savedTheme === "dark" ? theme.palette.primary.light : theme.palette.primary.contrastText }} onClick={() => toast.dismiss(t.id)}>
                    <CloseIcon />
                  </IconButton>
                )}
              </>
            )
          }}
        </ToastBar >
      )}
    </Toaster >
  );
};

export default ToasterComponent;
