import { createSvgIcon } from "@mui/material/utils";

export const NotionIcon = createSvgIcon(
  <svg
    viewBox="0 0 23 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7238 0.0545603L1.44398 1.03535C0.372796 1.12815 0 1.82814 0 2.66733V17.2256C0 17.8792 0.231997 18.4383 0.791991 19.1855L3.91355 23.2447C4.42635 23.8983 4.89274 24.0383 5.87193 23.9919L21.2934 23.0583C22.5973 22.9655 22.9709 22.3583 22.9709 21.3319V4.9537C22.9709 4.42331 22.7613 4.27051 22.1445 3.81772C22.1091 3.79215 22.0736 3.76655 22.0381 3.74092L17.7998 0.754552C16.7742 0.00896086 16.355 -0.0854381 14.7238 0.0545603ZM6.22073 4.68571C4.96154 4.77051 4.67595 4.78971 3.96075 4.20811L2.14237 2.76173C1.95758 2.57453 2.05038 2.34093 2.51597 2.29453L15.2822 1.36175C16.3542 1.26815 16.9126 1.64174 17.3318 1.96814L19.5214 3.55452C19.615 3.60172 19.8478 3.88092 19.5678 3.88092L6.38393 4.67451L6.22073 4.68571ZM4.75274 21.1919V7.28808C4.75274 6.68088 4.93914 6.40089 5.49754 6.35369L20.6398 5.4673C21.1534 5.4209 21.3854 5.74729 21.3854 6.35369V20.1647C21.3854 20.7719 21.2918 21.2855 20.4534 21.3319L5.96313 22.1719C5.12474 22.2183 4.75274 21.9391 4.75274 21.1919ZM19.0566 8.03367C19.1494 8.45366 19.0566 8.87366 18.6366 8.92166L17.9382 9.06006V19.3255C17.3318 19.6519 16.7734 19.8383 16.307 19.8383C15.5614 19.8383 15.375 19.6047 14.8166 18.9055L10.2495 11.72V18.6719L11.6943 18.9991C11.6943 18.9991 11.6943 19.8391 10.5287 19.8391L7.31511 20.0255C7.22152 19.8383 7.31511 19.3719 7.64071 19.2791L8.4799 19.0463V9.85445L7.31511 9.76005C7.22152 9.34005 7.45431 8.73366 8.10711 8.68646L11.5551 8.45446L16.307 15.7328V9.29365L15.0958 9.15445C15.0022 8.64006 15.375 8.26647 15.8406 8.22087L19.0566 8.03367Z" fill="black" />
  </svg>
  ,
  'AntGame'
);

export default NotionIcon;

