import React from "react";
import { Route, Routes } from "react-router-dom";
import { makeRelativePath, Paths } from "src/main/utils";
import { MainDashboardLayout } from "../../components";
import {
  AffiliatePageCreate,
  AffiliatePageDetail,
  AffiliatePageEdit,
  AffiliatePageHome,
  AffiliateTrackerDetail,
  AffiliateTrackerList,
} from "./AffiliatePage";
import { PayoutItemsDetailPage, PayoutItemsPage } from "./Payouts";
import { TaskAttemptDetail, TaskAttemptPage } from "./TaskAttempt";
import { CreateTaskPage, TaskDetailPage, TaskPage } from "./Tasks";
import { CreateTeamPage, EditTeamPage, TeamDetailPage, TeamPage } from "./Team";
import { WalletDetailPage } from "./Wallet";
import {
  BulkWithdrawProcess,
  WithdrawDetailPage,
  WithdrawPage,
} from "./Withdraw";
import {
  CreateWorker,
  WorkerDetail,
  WorkerEdit,
  WorkerExport,
  WorkerPage,
} from "./Worker";

interface MainProps extends React.PropsWithChildren {}

const Main: React.FC<MainProps> = () => {
  const p = makeRelativePath(Paths.Management.Index);
  return (
    <MainDashboardLayout>
      <Routes>
        <Route
          path={p(Paths.Management.Payouts)}
          element={<PayoutItemsPage />}
        />
        <Route
          path={p(Paths.Management.PayoutItemDetail)}
          element={<PayoutItemsDetailPage />}
        />

        <Route path={p(Paths.Management.Tasks)} element={<TaskPage />} />
        <Route
          path={p(Paths.Management.TasksDetail)}
          element={<TaskDetailPage />}
        />
        <Route
          path={p(Paths.Management.TaskSubmission)}
          element={<TaskAttemptDetail />}
        />
        <Route
          path={p(Paths.Management.CreateTask)}
          element={<CreateTaskPage />}
        />
        <Route
          path={p(Paths.Management.TaskSubmissionList)}
          element={<TaskAttemptPage />}
        />

        <Route
          path={p(Paths.Management.CreateWorker)}
          element={<CreateWorker />}
        />
        <Route
          path={p(Paths.Management.WorkerDetail)}
          element={<WorkerDetail />}
        />
        <Route path={p(Paths.Management.WorkerEdit)} element={<WorkerEdit />} />
        <Route path={p(Paths.Management.Workers)} element={<WorkerPage />} />
        <Route
          path={p(Paths.Management.WorkerExport)}
          element={<WorkerExport />}
        />

        <Route
          path={p(Paths.Management.AffiliatePageHome)}
          element={<AffiliatePageHome />}
        />
        <Route
          path={p(Paths.Management.AffiliatePageDetail)}
          element={<AffiliatePageDetail />}
        />
        <Route
          path={p(Paths.Management.AffiliatePageCreate)}
          element={<AffiliatePageCreate />}
        />
        <Route
          path={p(Paths.Management.AffiliatePageEdit)}
          element={<AffiliatePageEdit />}
        />
        <Route
          path={p(Paths.Management.AffiliateTrackerList)}
          element={<AffiliateTrackerList />}
        />
        <Route
          path={p(Paths.Management.AffiliateTrackerDetail)}
          element={<AffiliateTrackerDetail />}
        />

        <Route
          path={p(Paths.Management.WalletDetail)}
          element={<WalletDetailPage />}
        />

        <Route
          path={p(Paths.Management.WithdrawRequest)}
          element={<WithdrawPage />}
        />
        <Route
          path={p(Paths.Management.WithdrawRequestDetail)}
          element={<WithdrawDetailPage />}
        />
        <Route
          path={p(Paths.Management.BulkWithdraw)}
          element={<BulkWithdrawProcess />}
        />

        <Route path={p(Paths.Management.Teams)} element={<TeamPage />} />
        <Route
          path={p(Paths.Management.CreateTeam)}
          element={<CreateTeamPage />}
        />
        <Route
          path={p(Paths.Management.TeamDetail)}
          element={<TeamDetailPage />}
        />
        <Route path={p(Paths.Management.EditTeam)} element={<EditTeamPage />} />
      </Routes>
    </MainDashboardLayout>
  );
};

export default Main;
