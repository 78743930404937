import { createSvgIcon } from '@mui/material/utils';

const WindowsIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 10V5.625L7.5 4.525V9.925L2.5 10ZM16.6667 2.5V9.79167L8.33333 9.91667V4.34167L16.6667 2.5ZM2.5 10.8333L7.5 10.9083V16.5833L2.5 15.625V10.8333ZM16.6667 11.0417V18.3333L8.33333 16.7417V10.9167L16.6667 11.0417Z" fill="currentColor" />
  </svg>
  ,
  'WindowsIcon'
);

export default WindowsIcon;
