

const logMap = {
  "oauth-access": "Authentication",
  "credential": "Credentials",
  "password": "Password",
  "asset": "File Asset",
  "admin-person": "Admin Person",
  "admin-account": "Admin Account",
  "admin-worker": "Admin Worker",
  "account": "Account",
  "account-person": "Account Person",
  "account-worker": "Account Tasker",
  "account-task": "Account Task",
  "account-invite": "Account Invite",
  "work-task": "Work Task",
  "wallet": "Wallet",
  "payout-item": "Payout Item",
  "withdraw-request": "Withdraw Request",
  "team": "Team",
  "team-person": "Team Person",
  "team-task": "Team Task",
  "task": "Task",
  "task-attempt": "Task Submission",
  "task-objective": "Task Objective",
  "task-content": "Task Content",
  "worker-onboard": "Worker Onboard",
  "miscellaneous": "Miscellaneous",
}

const parseCategory = (key?: string) => {
  if (!key) return "";
  if (!logMap[key]) return key;
  return logMap[key];
}

export default parseCategory;