import React from "react";
import { TaskAttempt } from "src/main/views/dashboard/management/Main/TaskAttempt";

interface SubmissionsProps extends React.PropsWithChildren {
  taskId?: string;
  workerId?: string;
  updateTerminateIds?: () => {};
  terminateIds?: string[];
}

const SubmissionsComponent: React.FC<SubmissionsProps> = (props) => {
  const { taskId, workerId } = props;
  
  return (
    <TaskAttempt taskId={taskId} workerId={workerId}   />
  );
};

export default SubmissionsComponent;
