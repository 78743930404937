import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import DateInput from "src/main/components/DateInput";

export interface DateSelectDialogDates {
  start?: Dayjs | null;
  end?: Dayjs | null;
}

interface DateSelectDialogProps extends Omit<DialogProps, "open"> {
  onConfirm: (dateParams: DateSelectDialogDates | null) => void;
  loading?: boolean;
  open?: boolean;
}

const DateSelectDialog: React.FC<DateSelectDialogProps> = (props) => {
  const { onClose, onConfirm, loading, open = false, ...dialogProps } = props;
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  };

  const confirm = () => {
    onConfirm({ start: start?.startOf("day"), end: end?.endOf("day") });
    onClose?.({}, "escapeKeyDown");
  };

  const handleChangeStart = (val: Dayjs | null) => {
    setStart(val);

    setIsOpenEnd(true);
  };

  return (
    <Dialog open={open} fullWidth {...dialogProps} onClose={_onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" pt={2}>
          <Typography variant="h6">Select time range</Typography>
        </Box>
        <Box
          pt={2}
          sx={{
            display: {
              lg: "flex",
            },
          }}
          gap={2}
        >
          <DateInput
            label="From"
            value={start}
            handleChange={handleChangeStart}
          />
          <DateInput
            label="Until"
            value={end}
            handleChange={setEnd}
            open={isOpenEnd}
            onClose={() => setIsOpenEnd(false)}
            onOpen={() => setIsOpenEnd(true)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          sx={{ color: "gray" }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          color="secondary"
          onClick={() => {
            setStart(null);
            setEnd(null);
          }}
        >
          Reset
        </LoadingButton>
        <LoadingButton
          loading={loading}
          onClick={() => confirm()}
          color="primary"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DateSelectDialog;
