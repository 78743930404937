import React from "react";
import { useNavigate } from "react-router-dom";
import { TaskModel } from "src/main/types";
import { Paths } from "src/main/utils";
import { TaskCard } from "./components";

interface WorkerTaskListProps {
  tasks: TaskModel[];
}

const WorkerTaskList: React.FC<WorkerTaskListProps> = (props) => {
  const { tasks } = props;
  const navigate = useNavigate();

  const navigateTask = (task: TaskModel) => {
    if (!task.ongoingAttempt)
      navigate(Paths.Portal.TaskDetail.replace(":taskId", task.id))
    else
      navigate(Paths.Portal.AttemptDetail.replace(":attemptId", task.ongoingAttempt.id))
  }

  return (
    <>
      {tasks.map(task => (
        <TaskCard task={task} key={task.id} onClick={() => navigateTask(task)} />
      ))}
    </>
  );
};

export default WorkerTaskList;
