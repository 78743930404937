import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, joinSx } from "src/main/utils";


interface BackButtonProps extends ButtonProps {
  label?: string;
  url?: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const { children, sx, label = "Back", url, ...rest } = props;
  const navigate = useNavigate();

  const onBack = () => {
    if (url) return navigate(url);
    navigate(-1)
  }

  return (
    <Button
      {...rest}
      onClick={() => onBack()}
      startIcon={<ChevronLeftIcon />}
      sx={joinSx(styles.root, sx)}
    >
      {label}
    </Button>
  );
};

const styles = createStyles({
  root: {
    pl: 0
  },
});

export default BackButton;
