import React from "react";
import { Page } from "src/main/components";
import TaskAttempt from "../TaskAttempt";


interface TaskAttemptPageProps extends React.PropsWithChildren {

}
const TaskAttemptPage: React.FC<TaskAttemptPageProps> = () => {

  return (
    <Page>
      <Page.TopSection title="All Task Submissions">
      </Page.TopSection>
      <TaskAttempt defaultStatus={"submitted"} />
    </Page>
  );
};

export default TaskAttemptPage;
