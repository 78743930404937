import { createSvgIcon } from '@mui/material/utils';

const Home = createSvgIcon(
  <svg viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.80005 4.79961C3.80005 4.48135 3.92648 4.17612 4.15152 3.95108C4.37656 3.72604 4.68179 3.59961 5.00005 3.59961H19.4C19.7183 3.59961 20.0235 3.72604 20.2486 3.95108C20.4736 4.17612 20.6 4.48135 20.6 4.79961C20.6 5.11787 20.4736 5.42309 20.2486 5.64814C20.0235 5.87318 19.7183 5.99961 19.4 5.99961H5.00005C4.68179 5.99961 4.37656 5.87318 4.15152 5.64814C3.92648 5.42309 3.80005 5.11787 3.80005 4.79961ZM3.80005 9.59961C3.80005 9.28135 3.92648 8.97612 4.15152 8.75108C4.37656 8.52604 4.68179 8.39961 5.00005 8.39961H19.4C19.7183 8.39961 20.0235 8.52604 20.2486 8.75108C20.4736 8.97612 20.6 9.28135 20.6 9.59961C20.6 9.91787 20.4736 10.2231 20.2486 10.4481C20.0235 10.6732 19.7183 10.7996 19.4 10.7996H5.00005C4.68179 10.7996 4.37656 10.6732 4.15152 10.4481C3.92648 10.2231 3.80005 9.91787 3.80005 9.59961V9.59961ZM3.80005 14.3996C3.80005 14.0813 3.92648 13.7761 4.15152 13.5511C4.37656 13.326 4.68179 13.1996 5.00005 13.1996H19.4C19.7183 13.1996 20.0235 13.326 20.2486 13.5511C20.4736 13.7761 20.6 14.0813 20.6 14.3996C20.6 14.7179 20.4736 15.0231 20.2486 15.2481C20.0235 15.4732 19.7183 15.5996 19.4 15.5996H5.00005C4.68179 15.5996 4.37656 15.4732 4.15152 15.2481C3.92648 15.0231 3.80005 14.7179 3.80005 14.3996ZM3.80005 19.1996C3.80005 18.8813 3.92648 18.5761 4.15152 18.3511C4.37656 18.126 4.68179 17.9996 5.00005 17.9996H19.4C19.7183 17.9996 20.0235 18.126 20.2486 18.3511C20.4736 18.5761 20.6 18.8813 20.6 19.1996C20.6 19.5179 20.4736 19.8231 20.2486 20.0481C20.0235 20.2732 19.7183 20.3996 19.4 20.3996H5.00005C4.68179 20.3996 4.37656 20.2732 4.15152 20.0481C3.92648 19.8231 3.80005 19.5179 3.80005 19.1996Z" />
  </svg>
  ,
  'Home'
);

export default Home;
