import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, CardHeaderProps, CardProps, Divider, Typography } from '@mui/material';
import { ReactNode } from "react";
import { Link as RouterLink, To } from "react-router-dom";
import { createStyles, joinSx } from 'src/main/utils';

interface DetailCardProps extends CardProps {
  children: ReactNode;
}

interface HeaderProps extends CardHeaderProps {
  header: string | ReactNode;
  subheader?: string | ReactNode;
  endComp?: ReactNode;
  routeTo?: To;
  routeLabel?: string;
}

const Header = (props: HeaderProps) => {
  const { subheader, header, endComp, routeTo, routeLabel, ...rest } = props;
  return (
    <CardHeader sx={joinSx(styles.header, props.sx)} {...rest} title={
      <Box display="flex" alignItems="center">
        <Box sx={styles.title} flex={1}>
          {typeof header === "string" ? (<Typography variant="h6">{header}</Typography>) : header}
          {typeof subheader === "string" ? (<Typography variant="subtitle2" color="text.secondary">{subheader}</Typography>) : subheader}
        </Box>
        {endComp}
        {routeTo !== undefined && (
          <Button component={RouterLink} to={routeTo} endIcon={<ArrowForward />} color="primary">
            {routeLabel}
          </Button>
        )}
      </Box>
    } />
  )
}

const styles = createStyles({
  title: {
    display: "flex",
    alignItems: "flex-start",
    minHeight: "43px", // route button has 42.5px height on md
    flexDirection: "column",
  },
  header: {
    pb: 0,
  }
})

const DetailCard = (props: DetailCardProps) => {
  const { children, variant = "outlined", ...other } = props;

  return (
    <Card
      variant={variant}
      {...other}
    >
      {children}
    </Card>
  );
};

DetailCard.Header = Header;
DetailCard.Divider = Divider;

export default DetailCard;
