import React, { useMemo, useState } from "react";
import { accountApi } from "src/main/api";
import { ListingComp, MonoLabel, Table } from "src/main/components";
import { Meta } from "src/main/types";
import { Box, IconButton, SxProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Paths } from "src/main/utils";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface WalletListingProps extends React.PropsWithChildren {
  workerId: string;
  sx?: SxProps;
}

const WalletHeader = [
  { value: "Balance" },
  { value: "Currency" },
  { value: "" },
]

const WalletListing: React.FC<WalletListingProps> = (props) => {
  const { workerId, sx } = props;
  const [meta, setMeta] = useState<Meta>({ offset: 0, limit: 10, count: 0 });
  const result = accountApi.useListWorkerWalletQuery({ meta, workerId });
  const walletListing = useMemo(() => result.data ?? undefined, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);

  return (
    <ListingComp
      loading={result.isLoading}
      title="Wallets"
      updateList={(newMeta) => { setMeta(newMeta) }}
      meta={persistMeta}
      sx={sx}
    >
      <Box p={1} />
      <ListingComp.Content>
        <Table>
          <Table.Head headers={WalletHeader} />
          <Table.Body>
            {walletListing?.entries?.map((wallet) => (
              <Table.Row>
                <Table.Cell value={<MonoLabel>{BigNumber(wallet.balance).toFixed(2)}</MonoLabel>} />
                <Table.Cell value={<MonoLabel>{wallet.currency}</MonoLabel>} />
                <Table.Cell align="right">
                  <IconButton
                    component={RouterLink}
                    to={Paths.Management.WalletDetail.replace(":workerId", workerId).replace(":walletId", wallet.id)}
                  >

                    <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ListingComp.Content>
    </ListingComp>
  );
};

export default WalletListing;
