import { createSvgIcon } from '@mui/material/utils';

const CashChecked = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.72345 4.12445C2.17666 3.67124 2.79135 3.41663 3.43229 3.41663H11.7656C12.4066 3.41663 13.0213 3.67124 13.4745 4.12445C13.9277 4.57766 14.1823 5.19235 14.1823 5.83329V6.74996H15.099C15.1942 6.74996 15.2888 6.75558 15.3825 6.76664L13.9354 8.24996H6.76562C6.52251 8.24996 6.28935 8.34654 6.11744 8.51845C5.94554 8.69035 5.84896 8.92351 5.84896 9.16663V14.1666C5.84896 14.4097 5.94554 14.6429 6.11744 14.8148C6.28935 14.9867 6.52251 15.0833 6.76562 15.0833H15.099C15.3421 15.0833 15.5752 14.9867 15.7471 14.8148C15.919 14.6429 16.0156 14.4097 16.0156 14.1666V11.1298L17.5156 9.59219V14.1666C17.5156 14.8076 17.261 15.4223 16.8078 15.8755C16.3546 16.3287 15.7399 16.5833 15.099 16.5833H6.76562C6.12469 16.5833 5.51 16.3287 5.05678 15.8755C4.60357 15.4223 4.34896 14.8076 4.34896 14.1666V13.25H3.43229C2.79135 13.25 2.17666 12.9953 1.72345 12.5421C1.27024 12.0889 1.01562 11.4742 1.01562 10.8333V5.83329C1.01562 5.19235 1.27024 4.57766 1.72345 4.12445ZM12.6823 6.74996H6.76562C6.12468 6.74996 5.51 7.00457 5.05678 7.45779C4.60357 7.911 4.34896 8.52569 4.34896 9.16663V11.75H3.43229C3.18918 11.75 2.95602 11.6534 2.78411 11.4815C2.6122 11.3096 2.51562 11.0764 2.51562 10.8333V5.83329C2.51562 5.59018 2.6122 5.35702 2.78411 5.18511C2.95602 5.0132 3.18918 4.91663 3.43229 4.91663H11.7656C12.0087 4.91663 12.2419 5.0132 12.4138 5.18511C12.5857 5.35702 12.6823 5.59018 12.6823 5.83329V6.74996ZM18.7711 6.87321C19.0603 6.57672 19.0545 6.10188 18.758 5.81264C18.4615 5.52339 17.9866 5.52927 17.6974 5.82576L11.1349 12.5527L8.94396 10.1087C8.66747 9.80023 8.1933 9.77434 7.88488 10.0508C7.57645 10.3273 7.55056 10.8015 7.82705 11.1099L10.5533 14.1511C10.6919 14.3057 10.8884 14.3959 11.096 14.4003C11.3035 14.4047 11.5036 14.3228 11.6486 14.1742L18.7711 6.87321Z" fill="currentColor" />
  </svg>
  ,
  'cashChecked'
);

export default CashChecked;