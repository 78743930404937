import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { Typography } from "src/main/components";
import { IntlFormatter } from "src/main/utils";

interface TaskAcceptConfirmDialogProps extends Omit<DialogProps, "open"> {
  onClickConfirm: () => void;
  open: boolean;
}
const TaskAcceptConfirmDialog: React.FC<TaskAcceptConfirmDialogProps> = (props) => {
  const { open = false, onClose, onClickConfirm, ...dialogProps } = props;

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }
  const confirm = () => {
    onClickConfirm();
    onClose?.({}, "escapeKeyDown");
  }

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={_onClose}>
      <DialogContent>
        <Typography formatId="taskAcceptConfirmComp.accept_this_task" variant="h5" mb={3}>Accept This Task?</Typography>
        <Typography variant="body2" lineHeight={1.375}>
          {IntlFormatter.getMessage("taskAcceptConfirmComp._if_accept_task", "If you accept this task")}, <Box component="span" fontWeight={700}>{IntlFormatter.getMessage("taskAcceptConfirmComp._must_finish_before_expires", "you must finish it before it expires")}</Box> , {IntlFormatter.getMessage("taskAcceptConfirmComp._will_lower_score", "or it will lower your quality score. (The timer will start after you accept the task.)")}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 2, pr: 3 }} >
        <Button
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          {IntlFormatter.getMessage("taskAcceptConfirmComp.cancel", "Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => { confirm() }}
        >
          {IntlFormatter.getMessage("taskAcceptConfirmComp.confirm", "Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TaskAcceptConfirmDialog;