import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Defaults } from "src/main/constants";
import {
  AccountCurrencyModel,
  AccountEntityModel,
  AccountModel,
  PersonModel,
  ProfileModel,
  SelfEntityModel,
  WorkerModel,
} from "src/main/types";
import { AuthToken, InitAuthPayload } from "./types";

export interface AuthState {
  oauth?: AuthToken | null;
  profile?: SelfEntityModel | null;
  workspace?: AccountEntityModel | null;
  worker?: WorkerModel | null;
  accounts?: AccountModel[] | null;
  currency?: AccountCurrencyModel | null;
  initTokenChecking: boolean;
}

const persistToken = (token: AuthToken | null) => {
  if (token?.accessToken)
    localStorage.setItem(Defaults.AuthTokenKey, token.accessToken);
  else localStorage.removeItem(Defaults.AuthTokenKey);
};

const initialState: AuthState = {
  oauth: undefined,
  profile: undefined,
  workspace: undefined,
  worker: undefined,
  accounts: undefined,
  currency: undefined,
  initTokenChecking: true,
};

const authSlice = createSlice({
  name: "@auth",
  initialState,
  reducers: {
    initAuthState(state, action: PayloadAction<InitAuthPayload | null>) {
      const {
        token = null,
        profile = null,
        workspace = null,
        worker = null,
        accounts = null,
        currency = null,
      } = action.payload ?? {};

      state.profile = profile;
      state.workspace = workspace;
      state.worker = worker;
      state.accounts = accounts;
      state.oauth = token;
      state.currency = currency;
      state.initTokenChecking = false;

      persistToken(token);
    },
    updateAuthToken(state, action: PayloadAction<AuthToken>) {
      state.oauth = action.payload;

      persistToken(action.payload);
    },
    logout(state, action: PayloadAction<void>) {
      state.oauth = null;
      state.profile = null;
      state.workspace = null;
      state.accounts = null;
      state.worker = null;
      state.currency = null;

      persistToken(null);
    },
    updateProfile(state, action: PayloadAction<ProfileModel | null>) {
      state.profile = action.payload?.self ?? null;
      state.workspace = action.payload?.workspace ?? null;
      state.accounts = action.payload?.accounts ?? null;
      state.worker = action.payload?.worker ?? null;
      state.currency = action.payload?.currency ?? null;
    },
    updateProfilePerson(
      state,
      action: PayloadAction<{
        data: PersonModel;
        refreshProfile?: boolean;
      }>
    ) {
      if (state.profile) {
        state.profile.person = action.payload.data;
      }
    },
  },
});

export default authSlice;
