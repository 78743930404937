const logMap = {
  "create credential": "Created new login credentials",
  "update credential": "Updated login credentials",
  "create administrator": "Created new Administrater",
  "create user": "Created new user",
  "create person": "Created new user",
  "update person": "Updated user details",
  "create tasker": "Created new Tasker",
  "update tasker": "Updated Tasker details",
  "update password": "Updated password",
  "reset password": "Resetted passwrd",
  "request reset password": "Requested password reset",
  "create account": "Created new Account",
  "transfer account ownership": "Ownership of account transferred",
  "create account entity": "Created new Account",
  "assign privilege to account": "Update privilege for account user",
  "create asset": "Created asset file",
  "create task": "Created new task",
  "update task": "Update task details",
  "duplicate task": "Task Duplicated",
  "create task objective": "Created task objectives",
  "update task objective": "Updated task objectives details",
  "duplicate task objective": "Duplicated task objectives",
  "duplicate task content": "Duplicated task content",
  "create task attempt": "Created task submission",
  "approved attempt": "Approved task submission",
  "submit attempt": "Submitted task attempt",
  "reject attempt": "Rejected task submission",
  "terminate attempt": "Terminate task submission",
  "submit proof": "Submitted proof",
  "create wallet": "Created new wallet",
  "mint wallet": "Mint wallet",
  "burn wallet": "Burn wallet",
  "transfer wallet": "Transfer wallet",
  "submitted withdraw request": "Submitted withdraw request",
  "processed withdraw request": "Processes withdraw request",
  "cancel withdraw request": "Cancel withdraw request",
  "create payout item": "Created payout",
  "approve payout item": "Approved payout",
  "process payout item": "Processed payout",
  "submit payout item": "Submitted payout",
  "cancel payout item": "Cancelled payout",
  "invite people to account platform": "Sent account invitation",
  "cancel invitation from the account platform": "Cancelled account invitation",
  "add persons into team": "Add tasker to team",
  "update persons in team": "Update taskers in team",
  "remove person from team": "Remove tasker from team",
  "create team": "Created new team",
  "update team": "Updated team details",
  "assign parent team": "Assign parent team",
  "onboard invitation to tasker": "Sent onboard invitation",
  "assign task to team": "Assign task to team",
  "unassign task from team": "Unassign task from team",
  "update task in team": "Update task in team",
  "withdrawal cancelled": "Withdrawal Cancelled",
  "withdrawal processed": "Withdrawal Processed",
  "withdrawal submitted": "Withdrawal Processing",
}

const parseLogMessage = (key?: string) => {
  if (!key) return "";
  if (!logMap[key]) return key;
  return logMap[key];
}

export default parseLogMessage;