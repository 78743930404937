import { createSvgIcon } from '@mui/material/utils';

const CursorClick = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.79564 1.14151C6.19572 1.03421 6.60703 1.27155 6.71433 1.67162L7.36183 4.08579C7.46913 4.48586 7.2318 4.89717 6.83172 5.00447C6.43165 5.11178 6.02034 4.87444 5.91304 4.47436L5.26554 2.0602C5.15823 1.66012 5.39557 1.24881 5.79564 1.14151ZM12.1553 2.84475C12.4482 3.13764 12.4482 3.61251 12.1553 3.90541L10.3869 5.67374C10.094 5.96663 9.61916 5.96663 9.32627 5.67374C9.03338 5.38085 9.03338 4.90597 9.32627 4.61308L11.0946 2.84475C11.3875 2.55185 11.8624 2.55185 12.1553 2.84475ZM1.14052 5.79585C1.24779 5.39577 1.65908 5.15839 2.05916 5.26566L4.47416 5.91316C4.87424 6.02043 5.11162 6.43172 5.00435 6.8318C4.89708 7.23189 4.48579 7.46926 4.08571 7.36199L1.67071 6.71449C1.27062 6.60722 1.03325 6.19593 1.14052 5.79585ZM6.9696 6.96975C7.17565 6.7637 7.48239 6.69565 7.75624 6.79523L16.9229 10.1286C17.2152 10.2349 17.4116 10.5105 17.4165 10.8215C17.4214 11.1325 17.2339 11.4143 16.9451 11.5298L13.8345 12.774L17.1969 16.1364C17.4898 16.4293 17.4898 16.9042 17.1969 17.1971C16.904 17.49 16.4292 17.49 16.1363 17.1971L12.7739 13.8347L11.5296 16.9453C11.4141 17.2341 11.1324 17.4216 10.8214 17.4166C10.5104 17.4117 10.2347 17.2153 10.1284 16.923L6.79509 7.75638C6.69551 7.48253 6.76356 7.17579 6.9696 6.96975ZM8.75401 8.75415L10.8666 14.5639L11.8036 12.2215C11.8798 12.031 12.0308 11.8799 12.2214 11.8037L14.5637 10.8668L8.75401 8.75415ZM5.67251 9.32616C5.96554 9.61892 5.96577 10.0938 5.67301 10.3868L3.90635 12.1552C3.61359 12.4482 3.13872 12.4484 2.84569 12.1557C2.55265 11.8629 2.55243 11.388 2.84519 11.095L4.61185 9.32666C4.90461 9.03363 5.37948 9.03341 5.67251 9.32616Z" fill="currentColor" />
  </svg>

  ,
  'CursorClick'
);

export default CursorClick;
