import { createSvgIcon } from '@mui/material/utils';

const ClipBoard = createSvgIcon(
  <svg viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 3.12158H3C2.46957 3.12158 1.96086 3.3323 1.58579 3.70737C1.21071 4.08244 1 4.59115 1 5.12158V17.1216C1 17.652 1.21071 18.1607 1.58579 18.5358C1.96086 18.9109 2.46957 19.1216 3 19.1216H13C13.5304 19.1216 14.0391 18.9109 14.4142 18.5358C14.7893 18.1607 15 17.652 15 17.1216V16.1216M5 3.12158C5 3.65202 5.21071 4.16072 5.58579 4.5358C5.96086 4.91087 6.46957 5.12158 7 5.12158H9C9.53043 5.12158 10.0391 4.91087 10.4142 4.5358C10.7893 4.16072 11 3.65202 11 3.12158M5 3.12158C5 2.59115 5.21071 2.08244 5.58579 1.70737C5.96086 1.3323 6.46957 1.12158 7 1.12158H9C9.53043 1.12158 10.0391 1.3323 10.4142 1.70737C10.7893 2.08244 11 2.59115 11 3.12158M11 3.12158H13C13.5304 3.12158 14.0391 3.3323 14.4142 3.70737C14.7893 4.08244 15 4.59115 15 5.12158V8.12158M17 12.1216H7M7 12.1216L10 9.12158M7 12.1216L10 15.1216" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  ,
  'ClipBoard'
);

export default ClipBoard;
