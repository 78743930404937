import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import {
  AppBar, AppBarProps,
  Badge, BadgeProps,
  Box,
  Button,
  ButtonBase,
  IconButton,
  Toolbar,
  useTheme
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useMemo, useRef, useState } from "react";
import { AvatarIcon, MenuIcon } from "src/assets";
import { authApi } from "src/main/api";
import { RootState } from "src/main/store";
import { Meta, SelfEntityModel } from "src/main/types";
import { createStyles } from "src/main/utils";
import { AccountPopover } from "./components";



interface TopBarProps extends AppBarProps {
  onOpenSideBar?: () => void;
  person?: RootState | SelfEntityModel;
}

const TopBarRoot = styled(AppBar)(
  ({ theme }) => ({
    ...(
      theme.palette.mode === 'light'
        ? {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.neutral?.[500],
          boxShadow: theme.shadows[3]
        }
        : {
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          boxShadow: 'none'
        }
    )
  })
);
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const TopBar: React.FC<TopBarProps> = (props) => {
  const { onOpenSideBar, person } = props;
  const [meta] = useState<Meta>({ count: 0, limit: 1000, offset: 0 })
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const result = authApi.usePendingNotificationQuery({ meta, ...person });
  const pendingInvite = useMemo(() => result.data ?? undefined, [result.data]);
  const muiTheme = useTheme();

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <TopBarRoot
      sx={{
        left: {
          lg: 280
        },
        width: {
          lg: 'calc(100% - 280px)'
        }
      }}
    >
      <Toolbar
        disableGutters
        sx={styles.toolbar}
      >
        <IconButton
          onClick={onOpenSideBar}
          sx={styles.iconButton}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        {/* <Box ml={2} flexDirection="row" alignItems="center" justifyContent="center">
          <Typography sx={styles.brand} variant="h4">
            gig rewards
          </Typography>
        </Box> */}
        <Box sx={{ flexGrow: 1 }} />

        <Box
          ref={anchorRef}
          component={ButtonBase}
          onClick={handleOpenPopover}
          sx={{
            alignItems: 'center',
            display: 'flex',
            ml: 2
          }}
        >
          <StyledBadge
            overlap="circular"
            badgeContent={pendingInvite?.entries?.length}
            color="primary"
          >
            <Button
              size="large"
              sx={{ color: muiTheme.palette.text.secondary }}
              startIcon={<AvatarIcon style={{ fontSize: 40 }} sx={{ marginRight: '-10px' }} />}
              endIcon={<ChevronDownIcon fontSize='large' />}
            >
            </Button>
          </StyledBadge>
        </Box>
      </Toolbar>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
        notifyCount={pendingInvite?.entries?.length}
      />
    </TopBarRoot>
  );
};

const styles = createStyles({
  iconButton: {
    display: {
      xs: 'inline-flex',
      lg: 'none'
    },
    color: "inherit",
  },
  brand: {
    fontSize: "1.5rem",
  },
  toolbar: {
    minHeight: 64,
    left: 0,
    px: 2
  },
});

export default TopBar;
