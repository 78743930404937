import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, CardProps, IconButton, Typography, Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DetailCard, EmptyState, Page, Table, TableRowWrap } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { TeamModel } from "src/main/types";
import { Paths, createStyles } from "src/main/utils";
import { SetParentDialog, TeamInfoCard, UpdateTeamWorkerDialog } from "./components";

interface TeamDetailProps extends Omit<CardProps, "children"> {
  team: TeamModel;
}

const WORKER_LIST_HEADERS = [
  { value: "Worker" },
  { value: "Status" },
  { value: "" }
]

const TeamDetail: React.FC<TeamDetailProps> = (props) => {
  const { team } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateWorker, setOpenUpdateWorker] = useState(false);
  const results = accountApi.useListTeamWorkersQuery({ teamId: team.id, meta: { count: 0, limit: 1000000, offset: 0 } })
  const teamWorkers = useMemo(() => results.data?.entries ?? [], [results.data?.entries]);

  const styles = createStyles({
    root: {},
    boxSection: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      gap: 2,
    }
  })

  if (!team) return <EmptyState message="No team details" />

  return (
    <Page.Content>
      <Box flex={1}>
        <TeamInfoCard team={team} />
      </Box>
      <Box sx={styles.boxSection}>
        <DetailCard>
          <DetailCard.Header
            header="Parent team"
            endComp={(
              <Button onClick={() => setOpenDialog(true)} variant="contained">
                Change
              </Button>
            )}
          />
          <DetailCard.Divider />
          <Table>
            <Table.Body>
              <TableRowWrap
                label="name"
                value={team.parentTeam?.name}
              />
            </Table.Body>
          </Table>
        </DetailCard>
        <DetailCard>
          <DetailCard.Header
            header="Team Taskers"
            endComp={(
              <Button onClick={() => setOpenUpdateWorker(true)} variant="contained" >
                Update
              </Button>
            )}
          />
          <DetailCard.Divider />
          {!!teamWorkers?.length && (

            <Table>
              <Table.Head headers={WORKER_LIST_HEADERS} />
              <Table.Body>
                {teamWorkers.map((worker) => (
                  <Table.Row
                    sx={{ '& .MuiTableCell-root': { padding: 1, paddingX: 2 } }}
                  >
                    <Table.Cell>
                      <Box display="flex" flexDirection="column" >
                        <Typography variant="body1">{worker.person.firstname}</Typography>
                        <Typography variant="body2" color="text.secondary">{worker.person.primaryEmail}</Typography>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <StatusChip status={worker.status} />
                    </Table.Cell>
                    <Table.Cell align="right">
                      <IconButton
                        component={RouterLink}
                        to={Paths.Management.WorkerDetail.replace(":workerId", worker.id)}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
          {!teamWorkers?.length && (
            <EmptyState message="No Taskers" />
          )}
        </DetailCard>
      </Box>
      <SetParentDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        team={team}
      />
      <UpdateTeamWorkerDialog
        open={openUpdateWorker}
        onClose={() => setOpenUpdateWorker(false)}
        team={team}
        teamWorkers={teamWorkers}
        maxWidth="lg"
        loading={results.isLoading}
      />
    </Page.Content>
  );
};

export default TeamDetail;
