import { Box, BoxProps, styled, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import React from "react";
import { joinSx } from "src/main/utils";
import { PageContent } from "./components";

interface PageProps extends React.PropsWithChildren {
  sx?: SxProps<Theme>;
}

const DetailBoxProps = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 16,
  gap: 16,
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

const Page = ({ children, sx, ...rest }: PageProps) => {

  return (
    <Box sx={joinSx(styles.root, sx)} {...rest}>
      {children}
    </Box>
  );
}

Page.TopSection = PageContent.TopSection;
Page.Content = DetailBoxProps;

const styles: { [index: string]: SxProps } = {
  root: {
  }
}

export default Page;
