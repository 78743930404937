import { Container } from '@mui/material';
import React, { useMemo } from "react";
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { EmptyTasksGraphic } from 'src/assets';
import { workerApi } from 'src/main/api';
import { LoadingContainer, PortalLayout, WorkerAttemptList } from 'src/main/components';
import { createStyles, packageFormatMessage, Paths } from "src/main/utils";

interface HistoryPageProps extends React.PropsWithChildren {

}

const HistoryPage: React.FC<HistoryPageProps> = (props) => {
  const navigate = useNavigate();
  const result = workerApi.useWorkerAttemptsQuery({ status: "approved,terminated,cancelled", limit: "100" });
  const intl = useIntl();

  const attempts = useMemo(() => result.data?.entries ?? [], [result.data]);
  return (
    <Container sx={styles.container}>
      <PortalLayout.SectionTitle>{intl.formatMessage(packageFormatMessage("taskHistoryPage.task_history", "TASK HISTORY"))}</PortalLayout.SectionTitle>
      <LoadingContainer loading={result.isLoading}>
        <WorkerAttemptList attempts={attempts} />
      </LoadingContainer>

      {attempts.length === 0 && !result.isUninitialized && (
        <PortalLayout.EmptyState
          cta={intl.formatMessage(packageFormatMessage("taskHistoryPage.accept_task_now", "Accept Task Now"))}
          graphic={(<EmptyTasksGraphic style={{ height: "100px", marginBottom: "1rem", opacity: "0.7" }} />)}
          label={intl.formatMessage(packageFormatMessage("taskHistoryPage.you_have_not_completed_any_task_yet", "You have not completed any task yet."))}
          onClick={() => navigate(Paths.Portal.Home)}
        />
      )}
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },
  cta: {
    marginTop: 4,
  },
});

export default HistoryPage;
