import { Box, Table, TableBody, FormControl, InputLabel, Select, OutlinedInput, SelectChangeEvent, MenuItem } from "@mui/material";
import React from "react";
import { DetailCard, InputField, TableRowWrap } from "src/main/components";
import { SimpleMap } from "src/main/utils";

interface WorkerInformationProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  handleChange?: (e: string | React.ChangeEvent<any>) => void;
  errors?: SimpleMap<string | undefined>;
  touched?: SimpleMap<Boolean | undefined>;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  createMode?: boolean;
}

const WORKER_STATUS = [
  { value: "active", label: "Active" },
  { value: "suspended", label: "Suspended" }
]
// TODO: Fix select auto resize issue when changed value.
const WorkerInformation = (props: WorkerInformationProps) => {
  const { values, setFieldValue, handleChange, handleBlur, errors, createMode } = props;
  return (
    <Box flex={1} pb={2}>
      <DetailCard>
        <DetailCard.Header header="Tasker Information" />
        <DetailCard.Divider />
        <Box gap={2} >
          <Table>
            <TableBody>
              {!createMode && (
                <TableRowWrap
                  label="Status"
                  value={
                    <Box display="flex">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink></InputLabel>
                        <Select
                          input={<OutlinedInput notched />}
                          value={values.status + ""}
                          onChange={(ev: SelectChangeEvent) => {
                            if (setFieldValue) {
                              setFieldValue("status", ev.target.value, true);
                            }
                          }}
                        >
                          {WORKER_STATUS.map((option) => (
                            <MenuItem value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  }
                />
              )}

              <TableRowWrap label="Remark" value={
                <InputField
                  multiline
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remark}
                  name="remark"
                  error={!!errors?.remark}
                />
              } />
            </TableBody>
          </Table>
        </Box>
      </DetailCard>
    </Box>
  )
}

export default WorkerInformation;
