import { Box, Button, CardProps, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { workerApi } from 'src/main/api';
import { RootState } from 'src/main/store';
import { AuthState } from 'src/main/store/auth/slice';
import { Paths, createStyles, joinSx, IntlFormatter } from "src/main/utils";
import { Typography } from "src/main/components";

interface OverviewProps extends Omit<CardProps, "children"> {

}

const PotentialEarning: React.FC<OverviewProps> = (props) => {
  const { sx, ...boxProps } = props;
  const muiTheme = useTheme();
  const navigate = useNavigate();
  const { currency } = useSelector<RootState, AuthState>(state => state.auth);
  const potentialEarningQuery = workerApi.useWorkerPotentialEarningQuery({});
  const potentialEarnings = useMemo(() => potentialEarningQuery.data?.earnings, [potentialEarningQuery.data])

  return (
    <Box sx={joinSx(styles.root, sx)} {...boxProps}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography formatId="overview.getPotentialEarnings" variant="body2" color="text.secondary" defaultMessage="Get potential earnings:">
          <Typography variant="body2" ml={0.5} display='inline' fontWeight="bold" color="text.primary">+{potentialEarnings?.totalPotentialEarnings.toFormat(0)}</Typography>
          <Typography variant="body2" ml={0.5} display='inline' color="text.primary">{currency?.default}</Typography>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={joinSx({ background: muiTheme.palette.primary.gradient }, styles.button)}
          onClick={() => navigate(Paths.Portal.TasksAvailable)}
        >
          {IntlFormatter.getMessage("overview.viewAllAvailableTasks", "View all available tasks")}
        </Button>
      </Box>
    </Box >
  );
};

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    color: 'primary.contrastText',
    width: {
      xs: "100%",
      lg: 200,
    },
    lineHeight: "normal"
  },
});

export default PotentialEarning;