import {
  AssistantPhoto, AutoAwesome, AutoFixHigh, EmojiEvents, Favorite, Fingerprint, FmdGood,
  Group, Https, RocketLaunch, Send, StarRate, ThumbUpAlt, Timer, Verified
} from "@mui/icons-material";
import { Box, BoxProps, Breakpoint, Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { publicApi } from "src/main/api";
import { useAsyncTask } from "src/main/hooks";
import { AffiliatePageComponent, AffiliatePageConfig, AffiliatePageMeta } from "src/main/types";
import { createStyles, joinSx, SimpleMap } from "src/main/utils";
import { ShareImage } from "./components";

interface LegacyShareBrandProps extends BoxProps {
  affiliateComponents?: AffiliatePageComponent[];
  affiliateMeta?: AffiliatePageMeta;
  minHeight?: number;
}
type Params = {
  affiliateKey: string;
  brand: string;
}

const ICONS: SimpleMap<ReactNode> = {
  rocket: <RocketLaunch />,
  start: <StarRate />,
  stars: <AutoAwesome />,
  wand: <AutoFixHigh />,
  verified: <Verified />,
  flag: <AssistantPhoto />,
  trophy: <EmojiEvents />,
  heart: <Favorite />,
  fingerprint: <Fingerprint />,
  mapMarker: <FmdGood />,
  group: <Group />,
  lock: <Https />,
  send: <Send />,
  like: <ThumbUpAlt />,
  timer: <Timer />,
}

// TODO: refactor sharebrand and legacysharebrand
const LegacyShareBrand: React.FC<LegacyShareBrandProps> = (props) => {
  const { affiliateComponents, affiliateMeta, minHeight, sx, ...rest } = props;
  const [queryPageContent, request] = publicApi.useLazyQueryPageContentQuery();
  const { affiliateKey, brand } = useParams<Params>();
  const [pageContent, setPageContent] = useState<AffiliatePageConfig | null>(null);
  const [runQueryContent, loading, error] = useAsyncTask("public/share/page/content");

  useEffect(() => {
    if (!brand || (affiliateComponents && affiliateMeta)) return;
    runQueryContent(async () => {
      const result = await queryPageContent({ affiliateKey, affiliatePageKey: brand });
      if ("data" in result) {
        setPageContent(result.data?.page ?? null);
      }
    });
  }, [runQueryContent, queryPageContent, affiliateComponents, affiliateMeta, affiliateKey, brand]);

  const {
    isNotFound
  } = useMemo(() => {
    const isNotFound = request.isSuccess && !request.data?.page;
    return {
      isNotFound,
    }
  }, [request.data?.page, request.isSuccess]);

  const { components, meta } = useMemo(() => {
    if (affiliateComponents && affiliateMeta) return { components: affiliateComponents, meta: affiliateMeta };

    if (pageContent) {
      return { components: pageContent.components, meta: pageContent.meta };
    }
    return {};
  }, [affiliateComponents, affiliateMeta, pageContent])

  return (
    <Container disableGutters>
      {meta && !affiliateComponents && (
        <Helmet>
          <title>{meta?.title}</title>
        </Helmet>
      )}
      <Box {...rest} sx={joinSx(styles.root, { minHeight: minHeight, backgroundPositionX: "center", backgroundSize: "cover", backgroundImage: `url(${meta?.backgroundImageSrc})` }, sx)}>
        {!!meta?.logoImageSrc && (
          <Box display="flex" justifyContent="center">
            <ShareImage
              aspectRatio={1}
              src={meta?.logoImageSrc}
              maxWidth={"100px"}
            />
          </Box>
        )}
        {components?.map((component, compIndex) => (
          <Container key={compIndex} disableGutters maxWidth={component.maxWidth as Breakpoint | undefined ?? "md"}>
            <Grid sx={styles.component} container spacing={1} mt={`${component.marginTop}px`} mb={`${component.marginBottom}px`}>
              {component.type === "button" && component.buttons.map((button, index) => (
                <Grid key={`${compIndex}/${index}`} item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      fullWidth
                      variant={button.variant ?? "contained"}
                      size={button.size}
                      href={button.link}
                      startIcon={ICONS[button.startIcon ?? ""]}
                      endIcon={ICONS[button.endIcon ?? ""]}
                      disableElevation={false}
                      sx={{ borderRadius: "24px" }}
                    >
                      {button.label}
                    </Button>
                  </Box>
                </Grid>
              ))}
              {component.type === "image" && component.images.map((image, index) => (
                <Grid key={`${compIndex}/${index}`} item xs={12 / component.images.length}>
                  <ShareImage
                    aspectRatio={image.aspectRatio}
                    link={image.link}
                    src={image.src}
                    maxWidth={image.maxWidth?.toString().concat("px")}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        ))}

        {(isNotFound || error) && (
          <Box display="flex" mt={8} alignItems="center" flexDirection="column">
            <Typography>Page not found</Typography>
            <Button href="/">Return to Task4coins</Button>
          </Box>
        )}

        {loading && (
          <Box sx={styles.loaderContainer}>
            <CircularProgress sx={styles.loader} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

const styles = createStyles({
  root: {
    p: 1,
  },
  component: {
    paddingBottom: 1,
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    marginBottom: 4,
  },
});

export default LegacyShareBrand;
