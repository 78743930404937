import { CancelOutlined } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

interface CancelInviteButtonProps extends IconButtonProps {
  inviteId: string;
  onCancelConfirm: (passfunc) => void;
  loading?: boolean;
}

const CancelInviteButton: React.FC<CancelInviteButtonProps> = (props) => {
  const { inviteId, onCancelConfirm, loading, ...rest } = props;

  const _onCancelConfirm = () => {
    onCancelConfirm(inviteId);
  }

  return (
    <IconButton onClick={_onCancelConfirm} disabled={loading} {...rest}>
      <CancelOutlined />
    </IconButton>
  );
};

export default CancelInviteButton;