import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, Typography, Checkbox } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { ListingComp, Table, DetailCard } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { useAsyncTask } from "src/main/hooks";
import { Meta, TeamModel, WorkerModel } from "src/main/types";
import { handleFormikApiResponse } from "src/main/utils";

interface UpdateTeamWorkerDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;
  team: TeamModel;
  teamWorkers: WorkerModel[];
  loading?: boolean;
}

const STATUS_OPTION = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "onboarded", label: "Onboarded" },
  { value: "pendingonboard", label: "Pending" },
  { value: "suspended", label: "Suspended" },
]

const WORKER_LIST_HEADERS = [
  { value: "Worker" },
  { value: "Status" },
  { value: "" }
]

const UpdateTeamWorkerDialog: React.FC<UpdateTeamWorkerDialogProps> = (props) => {
  const { team, onClose, open = false, teamWorkers, loading, ...dialogProps } = props;

  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })
  const result = accountApi.useListWorkerQuery({ meta });
  const workerList = useMemo(() => result.data?.entries ?? undefined, [result.data?.entries]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);



  const [addTeamPersons] = accountApi.useCreateTeamPersonsMutation();
  const [removeTeamPersons] = accountApi.useDeleteTeamPersonsMutation();

  const [runAddTeamPerson] = useAsyncTask("add/team/person");
  const [runRemoveTeamPerson] = useAsyncTask("remove/team/person");


  const onAdd = useCallback((teamPersonId: string) => {
    runAddTeamPerson(async () => {
      const result = await addTeamPersons({ teamId: team.id, data: { personIds: [teamPersonId] } })

      const { error } = handleFormikApiResponse(result);

      if (error) {
        return toast.error(error.data?.error?.message ?? "");
      } else toast.success("Added!", { duration: 1000 });

    })
  }, [addTeamPersons, runAddTeamPerson, team])

  const onRemove = useCallback((teamPersonId: string) => {
    runRemoveTeamPerson(async () => {
      const result = await removeTeamPersons({ teamId: team.id, data: { personIds: [teamPersonId] } })

      const { error } = handleFormikApiResponse(result);

      if (error) {
        return toast.error(error.data?.error?.message ?? "");
      } else toast.success("Removed!", { duration: 1000, style: { color: "GrayText" } });
    })
  }, [removeTeamPersons, runRemoveTeamPerson, team])

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  const addRemoveWorker = useCallback((worker: WorkerModel) => {
    const hasWorker = teamWorkers.findIndex((wker) => wker.id === worker.id);
    if (hasWorker >= 0) {
      onRemove(worker.personId)
    } else {
      onAdd(worker.personId);
    }
  }, [teamWorkers, onRemove, onAdd])

  const onStatusUpdate = (status: string) => {
    let newMeta = { ...persistMeta };
    delete newMeta.onboardAt;
    delete newMeta.status;
    if (status === "all") {
    } else if (status === "pendingonboard") {
      newMeta.onboardAt = "null"
    } else if (status === "onboarded") {
      newMeta.onboardAt = "0,"
    } else if (status === "suspended") {
      newMeta.status = status
    } else {
      newMeta.onboardAt = "0,"
      newMeta.status = status
    }

    setMeta({ ...newMeta, offset: 0 });
  }

  const getWorkerStatus = (worker: WorkerModel) => {
    if (worker.onboardAt) return worker.status;
    if (worker.status === "suspended") return worker.status;
    return "pending";
  }

  return (
    <Dialog open={open} fullWidth {...dialogProps} onClose={_onClose} scroll="body">
      <DialogContent sx={{ p: 0 }}>
        <DetailCard.Header sx={{ pb: 0 }}
          header="Update Team Taskers"
          endComp={(
            <Typography variant="body1">Selected Taskers: {teamWorkers.length}</Typography>
          )}
        />
        <DetailCard.Divider />
        <ListingComp
          title="All Taskers"
          loading={result.isLoading || loading}
          updateList={(newMeta) => setMeta(newMeta)}
          meta={persistMeta}
          sx={{ m: 0 }}
        >
          <ListingComp.FilterSection>
            <ListingComp.Searchbar />
            <ListingComp.Statusbar options={STATUS_OPTION} onStatusUpdate={onStatusUpdate} />
          </ListingComp.FilterSection>
          <ListingComp.Content>
            <Table tableStyle={{ size: "small" }} >
              <Table.Head headers={WORKER_LIST_HEADERS} />
              <Table.Body>
                {workerList?.map((worker) => (
                  <Table.Row
                    selected={!!teamWorkers.find((wker) => wker.id === worker.id)}
                    onClick={() => addRemoveWorker(worker)}
                    sx={{ '& .MuiTableCell-root': { padding: 1 }, cursor: "pointer" }}
                  >
                    <Table.Cell>
                      <Box display="flex" flexDirection="column" px={1}>
                        <Typography variant="body1">{worker.person.firstname}</Typography>
                        <Typography variant="body2" color="text.secondary">{worker.person.primaryEmail}</Typography>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <StatusChip status={getWorkerStatus(worker)} />
                    </Table.Cell>
                    <Table.Cell align="center">
                      <Checkbox checked={!!teamWorkers.find((wker) => wker.id === worker.id)} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </ListingComp.Content>
        </ListingComp>

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ mr: 2, flexShrink: 0 }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default UpdateTeamWorkerDialog;
