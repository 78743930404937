import React, { useMemo } from "react";
import { Typography } from "src/main/components";
import {
  TaskAttemptModel,
  TaskObjectiveModel,
  TaskProofModel,
} from "src/main/types";
import { BaseContent } from "../BaseContent";

interface RejectContentProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
}

type ProofWithObjective = { objective?: TaskObjectiveModel } & TaskProofModel;

const RejectContent: React.FC<RejectContentProps> = (props) => {
  const { attempt } = props;

  const { mainReason, proofs } = useMemo(() => {
    const mainReason = attempt?.rejectReason;
    let proofs: ProofWithObjective[] = [];

    if (attempt?.taskProofs) {
      attempt?.taskProofs.forEach((proof) => {
        let objective = attempt?.task?.taskObjectives?.find(
          (obj) => obj.id === proof.taskObjectiveId
        );

        proofs.push({ ...proof, objective });
      });
    }

    proofs = proofs
      .filter((proof) => !!proof.rejectReason)
      .sort(
        (a, b) =>
          (a.objective?.sortPriority ?? 0) - (b.objective?.sortPriority ?? 0)
      );

    return {
      mainReason,
      proofs,
    };
  }, [attempt]);

  return (
    <BaseContent status="warning">
      {!!mainReason && (
        <Typography
          formatId="rejectContentComp.overall_feedback"
          variant="body2"
        >
          Overall feedback: {mainReason}
        </Typography>
      )}
      {!!proofs?.length && (
        <Typography
          formatId="rejectContentComp.detailed_feedback"
          variant="body2"
          fontWeight={700}
          sx={{ mt: 2 }}
        >
          Detailed Feedback:
        </Typography>
      )}
      {!!proofs?.length &&
        proofs?.map((proof, index) => (
          <Typography variant="body2" sx={{ mt: !!index ? 2 : 0 }}>
            Goal #{proof.objective?.sortPriority} {proof.rejectReason}
          </Typography>
        ))}
    </BaseContent>
  );
};

export default RejectContent;
