import { MailOutline } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Card, CardContent, Container, InputAdornment, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { authApi } from 'src/main/api';
import { AuthLayout, FormField, Typography } from "src/main/components";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { Paths, commonStyles, createStyles, handleApiResponse, joinSx, IntlFormatter } from "src/main/utils";
import * as Yup from "yup";

interface PasswordRequestProps extends React.PropsWithChildren {

}

interface FormState {
  emailAddress: string;
}
const initialFormState: FormState = {
  emailAddress: "",
}
const Field = FormField<FormState>;
const validationSchema = Yup.object().shape({
  emailAddress: Yup.string().email("Email is invalid.").required("Email is required"),
});
const titleMessages = [
  {
    "titleFormatId": "passwordRequestPage._reset_password_title",
    "messageFormatId": "passwordRequestPage._reset_password_message",
    "title": "Reset Password",
    "message": "Enter the email linked to your account. You will receive instructions in the email to reset your password."
  },
  {
    "titleFormatId": "passwordRequestPage._check_inbox_title",
    "messageFormatId": "passwordRequestPage._reset_password_message",
    "title": "Check Your Inbox",
    "message": `If an account exists, you will receive an email with instructions to reset your password. If it doesn't arrive, be sure to check your spam folder.`
  },
]
const PasswordRequest: React.FC<PasswordRequestProps> = (props) => {
  const navigate = useNavigate();
  const [formStates, { onValueChange, consumeAPIError, setError, validate }] = useFormState(initialFormState, validationSchema);
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitPasswordRequest] = authApi.usePasswordRequestMutation();
  const [runPasswordRequest, loading, error] = useAsyncTask("password/request");
  const { palette } = useTheme();
  const [resendCount, setResendCount] = useState(0);
  const passwordRequestSuccessMessage = IntlFormatter.getMessage("passwordRequestPage._password_reset_request_submitted", "Success! Password reset request submitted.");

  const onNavigateLogin = () => {
    navigate(Paths.Auth.Login);
  }

  const onSubmit = useCallback(() => {
    runPasswordRequest(async () => {
      setError({});
      if (validate()) return;

      const result = await submitPasswordRequest({
        email: formStates.input.emailAddress,
      });

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null)
          return;
        throw new Error(error.data?.error?.message)
      }

      setIsSuccess(true);
      setResendCount(60);
      toast.success(passwordRequestSuccessMessage);
    });
  }, [runPasswordRequest, submitPasswordRequest, consumeAPIError, formStates.input, setError, validate, passwordRequestSuccessMessage]);

  const timeOutCallback = useCallback(() => setResendCount(currTimer => currTimer - 1), []);

  useEffect(() => {
    resendCount > 0 && setTimeout(timeOutCallback, 1000);
  }, [resendCount, timeOutCallback]);

  return (
    <Container sx={joinSx(commonStyles.authContainer, styles.root)} maxWidth="sm" >
      <Card>
        <AuthLayout.CardBanner />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            py={0}
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Typography formatId={titleMessages[!isSuccess ? 0 : 1]["titleFormatId"]} variant="h5">
                {titleMessages[!isSuccess ? 0 : 1]["title"]}
              </Typography>
              <Typography formatId={titleMessages[!isSuccess ? 0 : 1]["messageFormatId"]} variant="body2" color="textSecondary" mt={1}>
                {titleMessages[!isSuccess ? 0 : 1]["message"]}
              </Typography>
            </div>
          </Box>
        </CardContent>

        <CardContent sx={{ p: 3, pt: 0 }}>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {!isSuccess && (
              <form onSubmit={(event) => event.preventDefault()}>
                <Field
                  label={IntlFormatter.getMessage("passwordRequestPage.email_address", "Email Address")}
                  name="email-address"
                  type="email"
                  fieldKey="emailAddress"
                  onValueChange={onValueChange}
                  formStates={formStates}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutline fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                {typeof error?.message === "string" && (
                  <Typography color="red">{error.message}</Typography>
                )}
                <Box mt={2}>
                  <LoadingButton
                    fullWidth
                    loading={loading}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                    sx={{ background: palette.gradient?.main }}
                  >
                    {IntlFormatter.getMessage("passwordRequestPage.send_instructions", "Send Instructions")}
                  </LoadingButton>
                </Box>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: "center" }}>
                  <Button onClick={() => navigate(Paths.Auth.Login)}>{IntlFormatter.getMessage("passwordRequestPage.back_to_log_in", "Back to Log In")}</Button>
                </Box>
              </form>
            )}
            {isSuccess && (
              <>
                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                  <Typography formatId="passwordRequestPage.didnt_receive_email">Didn't receive email?</Typography>
                  <Button disabled={resendCount !== 0} onClick={onSubmit}>
                    {resendCount !== 0 ? `${IntlFormatter.getMessage("passwordRequestPage.resend", "Resend")} (${resendCount.toString()}s)` : `${IntlFormatter.getMessage("passwordRequestPage.resend", "Resend")}`}
                  </Button>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onNavigateLogin}
                  sx={{ background: palette.gradient?.main, mt: 2 }}
                >
                  {IntlFormatter.getMessage("passwordRequestPage.back_to_log_in", "Back to Log In")}
                </Button>
              </>
            )}
          </Box>
        </CardContent>
        <AuthLayout.EnvStatus />
      </Card>
    </Container>
  );
};

const styles = createStyles({
  root: {
  },
});

export default PasswordRequest;
