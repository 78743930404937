import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, IconButton, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from "src/main/api";
import { ListingComp, Page, Table } from "src/main/components";
import { Meta } from "src/main/types";
import { Paths, truncate } from "src/main/utils";

interface TeamPageProps extends React.PropsWithChildren {
}

const TEAM_LIST_HEADERS = [
  { value: "Name" },
  { value: "Description" },
  { value: "Parent" },
  { value: "" },
]

const TeamPage: React.FC<TeamPageProps> = (props) => {
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })
  const result = accountApi.useListTeamQuery({ meta });
  const teamList = useMemo(() => result?.data ?? undefined, [result.data]);

  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);

  return (
    <Page>
      <Page.TopSection title="Teams">
        <Button
          component={RouterLink}
          to={Paths.Management.CreateTeam}
          size="large"
          type="submit"
          variant="contained"
        >
          <Typography color="#fff">Create New Team</Typography>
        </Button>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading} title="Teams"
        updateList={(newMeta) => { setMeta(newMeta) }}
        meta={persistMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          {!!teamList?.entries.length && (
            <Table>
              <Table.Head headers={TEAM_LIST_HEADERS} />
              <Table.Body>
                {teamList?.entries.map((team) => (
                  <Table.Row>
                    <Table.Cell>{team.name}</Table.Cell>
                    <Table.Cell sx={{ wordBreak: "break-word" }}>{team.description}</Table.Cell>
                    <Table.Cell>{team.parentTeam?.name ?? truncate(team.parentTeamId, 0) ?? "-"}</Table.Cell>
                    <Table.Cell align="right">
                      <IconButton
                        component={RouterLink}
                        to={Paths.Management.TeamDetail.replace(":teamId", team.id)}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default TeamPage;
