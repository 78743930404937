import { Theme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManagementLayout } from "src/main/components";
import { RootState } from "src/main/store";
import { SelfEntityModel } from "src/main/types";

interface MainDashboardLayoutProps extends React.PropsWithChildren {

}

const MainDashboardLayout: React.FC<MainDashboardLayoutProps> = (props) => {
  const { children } = props;
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const navigate = useNavigate();

  const [openSideBar, setOpenSideBar] = useState(lg);
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>(state => state.auth.profile);

  useEffect(() => {
    if (profile && profile.person?.worker) {
      navigate("/portal");
    } else if (profile === null) {
      navigate("/auth");
    }
  }, [navigate, profile]);

  if (!profile) return null;

  return (
    <ManagementLayout.Container>
      <ManagementLayout.SideBar
        open={openSideBar} onClose={() => setOpenSideBar(false)}
        variant={lg ? "permanent" : "temporary"}
      />
      <ManagementLayout.TopBar onOpenSideBar={() => setOpenSideBar(true)} person={profile} />
      <ManagementLayout.Content
        sx={{ paddingLeft: lg ? "280px" : 0, paddingTop: "64px", }}
      >
        {children}
      </ManagementLayout.Content>
    </ManagementLayout.Container>
  );
};

export default MainDashboardLayout;
