import { BuilderProp, require200Status } from "src/main/api/account";
import { QueryResult } from "src/main/types";
import { SimpleMap } from "src/main/utils";


export interface NotionValidRequest {
  url: string;
}
export interface NotionValidResponse {
  result: SimpleMap<any>;
}

export const notionUrl = (builder: BuilderProp) => {
  return builder.mutation<NotionValidResponse, NotionValidRequest>({
    invalidatesTags: ["misc"],
    query: ({ url }) => ({
      url: `/account/misc/notion/link`,
      method: "POST",
      body: { url },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<NotionValidResponse>) => {
      console.log("/account/misc/notion/link", response)
      return response.result;
    },
  });
}

export interface InvalidateNotionUrlRequest {
  url: string;
}

export const invalidateNotionUrl = (builder: BuilderProp) => {
  return builder.mutation<NotionValidResponse, InvalidateNotionUrlRequest>({
    invalidatesTags: ["misc"],
    query: ({ url }) => ({
      url: `/account/misc/notion/invalidate`,
      method: "POST",
      body: { url },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<NotionValidResponse>) => {
      console.log("/account/misc/notion/invalidate", response)
      return response.result;
    },
  });
}

export interface GetCurrencyListRequest {
}

export interface GetCurrencyListResponse {
  currencies: SimpleMap<string>;
}
export const getCurrencyList = (builder: BuilderProp) => {
  return builder.query<GetCurrencyListResponse, GetCurrencyListRequest>({
    providesTags: ["misc"],
    query: () => ({
      url: `/account/withdraw/currency/list`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<GetCurrencyListResponse>) => {
      return response.result
    }
  })
}

export interface GetAffiliateLinkResponse {
  affiliate: string;
}
export const getAffiliateLink = (builder: BuilderProp) => {
  return builder.query<GetAffiliateLinkResponse, void>({
    providesTags: ["misc"],
    query: () => ({
      url: `/account/misc/info`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<GetAffiliateLinkResponse>) => {
      return response.result
    }
  })
} 