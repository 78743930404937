import { Box, Button, Card, CardContent, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { AffiliateButtonComps, AffiliateImageComps, FormField, Page } from "src/main/components";
import { useFormState } from "src/main/hooks";
import {
  AccountVariableModel, AffiliatePageButtonComponent,
  AffiliatePageComponent, AffiliatePageImageComponent,
  AffiliatePageMeta, AffiliatePageModel
} from "src/main/types";
import { handleApiResponse, Paths, SimpleMap } from "src/main/utils";
import ShareBrand from "src/main/views/affiliate/Main/ShareBrand";

interface AffiliatePageCreateProps extends React.PropsWithChildren {

}
export interface FormState {
  brandKey: string;
  name: string;
  title: string;
  logoImageSrc: string;
  backgroundImageSrc: string,
}

const initialFormState: FormState = {
  brandKey: "",
  name: "",
  title: "",
  logoImageSrc: "",
  backgroundImageSrc: "",
}

const Field = FormField<FormState>;

export type AffImageCompWithErr = AffiliatePageImageComponent & { updateErr?: (updates: SimpleMap<string>) => void };
export type AffButtonCompWithErr = AffiliatePageButtonComponent & { updateErr?: (updates: SimpleMap<string>) => void };

const AffiliatePageCreateComponent: React.FC<AffiliatePageCreateProps> = (props) => {
  const navigate = useNavigate();
  const result = accountApi.useListAccountVariableQuery({ meta: { count: 0, limit: 1000, offset: 0 } });
  const accountVariables: AccountVariableModel<any>[] = useMemo(() => result.data?.entries ?? [], [result.data?.entries]);
  const [row1, setRow1] = useState<AffImageCompWithErr | null>(null);
  const [row2, setRow2] = useState<AffButtonCompWithErr | null>(null);
  const [row3, setRow3] = useState<AffImageCompWithErr | null>(null);
  const [row4, setRow4] = useState<AffImageCompWithErr | null>(null);

  const [errors, setErrors] = useState<SimpleMap<string>>({});

  const clearError = (key: string) => {
    setErrors((prev) => {
      let newPrev = { ...prev };
      delete newPrev[key];
      return { ...newPrev, }
    })
  }

  const updateErrorField = (key: string, message: string) => {
    setErrors((prev) => {
      return {
        ...prev,
        [key]: message
      }
    })
  }

  const brandKeyOptions = useMemo(() => {
    if (!accountVariables) return [];

    let newOptions: SimpleMap<string>[] = [];

    accountVariables.forEach((accVar) => {
      if ("items" in accVar.content) {
        accVar.content.items.forEach((item: SimpleMap<string>) => {
          newOptions.push({ value: item.key ?? "", label: item.label ?? "", key: item.key ?? "" });
        })
      }
    })

    return newOptions
  }, [accountVariables])

  const [createAffiliatePage, { isLoading: isSubmitLoading }] = accountApi.useCreateAffiliatePageMutation();
  const [formStates, { onValueChange }] = useFormState(initialFormState);

  const { meta, components } = useMemo(() => {

    let meta: AffiliatePageMeta = {
      backgroundImageSrc: formStates.input.backgroundImageSrc,
      title: formStates.input.title,
      logoImageSrc: formStates.input.logoImageSrc,
    };

    const comps: AffiliatePageComponent[] = [];

    if (!!row1?.images.length) {
      comps.push(row1)
    }

    if (!!row2?.buttons.length) {
      comps.push(row2)
    }

    if (!!row3?.images.length) {
      comps.push(row3)
    }

    if (!!row4?.images.length) {
      comps.push(row4)
    }

    return { components: comps, meta };
  }, [formStates.input, row1, row2, row3, row4]);

  const onSubmit = useCallback(async () => {
    try {
      if (Object.keys(errors).length) return toast.error("Please resolve all the errors.");
      if (!components.length) return toast.error("Please select at least 1 component.");

      let meta: SimpleMap<string> = {
        title: formStates.input.title,
      }
      if (formStates.input.backgroundImageSrc) meta.backgroundImageSrc = formStates.input.backgroundImageSrc;
      if (formStates.input.logoImageSrc) meta.logoImageSrc = formStates.input.logoImageSrc;

      let config = {
        meta: meta,
        components: components,
      }
      const data = {
        brandKey: formStates.input.brandKey,
        name: formStates.input.name,
        config: config
      }
      const result = await createAffiliatePage({ data });
      const { data: parsedData, error } = handleApiResponse<AffiliatePageModel>(result);

      if (parsedData) {
        toast.success("configPage created");
        navigate(Paths.Management.AffiliatePageDetail.replace(":affiliatePageId", parsedData.id));
      }

      if (error) {
        if (error.data?.error) {
          let err = error.data?.error;
          let newErr = { ...errors };

          if (err.errors) {
            Object.entries(err.errors).forEach(([key, value]) => {
              if (!key.includes("components[")) {
                newErr[key] = value.msg ?? ""
              } else if (key.includes("images[")) {

                const newArr = key.split(/[[\].]/).filter(key => key !== "")
                let compimg = config[newArr[1]]?.[newArr[2]];
                compimg?.updateErr({ [`${newArr[3]}[${newArr[4]}].${newArr[5]}`]: value.msg });
              } else if (key.includes("buttons[")) {

                const newArr = key.split(/[[\].]/).filter(key => key !== "")
                let compimg = config[newArr[1]]?.[newArr[2]];
                compimg?.updateErr({ [`${newArr[3]}[${newArr[4]}].${newArr[5]}`]: value.msg });

              } else {

                const newArr = key.split(/[[\].]/).filter(key => key !== "")
                let compimg = config[newArr[1]]?.[newArr[2]];
                compimg?.updateErr({ [`${newArr[3]}`]: value.msg });
              }
            })

            setErrors(newErr);
          }

        } else {
          if ((error as any).data?.error?.message === "brand key conflict") {
            setErrors({ ...errors, brandKey: "Brand key conflict" });
          }
        }

        toast.error((error as any).message ?? (error as any).data?.error?.message ?? "Error creating affiliate page!");
      }
    } catch (error) {
      toast.error("Error creating affiliate page!");
      console.error(error);
    }

    // eslint-disable-next-line
  }, [createAffiliatePage, components, formStates.input]);

  return (
    <Page>
      <Page.TopSection title="Create New Landing Page">
        <Box>
          <Button
            fullWidth
            size="large"
            variant="contained"
            disabled={isSubmitLoading}
            onClick={() => onSubmit()}
          >
            Create
          </Button>
        </Box>
      </Page.TopSection>
      <Page.Content>
        <Card sx={{ flex: 1 }}>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box gap={2}>
              <Typography variant="h5">Affiliate Page Info</Typography>
              <Box display="flex" gap={2}>
                <Box minWidth="200px">
                  <FormControl sx={{ mt: 2 }} fullWidth required variant="outlined">
                    <InputLabel>Brand</InputLabel>
                    <Select
                      label="Brand Key"
                      required
                      value={formStates.input.brandKey}
                      fullWidth
                      error={!!errors["brandKey"]}
                      onChange={(ev) => {
                        clearError("brandKey");
                        onValueChange("brandKey")(ev.target.value);
                      }}
                    >
                      <MenuItem disabled value="default" color="textSecondary">Select a brand</MenuItem>
                      {brandKeyOptions.map((brands) => (
                        <MenuItem value={brands.key}>{brands.label}</MenuItem>
                      ))}
                    </Select>
                    {<FormHelperText error={!!errors["brandKey"]}>{errors["brandKey"]}</FormHelperText>}
                  </FormControl>
                </Box>
                <Box flex={1}>
                  <Field
                    label="Name"
                    type="text"
                    fieldKey="name"
                    placeholder="Enter a name"
                    helperText={errors["name"] ?? "For internal use only"}
                    formStates={formStates}
                    onValueChange={onValueChange}
                    clearErrorOnChange={() => clearError("name")}
                    // onChange={(ev) => { if (ev.target.value) clearError("name") }}
                    onBlur={() => {
                      if (formStates.input.name) {
                        clearError("name");
                      } else {
                        updateErrorField("name", "Name is required");
                      }
                    }}
                    required
                    error={!!errors["name"]}

                  />
                </Box>
              </Box>
              <Field
                label="Page Title"
                type="text"
                fieldKey="title"
                placeholder="Page title shown on explorer tab"
                formStates={formStates}
                onValueChange={onValueChange}
                clearErrorOnChange={() => clearError("config.meta.title")}
                required
                error={!!errors["config.meta.title"]}
                helperText={errors["config.meta.title"]}
                onBlur={() => {
                  if (formStates.input.title) {
                    clearError("config.meta.title");
                  } else {
                    updateErrorField("config.meta.title", "Title is required");
                  }
                }}
              />

              <Box my={2}><Divider /></Box>
              <Typography variant="h5">Background and Logo</Typography>
              <Field
                label="Background Image"
                type="text"
                fieldKey="backgroundImageSrc"
                placeholder="Page title shown on explorer tab"
                formStates={formStates}
                onValueChange={onValueChange}
                clearErrorOnChange={() => clearError("config.meta.backgroundImageSrc")}
                error={!!errors["config.meta.backgroundImageSrc"]}
                helperText={errors["config.meta.backgroundImageSrc"]}
                onBlur={() => {
                  if (formStates.input.backgroundImageSrc) {
                    clearError("config.meta.backgroundImageSrc");
                  }
                }}
              />

              <Field
                label="Logo"
                type="text"
                fieldKey="logoImageSrc"
                placeholder="Logo at the top center of page"
                formStates={formStates}
                onValueChange={onValueChange}
                clearErrorOnChange={() => clearError("config.meta.logoImageSrc")}
                error={!!errors["config.meta.logoImageSrc"]}
                helperText={errors["config.meta.logoImageSrc"]}
                onBlur={() => {
                  if (formStates.input.logoImageSrc) {
                    clearError("config.meta.logoImageSrc");
                  }
                }}
              />
            </Box>
            <Box my={2}><Divider /></Box>
            <Box>
              <AffiliateImageComps aspectRatio={1} rowIndex={1} onCompnentChange={(ev) => { setRow1(ev) }} />
              <Box my={2}><Divider /></Box>
              <AffiliateButtonComps rowIndex={2} onCompnentChange={(ev) => { setRow2(ev) }} />
              <Box my={2}><Divider /></Box>
              <AffiliateImageComps aspectRatio={1} rowIndex={3} onCompnentChange={(ev) => { setRow3(ev) }} />
              <Box my={2}><Divider /></Box>
              <AffiliateImageComps aspectRatio={2} rowIndex={4} indexBypass={4} maxComps={1} onCompnentChange={(ev) => { setRow4(ev) }} />
            </Box>
          </CardContent>
        </Card>
        <Box flex={1}>
          <ShareBrand minHeight={600} affiliateMeta={meta} affiliateComponents={components} />
        </Box>
      </Page.Content>
    </Page >
  );
};

export default AffiliatePageCreateComponent; 
