import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface ActionButtonProps extends ButtonProps {
  loading?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { disabled = false, loading = false, fullWidth = true, size = "large", onClick = () => { }, variant = "contained", children, sx, ...rest } = props;
  return (
    <Button
      onClick={onClick}
      disabled={loading || disabled}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      sx={sx}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
