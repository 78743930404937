import { createSvgIcon } from '@mui/material/utils';

const Switch = createSvgIcon(
  <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33325 13.1667L4.66658 9.83333M4.66658 4.83333H14.6666H4.66658ZM14.6666 4.83333L11.3333 1.5L14.6666 4.83333ZM14.6666 4.83333L11.3333 8.16667L14.6666 4.83333ZM11.3333 13.1667H1.33325H11.3333ZM1.33325 13.1667L4.66658 16.5L1.33325 13.1667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  ,
  'Switch'
);

export default Switch;
