import { Box, BoxProps } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, BoxProps {

}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...boxProps } = props;
  return (
    <Box {...boxProps} sx={styles.root}>
      {children}
    </Box>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 8,
    zIndex: 2,
    paddingBottom: {
      xs: 11,
      md: 3,
    },
    marginLeft: {
      lg: "280px",
    }
  },
});

export default Content;
