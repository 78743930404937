import { Box, BoxProps, Switch, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { InputField } from "src/main/components";
import {
  RocketLaunch, StarRate, AutoAwesome, AutoFixHigh, Verified,
  AssistantPhoto, EmojiEvents, Favorite, Fingerprint, FmdGood,
  Group, Https, Send, ThumbUpAlt, Timer
} from "@mui/icons-material";
import { createStyles, SimpleMap } from "src/main/utils";
import { AffiliatePageButton } from "src/main/types";

interface ButtonTemplateProps extends BoxProps {
  inputKey: string;
  buttonContent: AffiliatePageButton;
  setInput: (prev) => void;
  usable?: boolean;
  toggleDisable?: () => void;
  index: number;
  errors?: SimpleMap<string>;
  updateErrorArr?: React.Dispatch<React.SetStateAction<SimpleMap<SimpleMap<string>>>>;
}

const IconOption = [
  { label: "None", value: "none" },
  { label: "Rocket", value: "rocket", icon: <RocketLaunch fontSize="small" />, iconComp: RocketLaunch },
  { label: "Start", value: "start", icon: <StarRate />, iconComp: RocketLaunch },
  { label: "Stars", value: "stars", icon: <AutoAwesome />, iconComp: RocketLaunch },
  { label: "Wand", value: "wand", icon: <AutoFixHigh />, iconComp: RocketLaunch },
  { label: "Verified", value: "verified", icon: <Verified />, iconComp: RocketLaunch },
  { label: "Flag", value: "flag", icon: <AssistantPhoto />, iconComp: RocketLaunch },
  { label: "Trophy", value: "trophy", icon: <EmojiEvents />, iconComp: RocketLaunch },
  { label: "Heart", value: "heart", icon: <Favorite />, iconComp: RocketLaunch },
  { label: "Finger Print", value: "fingerprint", icon: <Fingerprint />, iconComp: RocketLaunch },
  { label: "Map Marker", value: "mapMarker", icon: <FmdGood />, iconComp: RocketLaunch },
  { label: "Group", value: "group", icon: <Group />, iconComp: RocketLaunch },
  { label: "Lock", value: "lock", icon: <Https />, iconComp: RocketLaunch },
  { label: "Send", value: "send", icon: <Send />, iconComp: RocketLaunch },
  { label: "Like", value: "like", icon: <ThumbUpAlt />, iconComp: RocketLaunch },
  { label: "Timer", value: "timer", icon: <Timer />, iconComp: RocketLaunch },
]

const ButtonTemplate: React.FC<ButtonTemplateProps> = (props) => {
  const { updateErrorArr, errors, title, usable, toggleDisable, inputKey, buttonContent, setInput, index } = props;

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={2}>
      <Box display="flex" justifyContent="space-between" justifyItems="center">
        <Typography variant="h6">{title}</Typography>
        <Switch checked={usable} onChange={() => {
          toggleDisable?.();
          if (usable) {
            updateErrorArr?.((prev) => {
              let newPrev = { ...prev };
              delete newPrev[index]
              return newPrev
            })
          }
        }} />
      </Box>
      {usable && (
        <>
          <Box>
            <FormControlLabel
              control={(
                <Checkbox checked={buttonContent.useReferralLink} onChange={(ev, check) => {
                  setInput((prev) => {
                    let newButtons = [...prev[inputKey]];
                    newButtons[index] = { ...newButtons[index], useReferralLink: check };

                    return {
                      ...prev,
                      [inputKey]: newButtons,
                    }
                  })
                }} />
              )}
              label={
                <Typography>Use Tasker’s referral link for this brand</Typography>
              }
            />
          </Box>
          {buttonContent.useReferralLink && (
            <Box>
              <InputField
                InputProps={{
                  readOnly: true
                }}
                label="Link"
                placeholder="<Tasker’s referral link>"
              />
            </Box>
          )}
          <Box>
            <InputField
              disabled={!usable}
              label={`${buttonContent.useReferralLink ? "Fallback " : ""}Link`}
              placeholder="https://…"
              required
              error={!!errors?.link}
              helperText={errors?.link}
              value={buttonContent.link}
              onChange={(ev) => {
                if (!!ev.target.value) {
                  updateErrorArr?.((prev) => {
                    let newPrev = { ...prev };
                    delete newPrev[index]?.link;
                    return newPrev
                  })
                }
                setInput((prev) => {
                  let newButtons = [...prev[inputKey]];
                  newButtons[index] = { ...newButtons[index], link: ev.target.value };
                  return {
                    ...prev,
                    [inputKey]: newButtons
                  }
                })
              }}
            />
          </Box>
          <Box flex={1}>
            <InputField
              disabled={!usable}
              label="Label"
              placeholder="Text on button"
              required
              value={buttonContent.label}
              onChange={(ev) => {
                if (!!ev.target.value) {
                  updateErrorArr?.((prev) => {
                    let newPrev = { ...prev };
                    delete newPrev[index]?.label;
                    return newPrev
                  })
                }
                setInput((prev) => {
                  let newButtons = [...prev[inputKey]];
                  newButtons[index] = { ...newButtons[index], label: ev.target.value };
                  return {
                    ...prev,
                    [inputKey]: newButtons
                  }
                })
              }}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box flex={1}>
              <FormControl disabled={!usable} variant="outlined" fullWidth>
                <InputLabel shrink>Front Icon</InputLabel>
                <Select
                  input={<OutlinedInput notched label="Front Icon" />}
                  value={buttonContent.startIcon ?? "none"}
                  sx={styles.select}
                  // IconComponent={
                  //   IconOption.find((opt) => opt.value === buttonContent.startIcon)?.iconComp
                  // }
                  onChange={(ev) => {
                    setInput((prev) => {
                      let newButtons = [...prev[inputKey]];
                      newButtons[index] = { ...newButtons[index], startIcon: ev.target.value };

                      if (ev.target.value === "none") {
                        delete newButtons[index].startIcon;
                      }
                      return {
                        ...prev,
                        [inputKey]: newButtons
                      }
                    })
                  }}
                >
                  {IconOption.map((opt) => (
                    <MenuItem value={opt.value} sx={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
                      <Typography variant="body1">{opt.label}</Typography> {opt.icon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl disabled={!usable} variant="outlined" fullWidth>
                <InputLabel shrink>Back Icon</InputLabel>
                <Select
                  input={<OutlinedInput notched label="Back Icon" />}
                  value={buttonContent.endIcon ?? "none"}
                  sx={styles.select}
                  onChange={(ev) => {
                    setInput((prev) => {
                      let newButtons = [...prev[inputKey]];
                      newButtons[index] = { ...newButtons[index], endIcon: ev.target.value };

                      if (ev.target.value === "none") {
                        delete newButtons[index].endIcon;
                      }
                      return {
                        ...prev,
                        [inputKey]: newButtons
                      }
                    })
                  }}
                >
                  {IconOption.map((opt) => (
                    <MenuItem value={opt.value} sx={{ justifyContent: "space-between" }}>{opt.label} {opt.icon}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </>
      )}
    </Box >
  );
};

const styles = createStyles({
  select: {
    paddingY: "12px",
    paddingX: "10px",
    '& .MuiSelect-select': {
      p: 0,
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
    }
  },
});

export default ButtonTemplate;
