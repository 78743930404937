import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Card, CardContent, Container, useTheme, InputAdornment, Checkbox } from '@mui/material';
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authApi, accountApi } from 'src/main/api';
import { AuthLayout, FormField, Typography } from "src/main/components";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { AuthToken, RootState } from 'src/main/store';
import auth from "src/main/store/auth";
import preference from "src/main/store/preference";
import { SelfEntityModel } from 'src/main/types';
import { commonStyles, createStyles, handleApiResponse, joinSx, Paths, IntlFormatter } from "src/main/utils";
import * as Yup from "yup";
import FormControlLabel from '@mui/material/FormControlLabel';
import { PersonOutlineOutlined, LockOutlined } from "@mui/icons-material";
import { Messages } from "src/main/utils/msg";

interface LoginProps extends React.PropsWithChildren {

}

interface FormState {
  accessKey: string;
  secret: string;
}

const initialFormState: FormState = {
  accessKey: "",
  secret: "",
}
const Field = FormField<FormState>;

const LoginPage: React.FC<LoginProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessKeyErrorState, setAccessKeyErrorState] = useState<boolean>(false);
  const [secretErrorState, setSecretErrorState] = useState<boolean>(false);

  const profile = useSelector<RootState, SelfEntityModel | undefined | null>(state => state.auth.profile);
  const username = useSelector<RootState, string | null>(state => state.preference.username);
  const validationSchema = Yup.object().shape({
    accessKey: Yup.string().required(IntlFormatter.getMessage("formValidation._user_name_required", "Username is required")),
    secret: Yup.string().required(IntlFormatter.getMessage("formValidation._password_required", "Password is required"))
  });
  const initTokenChecking = useSelector<RootState, boolean>(state => state.auth.initTokenChecking);
  const [formStates, { onValueChange, consumeAPIError, setError, validate }] = useFormState(initialFormState, validationSchema);

  const [rememberMe, SetRememberMe] = useState<boolean>(false);
  const [accessTokenMutation] = authApi.useAccessTokenMutation();
  const [authenticateQuery] = authApi.useLazyAuthenticateQuery();
  const [getAffiliateLinkQuery] = accountApi.useLazyGetAffiliateLinkQuery();

  const [runLoginSubmit, loading] = useAsyncTask("login/submit");
  const onUpdateRememberMe = (ev: boolean) => {
    SetRememberMe(ev);
    if (!ev)
      dispatch(preference.slice.actions.setUsername(''))
  }

  useEffect(() => {
    if (username) {
      SetRememberMe(true);
      initialFormState.accessKey = username;
    }
    else
      initialFormState.accessKey = "";

    if (profile?.person?.worker) {
      navigate("/portal");
    } else if (profile) {
      navigate("/overview");
    }
  }, [navigate, profile, username])

  const onSubmit = useCallback(() => {
    runLoginSubmit(async () => {
      setError({});
      setAccessKeyErrorState(false);
      setSecretErrorState(false);

      if (validate()) return;

      const result = await accessTokenMutation({
        grantType: "password",
        accessKey: formStates.input.accessKey.trim(),
        secret: formStates.input.secret,
      });

      const { data, error } = handleApiResponse<AuthToken>(result);

      const errorData = error?.data?.error;
      if (errorData) {
        if (consumeAPIError(errorData) !== null)
          return;

        const errorMessage = Messages[errorData?.message];
        if (errorMessage) {
          setAccessKeyErrorState(true);
          setSecretErrorState(true);

          setError({
            secret: errorMessage
          });
        }

        throw new Error(errorData?.message)
      }

      let isAccount = false;
      if (data) {
        let authToken = data;
        const authenticateResult = await authenticateQuery(authToken.accessToken);
        if (authenticateResult.data?.accounts?.length && !authenticateResult.data?.workspace) {
          const accountLoginResult = await accessTokenMutation({
            grantType: "password",
            accessKey: formStates.input.accessKey.trim(),
            secret: formStates.input.secret,
            account: authenticateResult.data.accounts[0].id,
          });

          if ("data" in accountLoginResult) {
            isAccount = true;
            authToken = accountLoginResult.data;
          }
        }
        dispatch(auth.slice.actions.updateAuthToken(authToken));

        if (isAccount) {
          try {
            const affiliateResult = await getAffiliateLinkQuery();
            const { data: affiliateData, error: affiliateError } = affiliateResult;
            if (affiliateData && affiliateData.affiliate) {
              dispatch(preference.slice.actions.setAffiliateLink(affiliateData.affiliate));
            } else if (affiliateError) {
              toast.error("Error retrieving affiliate link");
            }
          } catch (error) {
          }
        }

        if (rememberMe)
          dispatch(preference.slice.actions.setUsername(formStates.input.accessKey.trim()));

        let successMessage = "Login success! Welcome back";
        if (authenticateResult.data?.self?.person?.firstname)
          successMessage += `, ${authenticateResult.data.self.person.firstname}!`;
        else
          successMessage += ".";

        toast.success(successMessage);
      }
    });
  }, [runLoginSubmit, dispatch, accessTokenMutation, authenticateQuery, consumeAPIError, setError, validate, formStates.input, getAffiliateLinkQuery, rememberMe]);

  const { palette } = useTheme();

  // Flashing the login page when the user is already logged in 
  if(initTokenChecking || profile) return null;

  return (
    <Container sx={joinSx(commonStyles.authContainer, styles.root)} maxWidth="sm">
      <Card sx={joinSx(styles.card)}>
        <AuthLayout.CardBanner />
        <CardContent sx={styles.cardContent}>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Field
                label={IntlFormatter.getMessage("loginPage.username", "Username")}
                name="username"
                type="string"
                fieldKey="accessKey"
                formStates={formStates}
                onValueChange={onValueChange}
                error={formStates.error.accessKey ? true : (accessKeyErrorState ? true : false)}

                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlined
                        fontSize="small"
                        color={accessKeyErrorState ? "error" : "inherit"}
                      />
                    </InputAdornment>
                  )
                }}
              />

              <Field
                label={IntlFormatter.getMessage("loginPage.password", "Password")}
                name="password"
                type="password"
                fieldKey="secret"
                formStates={formStates}
                onValueChange={onValueChange}
                error={formStates.error.secret ? true : (secretErrorState ? true : false)}

                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined
                        fontSize="small"
                        color={secretErrorState ? "error" : "inherit"}
                      />
                    </InputAdornment>
                  )
                }}
              />

              <Box mt={2} px={1} sx={styles.box}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={formStates.input.accessKey.trim().length === 0}
                      checked={rememberMe}
                      onChange={(ev, check) => {
                        onUpdateRememberMe(check)
                      }}
                    />
                  )}
                  label={(
                    <Typography formatId="loginPage.remember_me" variant="body2" sx={styles.rememberMe}>Remember Me</Typography>
                  )}
                />
                <Button color="primary" sx={styles.forgetPassword} onClick={() => navigate(Paths.Auth.RequestPassword)}>{IntlFormatter.getMessage("loginPage.forgot_password", "Forgot Password")}?</Button>
              </Box>

              <Box mt={2}>
                <LoadingButton
                  fullWidth
                  loading={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={onSubmit}
                  sx={{ background: palette.primary?.gradient }}
                >
                  {IntlFormatter.getMessage("loginPage.log_in", "Log In")}
                </LoadingButton>
              </Box>
            </form>
            <AuthLayout.EnvStatus />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

const styles = createStyles({
  root: {
  },
  card: {
    alignItems: "center"
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
    py: 0,
    px: 3,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rememberMe: {
    "&.MuiTypography-root": {
      whiteSpace: "nowrap",
    }
  },
  forgetPassword: {
    "&.MuiButton-root": {
      whiteSpace: "nowrap",
    }
  }
});

export default LoginPage;
