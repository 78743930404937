import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { LoadingContainer, Page, Tabs } from "src/main/components";
import { WorkerModel } from "src/main/types";
import { Paths } from "src/main/utils";
import { TaskAttempt } from "../../TaskAttempt";
import { DetailInfo, ReferralLinksEdit, WalletListing } from "./components";

interface WorkerDetailProps extends React.PropsWithChildren {
}

const tabs = [
  { value: "details", label: "Details" },
  { value: "variables", label: "Variables" },
  { value: "submissions", label: "Submission" },
  { value: "wallets", label: "Wallets" },
]

const WorkerDetail: React.FC<WorkerDetailProps> = (props) => {
  const { workerId = "" } = useParams();
  const result = accountApi.useGetWorkerQuery({ workerId });
  const { search } = useLocation();
  const navigate = useNavigate();
  const worker: WorkerModel | undefined = useMemo(() => result?.data?.model ?? undefined, [result?.data?.model]);

  const tabVal = useMemo(() => {
    const vals = new URLSearchParams(search)

    if (vals.get("tab")) return vals.get("tab")
    return "details"
  }, [search]);

  const updateTab = (tab: string) => {
    navigate(Paths.Management.WorkerDetail.replace(":workerId", workerId) + `?tab=${tab}`, { replace: true });
  }


  return (
    <Page>
      <Page.TopSection title="Detail">
        {worker && tabVal === "details" && (
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={() => navigate(Paths.Management.WorkerEdit.replace(":workerId", workerId))}
          >
            <EditIcon />&nbsp;<Typography>Edit</Typography>
          </Button>
        )}
      </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        {worker && (
          <Box>
            <Tabs value={tabVal} updateTab={updateTab} tabs={tabs} sx={{ paddingX: 2 }} />
            <Page.Content padding={2} gap={2} display="flex">
              {tabVal === "details" && (
                <DetailInfo worker={worker} />
              )}
              {tabVal === "variables" && (
                <ReferralLinksEdit worker={worker} />
              )}
              {tabVal === "submissions" && (
                <TaskAttempt workerId={workerId} sx={{ flex: 1, m: 0, }} />
              )}
              {tabVal === "wallets" && (
                <WalletListing workerId={workerId} sx={{ flex: 1, m: 0, }} />
              )}
            </Page.Content>
          </Box>
        )}
      </LoadingContainer>
    </Page>
  );
};

export default WorkerDetail; 
