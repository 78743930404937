import { ChevronRightOutlined } from "@mui/icons-material";
import { Badge, Box, BoxProps, Card, CardContent, SxProps } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { workerApi } from "src/main/api";
import { LoadingContainer, Typography } from "src/main/components";
import { BN_ZERO, CurrencyDecimals } from "src/main/constants";
import { Currency } from "src/main/types";
import { Paths, commonStyles, createStyles, joinSx } from "src/main/utils";

interface WithdrawRequestsProps extends BoxProps {
  cardSx?: SxProps
}

const currency = Currency.PHP;
const styles = createStyles({
  root: {
    cursor: "pointer",
  }
})

const WithdrawRequests: React.FC<WithdrawRequestsProps> = (props) => {
  const { sx, cardSx, ...boxProps } = props;
  const withdrawsResult = workerApi.useWorkerWithdrawListQuery({ meta: { status: "submitted", currency: "PHP" } });
  const navigate = useNavigate();

  const { count, value } = useMemo(() => {
    const withdraws = withdrawsResult.data?.entries ?? [];
    const count = withdraws.length;
    const value = withdraws.reduce((accum, withdraw) => accum.plus(withdraw.amount), BN_ZERO);
    return { count, value };
  }, [withdrawsResult])

  return (
    <LoadingContainer loading={withdrawsResult.isLoading} sx={sx} {...boxProps}>
      <Card onClick={() => navigate(Paths.Portal.WithdrawHistoryList + `?tab=submitted`)} sx={joinSx(cardSx, styles.root)}>
        <CardContent sx={{ ...commonStyles.cardContent, padding: 1 }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2" fontWeight={700}>
              {value.toFormat(CurrencyDecimals[currency])} {currency}
            </Typography>
            <Typography formatId="payoutPage.withdrawals_processing" ml={1} variant="caption" color="text.secondary">
              Withdrawals Processing
            </Typography>
            <Box flex={1} />
            {!!count && <Badge color="primary" badgeContent={count}></Badge>}
            <ChevronRightOutlined sx={{ color: "neutral.500", ml: count > 99 ? 2 : 1 }} />
          </Box>
        </CardContent>
      </Card>
    </LoadingContainer>
  );
};

export default WithdrawRequests;
