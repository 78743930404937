import { createSvgIcon } from '@mui/material/utils';

const Chat = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66667 9.99998H6.675M10 9.99998H10.0083M13.3333 9.99998H13.3417M17.5 9.99998C17.5 13.6816 14.1417 16.6666 10 16.6666C8.77386 16.6708 7.56233 16.4006 6.45417 15.8758L2.5 16.6666L3.6625 13.5666C2.92667 12.535 2.5 11.3116 2.5 9.99998C2.5 6.31831 5.85833 3.33331 10 3.33331C14.1417 3.33331 17.5 6.31831 17.5 9.99998Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  ,
  'Chat'
);

export default Chat;