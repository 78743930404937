import { SwitchAccount } from "@mui/icons-material";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  Badge,
  Box, Divider, ListItemIcon,
  ListItemText, MenuItem, Popover, Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarIcon, LogoutIcon } from "src/assets";
import { RootState } from 'src/main/store';
import auth from 'src/main/store/auth';
import { AccountEntityModel, SelfEntityModel } from 'src/main/types';
import { InviteNotificationDialog, SwitchAccountPopover } from "./components";


interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  notifyCount?: number | null;
}
const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, notifyCount, ...other } = props;
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>((state) => state.auth.profile);
  const acc = useSelector<RootState, AccountEntityModel | undefined | null>((state) => state.auth.workspace);
  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const mainPopRef = useRef<HTMLDivElement | null>(null);


  const anchorRef = useRef<HTMLLIElement | null>(null);

  const handleLogout = () => {
    dispatch(auth.slice.actions.logout());

    toast.success("Logout success, see you again soon!");
  }

  return (
    <>
      <Popover
        ref={mainPopRef}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={onClose}
        open={!!open}
        PaperProps={{ sx: { width: 300 } }}
        transitionDuration={0}
        {...other}
      >
        <Box
          sx={{
            alignItems: 'center',
            p: 2,
            display: 'flex'
          }}
        >
          <AvatarIcon fontSize="large" style={{ color: muiTheme.palette.text.secondary }} />
          <Box
            sx={{
              ml: 1
            }}
          >
            <Typography variant="body1">
              {profile?.person?.firstname}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {profile?.person?.primaryEmail}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ my: 1 }}>
          <MenuItem
            ref={anchorRef}
            onClick={() => setOpenDialog(!openDialog)}
          >
            <ListItemIcon>
              <SwitchAccount fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  Switch Account
                </Typography>
              )}
            />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => setOpenNotificationDialog(!openNotificationDialog)}>
            <ListItemIcon>
              <Badge
                overlap="circular"
                badgeContent={notifyCount}
                color="primary"
              >
                <NotificationsNoneOutlinedIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  Notifications
                </Typography>
              )}
            />
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  Logout
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
      </Popover>
      <SwitchAccountPopover
        currentAccount={acc?.account}
        anchorEl={anchorRef.current}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
      <InviteNotificationDialog
        onClose={() => setOpenNotificationDialog(false)}
        open={openNotificationDialog}
      />
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default AccountPopover
