import { Container, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TableRow, Dialog, DialogActions, DialogContent, } from '@mui/material';
import React, { useMemo, useCallback, useState } from "react";
import { useParams } from 'react-router-dom';
import { workerApi } from 'src/main/api';
import { Table as CustomTable, LoadingButton, PortalLayout, Typography } from 'src/main/components';
import { CurrencyDecimals } from 'src/main/constants';
import { createStyles, Paths, IntlFormatter } from "src/main/utils";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ClockIcon, DuplicateIcon } from 'src/assets';
import moment from 'moment';
import { useAsyncTask } from 'src/main/hooks';

interface WithdrawDetailPageProps extends React.PropsWithChildren {

}

const WithdrawDetailPage: React.FC<WithdrawDetailPageProps> = (props) => {
  const { withdrawId = "" } = useParams<{ withdrawId: string }>();
  const withdrawDetailResult = workerApi.useWorkerWithdrawDetailQuery(withdrawId, { skip: !withdrawId.length });
  const [cancelWithdraw] = workerApi.useWorkerWithdrawCancelMutation();
  const [runCancelWithdraw, loading] = useAsyncTask("worker/withdraw/cancel");
  const [showAbortDialog, setShowAbortDialog] = useState(false);

  const navigate = useNavigate();

  const withdraw = useMemo(() => {
    return withdrawDetailResult.data?.model ?? null;
  }, [withdrawDetailResult.data?.model]);

  const defineStatusIcon = (status?: string) => {
    switch (status) {
      case "processed":
        return <CheckCircleOutlineOutlined sx={{ color: "white" }} />;
      case "cancelled":
        return <CancelIcon sx={{ color: "white" }} />;
      case "submitted":
        return <ClockIcon sx={{ color: "white" }} />;
      default:
        return <></>;
    }
  }
  const defineStatusColor = (status?: string) => {
    switch (status) {
      case "processed":
        return "success.main";
      case "cancelled":
        return "neutral.500";
      default:
      case "submitted":
        return "info.main";
    }
  }

  const { reference, decimals, currency, message, statusIcon, statusColor } = useMemo(() => {
    const reference = withdraw?.id.substring(0, 8)?.toUpperCase();
    const currency = withdraw?.currency ?? "";
    const decimals = CurrencyDecimals[currency] ?? 0;
    const message = withdraw?.payoutItem?.message;

    const statusColor = defineStatusColor(withdraw?.status);
    const statusIcon = defineStatusIcon(withdraw?.status);

    return {
      reference,
      decimals,
      currency,
      message,
      statusIcon,
      statusColor,
    };
  }, [withdraw]);

  const toastMessages = useMemo(() => {
    return {
      withdrawCancelled: IntlFormatter.getMessage("withdrawRequestPage.withdraw_request_cancelled", "Withdraw request cancelled!"),
      withdrawIdCopied: IntlFormatter.getMessage("withdrawDetailPage.id_copied", "ID Copied")
    }
  }, []);

  const onCancelWithdraw = useCallback(() => {
    runCancelWithdraw(async () => {
      if (!withdraw) return;
      const withdrawId = withdraw.id;
      await cancelWithdraw(withdrawId);

      _onClose?.({}, "escapeKeyDown");
      toast.success(toastMessages.withdrawCancelled);
    })
  }, [runCancelWithdraw, cancelWithdraw, withdraw, toastMessages]);
  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    setShowAbortDialog(false);
  }

  if (!withdraw) return null;

  return (
    <Container sx={styles.container}>
      <PortalLayout.BackButton
        url={Paths.Portal.WithdrawHistoryList}
        label={IntlFormatter.getMessage(
          "withdrawDetailPage.withdrawal_history",
          "Withdrawal History"
        )}
      />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: `${statusColor}` }}>
              <TableCell colSpan={2}>
                <Box display="flex" alignItems="center">
                  {statusIcon}
                  <Typography
                    defaultMessage={`This withdrawal has been ${withdraw.status}`}
                    formatId={
                      `withdrawDetailPage.withdrawal_` + withdraw.status
                    }
                    color="white"
                    variant="body2"
                    fontWeight={700}
                    pl={1}
                    textTransform="none"
                  ></Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} sx={{ borderBottom: 0 }}>
                <Typography
                  formatId="withdrawDetailPage.withdrawal_detail"
                  variant="h6"
                >
                  Withdrawal Detail
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "withdrawDetailPage.withdrawal_id",
                  "Withdrawal ID"
                )}
                value={`R-${reference}`}
              />
              <TableCell align="right">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(`${withdraw?.id}`);
                    toast.success(toastMessages.withdrawIdCopied);
                  }}
                  endIcon={<DuplicateIcon />}
                  color="primary"
                ></Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "withdrawDetailPage.date_created",
                  "Date Created"
                )}
                value={`${moment(withdraw.createdAt).format(
                  "DD∙MM∙YY on HH:mm A"
                )}`}
                colSpan={2}
              />
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "withdrawDetailPage.amount",
                  "Amount"
                )}
                value={`${withdraw.finalAmount.toFormat(decimals)} ${currency}`}
                colSpan={2}
              />
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "withdrawDetailPage.fee",
                  "Fee"
                )}
                value={`+${withdraw.withdrawFee.toFormat(
                  decimals
                )} ${currency}`}
                colSpan={2}
              />
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "withdrawDetailPage.total",
                  "Total"
                )}
                value={`${withdraw.amount.toFormat(decimals)} ${currency}`}
                colSpan={2}
                sx={{ borderBottom: 0 }}
              />
            </TableRow>
            {!!(withdraw.status === "submitted") && (
              <TableRow>
                <TableCell colSpan={2} align="center" sx={{ borderBottom: 0 }}>
                  <LoadingButton
                    variant="outlined"
                    fullWidth
                    loading={loading}
                    size="large"
                    onClick={() => {
                      setShowAbortDialog(true);
                    }}
                    color="error"
                  >
                    {IntlFormatter.getMessage(
                      "withdrawDetailPage.cancel_withdrawal_request",
                      "Cancel Withdrawal Request"
                    )}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={2} align="center">
                <Button
                  onClick={() => {
                    navigate(Paths.Portal.Payouts);
                  }}
                  startIcon={
                    <Box component="span" display="flex" alignItems="center">
                      <ArrowBackIosIcon fontSize="small" />
                    </Box>
                  }
                >
                  {IntlFormatter.getMessage(
                    "withdrawDetailPage.back_to_payout_summary",
                    "Back To Payout Summary"
                  )}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
          {message && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  {IntlFormatter.getMessage(
                    "withdrawDetailPage.message",
                    "Message"
                  )}
                  : {message}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <Dialog fullWidth maxWidth="xs" onClose={_onClose} open={showAbortDialog}>
        <DialogContent>
          <Typography
            formatId="withdrawDetailPage.confirm_withdrawal_request_cancellation"
            variant="h6"
          >
            Confirm Withdrawal Request Cancellation
          </Typography>
          <Typography
            formatId="withdrawDetailPage.confirm_withdrawal_request_cancellation_message"
            variant="body1"
            color="text.secondary"
            lineHeight={1.375}
            mt={2}
          >
            Are you sure you want to cancel this withdrawal request? Your funds
            will not be transferred and the transaction will be terminated.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ pb: 2, pr: 3 }}>
          <Button
            onClick={() => {
              setShowAbortDialog(false);
            }}
          >
            {IntlFormatter.getMessage("withdrawRequestPage.Cancel", "Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => {
              onCancelWithdraw();
            }}
            color="error"
          >
            {IntlFormatter.getMessage("withdrawDetailPage.confirm", "Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },
  content: {
    p: 2,
  },
  icon: {
    mr: 1,
    fontSize: {
      xs: "14px",
      md: "20px",
    },
  },
  label: {
    opacity: 0.7,
    fontWeight: "bold",
    fontSize: {
      xs: "11px",
      md: "14px",
    },
  },
});

export default WithdrawDetailPage;
