import React from "react";
import { useNavigate } from "react-router-dom";
import { TaskAttemptModel } from "src/main/types";
import { Paths } from "src/main/utils";
import { TaskCard } from "./components";

interface WorkerAttemptListProps {
  attempts: TaskAttemptModel[];
}

const WorkerAttemptList: React.FC<WorkerAttemptListProps> = (props) => {
  const { attempts } = props;
  const navigate = useNavigate();
  return (
    <>
      {attempts.filter(a => !!a.task).map(attempt => (
        <TaskCard key={attempt.id} attempt={attempt} onClick={() => navigate(Paths.Portal.AttemptDetail.replace(":attemptId", attempt.id))} />
      ))}
    </>
  );
};

export default WorkerAttemptList;
