import { Box, BoxProps, Card, CardContent } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CashBillIcon } from "src/assets";
import { workerApi } from "src/main/api";
import { LoadingContainer, Typography } from "src/main/components";
import { BN_ZERO } from "src/main/constants";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { commonStyles, createStyles, joinSx } from "src/main/utils";
import WithdrawRequests from '../WithdrawRequests';

interface WalletBalanceProps extends BoxProps {

}
const styles = createStyles({
  cardContent:{
    pb:0
  }
})
const WalletBalance: React.FC<WalletBalanceProps> = (props) => {
  const { sx, ...boxProps } = props;
  const walletsResult = workerApi.useWorkerWalletListQuery(undefined);
  const { currency } = useSelector<RootState, AuthState>(state => state.auth)
  const default_currency = currency?.default ?? "PHP";
  const default_wallet = useMemo(() => walletsResult.data?.entries.find(wallet => wallet.currency === default_currency), [walletsResult, default_currency])

  return (
    <LoadingContainer loading={walletsResult.isLoading} sx={sx} {...boxProps}>
      <Card sx={{ backgroundColor: "rgba(0, 92, 219, 0.08)" }}>
        <CardContent sx={joinSx(commonStyles.cardContent, styles.cardContent)}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" >
                <CashBillIcon color="primary" />
              </Typography>
            </Box>
            <Box display="flex" flex={1}>
              <Box display="flex" flex={1} justifyContent="flex-end" alignItems="center">
                <Box display="flex" flexDirection="column">
                  <Typography formatId="payoutPage.available_balance" variant="overline" align="right">AVAILABLE BALANCE</Typography>
                  <Box display="flex" flexDirection="row" alignItems="center" marginLeft="auto">
                    <Typography variant="h5" paddingRight={0.5}>
                      {(default_wallet?.balance ?? BN_ZERO).toFormat(0)}
                    </Typography>
                    <Typography variant="body2">
                      {default_currency}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <WithdrawRequests mt={2} cardSx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
      </Card>
    </LoadingContainer>
  );
};

export default WalletBalance;
