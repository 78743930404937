import { Box, BoxProps } from "@mui/material";
import React from "react";
import { createStyles, joinSx } from "src/main/utils";
import Branding from "src/main/components/PortalLayout/components/Branding";

interface CardBannerProps extends BoxProps {}

const CardBanner: React.FC<CardBannerProps> = (props) => {
  const { sx, ...rest } = props;
  return (
    <Box {...rest} sx={joinSx(styles.root, sx)}>
      <Branding />
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pt: 2,
  },
  image: {
    p: 1,
    height: "60px",
    width: "60px",
  },
  textPrimary: {
    fontSize: "3rem",
    fontFamily: "Quicksand, Arial",
    color: "#000000",
    fontWeight: "bold",
  },
  textSecondary: {
    fontSize: "3rem",
    fontFamily: "Quicksand, Arial",
    backgroundcolor: "primary",
    backgroundImage: "linear-gradient(77.65deg, #005CDB 1.57%, #2599EB 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
});

export default CardBanner;
