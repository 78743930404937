import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Card, CardProps } from "@mui/material";
import { ApexOptions } from 'apexcharts';
import ApexChart from "react-apexcharts";
import { Link as RouterLink, To } from "react-router-dom";
import { commonStyles, createStyles, joinSx } from 'src/main/utils';
import DetailCard from "../DetailCard";

interface RouteShortcut {
  label: string;
  to: To;
}

export interface ChartProps extends CardProps {
  title: string;
  height: number;
  type: "line" | "area" | "bar" | "histogram" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "treemap" | "boxPlot" | "candlestick" | "radar" | "polarArea" | "rangeBar" | undefined;
  chartOptions: ApexOptions;
  chartSeries: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  shortcuts?: {
    header?: RouteShortcut;
    footer?: RouteShortcut;
  };
  subtitle?: string;
}

const Chart = (props: ChartProps) => {
  const { subtitle, title, height, type, chartOptions, chartSeries, shortcuts = {}, sx, ...rest } = props;

  return (
    <Card {...rest} sx={joinSx(styles.root, sx)}>
      <DetailCard.Header subheader={subtitle} sx={commonStyles.cardHeader} header={title} routeTo={shortcuts.header?.to} routeLabel={shortcuts.header?.label} />
      <DetailCard.Divider />
      <Box sx={styles.chart}>
        <ApexChart
          height={height}
          options={chartOptions}
          series={chartSeries}
          type={type}
        />
      </Box>
      {shortcuts.footer && (
        <>
          <DetailCard.Divider />
          <Box px={2} py={1}>
            <Button
              component={RouterLink}
              to={shortcuts.footer.to}
              endIcon={<ArrowForwardIcon />}
              color="primary"
            >
              {shortcuts.footer.label}
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
}

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  chart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
  },
});

export default Chart;
