
import {
  Meta, TaskModel, TaskObjectiveModel, PersonModel,
  QueryResult,
  TaskAttemptModel
} from "src/main/types";
import moment, { Moment } from "moment";
import { BuilderProp, require200Status } from "src/main/api/account";
import { parseMeta } from "src/main/utils/parseMeta";
import { bnOrZero, SimpleMap } from "src/main/utils";

export interface ListTaskResponse {
  entries: TaskModel[];
  meta: Meta;
}

export interface ListTaskRequest {
  meta: Meta;
}

export interface CreateTaskRequest {
  start?: Moment | number | undefined;
  expiry?: Moment | number | undefined;
  publishedAt?: Moment | number | undefined;
  title: string;
  description: string;
  notionUrl?: string;
  maxSubmission?: number;
  maxAccept?: number;
  maxPeriodAccept?: number;
  objectives: SimpleMap<string>[];
  payAmount: number | string;
  payCurrency: string;
  maxPeriodSubmission?: string | null;
  periodType?: string | null;
  periodStartOffset?: string | null;
  timeAllowed?: number | null;
  resubmitTimeAllowed?: number | null;
}

export interface CreateTaskResponse {
  model: TaskModel;
}

export interface updateTaskRequest {
  model: {
    start?: Moment | number | undefined | null;
    expiry?: Moment | number | undefined | null;
    publishedAt?: Moment | number | undefined | null;
    title?: string | null;
    description?: string | null;
    notionUrl?: string | null;
    maxSubmission?: number | null;
    maxAccept?: number;
    objectives?: Partial<TaskObjectiveModel>[];
    payAmount?: number | string | null;
    payCurrency?: string | null;
    maxPeriodSubmission?: string | null;
    periodType?: string | null;
    timeAllowed?: number | null;
    resubmitTimeAllowed?: number | null;
  },
  taskId: string;
}

export interface UpdateTaskResponse {
  model: TaskModel;
}
export interface GetTaskRequest {
  taskId: string
}

export interface DuplicateTaskRequest {
  taskId: string;
}

export interface DuplicateTaskResponse {
  model: TaskModel;
}

export interface GetTaskResponse {
  model: TaskModel;
}

export interface ListUserResponse {
  entries: PersonModel[];
  meta: Meta;
}

export interface ListTaskAttemptResponse {
  entries: TaskAttemptModel[];
  meta: Meta;
}

export interface ListTaskAttemptRequest {
  //task: string;
  meta?: Meta;
}

export interface GetTaskAttemptResponse {
  model: TaskAttemptModel;
}

export interface GetTaskAttemptRequest {
  attemptId: string;
}

export interface RejectTaskAttemptRequest {
  attemptId: string;
  data: {
    reason: string;
    proofs?: SimpleMap<SimpleMap<string>>;
  }
}

export interface ApproveTaskAttemptRequest {
  attemptId: string;
  data: {
    payAmount: string | number;
    payCurrency: string;
    score: string | number;
  }
}

export interface RejectTaskAttemptResponse {
  model: TaskAttemptModel;
}

export interface ApproveTaskAttemptResponse {
  model: TaskAttemptModel;
}

export interface TerminateTaskAttemptRequest {
  attemptId: string;
  data?: {
    reason: string;
  }
}

export interface TerminateTaskAttemptResponse {
  model: TaskAttemptModel;
}

export interface TerminateAllTaskAttemptRequest {
  attemptIds: string[];
}

export interface TerminateAllTaskAttemptResponse {
  model: TaskModel;
}

export const listTask = (builder: BuilderProp) => {
  return builder.query<ListTaskResponse, ListTaskRequest>({
    providesTags: ["task"],
    query: ({ meta }) => ({
      url: `/account/task/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListTaskResponse>) => {
      for (const task of response.result.entries) {
        if (typeof task.payAmount === "string")
          task.payAmount = bnOrZero(task.payAmount);
        if (typeof task.publishedAt === "string")
          task.publishedAt = moment(task.publishedAt);
      }
      return response.result
    }
  })
}

export const getTask = (builder: BuilderProp) => {
  return builder.query<GetTaskResponse, GetTaskRequest>({
    providesTags: ["task"],
    query: ({ taskId }) => ({
      url: `/account/task/${taskId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetTaskResponse>) => {
      if (typeof response.result.model.payAmount === "string")
        response.result.model.payAmount = bnOrZero(response.result.model.payAmount);
      if (typeof response.result.model.publishedAt === "string")
        response.result.model.publishedAt = moment(response.result.model.publishedAt);
      return response.result
    }
  })
}

export const createTask = (builder: BuilderProp) => {
  return builder.mutation<CreateTaskResponse, CreateTaskRequest>({
    invalidatesTags: ["task"],
    query: (data) => ({
      url: `/account/task/create`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CreateTaskResponse>) => {
      return response.result;
    },
  })
}

export const updateTask = (builder: BuilderProp) => {
  return builder.mutation<UpdateTaskResponse, updateTaskRequest>({
    invalidatesTags: ["task"],
    query: ({ taskId, model }) => ({
      url: `/account/task/${taskId}/update`,
      method: "POST",
      body: model,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateTaskResponse>) => {
      return response.result;
    },
  })
}

export const duplicateTask = (builder: BuilderProp) => {
  return builder.mutation<DuplicateTaskResponse, DuplicateTaskRequest>({
    invalidatesTags: ["task"],
    query: ({ taskId }) => ({
      url: `/account/task/${taskId}/duplicate`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<DuplicateTaskResponse>) => {
      return response.result
    }
  })
}

export const listTaskAttempt = (builder: BuilderProp) => {
  return builder.query<ListTaskAttemptResponse, ListTaskAttemptRequest>({
    providesTags: ["task"],
    query: ({ meta }) => ({
      url: `/account/task/attempt/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListTaskAttemptResponse>) => {
      return response.result
    }
  })
}

export const getTaskAttempt = (builder: BuilderProp) => {
  return builder.query<GetTaskAttemptResponse, GetTaskAttemptRequest>({
    providesTags: ["task"],
    query: ({ attemptId }) => ({
      url: `/account/task/attempt/${attemptId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetTaskAttemptResponse>) => {
      return response.result
    }
  })
}

export const rejectTaskAttempt = (builder: BuilderProp) => {
  return builder.mutation<RejectTaskAttemptResponse, RejectTaskAttemptRequest>({
    invalidatesTags: ["task"],
    query: ({ attemptId, data }) => ({
      url: `/account/task/attempt/${attemptId}/reject`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<RejectTaskAttemptResponse>) => {
      return response.result
    }
  })
}

export const approveTaskAttempt = (builder: BuilderProp) => {
  return builder.mutation<ApproveTaskAttemptResponse, ApproveTaskAttemptRequest>({
    invalidatesTags: ["task"],
    query: ({ attemptId, data }) => ({
      url: `/account/task/attempt/${attemptId}/approve`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ApproveTaskAttemptResponse>) => {
      return response.result
    }
  })
}

export const terminateTaskAttempt = (builder: BuilderProp) => {
  return builder.mutation<TerminateTaskAttemptResponse, TerminateTaskAttemptRequest>({
    invalidatesTags: ["task"],
    query: ({ attemptId, data }) => ({
      url: `/account/task/attempt/${attemptId}/terminate`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TerminateTaskAttemptResponse>) => {
      return response.result
    }
  })
}


export const terminateAllTaskAttempt = (builder: BuilderProp) => {
  return builder.mutation<TerminateAllTaskAttemptResponse, TerminateAllTaskAttemptRequest>({
    invalidatesTags: ["task"],
    query: ({ attemptIds }) => ({
      url: `/account/task/attempt/terminate/bulk`,
      method: "POST",
      body: attemptIds,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TerminateAllTaskAttemptResponse>) => {
      return response.result
    }
  })
}

export interface AssignTeamToTaskRequest {
  taskId: string;
  teamIds: string[];
}

export interface AssignTeamToTaskResponse {

}

export const assignTeamToTask = (builder: BuilderProp) => {
  return builder.mutation<AssignTeamToTaskResponse, AssignTeamToTaskRequest>({
    invalidatesTags: ["task"],
    query: ({ taskId, teamIds }) => ({
      url: `/account/task/${taskId}/team/assign`,
      method: "POST",
      body: { teamIds },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<AssignTeamToTaskResponse>) => {
      return response.result
    }
  })
}

export interface UnassignTeamToTaskRequest {
  taskId: string;
  teamIds: string[];
}

export interface UnassignTeamToTaskResponse {

}

export const unassignTeamToTask = (builder: BuilderProp) => {
  return builder.mutation<UnassignTeamToTaskResponse, UnassignTeamToTaskRequest>({
    invalidatesTags: ["task"],
    query: ({ taskId, teamIds }) => ({
      url: `/account/task/${taskId}/team/unassign`,
      method: "POST",
      body: { teamIds },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UnassignTeamToTaskResponse>) => {
      return response.result
    }
  })
}

export interface UpdateTeamInTaskRequest {
  taskId: string;
  teamIds: string[];
}

export interface UpdateTeamInTaskResponse {

}

export const updateTeamInTask = (builder: BuilderProp) => {
  return builder.mutation<UpdateTeamInTaskResponse, UpdateTeamInTaskRequest>({
    invalidatesTags: ["task"],
    query: ({ taskId, teamIds }) => ({
      url: `/account/task/${taskId}/team/update`,
      method: "POST",
      body: { teamIds },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateTeamInTaskResponse>) => {
      return response.result
    }
  })
}