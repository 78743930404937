import { Button, Container, Menu, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from "src/main/components";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import React, { useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { SelectorIcon } from "src/assets";
import { workerApi } from 'src/main/api';
import { RootState } from 'src/main/store';
import { AuthState } from 'src/main/store/auth/slice';
import { Paths, createStyles, makeRelativePath, IntlFormatter } from "src/main/utils";
import { AvailableTasks, OngoingTasks, RejectedTasks, SubmittedTasks, TaskFilter, TaskTabs } from './components';
import ClosedTasks from './components/ClosedTasks/ClosedTasks';


const SORTBY_DROPDOWN_MENU = {
  "ending": "Ending Soon",
  "highest": "Highest Earning",
  "newly": "Newly Listed",
}
interface TasksPageProps extends React.PropsWithChildren {

}

const TasksPage: React.FC<TasksPageProps> = (props) => {
  const p = makeRelativePath(Paths.Portal.Tasks);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  const isAvailableTaskPage = pathname.startsWith(Paths.Portal.TasksAvailable);
  const potentialEarningQuery = workerApi.useWorkerPotentialEarningQuery({});
  const potentialEarnings = useMemo(() => potentialEarningQuery.data?.earnings, [potentialEarningQuery.data])
  const { currency } = useSelector<RootState, AuthState>(state => state.auth);

  const [selectedSortBy, setSelectedSortBy] = useState(IntlFormatter.getMessage("taskAvailable.sortByEndingSoon", SORTBY_DROPDOWN_MENU["ending"]));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (event.currentTarget.textContent !== "") {
      setSelectedSortBy(event.currentTarget.textContent);
    }

    setAnchorEl(null);
  };

  const [availableTask, setAvailableTask] = useState<number>(0);
  const [noTaskByStatus, setNoTaskByStatus] = useState<number>(0);

  const updateAvailableTask = (count: number) => {
    setAvailableTask(count);
  };

  const updateNoTaskByStatus = (count: number) => {
    setNoTaskByStatus(count);
  };

  return (
    <Container sx={styles.container}>
      {isAvailableTaskPage && (
        <Box sx={{ pl: 2, ...(isMobile && { backgroundColor: "background.paper", ml: -2, mr: -2, mt: -1, pl: 0 }) }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent={isMobile ? 'center' : ''}
            pt={2}
          >
            <Typography formatId="sideBar.availableTasks" variant="h6">
              Available Tasks
            </Typography>
            <Typography variant="body1" color="primary.main">
              ({availableTask})
            </Typography>

          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent={isMobile ? 'center' : ''}
          >
            <Typography formatId="taskAvailable.potientialEarnings" variant="body2">
              Potential earnings:
            </Typography>
            <Typography color="primary" variant="body2">+{potentialEarnings?.totalPotentialEarnings.toFormat(0)}</Typography>
            <Typography variant="body2">{currency?.default}</Typography>
          </Stack>
        </Box>
      )
      }

      <Routes>
        <Route path={p(Paths.Portal.TasksTab)} element={<TaskTabs />} />
      </Routes>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ ...(isMobile && { backgroundColor: "background.paper", ml: -2, mr: -2, mt: -2 }) }}
      >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<SelectorIcon sx={{ fontSize: 8 }} />}
          sx={{
            "&[aria-expanded='true']": {
              backgroundColor: "primary.ghost"
            }
          }}
        >
          {IntlFormatter.getMessage("taskAvailable.sortBy", "Sort By")}: {selectedSortBy}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            "& .MuiMenu-paper": {
              border: 0
            },
          }}
        >
          <MenuItem onClick={handleClose} value={10}>{IntlFormatter.getMessage("taskAvailable.sortByEndingSoon", SORTBY_DROPDOWN_MENU["ending"])}</MenuItem>
          <MenuItem onClick={handleClose} value={20}>{IntlFormatter.getMessage("taskAvailable.sortByHighestEarn", SORTBY_DROPDOWN_MENU["highest"])}</MenuItem>
          <MenuItem onClick={handleClose} value={30}>{IntlFormatter.getMessage("taskAvailable.sortByNewly", SORTBY_DROPDOWN_MENU["newly"])}</MenuItem>
        </Menu>
        <TaskFilter noTaskByStatus={noTaskByStatus} />
      </Stack>

      <Routes>
        <Route path={p(Paths.Portal.TasksAvailable)} element={<AvailableTasks updateAvailableTask={updateAvailableTask} updateNoTaskByStatus={updateNoTaskByStatus} />} />
        <Route path={p(Paths.Portal.TasksInProgress)} element={<OngoingTasks updateNoTaskByStatus={updateNoTaskByStatus} />} />
        <Route path={p(Paths.Portal.TasksSubmitted)} element={<SubmittedTasks updateNoTaskByStatus={updateNoTaskByStatus} />} />
        <Route path={p(Paths.Portal.TasksRejected)} element={<RejectedTasks updateNoTaskByStatus={updateNoTaskByStatus} />} />
        <Route path={p(Paths.Portal.TasksClosed)} element={<ClosedTasks updateNoTaskByStatus={updateNoTaskByStatus} />} />
        <Route path="*" element={<Navigate to={Paths.Portal.TasksInProgress} replace />} />
      </Routes>
    </Container >
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },

});

export default TasksPage;
