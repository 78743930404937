import { Box, BoxProps } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyTasksGraphic } from "src/assets";
import { workerApi } from "src/main/api";
import { ListingComp, PortalLayout, WorkerTaskList } from "src/main/components";
import { Meta } from "src/main/types";
import { createStyles, IntlFormatter, Paths } from "src/main/utils";

interface AvailableTasksProps extends BoxProps {
  updateAvailableTask: (count: number) => void;
  updateNoTaskByStatus: (count: number) => void;
}

const AvailableTasks: React.FC<AvailableTasksProps> = ({
  updateAvailableTask,
  updateNoTaskByStatus,
}) => {
  const navigate = useNavigate();

  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 20, offset: 0 });
  const tasksResult = workerApi.useWorkerTasksQuery({
    ...meta,
    limit: "20",
    available: "true",
  });
  const persisMeta = useMemo(
    () => ({ ...meta, ...tasksResult.data?.meta }),
    [tasksResult.data, meta]
  );

  const tasks = useMemo(() => {
    return tasksResult.data?.entries ?? [];
  }, [tasksResult.data]);

  useEffect(() => {
    updateAvailableTask(tasks.length);
    updateNoTaskByStatus(tasks.length);
  }, [tasks.length, updateAvailableTask, updateNoTaskByStatus]);

  return (
    <Box>
      {tasks.length > 0 && (
        <ListingComp
          title=""
          loading={tasksResult.isLoading}
          updateList={(newMeta) => {
            setMeta(newMeta);
          }}
          meta={persisMeta}
          sx={styles.list}
          mode="tasker"
        >
          <ListingComp.Content>
            <WorkerTaskList tasks={tasks} />
          </ListingComp.Content>
        </ListingComp>
      )}

      {tasks.length === 0 && !tasksResult.isUninitialized && (
        <PortalLayout.EmptyState
          cta={IntlFormatter.getMessage(
            "taskPage.available",
            "Accept Task Now"
          )}
          graphic={
            <EmptyTasksGraphic
              style={{ height: "100px", marginBottom: "1rem", opacity: "0.7" }}
            />
          }
          label={IntlFormatter.getMessage(
            "taskPage.have_not_accept_tasks",
            "You have not accepted any tasks yet."
          )}
          onClick={() => navigate(Paths.Portal.Home)}
        />
      )}
    </Box>
  );
};
const styles = createStyles({
  container: {
    pt: 1,
  },
  list: {
    m: 0,
    backgroundColor: "inherit",
    boxShadow: "inherit",
  },
});

export default AvailableTasks;
