import { Box, CardProps } from "@mui/material";
import React from "react";
import { DetailCard, EmptyState, Table, TableRowWrap } from "src/main/components";
import { CredentialModel } from "src/main/types";

interface CredentialInfoProps extends Omit<CardProps, "children"> {
  credential?: CredentialModel;
}

const CredentialInfo: React.FC<CredentialInfoProps> = (props) => {
  const { credential } = props;


  return (
    <Box flex={1}>
      <DetailCard>
        <DetailCard.Header header="Credential Info" />
        <DetailCard.Divider />
        {!credential && (<EmptyState message="No credentials found" />)}
        {credential && (
          <Table>
            <Table.Body>
              <TableRowWrap label="Username" value={credential.accessKey} />
            </Table.Body>
          </Table>
        )}
      </DetailCard>
    </Box>
  );
};

// const styles = createStyles({
//   root: {

//   },
// })

export default CredentialInfo;
