import { Box, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import React from "react";
import { CheckCircleIcon, CircleXIcon, InfoIcon } from "src/assets";
import { TaskAttemptModel } from "src/main/types";
import { ApproveContent, CancelContent, ExpireContent, RejectContent, TerminateContent } from "./components";
import { FormattedMessage } from 'react-intl';

interface TaskStatusCompProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
  isExpired?: boolean;
}

const getStatusText = (attempt: TaskAttemptModel, isExpired) => {
  if (isExpired) return <FormattedMessage id="taskStatusComponent.task_been_expired" defaultMessage="This task has expired" />;

  switch (attempt.status) {
    case "approved": return <FormattedMessage id="taskStatusComponent.task_been_approved" defaultMessage="This task has been approved" />;
    case "cancelled": return <FormattedMessage id="taskStatusComponent.task_been_cancelled" defaultMessage="This task has been cancelled" />;
    case "terminated": return <FormattedMessage id="taskStatusComponent.task_been_terminated" defaultMessage="This task has been terminated" />;
    case "expired": return <FormattedMessage id="taskStatusComponent.task_been_expired" defaultMessage="This task has expired" />;
    case "in-progress":
      if (attempt.rejectReason) return <FormattedMessage id="taskStatusComponent.revise_resubmit_task" defaultMessage="Please revise and resubmit this task" />;
      return "";
    default: return "";
  }
}

const getStatusIcon = (attempt: TaskAttemptModel, color: string) => {
  switch (attempt.status) {
    case "approved": return <CheckCircleIcon sx={{ color }} />;

    case "cancelled":
    case "terminated":
    case "expired": return <CircleXIcon sx={{ color }} />;
    case "in-progress":
      if (attempt.rejectReason) return <InfoIcon sx={{ color }} />;;
      return "";
    default: return "";
  }
}

const TaskStatusComp: React.FC<TaskStatusCompProps> = (props) => {
  const { attempt, isExpired } = props;
  const { palette } = useTheme();
  
  const getStatusColor = (attempt?: TaskAttemptModel) => {
    if (isExpired) return palette.error.main;

    switch (attempt?.status) {
      case "approved": return palette.success.main;
      case "cancelled": return palette.error.main;
      case "terminated": return palette.error.main;
      case "expired": return palette.error.main;
      case "in-progress":
        if (attempt.rejectReason) return palette.warning.main;
        return "";
      default: return "";
    }
  }

  const getContent = () => {
    if (!attempt) return;

    if (isExpired) return <ExpireContent attempt={attempt} />;

    switch (attempt.status) {
      case "approved": return <ApproveContent attempt={attempt} />;
      case "cancelled": return <CancelContent attempt={attempt} />;
      case "terminated": return <TerminateContent attempt={attempt} />;
      case "expired": return <ExpireContent attempt={attempt} />;
      case "in-progress":
        if (attempt.rejectReason) return <RejectContent attempt={attempt} />;
        return "";
      default: return "";
    }
  }

  if ((attempt?.status === "in-progress" && !attempt.rejectReason) || attempt?.status === "submitted") return null;

  return (
    <>
      <CardHeader
        sx={{ background: getStatusColor(attempt), p: [2, 1] }}
        title={
          !!attempt && (
            <Box display="flex" alignItems="center">
              <Box display="flex" pr={1} alignItems="center">
                {getStatusIcon(attempt, palette.primary.contrastText)}
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" fontWeight={700} color="primary.contrastText">
                  {getStatusText(attempt, isExpired)}
                </Typography>
              </Box>
            </Box>
          )
        }
      />
      <CardContent sx={{ p: 2 }}>
        {getContent()}
      </CardContent>
    </>
  );
};

export default TaskStatusComp;

