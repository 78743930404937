import { WorkerBuilderProp, require200Status } from "src/main/api/worker";
import { Meta, QueryResult, WorkerTaskStatistic } from "src/main/types";
import { SimpleMap } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";

export interface WorkerTaskStatsRequest {

}
export interface WorkerTaskStatsResponse {
  task: WorkerTaskStatistic
}

export const workerTaskStats = (builder: WorkerBuilderProp) => {
  return builder.query<WorkerTaskStatsResponse, WorkerTaskStatsRequest>({
    providesTags: ["stats"],
    query: () => ({
      url: `/work/stats/tasks`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<WorkerTaskStatsResponse>) => {
      return response.result;
    }
  })
}


export interface DailyTaskEarningRequest {
  meta?: Partial<Meta>;
}

export interface TaskEarningSeries {
  amount: string;
  amountInUsd: string;
  tasks: string;
}
export interface DailyTaskEarningResponse {
  series: SimpleMap<TaskEarningSeries>;
}

export const dailyTaskEarnings = (builder: WorkerBuilderProp) => {
  return builder.query<DailyTaskEarningResponse, DailyTaskEarningRequest>({
    providesTags: ["stats"],
    query: ({ meta }) => ({
      url: `/work/stats/earnings/series?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<DailyTaskEarningResponse>) => {
      return response.result;
    }
  })
}
