import BigNumber from "bignumber.js";
import moment from "moment";
import { SimpleMap } from "./type";
import * as XLSX from "xlsx";
import { toast } from "react-hot-toast";
import { reduceVariables } from "./misc";

export const exportList = (
  exportFile: SimpleMap<any>[],
  seqArr: SimpleMap<string>[],
  newHeader: string[],
  fileName: string,
  dateformat?: string
) => {
  try {
    if (!dateformat) dateformat = "DD-MM-YYYY";
    const dataArr: any = [newHeader];

    exportFile.forEach((eFile: any) => {
      let newRow: any[] = [];

      const variablesArr = reduceVariables(eFile);
      const record = variablesArr.reduce(
        (a, b) => ({ ...a, [b[0]]: b[1] }),
        {}
      );

      seqArr.forEach((seq) => {
        if (!record[seq.key]) {
          newRow.push("-");
          return;
        } else {
          if (seq.type) {
            switch (seq.type) {
              case "date":
                newRow.push(moment(record[seq.key]).format(dateformat));
                break;
              case "amount":
                newRow.push(BigNumber(record[seq.key] ?? 0).toFixed(2));
                break;
              case "forceString":
                newRow.push(`="${record[seq.key]}"`);
                break;
            }
          } else {
            newRow.push(record[seq.key]);
          }
        }
      });

      dataArr.push(newRow);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(dataArr);
    const Workbook = {
      SheetNames: ["sheet1"],
      Sheets: {
        sheet1: worksheet,
      },
    };

    return XLSX.writeFile(Workbook, fileName);
  } catch (error) {
    toast.error("Error exporting file: " + error);
  }
};
