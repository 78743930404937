import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Chip, IconButton, Typography } from "@mui/material";
import React from "react";
import { DetailCard } from "src/main/components";
import { TaskAttemptModel, TaskObjectiveModel, TaskProofModel } from "src/main/types";
import { normalizeText } from "src/main/utils";

interface TaskObjectiveProofCardProps extends React.PropsWithChildren {
  taskProof: TaskProofModel;
  index: number;
  taskObjs: TaskObjectiveModel[];
  taskAttempt: TaskAttemptModel;
}

const TaskObjectiveProofCardComponent: React.FC<TaskObjectiveProofCardProps> = (props) => {
  const { taskProof, index, taskObjs, taskAttempt } = props;

  const taskObjective = taskObjs.find((taskObj) => taskObj.id === taskProof.taskObjectiveId);

  const getContenttype = () => {

    switch (taskObjective!.type) {
      case "link":
        return (
          <Box sx={{ overflowX: "scroll" }} p={2} display="flex">
            <Box flex={1}>
              <Typography variant="subtitle1">{taskProof.content}</Typography>
            </Box>
            <IconButton
              component="a"
              target="_blank"
              href={taskProof.content}
            >
              <LaunchIcon />
            </IconButton>
          </Box>
        )
      case "screenshot":
        return (
          <Box sx={{ overflowX: "scroll" }} p={2} display="flex">
            <img alt="screen-shot" src={taskProof.proofImage?.url} />
          </Box>
        )

      default:
        return (
          <Box p={2} display="flex">
            <Typography>{taskProof.content}</Typography>
          </Box>
        )
    }
  }

  const getRejectLabel = () => {
    if (!!taskAttempt.rejectCount && taskAttempt.status === "submitted") {
      if (taskProof.isResubmitted) return "Previously Rejected";
      else return "No Resubmission";
    }
    return "Rejected"
  }

  return (
    <DetailCard>
      <DetailCard.Header
        header={(
          <Box width="100%" display="flex" justifyContent="space-between" pb={2}>
            <Box>
              <Typography variant="h6">Objective {index + 1}</Typography>
              <Typography variant="body2" color="text.secondary">Objective Instruction: {taskObjective?.instruction}</Typography>
            </Box>
            <Box flex={1} />
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="subtitle1">{normalizeText(taskObjective?.type)}</Typography>
              {taskProof.rejectReason && (
                <>
                  <Chip size='small' color="error" label={getRejectLabel()} />
                  <Typography variant="body2" color="error">
                    <b>Reason:</b> {taskProof.rejectReason}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      />
      <DetailCard.Divider />
      {getContenttype()}

      <Box p={2} px={3}>
        <Typography variant="subtitle1">Workers Notes:</Typography>
        <Typography>
          {taskProof.note ?? "NA"}
        </Typography>
      </Box>
    </DetailCard >
  );
};

export default TaskObjectiveProofCardComponent;
