import React, { useState} from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogProps, Typography } from "@mui/material";

import { accountApi } from "src/main/api";
import { useAsyncTask } from "src/main/hooks";
import { toast } from "react-hot-toast";
import { handleApiResponse } from "src/main/utils";

interface CancelInviteConfirmationProps extends Omit<DialogProps, "open"> {
    open?: boolean;
    targetInviteId: string;
}
const CancelInviteConfirmation: React.FC<CancelInviteConfirmationProps> = (props) => {
    const { onClose, open = false, targetInviteId, ...dialogProps } = props;
    const [isCanceling, setIsCanceling] = useState(false);
    let confirmInviteId = "";
    
    const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      onClose?.(event, reason);
      confirmInviteId = "";
    }
    const confirm = () => {
      confirmInviteId = targetInviteId;
      runCancelInvite(async () => {
        setIsCanceling(true);
        const cancelResponse = await cancelInvite({ inviteId: confirmInviteId });
        const { error } = handleApiResponse(cancelResponse);
        setIsCanceling(false);
        confirmInviteId = "";
        onClose?.({}, "escapeKeyDown");

        if (error) {  
          toast.error("Cancelation failed.");
          throw new Error(error?.data?.error?.message);
        }
        else{
          toast.success("Invite cancelled");
        }
        
      })
    }

    const [cancelInvite] = accountApi.useCancelInviteMutation();
    const [runCancelInvite] = useAsyncTask(`account/invite/cancel/${targetInviteId}`);
    
    return (
      <Dialog open={open} fullWidth {...dialogProps} onClose={_onClose}>
        <DialogContent>
          <Box display="flex" flexDirection="column" pt={2}>
            <Typography variant="h6">Confirm Cancel Invitation?</Typography>
            <Alert sx={{ mt: 2 }} severity="warning">This invitation will be cancel!</Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isCanceling}
            onClick={() => confirm()}
            color="primary"
            loadingIndicator="Canceling..."
          >
            <span>Cancel Invitation</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
};

export default CancelInviteConfirmation;