import { Button, Card, CardContent, CardProps, Typography } from "@mui/material";
import React from "react";
import { createStyles, joinSx } from "src/main/utils";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface EmptyStateProps extends CardProps {
  graphic?: React.ReactNode;
  cta?: string;
  onClick?: () => void;
  label?: string;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { children, graphic, cta, label, onClick, sx, ...cardProps } = props;
  return (
    <Card sx={joinSx(styles.root, sx)} variant="outlined" {...cardProps}>
      <CardContent sx={styles.content}>
        {typeof label === "string" && (
          <Typography variant="body1" fontWeight="700">{label}</Typography>
        )}
        {typeof cta === "string" && typeof onClick === "function" && (
          <Button onClick={onClick} endIcon={<ChevronRightIcon />}>
            {cta}
          </Button>
        )}
        {children}
        {graphic}
      </CardContent>
    </Card>
  );
};

const styles = createStyles({
  root: {
    background: "rgba(0, 92, 219, 0.08)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default EmptyState;
