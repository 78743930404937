import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { AppBar, AppBarProps, Box, Button, IconButton, Toolbar, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import { useLocation } from "react-router-dom";
import { AvatarIcon, MenuIcon } from "src/assets";
import { Paths } from "src/main/utils";
import { AccountMenu } from "./components";

interface TopBarProps extends AppBarProps {
  onOpenSidebar?: () => void;
}

const TopBarRoot = styled(AppBar)(
  ({ theme }) => ({
    ...(
      theme.palette.mode === 'light'
        ? {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.neutral?.[500],
          boxShadow: theme.shadows[3]
        }
        : {
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          boxShadow: 'none'
        }
    )
  })
);


const TopBar: React.FC<TopBarProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { onOpenSidebar } = props;
  const { pathname } = useLocation();

  const portalHome = pathname.startsWith(Paths.Portal.Home);
  const muiTheme = useTheme();

  return (
    <TopBarRoot
      position={portalHome ? "static" : "fixed"}
      sx={{
        color: portalHome ? "#FFFFFF" : muiTheme.palette.text.secondary,
        left: {
          lg: 280
        },
        width: {
          lg: 'calc(100% - 280px)'
        }
      }}
    >
      {portalHome && (
        <Box
          sx={{
            position: "absolute", marginTop: -20,
            zIndex: 1,
            background: muiTheme.palette.primary.gradient,
            backgroundColor: {
              md: muiTheme.palette.background.default,
            },
            height: "400px",
            width: "100%",
          }}>
        </Box>

      )}
      <Toolbar
        disableGutters
        sx={{
          zIndex: 2,
          minHeight: 64,
          left: {
            xs: 0,
            lg: portalHome ? 280 : 0,
          },
          px: 2
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
            color: "inherit",
            mr: 1,
          }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        {/* <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            ml: 2,
          }}
        >
          <BellIcon fontSize="large" />
        </Box> */}

        <Button
          size="large"
          sx={{ color: portalHome ? "#FFFFFF" : muiTheme.palette.text.secondary }}
          startIcon={<AvatarIcon style={{ fontSize: 40 }} sx={{ marginRight: '-10px' }} />}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<ChevronDownIcon fontSize='large' />}
        >
        </Button>
        <AccountMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}
        />
      </Toolbar>
    </TopBarRoot>
  );
};

export default TopBar;
