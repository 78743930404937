import moment, { Moment } from "moment";
import { BuilderProp, require200Status } from "src/main/api/account";
import { DayVisitModel, Meta, QueryResult, VisitModel, WorkerStatsModel, AffiliateTrackerDailyStats } from "src/main/types";
import { QueryWithMeta, SimpleMap } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";

export interface GetPayoutStatsResponse {
  count: {
    submitted: number;
    processed: number;
  }
}

//WORKER STATS
export interface GetWorkerStatsResponse {
  worker: WorkerStatsModel;
  meta: Meta;
}

//LIST PAYOUT VOL
export interface ListPayoutStatsVolResItem {
  amount: number;
  amountInUsd: number;
}
export interface ListPayoutVolStatsResponse {
  result: SimpleMap<ListPayoutStatsVolResItem>;
}
export interface ListPayoutStatsVolItem {
  date: Moment;
  amount: number;
  amountInUsd: number;
}

//WORKER ONBOARD SERIES
export interface WorkerStatsOnboardItem {
  active: string;
  pending: string;
}
export interface ListWorkerStatsOnboardSeriesResponse {
  series: SimpleMap<WorkerStatsOnboardItem>
}
export interface ListWorkerStatsOnboardItem {
  date: Moment;
  active: number;
  pending: number;
}

//WALLET BALANCE
export interface WalletbalanceItem {
  currency: string,
  balance: string,
  balanceInUSD: string
}
export interface ListWalletBalanceResponse {
  result: WalletbalanceItem[];
}

export interface TaskSubmissionSeriesItem {
  date: Moment;
  inprogress: number;
  approved: number;
  submitted: number;
}
export interface ListTaskSubmissionSeriesResponse {
  series: TaskSubmissionSeriesItem[];
}

export const getPayoutStats = (builder: BuilderProp) => {
  return builder.query<GetPayoutStatsResponse, QueryWithMeta>({
    providesTags: ["stats"],
    query: ({ meta }) => ({
      url: `/account/stats/payout?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetPayoutStatsResponse>) => {
      return response.result;
    }
  })
}

export const listPayoutVolStats = (builder: BuilderProp) => {
  return builder.query<ListPayoutStatsVolItem[], QueryWithMeta>({
    providesTags: ["stats"],
    query: ({ meta }) => ({ // TODO: use meta properly
      url: `/account/stats/payout/volume?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListPayoutVolStatsResponse>) => {
      return Object.entries(response.result).map(([key, value]) => ({
        date: moment(key, "YYYY-MM-DD"),
        amount: parseInt(value.amount),
        amountInUsd: parseInt(value.amountInUsd)
      })).sort((a, b) => a.date.diff(b.date));
    }
  })
}

export const getWorkerStats = (builder: BuilderProp) => {
  return builder.query<GetWorkerStatsResponse, QueryWithMeta>({
    providesTags: ["stats"],
    query: (meta) => ({
      url: `/account/stats/worker`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<GetWorkerStatsResponse>) => {
      return response.result
    }
  })
}

export const listWorkerOnBoardSeries = (builder: BuilderProp) => {
  return builder.query<ListWorkerStatsOnboardItem[], QueryWithMeta>({
    providesTags: ["stats"],
    query: ({ meta }) => ({
      url: `/account/stats/worker/series?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<ListWorkerStatsOnboardSeriesResponse>) => {
      return Object.entries(response.result.series).map(([key, value]) => ({
        date: moment(key, "YYYY-MM-DD"),
        active: parseInt(value.active),
        pending: parseInt(value.pending)
      })).sort((a, b) => a.date.diff(b.date));
    }
  })
}

export const listWalletBalance = (builder: BuilderProp) => {
  return builder.query<ListWalletBalanceResponse, QueryWithMeta>({
    providesTags: ["stats"],
    query: (meta) => ({
      url: `/account/stats/wallet/balance`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<ListWalletBalanceResponse>) => {
      return response.result;
    }
  })
}

export const listTaskSubmissionSeries = (builder: BuilderProp) => {
  return builder.query<ListTaskSubmissionSeriesResponse, QueryWithMeta>({
    providesTags: ["stats"],
    query: ({ meta }) => ({
      url: `/account/stats/tasks/series?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<ListTaskSubmissionSeriesResponse>) => {
      return {
        ...response.result,
        series: Object.entries(response.result.series).map(([key, serie]) => {
          return {
            date: moment(key),
            inprogress: parseInt(serie.inprogress + ""),
            approved: parseInt(serie.approved + ""),
            submitted: parseInt(serie.submitted + ""),
          }
        }).sort((a, b) => a.date.diff(b.date))
      };
    }
  })
}

export interface GetAvailableTaskStatsResponse {
  active: number
}

export const getAvailableTaskStats = (builder: BuilderProp) => {
  return builder.query<GetAvailableTaskStatsResponse, QueryWithMeta>({
    providesTags: ["stats"],
    query: () => ({
      url: `/account/stats/tasks`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<GetAvailableTaskStatsResponse>) => {
      return response.result
    }
  })
}

export interface GetVisitStats {
  meta: Partial<Meta>;
}

export const getVisitStats = (builder: BuilderProp) => {
  return builder.query<VisitModel[], GetVisitStats>({
    providesTags: ["stats"],
    query: ({ meta }) => ({
      url: `/account/stats/visit?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<VisitModel[]>) => {
      return response.result
    }
  })
}

export const getAffiliateTrackerStats = (builder: BuilderProp) => {
  return builder.query<DayVisitModel[], GetVisitStats>({
    providesTags: ["stats"],
    query: ({ meta }) => ({
      url: `/account/affiliate/visit/list?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<DayVisitModel[]>) => {
      return response.result
    }
  })
}

export interface GetAffiliateTrackerDailyStatsRequest {
  meta?: Partial<Meta>;
  affiliateTrackerId: string;
}

export interface GetAffiliateTrackerDailyStatsResponse {
  meta?: Meta;
  series: AffiliateTrackerDailyStats[];
}

export const getAffiliateTrackerDailyStats = (builder: BuilderProp) => {
  return builder.query<GetAffiliateTrackerDailyStatsResponse, GetAffiliateTrackerDailyStatsRequest>({
    providesTags: ["stats"],
    query: ({ meta, affiliateTrackerId }) => ({
      url: `/account/stats/affiliate/tracker/${affiliateTrackerId}/daily?${parseMeta(meta)}`,
      method: "GET",
      validateStatus: require200Status
    }),
    transformResponse: (response: QueryResult<GetAffiliateTrackerDailyStatsResponse>) => {
      return {
        ...response.result,
        series: Object.entries(response.result.series).map(([key, serie]) => {
          console.log({ serie }, serie.uview, parseInt(serie.uview + ""));
          return {
            date: moment(key),
            view: parseInt(serie.view + ""),
            uview: parseInt(serie.uview + ""),
            click: parseInt(serie.click + ""),
            uclick: parseInt(serie.uclick + ""),
          }
        }).sort((a, b) => a.date.diff(b.date))
      };
    }
  })
}