import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/main/store";
import { SelfEntityModel } from 'src/main/types';
import { createStyles } from "src/main/utils";
import { Overview, PotentialEarning, TaskSummary, Wallet } from "./components";

interface HomePageProps extends React.PropsWithChildren {

}

const HomePage: React.FC<HomePageProps> = (props) => {
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>((state) => state.auth.profile);

  return (
    <Box display="flex" p={2}>
      <Container disableGutters sx={styles.container}>
        <Typography mb={1} variant="h6">
          Hi, {profile?.person?.firstname}
        </Typography>

        {/* <Benefit /> */}

        <Overview />

        <TaskSummary />

        {/* <Rating /> */}

        <Wallet />

        <PotentialEarning />

      </Container>
    </Box>
  );
};

const styles = createStyles({
  hero: {
    backgroundColor: "background.paper",
    elevation: 1,
    pt: 1,
    boxShadow: 1,
  },
  container: {
    p: 2,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 3,
    marginTop: -8,
  },
});

export default HomePage;
