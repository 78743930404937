import { Box, CardProps, Link, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DetailCard, InputField, LoadingButton, Table } from "src/main/components";
import { Defaults } from "src/main/constants";
import { WorkerModel } from "src/main/types";
import { createStyles, joinSx, Paths, SimpleMap, Variables } from "src/main/utils";

interface OnboardInfoProps extends Omit<CardProps, "children"> {
  worker: WorkerModel;
}

interface WorkerBrandInfo {
  label: string;
  key: string;
  referral: string;
  username: string;
}

const OnboardInfo: React.FC<OnboardInfoProps> = (props) => {
  const { worker, sx, ...rest } = props;
  const result = accountApi.useQueryAccountVariablesQuery({ key: Defaults.Setting.WorkerBrands });

  const [updateWorkerMutation, { isLoading: isUpdateLoading }] = accountApi.useUpdateWorkerMutation();

  const navigate = useNavigate();
  const brands = useMemo(() => result.data?.content?.items, [result.data]);

  const referrals = (worker?.variables?.referrals ?? {}) as SimpleMap<string>;
  const usernames = (worker?.variables?.usernames ?? {}) as SimpleMap<string>;

  const [variables, setVariables] = useState<Variables>({
    referrals: { ...referrals },
    usernames: { ...usernames },
  })

  const workerBrands: WorkerBrandInfo[] = useMemo(() => {
    const { referrals = {}, usernames = {} } = variables;
    return brands?.map(brand => ({
      ...brand,
      referral: referrals[brand.key] ?? "",
      username: usernames[brand.key] ?? "",
    })) ?? [];
  }, [brands, variables]);

  const onChange = (key: string, type: "referrals" | "usernames") => {
    return (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      setVariables(v => {
        const newVariables = { ...v };
        if (typeof newVariables[type] !== "object") {
          newVariables[type] = {};
        }
        newVariables[type][key] = value;

        return newVariables;
      });
    };
  }

  const onUpdate = useCallback(async () => {
    if (!worker) {
      return toast.error("Invalid worker information");
    }

    const newWorker = {
      variables: variables
    }

    const workerResult = await updateWorkerMutation({ workerId: worker.id, model: newWorker });

    if ("data" in workerResult) {
      toast.success("Updated!");
    } else {
      toast.error((workerResult.error as any).data?.error?.message);
      throw new Error((workerResult.error as any).data?.error?.message);
    }
  }, [updateWorkerMutation, variables, worker])

  return (
    <DetailCard {...rest} sx={joinSx(styles.root, sx)}>
      <DetailCard.Header header="Worker Brands" subheader={(
        <Typography variant="subtitle2" color="text.secondary" sx={{ pb: 2 }}>
          Use the following to configure this tasker's affiliate landing pages. You can configure the brands <Link sx={{ cursor: "pointer", fontWeight: "bold" }} onClick={() => navigate(Paths.Overview.AccountSetting)}>HERE</Link>.
          <br />
          Tasker's affiliate page links can be inserted into task content by using the following tag:
          <br />
          <code>{`{{ referrals.affiliate-page-key }}`}</code>
        </Typography>
      )} />
      <DetailCard.Divider />
      <Table>
        <Table.Body>
          {workerBrands?.map((({ key, label, referral, username }) => (
            <Table.Row>
              <Table.Cell align="center">
                <Typography>{key}</Typography>
                <Typography variant="subtitle2" fontSize="small" color="text.secondary">({label})</Typography>
              </Table.Cell>
              <Table.Cell>
                <Box display="flex" gap={2}>
                  <InputField label="Referral Link" onChange={onChange(key, "referrals")} value={referral} />
                  <InputField label="Username" onChange={onChange(key, "usernames")} value={username} />
                </Box>
              </Table.Cell>
            </Table.Row>
          )))}
        </Table.Body>
      </Table>
      <Box display="flex" p={2} justifyContent="flex-end">
        <LoadingButton
          onClick={() => onUpdate()}
          loading={isUpdateLoading}
          variant="contained"
        >
          Update
        </LoadingButton>
      </Box>
    </DetailCard>
  );
};

const styles = createStyles({
  root: {

  },
})

export default OnboardInfo;
