import React from "react";
import type { ReactNode } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { createStyles, joinSx } from "src/main/utils";
import { Box, Button, ButtonProps, Typography, Stack, BoxProps } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface NavigateButtonProps extends ButtonProps {
  active?: boolean;
  chip?: ReactNode;
  icon?: ReactNode;
  info?: ReactNode;
  subInfo?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  titleColor?: string;
  onClick?: React.MouseEventHandler;
}


const NavigateButton = (props: NavigateButtonProps) => {
  const {
    active,
    children,
    chip,
    icon,
    info,
    open: openProp,
    path,
    title,
    titleColor,
    color,
    subInfo,
    onClick,
    ...other
  } = props;

  const linkProps = {
    ...typeof path === "string" ? {
      to: path,
      component: RouterLink,
    } : { onClick },
  }

  return (
    <Button
      {...linkProps}
      endIcon={(
        <Box component="span" display="flex" alignItems="center">
          {chip}
          <ChevronRightIcon fontSize="small" color={color} />
        </Box>
      )}
      disableRipple
      startIcon={icon}
      sx={{
        mb: 1,
        border: 1,
        color: active ? 'secondary.main' : 'neutral.300',
        justifyContent: 'flex-start',
        pr: 3,
        textAlign: 'left',
        textTransform: 'uppercase',
        width: '100%',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, 0.08)'
        },
        '& .MuiButton-startIcon': {
          color: active ? 'secondary.main' : 'neutral.400'
        },
        '& .MuiButton-endIcon': {
          color: 'neutral.400'
        }
      }}
      {...other}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Stack>
          <Typography variant="overline" color={titleColor}>{title}</Typography>
          <Typography variant="caption" >{subInfo}</Typography>
        </Stack>
      </Box>
      {info}
    </Button>
  );
};

interface NavigateButtonVerticalProps extends BoxProps {
  icon: ReactNode;
  title: string;
  content: string | ReactNode;
  linkTitle: string;
  path?: string;
  onClick?: React.MouseEventHandler;
}

const Vertical = (props: NavigateButtonVerticalProps) => {
  const {
    sx,
    title,
    content,
    linkTitle,
    icon,
    path,
    onClick
  } = props;

  const linkProps = {
    ...typeof path === "string" ? {
      to: path,
      component: RouterLink,
    } : { onClick },
  }

  return (
    <Box sx={joinSx(styles.root, sx)} display="flex" flexDirection="column">
      {icon}
      <Typography variant="overline" lineHeight={1.375} mt={0.5}>
        {title}
      </Typography>
      {content}
      <Button
        {...linkProps}
        endIcon={(
          <Box component="span" display="flex" alignItems="center">
            <ChevronRightIcon fontSize="small" />
          </Box>
        )}
        sx={styles.verticalButton}
      >
        <Box flex={1}>
          <Typography variant="caption" color="text.secondary">{linkTitle}</Typography>
        </Box>
      </Button>
    </Box>
  );
};

const styles = createStyles({
  root: {
    width: '100%',
    p: {
      lg: 1
    }
  },
  verticalButton: {
    textAlign: 'left',
    p: 0,
    color: 'text.secondary'
  }
});

NavigateButton.Vertical = Vertical;
export default NavigateButton;
