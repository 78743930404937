import {
  TableContainer, Table, TableRow, TableCell, Link,
  TableHead, TableBody, TableContainerProps, TableHeadProps,
  TableCellProps, Typography, TableBodyProps, TableRowProps, Stack, Paper,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { createStyles, joinSx } from "src/main/utils"
import { SxProps } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { OverridableStringUnion } from "@mui/types"
import { Variant } from "@mui/material/styles/createTypography";

interface Props extends TableContainerProps {
  tableStyle?: SxProps;
}

export interface HeadCellProp {
  value: string | React.ReactNode;
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  cellSx?: SxProps;
  colSpan?: number;
  infoIcon?: boolean;
  infoIconText?: string;
}

interface HeadProps extends TableHeadProps {
  headers?: HeadCellProp[];
}

interface CellProps extends TableCellProps {
  value?: React.ReactNode;
  link?: string;
  header?: boolean;
  textVariant?: OverridableStringUnion<"inherit" | Variant, TypographyPropsVariantOverrides> | undefined;
  color?: string;
}

interface RowProps extends TableRowProps {

}

interface BodyProps extends TableBodyProps {
}

interface LabelValueCellProps extends TableCellProps {
  value?: React.ReactNode;
  label?: string;
}

const Head = (props: HeadProps) => {
  const { headers, children } = props;
  return (
    <TableHead {...props}>
      {!!headers?.length && <Row>
        {headers?.map((headCellProps, index) => (
          <Cell textVariant="overline" color="text.secondary" key={index} {...headCellProps} sx={{ padding: "16px 8px" }} header />
        ))}
      </Row>}
      {children}
    </TableHead>
  )
}

const LinkWrapper = (props: any) => {
  const { link, children } = props;
  if (link) {
    return (
      <Link color="inherit" component={RouterLink} to={link}>
        {children}
      </Link>
    )
  }
  return children;
}

const Cell = (props: CellProps) => {
  const { link, value, header = false, align = "left", textVariant = "body2", children, color, sx } = props;
  const getValue = () => {
    if (!value) return null;
    if (typeof value === "string") {
      return (
        <Typography
          variant={textVariant}
          align={align}
          color={color}
          sx={{
            fontSize: header ? "12px" : "14px",
            fontWeight: header ? 600 : 400,
          }}
        >
          {value}
        </Typography>
      )
    }
    return value;
  }
  return (
    <TableCell {...props} sx={joinSx({ padding: "12px 10px" }, sx)}>
      <LinkWrapper link={link}>
        {getValue()}
        {children}
      </LinkWrapper>
    </TableCell>
  )
}

const Row = (props: RowProps) => {
  return (
    <TableRow {...props}>
      {props.children}
    </TableRow>
  )
}

const Body = (props: BodyProps) => {
  return (
    <TableBody component={Paper} {...props}>
      {props.children}
    </TableBody>
  )
}

const LabelValueCell = (props: LabelValueCellProps) => {
  const { label, value, ...rest } = props;
  return (
    <TableCell {...rest}>
      <Stack>
        <Typography color="textSecondary" sx={{ fontSize: '12px', fontWeight: 600 }}>{label}</Typography>
        {value ? <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{value}</Typography> : <>&nbsp;</>}
      </Stack>
    </TableCell>
  )
}

const TableComponent = (props: Props) => {
  const { tableStyle, sx, children } = props;

  return (
    <TableContainer sx={joinSx(styles.root, sx)}>
      <Table stickyHeader sx={joinSx(styles.table, tableStyle)}>
        {children}
      </Table>
    </TableContainer>
  );
};

const styles = createStyles({
  root: {},
  table: {}
})

TableComponent.Head = Head;
TableComponent.Cell = Cell;
TableComponent.Row = Row;
TableComponent.Body = Body;
TableComponent.LabelValueCell = LabelValueCell;

export default TableComponent;
