import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { QueryTags } from "src/main/constants";
import { PersonModel } from "src/main/types";
import { QueryFn } from "../../base";
import { QueryResult, QueryResultModel } from "./invite";
import authSlice from "src/main/store/auth/slice";

type AuthApiBuilder<A extends string> = EndpointBuilder<
  QueryFn,
  (typeof QueryTags.Worker)[number],
  A
>;

export interface SubmitPersonInfoProps {
  personInfoBody: FormData;
}

export const submitPersonInfo = <A extends string>(
  builder: AuthApiBuilder<A>
) =>
  builder.mutation({
    invalidatesTags: ["self"],
    query: (props: SubmitPersonInfoProps) => ({
      url: `self/person/update/`,
      method: "POST",
      body: props.personInfoBody,
      validateStatus: (response: Response) => response.status === 200,
    }),
    transformResponse: (
      response: QueryResult<QueryResultModel<PersonModel>>
    ) => {
      console.log("self/person/update/", response);
      return response.result;
    },
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const data = await queryFulfilled;
      const model = data.data.model;
      dispatch(
        authSlice.actions.updateProfilePerson({
          data: model,
          refreshProfile: true,
        })
      );
    },
  });
