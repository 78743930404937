import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { accountApi } from 'src/main/api';
import { DetailCard, EmptyState, LoadingContainer, Table } from "src/main/components";
import { AffiliateTrackerModel } from "src/main/types";

interface AffiliateTrackerStatsCardProps extends React.PropsWithChildren {
  affiliateTracker?: AffiliateTrackerModel;
}

const HEADERS = [
  { value: "1D" },
  { value: "7D" },
  { value: "30D" },
  { value: "1D(U)" },
  { value: "7D(U)" },
  { value: "30D(U)" },
]

const AffiliateTrackerStatsCardComponent: React.FC<AffiliateTrackerStatsCardProps> = (props) => {
  const { affiliateTracker } = props;
  const result = accountApi.useGetAffiliateTrackerStatsQuery({ meta: { tracker: affiliateTracker?.id ?? "" } });

  const dayVisits = useMemo(() => result.data?.entries ?? [], [result.data?.entries]);

  return (
    <DetailCard>
      <DetailCard.Header
        header="Total Visits"
        endComp={
          <Typography alignSelf="flex-start">{affiliateTracker?.brandKey}</Typography>
        }
      />
      < DetailCard.Divider />
      <LoadingContainer loading={result.isLoading}>
        <Table>
          <Table.Head headers={[{ value: "Clicks" }, { value: "Views" }]} />
          <Table.Body>
            <Table.Row>
              <Table.Cell align="center">{affiliateTracker?.totalVisit?.click}</Table.Cell>
              <Table.Cell align="center">{affiliateTracker?.totalVisit?.view}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {!dayVisits.length && <EmptyState message='No total visits record' />}
        {!!dayVisits.length && (
          <Table>
            <Table.Head headers={HEADERS} />
            <Table.Body>
              <Table.Row>
                <Table.Cell>{dayVisits[0].count1d ?? 0}</Table.Cell>
                <Table.Cell>{dayVisits[0].count7d ?? 0}</Table.Cell>
                <Table.Cell>{dayVisits[0].count30d ?? 0}</Table.Cell>
                <Table.Cell>{dayVisits[0].ucount1d ?? 0}</Table.Cell>
                <Table.Cell>{dayVisits[0].ucount7d ?? 0}</Table.Cell>
                <Table.Cell>{dayVisits[0].ucount30d ?? 0}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </LoadingContainer>
    </DetailCard >
  );
};

export default AffiliateTrackerStatsCardComponent;
