import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi } from "@reduxjs/toolkit/query/react";

import { queryWithRefresh } from "../base";
import { listActivityLog } from "./ActivityLog";
import { listAffiliateTracker, detailAffiliateTracker, createAffiliatePage, detailAffiliatePage, listAffiliatePage, updateAffiliatePage, listAffiliateVisitTrackers } from "./Affiliate";
import { approvePayoutItem, cancelPayoutItem, getPayoutItem, listPayoutItem, processPayoutItem } from "./Payout";
import { listPrivileges } from "./Privilege";
import { approveTaskAttempt, assignTeamToTask, createTask, duplicateTask, getTask, getTaskAttempt, listTask, listTaskAttempt, rejectTaskAttempt, terminateAllTaskAttempt, terminateTaskAttempt, unassignTeamToTask, updateTask, updateTeamInTask } from "./Task";
import { assignParent, createTeam, createTeamPersons, deleteTeam, deleteTeamPersons, getTeam, listTeamPerson, listTeams, listTeamTasks, listTeamWorker, updateTeam, updateTeamPersons } from "./Team";
import { getAffiliateTrackerDailyStats, getAffiliateTrackerStats, getAvailableTaskStats, getPayoutStats, getVisitStats, getWorkerStats, listPayoutVolStats, listTaskSubmissionSeries, listWalletBalance, listWorkerOnBoardSeries } from "./Stats";
import { cancelInvite, createInvite, createUser, getUser, listInvites, listUser, updateCredential, updateUser, updateUserPrivilege } from "./User";
import { createAccountVariable, deleteAccountVariable, getAccountVariable, listAccountVariable, updateAccountVariable } from "./Variable";
import { getWalletTxDetail, listWalletTx } from "./WalletTx";
import { BulkProcessWithdraw, getWithdrawRequest, listWithdrawRequest, processWithdrawRequest } from "./Withdraw";
import { createWorker, generateWorkerAffiliate, getWorker, getWorkerWalletDetail, listWorker, listWorkerWallets, onboardWorker, queryAccountVariables, updateWorker, updateWorkerPerson } from "./Worker";
import { getCurrencyList, invalidateNotionUrl, notionUrl, getAffiliateLink } from "./Misc";

export type AccountTagType = "account" | "user" | "worker" | "task" | "privilege" | "invites" | "variable" | "withdraw" | "withdraws" | "payout" | "activitylog" | "team" | "stats" | "affiliatePage" | "walletTx" | "misc" | "pendingInvite";
export type BuilderProp = EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, AccountTagType, "accountApi">

export const accountTags: AccountTagType[] = ["account", "user", "worker", "task", "privilege", "invites", "variable", "withdraw", "payout", "activitylog", "team", "stats", "walletTx", "affiliatePage", "misc", "pendingInvite"];
export const require200Status = (response: Response) => response.status === 200;

const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: queryWithRefresh,
  tagTypes: accountTags,
  endpoints: (builder) => ({
    createWorker: createWorker(builder),
    listWorker: listWorker(builder),
    getWorker: getWorker(builder),
    updateWorker: updateWorker(builder),
    onboardWorker: onboardWorker(builder),
    updateWorkerPerson: updateWorkerPerson(builder),
    listWorkerWallet: listWorkerWallets(builder),
    getWorkerWalletDetail: getWorkerWalletDetail(builder),
    generateWorkerAffiliate: generateWorkerAffiliate(builder),
    listWalletTx: listWalletTx(builder),
    getWalletTxDetail: getWalletTxDetail(builder),

    queryAccountVariables: queryAccountVariables(builder),

    listTask: listTask(builder),
    getTask: getTask(builder),
    createTask: createTask(builder),
    updateTask: updateTask(builder),
    duplicateTask: duplicateTask(builder),
    listTaskAttempt: listTaskAttempt(builder),
    getTaskAttempt: getTaskAttempt(builder),
    approveTaskAttempt: approveTaskAttempt(builder),
    rejectTaskAttempt: rejectTaskAttempt(builder),
    terminateTaskAttempt: terminateTaskAttempt(builder),
    terminateAllTaskAttempt: terminateAllTaskAttempt(builder),
    assignTeamToTask: assignTeamToTask(builder),
    unassignTeamToTask: unassignTeamToTask(builder),
    updateTeamInTask: updateTeamInTask(builder),

    listUser: listUser(builder),
    getUser: getUser(builder),
    createUser: createUser(builder),
    updateUser: updateUser(builder),
    updateUserPrivilege: updateUserPrivilege(builder),
    listInvites: listInvites(builder),
    createInvite: createInvite(builder),
    cancelInvite: cancelInvite(builder),
    updateCredential: updateCredential(builder),

    getAccountVariable: getAccountVariable(builder),
    listAccountVariable: listAccountVariable(builder),
    createAccountVariable: createAccountVariable(builder),
    updateAccountVariable: updateAccountVariable(builder),
    deleteAccountVariable: deleteAccountVariable(builder),

    getWithdrawRequest: getWithdrawRequest(builder),
    listWithdrawRequest: listWithdrawRequest(builder),
    processWithdrawRequest: processWithdrawRequest(builder),
    bulkProcessWithdraw: BulkProcessWithdraw(builder),

    getPayoutItem: getPayoutItem(builder),
    listPayoutItem: listPayoutItem(builder),
    approvePayoutItem: approvePayoutItem(builder),
    processPayoutItem: processPayoutItem(builder),
    cancelPayoutItem: cancelPayoutItem(builder),

    listPrivileges: listPrivileges(builder),
    listActivityLog: listActivityLog(builder),

    getPayoutStats: getPayoutStats(builder),
    listPayoutVolStats: listPayoutVolStats(builder),
    getWorkerStats: getWorkerStats(builder),
    listWorkerOnBoardSeries: listWorkerOnBoardSeries(builder),
    listWalletBalance: listWalletBalance(builder),
    listTaskSubmissionSeries: listTaskSubmissionSeries(builder),
    getAvailableTaskStats: getAvailableTaskStats(builder),
    getVisitStats: getVisitStats(builder),
    getAffiliateTrackerStats: getAffiliateTrackerStats(builder),
    getAffiliateTrackerDailyStats: getAffiliateTrackerDailyStats(builder),

    listAffiliatePage: listAffiliatePage(builder),
    createAffiliatePage: createAffiliatePage(builder),
    detailAffiliatePage: detailAffiliatePage(builder),
    updateAffiliatePage: updateAffiliatePage(builder),
    listAffiliateTracker: listAffiliateTracker(builder),
    detailAffiliateTracker: detailAffiliateTracker(builder),
    listAffiliateVisitTrackers: listAffiliateVisitTrackers(builder),

    listTeam: listTeams(builder),
    createTeam: createTeam(builder),
    updateTeam: updateTeam(builder),
    deleteTeam: deleteTeam(builder),
    getTeam: getTeam(builder),
    listTeamTask: listTeamTasks(builder),

    assignParentTeam: assignParent(builder),
    createTeamPersons: createTeamPersons(builder),
    updateTeamPersons: updateTeamPersons(builder),
    deleteTeamPersons: deleteTeamPersons(builder),
    listTeamPersons: listTeamPerson(builder),
    listTeamWorkers: listTeamWorker(builder),

    getCurrencyList: getCurrencyList(builder),
    notionUrl: notionUrl(builder),
    getAffiliateLink: getAffiliateLink(builder),
    invalidateNotionUrl: invalidateNotionUrl(builder),
  })
})

export default accountApi; 
