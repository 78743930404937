import { Box, Typography, Button, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "src/assets";
import { TaskAttemptModel } from "src/main/types";
import { Paths } from "src/main/utils";
import { packageFormatMessage } from "src/main/utils";
import { useIntl } from "react-intl";

interface TerminateContentProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
}
const TerminateContent: React.FC<TerminateContentProps> = (props) => {
  const { attempt } = props;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const intl = useIntl();

  const getTerminateText = () => {
    if (!!attempt?.rejectCount) {
      return `${intl.formatMessage(
        packageFormatMessage(
          "terminatedContentComp.reached_resubmission_limit_unqualified",
          "You have reached the resubmission limit with unqualified work."
        )
      )}`;
    }

    return intl.formatMessage(
      packageFormatMessage(
        "taskStatusComponent.task_been_terminated",
        "Task has been terminated"
      )
    );
  };

  return (
    <>
      <Box
        sx={{
          background: palette.error.alert.background,
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Typography variant="body2">{getTerminateText()}</Typography>
      </Box>
      <Box textAlign="right" mt="4px">
        <Button
          endIcon={<ChevronRight />}
          onClick={() => {
            navigate(Paths.Portal.TasksAvailable);
          }}
          variant="text"
        >
          {intl.formatMessage(
            packageFormatMessage(
              "terminatedContentComp.more_available_tasks",
              "More Available Tasks"
            )
          )}
        </Button>
      </Box>
    </>
  );
};

export default TerminateContent;
