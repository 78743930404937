import { createSvgIcon } from '@mui/material/utils';

const TextIcon = createSvgIcon(
  <svg viewBox="0 1 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.40576 4.89551C1.40576 4.48129 1.74155 4.14551 2.15576 4.14551H12.7225C13.1367 4.14551 13.4725 4.48129 13.4725 4.89551C13.4725 5.30972 13.1367 5.64551 12.7225 5.64551H8.32461L8.32461 16.4774C8.32461 16.8916 7.98883 17.2274 7.57461 17.2274C7.1604 17.2274 6.82461 16.8916 6.82461 16.4774L6.82461 5.64551H2.15576C1.74155 5.64551 1.40576 5.30972 1.40576 4.89551ZM10.0624 9.35979C10.0624 8.94557 10.3981 8.60979 10.8124 8.60979H17.844C18.2582 8.60979 18.594 8.94557 18.594 9.35979C18.594 9.774 18.2582 10.1098 17.844 10.1098H15.1683V16.4774C15.1683 16.8916 14.8326 17.2274 14.4183 17.2274C14.0041 17.2274 13.6683 16.8916 13.6683 16.4774V10.1098H10.8124C10.3981 10.1098 10.0624 9.774 10.0624 9.35979Z" fill="currentColor" />
  </svg>
  ,
  'TextIcon'
);

export default TextIcon;
