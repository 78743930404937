import { Box, Button } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { CreateTaskRequest, updateTaskRequest } from "src/main/api/account/Task";
import { EmptyState, LoadingButton, LoadingContainer, Page, Tabs, ActivityLog } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { createStyles, Paths, SimpleMap } from "src/main/utils";
import * as Yup from "yup";
import { SubmissionConfig, TaskDetail, TaskObjectiveDetail } from "../CreateTaskPage/components";
import { Submissions, TaskDatesInfoCard, TaskSchedule, TeamAssignCard } from "./components";


interface TaskDetailPageProps extends React.PropsWithChildren {

}
const tabs = [
  { value: "details", label: "Details" },
  { value: "schedule", label: "Schedule" },
  { value: "submissions", label: "Submissions" },
  { value: "activity", label: "Activity" },
]

const TaskDetailPage: React.FC<TaskDetailPageProps> = () => {
  const { taskId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [tabVal, setTabVal] = useState("details");

  const { currency } = useSelector<RootState, AuthState>(state => state.auth);

  React.useMemo(() => {
    const vals = new URLSearchParams(search)

    if (vals.get("tab")) setTabVal(vals.get("tab") ?? "detail");
  }, [search]);

  const result = accountApi.useGetTaskQuery({ taskId: taskId ?? "" });
  const task = useMemo(() => result?.data?.model ?? undefined, [result.data?.model]);
  const [runPublishTask, publishLoading] = useAsyncTask("account/task/publish");
  const [runDuplicateTask] = useAsyncTask("account/task/duplicate");

  // const [terminateAllAttempts, { isLoading: isTerminateLoading }] = accountApi.useTerminateAllTaskAttemptMutation();
  // const [runTerminateAllAttempts] = useAsyncTask("terminate/all/taskAttempts");

  const [duplicateTask, { isLoading: isDuplicateLoading }] = accountApi.useDuplicateTaskMutation();
  const [updateTaskMutation, { isLoading: isSubmitLoading }] = accountApi.useUpdateTaskMutation();
  // const [terminateIds, setTerminateIds] = useState<string[]>([]);

  const [formErrors, setFormErrors] = useState<SimpleMap<string>>({});

  const initial_values = useMemo(() => {
    return {
      title: task?.title ?? "",
      description: task?.description ?? "",
      notionUrl: task?.notionUrl ?? "",
      objectives: task?.taskObjectives,

      start: task?.start,
      expiry: task?.expiry,
      publishedAt: task?.publishedAt,
      payAmount: task?.payAmount ?? "",
      payCurrency: task?.payCurrency ?? currency?.default ?? "PHP",
      maxSubmission: task?.maxSubmission,
      maxAccept: task?.maxAccept,
      maxPeriodAccept: task?.maxPeriodAccept,
      maxPeriodSubmission: task?.maxPeriodSubmission,
      periodType: task?.periodType,
      isRecurring: !!task?.periodType,
      enableStart: !!task?.start,
      enableExpiry: !!task?.expiry,
      periodStartOffset: task?.periodStartOffset,
      taskId: task?.id,
      timeAllowed: task?.timeAllowed ?? null,
      resubmitTimeAllowed: task?.resubmitTimeAllowed ?? null,
    }
  }, [task, currency]);

  const onDuplicateTask = () => {
    runDuplicateTask(async () => {
      if (!task) return;
      const duplicated = await duplicateTask({ taskId: task.id });


      if ("data" in duplicated) {
        toast.success("Task duplicated!");
        navigate(Paths.Management.TasksDetail.replace(":taskId", duplicated.data?.model?.id));
      } else {
        toast.error((duplicated.error as any).data?.error?.message)
        throw new Error((duplicated.error as any).data?.error?.message)
      }

    })
  }

  // const onTerminateAll = () => {
  //   runTerminateAllAttempts(async () => {
  //     if (!task) return;
  // const terminates = await terminateAllAttempts({ });

  // if ("data" in terminates) {
  //   toast.success("Task updated!");
  //   result.refetch()
  // } else {
  //   toast.error((terminates.error as any).data?.error?.message)
  //   throw new Error((terminates.error as any).data?.error?.message)
  // }
  //   })
  // }

  const onUnpublish = (publish: boolean) => {
    runPublishTask(async () => {
      if (!task) return;

      const updateResult = await updateTaskMutation({
        taskId: task.id,
        model: {
          publishedAt: publish ? moment().unix() : null,
        },
      });

      if ("data" in updateResult) {
        toast.success("Task updated!");
        result.refetch()
      } else {
        toast.error((updateResult.error as any).data?.error?.message)
        throw new Error((updateResult.error as any).data?.error?.message)
      }
    })
  }

  const onUpdate = useCallback(async (values: FormikValues) => {
    if (!task) return;
    const objectives = values.objectives.map((obj) => {
      if (!obj.id) return obj;
      else return { ...obj, taskObjectiveId: obj.id }
    });

    if (values.objectives.length) {
      let err = false;
      values.objectives.forEach((obj, index) => {
        if (obj.error) {
          toast.error(obj.error + ` Please update objective ${index + 1}`);
          err = true;
        }
      })

      if (err) {
        return;
      }
    }

    if (values.timeAllowed || values.resubmitTimeAllowed) {
      if (!values.resubmitTimeAllowed) {
        toast.error("Please update resubmit time limit fields.");
        setFormErrors((prev) => ({
          ...prev,
          resubmitTimeAllowed: "required field"
        }))
        return;
      }
      if (!values.timeAllowed) {
        toast.error("Please update time limit fields.");
        setFormErrors((prev) => ({
          ...prev,
          timeAllowed: "required field"
        }))
        return;
      }
    }

    const model = {
      title: values.title,
      description: values.description,
      notionUrl: values.notionUrl ?? null,
      objectives: objectives,

      publishedAt: values.publishedAt,

      payAmount: values.payAmount ?? null,
      payCurrency: values.payCurrency ?? null,
      maxSubmission: (!values.maxSubmission || values.maxSubmission === 0) ? null : values.maxSubmission,
      maxAccept: (!values.maxAccept || values.maxAccept === 0) ? null : values.maxAccept,
      maxPeriodAccept: (!values.maxPeriodAccept || values.maxPeriodAccept === 0) ? null : values.maxPeriodAccept,
      timeAllowed: values.timeAllowed,
      resubmitTimeAllowed: values.resubmitTimeAllowed,
    } as CreateTaskRequest;


    if (values.publishIfNull && !model.publishedAt) {
      model.publishedAt = moment().unix();
    }

    if (values.isRecurring) {
      model.maxPeriodSubmission = values.maxPeriodSubmission ? values.maxPeriodSubmission : null;
      model.periodType = values.periodType;
      model.periodStartOffset = !!values.periodStartOffset ? values.periodStartOffset : null;
    } else {
      model.maxPeriodSubmission = null;
      model.periodType = null;
      model.periodStartOffset = null;
    }

    const submitData: updateTaskRequest = {
      model, taskId: task.id
    }
    const updateResult = await updateTaskMutation(submitData);

    if ("data" in updateResult) {
      toast.success("Task updated!");
      result.refetch()
    } else {
      toast.error((updateResult.error as any).data?.error?.message)
      throw new Error((updateResult.error as any).data?.error?.message)
    }
    // eslint-disable-next-line
  }, [task])

  const onUpdateSchedule = useCallback(async (values: FormikValues, initial) => {
    if (!task) return;
    const model: SimpleMap<any> = {
    };

    if (values.enableExpiry) {
      model.expiry = moment(values.expiry).unix();
    } else {
      model.expiry = null
    }

    if (values.enableStart) {
      model.start = moment(values.start).unix();
    } else {
      model.start = null
    }

    const submitData: updateTaskRequest = {
      model, taskId: task.id
    }

    const updateResult = await updateTaskMutation(submitData);

    if ("data" in updateResult) {
      toast.success("Task updated!");
      result.refetch()
    } else {
      toast.error((updateResult.error as any).data?.error?.message)
      throw new Error((updateResult.error as any).data?.error?.message)
    }
    // eslint-disable-next-line
  }, [task])

  const {
    isPublished,
  } = useMemo(() => {
    const isPublished = task?.publishedAt?.isBefore(moment());

    return {
      isPublished,
    }
  }, [task?.publishedAt])

  if (!result.isLoading && !task) return <EmptyState />

  return (
    <Page>
      {!result.isLoading && (
        <Formik
          initialValues={initial_values}
          enableReinitialize
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Title is required"),
            description: Yup.string().required("Description is required").max(250, 'Max 250 characters'),
            maxSubmission: Yup.number().positive("Max submission should be positive value").nullable(),
            maxAccept: Yup.number().positive("Max accept should be positive value").nullable(),
            maxPeriodAccept: Yup.number().positive("Max period accept should be positive value").nullable(),
            notionUrl: Yup.string(),

            payAmount: Yup.number().required("Amount is required").test("payAmount", "", function (value, context) {
              if ((!value && value !== 0) || value < 0) {
                return this.createError({ path: context.path, message: `${context.path} must be a valid amount` });
              }
              return true;
            }),
            payCurrency: Yup.string().required("Currency is required"),
            objectives: Yup.array(),
            maxPeriodSubmission: Yup.number().positive("Max period submission should be positive value").nullable(),
            periodType: Yup.string().nullable(),
          })}
          onSubmit={onUpdate}
        >
          {({
            dirty,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            validateForm,
            setErrors,
            initialValues
          }): JSX.Element => (
            <Form
              noValidate 
              onSubmit={handleSubmit}
            >
              <Page.TopSection title="Task">
                {tabVal === "details" && (
                  <Box display="flex">
                    {!isPublished && (
                      <LoadingButton
                        color="secondary"
                        variant="contained"
                        loading={publishLoading}
                        sx={{ mr: 2, flexShrink: 0 }}
                        onClick={() => onUnpublish(true)}
                        disabled={dirty}
                      >
                        Publish Now
                      </LoadingButton>
                    )}
                    {isPublished && (
                      <LoadingButton
                        color="secondary"
                        variant="outlined"
                        loading={publishLoading}
                        sx={{ mr: 2, flexShrink: 0 }}
                        onClick={() => onUnpublish(false)}
                        disabled={dirty}
                      >
                        Unpublish
                      </LoadingButton>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitLoading}
                      sx={{ mr: 2, flexShrink: 0 }}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      disabled={isDuplicateLoading}
                      onClick={() => onDuplicateTask()}
                    >
                      Duplicate
                    </Button>
                  </Box>
                )}
                {tabVal === "schedule" && (
                  <Box>
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={isSubmitLoading}
                      onClick={() => onUpdateSchedule({ ...values }, initialValues)}
                    >
                      Update
                    </Button>
                  </Box>
                )}
              </Page.TopSection>
              <Tabs value={tabVal} updateTab={setTabVal} tabs={tabs} sx={{ paddingX: 2 }} />
              {tabVal === "details" && (
                <LoadingContainer loading={result.isLoading || isDuplicateLoading || isSubmitLoading}>
                  {task && (
                    <Page.Content >
                      <Box sx={styles.boxSection}>
                        <TaskDetail
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setErrors={setErrors}
                        />
                        <TaskDatesInfoCard task={task} />
                        <SubmissionConfig
                          setFormErrors={(val: SimpleMap<string>) => setFormErrors(val)}
                          formErrors={formErrors}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                      <Box sx={styles.boxSection}>
                        <TaskObjectiveDetail
                          errors={errors}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                        <TeamAssignCard task={task} />
                      </Box>
                    </Page.Content>
                  )}
                </LoadingContainer>
              )}
              {tabVal === "schedule" && (
                <Page.Content>
                  <Box flex={1} >
                    <TaskSchedule
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </Box>
                </Page.Content>
              )}
              {tabVal === "submissions" && (
                <Submissions taskId={task!.id} />
              )}
              {tabVal === "activity" && (
                <ActivityLog ownerId={task!.id} ownerName="task" />
              )}
            </Form>
          )}
        </Formik>
      )}
    </Page>
  );
};

const styles = createStyles({
  root: {},
  boxSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 2,
  }
})

export default TaskDetailPage; 
