import createSagaMiddleware from "@redux-saga/core";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiMiddlewares, apiReducers } from "../api";
import authSlice from "./auth/slice";
import layoutSlice from "./layout/slice";
import preferenceSlice from "./preference/slice";
import mainSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  preference: preferenceSlice.reducer,
  layout: layoutSlice.reducer,
  auth: authSlice.reducer,
  ...apiReducers,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
  }).concat([sagaMiddleware, ...apiMiddlewares])
});

sagaMiddleware.run(mainSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from "./types";

export default store;
