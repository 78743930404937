import { Box, BoxProps } from "@mui/material";
import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyTasksGraphic } from "src/assets";
import { workerApi } from "src/main/api";
import {
  ListingComp,
  PortalLayout,
  WorkerAttemptList,
} from "src/main/components";
import { Paths, createStyles, IntlFormatter } from "src/main/utils";
import { Meta } from "src/main/types";

interface SubmittedTasksProps extends BoxProps {
  updateNoTaskByStatus: (count: number) => void;
}

const SubmittedTasks: React.FC<SubmittedTasksProps> = ({
  updateNoTaskByStatus,
}) => {
  const navigate = useNavigate();

  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 20, offset: 0 });
  const result = workerApi.useWorkerAttemptsQuery({
    ...meta,
    status: "submitted",
    submittedAt: "0,",
    limit: "20",
  });
  const persisMeta = useMemo(
    () => ({ ...meta, ...result.data?.meta }),
    [result.data, meta]
  );

  const attempts = useMemo(() => result.data?.entries ?? [], [result.data]);

  useEffect(() => {
    updateNoTaskByStatus(result.data?.meta.count);
    return () => {};
  }, [result.data?.meta.count, updateNoTaskByStatus]);

  return (
    <Box>
      {attempts.length > 0 && (
        <ListingComp
          title=""
          loading={result.isLoading}
          updateList={(newMeta) => {
            setMeta(newMeta);
          }}
          meta={persisMeta}
          sx={styles.list}
          mode="tasker"
        >
          <ListingComp.Content>
            <WorkerAttemptList attempts={attempts} />
          </ListingComp.Content>
        </ListingComp>
      )}

      {attempts.length === 0 && !result.isUninitialized && (
        <PortalLayout.EmptyState
          cta={IntlFormatter.getMessage(
            "taskPage.available",
            "Accept Task Now"
          )}
          graphic={
            <EmptyTasksGraphic
              style={{ height: "100px", marginBottom: "1rem", opacity: "0.7" }}
            />
          }
          label={IntlFormatter.getMessage(
            "taskPage.have_not_accept_tasks",
            "You have not accepted any tasks yet."
          )}
          onClick={() => navigate(Paths.Portal.Home)}
        />
      )}
    </Box>
  );
};
const styles = createStyles({
  list: {
    m: 0,
    backgroundColor: "inherit",
    boxShadow: "inherit",
  },
});
export default SubmittedTasks;
