import { Box, BoxProps } from "@mui/material";
import React from "react";
import { NavigateButton, Typography } from "src/main/components";
import { createStyles, joinSx, Paths, IntlFormatter } from "src/main/utils";
import { AccessTimeOutlined, CreateOutlined, CloudUploadOutlined } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { RootState } from "src/main/store";
import { WorkerTaskStatistic } from "src/main/types";

interface WalletProps extends BoxProps {

}

const TaskSummary: React.FC<WalletProps> = (props) => {
  const { sx, ...boxProps } = props;

  const taskStats = useSelector<RootState, WorkerTaskStatistic | null>(state => state.layout.taskStats);

  return (
    <Box sx={joinSx(styles.root, sx)} {...boxProps}>
      <Typography formatId="overview.myTasks" variant="subtitle2" fontWeight={700}>My Tasks</Typography>
      <NavigateButton
        icon={<CreateOutlined color="primary" />}
        title={IntlFormatter.getMessage("overview.myTask_taskInProgress", "tasks in progress")}
        titleColor="text.primary"
        chip={<Typography variant="h6" color="text.primary">{taskStats?.inprogress ?? 0}</Typography>}
        path={Paths.Portal.Tasks}
      />
      <NavigateButton
        icon={<AccessTimeOutlined color="primary" />}
        title={IntlFormatter.getMessage("overview.myTask_pendingApproval", "pending approval")}
        titleColor="text.primary"
        chip={<Typography variant="h6" color="text.primary">{taskStats?.submitted ?? 0}</Typography>}
        path={Paths.Portal.TasksSubmitted}
      />
      <NavigateButton
        icon={<CloudUploadOutlined color="primary" />}
        title={IntlFormatter.getMessage("overview.myTask_pendingResubmission", "pending resubmission")}
        titleColor="text.primary"
        chip={<Typography variant="h6" color="error">{taskStats?.rejected ?? 0}</Typography>}
        path={Paths.Portal.TasksRejected}
      />
      {/* HIDE IT FOR NOW <NavigateButton
        icon={<CursorClickIcon color="primary" />}
        title="affliate link click"
        chip={AFFILIATE_LINK_CLICK}
        subInfo={AFFILIATE_INFO}
      /> */}
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    mb: 2,
  },
});

export default TaskSummary;