import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface SectionTitleProps extends TypographyProps {

}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  const { children, ...typographyProps } = props;
  return (
    <Typography
      mt={1}
      variant="overline"
      sx={styles.root}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};

const styles = createStyles({
  root: {
    display: "block",
  },
});

export default SectionTitle;
