import { Badge, BadgeProps } from "@mui/material";
import { useSelector } from "react-redux";
import { CashBillIcon, ClipboardIcon, HomeIcon, ListIcon } from "src/assets";
import { RootState } from "src/main/store";
import { WorkerTaskStatistic } from "src/main/types";
import { Paths } from "src/main/utils";
interface CustomChipProps extends BadgeProps {
}

const CustomChip: React.FC<CustomChipProps> = (props) => {
  const { badgeContent, sx, ...rest } = props

  const taskStats = useSelector<RootState, WorkerTaskStatistic | null>(state => state.layout.taskStats);

  const getTaskStatsValue = (status: string) => {
    switch (status) {
      case "available":
      case "inprogress":
      case "submitted":
      case "rejected":
        return taskStats?.[status] ?? 0;
      case "closed":
        return (taskStats?.approved ?? 0) + (taskStats?.terminated ?? 0) + (taskStats?.cancelled ?? 0);
    }
  }

  return (
    <Badge
      {...rest}
      badgeContent={getTaskStatsValue(badgeContent as string)}
    >
    </Badge>
  )
}

export const SideBarNav = [
  {
    title: "",
    items: [
      {
        translate: {
          id:"sideBar.overview",
          defaultMessage: "Overview"
        },
        title: "Overview",
        path: Paths.Portal.Home,
        icon: <HomeIcon />,
      },
      {
        translate: {
          id:"sideBar.myTasks",
          defaultMessage: "My Tasks"
        },
        title: "My Tasks",
        icon: <ClipboardIcon />,
        path: Paths.Portal.Tasks,
        items: [{
          translate: {
            id:"sideBar.availableTasks",
            defaultMessage: "Available Tasks"
          },
          title: "Available Tasks",
          path: Paths.Portal.TasksAvailable,
          chip: <CustomChip />,
        }, {
          translate: {
            id:"sideBar.inProgress",
            defaultMessage: "In Progress"
          },
          title: "In Progress",
          path: Paths.Portal.TasksInProgress,
          chip: <CustomChip badgeContent="inprogress" color="primary" />,
        }, {
          translate: {
            id:"sideBar.underReview",
            defaultMessage: "Under Review"
          },
          title: "Under Review",
          path: Paths.Portal.TasksSubmitted,
          chip: <CustomChip />,
        }, {
          translate: {
            id:"sideBar.toResubmit",
            defaultMessage: "To Resubmit"
          },
          title: "To Resubmit",
          path: Paths.Portal.TasksRejected,
          chip: <CustomChip badgeContent="rejected" color="warning" />,
        },
        {
          translate: {
            id:"sideBar.closed",
            defaultMessage: "Closed"
          },
          title: "Closed",
          path: Paths.Portal.TasksClosed,
          chip: <CustomChip />,
        },
        ]
      },
      {
        translate: {
          id:"sideBar.taskHistory",
          defaultMessage: "Task History"
        },
        title: "Task History",
        path: Paths.Portal.History,
        icon: <ListIcon />,
      },
      {
        translate: {
          id:"sideBar.payoutSummary",
          defaultMessage: "Payout Summary"
        },
        title: "Payout Summary",
        path: Paths.Portal.Payouts,
        icon: <CashBillIcon />,
      },
    ]
  },
]
