import { Box, BoxProps, Typography, Switch, FormControlLabel, Checkbox } from "@mui/material";
import React from "react";
import { InputField } from "src/main/components";
import { AffiliatePageImage } from "src/main/types";
import { SimpleMap } from "src/main/utils";

interface ImageTemplateProps extends BoxProps {
  title: string;
  inputKey: string;
  imageContent: AffiliatePageImage;
  setInput: (prev) => void;
  usable?: boolean;
  toggleDisable?: () => void;
  linkable?: boolean;
  index: number;
  errors?: SimpleMap<string>;
  updateErrorArr?: React.Dispatch<React.SetStateAction<SimpleMap<SimpleMap<string>>>>;
}

const ImageTemplate: React.FC<ImageTemplateProps> = (props) => {
  const { updateErrorArr, errors, linkable = true, title, inputKey, imageContent, setInput, toggleDisable, usable, index } = props;
  return (
    <Box display="flex" flexDirection="column" gap={2} pt={2}>
      <Box display="flex" justifyContent="space-between" justifyItems="center">
        <Typography variant="h6">{title}</Typography>
        <Switch checked={usable} onChange={() => {
          toggleDisable?.();
          if (usable) {
            updateErrorArr?.((prev) => {
              let newPrev = { ...prev };
              delete newPrev[index]
              return newPrev
            })
          }
        }} />
      </Box>
      {usable && (
        <>
          {linkable && (
            <Box>
              <FormControlLabel
                control={(
                  <Checkbox checked={imageContent.useReferralLink} onChange={(ev, check) => {
                    setInput((prev) => {
                      let newImages = [...prev[inputKey]];
                      newImages[index] = { ...newImages[index], useReferralLink: check };

                      return {
                        ...prev,
                        [inputKey]: newImages,
                      }
                    })
                  }} />
                )}
                label={
                  <Typography>Use Tasker’s referral link for this brand</Typography>
                }
              />
            </Box>
          )}
          {imageContent.useReferralLink && (
            <Box>
              <InputField
                InputProps={{
                  readOnly: true
                }}
                label="Link"
                placeholder="<Tasker’s referral link>"
              />
            </Box>
          )}
          {linkable && (
            <Box flex={1}>
              <InputField
                label={`${imageContent.useReferralLink ? "Fallback " : ""}Link`}
                required
                disabled={!usable}
                value={imageContent.link}
                error={!!errors?.link}
                onChange={(ev) => {
                  if (!!ev.target.value) {
                    updateErrorArr?.((prev) => {
                      let newPrev = { ...prev };
                      delete newPrev[index]?.link;
                      return newPrev
                    })
                  }
                  setInput((prev) => {
                    let newImages = [...prev[inputKey]];
                    newImages[index] = { ...newImages[index], link: ev.target.value };
                    return {
                      ...prev,
                      [inputKey]: newImages
                    }
                  })
                }}
              />
            </Box>
          )}
          <Box>
            <InputField
              disabled={!usable}
              label="Image Src"
              error={!!errors?.src}
              required
              value={imageContent.src}
              onChange={(ev) => {
                if (!!ev.target.value) {
                  updateErrorArr?.((prev) => {
                    let newPrev = { ...prev };
                    delete newPrev[index]?.src;
                    return newPrev
                  })
                }
                setInput((prev) => {
                  let newImages = [...prev[inputKey]];
                  newImages[index] = { ...newImages[index], src: ev.target.value };
                  return {
                    ...prev,
                    [inputKey]: newImages
                  }
                })
              }}
            />
          </Box>

        </>
      )}

    </Box >
  );
};

export default ImageTemplate;
