import { Box, BoxProps, Button, Card, CardContent, Divider, FormControl, InputLabel, MenuItem, Select, styled, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { AffiliateButtonComps, AffiliateImageComps, FormField, LoadingButton, Page } from "src/main/components";
import { useFormState } from "src/main/hooks";
import { AccountVariableModel, AffiliatePageComponent, AffiliatePageMeta, AffiliatePageModel } from "src/main/types";
import { handleApiResponse, Paths, SimpleMap } from "src/main/utils";

import EditIcon from '@mui/icons-material/Edit';
import ShareBrand from "src/main/views/affiliate/Main/ShareBrand";
import { AffButtonCompWithErr, AffImageCompWithErr } from "../AffiliatePageCreate/AffiliatePageCreate";

interface AffiliatePageEditProps extends React.PropsWithChildren {
}

const CreateContentBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 8,
  gap: 16,
  display: "flex",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

export interface FormState {
  brandKey: string;
  name: string;
  title: string;
  logoImageSrc: string;
  backgroundImageSrc: string,
}

const initialFormState: FormState = {
  brandKey: "",
  name: "",
  title: "",
  logoImageSrc: "",
  backgroundImageSrc: "",
}

const Field = FormField<FormState>;

const AffiliatePageEdit: React.FC<AffiliatePageEditProps> = (props) => {
  const { affiliatePageId = "" } = useParams();
  const navigate = useNavigate();
  const [formStates, { onValueChange, setInput }] = useFormState(initialFormState);

  const accountVariableQueryResult = accountApi.useListAccountVariableQuery({ meta: { count: 0, limit: 1000, offset: 0 } });
  const accountVariables: AccountVariableModel<any>[] = useMemo(() => accountVariableQueryResult.data?.entries ?? [], [accountVariableQueryResult.data?.entries]);
  const [row1, setRow1] = useState<AffImageCompWithErr | null>(null);
  const [row2, setRow2] = useState<AffButtonCompWithErr | null>(null);
  const [row3, setRow3] = useState<AffImageCompWithErr | null>(null);
  const [row4, setRow4] = useState<AffImageCompWithErr | null>(null);
  const [processed, setProcessed] = useState(false);

  const [errors, setErrors] = useState<SimpleMap<string>>({});

  const clearError = (key: string) => {
    setErrors((prev) => {
      let newPrev = { ...prev };
      delete newPrev[key];
      return { ...newPrev, }
    })
  }

  const updateErrorField = (key: string, message: string) => {
    setErrors((prev) => {
      return {
        ...prev,
        [key]: message
      }
    })
  }

  const brandKeyOptions = useMemo(() => {
    if (!accountVariables) return [];

    let newOptions: SimpleMap<string>[] = [];

    accountVariables.forEach((accVar) => {
      if ("items" in accVar.content) {
        accVar.content.items.forEach((item: SimpleMap<string>) => {
          newOptions.push({ value: item.key ?? "", label: item.label ?? "", key: item.key ?? "" });
        })
      }
    })

    return newOptions
  }, [accountVariables])

  const result = accountApi.useDetailAffiliatePageQuery(affiliatePageId);
  const affiliatePageContent: AffiliatePageModel | undefined = useMemo(() => result.data ?? undefined, [result.data]);
  const [updateAffiliatePage, { isLoading: isSubmitLoading }] = accountApi.useUpdateAffiliatePageMutation();

  useEffect(() => {
    if (!affiliatePageContent) return;

    setInput((prev) => {

      const newPrev = {
        ...prev,
        brandKey: affiliatePageContent.brandKey ?? "",
        title: affiliatePageContent.config?.meta?.title ?? "",
        name: affiliatePageContent.name,
        backgroundImageSrc: affiliatePageContent.config?.meta?.backgroundImageSrc ?? "",
        logoImageSrc: affiliatePageContent.config?.meta?.logoImageSrc ?? "",
      }

      let tempRow1: AffImageCompWithErr | null = null;
      let tempRow2: AffButtonCompWithErr | null = null;
      let tempRow3: AffImageCompWithErr | null = null;
      let tempRow4: AffImageCompWithErr | null = null;

      let affComps = affiliatePageContent.config?.components ?? [];

      if (affComps[0] && affComps[0].type === "image" && affComps[1] && affComps[1].type === "button") {
        tempRow1 = affComps[0];
        tempRow2 = affComps[1];
        if (affComps[2])
          tempRow3 = affComps[2] as AffImageCompWithErr;
        if (affComps[3])
          tempRow4 = affComps[3] as AffImageCompWithErr;
      } else if (affComps[0] && affComps[0].type === "button") {
        tempRow2 = affComps[0];
        if (affComps[1])
          tempRow3 = affComps[1] as AffImageCompWithErr;
        if (affComps[2])
          tempRow4 = affComps[2] as AffImageCompWithErr;
      } else {
        tempRow1 = affComps[0];
        if (affComps[1])
          tempRow3 = affComps[1] as AffImageCompWithErr;
        if (affComps[2])
          tempRow4 = affComps[2] as AffImageCompWithErr;
      }

      if (tempRow1) setRow1(tempRow1);
      if (tempRow2) setRow2(tempRow2);
      if (tempRow3) setRow3(tempRow3);
      if (tempRow4) setRow4(tempRow4);

      setProcessed(true);


      return newPrev
    })

  }, [affiliatePageContent, setInput])


  const { meta, components } = useMemo(() => {

    const meta: AffiliatePageMeta = {
      title: formStates.input.title,
      backgroundImageSrc: formStates.input.backgroundImageSrc,
      logoImageSrc: formStates.input.logoImageSrc,
    }

    const comps: AffiliatePageComponent[] = [];

    if (!!row1?.images.length) {
      comps.push(row1)
    }

    if (!!row2?.buttons.length) {
      comps.push(row2)
    }

    if (!!row3?.images.length) {
      comps.push(row3)
    }

    if (!!row4?.images.length) {
      comps.push(row4)
    }

    return { components: comps, meta };
  }, [formStates.input, row1, row2, row3, row4]);

  const onSubmit = useCallback(async () => {
    if (!components.length) return toast.error("Please select at least 1 component.");
    try {

      let meta: SimpleMap<string> = {
        title: formStates.input.title,
      }
      if (formStates.input.backgroundImageSrc) meta.backgroundImageSrc = formStates.input.backgroundImageSrc
      if (formStates.input.logoImageSrc) meta.logoImageSrc = formStates.input.logoImageSrc

      let config = {
        meta: meta,
        components: components,
      }
      const data = {
        name: formStates.input.name,
        config: config
      }
      const result = await updateAffiliatePage({ id: affiliatePageId, data });

      const { data: parsedData, error } = handleApiResponse(result);

      if (parsedData) {
        navigate(Paths.Management.AffiliatePageDetail.replace(":affiliatePageId", affiliatePageId))

        toast.success("configPage updated");
      }

      if (error) {
        if (error.data?.error) {
          let err = error.data?.error;
          let newErr = { ...errors };

          if (err.errors) {
            Object.entries(err.errors).forEach(([key, value]) => {
              if (!key.includes("components[")) {
                newErr[key] = value.msg ?? ""
              } else if (key.includes("images[")) {

                const newArr = key.split(/[[\].]/).filter(key => key !== "")
                let compimg = config[newArr[1]]?.[newArr[2]];
                compimg?.updateErr?.({ [`${newArr[3]}[${newArr[4]}].${newArr[5]}`]: value.msg });
              } else if (key.includes("buttons[")) {

                const newArr = key.split(/[[\].]/).filter(key => key !== "")
                let compimg = config[newArr[1]]?.[newArr[2]];
                compimg?.updateErr?.({ [`${newArr[3]}[${newArr[4]}].${newArr[5]}`]: value.msg });

              } else {
                const newArr = key.split(/[[\].]/).filter(key => key !== "")

                let compimg = config[newArr[1]]?.[newArr[2]];
                compimg?.updateErr?.({ [`${newArr[3]}`]: value.msg });
              }
            })

            setErrors(newErr);
          }

        } else {
          if ((error as any).data?.error?.message === "brand key conflict") {
            setErrors({ ...errors, brandKey: "Brand key conflict" });
          }
        }

        toast.error((error as any).message ?? (error as any).data?.error?.message ?? "Error updating affiliate page!");
      }
    } catch (error: unknown) {
      toast.error("Error updating affiliate page!");
      console.error(error);
    }
    // eslint-disable-next-line
  }, [updateAffiliatePage, components, formStates.input]);

  return (
    <Page>
      <Page.TopSection>
        <Box>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => navigate(Paths.Management.AffiliatePageDetail.replace(":affiliatePageId", affiliatePageId))}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitLoading}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => onSubmit()}
          >
            <EditIcon />&nbsp;<Typography>Update</Typography>
          </LoadingButton>
        </Box>
      </Page.TopSection>
      <CreateContentBox>
        <Card sx={{ flex: 1 }}>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box gap={2}>
              <Typography variant="h5">Affiliate Page Info</Typography>
              <Box display="flex" gap={2}>
                <Box minWidth="200px">
                  <FormControl disabled sx={{ mt: 2 }} fullWidth required variant="outlined">
                    <InputLabel shrink>Brand Key</InputLabel>
                    <Select
                      label="Brand Key"
                      required
                      value={formStates.input.brandKey}
                      fullWidth
                      onChange={(ev) => {
                        onValueChange("brandKey")(ev.target.value);
                      }}
                    >
                      {brandKeyOptions.map((brands) => (
                        <MenuItem value={brands.key}>{brands.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box flex={1}>
                  <Field
                    label="Name"
                    type="text"
                    fieldKey="name"
                    placeholder="Enter a name"
                    helperText={errors["name"] ?? "For internal use only"}
                    formStates={formStates}
                    onValueChange={onValueChange}
                    clearErrorOnChange={() => clearError("name")}
                    onBlur={() => {
                      if (formStates.input.name) {
                        clearError("name");
                      } else {
                        updateErrorField("name", "Name is required");
                      }
                    }}
                    required
                    error={!!errors["name"]}
                  />
                </Box>
              </Box>
              <Field
                label="Title"
                type="text"
                fieldKey="title"
                placeholder="Page title shown on explorer tab"
                formStates={formStates}
                onValueChange={onValueChange}
                clearErrorOnChange={() => clearError("config.meta.title")}
                required
                error={!!errors["config.meta.title"]}
                helperText={errors["config.meta.title"]}
                onBlur={() => {
                  if (formStates.input.title) {
                    clearError("config.meta.title");
                  } else {
                    updateErrorField("config.meta.title", "Title is required");
                  }
                }}
              />
              <Box my={2}><Divider /></Box>
              <Typography variant="h5">Background and Logo</Typography>
              <Field
                label="Background Image"
                type="text"
                fieldKey="backgroundImageSrc"
                placeholder="Page title shown on explorer tab"
                formStates={formStates}
                onValueChange={onValueChange}
                clearErrorOnChange={() => clearError("config.meta.backgroundImageSrc")}
                error={!!errors["config.meta.backgroundImageSrc"]}
                helperText={errors["config.meta.backgroundImageSrc"]}
                onBlur={() => {
                  if (formStates.input.backgroundImageSrc) {
                    clearError("config.meta.backgroundImageSrc");
                  }
                }}
              />

              {/* <ImageTemplate
                title="Background image"
                inputKey="backgroundImage"
                imageContent={formStates.input.backgroundImage}
                setInput={setInput}
                usable={true}
                linkable={false}
                showSwitch={false}
                error={errors["config.meta.backgroundImageSrc"]}
                clearError={() => clearError("config.meta.backgroundImageSrc")}
              // updateErrors={() => updateErrorField("config.meta.backgroundImageSrc", "Background image is required!")}
              /> */}

              <Field
                label="Logo"
                type="text"
                fieldKey="logoImageSrc"
                placeholder="Logo at the top center of page"
                formStates={formStates}
                onValueChange={onValueChange}
                clearErrorOnChange={() => clearError("config.meta.logoImageSrc")}
                error={!!errors["config.meta.logoImageSrc"]}
                helperText={errors["config.meta.logoImageSrc"]}
                onBlur={() => {
                  if (formStates.input.logoImageSrc) {
                    clearError("config.meta.logoImageSrc");
                  }
                }}
              />
            </Box>
            <Box my={2}><Divider /></Box>
            {processed && !!formStates.input.brandKey && (
              <Box>
                <AffiliateImageComps aspectRatio={1} affiliateComps={row1} rowIndex={1} onCompnentChange={(ev) => { setRow1(ev); }} />
                <Box my={2}><Divider /></Box>
                <AffiliateButtonComps affiliateComps={row2} rowIndex={2} onCompnentChange={(ev) => { setRow2(ev); }} />
                <Box my={2}><Divider /></Box>
                <AffiliateImageComps aspectRatio={1} affiliateComps={row3} rowIndex={3} onCompnentChange={(ev) => { setRow3(ev); }} />
                <Box my={2}><Divider /></Box>
                <AffiliateImageComps aspectRatio={2} affiliateComps={row4} rowIndex={4} indexBypass={4} maxComps={1} onCompnentChange={(ev) => { setRow4(ev) }} />
              </Box>
            )}
          </CardContent>
        </Card>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <ShareBrand affiliateMeta={meta} affiliateComponents={components} />
          </CardContent>
        </Card>
      </CreateContentBox>
    </Page>
  );
};

export default AffiliatePageEdit; 
