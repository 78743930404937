import { Box, Table, TableBody, } from "@mui/material";
import React from "react";
import { DetailCard, InputField, TableRowWrap } from "src/main/components";
import { SimpleMap } from "src/main/utils";

interface CredentialProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  handleChange?: (e: string | React.ChangeEvent<any>) => void;
  errors?: SimpleMap<string | undefined>;
  touched?: SimpleMap<Boolean | undefined>;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const CredentialInformation = (props: CredentialProps) => {
  const { values, handleChange, handleBlur, errors = {}, touched = {}, } = props;
  return (
    <Box flex={1} pb={2}>
      <DetailCard>
        <DetailCard.Header header="Credential" />
        <DetailCard.Divider />
        <Box gap={2} >
          <Table>
            <TableBody>
              <TableRowWrap
                label="Username"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="username"
                    autoComplete='new-password'
                    value={values.username}
                    error={!!errors.username && !!touched.username}
                    helperText={!!touched.username && errors.username}
                  />
                }
              />

              <TableRowWrap
                label="Password"
                value={
                  <InputField
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    autoComplete='new-password'
                    value={values.password}
                    error={!!errors.password && !!touched.password}
                    helperText={errors.password}
                  />
                }
              />
            </TableBody>
          </Table>
        </Box>
      </DetailCard>
    </Box>
  )
}

export default CredentialInformation;