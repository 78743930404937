const slicePart = (input: string, start = 4, end, caps?: boolean) => {
  if (input.length < start + end)
    return input;
  let ellipsis = "…";
  if (start === 0 || end === 0) ellipsis = "";

  let res = ""
  if (!end) {
    res = input.slice(0, start);
  } else {
    res = [
      input.slice(0, start),
      input.slice(-end),
    ].join(ellipsis)
  }

  if (caps) {
    return res.toUpperCase();
  }
  return res;
}

const truncate = (str?: string | null | undefined, start?: number, end?: number, caps?: boolean) => {
  if (typeof str !== "string") return str;
  const parts = str.split(/[x:]/i);
  if (parts.length < 2)
    return slicePart(str, start, end, caps);

  return slicePart(parts[1], start, end, caps);
}

export default truncate;
