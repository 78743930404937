import { Box, BoxProps, TextField } from "@mui/material";
import { ClockPickerView } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import React from "react";
import { createStyles, joinSx } from "src/main/utils";

interface Props extends BoxProps {
  label?: string;
  value: Dayjs | null;
  handleChange: (val: Dayjs | null) => void;
  disabled?: boolean;
  timeFormat?: "sec" | "min" | "hour"
}

const TimePickerComp: React.FC<Props> = (props: Props) => {
  const { value, label = "Time", handleChange, disabled = false, children, timeFormat = "min", sx, ...rest } = props;

  // const [value, setValue] = React.useState<Dayjs | null>(
  //   dayjs(),
  // );

  const onChange = (newValue: Dayjs | null) => {
    handleChange(newValue);
  };

  const getView = () => {
    switch (timeFormat) {
      case "sec": return ['hours', 'minutes', 'seconds'];
      case "hour": return ['hours'];
      default: return ['hours', 'minutes'];
    }
  }

  // const getFormat = () => {
  //   switch (timeFormat) {
  //     case "sec": return "HH:mm:ss";
  //     case "hour": return "HH";
  //     default: return "HH:mm";
  //   }
  // }

  return (
    <Box  {...rest} sx={joinSx(styles.root, sx)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          // inputFormat={getFormat()}
          views={getView() as ClockPickerView[]}
          renderInput={(params) => <TextField InputLabelProps={{ shrink: true, }} fullWidth {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

const styles = createStyles({
  root: {
  },
});

export default TimePickerComp;
