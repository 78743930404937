import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { PersonModel } from "src/main/types";

interface WorkerNameDisplayProps extends TypographyProps {
  person: PersonModel | undefined | null;
  hideMiddle?: boolean;
  hideLast?: boolean;
}

const WorkerNameDisplayComponent: React.FC<WorkerNameDisplayProps> = (props) => {
  const { person, hideMiddle = false, hideLast = false, ...rest } = props;


  const getName = () => {
    if (!person) return "-";
    const { firstname, middlename, lastname } = person;
    const parseMiddle = !!middlename ? " " + middlename : "";
    const parseLast = !!lastname ? " " + lastname : "";


    if (hideMiddle && hideLast) {
      return firstname;
    } else if (hideMiddle) {
      return firstname + parseLast;
    } else if (hideLast) {
      return firstname + parseMiddle;
    } else {
      return firstname + parseMiddle + parseLast;
    }
  }

  return (
    <Typography variant="body1" {...rest}>{getName()}</Typography>
  );
};

export default WorkerNameDisplayComponent;
