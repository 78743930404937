import moment from "moment";
import React, { useMemo } from "react";
import { accountApi } from 'src/main/api';
import { BigLabelCard } from "src/main/components";
import { Paths } from "src/main/utils";

interface WithdrawTodayProps {
}
const WithdrawToday: React.FC<WithdrawTodayProps> = (props) => {
  const result = accountApi.useGetPayoutStatsQuery({ meta: { createdAt: `${moment().startOf("day").unix()},${moment().endOf("day").unix()}` } });
  const withdrawRequests = useMemo(() => result.data ?? undefined, [result.data]);

  const { processed } = useMemo(() => {
    if (!withdrawRequests?.count) return { processed: 0 };
    return { processed: withdrawRequests?.count?.processed ?? 0 };
  }, [withdrawRequests]);

  return (
    <BigLabelCard
      label="Withdraws Approved Today" routeLabel="See All Withdraws"
      value={String(processed ?? 0)}
      route={Paths.Management.WithdrawRequest} />
  );
}

export default WithdrawToday;
