const Paths = {
  Auth: {
    Index: "/auth",
    Login: "/auth/login",
    Onboard: "/auth/onboard",
    Invite: "/auth/invite",

    RequestPassword: "/auth/password/request",
    ResetPassword: "/auth/password/reset",
  },
  Affiliate: {
    Index: "/share",
    ShareBrand: "/share/:affiliateKey/:affiliatePageKey/:brand",
    LegacyShareBrand: "/share/:affiliateKey/:brand",
  },
  Overview: {
    Index: "/general",
    Overview: "/general/overview",
    Account: "/general/account",

    Users: "/general/users",
    UserDetail: "/general/user/:userId/detail",
    UserEdit: "/general/user/:userId/edit",
    CreateUser: "/general/user/create",
    Invites: "/general/user/invites",
    InviteUser: "/general/user/invite",
    AccountSetting: "/general/account/setting",
  },
  Management: {
    Index: "/management",
    Workers: "/management/workers",
    Tasks: "/management/tasks",
    TasksDetail: "/management/task/:taskId/detail",
    Payouts: "/management/payouts",
    PayoutItemDetail: "/management/payout/:payoutItemId/detail",
    CreateWorker: "/management/create-worker",
    WorkerDetail: "/management/worker/:workerId/detail",
    WorkerEdit: "/management/worker/:workerId/edit",
    WorkerExport: "/management/worker/export",

    CreateTask: "/management/create-task",
    TaskSubmission: "/management/task/submission/:submissionId/detail",
    TaskSubmissionList: "/management/task/submissions",
    WithdrawRequest: "/management/withdraw-requests",
    WithdrawRequestDetail: "/management/withdraw-requests/:withdrawRequestId/detail",
    BulkWithdraw: "/management/withdraw-request/bulk",
    WalletDetail: "/management/worker/:workerId/wallet/:walletId/detail",

    AffiliatePageHome: "/management/affiliate/page",
    AffiliatePageCreate: "/management/affiliate/page/create",
    AffiliatePageEdit: "/management/affiliate/page/:affiliatePageId/edit",
    AffiliatePageDetail: "/management/affiliate/page/:affiliatePageId/detail",
    AffiliateTrackerList: "/management/affiliate/tracker/page",
    AffiliateTrackerDetail: "/management/affiliate/tracker/:affiliateTrackerId/detail",

    Teams: "/management/teams",
    CreateTeam: "/management/create-team",
    TeamDetail: "/management/team/:teamId/detail",
    EditTeam: "/management/team/:teamId/edit",
  },
  Platform: {
    Index: "/platform",
    AllAccounts: "/platform/all-accounts",
    CreateAccount: "/platform/create-account",
    Administrators: "/platform/administrators",
    Workers: "/platform/workers",
  },
  Portal: {
    Index: "/portal",
    Home: "/portal/home",
    Tasks: "/portal/task",
    TasksTab: "/portal/task/:tab",
    TasksAvailable: "/portal/task/available",
    TasksInProgress: "/portal/task/ongoing",
    TasksSubmitted: "/portal/task/submitted",
    TasksRejected: "/portal/task/rejected",
    TasksClosed: "/portal/task/closed",
    TaskDetail: "/portal/task/:taskId/detail",
    AttemptDetail: "/portal/task/attempt/:attemptId",
    History: "/portal/history",
    Payouts: "/portal/payout",
    Withdraw: "/portal/withdraw",
    WithdrawHistoryList: "/portal/withdraw/history",
    WithdrawRequestDetail: "/portal/withdraw/:withdrawId/detail",
    WithdrawPending: "/portal/withdraw/pending",
    Settings: "/portal/settings",
  },
}

export const makeRelativePath = (rootPath: string) => {
  return (path: string, suffix = "") => path.replace(rootPath, "").concat(suffix);
}

export default Paths
