import { CancelOutlined, CheckCircleOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Icon, SxProps, useTheme } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { CircleXFillIcon, LinkIcon, PhotoIcon, TextIcon, CheckCircleIconFill } from "src/assets";
import { TaskObjectiveModel } from "src/main/types";
import { createStyles, joinSx, IntlFormatter } from "src/main/utils";
import { GoalTaskObjectModel } from "src/main/views/portal/Main/TaskDetailPage/components/TaskGoal/TaskGoal";
import { Typography } from "src/main/components";

interface TaskObjectiveProps {
  objectiveModel: GoalTaskObjectModel;
  editable?: boolean;
  onClick?: (obj: TaskObjectiveModel) => void;
  sx?: SxProps;
  circleIconFlag?: boolean;
}

const getTypeIcon = (type) => {
  switch (type) {
    case "screenshot":
      return <PhotoIcon sx={styles.icon} />
    case "text":
      return <TextIcon sx={styles.icon} />
    case "link":
      return <LinkIcon sx={styles.icon} />
  }

}
const TaskObjective: React.FC<TaskObjectiveProps> = (props) => {
  const { objectiveModel, editable = false, onClick, sx, circleIconFlag } = props;
  const theme = useTheme();
  const newSx = joinSx(styles.base, sx);

  const _editOnClick = () => {
    (editable) ? onClick?.(objectiveModel.objective) : toast.error(IntlFormatter.getMessage("taskObjective.toastError_have_not_accept_task", "You've not accept the task yet"));
  }

  return (
    <Box
      onClick={() => { if (!circleIconFlag) _editOnClick() }}
      sx={joinSx(newSx, { cursor: (!editable) ? "null" : (!circleIconFlag) ? "pointer" : "null" })}
    >
      {
        circleIconFlag && (
          <>
            {
              !!objectiveModel.proof ?
                !objectiveModel.proof?.rejectReason ?
                  <CheckCircleIconFill sx={joinSx(styles.taskGoalCircleCheck, { color: "success.main" })} /> :
                  <CircleXFillIcon sx={joinSx(styles.taskGoalCircleCheck, { color: "error.main" })} /> :
                <Box sx={styles.taskGoalCircle} />
            }
          </>
        )
      }
      <Box flexDirection="column" flex={12}>
        <Typography variant="subtitle2" fontWeight="700" lineHeight={1.375}>
          {objectiveModel.objective.instruction}
        </Typography>
        <Chip
          size="small"
          icon={getTypeIcon(objectiveModel.objective.type)}
          label={objectiveModel.objective.type}
          sx={
            {
              textTransform: 'capitalize', px: .5,
              "&.MuiChip-root": {
                borderRadius: 10,
                backgroundColor: "neutral.100"
              },
            }
          }
        />
        {!!objectiveModel.proof?.rejectReason && (
          <>
            <Typography color="error" variant="subtitle2" sx={styles.rejectedText}>Rejected: {objectiveModel.proof?.rejectReason}</Typography>
            <Typography variant="subtitle2" sx={joinSx(styles.rejectedText, { color: objectiveModel.proof.isResubmitted ? theme.palette.info.main : theme.palette.warning.main })}>
              {!!objectiveModel.proof.isResubmitted ? IntlFormatter.getMessage("taskObjective.resubmitted", "Resubmitted") : IntlFormatter.getMessage("taskObjective.pleaseSubmit", "Please resubmit")}
            </Typography>
          </>
        )}
      </Box>

      {
        !circleIconFlag && <Icon>
          {!!objectiveModel.proof?.rejectReason && !objectiveModel.proof.isResubmitted && (<CancelOutlined color="error" />)}
          {!!objectiveModel.proof?.rejectReason && !!objectiveModel.proof.isResubmitted && (<CheckCircleOutlineOutlined color="success" />)}
          {!objectiveModel.proof?.rejectReason && (
            <>
              {!!objectiveModel.proof && (
                <CheckCircleOutlineOutlined color="success" />
              )}
              {(!objectiveModel.proof && editable) && (
                <EditOutlined sx={styles.icon} color="inherit" />
              )}
            </>
          )}
        </Icon>
      }
      {
        (circleIconFlag && editable) && <Button disabled={!editable} onClick={() => _editOnClick()} color={objectiveModel.proof ? "success" : "primary"}>{objectiveModel.proof ? IntlFormatter.getMessage("taskObjective.update", "Update") : IntlFormatter.getMessage("taskObjective.start", "Start")}</Button>
      }
    </Box>
  );
};

const styles = createStyles({
  type: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 10,
    opacity: .5,
  },
  rejectedText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 10,
  },
  icon: {
    color: "text.secondary",
    opacity: .6,
  },
  base: {
    alignItems: 'center',
    display: 'flex',
    px: 2,
    py: 1.5,
  },
  taskGoalCircle: {
    backgroundColor: "white",
    borderRadius: "50%",
    border: "2px solid",
    borderColor: "neutral.300",
    width: "15px", height: "15px",
    display: "inline-block",
    mx: 2.15,
    left: 0,
    mt: .5
  },
  taskGoalCircleCheck: {
    ml: 2, mr: 2,
    left: 0,
    fontSize: "18px",
    backgroundColor: "white",
    color: "neutral.300",
    top: "20px",
  }
});


export default TaskObjective;
