import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogProps, TextField, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { QueryWorkerResponse } from "src/main/api/account/Worker";
import { InputField } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { Meta, WorkerModel } from "src/main/types";
import { handleApiResponse } from "src/main/utils";
import { RootState } from 'src/main/store';
import { useSelector } from 'react-redux';

interface GenerateAffiliateLinkDialogProps extends Omit<DialogProps, "open"> {
  loading?: boolean;
  open?: boolean;
  brandKey: string;
  affiliatePageKey: string;
}

interface WorkerOption {
  value: string;
  label: string;
  worker: WorkerModel;
}

//const BASE_URL = process.env.REACT_APP_AFFILIATE_PAGE;

const GenerateAffiliateLinkDialog: React.FC<GenerateAffiliateLinkDialogProps> = (props) => {
  const { affiliatePageKey, brandKey, onClose, loading, open = false, ...dialogProps } = props;

  const affiliateUrl = useSelector<RootState, string | null>((state) => state.preference.affiliateLink);

  const result = accountApi.useListWorkerQuery({ meta: { count: 0, limit: 100000, offset: 0 } as Meta });
  const workerListResult: QueryWorkerResponse | undefined = useMemo(() => result.data ?? undefined, [result.data]);

  const [selected, setSelected] = useState<WorkerOption | null>(null);

  const workerOptions = useMemo(() => {
    if (!workerListResult?.entries) return [];

    let opt: WorkerOption[] = []

    workerListResult.entries.forEach((entry) => {

      if (selected && selected.value === entry.id) {
        setSelected({
          ...selected,
          worker: entry
        })
      }

      const { firstname, middlename, lastname } = entry.person;
      const parseMiddle = !!middlename ? " " + middlename : "";
      const parseLast = !!lastname ? " " + lastname : "";

      opt.push({
        value: entry.id,
        label: firstname + parseMiddle + parseLast,
        worker: entry,
      })
    })

    return opt;
    // eslint-disable-next-line
  }, [workerListResult]);

  const [generateWorkerAffiliate, { isLoading }] = accountApi.useGenerateWorkerAffiliateMutation();
  const [runGenerateAffiliate] = useAsyncTask("generate/affiliate/key");

  const onGenerate = useCallback(() => {
    runGenerateAffiliate(async () => {
      if (!selected?.worker.id) return;
      const result = await generateWorkerAffiliate(selected?.worker?.id);

      const { data, error } = handleApiResponse<WorkerModel>(result);

      if (data) {
        toast.success("Affiliate key generated!");
      }
      if (error) {
        toast.error(error.data?.error.message ?? "Error generating affiliate key!");
      }

    })
  }, [generateWorkerAffiliate, runGenerateAffiliate, selected?.worker?.id])

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
    setSelected(null);
  }

  return (
    <Dialog open={open} fullWidth {...dialogProps} onClose={_onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" pt={2}>
          <Typography variant="h6">Affiliate Link</Typography>
        </Box>
        <Box pt={2} display="flex" flexDirection="column">
          <Typography variant="subtitle2">Select a Tasker</Typography>
          <Autocomplete
            options={workerOptions}
            onChange={(ev, newSelected) => { setSelected(newSelected) }}
            renderInput={(params) => <TextField {...params} />}
          />
          {/* <Select
              name="workers"
              onChange={(ev: SelectChangeEvent) => {
                if (!ev.target.value) setSelected(null)
                let found = workerOptions.find((worker) => worker.value === ev.target.value);
                setSelected(found ?? null);
              }}
            >
              {workerOptions.map((worker) => (
                <MenuItem value={worker.value}>{worker.label}</MenuItem>
              ))}
              

            </Select> */}

        </Box>
        <Box>
          {selected?.worker && selected.worker.affiliateKey && (
            <Box pt={2}>
              <InputField
                label="Affiliate Link"
                InputProps={{
                  readOnly: true,
                }}
                value={affiliateUrl?.replace(":affiliateKey", selected.worker.affiliateKey).replace(":affiliatePageKey", affiliatePageKey).replace(":brand", brandKey)}
              />
            </Box>
          )}
          {selected?.worker && !selected.worker.affiliateKey && (
            <Box display="flex" flexDirection="column" pt={2} gap={2}>
              <Box flex={1}>
                <Typography>
                  Tasker does not have an Affiliate Key
                </Typography>
              </Box>
              <Box flex={1}>
                <LoadingButton
                  fullWidth
                  loading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => onGenerate()}
                >
                  Generate Affiliate Key
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          sx={{ color: "gray" }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          Close
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
};

export default GenerateAffiliateLinkDialog;
