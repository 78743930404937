import BigNumber from "bignumber.js";
import moment from "moment";
import queryString from "query-string";
import { BuilderProp, require200Status } from "src/main/api/account";
import { AccountModel, Meta, PersonModel, QueryResult, WalletModel, WorkerModel } from "src/main/types";
import { SimpleMap } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";
import { QueryResultEntries, QueryResultModel } from "../../auth/endpoints";

export interface CreateWorkerRequest {
  data: {
    firstname: string;
    middlename: string;
    lastname: string;
    salutation: string;
    gender: string;
    primaryEmail: string;
    primaryPhone: string;
    discordHandle: string;
    instagramHandle: string;
    tiktokHandle: string;
    twitterHandle: string;
    telegramHandle: string;
    facebookHandle: string;
    status: string;
  }
}
export interface CreateWorkerResponse extends WorkerModel {
  account: AccountModel;
  person: PersonModel;
}

export interface QueryWorkerResponse {
  entries: WorkerModel[];
  meta: Meta;
}

export interface QueryWorkerRequest {
  meta: Meta;
}

export interface GetWorkerRequest {
  workerId: string;
}

export interface GetWorkerResponse {
  model: WorkerModel;
}

export interface UpdateWorkerResponse {
  model: WorkerModel;
}

export interface UpdateWorkerRequest {
  // model: {
  //   status?: string;
  //   credential?: {
  //     accessKey?: string;
  //     secret?: string;
  //   },
  //   person?: SimpleMap<string | number | null | undefined>;
  // }   ;
  model: SimpleMap<any>;
  workerId: string;
}

export interface UpdatePersonRequest {
  model: PersonModel;
  personId: string;
}

export interface UpdatePersonResponse {
  model: PersonModel;
}

export interface ListWorkerWalletsRequest {
  workerId: string;
  meta: Meta;
}

export interface GetWorkerWalletDetailRequest {
  workerId: string;
  walletId: string;
}
export interface GetWorkerWalletDetailResponse {
  model: WalletModel;
}

export interface ListWorkerWalletsResponse {
  meta: Meta;
  entries: WalletModel[];
}

const transformWorker = (workerData: any) => {
  workerData.onboardAt = typeof workerData.onboardAt !== "string" ? null : moment(workerData.onboardAt);
  workerData.score = !!workerData.score ? BigNumber(workerData.score + "").toNumber() : null;
}

export const createWorker = (builder: BuilderProp) => {
  return builder.mutation<CreateWorkerResponse, CreateWorkerRequest>({
    invalidatesTags: ["worker"],
    query: (props: CreateWorkerRequest) => ({
      url: `/account/worker/create`,
      method: "POST",
      body: props.data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CreateWorkerResponse>) => {
      transformWorker(response.result);
      return response.result;
    },
  })
}

export const listWorker = (builder: BuilderProp) => {
  return builder.query<QueryWorkerResponse, QueryWorkerRequest>({
    providesTags: ["worker"],
    query: ({ meta }) => ({
      url: `/account/worker/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryWorkerResponse>) => {
      response.result.entries.forEach(transformWorker);
      return response.result;
    },
  })
}

export const getWorker = (builder: BuilderProp) => {
  return builder.query<GetWorkerResponse, GetWorkerRequest>({
    providesTags: ["worker"],
    query: ({ workerId }) => ({
      url: `/account/worker/${workerId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetWorkerResponse>) => {
      transformWorker(response.result.model);
      return response.result;
    }
  })
}

export const updateWorker = (builder: BuilderProp) => {
  return builder.mutation<UpdateWorkerResponse, UpdateWorkerRequest>({
    invalidatesTags: ["worker"],
    query: ({ workerId, model }) => ({
      url: `/account/worker/${workerId}/update`,
      method: "POST",
      body: model,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateWorkerResponse>) => {
      transformWorker(response.result.model);
      return response.result;
    },
  })
}

export const onboardWorker = (builder: BuilderProp) => {
  return builder.mutation<QueryResultModel<WorkerModel>, string>({
    invalidatesTags: ["worker"],
    query: (workerId) => ({
      url: `/account/worker/${workerId}/onboard`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultModel<WorkerModel>>) => {
      transformWorker(response.result.model);
      return response.result;
    },
  })
}

export const updateWorkerPerson = (builder: BuilderProp) => {
  return builder.mutation<UpdatePersonResponse, UpdatePersonRequest>({
    invalidatesTags: ["worker"],
    query: ({ personId, model }) => ({
      url: `/account/person/${personId}/update`,
      method: "POST",
      body: model,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdatePersonResponse>) => {
      return response.result;
    },
  })
}

export interface QueryAccountVariableRequest {
  type?: string;
  key?: string;
}
export interface AccountVariableResponse {
  content?: {
    items?: {
      key: string;
      label: string;
    }[],
  }
}
export const queryAccountVariables = (builder: BuilderProp) => {
  return builder.query<AccountVariableResponse, QueryAccountVariableRequest>({
    providesTags: ['worker'],
    query: (query) => ({
      url: `/account/variable/list?${queryString.stringify(query)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultEntries<AccountVariableResponse>>) => {
      console.log("/account/variable/list", response);
      return response.result.entries[0] ?? null;
    }
  })
}

export const listWorkerWallets = (builder: BuilderProp) => {
  return builder.query<ListWorkerWalletsResponse, ListWorkerWalletsRequest>({
    providesTags: ['worker'],
    query: ({ workerId, meta }) => ({
      url: `/account/worker/${workerId}/wallet/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListWorkerWalletsResponse>) => {
      return response.result ?? null;
    }
  })
}

export const getWorkerWalletDetail = (builder: BuilderProp) => {
  return builder.query<GetWorkerWalletDetailResponse, GetWorkerWalletDetailRequest>({
    providesTags: ['worker'],
    query: ({ walletId, workerId }) => ({
      url: `/account/worker/${workerId}/wallet/${walletId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetWorkerWalletDetailResponse>) => {
      return response.result ?? null;
    }
  })
}

export const generateWorkerAffiliate = (builder: BuilderProp) => {
  return builder.mutation<QueryResultModel<WorkerModel>, string>({
    invalidatesTags: ["worker"],
    query: (workerId) => ({
      url: `/account/worker/${workerId}/affiliate`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultModel<WorkerModel>>) => {
      transformWorker(response.result.model);
      return response.result;
    }
  })
}