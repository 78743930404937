import { useTheme } from "@mui/material";
import { ApexOptions } from 'apexcharts';
import BigNumber from "bignumber.js";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { accountApi } from 'src/main/api';
import { ListWalletBalanceResponse } from "src/main/api/account/Stats";
import { Chart } from "src/main/components";
import { ChartProps } from "src/main/components/Chart/Chart";
import { RootState } from "src/main/store";
import { Paths } from "src/main/utils";

interface WalletBalanceProps extends Partial<ChartProps> {
}
const WalletBalance: React.FC<WalletBalanceProps> = (props) => {
  const theme = useTheme();
  const currentTheme = useSelector<RootState>(state => state.preference.theme);

  const result = accountApi.useListWalletBalanceQuery({});
  const responseWalletBalance: ListWalletBalanceResponse | undefined = useMemo(() => result.data ?? undefined, [result.data]);

  const { chartOpts, series } = useMemo(() => {
    const chartOpts: ApexOptions = {
      chart: {
        background: 'transparent', stacked: false, toolbar: { show: false },
      },
      theme: {
        monochrome: {
          enabled: true,
          color: currentTheme === "light" ? theme.palette.primary.light : theme.palette.primary.dark,
          shadeTo: 'dark', shadeIntensity: 0.65
        }
      },
      dataLabels: { enabled: true },
      fill: { opacity: 1 },
      labels: [] as string[],
      legend: { show: true, position: 'bottom' },
      stroke: { width: 0 },
    };
    const series: number[] = [];
    if (responseWalletBalance) {
      Object.keys(responseWalletBalance).forEach((walletItem) => {
        const wallet = responseWalletBalance[walletItem];
        let chartOpLabels = chartOpts.labels ?? [];
        if (chartOpLabels.indexOf(wallet.currency) < 0) {
          chartOpts.labels?.push(`${wallet.balance} ${wallet.currency} ($${BigNumber(wallet.balanceInUSD).toFixed(0)})`);
          series.push(parseInt(wallet.balance));
        }
      })
    }
    return { chartOpts, series };
  }, [responseWalletBalance, theme, currentTheme]);

  return (
    <Chart
      {...props}
      title="Tasker Balance"
      subtitle="All Wallet Balances"
      chartOptions={chartOpts}
      chartSeries={series}
      height={300}
      type="donut"
      shortcuts={{
        footer: {
          label: "Taskers Balances",
          to: Paths.Management.Workers,
        },
      }}
    />
  )
}
export default WalletBalance;
