import React from "react";
import { Route, Routes } from "react-router-dom";
import { makeRelativePath, Paths } from "src/main/utils";
import { MainDashboardLayout } from "../../components";
import { AllAccountPage, CreateAccountPage } from "./Accounts";
import { PlatformAdministratorPage } from "./Administrators";
import { PlatformWorkerPage } from "./Workers";

interface MainProps extends React.PropsWithChildren {}

const Main: React.FC<MainProps> = () => {
  const p = makeRelativePath(Paths.Platform.Index);
  return (
    <MainDashboardLayout>
      <Routes>
        <Route
          path={p(Paths.Platform.AllAccounts)}
          element={<AllAccountPage />}
        />
        <Route
          path={p(Paths.Platform.CreateAccount)}
          element={<CreateAccountPage />}
        />
        <Route
          path={p(Paths.Platform.Administrators)}
          element={<PlatformAdministratorPage />}
        />
        <Route
          path={p(Paths.Platform.Workers)}
          element={<PlatformWorkerPage />}
        />
      </Routes>
    </MainDashboardLayout>
  );
};

export default Main;
