import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, useTheme } from "@mui/material";
import React from "react";
import { DetailCard, Typography } from "src/main/components";
import { IntlFormatter, createStyles } from "src/main/utils";


export interface WithdrawalModel {
  amount: string;
  currency: string;
}
interface ConfirmWithdrawRequestDialogProps extends Omit<DialogProps, "open"> {
  withdrawalDetails: WithdrawalModel;
  open?: boolean;
  confirmWithdrawal?: () => void;
}

const styles = createStyles({
  amountBoxRow: {
    display: "flex", justifyContent: 'space-between',
  }
})

const ConfirmWithdrawRequestDialog: React.FC<ConfirmWithdrawRequestDialogProps> = (props) => {
  const { open = false, onClose, confirmWithdrawal, withdrawalDetails } = props;
  const { palette } = useTheme();

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  return (
    <Dialog open={open} onClose={_onClose} >
      <DialogContent>
        <DetailCard.Header
          sx={{ p: 0 }}
          header={IntlFormatter.getMessage("withdrawRequestPage.confirm_withdrawal", "Confirm Withdrawal")}
        />
        <Box p={2} sx={{ backgroundColor: "primary.ghost", borderRadius: "10px" }}>
          <Box sx={styles.amountBoxRow}>
            <Typography formatId="withdrawRequestPage.amount_to_withdraw" variant="body2">Amount to withdraw</Typography>
            <Typography variant="body2" fontWeight={700}>{withdrawalDetails.amount} {withdrawalDetails.currency}</Typography>
          </Box>
          <Box sx={styles.amountBoxRow}>
            <Typography formatId="withdrawRequestPage.you_will_receive" variant="body2">You will receive:</Typography>
            <Typography variant="body2" fontWeight={700}>{withdrawalDetails.amount} {withdrawalDetails.currency}</Typography>
          </Box>
          <Box sx={styles.amountBoxRow}>
            <Typography formatId="withdrawRequestPage.transaction_fee" variant="body2">Transaction fee:</Typography>
            <Typography variant="body2" fontWeight={700}>+0.00 {withdrawalDetails.currency}</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography formatId="withdrawRequestPage._review_information_before_submmit" variant="caption" color="textSecondary" display="block" lineHeight={1.375}>Please review the above information before submitting. Click cancel if you would like to make changes to the withdrawal amount.</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mr: 2, flexShrink: 0 }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          {IntlFormatter.getMessage("withdrawRequestPage.cancel", "Cancel")}
        </Button>
        <Button
          variant="contained"
          sx={{ mr: 2, flexShrink: 0, background: palette.gradient?.main }}
          onClick={() => {
            confirmWithdrawal?.();
            _onClose({}, "escapeKeyDown")
          }}
        >
          {IntlFormatter.getMessage("withdrawRequestPage.submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  )

}



export default ConfirmWithdrawRequestDialog;
