import { createSvgIcon } from '@mui/material/utils';

const CashBill = createSvgIcon(
  <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 5.12158V3.12158C15 2.59115 14.7893 2.08244 14.4142 1.70737C14.0391 1.3323 13.5304 1.12158 13 1.12158H3C2.46957 1.12158 1.96086 1.3323 1.58579 1.70737C1.21071 2.08244 1 2.59115 1 3.12158V9.12158C1 9.65202 1.21071 10.1607 1.58579 10.5358C1.96086 10.9109 2.46957 11.1216 3 11.1216H5M7 15.1216H17C17.5304 15.1216 18.0391 14.9109 18.4142 14.5358C18.7893 14.1607 19 13.652 19 13.1216V7.12158C19 6.59115 18.7893 6.08244 18.4142 5.70737C18.0391 5.3323 17.5304 5.12158 17 5.12158H7C6.46957 5.12158 5.96086 5.3323 5.58579 5.70737C5.21071 6.08244 5 6.59115 5 7.12158V13.1216C5 13.652 5.21071 14.1607 5.58579 14.5358C5.96086 14.9109 6.46957 15.1216 7 15.1216ZM14 10.1216C14 10.652 13.7893 11.1607 13.4142 11.5358C13.0391 11.9109 12.5304 12.1216 12 12.1216C11.4696 12.1216 10.9609 11.9109 10.5858 11.5358C10.2107 11.1607 10 10.652 10 10.1216C10 9.59115 10.2107 9.08244 10.5858 8.70737C10.9609 8.3323 11.4696 8.12158 12 8.12158C12.5304 8.12158 13.0391 8.3323 13.4142 8.70737C13.7893 9.08244 14 9.59115 14 10.1216Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  ,
  'CashBill'
);

export default CashBill;