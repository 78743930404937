import { Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DateDisplay, ListingComp, Page, Table } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { Meta } from 'src/main/types';
import { Paths } from "src/main/utils";
import CancelInviteButton from "./components/CancelInviteButton";
import CancelInviteConfirmation from "./components/CancelInviteConfirmation";

import { RootState } from "src/main/store";
import { LoadingTasks } from "src/main/store/layout/types";
import { useSelector } from "react-redux"



interface InvitesPageProps extends React.PropsWithChildren {

}

const TaskHeaders = [
  { value: "Name" },
  { value: "Email" },
  { value: "Invited by" },
  { value: "Invited at" },
  { value: "Status" },
  { value: "" },
]
const STATUS_OPTION = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "accepted", label: "Accepted" },
  { value: "cancelled", label: "Cancelled" },
]

const InvitesPage: React.FC<InvitesPageProps> = (props) => {
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })
  const result = accountApi.useListInvitesQuery({ meta });
  const invites = useMemo(() => result.data ?? undefined, [result.data]);
  const persisMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])

  const onStatusUpdate = (status: string) => {
    let newMeta = { ...persisMeta };
    delete newMeta.status;

    if (status !== "all") {
      newMeta.status = status;
    }

    setMeta({ ...newMeta, offset: 0 });
  }

  const loadingTasks = useSelector<RootState, LoadingTasks>((store) => store.layout.loadingTasks);
  const [targetInviteId, setTargetInviteId] = useState<string | null>(null);

  const onOpenConfirmDialog = (theTargetId) => {
    setTargetInviteId(theTargetId);
  }
  const onCloseConfirmDialog = () => {
    setTargetInviteId(null);
  }

  return (
    <Page>
      <Page.TopSection title="User invites">
        <Button
          component={RouterLink}
          to={Paths.Overview.InviteUser}
          size="large"
          type="submit"
          variant="contained"
        >
          New Invite
        </Button>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading} title="Invites"
        updateList={(newMeta) => { setMeta(newMeta); }}
        meta={persisMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
          <ListingComp.Statusbar defaultSelected={"all"} options={STATUS_OPTION} onStatusUpdate={onStatusUpdate} />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          <Table>
            <Table.Head headers={TaskHeaders} />
            <Table.Body>
              {invites?.entries?.map((invite) => (
                <Table.Row>
                  <Table.Cell value={invite.name} />
                  <Table.Cell value={invite.email} />
                  <Table.Cell value={invite.inviter?.firstname} />
                  <Table.Cell value={<DateDisplay value={invite.createdAt} />} />
                  <Table.Cell>
                    <StatusChip status={invite.status} />
                  </Table.Cell>
                  <Table.Cell align="right">
                    {invite.status === "pending" && (
                      <CancelInviteButton loading={!!loadingTasks[invite.id]} inviteId={invite.id} onCancelConfirm={onOpenConfirmDialog} />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
      {!!targetInviteId && <CancelInviteConfirmation
        open={!!targetInviteId}
        onClose={onCloseConfirmDialog}
        targetInviteId={targetInviteId}
      />}
    </Page>
  );
};

export default InvitesPage;
