
import { BuilderProp, require200Status } from "src/main/api/account";
import { Meta, QueryResult, WithdrawRequestModel } from "src/main/types";
import { QueryWithMeta } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";

export interface MetaRequest {
  meta: Meta;
}

export interface ListWithdrawResponse {
  entries: WithdrawRequestModel[];
  meta: Meta;
}

export interface ProcessWithdrawRequest {
  withdrawRequestId: string;
}

export interface WithdrawRequestResponse {
  model: WithdrawRequestModel;
}


export interface GetWithdrawRequest {
  withdrawRequestId: string;
}

export interface BulkProcessWithdrawRequest {
  entries: { withdrawRequestIds: string[] };
}
export interface BulkProcessWithdrawResponse {
  entries: WithdrawRequestModel[];
}

export const listWithdrawRequest = (builder: BuilderProp) => {
  return builder.query<ListWithdrawResponse, QueryWithMeta>({
    providesTags: ["withdraw"],
    query: ({ meta }) => ({
      url: `/account/withdraw/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListWithdrawResponse>) => {
      return response.result;
    },
  })
}


export const getWithdrawRequest = (builder: BuilderProp) => {
  return builder.query<WithdrawRequestResponse, GetWithdrawRequest>({
    providesTags: ["withdraw"],
    query: ({ withdrawRequestId }) => ({
      url: `/account/withdraw/${withdrawRequestId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<WithdrawRequestResponse>) => {
      return response.result;
    },
  })
}


export const processWithdrawRequest = (builder: BuilderProp) => {
  return builder.mutation<WithdrawRequestResponse, ProcessWithdrawRequest>({
    invalidatesTags: ["withdraw"],
    query: ({ withdrawRequestId }) => ({
      url: `/account/withdraw/${withdrawRequestId}/process`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<WithdrawRequestResponse>) => {
      return response.result;
    },
  })
}

export const BulkProcessWithdraw = (builder: BuilderProp) => {
  return builder.mutation<BulkProcessWithdrawResponse, BulkProcessWithdrawRequest>({
    invalidatesTags: ["withdraw"],
    query: ({ entries }) => ({
      url: `/account/withdraw/process/bulk`,
      method: "POST",
      body: entries,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<BulkProcessWithdrawResponse>) => {
      return response.result;
    },
  })
}

