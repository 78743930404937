import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import {
  Box,
  BoxProps,
  Chip,
  ChipPropsSizeOverrides,
  SxProps,
  Tooltip,
  Typography,
  capitalize,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { OverridableStringUnion } from "@mui/types";
import React from "react";
import { useSelector } from "react-redux";
import {
  CircleXIcon,
  EditOrDraftIcon,
  OnGoingIcon,
  StopIcon,
} from "src/assets";
import { RootState } from "src/main/store";
import { ThemeMode } from "src/main/store/preference/types";

interface StatusChipProps extends BoxProps {
  status: string;
  color?: string;
  compactMode?: boolean;
  textOnly?: boolean;
  textProps?: SxProps | undefined;
  overrideColor?: string;
  size?:
    | OverridableStringUnion<"small" | "medium", ChipPropsSizeOverrides>
    | undefined;
  iconFontSize?: string;
}

const getStatusColor = (
  status: string,
  theme: Theme,
  overrideColor?: string
) => {
  const { palette } = theme;
  switch (overrideColor ?? status.toLowerCase()) {
    case "suspended":
    case "cancelled":
      return palette.grey[500];
    case "not-started":
    case "pending":
      return palette.warning.main;
    case "rejected":
    case "terminated":
    case "error":
    case "expired":
      return palette.error.main;
    case "published":
    case "active":
      return palette.success.main;
    case "submitted":
    case "draft":
      return palette.info.main;
    default:
      return palette.success.main;
  }
};

const getBorderColor = (
  status: string,
  theme: Theme,
  curTheme: ThemeMode,
  overrideColor?: string
) => {
  const { palette } = theme;
  switch (overrideColor ?? status.toLowerCase()) {
    case "suspended":
    case "cancelled":
      return palette.grey[500];
    case "not-started":
    case "pending":
      return palette.warning[curTheme];
    case "rejected":
    case "terminated":
    case "error":
    case "expired":
      return palette.error[curTheme];
    case "published":
    case "active":
      return palette.success[curTheme];
    case "submitted":
      return palette.info[curTheme];
    case "draft":
      return "transparent";
    default:
      return palette.success[curTheme];
  }
};

const getStatusIcon = (
  status: string,
  iconFontSize: string,
  color?: string
) => {
  switch (status.toLowerCase()) {
    case "draft":
      return (
        <EditOrDraftIcon sx={{ mr: 0.5, color, fontSize: iconFontSize }} />
      );
    case "not-started":
      return <StopIcon sx={{ mr: 0.5, color, fontSize: iconFontSize }} />;
    case "pending":
    case "submitted":
      return (
        <PendingOutlinedIcon sx={{ mr: 0.5, color, fontSize: iconFontSize }} />
      );
    case "expired":
    case "rejected":
    case "cancelled":
    case "terminated":
    case "suspended":
      return <CircleXIcon sx={{ mr: 0.5, color, fontSize: iconFontSize }} />;
    case "active":
    case "published":
      return <OnGoingIcon sx={{ mr: 0.5, color, fontSize: iconFontSize }} />;
    case "approved":
    case "completed":
      return (
        <CheckCircleIcon sx={{ mr: 0.5, color, fontSize: iconFontSize }} />
      );
    default:
      return <Box sx={{ paddingLeft: 1 }}></Box>;
  }
};

const getStatusTooltip = (status: string) => {
  switch (status.toLowerCase()) {
    case "published":
      return "Task is being published.";
    case "draft":
      return "Task has been added drafted.";
    case "rejected":
      return "Task has been rejected.";
    case "expired":
      return "Task has expired.";
    case "completed":
      return "Task have been completed.";

    case "failed":
      return "Task has failed.";
    default:
      return "";
  }
};

const getBackgroundColor = (
  status: string,
  theme: Theme,
  curTheme: ThemeMode
) => {
  const { palette } = theme;
  switch (status.toLowerCase()) {
    case "draft":
      return palette.info.alert.background;
    default:
      return "transparent";
  }
};

const getText = (status: string) => {
  switch (status.toLowerCase()) {
    case "not-started":
      return "Not Started";
    default:
      return capitalize(status);
  }
};

const StatusChipComponent: React.FC<StatusChipProps> = (props) => {
  const {
    overrideColor,
    size = "small",
    status,
    color,
    compactMode,
    textProps,
    textOnly,
    iconFontSize = "16px",
  } = props;
  const theme = useTheme();
  const curTheme = useSelector<RootState, ThemeMode>(
    (state) => state.preference.theme
  );
  const statusColor: string = getStatusColor(status, theme, overrideColor);
  const borderColor: string = getBorderColor(
    status,
    theme,
    curTheme,
    overrideColor
  );
  const backgroundColor: string = getBackgroundColor(status, theme, curTheme);
  if (compactMode)
    return <>{getStatusIcon(status, iconFontSize, statusColor)}</>;
  if (textOnly)
    return (
      <Box sx={{ "& .MuiTypography-root": { color: statusColor } }}>
        <Typography sx={{ ...textProps }}>{getText(status)}</Typography>
      </Box>
    );

  return (
    <Tooltip title={getStatusTooltip(status)}>
      <Chip
        size={size}
        sx={{
          "&.MuiChip-root": {
            backgroundColor: backgroundColor,
            borderRadius: "8px",
            border: `1px solid ${borderColor}`,
            paddingLeft: 0,
          },
          "& > .MuiChip-label": {
            paddingLeft: 0.5,
          },
        }}
        label={
          <Box
            sx={{
              color: color ?? statusColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            {getStatusIcon(status, iconFontSize)}
            {getText(status)}
          </Box>
        }
      />
    </Tooltip>
  );
};

export default StatusChipComponent;
