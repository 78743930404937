import { Typography, TypographyProps } from "@mui/material";
import React from "react";

interface MonoLabelProps extends TypographyProps {

}

const MonoLabel: React.FC<MonoLabelProps> = (props) => {
  const { children, sx, ...rest } = props;
  return (
    <Typography
      color="textSecondary"
      gutterBottom
      variant="overline"
      sx={sx}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default MonoLabel;
