import { createSvgIcon } from '@mui/material/utils';

const CircleCancelIcon = createSvgIcon(
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.26757 3.10039C6.8354 3.10039 5.46189 3.66932 4.4492 4.68201C3.4365 5.69471 2.86757 7.06822 2.86757 8.50039C2.86757 9.20953 3.00725 9.91172 3.27862 10.5669C3.55 11.222 3.94776 11.8173 4.4492 12.3188C4.95063 12.8202 5.54592 13.218 6.20108 13.4893C6.85624 13.7607 7.55843 13.9004 8.26757 13.9004C8.97671 13.9004 9.67891 13.7607 10.3341 13.4893C10.9892 13.218 11.5845 12.8202 12.0859 12.3188C12.5874 11.8173 12.9851 11.222 13.2565 10.5669C13.5279 9.91172 13.6676 9.20953 13.6676 8.50039C13.6676 7.06822 13.0986 5.69471 12.0859 4.68201C11.0733 3.66932 9.69974 3.10039 8.26757 3.10039ZM3.60067 3.83349C4.83841 2.59575 6.51714 1.90039 8.26757 1.90039C10.018 1.90039 11.6967 2.59575 12.9345 3.83349C14.1722 5.07123 14.8676 6.74996 14.8676 8.50039C14.8676 9.36712 14.6969 10.2254 14.3652 11.0261C14.0335 11.8269 13.5473 12.5544 12.9345 13.1673C12.3216 13.7802 11.594 14.2663 10.7933 14.598C9.99253 14.9297 9.1343 15.1004 8.26757 15.1004C7.40085 15.1004 6.54261 14.9297 5.74186 14.598C4.94111 14.2663 4.21353 13.7802 3.60067 13.1673C2.9878 12.5544 2.50165 11.8269 2.16997 11.0261C1.83829 10.2254 1.66757 9.36712 1.66757 8.50039C1.66757 6.74996 2.36293 5.07123 3.60067 3.83349Z" fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.43472 5.66792C5.66904 5.43361 6.04894 5.43361 6.28325 5.66792L8.26746 7.65213L10.2517 5.66792C10.486 5.43361 10.8659 5.43361 11.1002 5.66792C11.3345 5.90224 11.3345 6.28214 11.1002 6.51645L9.11599 8.50066L11.1002 10.4849C11.3345 10.7192 11.3345 11.0991 11.1002 11.3334C10.8659 11.5677 10.486 11.5677 10.2517 11.3334L8.26746 9.34919L6.28325 11.3334C6.04894 11.5677 5.66904 11.5677 5.43472 11.3334C5.20041 11.0991 5.20041 10.7192 5.43472 10.4849L7.41893 8.50066L5.43472 6.51645C5.20041 6.28214 5.20041 5.90224 5.43472 5.66792Z" fill="currentColor" />
  </svg>
  ,
  'CircleCancelIcon'
);

export default CircleCancelIcon;