import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogProps, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { useAsyncTask } from "src/main/hooks";
import { TeamModel } from "src/main/types";
import { handleApiResponse } from "src/main/utils";

interface SetParentDialogProps extends Omit<DialogProps, "open"> {
  loading?: boolean;
  open?: boolean;
  team?: TeamModel;
}

interface SelectOptionType {
  label: string;
  id: string;
  team: TeamModel;
}

const SetParentDialog: React.FC<SetParentDialogProps> = (props) => {
  const { team, onClose, open = false, ...dialogProps } = props;
  const [selectedTeam, setSelectedTeam] = useState<SelectOptionType | null>(null);

  const result = accountApi.useListTeamQuery({ meta: { count: 0, limit: 100000, offset: 0 } });

  const [assignParent] = accountApi.useAssignParentTeamMutation();
  const [runAssignParent, loading] = useAsyncTask("assign/parent");

  const onSubmit = useCallback(() => {
    runAssignParent(async () => {
      if (!team || !selectedTeam) return;
      const result = await assignParent({ teamId: team?.id, data: { parentTeamId: selectedTeam.id } });
      const { data, error } = handleApiResponse<TeamModel>(result);
      if (data?.parentTeamId === selectedTeam.id) {
        toast.success("Parent assigned!");
        onClose?.({}, "escapeKeyDown");
      }

      if (error?.data?.error?.message) {
        throw new Error(error.data?.error?.message)
      }

    })
    // eslint-disable-next-line 
  }, [assignParent, runAssignParent, selectedTeam, team])

  useEffect(() => {
    if (!!result.data?.entries.length && team?.parentTeamId) {
      const parentTeam = result.data.entries.find((entry) => entry.id === team.parentTeamId);
      if (!parentTeam) return;

      setSelectedTeam({
        label: parentTeam!.name,
        id: parentTeam!.id,
        team: parentTeam!
      })
    }
    // eslint-disable-next-line
  }, [result])

  const options = useMemo(() => {
    if (!result.data?.entries.length) return [];

    return result.data.entries.map((team) => {

      return {
        label: team.name,
        id: team.id,
        team: team
      }
    })
  }, [result])

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  const confirm = () => {
    onSubmit();
    // onClose?.({}, "escapeKeyDown");
  }


  return (
    <Dialog open={open} fullWidth {...dialogProps} onClose={_onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" pt={2}>
          <Typography variant="h6">Select new parent</Typography>
        </Box>
        <Box pt={2} sx={{
          display: "flex",
        }}
          gap={2}
        >
          <Autocomplete
            sx={{ flex: 1 }}
            options={options}
            value={selectedTeam}
            onChange={(ev, selected) => { setSelectedTeam(selected) }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          sx={{ color: "gray" }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          onClick={() => confirm()}
          color="primary"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
};

export default SetParentDialog;
