import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, CardProps, IconButton, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DetailCard } from "src/main/components";
import { WorkerModel } from 'src/main/types';
import { Paths } from "src/main/utils";

interface WorkerCardProps extends CardProps {
  workerId?: string;
  worker?: WorkerModel;
}

const WorkerCardComponent: React.FC<WorkerCardProps> = (props) => {
  const { workerId, worker, ...rest } = props;
  const navigate = useNavigate();

  const result = workerId ? accountApi.useGetWorkerQuery({ workerId }) : null;

  const person = useMemo(() => {
    if (worker) return worker.person ?? undefined;
    if (!!workerId && result) return result.data?.model.person ?? undefined;
    if (!workerId && !worker && !result) return undefined;
    return undefined;
    // eslint-disable-next-line
  }, [result?.data?.model?.person, workerId, worker, result]);

  return (
    <DetailCard {...rest}>
      <DetailCard.Header
        header="Worker Information"
        endComp={
          <IconButton
            onClick={() => {
              navigate(Paths.Management.WorkerDetail.replace(":workerId", workerId ?? worker?.id ?? ""));
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        }
      />
      <DetailCard.Divider />
      <Box p={2} px={3} display="flex" justifyContent="space-between">
        <Typography>Name</Typography>
        <Typography>{person?.firstname}&nbsp;{person?.middlename ?? ""}{person?.lastname ? " " + person.lastname : ""}</Typography>
      </Box>
    </DetailCard >
  );
};

export default WorkerCardComponent;
