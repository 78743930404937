import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControlLabel, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { LoadingContainer } from "src/main/components";
import { Defaults } from 'src/main/constants';
import { useFormState } from "src/main/hooks";
import { AccountVariableBrandType, AccountVariableModel, AccountVariableType } from "src/main/types";
import { handleApiResponse, normalizeText, SimpleMap } from "src/main/utils";
import * as Yup from "yup";
import { BrandLabel } from "./components";

interface BrandCardProps extends React.PropsWithChildren {
  variable?: AccountVariableModel<AccountVariableBrandType>;
}


const keyToLabel = (key: string) => {
  return normalizeText(key.replaceAll("-", " "), "letter");
}

const initialState = {
  type: AccountVariableType.Generic,
  key: Defaults.Setting.WorkerBrands as string,
  content: {
    items: [{
      key: "",
      label: "",
    }]
  } as SimpleMap<SimpleMap<string>[]>,
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  key: Yup.string().required("Type is required"),
  content: Yup.object().required(),
})

const BrandCardPage: React.FC<BrandCardProps> = (props) => {
  const { variable } = props;

  const [createAccountVariable, { isLoading: isCreateLoading }] = accountApi.useCreateAccountVariableMutation();
  const [updateAccountVariable, { isLoading: isUpdateLoading }] = accountApi.useUpdateAccountVariableMutation();
  const [formStates, { setInput, consumeAPIError }] = useFormState(initialState, validationSchema);
  const createNew = !variable;

  useEffect(() => {
    if (variable) {
      setInput((prevState) => {
        return {
          ...prevState,
          key: variable.key,
          content: {
            items: [...variable.content.items],
          } as SimpleMap<SimpleMap<string>[]>
        }
      })
    }
  }, [setInput, variable])

  const addLabel = () => {
    setInput((prevState) => {
      return {
        ...prevState,
        content: {
          ...prevState.content,
          items: [
            ...prevState.content.items,
            {
              key: "",
              label: "",
            }
          ],
        }
      }
    })
  }

  const onDeleteLabel = (index: number) => {
    const newContent = formStates.input.content
    newContent["items"].splice(index, 1);

    setInput((prevState) => {
      return {
        ...prevState,
        content: newContent
      }
    })
  }

  const onCreate = useCallback(async () => {
    const createRequest = {
      key: formStates.input.key,
      type: formStates.input.type,
      content: formStates.input.content,
    }

    const result = await createAccountVariable(createRequest);

    const { error } = handleApiResponse(result);
    if (error?.data?.error) {
      if (consumeAPIError(error.data?.error) !== null)
        return;
      throw new Error(error.data?.error?.message)
    }

    toast.success("Brand created");
    // eslint-disable-next-line
  }, [consumeAPIError, createAccountVariable, handleApiResponse, formStates.input])

  const onUpdate = useCallback(async () => {
    const updateRequest = {
      content: formStates.input.content,
    }

    const result = await updateAccountVariable({ accountVariableId: variable!.id, data: updateRequest })

    const { error } = handleApiResponse(result);
    if (error?.data?.error) {
      if (consumeAPIError(error.data?.error) !== null)
        return;
      throw new Error(error.data?.error?.message)
    }

    toast.success("Brand updated");
    // eslint-disable-next-line
  }, [consumeAPIError, updateAccountVariable, handleApiResponse, formStates.input, variable])

  const validate = (label: string) => {
    const regex = /^[a-z0-9-_\s]+$/i
    return regex.test(label);
  }

  const checkAndExecute = (choice: "create" | "update") => {
    let hasError = false;
    const labelArr: string[] = [];
    const keyArr: string[] = [];

    const newItems = formStates.input.content["items"].map((item) => {
      if (!item.label) {
        item.error = "Enter a label";
        hasError = true;
      } else if (labelArr.includes(item.label.toLowerCase())) {
        item.error = "Duplicated entries";
        hasError = true;
      } else if (keyArr.includes(item.key.toLowerCase())) {
        item.error = "Duplicated key entries";
        hasError = true;
      } else if (!validate(item.label)) {
        item.error = "Invalid characters";
        hasError = true;
      } else delete item.error;

      labelArr.push(item.label.toLowerCase());
      keyArr.push(item.key);
      return item;
    })

    if (hasError) {
      return setInput((prevState) => {

        return {
          ...prevState,
          content: {
            ...prevState.content,
            items: newItems
          }
        }
      })
    }

    if (choice === "create") return onCreate();

    onUpdate();
  }


  return (
    <Card >
      <LoadingContainer loading={isCreateLoading || isUpdateLoading}>
        {/* <CardHeader title={
          <Box>
            <Field
              label="Brand Label"
              required
              fieldKey="key"
              formStates={formStates}
              onValueChange={onValueChange}
            />
          </Box>
        } /> */}
        <CardHeader title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{keyToLabel(formStates.input.key)}</Typography>
            <FormControlLabel onClick={() => addLabel()} label="Add brand" labelPlacement='start' control={
              <AddCircleOutlineIcon sx={{ mx: 2 }} color='primary' />
            } />
          </Box>
        } />
      </LoadingContainer>
      <CardContent>
        {formStates.input.content["items"].map((item, index) => (
          <Box key={index} display="flex" gap={2} paddingBottom={2} alignItems="center">
            <Box flex={1}>
              <BrandLabel setInput={setInput} content={formStates.input.content} item={item} index={index} />
            </Box>
            <Box display="flex">
              <IconButton
                sx={{ ml: 1, }}
                onClick={() => onDeleteLabel(index)}
                color="error"
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={() => checkAndExecute(createNew ? "create" : "update")}
          disabled={isCreateLoading || isUpdateLoading}
        >
          {createNew ? "Create" : "Update"}
        </Button>
      </CardActions>
    </Card>
  )
};

export default BrandCardPage;
