import BigNumber from "bignumber.js";
import moment from "moment";

export const Defaults = {
  AuthTokenKey: "task-api-token",

  Setting: {
    WorkerBrands: "worker-brands",
  },

  WalletCurrency: "PHP",
  Theme: "theme",
  UserName: "username",
  Language: "language",

  WithdrawCutoffTime: moment.unix(moment().utcOffset(7).set("hour", 23).set("minute", 59).unix())
} as const;

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const CurrencyDecimals = {
  PHP: 0,
  USD: 2,
  THB: 0,
  AUD: 0,
}

export const QueryTags = {
  Worker: [
    "tasks",
    "task",
    "workerWallets",
    "withdraw",
    "withdraws",
    "oauth",
    "self",
    "taskDetail",
    "pendingInvite",
    "stats",
  ] as const
}