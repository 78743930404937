
import moment from "moment";
import { BuilderProp, require200Status } from "src/main/api/account";
import { Meta, PersonModel, QueryResult, TaskModel, TeamModel, TeamPersonModel, WorkerModel } from "src/main/types";
import { bnOrZero } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";

export interface MetaRequest {
  meta: Meta;
}

export interface ListTeamsResponse {
  meta: Meta;
  entries: TeamModel[];
}

export interface CreateTeamRequest {
  name: string;
  description: string;
}

export interface TeamResponse {
  model: TeamModel;
}

export interface UpdateTeamRequest {
  data: {
    name: string;
    description: string;
  },
  teamId: string;
}

export interface DeleteTeamRequest {
  teamId: string;
}

export interface GetTeamRequest {
  teamId: string;
}

export interface AssignTeamParentRequest {
  teamId: string;
  data: {
    parentTeamId: string;
  }
}

export interface CreateTeamPersonsRequest {
  teamId: string;
  data: {
    personIds: string[];
  }
}

export interface TeamPersonsResponse {
  entries: TeamPersonModel[]
}

export interface UpdateTeamPersonsRequest {
  teamId: string;
  data: {
    personIds: string[];
  }
}

export interface DeleteTeamPersonsRequest {
  teamId: string;
  data: {
    personIds: string[];
  }
}

export interface ListTeamPersonsRequest {
  teamId: string;
  meta: Meta;
}

export interface ListTeamPersonsResponse {
  meta: Meta;
  entries: PersonModel[];
}

export interface ListTeamWorkersResponse {
  meta: Meta;
  entries: WorkerModel[];
}

export const listTeams = (builder: BuilderProp) => {
  return builder.query<ListTeamsResponse, MetaRequest>({
    providesTags: ["team"],
    query: ({ meta }) => ({
      url: `/account/team/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListTeamsResponse>) => {
      return response.result
    }
  })
}

export const createTeam = (builder: BuilderProp) => {
  return builder.mutation<TeamResponse, CreateTeamRequest>({
    invalidatesTags: ["team"],
    query: (data) => ({
      url: `/account/team/create`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamResponse>) => {
      return response.result
    }
  })
}

export const updateTeam = (builder: BuilderProp) => {
  return builder.mutation<TeamResponse, UpdateTeamRequest>({
    invalidatesTags: ["team"],
    query: ({ teamId, data }) => ({
      url: `/account/team/${teamId}/update`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamResponse>) => {
      return response.result
    }
  })
}

export const deleteTeam = (builder: BuilderProp) => {
  return builder.mutation<TeamResponse, DeleteTeamRequest>({
    invalidatesTags: ["team"],
    query: ({ teamId }) => ({
      url: `/account/team/${teamId}/delete`,
      method: "DELETE",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamResponse>) => {
      return response.result
    }
  })
}

export const getTeam = (builder: BuilderProp) => {
  return builder.query<TeamResponse, GetTeamRequest>({
    providesTags: ["team"],
    query: ({ teamId }) => ({
      url: `/account/team/${teamId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamResponse>) => {
      return response.result
    }
  })
}

export const assignParent = (builder: BuilderProp) => {
  return builder.mutation<TeamResponse, AssignTeamParentRequest>({
    invalidatesTags: ["team"],
    query: ({ teamId, data }) => ({
      url: `/account/team/${teamId}/assign`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamResponse>) => {
      return response.result
    }
  })
}

export const listTeamPerson = (builder: BuilderProp) => {
  return builder.query<ListTeamPersonsResponse, ListTeamPersonsRequest>({
    providesTags: ["team"],
    query: ({ teamId, meta }) => ({
      url: `/account/team/${teamId}/person/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListTeamPersonsResponse>) => {
      return response.result
    }
  })
}

export const listTeamWorker = (builder: BuilderProp) => {
  return builder.query<ListTeamWorkersResponse, ListTeamPersonsRequest>({
    providesTags: ["team"],
    query: ({ teamId, meta }) => ({
      url: `/account/team/${teamId}/worker/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListTeamWorkersResponse>) => {
      return response.result
    }
  })
}

export const createTeamPersons = (builder: BuilderProp) => {
  return builder.mutation<TeamPersonsResponse, CreateTeamPersonsRequest>({
    invalidatesTags: ["team"],
    query: ({ teamId, data }) => ({
      url: `/account/team/${teamId}/person/create`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamPersonsResponse>) => {
      return response.result
    }
  })
}

export const updateTeamPersons = (builder: BuilderProp) => {
  return builder.mutation<TeamPersonsResponse, UpdateTeamPersonsRequest>({
    invalidatesTags: ["team"],
    query: ({ teamId, data }) => ({
      url: `/account/team/${teamId}/person/update`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamPersonsResponse>) => {
      return response.result
    }
  })
}

export const deleteTeamPersons = (builder: BuilderProp) => {
  return builder.mutation<TeamPersonsResponse, DeleteTeamPersonsRequest>({
    invalidatesTags: ["team"],
    query: ({ teamId, data }) => ({
      url: `/account/team/${teamId}/person/delete`,
      method: "DELETE",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<TeamPersonsResponse>) => {
      return response.result
    }
  })
}

export interface ListTeamTaskRequest {
  teamId: string;
  meta: Meta;
}

export interface ListTeamTaskResponse {
  entries: TaskModel[];
  meta: Meta;
}

export const listTeamTasks = (builder: BuilderProp) => {
  return builder.query<ListTeamTaskResponse, ListTeamTaskRequest>({
    providesTags: ["team"],
    query: ({ teamId, meta }) => ({
      url: `/account/team/${teamId}/task/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListTeamTaskResponse>) => {
      for (const task of response.result.entries) {
        if (typeof task.payAmount === "string")
          task.payAmount = bnOrZero(task.payAmount);
        if (typeof task.publishedAt === "string")
          task.publishedAt = moment(task.publishedAt);
      }
      return response.result
    }
  })
}