import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { queryWithRefresh } from "src/main/api/base";
import { QueryTags } from "src/main/constants";
import { AuthToken } from "src/main/store";
import {
  AccountEntityModel,
  AccountModel,
  SelfEntityModel,
} from "src/main/types";
import {
  acceptTask,
  queryAttempt,
  queryAttempts,
  queryEarnings,
  queryPotentialEarnings,
  queryTask,
  queryTaskContent,
  queryTasks,
  queryWallets,
  submitProof,
  submitTask,
  withdrawCancel,
  withdrawDetail,
  withdrawList,
  withdrawRequest,
  cancelTask,
} from "./task";

import { dailyTaskEarnings, workerTaskStats } from "./Stats";

export type WorkerTagType =
  | "tasks"
  | "task"
  | "workerWallets"
  | "withdraw"
  | "withdraws"
  | "oauth"
  | "self"
  | "taskDetail"
  | "pendingInvite"
  | "stats";
export type WorkerBuilderProp = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  WorkerTagType,
  "workerApi"
>;
export const require200Status = (response: Response) => response.status === 200;
export interface QueryResult<T> {
  result: T;
  meta: any; // TODO: type meta
}
export interface OAuthAccessTokenResponse extends AuthToken {}
export interface OAuthAccessTokenResult {
  token_type: "bearer";
  access_token: string;
  expires_at: number;
  expires_in: number;
  refresh_expires_at: number;
  refresh_expires_in: number;
  refresh_token: string;
}
export interface OAuthAccessTokenRequest {
  grantType: "password";
  accessKey: string;
  secret: string;
  account?: string;
}
export interface OAuthRefreshTokenRequest {
  grantType: "refresh_token";
  refreshToken: string;
}
export interface ProfileResponse {
  self: SelfEntityModel;
  workspace?: AccountEntityModel;
  accounts: AccountModel[];
}

const workerApi = createApi({
  reducerPath: "workerApi",
  baseQuery: queryWithRefresh,
  tagTypes: QueryTags.Worker,
  endpoints: (builder) => ({
    workerTasks: queryTasks(builder),
    workerAttempts: queryAttempts(builder),
    workerTaskDetail: queryTask(builder),
    workerTaskContent: queryTaskContent(builder),
    workerTaskAttempt: queryAttempt(builder),
    workerTaskAccept: acceptTask(builder),
    workerTaskSubmit: submitTask(builder),
    workerProofSubmit: submitProof(builder),
    workerTaskCancel: cancelTask(builder),

    workerWithdrawRequest: withdrawRequest(builder),
    workerWithdrawDetail: withdrawDetail(builder),
    workerWithdrawCancel: withdrawCancel(builder),
    workerWithdrawList: withdrawList(builder),

    workerWalletList: queryWallets(builder),
    workerEarnings: queryEarnings(builder),
    workerPotentialEarning: queryPotentialEarnings(builder),

    workerTaskStats: workerTaskStats(builder),
    workerDailyTaskEarning: dailyTaskEarnings(builder),
  }),
});

export default workerApi;
