import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent, Dialog, DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { TaskAttemptModel } from "src/main/types";
import { createStyles } from "src/main/utils";
import { ApproveContent, RejectContent, TerminateContent } from "./components";


import { Check, Close, DoNotDisturb } from "@mui/icons-material";

interface Props extends Omit<DialogProps, "open"> {
  taskAttempt: TaskAttemptModel;
  open?: boolean;
}

const TaskDialog: React.FC<Props> = ({ open = false, onClose, taskAttempt }) => {
  const theme = useTheme();

  const [selectedButton, setSelectedButton] = useState<
    "approve" | "reject" | "terminate" | null
  >(null);

  const handleButtonClick = (button: "approve" | "reject" | "terminate") => {
    setSelectedButton(button);
  };

  const handleCancel = () => {
    setSelectedButton(null);
  };


  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
    setSelectedButton(null);
  }

  const getDialogContent = () => {
    switch (selectedButton) {
      case "approve":
        return <ApproveContent onClose={() => _onClose({}, "backdropClick")} onCancel={handleCancel} taskAttempt={taskAttempt} />;
      case "reject":
        return <RejectContent onClose={() => _onClose({}, "backdropClick")} onCancel={handleCancel} taskAttempt={taskAttempt} />;
      case "terminate":
        return <TerminateContent onClose={() => _onClose({}, "backdropClick")} onCancel={handleCancel} taskAttempt={taskAttempt} />;
      default:
        return <></>;
    }
  };

  const getDialogActions = () => {
    if (selectedButton) {
      return (
        <>{getDialogContent()}</>
      );
    } else {
      return (
        <>
          <DialogTitle>Choose your action</DialogTitle>
          <DialogTitle variant="subtitle1">Rejections: {taskAttempt.rejectCount ?? 0}</DialogTitle>
          <DialogContent sx={styles.buttonContent}>
            <Card sx={{ opacity: (taskAttempt.status !== "submitted") ? 0.4 : 1 }} variant="outlined">
              <CardActionArea disabled={taskAttempt.status !== "submitted"} onClick={() => handleButtonClick("approve")}>
                <CardContent sx={styles.cardContent}>
                  <Avatar sx={{ bgcolor: theme.palette.success.light }}>
                    <Check />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1">Approve</Typography>
                    <Typography variant="body2" color="text.secondary">Approve with rating</Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card sx={{ opacity: (taskAttempt.rejectCount >= 2 || taskAttempt.status !== "submitted") ? 0.4 : 1 }} variant="outlined">
              <CardActionArea disabled={taskAttempt.rejectCount >= 2 || taskAttempt.status !== "submitted"} onClick={() => handleButtonClick("reject")}>
                <CardContent sx={styles.cardContent}>
                  <Avatar sx={{ bgcolor: theme.palette.warning.main }}>
                    <Close />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1">Reject</Typography>
                    <Typography variant="body2" color="text.secondary">State reason for rejection</Typography>
                    {taskAttempt.rejectCount >= 2 && (
                      <Typography></Typography>
                    )}
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card variant="outlined" onClick={() => handleButtonClick("terminate")}>
              <CardActionArea>
                <CardContent sx={styles.cardContent}>
                  <Avatar sx={{ bgcolor: theme.palette.error.main }}>
                    <DoNotDisturb />
                  </Avatar>
                  <Typography variant="subtitle1">Terminate</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </DialogContent>
        </>
      );
    }
  };

  return (
    <Dialog open={open} onClose={_onClose} maxWidth="sm" fullWidth={true} scroll="body">
      {/* <DialogTitle>{task.name}</DialogTitle> */}
      {getDialogActions()}
    </Dialog>
  );
};

const styles = createStyles({
  root: {},
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center"
  }
})

export default TaskDialog;
