import { createSvgIcon } from '@mui/material/utils';

const SelectorIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 3.41675C10.1987 3.41675 10.3895 3.49577 10.5302 3.63642L13.8635 6.96975C14.1564 7.26265 14.1564 7.73752 13.8635 8.03041C13.5706 8.3233 13.0957 8.3233 12.8028 8.03041L9.99984 5.22741L7.19683 8.03041C6.90394 8.3233 6.42907 8.3233 6.13617 8.03041C5.84328 7.73752 5.84328 7.26265 6.13617 6.96975L9.46951 3.63642C9.61016 3.49577 9.80093 3.41675 9.99984 3.41675ZM6.13617 11.9698C6.42907 11.6769 6.90394 11.6769 7.19683 11.9698L9.99984 14.7728L12.8028 11.9698C13.0957 11.6769 13.5706 11.6769 13.8635 11.9698C14.1564 12.2626 14.1564 12.7375 13.8635 13.0304L10.5302 16.3637C10.3895 16.5044 10.1987 16.5834 9.99984 16.5834C9.80093 16.5834 9.61016 16.5044 9.46951 16.3637L6.13617 13.0304C5.84328 12.7375 5.84328 12.2626 6.13617 11.9698Z" fill="currentColor" />
  </svg>
  ,
  'SelectorIcon'
);

export default SelectorIcon;
