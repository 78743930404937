import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { ListingComp, MonoLabel, Table, DetailCard } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { Meta, TeamModel } from "src/main/types";
import { handleFormikApiResponse, truncate } from "src/main/utils";

interface UpdateTeamWorkerDialogProps extends Omit<DialogProps, "open"> {
  open?: boolean;
  teams: TeamModel[];
  taskId: string;
}

const TEAM_HEADER = [
  { value: "Team" },
  { value: "Description" },
  { value: "" }
]

const UpdateTeamWorkerDialog: React.FC<UpdateTeamWorkerDialogProps> = (props) => {
  const { taskId, teams, onClose, open = false, ...dialogProps } = props;

  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })
  const result = accountApi.useListTeamQuery({ meta });
  const teamList = useMemo(() => result.data?.entries ?? undefined, [result.data?.entries]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);

  const [assignTeamToTask] = accountApi.useAssignTeamToTaskMutation()
  const [unassignTeamFromTask] = accountApi.useUnassignTeamToTaskMutation()

  const [runAssignTeamToTask] = useAsyncTask("assign/team/task");
  const [runUnassignTeamToTask] = useAsyncTask("unassign/team/task");

  const onAdd = useCallback((team: TeamModel) => {
    runAssignTeamToTask(async () => {
      const result = await assignTeamToTask({ teamIds: [team.id], taskId });
      const { error } = handleFormikApiResponse(result);
      if (error) {
        return toast.error(error.data?.error?.message ?? "");
      } else toast.success("Added!", { duration: 1000 });
    })
  }, [assignTeamToTask, runAssignTeamToTask, taskId])

  const onRemove = useCallback((team: TeamModel) => {
    runUnassignTeamToTask(async () => {
      const result = await unassignTeamFromTask({ teamIds: [team.id], taskId });
      const { error } = handleFormikApiResponse(result);
      if (error) {
        return toast.error(error.data?.error?.message ?? "");
      } else toast.success("Removed!", { duration: 1000 });
    })
  }, [unassignTeamFromTask, runUnassignTeamToTask, taskId]);


  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  const isSelected = (team) => {
    if (!!teams.find((a) => a.id === team.id)) return true;

    return false;
  }

  return (
    <Dialog open={open} fullWidth {...dialogProps} onClose={_onClose} scroll={"body"}>
      <DialogContent sx={{ p: 0 }}>
        <DetailCard.Header sx={{ pb: 0 }}
          header="Update Teams"
          endComp={(
            <Typography variant="body1">Selected Teams:&nbsp;{teams.length}</Typography>
          )}
        />
        <DetailCard.Divider />
        <ListingComp
          title="All Teams"
          loading={result.isLoading}
          updateList={(newMeta) => setMeta(newMeta)}
          meta={persistMeta}
          sx={{ m: 0 }}
        >
          <ListingComp.FilterSection>
            <ListingComp.Searchbar />
          </ListingComp.FilterSection>
          <ListingComp.Content>
            <Table tableStyle={{ size: "small" }} >
              <Table.Head headers={TEAM_HEADER} />
              <Table.Body>
                {teamList?.map((team) => (
                  <Table.Row
                    selected={isSelected(team)}
                    onClick={() => { isSelected(team) ? onRemove(team) : onAdd(team) }}
                    sx={{ '& .MuiTableCell-root': { padding: 1 }, cursor: "pointer" }}
                  >
                    <Table.Cell>
                      <Box display="flex" flexDirection="column" px={1}>
                        <Typography variant="body1">{team.name}</Typography>
                        <Typography variant="body2"><MonoLabel>{truncate(team.id, 8)}</MonoLabel></Typography>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="body1">{team.description}</Typography>
                    </Table.Cell>
                    <Table.Cell align="center">
                      <Checkbox checked={isSelected(team)} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </ListingComp.Content>
        </ListingComp>

      </DialogContent>
      <DetailCard.Divider />
      <DialogActions>
        <Button
          variant="contained"
          sx={{ mr: 2, flexShrink: 0 }}
          onClick={() => _onClose({}, "escapeKeyDown")}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default UpdateTeamWorkerDialog;
