import React from "react";
import { Typography, TypographyProps } from "@mui/material";

interface TitleProps extends TypographyProps {
  label: string
}

const TitleComponent: React.FC<TitleProps> = (props) => {
  const { label, variant = "h3", ...rest } = props;
  return (
    <Typography variant={variant} {...rest}>
      {label}
    </Typography>
  );
};

export default TitleComponent;
