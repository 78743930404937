import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { accountApi } from "src/main/api";
import { FormField, LoadingContainer } from "src/main/components";
import { useFormState } from "src/main/hooks";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { AccountVariableModel, AccountVariableType } from "src/main/types";
import { handleApiResponse, SimpleMap } from "src/main/utils";
import * as Yup from "yup";
import CurrenciesJson from "./currencies.json";

interface AccountCurrencyCardProp extends React.PropsWithChildren {
  variable?: AccountVariableModel<SimpleMap<string | number>>;
}

const ACCOUNT_CURRENCY = "account-currency";

interface Options {
  value: string;
  label: string;
}
interface FormState {
  currency: string,
  withdrawLimit: number,
}
const initial_values: FormState = {
  currency: "",
  withdrawLimit: 0,
}

const Field = FormField<FormState>;

const validationSchema = Yup.object().shape({
  currency: Yup.string().nullable(),
  withdrawLimit: Yup.number().positive().nullable(),
})

const AccountCurrencyCard: React.FC<AccountCurrencyCardProp> = (props) => {
  const { variable } = props;

  const [createAccountVariable, { isLoading: isCreateLoading }] = accountApi.useCreateAccountVariableMutation();
  const [updateAccountVariable, { isLoading: isUpdateLoading }] = accountApi.useUpdateAccountVariableMutation();
  const currencyListResult = accountApi.useGetCurrencyListQuery({});
  const { currency } = useSelector<RootState, AuthState>(state => state.auth);

  const [formStates, { onValueChange, setInput }] = useFormState(initial_values, validationSchema);

  useEffect(() => {

    setInput((prev) => {
      const newPrev = { ...prev };

      newPrev.currency = (variable?.content?.currency ?? currency?.default ?? "PHP") as string;
      newPrev.withdrawLimit = (variable?.content?.withdrawLimit ?? currency?.withdrawLimit ?? 200) as number;

      return newPrev;
    })
  }, [variable, setInput, currency])

  const currencyListOption = useMemo(() => {

    const options: Options[] = [];
    const list = Object.keys(currencyListResult?.data?.currencies ?? {});

    list.forEach((curr) => {
      options.push({ value: curr, label: CurrenciesJson[curr].name + ` (${curr})` });
    })

    return options

  }, [currencyListResult.data]);


  const onCreate = useCallback(async () => {

    const input = formStates.input;

    let content: SimpleMap<string | number> = {};
    if (input.currency) content.currency = input.currency;
    if (input.withdrawLimit) content.withdrawLimit = input.withdrawLimit;

    const createRequest = {
      type: AccountVariableType.Generic,
      key: ACCOUNT_CURRENCY,
      content: content
    }

    const result = await createAccountVariable(createRequest);

    const { error } = handleApiResponse(result);
    if (error?.data?.error) {
      throw new Error(error.data?.error?.message)
    }

    toast.success("Account settings updated");
    // eslint-disable-next-line
  }, [createAccountVariable, handleApiResponse, formStates.input])

  const onUpdate = useCallback(async () => {

    const input = formStates.input;

    let content: SimpleMap<string | number> = {};
    if (input.currency) content.currency = input.currency;
    if (input.withdrawLimit) content.withdrawLimit = input.withdrawLimit;

    const updateRequest = {
      key: ACCOUNT_CURRENCY,
      content: content
    }

    const result = await updateAccountVariable({ accountVariableId: variable!.id, data: updateRequest })

    const { error } = handleApiResponse(result);
    if (error?.data?.error) {
      throw new Error(error.data?.error?.message)
    }

    toast.success("Brand updated");
    // eslint-disable-next-line
  }, [updateAccountVariable, handleApiResponse, formStates.input, variable])

  return (
    <Card >
      <LoadingContainer loading={isCreateLoading || isUpdateLoading || currencyListResult.isLoading}>
        <CardHeader title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Account Currency</Typography>
          </Box>
        } />
        <CardContent>
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink>Currency</InputLabel>
            <Select
              input={<OutlinedInput notched />}
              value={formStates.input.currency}
              onChange={(ev) => {
                setInput((prev) => {
                  return {
                    ...prev,
                    currency: ev.target.value
                  }
                });
              }}
            >
              {currencyListOption.map((opt) => (
                <MenuItem value={opt.value}>{opt.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Field
            label="Withdraw Limit"
            type="number"
            fieldKey="withdrawLimit"
            formStates={formStates}
            onValueChange={onValueChange}
          />
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={() => { !!variable ? onUpdate() : onCreate() }}
            disabled={isUpdateLoading || currencyListResult.isLoading || isCreateLoading}
          >
            {"Update"}
          </Button>
        </CardActions>
      </LoadingContainer>
    </Card>
  )
};

export default AccountCurrencyCard;
