import {
  Box,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from "src/main/api";
import { ListAccountVariableResponse } from "src/main/api/account/Variable";
import { ListWithdrawResponse } from "src/main/api/account/Withdraw";
import {
  DateDisplay,
  ListingComp,
  LoadingButton,
  Page,
  Table,
} from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { Meta } from "src/main/types";
import { exportList, Paths, SimpleMap, truncate } from "src/main/utils";

interface WithdrawPageProps extends React.PropsWithChildren {}

const WITHDRAW_REQUEST_HEADERS = [
  { value: "Worker" },
  { value: "Requested On" },
  { value: "GCash Account" },
  { value: "Amount" },
  { value: "Currency" },
  { value: "Fee" },
  { value: "Status" },
  { value: "" },
];

const WITHDRAW_REQUEST_STATUS_OPTION = [
  { value: "all", label: "All" },
  { value: "submitted", label: "Submitted" },
  { value: "processed", label: "Processed" },
  { value: "cancelled", label: "Cancelled" },
  { value: "draft", label: "Drafted" },
];

const WithdrawPage: React.FC<WithdrawPageProps> = () => {
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 });
  const [brand, setBrand] = useState<string>("");

  const result = accountApi.useListWithdrawRequestQuery({ meta });
  const WithdrawRequests: ListWithdrawResponse | undefined = useMemo(
    () => result.data ?? undefined,
    [result.data]
  );
  const persistMeta = useMemo(
    () => ({ ...meta, ...result.data?.meta }),
    [result.data, meta]
  );

  const brandVariablesResult = accountApi.useListAccountVariableQuery({
    meta: { count: 0, limit: 10000, offset: 0, key: "worker-brands" },
  });
  const variables: ListAccountVariableResponse | undefined = useMemo(
    () => brandVariablesResult.data ?? undefined,
    [brandVariablesResult.data]
  );

  const variablesOpt = useMemo(() => {
    if (!variables?.entries[0]) return null;

    const baseBrands = variables.entries[0];

    let option: SimpleMap<string>[] = [];
    (baseBrands.content as SimpleMap<SimpleMap<string>[]>).items.forEach(
      (item) => {
        option.push({ value: item.key, label: item.label });
      }
    );

    return option;
  }, [variables]);

  const [listWithdrawRequest, { isLoading: exportWithdrawRequestLoading }] =
    accountApi.useLazyListWithdrawRequestQuery();

  const onExport = useCallback(async () => {
    const exportResult = await listWithdrawRequest({
      meta: { ...persistMeta, limit: 1000000 },
    });

    const entries = exportResult.data?.entries ?? [];

    exportList(
      entries,
      [
        { key: "id" },
        { key: `worker.variables.usernames.${brand}` },
        { key: "amount", type: "amount" },
        { key: "currency" },
        { key: "amountInUsd", type: "amount" },
        { key: "worker.gCashAccount", type: "forceString" },
        { key: "finalAmount", type: "amount" },
        { key: "withdrawFee", type: "amount" },
        { key: "status" },
        { key: "workerId" },
      ],
      [
        "Withdraw ID",
        "Username",
        "Amount",
        "Currency",
        "Amount (USD)",
        "GCash Account",
        "Final Amount",
        "Fee",
        "Status",
        "Worker ID",
      ],
      `WITHDRAW_REQUEST_${moment().format("YYYY-MM-DD")}.csv`
    );
  }, [listWithdrawRequest, brand, persistMeta]);

  const onStatusUpdate = (status: string) => {
    let newMeta = { ...persistMeta };
    if (status === "all") {
      delete newMeta.status;
    } else {
      newMeta.status = status;
    }

    setMeta({ ...newMeta, offset: 0 });
  };

  return (
    <Page>
      <Page.TopSection title="Withdraw Requests">
        {!!WithdrawRequests?.entries.length && (
          <Box flex={1} display="flex" gap={2} justifyContent="flex-end">
            <FormControl sx={{ maxWidth: 150 }} variant="outlined" fullWidth>
              <InputLabel shrink>Select Brand</InputLabel>
              <Select
                input={
                  <OutlinedInput notched label="Select brand" sx={{ pr: 1 }} />
                }
                name="brand"
                value={brand}
                label="Select brand"
                onChange={(ev) => setBrand(ev.target.value)}
                disabled={!variablesOpt?.length}
                sx={{ backgroundColor: "background.paper", borderRadius: 1 }}
              >
                {variablesOpt?.map((opt: SimpleMap<string>) => (
                  <MenuItem value={opt.value}>{opt.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <LoadingButton
              variant="contained"
              loading={exportWithdrawRequestLoading}
              onClick={() => onExport()}
            >
              Export
            </LoadingButton>
          </Box>
        )}
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading}
        title="Requests"
        updateList={(newMeta) => {
          setMeta(newMeta);
        }}
        meta={persistMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
          <ListingComp.Statusbar
            options={WITHDRAW_REQUEST_STATUS_OPTION}
            onStatusUpdate={onStatusUpdate}
          />
          <ListingComp.DateFilter dateSearchKey="createdAt" />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          <Table>
            <Table.Head headers={WITHDRAW_REQUEST_HEADERS} />
            <Table.Body>
              {WithdrawRequests?.entries.map((request) => (
                <Table.Row>
                  <Table.Cell>{truncate(request.workerId, 8)}</Table.Cell>
                  <Table.Cell>
                    <DateDisplay value={request.createdAt} />
                  </Table.Cell>
                  <Table.Cell>{request.worker?.gCashAccount}</Table.Cell>
                  <Table.Cell>{request.amount.toString()}</Table.Cell>
                  <Table.Cell>{request.currency}</Table.Cell>
                  <Table.Cell>{request.withdrawFee.toString()}</Table.Cell>
                  <Table.Cell>
                    <StatusChip status={request.status} />
                  </Table.Cell>
                  <Table.Cell align="right">
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.WithdrawRequestDetail.replace(
                        ":withdrawRequestId",
                        request.id
                      )}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default WithdrawPage;
