import React from "react";
import { InputField } from "src/main/components";
import { SimpleMap } from "src/main/utils";
import { Box } from "@mui/material";

interface BrandLabelProps extends React.PropsWithChildren {
  item: SimpleMap<string>;
  content: SimpleMap<SimpleMap<string>[]>;
  index: number;
  setInput: any;
}

const labelToKey = (label: string) => {
  return label
    .toLowerCase()
    .replace(/[^a-z0-9-_]+/, "-")
    .replaceAll(" ", "-");
};

const validate = (label: string) => {
  const regex = /^[a-z0-9-_\s]+$/i;
  return regex.test(label);
};

const BrandLabelPage: React.FC<BrandLabelProps> = (props) => {
  const { item, setInput, index, content } = props;

  const updateLabel = (ev) => {
    let newContentItems = { ...content };
    newContentItems["items"][index] = {
      key: labelToKey(ev.target.value),
      label: ev.target.value,
    };
    if (newContentItems["items"][index].label) {
      delete newContentItems["items"][index].error;
    }

    if (!!ev.target.value && !validate(ev.target.value)) {
      newContentItems["items"][index] = {
        ...newContentItems["items"][index],
        error: "Invalid characters",
      };
    }

    setInput((prevState) => {
      return {
        ...prevState,
        content: newContentItems,
      };
    }) as unknown;
  };

  return (
    <Box flex={1}>
      <InputField
        required
        label="Brand name"
        value={item.label}
        onChange={updateLabel}
        helperText={
          item.error
            ? item.error
            : !item.key.length
            ? "Enter brand name"
            : item.key
        }
        error={!!item.error}
        placeholder="e.g: Maverick"
      />
    </Box>
  );
};

export default BrandLabelPage;
