import { Box, BoxProps, Stack, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FireIcon } from "src/assets";
import { workerApi } from "src/main/api";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { Paths, createStyles, joinSx, IntlFormatter } from "src/main/utils";
import { Typography } from "src/main/components";
import { ClipBoardCheckIcon } from "src/assets";
import { WorkerTaskStatistic } from "src/main/types";
import moment from "moment";

interface OverviewProps extends BoxProps {}

const DEFAULT_DAYS = 6;

const Overview: React.FC<OverviewProps> = (props) => {
  const { sx, ...rest } = props;
  const navigate = useNavigate();
  const taskStats = useSelector<RootState, WorkerTaskStatistic | null>(
    (state) => state.layout.taskStats
  );
  const { currency } = useSelector<RootState, AuthState>((state) => state.auth);
  const { palette } = useTheme();

  const earningsQuery = workerApi.useWorkerEarningsQuery(undefined);

  const startDate = moment().subtract(DEFAULT_DAYS, "day").startOf("day");

  const taskSeries = workerApi.useWorkerDailyTaskEarningQuery({
    meta: { from: `${startDate.unix()}` },
  });

  const { seriesTotalTasks, seriesTotalEarnings } = useMemo(() => {
    let seriesTotalTasks = 0;
    let seriesTotalEarnings = 0;
    let seriesTotalEarningsUSD = 0;
    let ogSeries = taskSeries?.data?.series;

    if (ogSeries) {
      Object.values(ogSeries).forEach((serie) => {
        let task = parseInt(serie.tasks + "");
        let earning = parseInt(serie.amount + "");
        let earningUSD = parseInt(serie.amountInUsd + "");
        if (!isNaN(task)) {
          seriesTotalTasks += task;
        }

        if (!isNaN(earning)) {
          seriesTotalEarnings += earning;
        }

        if (!isNaN(earningUSD)) {
          seriesTotalEarningsUSD += earningUSD;
        }
      });
    }

    return {
      ogSeries,
      seriesTotalTasks,
      seriesTotalEarnings,
      seriesTotalEarningsUSD,
    };
  }, [taskSeries]);

  const earnings = useMemo(
    () => earningsQuery.data?.earnings,
    [earningsQuery.data]
  );

  return (
    <Box sx={joinSx(styles.root, sx)} {...rest}>
      <Box sx={styles.card}>
        <Box sx={styles.cardContent}>
          <Box>
            <Typography variant="overline" lineHeight={1.375}>
              <Typography formatId="overview.totalEarning" variant="body2">
                total earning
              </Typography>
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">
                {earnings?.totalEarnings.toFormat(2) ?? "0.00"}
              </Typography>
              <Typography variant="body2" ml={0.5}>
                {" "}
                {currency?.default}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <FireIcon fontSize="large" color="primary" />
          </Box>
        </Box>
        <Box sx={styles.cardFooter}>
          <Box>
            <Typography variant="caption" color="text.secondary">
              {IntlFormatter.getMessage("overview.last", "Last")}{" "}
              {DEFAULT_DAYS + 1}{" "}
              {IntlFormatter.getMessage("overview.days", "days")}:{" "}
              <Box component="span" color="text.primary">
                {seriesTotalEarnings} {currency?.default}{" "}
              </Box>{" "}
              {IntlFormatter.getMessage("overview.earned", "earned")}
            </Typography>
          </Box>
          <Typography
            formatId="overview.payout"
            variant="body2"
            sx={joinSx(styles.underlineText, {
              borderBottom: `1px solid ${palette.text.disabled}`,
            })}
            onClick={() => navigate(Paths.Portal.Payouts)}
          >
            payout
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.card}>
        <Box sx={styles.cardContent}>
          <Box>
            <Typography
              formatId="overview.totalCompletedTask"
              color="textPrimary"
              variant="overline"
              lineHeight={1.375}
            >
              Total Completed Task
            </Typography>
            <Stack direction="row" alignItems="center" display="flex">
              <Typography variant="h5">{taskStats?.approved ?? 0}</Typography>
              <Typography formatId="overview.tasks" variant="body2" ml={0.5}>
                tasks
              </Typography>
            </Stack>
          </Box>
          <Box>
            <ClipBoardCheckIcon fontSize="large" color="primary" />
          </Box>
        </Box>
        <Box sx={styles.cardFooter}>
          <Box>
            <Typography variant="caption" color="text.secondary">
              {IntlFormatter.getMessage("overview.last", "Last")}{" "}
              {DEFAULT_DAYS + 1}{" "}
              {IntlFormatter.getMessage("overview.days", "days")}:{" "}
              <Box component="span" color="text.primary">
                {" "}
                {seriesTotalTasks}{" "}
                {IntlFormatter.getMessage("overview.tasks", "tasks")}{" "}
              </Box>{" "}
              {IntlFormatter.getMessage("overview.completed", "completed")}
            </Typography>
          </Box>
          <Typography
            formatId="overview.myTasks"
            variant="body2"
            sx={joinSx(styles.underlineText, {
              borderBottom: `1px solid ${palette.text.disabled}`,
            })}
            onClick={() => navigate(Paths.Portal.Tasks)}
          >
            my tasks
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = createStyles({
  root: {
    mb: 2,
  },
  underlineText: {
    cursor: "pointer",
    textDecorationColor: "neutral.900",
    textTransform: "capitalize",
    lineHeight: 1.65,
  },
  card: {
    px: 2,
    py: 1,
    border: 1,
    borderColor: "neutral.200",
    boxShadow: 3,
    borderRadius: 1,
    mb: 2,
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 1,
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default Overview;
