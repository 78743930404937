import { Box, CardProps } from "@mui/material";
import React from "react";
import { DetailCard, EmptyState, Table, TableRowWrap } from "src/main/components";
import { TeamModel } from "src/main/types";

interface TeamInfoCardProps extends Omit<CardProps, "children"> {
  team?: TeamModel;
}

const TeamInfoCard: React.FC<TeamInfoCardProps> = (props) => {
  const { team, ...rest } = props;

  if (!team) return <EmptyState message="No team details" />

  return (
    <DetailCard {...rest}>
      <DetailCard.Header header="Team Information" />
      <DetailCard.Divider />
      <Box p={2}>
        <Table>
          <Table.Body>
            <TableRowWrap
              label="Name"
              value={team.name}
            />

            <TableRowWrap
              label="Description"
              value={team.description}
            />
          </Table.Body>
        </Table>
      </Box>
    </DetailCard>
  );
};

export default TeamInfoCard;
