import { Box, Divider, MenuItem, Typography } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import { FC, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import { AvatarIcon, CogIcon, LogoutIcon } from "src/assets";
import { workerApi } from "src/main/api";
import SelectLanguage from 'src/main/components/SelectLanguage';
import { RootState } from 'src/main/store';
import auth from 'src/main/store/auth';
import { AuthState } from 'src/main/store/auth/slice';
import { SelfEntityModel } from 'src/main/types';
import { IntlFormatter, Paths, browserLanguageSetting, getLanguageLabel } from "src/main/utils";

interface AccountMenuProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  notifyCount?: number | null;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 250,
    color: theme.palette.text.primary,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenuItem-root': {
      "&.Mui-disabled": {
        opacity: 1,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const AccountMenu: FC<AccountMenuProps> = (props) => {
  const {
    anchorEl,
    onClose,
    open,
    notifyCount,
    ...other
  } = props;
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>((state) => state.auth.profile);
  const { currency } = useSelector<RootState, AuthState>(state => state.auth);
  const [languageSelector, setLanguageSelector] = useState<boolean>(false);

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState('en');
  const earningsQuery = workerApi.useWorkerEarningsQuery(undefined);
  const handleLogout = () => {
    dispatch(auth.slice.actions.logout());
    toast.success(IntlFormatter.getMessage("accountMenu._logout_success_message", "Logout success, see you again soon!"));
  }

  const handleLanguage = () => {
    if (onClose) onClose();
    setLanguageSelector(!languageSelector)
  }

  const handleClose = (value: string) => {
    setLanguageSelector(false);
    setSelectedValue(value);
  };
  const language = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;
  const languageLabel = getLanguageLabel(language);

  const earnings = useMemo(() => earningsQuery.data?.earnings, [earningsQuery.data])
  return (
    <>
      <StyledMenu
        anchorEl={anchorEl}
        open={!!open}
        onClose={onClose}
        {...other}
      >
        <MenuItem disableRipple disabled>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <AvatarIcon fontSize="large" />
            <Box>
              <Typography variant="body2" fontWeight={700}>
                {profile?.person?.firstname}
              </Typography>
              <Typography variant="caption" color="primary" display="block" lineHeight={1.375}>
                {earnings?.totalEarnings.toFormat(2) ?? "0.00"} {currency?.default}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLanguage}>
          {languageLabel.icon}
          <Typography variant="body2">
            {languageLabel.label}
          </Typography>
        </MenuItem>
        <MenuItem component={RouterLink} to={Paths.Portal.Settings} onClick={onClose}>
          <CogIcon fontSize="small" />
          <Typography variant="body2">
            {IntlFormatter.getMessage("accountMenu.setting", "Setting")}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} disableRipple>
          <LogoutIcon />
          <Typography variant="body2">
            {IntlFormatter.getMessage("accountMenu.logout", "Logout")}
          </Typography>
        </MenuItem>
      </StyledMenu>

      <SelectLanguage
        selectedValue={selectedValue}
        open={languageSelector}
        onClose={handleClose}
      />
    </>
  );
};

export default AccountMenu
