import { Middleware } from "@reduxjs/toolkit";
import accountApi from "./account";
import authApi from "./auth";
import workerApi from "./worker";
import publicApi from "./public";

export const apiReducers = {
  [authApi.reducerPath]: authApi.reducer,
  [workerApi.reducerPath]: workerApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
};

export const apiMiddlewares: Middleware[] = [
  authApi.middleware,
  workerApi.middleware,
  accountApi.middleware,
  publicApi.middleware,
];

export {
  authApi,
  workerApi,
  accountApi,
  publicApi,
};

