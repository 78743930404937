import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextFieldProps,
  OutlinedInput,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { Option } from "src/main/types";
import { AccessTime } from "@mui/icons-material";
import { ChevronDown } from "src/assets";

type SortbarProps = TextFieldProps & {
  onOptionChange: (option: Option) => void;
  options: Option[];
  defaultSelected: string;
  onOpenTimeDialog?: () => void;
  value?: string;
};

const SortbarComponent: React.FC<SortbarProps> = (props) => {
  const {
    value,
    onOptionChange,
    options,
    label = "Sort By",
    placeholder = "sort",
    onOpenTimeDialog,
  } = props;

  const onChangeHandler = (val: string) => {
    let found = options.find((opt) => opt.value === val);
    if (found) onOptionChange(found);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      sx={{
        backgroundColor: "background.paper",
        borderRadius: 1,
      }}
    >
      <InputLabel shrink>{label}</InputLabel>
      <Select
        input={
          <OutlinedInput
            placeholder={placeholder}
            notched
            label={label}
            sx={{ pr: 1 }}
          />
        }
        IconComponent={
          onOpenTimeDialog
            ? (props) => (
                <IconButton onClick={() => onOpenTimeDialog()}>
                  <AccessTime />
                </IconButton>
              )
            : !!options.length
            ? (props) => <ChevronDown sx={{ fontSize: 14, mr: 1 }} {...props} />
            : undefined
        }
        name="sort"
        onChange={(ev: SelectChangeEvent) => {
          onChangeHandler(ev.target.value);
        }}
        placeholder={placeholder}
        value={value}
      >
        {options.map((option: Option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortbarComponent;
