import React, { ReactNode } from "react";
import { List, ListSubheader, SxProps, Divider, Box } from "@mui/material";
import { SideBarItem } from "src/main/components/Page/components/SideBar/components";

interface SideBarSectionProps extends React.PropsWithChildren {
  items: Item[];
  path: string;
  depth?: number;
  icon?: ReactNode;
  title: string;
  length: number;
  sx?: SxProps;
}

interface Item {
  path?: string;
  icon?: ReactNode;
  chip?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

const SideBarSection: React.FC<SideBarSectionProps> = (props) => {
  const { items, path, title, icon, length, ...other } = props;

  const renderNavItems = ({ depth = 0, items, path }: { depth?: number; items: Item[]; path: string; }): JSX.Element => (
    <List disablePadding>
      {items.reduce(
        (acc: JSX.Element[], item, index) => reduceChildRoutes({ acc, depth, item, path, index }),
        []
      )}
    </List>
  );

  const reduceChildRoutes = ({ acc, depth, item, path, index }: { acc: JSX.Element[]; depth: number; item: Item; path: string; index: number }): Array<JSX.Element> => {
    const key = `${item.title}-${depth}`;
    const partialMatch = item.path ? path.includes(item.path) : false;
    const exactMatch = path.split('?')[0] === item.path; // We don't compare query params
   
    let childActive = false;

    if (item.items) {
      childActive = !!item.items.find((child) => {
        const cPartial = child.path ? path.includes(child.path) : false;
        //const cExactMatch = path.split('?')[0] === item.path;
        return  cPartial
      })

      acc.push(
        <SideBarItem
          active={partialMatch || childActive}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch || childActive}
          path={item.path}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.items,
            path
          })}
        </SideBarItem>
      );
    } else {
      acc.push(
        <SideBarItem
          active={exactMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
        />
      );
    }

    if (!depth && index !== length - 1) {
      acc.push(
        <Box key={'div' + key} sx={{ px: 1 }}>
          <Divider sx={{ borderColor: 'neutral.200' }} />
        </Box>
      )
    }

    return acc;
  };


  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'neutral.500',
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: 'uppercase'
          }}
        >
          {title}
        </ListSubheader>
      )}
      sx={{
        width: "100%",
      }}
      {...other}
    >
      {renderNavItems({
        items,
        path
      })}
    </List>
  );
};

export default SideBarSection;
