import { capitalize } from "@mui/material";
import React, { useCallback, useState } from "react";
import { ObjectSchema, ValidationError } from "yup";
import { ObjectShape } from "yup/lib/object";
import { APIError, SimpleMap } from "../utils";

export type StringMap<T> = { [key in keyof T]: string };
export type FormError<T> = Partial<StringMap<T>>;

export interface FormStates<T> {
  input: T;
  error: FormError<T>;
}

interface ReturnHandlers<T> {
  setInput: (formState: T | ((prevState: T) => T)) => void;
  setError: (error: FormError<T>) => void;

  onInputChange: (key: keyof T) => React.ChangeEventHandler;
  onValueChange: (key: keyof T) => ((string) => void);
  consumeAPIError: (apiError: APIError, errorKeyMap?: SimpleMap) => FormError<T> | null;
  validate: () => object | undefined;
}
type ReturnType<T> = [FormStates<T>, ReturnHandlers<T>]

const useFormState = <T, K extends ObjectShape>(initialFormState: T, validationSchema?: ObjectSchema<K>): ReturnType<T> => {
  const [formState, setFormState] = useState<T>(initialFormState);
  const [formError, setFormError] = useState<FormError<T>>({});

  const consumeAPIError = useCallback((apiError: APIError, keyMap: SimpleMap = {}) => {
    if (apiError?.type === "ValidationError") {
      const errors: FormError<T> = {};
      for (const error of Object.values<any>(apiError.errors)) {
        const key = keyMap[error.param] ?? error.param;
        errors[key] = capitalize(error.msg);
      }
      setFormError(errors);
      return errors;
    }
    return null;
  }, [setFormError]);

  const onValueChange = useCallback((key: keyof T) => {
    return (value: string) => {
      setFormState((formState) => ({
        ...formState,
        [key]: value,
      }))
    }
  }, [setFormState]);

  const onInputChange = useCallback((key: keyof T) => {
    return (evt: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(key)(evt.target.value);
    }
  }, [onValueChange]);

  const validate = useCallback(() => {
    if (!validationSchema) {
      console.error("validation schema not provided");
    }
    try {
      validationSchema?.validateSync(formState, {
        abortEarly: false,
      });
    } catch (error: unknown) {
      if (error instanceof ValidationError) {
        const errors: FormError<T> = {};

        if (error.path) {
          errors[error.path ?? ""] = error.message;
        } else if (error.inner) {
          for (const item of error.inner) {
            errors[item.path ?? ""] = item.message;
          }
        }

        if (Object.keys(errors).length > 0) {
          setFormError(errors);
          return errors;
        }
      }

      throw error;
    }
    return undefined;
  }, [validationSchema, formState]);

  return [{
    input: formState,
    error: formError,
  }, {
    setInput: setFormState,
    setError: setFormError,
    onInputChange,
    onValueChange,
    consumeAPIError,
    validate,
  }];
};

export default useFormState;
