import { Box, Button, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accountApi } from "src/main/api";
import { LoadingContainer, Page, Tabs } from "src/main/components";
import { Paths } from "src/main/utils";
import { TeamDetail, TaskList } from "./components";
import EditIcon from '@mui/icons-material/Edit';
interface TeamDetailPageProps extends React.PropsWithChildren {
}

const tabs = [
  { value: "details", label: "Details" },
  { value: "taskList", label: "Task List" },
]

const TeamDetailPage: React.FC<TeamDetailPageProps> = (props) => {
  const { teamId = "" } = useParams();

  const { search } = useLocation();
  const navigate = useNavigate();

  const tabVal = useMemo(() => {
    const vals = new URLSearchParams(search);
    if (vals.get("tab")) return vals.get("tab");
    return "details";
  }, [search]);

  const result = accountApi.useGetTeamQuery({ teamId });
  const teamData = useMemo(() => result.data?.model ?? undefined, [result?.data?.model]);

  const updateTab = (tab: string) => {
    navigate(Paths.Management.TeamDetail.replace(":teamId", teamId) + `?tab=${tab}`, { replace: true });
  }
  return (
    <Page>
      <Page.TopSection title={"Team Detail"}>
        {teamData && tabVal === "details" && (
          <Button
            type="submit"
            variant="contained"
            onClick={() => navigate(Paths.Management.EditTeam.replace(":teamId", teamId))}
          >
            <EditIcon />&nbsp;<Typography>Edit</Typography>
          </Button>
        )}
      </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        <Box>
          <Tabs value={tabVal} updateTab={updateTab} tabs={tabs} sx={{ paddingX: 2 }} />
          {tabVal === "details" && teamData && (
            <TeamDetail team={teamData} />
          )}
          {tabVal === "taskList" && teamData && (
            <TaskList teamId={teamData.id} />
          )}
        </Box>
      </LoadingContainer>
    </Page>
  );
};

export default TeamDetailPage;
