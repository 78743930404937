import { AccessTime, LaunchRounded } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Container, Divider, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { CashBillIcon } from "src/assets";
import { workerApi } from 'src/main/api';
import { LoadingButton, TaskExpiryChip, Typography } from 'src/main/components';
import { Breadcrumb } from "src/main/components/Page/components";
import { CrumbsProps } from "src/main/components/Page/components/Breadcrumb/Breadcrumb";
import { useAsyncTask, useIsXs } from 'src/main/hooks';
import { TaskObjectiveModel } from "src/main/types";
import { Paths, SimpleMap, createStyles, handleApiResponse, joinSx, sortByPriority, IntlFormatter } from "src/main/utils";
import { TimeCompleteInfoDialog } from "../components";
import { TaskAcceptConfirmDialog, TaskGoal, TaskIdChip, TaskInfoIcons } from "./components";

interface TaskDetailPageProps extends React.PropsWithChildren {

}


const crumbsProp: CrumbsProps[] = [
  {
    label: "Available Task",
    path: Paths.Portal.TasksAvailable
  },
  { label: "Task Detail", }
]

const HOUR_SEC = 3600;
const TaskDetailPage: React.FC<TaskDetailPageProps> = (props) => {
  const { taskId = "" } = useParams<{ taskId: string }>();
  const navigate = useNavigate();
  const isXs = useIsXs();
  const taskDetailResult = workerApi.useWorkerTaskDetailQuery(taskId, { skip: !taskId.length });
  const [acceptTaskMutation] = workerApi.useWorkerTaskAcceptMutation();
  const [runAcceptTask, loading] = useAsyncTask("worker/task/accept");
  const { palette } = useTheme();
  const [openConfirmDialogFlag, setOpenConfirmDialogFlag] = useState<boolean>(false);

  useEffect(() => {
    const task = taskDetailResult.data?.model;
    if (!task?.ongoingAttempt) return;

    navigate(Paths.Portal.AttemptDetail.replace(":attemptId", task.ongoingAttempt.id), { replace: true });
  }, [navigate, taskDetailResult.data?.model])

  const toastMessages = useMemo(() => {
    return {
      taskAccepted: IntlFormatter.getMessage("taskDetailsPage.task_accepted", "Task accepted successfully!.")
    }
  }, []);

  const taskInfo = useMemo(() => {
    const task = taskDetailResult.data?.model;
    if (!task) return null;

    const title = task.title ?? IntlFormatter.getMessage("taskAttemptPage.task_details", "Task Detail");
    const reference = task.id.substring(0, 8);
    const description = task.description ?? IntlFormatter.getMessage("taskAttemptPage.this_task_does_not_have_description", "This task does not have a description");

    const taskObjectives = task.taskObjectives?.slice() ?? [];
    taskObjectives.sort(sortByPriority);

    const objectives: { objective: TaskObjectiveModel }[] = [];
    for (const objective of taskObjectives) {
      objectives.push({
        objective
      });
    }

    let accepted = !task.periodType ? !!(task.ongoingAttempt ?? task.recentSubmittedAttempt) : !!task.ongoingAttempt;

    const notionUrl = task.notionUrl;
    const start = moment(task.start).isValid() ? moment(task.start) : null;
    const taskStarted = !task.start ? true : (moment(task.start).isValid() ? moment(task.start).isBefore(moment()) : false);
    const terminated = task.recentSubmittedAttempt?.status === "terminated";
    const pay = (task.payAmount && task.payCurrency) ? task.payAmount + " " + task.payCurrency : "N/A";

    let allowedTime: SimpleMap<number> | null = null;
    if (!!task.timeAllowed) {
      allowedTime = {};
      const hour = (task.timeAllowed >= HOUR_SEC) ? parseInt((task.timeAllowed / HOUR_SEC) + "") : 0;
      const minute = (task.timeAllowed - hour * HOUR_SEC) / 60;

      allowedTime.hour = hour;
      allowedTime.minute = minute;
    }

    return {
      ogTask: task,
      title,
      reference,
      description,
      objectives,
      accepted,
      notionUrl,
      start,
      taskStarted,
      terminated,
      pay,
      allowedTime,
    }
  }, [taskDetailResult.data]);

  const onAcceptTask = useCallback(async () => {
    runAcceptTask(async () => {
      if (!taskInfo) return;
      const taskId = taskInfo.ogTask.id;
      const result = await acceptTaskMutation(taskId);

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        throw new Error(error.data?.error?.message)
      }

      toast.success(toastMessages.taskAccepted);
    })
  }, [runAcceptTask, acceptTaskMutation, taskInfo, toastMessages]);

  const taskerStatistic = useMemo(() => {
    let acceptedSubmittedSlot = 0;

    if (taskInfo?.ogTask.attemptStat) acceptedSubmittedSlot = parseInt(taskInfo?.ogTask.attemptStat?.statusSubmitted) + parseInt(taskInfo?.ogTask.attemptStat?.statusApproved);

    return acceptedSubmittedSlot + "/" + (taskInfo?.ogTask.maxAccept ?? "0");

  }, [taskInfo]);

  if (!taskInfo) return null;

  const getStartingText = () => {
    if (taskInfo.start && !taskInfo.taskStarted) {
      return (
        <Box
          mt={2}
          sx={{
            alignItems: "center",
            display: "flex",
            color: `warning.main`,
          }}
        >
          <AccessTime
            sx={styles.icon}
          />
          <Typography
            color="textSecondary"
            noWrap
            variant="overline"
            sx={styles.label}
          >
            {`starting ${moment(taskInfo.start).fromNow()}`}
          </Typography>
        </Box>
      )
    }

    //ELSE
    return (
      <>
        {!!taskInfo?.allowedTime && (
          <Box sx={{ backgroundColor: "primary.ghost", borderRadius: "10px", mt: 2, p: 1 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                color="text.primary"
                noWrap
                variant="body2"
                fontWeight={400}
                lineHeight={1.375}
              >
                {`Submit within: `}
              </Typography>&nbsp;
              <Typography color="primary" variant="body2" fontWeight="bold" lineHeight={1.375} >
                {`${taskInfo?.allowedTime?.hour ?? 0}h  ${taskInfo?.allowedTime?.minute ?? 0}m`}
              </Typography>
              <TimeCompleteInfoDialog />
            </Box>
            <Typography formatId="taskDetailsPage.timer_will_start_after_accept_task" variant="caption">The timer will start after you accept task.</Typography>
          </Box>
        )}
      </>
    );
  }

  return (
    <Container sx={styles.container}>
      <Breadcrumb crumbs={crumbsProp} />
      <Card sx={{ mt: 2 }}>
        <CardContent sx={joinSx(styles.content, { p: 0 })}>
          <Box sx={styles.rowBox}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {/*HIDE FOR FUTURE <Chip label="Task difficulty" deleteIcon={<InfoIcon fontSize="small" />} onDelete={() => { }} sx={styles.chipDifficulty} /> */}
              <TaskExpiryChip expiry={taskInfo.ogTask.expiry} />
            </Box>
            <Typography variant="h5" my={1}>
              {taskInfo.title}
            </Typography>
            <TaskInfoIcons
              pay={taskInfo.pay}
              submissionSlot={taskerStatistic}
            />
            {getStartingText()}
            <Typography mt={2} variant="body2" color="textSecondary">
              {taskInfo.description}
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
            >
              <Typography
                formatId="taskAttemptPage.taskId"
                mr={1}
                variant="subtitle2"
              />
              <TaskIdChip taskId={taskId} />
            </Box>
          </Box>
          <Divider />
          {typeof taskInfo.notionUrl === "string" && (
            <>
              <Box sx={{ textAlign: "center", p: 2 }}>
                <Typography
                  formatId="taskAttemptPage.recommended_to_review_instruction_before_accept_task"
                  variant="caption"
                  display="block"
                  lineHeight={1.375}
                >
                  It is recommended to review instruction and goals before
                  accepting a task.
                </Typography>
                <Button
                  sx={{ mt: 1 }}
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    window.open(taskInfo.notionUrl, "_blank");
                  }}
                  endIcon={
                    <Box display="flex" alignItems="center">
                      <LaunchRounded fontSize="small" />
                    </Box>
                  }
                >
                  {IntlFormatter.getMessage(
                    "taskAttemptPage.full_instructions",
                    "Full Instructions"
                  )}
                </Button>
              </Box>
              <Divider />
            </>
          )}

          <TaskGoal goals={taskInfo.objectives} pay={taskInfo.pay} />
        </CardContent>
      </Card>

      <Box textAlign={{ xs: "center", md: "right" }} mt={2}>
        {taskInfo.taskStarted && (
          <>
            {!taskInfo.accepted && (
              <Box
                display="flex"
                alignItems="center"
                px={1}
                justifyContent={{
                  xs: "space-between",
                  sm: "flex-end",
                }}
                my={1}
              >
                <Typography
                  formatId="taskDetailsPage.accept__submit_task_earn_reward"
                  variant="caption"
                  pr={1}
                >
                  Accept and submit to earn your reward
                </Typography>
                <Box display="flex" alignItems="center">
                  <CashBillIcon
                    fontSize="small"
                    sx={{ color: "text.secondary" }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 700, pl: 0.5 }}>
                    +{taskInfo.pay}
                  </Typography>
                </Box>
              </Box>
            )}
            <LoadingButton
              fullWidth={isXs}
              loading={loading}
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenConfirmDialogFlag(true);
              }}
              disabled={taskInfo.accepted}
              sx={{
                background: !taskInfo.accepted ? palette.gradient?.main : null,
              }}
            >
              {taskInfo.terminated
                ? IntlFormatter.getMessage(
                    "taskDetailsPage.terminated",
                    "Terminated"
                  )
                : taskInfo.accepted
                ? IntlFormatter.getMessage(
                    "taskDetailsPage.already_accepted",
                    "Already accepted"
                  )
                : IntlFormatter.getMessage(
                    "taskDetailsPage.accept_task",
                    "Accept Task"
                  )}
            </LoadingButton>
          </>
        )}
      </Box>
      <TaskAcceptConfirmDialog
        open={openConfirmDialogFlag}
        onClose={() => {
          setOpenConfirmDialogFlag(false);
        }}
        onClickConfirm={() => {
          onAcceptTask();
        }}
      />
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },
  content: {
    p: 2,
  },
  icon: {
    ml: 1,
    fontSize: {
      xs: "14px",
      md: "20px",
    },
    color: "primary.main"
  },
  label: {
    opacity: 0.7,
    fontWeight: "bold",
    fontSize: "14px",
  },
  rowBox: {
    p: 2
  },
  chipDifficulty: {
    '&.MuiChip-root': {
      backgroundColor: "primary.ghost",
      color: "primary.main"
    },
    '&.MuiChip-root > .MuiChip-deleteIcon': {
      color: "info.main",
      cursor: "default"
    }
  },
});

export default TaskDetailPage;
