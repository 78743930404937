import { createSvgIcon } from '@mui/material/utils';

const PauseIcon = createSvgIcon(
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.16657 5.40039C6.83063 5.40039 6.50845 5.53384 6.2709 5.77139C6.03336 6.00894 5.8999 6.33112 5.8999 6.66706V9.33372C5.8999 9.66967 6.03336 9.99185 6.2709 10.2294C6.50845 10.4669 6.83063 10.6004 7.16657 10.6004L9.83324 10.6004C10.1692 10.6004 10.4914 10.4669 10.7289 10.2294C10.9665 9.99185 11.0999 9.66967 11.0999 9.33372L11.0999 6.66706C11.0999 6.33112 10.9665 6.00894 10.7289 5.77139C10.4914 5.53384 10.1692 5.40039 9.83324 5.40039H7.16657ZM7.11943 6.61992C7.13193 6.60741 7.14889 6.60039 7.16657 6.60039H9.83324C9.85092 6.60039 9.86787 6.60741 9.88038 6.61992C9.89288 6.63242 9.8999 6.64938 9.8999 6.66706L9.8999 9.33372C9.8999 9.35141 9.89288 9.36836 9.88038 9.38086C9.86787 9.39337 9.85092 9.40039 9.83324 9.40039L7.16657 9.40039C7.14889 9.40039 7.13193 9.39337 7.11943 9.38086C7.10693 9.36836 7.0999 9.35141 7.0999 9.33372V6.66706C7.0999 6.64938 7.10693 6.63242 7.11943 6.61992Z" fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4999 1.40039C6.74947 1.40039 5.07074 2.09575 3.833 3.33349C2.59526 4.57123 1.8999 6.24996 1.8999 8.00039C1.8999 8.86712 2.07062 9.72535 2.4023 10.5261C2.73398 11.3269 3.22013 12.0544 3.833 12.6673C4.44586 13.2802 5.17344 13.7663 5.97419 14.098C6.77494 14.4297 7.63318 14.6004 8.4999 14.6004C9.36663 14.6004 10.2249 14.4297 11.0256 14.098C11.8264 13.7663 12.5539 13.2802 13.1668 12.6673C13.7797 12.0544 14.2658 11.3269 14.5975 10.5261C14.9292 9.72535 15.0999 8.86711 15.0999 8.00039C15.0999 6.24996 14.4045 4.57123 13.1668 3.33349C11.9291 2.09575 10.2503 1.40039 8.4999 1.40039ZM4.68153 4.18201C5.69422 3.16932 7.06773 2.60039 8.4999 2.60039C9.93207 2.60039 11.3056 3.16932 12.3183 4.18201C13.331 5.19471 13.8999 6.56822 13.8999 8.00039C13.8999 8.70953 13.7602 9.41172 13.4889 10.0669C13.2175 10.722 12.8197 11.3173 12.3183 11.8188C11.8168 12.3202 11.2216 12.718 10.5664 12.9893C9.91124 13.2607 9.20904 13.4004 8.4999 13.4004C7.79076 13.4004 7.08857 13.2607 6.43341 12.9893C5.77825 12.718 5.18296 12.3202 4.68153 11.8188C4.18009 11.3173 3.78233 10.722 3.51095 10.0669C3.23958 9.41172 3.0999 8.70953 3.0999 8.00039C3.0999 6.56822 3.66883 5.19471 4.68153 4.18201Z" fill="currentColor" />
  </svg>
  ,
  'PauseIcon'
);

export default PauseIcon;