import { Box, BoxProps, DialogContent, DialogTitle, Rating, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { TaskAttemptModel } from "src/main/types";
import { createStyles, joinSx } from "src/main/utils";
import StarIcon from '@mui/icons-material/Star';
import { ActionButton, LoadingButton } from "src/main/components";
import { accountApi } from "src/main/api";
import { useAsyncTask } from "src/main/hooks";
import BigNumber from "bignumber.js";
import { toast } from "react-hot-toast";

interface ApproveContentProps extends BoxProps {
  taskAttempt: TaskAttemptModel;
  onClose: () => void;
  onCancel: () => void;
}

const getInitialScore = (attempt: TaskAttemptModel) => {
  if (attempt.rejectCount === 0) return 5;
  if (attempt.rejectCount === 1) return 3;
  return 1;
}

const ApproveContent: React.FC<ApproveContentProps> = (props) => {
  const { taskAttempt, onCancel, onClose, sx } = props;
  const [value, setValue] = React.useState<number | null>(getInitialScore(taskAttempt));

  const [runApproveTask] = useAsyncTask("approve/task");
  const [approveTaskAttemptMutation, { isLoading: isApproveLoading }] = accountApi.useApproveTaskAttemptMutation();

  const handleConfirm = useCallback(() => {
    runApproveTask(async () => {
      if (!taskAttempt) return;
      const result = await approveTaskAttemptMutation({
        attemptId: taskAttempt.id!, data: {
          payAmount: new BigNumber(taskAttempt?.task?.payAmount ?? 0).toString(),
          payCurrency: taskAttempt?.task?.payCurrency ?? "",
          score: value ? parseInt((value * 2) + "") : 0,
        }
      });

      if ("data" in result) {
        toast.success("Task Approved!");
        onClose?.();
      } else {
        toast.error((result.error as any).data?.error?.message)
        throw new Error((result.error as any).data?.error?.message)
      }
    })
  }, [taskAttempt, onClose, approveTaskAttemptMutation, runApproveTask, value]);

  return (
    <>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Approve Submission?</Typography>
          <Typography>Rejections: {taskAttempt.rejectCount}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={joinSx(styles.root, sx)}>
        <Box display="flex" flexDirection="column" p={2}>
          <Rating
            sx={{ alignSelf: "center" }}
            name="hover-feedback"
            size="large"
            value={value}
            precision={0.5}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          />
        </Box>
        <Box display="flex" flexDirection="column" p={2}>
          <Typography>This submission will be awarded {value ?? 0} star{(!!value && value > 1) ? "s" : ""}</Typography>
        </Box>
        <Box sx={styles.buttonBox}>
          <ActionButton disabled={isApproveLoading} color="inherit" onClick={onCancel}>Cancel</ActionButton>
          <LoadingButton
            fullWidth
            variant="contained"
            loading={isApproveLoading}
            onClick={handleConfirm}>Confirm</LoadingButton>
        </Box>
      </DialogContent>
    </>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    mt: 3,
  }
})

export default ApproveContent;
