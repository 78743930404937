import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { Box, CardProps, IconButton, Typography, useTheme } from "@mui/material";
import moment from 'moment';
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { ListWithdrawResponse } from "src/main/api/account/Withdraw";
import { DetailCard, ListingComp, MonoLabel, Table } from "src/main/components";
import { RootState } from "src/main/store";
import { ThemeMode } from "src/main/store/preference/types";
import { Meta, PersonModel } from "src/main/types";
import { commonStyles, Paths, truncate } from "src/main/utils";


const WITHDRAW_REQUEST_HEADERS = [
  { value: "Request" },
  { value: "Tasker" },
  { value: "" },
  { value: "" },
  { value: "" },
]
interface WithdrawReqProps extends CardProps {
}
const WithdrawReq: React.FC<WithdrawReqProps> = (props) => {
  const theme = useTheme();
  const curTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 6, offset: 0, status: "submitted" });

  const result = accountApi.useListWithdrawRequestQuery({ meta });
  const WithdrawRequests: ListWithdrawResponse | undefined = useMemo(() => result.data ?? undefined, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);

  const getWorkerName = (person?: PersonModel) => {
    if (!person) return "-"
    const { firstname, middlename, lastname } = person;
    const parsedMiddle = !!middlename ? ` ${middlename}` : "";
    const parsedLast = !!lastname ? ` ${lastname}` : ""
    return firstname + parsedMiddle + parsedLast;
  }

  return (
    <DetailCard {...props} variant="elevation" >
      <DetailCard.Header
        sx={commonStyles.cardHeader}
        header="Withdraw Requests Pending"
        routeLabel="All Requests"
        routeTo={Paths.Management.WithdrawRequest}
      />
      <DetailCard.Divider />
      <ListingComp hidePagination
        loading={result.isLoading} title=""
        updateList={(newMeta) => { setMeta(newMeta) }} meta={persistMeta}
        sx={{margin:0}}
      >
        <ListingComp.Content>
          <Table>
            <Table.Head headers={WITHDRAW_REQUEST_HEADERS} />
            <Table.Body>
              {WithdrawRequests?.entries.map((req) => (
                <Table.Row>
                  <Table.Cell><MonoLabel>{truncate(req.id, 8)}</MonoLabel></Table.Cell>
                  <Table.Cell>
                    <Typography>{getWorkerName(req.worker?.person)}</Typography>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Requested&nbsp;{moment(req.createdAt).fromNow()}</Typography>
                  </Table.Cell>
                  <Table.Cell>{req.amount.toString()} {req.currency}</Table.Cell>
                  <Table.Cell align='right'>
                    <PendingOutlinedIcon sx={{ color: theme.palette.warning[curTheme] }} />
                  </Table.Cell>
                  <Table.Cell>
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.WithdrawRequestDetail.replace(":withdrawRequestId", req.id)}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Box flex={1} />
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </DetailCard>
  )
}

export default WithdrawReq;
