import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { QueryTags } from "src/main/constants";
import { AuthToken } from "src/main/store";
import { AccountCurrencyModel, AccountEntityModel, AccountModel, SelfEntityModel, WorkerModel } from "src/main/types";
import { require200Status, transformAccessToken } from "src/main/utils";
import { queryWithAuth } from "../base";
import { queryInviteToken, queryOnboardToken, queryPasswordToken, requestPasswordRequest, submitInvite, submitOnboard, submitPasswordReset, queryPendingNotification, acceptPendingNotification, submitPersonInfo } from "./endpoints";

export interface QueryResult<T> {
  result: T;
  meta: any; // TODO: type meta
}
export interface OAuthAccessTokenResponse extends AuthToken {
}
export interface OAuthAccessTokenResult {
  token_type: "bearer";
  access_token: string;
  expires_at: number;
  expires_in: number;
  refresh_expires_at: number;
  refresh_expires_in: number;
  refresh_token: string;
}
export interface OAuthAccessTokenRequest {
  grantType: "password" | "refresh_token";
  accessKey?: string;
  secret?: string;
  account?: string;
  refresh_token?: string;
}
export interface OAuthRefreshTokenRequest {
  grantType: "refresh_token";
  refreshToken: string;
}
export interface ProfileResponse {
  self: SelfEntityModel;
  workspace?: AccountEntityModel;
  worker?: WorkerModel;
  accounts: AccountModel[];
  currency?: AccountCurrencyModel;
}

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: queryWithAuth,
  tagTypes: QueryTags.Worker,
  endpoints: (builder) => ({
    accessToken: builder.mutation<OAuthAccessTokenResponse, OAuthAccessTokenRequest>({
      invalidatesTags: ["self"],
      query: (props: OAuthAccessTokenRequest) => ({
        url: "/oauth/access_token",
        method: "POST",
        body: {
          grant_type: props.grantType,
          client_id: props.accessKey,
          client_secret: props.secret,
          account: props.account,
          refresh_token: props.refresh_token,
        },
        validateStatus: require200Status,
      }),
      transformResponse: async (response: OAuthAccessTokenResult, meta, arg): Promise<OAuthAccessTokenResponse> => {
        return transformAccessToken(response);
      },
    }),
    refreshToken: builder.mutation<OAuthAccessTokenResponse, OAuthRefreshTokenRequest>({
      invalidatesTags: ["self"],
      query: (props: OAuthRefreshTokenRequest) => ({
        url: "/oauth/access_token",
        method: "POST",
        body: {
          grant_type: props.grantType,
          refresh_token: props.refreshToken,
        },
        validateStatus: require200Status,
      }),
      transformResponse: async (response: OAuthAccessTokenResult, meta, arg): Promise<OAuthAccessTokenResponse> => {
        return transformAccessToken(response);
      },
    }),

    profile: builder.query<ProfileResponse, void>({
      providesTags: ["self"],
      query: () => ({
        url: "self/profile",
        validateStatus: require200Status,
      }),
      transformResponse: (response: QueryResult<ProfileResponse>) => {
        return response.result;
      },
    }),

    authenticate: builder.query<ProfileResponse, string>({
      providesTags: ["self"],
      query: (token: string) => ({
        url: "self/profile",
        validateStatus: require200Status,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }),
      transformResponse: (response: QueryResult<ProfileResponse>) => {
        return response.result;
      },
    }),

    passwordRequest: requestPasswordRequest(builder),
    passwordRequestToken: queryPasswordToken(builder),
    passwordReset: submitPasswordReset(builder),

    onboardToken: queryOnboardToken(builder),
    onboardSubmit: submitOnboard(builder),

    inviteToken: queryInviteToken(builder),
    inviteSubmit: submitInvite(builder),
    pendingNotification: queryPendingNotification(builder),
    acceptPendingNotification: acceptPendingNotification(builder),
    workerPersonInfo : submitPersonInfo(builder),
  }),
});

export default authApi;
