import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { workerApi } from 'src/main/api';
import { ListingComp, Table, Tabs, PortalLayout, Typography } from "src/main/components";
import { Meta } from "src/main/types";
import { Paths, createStyles, IntlFormatter } from "src/main/utils";
import WithdrawCard from "../PayoutsPage/components/WithdrawCard/WithdrawCard";
import { useLocation, useNavigate } from 'react-router-dom';

const tabs = [
  { value: "all", label: "All" },
  { value: "submitted", label: "Submitted" },
  { value: "processed", label: "Processed" },
  { value: "cancelled", label: "Cancelled" },
]
interface WithdrawHistoryListProps extends React.PropsWithChildren {

}

const TopTabs = styled(Tabs)(
  ({ theme }) => ({
    ...(
      {
        borderBottom: 0,
        backgroundColor: theme.palette.background.paper,
        marginRight: -16,
        marginLeft: -16,
      }
    )
  })
);

const WithdrawHistoryList: React.FC<WithdrawHistoryListProps> = (props) => {
  const [tabVal, setTabVal] = useState("all");
  const { search } = useLocation();
  const navigate = useNavigate();
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0, status: "processed,submitted,cancelled" });

  const withdrawsResult = workerApi.useWorkerWithdrawListQuery({ meta }, { refetchOnMountOrArgChange: true });
  const listResult = useMemo(() => withdrawsResult.data ?? undefined, [withdrawsResult.data]);

  const persisMeta = useMemo(() => ({ ...meta, ...withdrawsResult.data?.meta }), [withdrawsResult.data, meta])

  const updateTab = useCallback((curTab) => {
    const newMeta = { ...persisMeta };

    setTabVal(curTab)
    if (curTab === "all") {
      delete newMeta.status;
    } else {
      newMeta.status = curTab;
    }
    setMeta(newMeta);
  }, [persisMeta, setMeta, setTabVal])

  useEffect(() => {
    const vals = new URLSearchParams(search);
    const tabValue = vals.get("tab")
    if (tabValue) {
      setTabVal(tabValue ?? "all");
      updateTab(tabValue)
      navigate(Paths.Portal.WithdrawHistoryList);
    }
    // eslint-disable-next-line
  }, [])

  const translatedTabs = tabs.map(tab => ({
    ...tab,
    label: IntlFormatter.getMessage("withdrawHistoryPage." + tab.label, tab.label),
  }));


  return (
    <Container sx={styles.container}>
      <PortalLayout.BackButton url={Paths.Portal.Payouts} label={IntlFormatter.getMessage("payoutPage.payout_summary", "Payout Summary")} />
      <Typography formatId="payoutPage.withdrawal_history" variant="h6" my={2}>Withdrawal History</Typography>
      <TopTabs
        value={tabVal}
        updateTab={updateTab}
        tabs={translatedTabs}
        variant='fullWidth'
      />
      <ListingComp
        title=""
        loading={withdrawsResult.isLoading}
        updateList={(newMeta) => { setMeta(newMeta) }}
        meta={persisMeta}
        sx={styles.list}
        mode="tasker"
      >
        <ListingComp.Content>
          <Table>
            <Table.Body>
              {
                listResult?.entries?.map((history) => (
                  <WithdrawCard key={history.id} withdraw={history} sx={{ mb: 1 }} />
                ))
              }
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Container>
  )
}

const styles = createStyles({
  container: {
    pt: 1,
  },
  list: {
    mx: 0,
    backgroundColor: "inherit",
    boxShadow: "inherit",
  }
});

export default WithdrawHistoryList;