import { Button, IconButton, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { DateDisplay, ListingComp, Page, Table } from "src/main/components";
import { AccountVariableBrandType, Meta } from "src/main/types";
import { Paths, SimpleMap } from "src/main/utils";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


interface AffiliatePageHomeProps extends React.PropsWithChildren {

}

const HEADERS = [
  { value: "Brand" },
  { value: "Page Name" },
  { value: "Affiliate Page Key" },
  { value: "" }
]

const AffiliatePageHome: React.FC<AffiliatePageHomeProps> = () => {
  //TODO: customized sort
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })

  const result = accountApi.useListAffiliatePageQuery({ meta });
  const varResult = accountApi.useListAccountVariableQuery({});
  const affiliatePageResult = useMemo(() => result.data ?? null, [result.data]);

  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])

  const brandNames = useMemo(() => {
    const brandList = varResult.data?.entries.find(v => v.key === "worker-brands")?.content as AccountVariableBrandType | undefined;
    return brandList?.items.reduce((accum, brand) => {
      accum[brand.key] = brand.label;
      return accum;
    }, {} as SimpleMap) ?? {};
  }, [varResult.data]);

  return (
    <Page>
      <Page.TopSection title="Affiliate">
        <Button
          component={RouterLink}
          to={Paths.Management.AffiliatePageCreate}
          size="large"
          type="submit"
          variant="contained"
        >
          <Typography color="#fff">Create New Landing Page</Typography>
        </Button>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading} title="Affiliates"
        updateList={(newMeta) => { setMeta(newMeta) }}
        meta={persistMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          <Table>
            <Table.Head headers={HEADERS} />
            <Table.Body>
              {affiliatePageResult?.entries.map((affiliate) => (
                <Table.Row>
                  <Table.Cell width="200px">
                    {brandNames[affiliate.brandKey] ?? affiliate.brandKey}
                    <DateDisplay color="textSecondary" value={affiliate.createdAt} format="YYYY-MM-DD HH:mm" />
                  </Table.Cell>
                  <Table.Cell>
                    {affiliate.name}
                  </Table.Cell>
                  <Table.Cell>
                    {affiliate.affiliatePageKey}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.AffiliatePageDetail.replaceAll(":affiliatePageId", affiliate.id)}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default AffiliatePageHome;
