import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DateDisplay, ListingComp, Page, Table } from "src/main/components";
import { Paths } from "src/main/utils";
import { Meta } from "src/main/types";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

interface UserPageProps extends React.PropsWithChildren {}

const TaskHeaders = [
  { value: "Name" },
  { value: "Email" },
  { value: "Date" },
  { value: "" },
];

const UserPage: React.FC<UserPageProps> = (props) => {
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 });
  const result = accountApi.useListUserQuery({ meta });
  const persons = useMemo(() => result.data ?? undefined, [result.data]);
  const persistMeta = useMemo(
    () => ({ ...meta, ...result.data?.meta }),
    [result.data, meta]
  );

  return (
    <Page>
      <Page.TopSection title="Users">
        <Button
          component={RouterLink}
          to={Paths.Overview.InviteUser}
          size="large"
          type="submit"
          variant="contained"
        >
          Invite User
        </Button>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading}
        title="Users"
        updateList={(newMeta) => {
          setMeta(newMeta);
        }}
        meta={persistMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          <Table>
            <Table.Head headers={TaskHeaders} />
            <Table.Body>
              {persons?.entries.map((person) => (
                <Table.Row>
                  <Table.Cell
                    value={
                      <Box display="flex" flexDirection="column">
                        <Typography>{person.firstname}</Typography>
                        <Typography color="text.secondary">
                          {person.nickname}
                        </Typography>
                      </Box>
                    }
                  />
                  <Table.Cell value={person.primaryEmail} />
                  <Table.Cell
                    value={<DateDisplay value={person.createdAt} />}
                  />
                  <Table.Cell
                    align="right"
                    value={
                      <IconButton
                        component={RouterLink}
                        to={Paths.Overview.UserDetail.replace(
                          ":userId",
                          person.id
                        )}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    }
                  />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default UserPage;
