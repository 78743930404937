import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { EnforceDomain, PortalLayout } from "src/main/components";
import { RootState } from "src/main/store";
import { SelfEntityModel } from "src/main/types";
import { makeRelativePath, Paths } from "src/main/utils";
import HistoryPage from "./HistoryPage";
import HomePage from "./HomePage";
import PayoutsPage from "./PayoutsPage";
import SettingsPage from "./SettingsPage";
import TaskAttemptPage from "./TaskAttemptPage";
import TaskDetailPage from "./TaskDetailPage";
import TasksPage from "./TasksPage";
import WithdrawDetailPage from "./WithdrawDetailPage";
import WithdrawRequestPage from "./WithdrawRequestPage";
import WithdrawHistoryList from "./WithdrawHistoryList/WithdrawHistoryList";
import { workerApi } from "src/main/api";
import { useAsyncTask } from "src/main/hooks";
import layout from "src/main/store/layout";

interface MainProps extends React.PropsWithChildren {}
const Main: React.FC<MainProps> = (props) => {
  const navigate = useNavigate();
  const profile = useSelector<RootState, SelfEntityModel | undefined | null>(
    (state) => state.auth.profile
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const [queryTaskStats] = workerApi.useLazyWorkerTaskStatsQuery();
  const [loadTaskStats] = useAsyncTask("load/task/stats");

  const updateTaskStats = () => {
    loadTaskStats(async () => {
      const result = await queryTaskStats({});
      const taskStats = result?.data?.task ?? null;

      dispatch(layout.slice.actions.updateTaskStatistic(taskStats));
    });
  };

  useEffect(() => {
    if (profile && !profile.person?.worker) {
      navigate("/overview");
    } else if (profile === null) {
      navigate("/auth");
    } else if (profile) {
      updateTaskStats();
    }
    return () => {};
    // eslint-disable-next-line
  }, [navigate, profile]);

  const p = makeRelativePath(Paths.Portal.Index);

  if (!profile) return null;

  return (
    <PortalLayout.Container>
      <EnforceDomain />
      <PortalLayout.SideBar
        onClose={(): void => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      />
      <PortalLayout.TopBar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <PortalLayout.Content>
        <Routes>
          <Route path={p(Paths.Portal.Home)} element={<HomePage />} />
          <Route
            path={p(Paths.Portal.TaskDetail)}
            element={<TaskDetailPage />}
          />
          <Route
            path={p(Paths.Portal.AttemptDetail)}
            element={<TaskAttemptPage />}
          />
          <Route path={p(Paths.Portal.Tasks, "/*")} element={<TasksPage />} />
          <Route path={p(Paths.Portal.History)} element={<HistoryPage />} />
          <Route path={p(Paths.Portal.Payouts)} element={<PayoutsPage />} />
          <Route
            path={p(Paths.Portal.Withdraw)}
            element={<WithdrawRequestPage />}
          />
          <Route
            path={p(Paths.Portal.WithdrawHistoryList)}
            element={<WithdrawHistoryList />}
          />
          {/* <Route path={p(Paths.Portal.WithdrawPending)} element={<WithdrawPendingPage />} /> */}
          <Route
            path={p(Paths.Portal.WithdrawRequestDetail)}
            element={<WithdrawDetailPage />}
          />
          <Route path={p(Paths.Portal.Settings)} element={<SettingsPage />} />
          <Route
            path="*"
            element={<Navigate to={Paths.Portal.Home} replace />}
          />
        </Routes>
      </PortalLayout.Content>
      <PortalLayout.BottomBar />
    </PortalLayout.Container>
  );
};

export default Main;
