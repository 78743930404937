import { Box, MenuItem, Select, Table, TableBody } from "@mui/material";
import React from "react";
import { DetailCard, InputField, TableRowWrap } from "src/main/components";
import { SimpleMap } from "src/main/utils";

interface PersonalInformationProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  handleChange?: (e: string | React.ChangeEvent<any>) => void;
  errors?: SimpleMap<string | undefined>;
  touched?: SimpleMap<Boolean | undefined>;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const GENDER_OPTIONS = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "others", label: "Others" },
];

const SALUTATION_OPTIONS = [
  { value: "Mr.", label: "Mr" },
  { value: "Ms.", label: "Ms" },
  { value: "Mrs.", label: "Mrs" },
  { value: "Mdm.", label: "Mdm" },
  { value: "Dr.", label: "Dr" },
];

const PersonalInformationComponent: React.FC<PersonalInformationProps> = (
  props
) => {
  const {
    values,
    handleChange,
    errors = {},
    touched = {},
    handleBlur,
    setFieldValue,
  } = props;

  return (
    <Box flex={1} pb={2}>
      <DetailCard>
        <DetailCard.Header header="Personal Information" />
        <DetailCard.Divider />
        <Box gap={2} sx={{}}>
          <Table>
            <TableBody>
              <TableRowWrap
                label="Email"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="primaryEmail"
                    required
                    value={values.primaryEmail}
                    error={!!errors.primaryEmail && !!touched.primaryEmail}
                    helperText={!!touched.primaryEmail && errors.primaryEmail}
                    placeholder="e.g: tom@example.com"
                  />
                }
              />
              <TableRowWrap
                label="Firstname"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    name="firstname"
                    value={values.firstname}
                    error={!!errors.firstname && !!touched.firstname}
                    helperText={!!touched.firstname && errors.firstname}
                    placeholder="e.g: Tom"
                  />
                }
              />
              <TableRowWrap
                label="Middlename"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="middlename"
                    value={values.middlename}
                    error={!!errors.middlename && !!touched.middlename}
                    helperText={!!touched.middlename && errors.middlename}
                    placeholder="e.g: Anderson"
                  />
                }
              />
              <TableRowWrap
                label="Lastname"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="lastname"
                    value={values.lastname}
                    error={!!errors.lastname && !!touched.lastname}
                    helperText={!!touched.lastname && errors.lastname}
                    placeholder="e.g: Smith"
                  />
                }
              />
              <TableRowWrap
                label="Phone"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="primaryPhone"
                    value={values.primaryPhone}
                    error={!!errors.middlename && !!touched.middlename}
                    helperText={!!touched.middlename && errors.middlename}
                    placeholder="e.g: 1234567890"
                  />
                }
              />
              <TableRowWrap
                label="GCash Account"
                value={
                  <InputField
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="gCashAccount"
                    value={values.gCashAccount}
                    error={!!errors.gCashAccount && !!touched.gCashAccount}
                    helperText={!!touched.gCashAccount && errors.gCashAccount}
                    placeholder="e.g: 01234567890"
                  />
                }
              />
              <TableRowWrap
                label="Gender"
                value={
                  // <InputField
                  //   onChange={handleChange}
                  //   onBlur={handleBlur}
                  //   name="gender"
                  //   value={values.gender}
                  //   error={!!errors.gender && !!touched.gender}
                  //   helperText={!!touched.gender && errors.gender}
                  // />
                  <Select
                    value={values.gender}
                    fullWidth
                    sx={{
                      paddingY: "12px",
                      paddingX: "10px",
                      "& .MuiSelect-select": {
                        p: 0,
                      },
                    }}
                    onChange={(ev) => {
                      setFieldValue?.("gender", ev.target.value);
                    }}
                  >
                    {GENDER_OPTIONS.map((gender) => (
                      <MenuItem value={gender.value}>{gender.label}</MenuItem>
                    ))}
                  </Select>
                }
              />

              <TableRowWrap
                label="Salutation"
                value={
                  <Select
                    value={values.salutation}
                    fullWidth
                    sx={{
                      paddingY: "12px",
                      paddingX: "10px",
                      "& .MuiSelect-select": {
                        p: 0,
                      },
                    }}
                    onChange={(ev) => {
                      setFieldValue?.("salutation", ev.target.value);
                    }}
                  >
                    {SALUTATION_OPTIONS.map((salutation) => (
                      <MenuItem value={salutation.value}>
                        {salutation.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />

              <TableRowWrap
                label="Facebook Handle"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="facebookHandle"
                    value={values.facebookHandle}
                    error={!!errors.facebookHandle && !!touched.facebookHandle}
                    helperText={
                      !!touched.facebookHandle && errors.facebookHandle
                    }
                    placeholder="e.g: tomcruise"
                  />
                }
                tooltip="FB username (e.g. Facebook.com/yourname : just input yourname )  or profile ID (e.g. Facebook.com/id=123456789 : just input 123456789"
              />

              <TableRowWrap
                label="Facebook Handle (Alt)"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="facebookAltHandle"
                    value={values.facebookAltHandle}
                    error={
                      !!errors.facebookAltHandle && !!touched.facebookAltHandle
                    }
                    helperText={
                      !!touched.facebookAltHandle && errors.facebookAltHandle
                    }
                    placeholder="e.g: tomcruise"
                  />
                }
                tooltip="FB username (e.g. Facebook.com/yourname : just input yourname )  or profile ID (e.g. Facebook.com/id=123456789 : just input 123456789"
              />
              <TableRowWrap
                label="Discord Handle"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="discordHandle"
                    value={values.discordHandle}
                    error={!!errors.discordHandle && !!touched.discordHandle}
                    helperText={!!touched.discordHandle && errors.discordHandle}
                    placeholder="e.g: tomcruise#3079"
                  />
                }
                tooltip="Discord username is always followed by a # and exactly four digits (e.g., UserName#1234)"
              />
              <TableRowWrap
                label="Instagram Handle"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="instagramHandle"
                    value={values.instagramHandle}
                    error={
                      !!errors.instagramHandle && !!touched.instagramHandle
                    }
                    helperText={
                      !!touched.instagramHandle && errors.instagramHandle
                    }
                    placeholder="e.g: tomcruise"
                  />
                }
                tooltip="Instagram username without @"
              />
              <TableRowWrap
                label="TikTok Handle"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="tiktokHandle"
                    value={values.tiktokHandle}
                    error={!!errors.tiktokHandle && !!touched.tiktokHandle}
                    helperText={!!touched.tiktokHandle && errors.tiktokHandle}
                    placeholder="e.g: @tomcruise"
                  />
                }
                tooltip="TikTok must start with @"
              />
              <TableRowWrap
                label="Twitter Handle"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="twitterHandle"
                    value={values.twitterHandle}
                    error={!!errors.twitterHandle && !!touched.twitterHandle}
                    helperText={!!touched.twitterHandle && errors.twitterHandle}
                    placeholder="e.g: tomcruise"
                  />
                }
                tooltip="Twitter username without @"
              />
              <TableRowWrap
                label="Telegram Handle"
                value={
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="telegramHandle"
                    value={values.telegramHandle}
                    error={!!errors.telegramHandle && !!touched.telegramHandle}
                    helperText={
                      !!touched.telegramHandle && errors.telegramHandle
                    }
                    placeholder="e.g: tomcruise"
                  />
                }
                tooltip="Telegram username without @"
              />
            </TableBody>
          </Table>
        </Box>
      </DetailCard>
    </Box>
  );
};

export default PersonalInformationComponent;
