import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { DetailCard, FormField, LoadingButton, LoadingContainer, Page } from "src/main/components";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { handleApiResponse, Paths } from "src/main/utils";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { toast } from "react-hot-toast";
import { TeamModel } from "src/main/types";

interface CreateTeamPageProps extends React.PropsWithChildren {
}

interface FormState {
  name: string;
  description: string;
}

const initialFormState: FormState = {
  name: "",
  description: "",
}

const Field = FormField<FormState>;

const CreateTeamPage: React.FC<CreateTeamPageProps> = (props) => {
  const navigate = useNavigate();
  const [formState, { onValueChange, consumeAPIError }] = useFormState(initialFormState);
  const [createTeam] = accountApi.useCreateTeamMutation();
  const [runCreateTeam, loading] = useAsyncTask("create/team");

  const onCreate = useCallback(() => {
    runCreateTeam(async () => {

      const result = await createTeam({
        name: formState.input.name,
        description: formState.input.description
      })


      const { data, error } = handleApiResponse<TeamModel>(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null) return;
        throw new Error(error.data?.error?.message)
      }
      if (!data?.id)
        throw new Error("Invalid response");
      toast.success("Team created!");
      navigate(Paths.Management.TeamDetail.replace(":teamId", data.id));
    });
  }, [runCreateTeam, createTeam, formState, consumeAPIError, navigate])

  return (
    <Page>
      <Page.TopSection
        title="Create Team"
      />
      <LoadingContainer>
        <DetailCard sx={{ maxWidth: 600, m: 2, p: 2 }}>
          <DetailCard.Header header="Team Information" />
          <Box sx={{ flexGrow: 1, mt: 3 }}>
            <Field
              label="Team Name"
              name="name"
              type="string"
              fieldKey="name"
              formStates={formState}
              onValueChange={onValueChange}
            />

            <Field
              label="Team Description"
              name="description"
              type="string"
              fieldKey="description"
              formStates={formState}
              onValueChange={onValueChange}
            />
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                fullWidth
                loading={loading}
                size="large"
                type="submit"
                variant="contained"
                onClick={onCreate}
              >
                Create Team
              </LoadingButton>
            </Box>
          </Box>
        </DetailCard>
      </LoadingContainer>
    </Page>
  );
};

export default CreateTeamPage;
