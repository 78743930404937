import { Box, BoxProps, Chip, useTheme } from "@mui/material";
import { CashBillIcon } from "src/assets";
import { commonStyles, joinSx } from "src/main/utils";
import TaskSubmissionSlot from "../TaskSubmissionSlot/TaskSubmissionSlot";
interface TaskInfoIconsProps extends BoxProps {
  pay: string | undefined;
  submissionSlot: string;
}
const TaskInfoIcons: React.FC<TaskInfoIconsProps> = (props) => {
  const { pay, submissionSlot } = props;
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box display="flex">
        <Chip
          sx={joinSx(commonStyles.borderlessChip)}
          icon={(<CashBillIcon fontSize="small" style={{ color: theme.palette.neutral?.[500] }} />)}
          label={<Box component="span" color="primary.main" >+{pay ?? "0"}</Box>}
          variant="outlined"
        />
        <TaskSubmissionSlot submissionSlot={submissionSlot} sx={{ mx: 1 }} />
      </Box>
    </Box>
  )
}

export default TaskInfoIcons;