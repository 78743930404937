import { CloseOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogProps, FormControl, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { workerApi } from "src/main/api";
import { FormField, Typography } from "src/main/components";
import { useAsyncTask, useFormState, useIsXs } from "src/main/hooks";
import { FormError } from "src/main/hooks/useFormState";
import { TaskAttemptModel, TaskObjectiveModel, TaskProofModel } from "src/main/types";
import { commonStyles, handleApiResponse, IntlFormatter } from "src/main/utils";

interface TaskObjectiveFormProps extends Omit<DialogProps, "open"> {
  objective: TaskObjectiveModel | null;
  attempt: TaskAttemptModel | null;
  proof?: TaskProofModel;
}

interface FormState {
  message: string;
  link: string;
  file: string;
}

const initFormState: FormState = {
  message: "",
  link: "",
  file: "",
};
const Field = FormField<FormState>;

const checkHttp = (url: string) => {
  if (!url) return false;
  if (!url.toLowerCase().includes("://")) return false;
  if (!url.toLowerCase().includes("http")) return false;
  return true;
}

const HTTP_OPTIONS = [
  { value: "http://", label: "http://" },
  { value: "https://", label: "https://" },
]

const TaskObjectiveForm: React.FC<TaskObjectiveFormProps> = (props) => {
  const { objective, attempt, proof, onClose, ...dialogProps } = props;
  const isXs = useIsXs();
  const [isShown, setIsShown] = useState(!!objective);
  const [file, setFile] = useState<File | null>(null)
  const [formStates, { onValueChange, consumeAPIError, setInput, setError }] = useFormState(initFormState);

  const [submitProof] = workerApi.useWorkerProofSubmitMutation();
  const [runSubmitProof, loading] = useAsyncTask("worker/proof/submit");
  const [overridePreview, setOverridePreview] = useState<string | null>(null);

  const previewImage = useMemo(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result !== "string")
          throw new Error(IntlFormatter.getMessage("taskObjective.file_cannot_be_read", "file cannot be read"));

        setOverridePreview(reader.result);
        return;
      }
      reader.readAsDataURL(file);
    }
    if (proof?.proofImage) {
      return proof.proofImage.url;
    }

    return null;
  }, [file, proof?.proofImage])

  useEffect(() => {
    if (objective) {
      setIsShown(!!objective);
      setInput((input) => {
        const newInput = { ...input };
        newInput.message = proof?.note ?? "";
        switch (objective.type) {
          case "screenshot": {
            break;
          }
          case "link": {
            newInput.link = proof?.content ?? "";
            break;
          }
        }
        return newInput;
      });
    }
    setIsShown(!!objective);
    return () => { };
  }, [setInput, proof, objective]);

  const onFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFile(evt.target.files?.[0] ?? null);
  }

  const validateInput = useCallback(() => {
    if (!objective) return;
    const errors: FormError<FormState> = {};

    if (objective.type === "link" && !formStates.input.link) {
      errors.link = IntlFormatter.getMessage("taskObjective.this_is_required", "This is required");
    }

    if (objective.type === "screenshot" && !file) {
      errors.file = IntlFormatter.getMessage("taskObjective.this_is_required", "This is required");
    }

    setError(errors);

    return Object.keys(errors).length === 0;
  }, [objective, setError, formStates.input, file]);

  const onSubmit = useCallback(async () => {
    if (!objective || !attempt) return;
    if (!validateInput()) return;

    runSubmitProof(async () => {
      const formData = new FormData();
      formData.append("note", formStates.input.message);
      if (formStates.input.link)
        formData.append("content", formStates.input.link);
      if (file)
        formData.append("file", file);

      const result = await submitProof({
        objectiveId: objective.id,
        attemptId: attempt.id,
        proofBody: formData,
      });

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null)
          return;
        throw new Error(error.data?.error?.message)
      }

      setIsShown(false);
      toast.success("Proof submitted");
    });
  }, [runSubmitProof, submitProof, consumeAPIError, validateInput, file, formStates.input, objective, attempt])

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    setOverridePreview(null);
    setFile(null);
    onClose?.(event, reason);
  }

  const { http, urlLink, noHttp } = useMemo(() => {
    let http = "";
    let urlLink = "";
    let noHttp = false;
    if (!formStates.input.link) return { http, urlLink, noHttp };

    if (checkHttp(formStates.input.link)) {
      const [urlStart, urlEnd] = formStates.input.link.split("://");
      http = urlStart + "://";
      urlLink = urlEnd;
    } else {
      urlLink = formStates.input.link;
      noHttp = true;
    }

    return { http, urlLink, noHttp }
  }, [formStates.input.link])

  const onHttpChange = useCallback((evt: SelectChangeEvent<string>) => {
    let val = evt.target.value;

    if (!val) return;
    setInput((input) => {
      let newInput = { ...input };
      newInput.link = val + urlLink;

      return newInput;
    });
  }, [urlLink, setInput])

  const onLinkChange = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let val = evt.target.value ?? "";

    setInput((input) => {
      let newInput = { ...input };
      if (checkHttp(val)) {
        newInput.link = val;
      } else {
        newInput.link = http + val;
      }
      return newInput;
    });
  }, [http, setInput])

  const proofImageKey = `upload-proof-pic-${proof?.id}`
  const displayImage = overridePreview ?? previewImage;
  const inProgress = attempt?.status === "in-progress";

  return (
    <Dialog fullWidth {...dialogProps} onClose={_onClose} open={isShown}>
      <IconButton sx={commonStyles.dialogCloseButton} onClick={(e) => _onClose(e, "escapeKeyDown")}>
        <CloseOutlined />
      </IconButton>
      <DialogContent>
        <Typography formatId="taskObjective.updateGoal" variant="h6">Update Goal</Typography>
        <Typography variant="body2">{objective?.instruction}</Typography>
        <Typography formatId="taskObjective.type" variant="overline" fontSize={8} color="textSecondary">Type: {objective?.type}</Typography>

        <form onSubmit={(event) => event.preventDefault()}>
          {objective?.type === "link" && (
            <TextField
              fullWidth
              label={IntlFormatter.getMessage("taskObjective.link", "Link")}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={urlLink}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <FormControl variant="standard">
                    <Select
                      value={http}
                      onChange={onHttpChange}
                      displayEmpty
                    >
                      <MenuItem disabled value={""}>{IntlFormatter.getMessage("taskObjective.none", "None")}</MenuItem>
                      {HTTP_OPTIONS.map((opt, index) => (
                        <MenuItem key={`task-objective-link-${index}`} value={opt.value}>{opt.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputAdornment>
              }}
              sx={{ display: displayImage ? "none" : "" }}
              onChange={onLinkChange}
              error={!!formStates.error.link || noHttp}
              helperText={formStates.error.link ?? (noHttp && "Please select http/https") ?? ""}
            />
          )}

          {objective?.type === "screenshot" && (
            <TextField
              id={proofImageKey}
              fullWidth
              label={IntlFormatter.getMessage("taskObjective.uploadProof", "Upload proof")}
              margin="normal"
              name="file"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ display: displayImage ? "none" : "" }}
              onChange={onFileChange}
              error={!!formStates.error.file}
              helperText={formStates.error.file ?? ""}
            />
          )}

          <Box display="flex" flexDirection="column" pt={2}>
            {inProgress && (
              <Box display="flex">
                {(!!proof?.proofImage || !!overridePreview) && (
                  <label htmlFor={proofImageKey} style={{ cursor: "pointer", padding: 2 }}>
                    <Typography
                      formatId="taskObjective.change"
                      color="primary"
                      gutterBottom
                      sx={{ fontSize: 14 }}
                    >
                      Change
                    </Typography>
                  </label>
                )}
                {!!overridePreview && (
                  <label
                    style={{ cursor: "pointer", padding: 2, marginLeft: 4 }}
                  >
                    <Typography
                      formatId="taskObjective.reset"
                      color="primary"
                      gutterBottom
                      sx={{ fontSize: 14 }}
                      onClick={() => {
                        setOverridePreview(null);
                        setFile(null);
                        (document.getElementById(proofImageKey) as HTMLInputElement).value = "";
                      }}
                    >
                      Reset
                    </Typography>
                  </label>
                )}
              </Box>
            )}
            {!!displayImage && (<img alt={`proof-pic-${proof?.id}`} src={displayImage} />)}
          </Box>

          <Field
            label={IntlFormatter.getMessage("taskObjective.notes", "Notes")}
            fieldKey="message"
            type="text"
            formStates={formStates}
            onValueChange={onValueChange}
            rows={2}
            multiline
          />
        </form>

      </DialogContent>
      {inProgress && (
        <DialogActions>
          <LoadingButton
            fullWidth={isXs}
            loading={loading}
            onClick={onSubmit}
            color="primary"
            disabled={noHttp}
          >
            {IntlFormatter.getMessage("taskObjective.update", "Update")}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TaskObjectiveForm;
