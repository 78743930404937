import { createSvgIcon } from '@mui/material/utils';

const LightningBolt = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0856 1.79366C11.3842 1.90024 11.5835 2.18301 11.5835 2.50001V7.58334H16.6668C16.9566 7.58334 17.2205 7.75029 17.3446 8.01217C17.4687 8.27405 17.4308 8.58398 17.2473 8.80827L9.7473 17.9749C9.54657 18.2203 9.21325 18.3129 8.9147 18.2064C8.61614 18.0998 8.41684 17.817 8.41684 17.5V12.4167H3.3335C3.04371 12.4167 2.77985 12.2497 2.65575 11.9878C2.53165 11.726 2.56953 11.416 2.75304 11.1917L10.253 2.02508C10.4538 1.77973 10.7871 1.68709 11.0856 1.79366ZM4.91619 10.9167H9.16684C9.58105 10.9167 9.91684 11.2525 9.91684 11.6667V15.399L15.0842 9.08334H10.8335C10.4193 9.08334 10.0835 8.74756 10.0835 8.33334V4.60107L4.91619 10.9167Z" fill="currentColor" />
    </svg>

    ,
    'LightningBolt'
);

export default LightningBolt;