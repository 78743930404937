import { Copyright } from "@mui/icons-material";
import { AppBar, AppBarProps, Box, Link, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Typography } from "src/main/components";
import { createStyles } from "src/main/utils";
import Branding from "../Branding";
import { SUPPORT_LINK } from "src/constant";

interface BottomBarProps extends AppBarProps {}

const BottomBarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: "transparent",
  ...(theme.palette.mode === "light"
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const BottomBar: React.FC<BottomBarProps> = (props) => {
  return (
    <BottomBarRoot position="relative" variant="elevation" sx={styles.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={styles.brand}
      >
        <Branding />
        <Stack
          direction="row"
          alignItems="center"
          sx={{ color: "neutral.400" }}
        >
          <Link color="inherit" href={SUPPORT_LINK}>
            <Typography
              formatId="bottomBar.contact_support"
              variant="caption"
              color="textPrimary"
              sx={{ px: 2 }}
            >
              Contact Support
            </Typography>
          </Link>
          <Box component="span" sx={styles.divider} />
          <Link color="inherit" href="#">
            <Typography
              formatId="bottomBar.terms_&_conditions"
              variant="caption"
              color="textPrimary"
              sx={{ px: 2 }}
            >
              "Terms & Conditions"
            </Typography>
          </Link>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          sx={{ color: "neutral.400", py: 2 }}
        >
          <Copyright sx={{ fontSize: 15 }} />
          <Typography
            formatId="bottomBar.all_right_reserverd"
            variant="caption"
            lineHeight={1.37}
          >
            2023 All Right Reserverd.
          </Typography>
        </Stack>
      </Box>
    </BottomBarRoot>
  );
};

const styles = createStyles({
  root: {
    display: {
      xs: "block",
      md: "none",
    },
    bottom: 0,
    top: "auto",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  brand: {
    flexDirection: "column",
    height: "152px",
    backgroundColor: "rgba(255,255,255,.05)",
    color: "primary.main",
  },
  divider: {
    width: "2px",
    backgroundColor: "rgba(209,213,219,0.3)",
    py: "8px",
  },
});

export default BottomBar;
