import { Box, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import React from "react";
import { TaskAttemptModel } from "src/main/types";
import { packageFormatMessage } from "src/main/utils";
import { useIntl } from "react-intl";
import { BaseContent } from "../BaseContent";

interface ApproveContentProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
}
const ApproveContent: React.FC<ApproveContentProps> = (props) => {
  const { attempt } = props;
  const intl = useIntl();

  return (
    <>
      {attempt?.payAmount && (
        <Box display="flex" justifyContent="center" mb={1}>
          <Typography variant="body2" fontWeight={700}>
            + {BigNumber(attempt.payAmount).toFixed(0)} {attempt.payCurrency}
            &nbsp;
          </Typography>
          <Typography variant="body2">
            {" "}
            {intl.formatMessage(
              packageFormatMessage(
                "approveContentComp.has_been_sent_to_wallet",
                "has been sent to your wallet"
              )
            )}
          </Typography>
        </Box>
      )}
      <BaseContent status="success" withMoreTasks={true}>
        <Typography variant="body2">
          {intl.formatMessage(
            packageFormatMessage(
              "approveContentComp.your_efforts_are_truly_appreciated",
              "Your efforts are truly appreciated and your results are outstanding!"
            )
          )}
        </Typography>
      </BaseContent>
    </>
  );
};

export default ApproveContent;
