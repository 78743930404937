import React from "react";
import { ManagementLayout, Page } from "src/main/components";


interface AccountPageProps extends React.PropsWithChildren {

}

const AccountPage: React.FC<AccountPageProps> = () => {
  return (
    <Page>
      <ManagementLayout.WorkInProgress />
    </Page>
  );
};

export default AccountPage;
