import EditIcon from '@mui/icons-material/Edit';
import {
  Box, Button, Checkbox, Container, Typography
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback } from "react";
import { toast } from 'react-hot-toast';
import { accountApi } from 'src/main/api';
import { ListPrivilegeResponse } from 'src/main/api/account/Privilege';
import { DetailCard, EmptyState, Page, Table } from "src/main/components";
import { AccountEntityModel } from "src/main/types";
import { normalizeText } from 'src/main/utils';
import * as Yup from "yup";

interface AccountPrivilegeProps extends React.PropsWithChildren {
  toggleEdit: () => void;
  activeAcc?: AccountEntityModel;
  privilegesResponse?: ListPrivilegeResponse;
  personId: string;
}

const AccountPrivilege: React.FC<AccountPrivilegeProps> = (props) => {
  const { toggleEdit, activeAcc, privilegesResponse, personId } = props;

  const [updateUserPrivilegeMutation, { isLoading: isSubmitLoading }] = accountApi.useUpdateUserPrivilegeMutation();

  const onSubmit = useCallback(async (values: FormikValues) => {
    const result = await updateUserPrivilegeMutation({ personId, privileges: values.privileges })
    if ("data" in result) {
      toast.success("User privileges updated");
      toggleEdit()
    } else {
      toast.error((result.error as any).data?.error?.message);
      throw new Error((result.error as any).data?.error?.message)
    }
    // eslint-disable-next-line
  }, [])

  if (!activeAcc && !privilegesResponse) return <EmptyState />;


  const initial_privilege_value: string[] = []
  activeAcc?.privileges?.forEach((privilege) => {
    initial_privilege_value.push(privilege);
  });

  const initial_values = {
    privileges: initial_privilege_value
  }

  return (
    <Page>
      <Formik
        initialValues={initial_values}
        validationSchema={Yup.object().shape({
          privileges: Yup.array(),
        })}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          validateForm,
        }): JSX.Element => (
          <Form onSubmit={(e) => {
            validateForm().then((err: any) => {
              Object.keys(err).forEach((err) => {
                toast.error(`error validating ${err}`);
              });
            });
            handleSubmit(e);
          }}>
            <Page.TopSection title="Update Privileges">
              <Box display="flex">
                <Box>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={toggleEdit}
                    sx={{ mr: 2 }}
                    disabled={isSubmitLoading}
                  >
                    <EditIcon />&nbsp;<Typography>Back</Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={isSubmitLoading}
                  >
                    <EditIcon />&nbsp;<Typography>Update</Typography>
                  </Button>
                </Box>
              </Box>
            </Page.TopSection>
            <Page.Content>
              <Container >
                <DetailCard>
                  <DetailCard.Header header="Privileges" />
                  <DetailCard.Divider />

                  <Table>
                    <Table.Body>
                      {privilegesResponse?.entries.map((privilege, i) => (
                        <Table.Row>
                          <Table.Cell>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={values.privileges?.includes("account:" + privilege.name)}
                                  onChange={(ev) => {
                                    const currPrivilegeKey = "account:" + privilege.name;
                                    const newPrivileges = [...values.privileges];
                                    if (newPrivileges.includes(currPrivilegeKey)) {
                                      newPrivileges.splice(newPrivileges.indexOf(currPrivilegeKey), 1);
                                    } else {
                                      newPrivileges.push(currPrivilegeKey);
                                    }
                                    setFieldValue("privileges", newPrivileges);
                                  }}
                                />
                              )}
                              label={(
                                <Typography>{normalizeText(privilege.name.replaceAll("-", " "))}</Typography>
                              )}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Typography textAlign="left">{normalizeText(privilege.description)}</Typography>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>

                  <Box>
                  </Box>
                </DetailCard>
              </Container>
            </Page.Content>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default AccountPrivilege;