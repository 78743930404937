import React from "react";
import { Box, BoxProps } from "@mui/material";
import { createStyles, joinSx } from "src/main/utils";
import { BrandClient } from "src/assets";

interface BrandingProps extends BoxProps {}

const Branding: React.FC<BrandingProps> = (props) => {
  const { sx } = props;

  return (
    <Box sx={joinSx(styles.root, sx)}>
      <Box component="img" src={BrandClient} sx={styles.image} />
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
  },
  image: {
    height: "100%",
    width: "auto",
  },
});

export default Branding;
