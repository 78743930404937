import { Box, BoxProps, Button, styled } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { Page } from "src/main/components";
import { handleFormikApiResponse, Paths } from "src/main/utils";
import * as Yup from "yup";
import { PersonalInformation, WorkerInformation } from "./components";

interface CreateWorkerProps extends React.PropsWithChildren {}

const CreateContentBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 8,
  gap: 16,
  display: "flex",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const initial_values = {
  firstname: "",
  middlename: "",
  lastname: "",
  salutation: "",
  gender: "",
  primaryEmail: "",
  primaryPhone: "",
  discordHandle: "",
  instagramHandle: "",
  tiktokHandle: "",
  twitterHandle: "",
  telegramHandle: "",
  facebookHandle: "",
  facebookAltHandle: "",
  remark: "",
  gCashAccount: "",
  // accessKey: "",
  // secret: "",
};

const CreateWorkerComponent: React.FC<CreateWorkerProps> = (props) => {
  const navigate = useNavigate();

  const [createWorkerMutation, { isLoading: isSubmitLoading }] =
    accountApi.useCreateWorkerMutation();

  const onSubmit = useCallback(
    async (values: FormikValues) => {
      try {
        const val = {
          firstname: values.firstname,
          middlename: values.middlename,
          lastname: values.lastname,
          salutation: values.salutation,
          gender: values.gender,
          primaryEmail: values.primaryEmail,
          primaryPhone: values.primaryPhone,
          discordHandle: values.discordHandle,
          instagramHandle: values.instagramHandle,
          tiktokHandle: values.tiktokHandle,
          twitterHandle: values.twitterHandle,
          telegramHandle: values.telegramHandle,
          facebookHandle: values.facebookHandle,
          facebookAltHandle: values.facebookAltHandle,
          status: "active",
          remark: values.remark,
          gCashAccount: values.gCashAccount,
          // accessKey: values.accessKey,
        };

        const result = await createWorkerMutation({ data: val });

        const {
          error: workerError,
          data,
          isToasted,
        } = handleFormikApiResponse(result);

        if (workerError && isToasted) return;

        if (workerError?.data?.error) {
          throw new Error(workerError.data?.error?.message);
        }

        if (!data) {
          throw new Error("No data returned");
        }

        navigate(Paths.Management.WorkerDetail.replace(":workerId", data.id));
        toast.success("Tasker created");
      } catch (error: unknown) {
        toast.error((error as any).message ?? "Error creating Tasker!");
      }
    },

    [createWorkerMutation, navigate]
  );

  return (
    <Page>
      <Formik
        initialValues={initial_values}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required("First name is required"),
          primaryEmail: Yup.string()
            .email("Email is invalid")
            .required("Email address is required"),
          lastname: Yup.string(),
          gender: Yup.string(),

          salutation: Yup.string(),
          primaryPhone: Yup.string(),
          discordHandle: Yup.string(),
          instagramHandle: Yup.string(),
          tiktokHandle: Yup.string(),
          twitterHandle: Yup.string(),
          telegramHandle: Yup.string(),
          facebookHandle: Yup.string(),
          remark: Yup.string(),
          gCashAccount: Yup.string()
            .matches(
              /^0[0-9]+/,
              "GCash account must be a number leading with 0"
            )
            .length(11, "GCash account must be 11 digits")
            .required("GCash account is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }): JSX.Element => (
          <Form noValidate onSubmit={handleSubmit}>
            <Page.TopSection title="New Tasker">
              <Box>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitLoading}
                >
                  Create
                </Button>
              </Box>
            </Page.TopSection>
            <CreateContentBox>
              <Box flex={1} pb={2}>
                <PersonalInformation
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <Box flex={1} pb={2}>
                <WorkerInformation
                  createMode={true}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
                {/* 
                <CredentialInformation
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                /> */}
              </Box>
            </CreateContentBox>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateWorkerComponent;
