import { CloseOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { toast } from "react-hot-toast";
import { authApi } from "src/main/api";
import { commonStyles, handleApiResponse } from "src/main/utils";

interface ProfileFormProps {
  open: boolean;
  onClose: () => void;
  defaultValue: string;
  label: string;
  placeholder?: string;
  updateKey: string;
}

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const { open, onClose, defaultValue, label, placeholder, updateKey } = props;
  const [value, setValue] = React.useState(defaultValue);

  const [submitPersonInfo] = authApi.useWorkerPersonInfoMutation();
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (open) {
      setValue(defaultValue);
      setError("");
    }
  }, [defaultValue, open]);

  const onSubmit = useCallback(async () => {
    const formData = new FormData();
    formData.append(updateKey, value);

    const result = await submitPersonInfo({ personInfoBody: formData });
    // Handle the successful response or perform any necessary actions
    const { error } = handleApiResponse(result);

    if (error?.data?.error) {
      toast.error(error.data?.error?.message);
      //setError(error.data?.error?.message);
      return;
    }

    onClose();
    setValue("");
    toast.success("Profile updated!");
  }, [updateKey, value, submitPersonInfo, onClose]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setValue("");
    onClose();
  };

  const handleReset = () => {
    setValue(defaultValue);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <IconButton sx={commonStyles.dialogCloseButton} onClick={handleClose}>
        <CloseOutlined />
      </IconButton>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          label={label}
          value={value}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {error && <>{error}</>}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleReset}>Reset</Button>
        <Button variant="contained" onClick={onSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileForm;
