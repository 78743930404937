import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DetailCard, InputField, Page } from "src/main/components";
import { Paths } from 'src/main/utils';
import * as Yup from "yup";

interface UserCreatePageProps extends React.PropsWithChildren {
}

const initial_values = {
  firstname: "",
  middlename: "",
  lastname: "",
  salutation: "",
  gender: "",
  primaryEmail: "",
  primaryPhone: "",
  discordHandle: "",
  instagramHandle: "",
  tiktokHandle: "",
  twitterHandle: "",
  telegramHandle: "",
  facebookHandle: "",
  facebookAltHandle: "",
  // secret: "",
}

const GENDER_OPTIONS = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "others", label: "Others" },
]

const UserCreatePage: React.FC<UserCreatePageProps> = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createUserMutation, { isLoading: isSubmitLoading }] = accountApi.useCreateUserMutation();

  const onSubmit = useCallback(async (values: FormikValues) => {
    const val = {
      firstname: values.firstname,
      middlename: values.middlename,
      lastname: values.lastname,
      salutation: values.salutation,
      gender: values.gender,
      primaryEmail: values.primaryEmail,
      primaryPhone: values.primaryPhone,
      discordHandle: values.discordHandle,
      instagramHandle: values.instagramHandle,
      tiktokHandle: values.tiktokHandle,
      twitterHandle: values.twitterHandle,
      telegramHandle: values.telegramHandle,
      facebookHandle: values.facebookHandle,
      facebookAltHandle: values.facebookAltHandle,
    }

    const result = await createUserMutation(val);

    if ("data" in result) {
      toast.success("User created");
      navigate(Paths.Overview.UserDetail.replace(":userId", result.data.model.id));
    } else {
      throw new Error((result.error as any).data?.error?.message)
    }
    // eslint-disable-next-line
  }, [dispatch])

  return (
    <Page>
      <Formik
        initialValues={initial_values}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required("First name is required"),
          lastname: Yup.string(),
          gender: Yup.string(),
          primaryEmail: Yup.string()
            .email("Email is invalid")
            .required("Email address is required"),

          salutation: Yup.string(),
          primaryPhone: Yup.string(),
          discordHandle: Yup.string(),
          instagramHandle: Yup.string(),
          tiktokHandle: Yup.string(),
          twitterHandle: Yup.string(),
          telegramHandle: Yup.string(),
          facebookHandle: Yup.string(),
          facebookAltHandle: Yup.string(),
          accessKey: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          validateForm,
          setErrors
        }): JSX.Element => (
          <Form onSubmit={(e) => {
            validateForm().then((err: any) => {
              Object.keys(err).forEach((err) => {
                toast.error(`error validating ${err}`);
              });
            });
            handleSubmit(e);
          }}>
            <Page.TopSection title="Create User">

              <Button
                disabled={isSubmitLoading}
                size="large"
                type="submit"
                variant="contained"
                onClick={() => { }}
              >
                <EditIcon />&nbsp;<Typography>Create</Typography>
              </Button>
            </Page.TopSection>
            <Container>
              <Page.Content>
                <Box flex={1}>
                  <DetailCard>
                    <DetailCard.Header header="Personal Info" />
                    {/* <DetailCard.Divider /> */}
                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="firstname"
                          label="Firstname"
                          required
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.firstname && !!touched.firstname}
                          helperText={!!touched.firstname && errors.firstname}
                        />
                      </Grid>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="middlename"
                          label="Middlename"
                          value={values.middlename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.middlename && !!touched.middlename}
                          helperText={!!touched.middlename && errors.middlename}
                        />
                      </Grid>

                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="lastname"
                          label="Lastname"
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.lastname && !!touched.lastname}
                          helperText={!!touched.lastname && errors.lastname}
                        />
                      </Grid>

                      <Grid container item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Gender</InputLabel>
                          <Select
                            input={<OutlinedInput notched label="Gender" />}
                            value={values.gender}
                            sx={{
                              paddingY: "12px",
                              paddingX: "10px",
                              '& .MuiSelect-select': {
                                p: 0,
                              }
                            }}
                            onChange={(ev) => {
                              setErrors(errors);
                              setFieldValue("gender", ev.target.value)
                            }}
                          >
                            {GENDER_OPTIONS.map((gender) => (
                              <MenuItem value={gender.value}>{gender.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <DetailCard.Header header="Contact Info" />

                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="primaryEmail"
                          label="Email"
                          required
                          value={values.primaryEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.primaryEmail && !!touched.primaryEmail}
                          helperText={!!touched.primaryEmail && errors.primaryEmail}
                        />
                      </Grid>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="primaryPhone"
                          label="Phone"
                          value={values.primaryPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.primaryPhone && !!touched.primaryPhone}
                          helperText={!!touched.primaryPhone && errors.primaryPhone}
                        />
                      </Grid>
                    </Grid>

                    <DetailCard.Header header="Social Media" />

                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="facebookHandle"
                          label="Facebook Handle"
                          value={values.facebookHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.facebookHandle && !!touched.facebookHandle}
                          helperText={!!touched.facebookHandle && errors.facebookHandle}
                        />
                      </Grid>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="facebookAltHandle"
                          label="Facebook Handle"
                          value={values.facebookAltHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.facebookAltHandle && !!touched.facebookAltHandle}
                          helperText={!!touched.facebookAltHandle && errors.facebookAltHandle}
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="discordHandle"
                          label="Discord Handle"
                          value={values.discordHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.discordHandle && !!touched.discordHandle}
                          helperText={!!touched.discordHandle && errors.discordHandle}
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="instagramHandle"
                          label="Instagram Handle"
                          value={values.instagramHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.instagramHandle && !!touched.instagramHandle}
                          helperText={!!touched.instagramHandle && errors.instagramHandle}
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="tiktokHandle"
                          label="TikTok Handle"
                          value={values.tiktokHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.tiktokHandle && !!touched.tiktokHandle}
                          helperText={!!touched.tiktokHandle && errors.tiktokHandle}
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="twitterHandle"
                          label="Twitter Handle"
                          value={values.twitterHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.twitterHandle && !!touched.twitterHandle}
                          helperText={!!touched.twitterHandle && errors.twitterHandle}
                        />
                      </Grid>

                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="telegramHandle"
                          label="Telegram Handle"
                          value={values.telegramHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.telegramHandle && !!touched.telegramHandle}
                          helperText={!!touched.telegramHandle && errors.telegramHandle}
                        />
                      </Grid>
                    </Grid>


                    {/* <DetailCard.Header header="Credentials" />

                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="accessKey"
                          label="Username"
                          value={values.accessKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.accessKey && !!touched.accessKey}
                          helperText={!!touched.accessKey && errors.accessKey}
                        />
                      </Grid>
                    </Grid> */}
                  </DetailCard>
                </Box>
              </Page.Content>
            </Container>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default UserCreatePage;
