import { createSvgIcon } from '@mui/material/utils';

const AppleIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
    <path d="M14.2084 16.9C13.3917 17.6917 12.5001 17.5667 11.6417 17.1917C10.7334 16.8083 9.90005 16.7917 8.94172 17.1917C7.74172 17.7083 7.10839 17.5583 6.39172 16.9C2.32506 12.7083 2.92506 6.325 7.54172 6.09167C8.66672 6.15 9.45006 6.70833 10.1084 6.75833C11.0917 6.55833 12.0334 5.98333 13.0834 6.05833C14.3417 6.15833 15.2917 6.65833 15.9167 7.55833C13.3167 9.11667 13.9334 12.5417 16.3167 13.5C15.8417 14.75 15.2251 15.9917 14.2001 16.9083L14.2084 16.9ZM10.0251 6.04167C9.90005 4.18333 11.4084 2.65 13.1417 2.5C13.3834 4.65 11.1917 6.25 10.0251 6.04167Z" fill="currentColor"/>
  </svg>
  ,
  'AppleIcon'
);

export default AppleIcon;
