import React from "react";
import { Box, SxProps } from "@mui/material";
import Breadcrumb from "../Breadcrumb";
import { Title } from "src/main/components";
import { CrumbsProps } from "../Breadcrumb/Breadcrumb";


interface ContentProps extends React.PropsWithChildren {
  sx?: SxProps;
}

interface TopSectionProps extends React.PropsWithChildren {
  title?: string;
  crumbs?: CrumbsProps[];
}


const TopSection: React.FC<TopSectionProps> = (props) => {
  const { title, crumbs, children } = props;
  return (
    <Box sx={{
      display: "flex",
      p: 4,
      pb: 1,
      justifyContent: "space-between",
      flexDirection: {
        xs: "column",
        md: "row"
      }
    }}>
      <Box>
        {title && <Title label={title} />}
        {crumbs && <Breadcrumb crumbs={crumbs} />}
      </Box>
      {children}
    </Box>
  )
}

const Content = (props: ContentProps) => {
  const { children, sx } = props;
  return (
    <Box sx={{ ...sx }}>
      {children}
    </Box>
  );
};

Content.TopSection = TopSection;

export default Content;
