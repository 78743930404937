import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ThemeMode } from './types';
import { Defaults } from 'src/main/constants';

interface PreferenceState {
  theme: ThemeMode;
  affiliateLink: string | null;
  username: string | null;
  language: string | null;
}

const getPersisTheme = () => {
  try {
    const savedTheme = localStorage.getItem(Defaults.Theme);
    if (savedTheme === "dark" || savedTheme === "light") return savedTheme;
  } catch (error) {
  }
  return "light";
}

const getPersisLanguage = () => {
  try {
    const savedLanguage = localStorage.getItem(Defaults.Language);
    return savedLanguage
  } catch (error) {
  }
  return "en";
}
// const getPersisAffiliate = () => {
//   try {
//     const savedAffiliateLink = localStorage.getItem("affiliateLink");
//     if (savedAffiliateLink) return savedAffiliateLink;
//   } catch (error) {
//   }
//   return null;
// }

const rememberMe = (username: string) => {
  if (username)
    localStorage.setItem(Defaults.UserName, username);
  else
    localStorage.removeItem(Defaults.UserName);
}

const getUsername = () => {
  try {
    const savedUsername = localStorage.getItem(Defaults.UserName);
    if (savedUsername) return savedUsername
  } catch (error) {
  }
  return null;
}

const initialState: PreferenceState = {
  theme: getPersisTheme(),
  affiliateLink: "",
  username: getUsername(),
  language: getPersisLanguage(),
};

const preferenceSlice = createSlice({
  name: '@preference',
  initialState,
  reducers: {
    setThemeMode(state, action: PayloadAction<ThemeMode>) {
      state.theme = action.payload;
      localStorage.setItem(Defaults.Theme, state.theme);
    },
    setAffiliateLink(state, action: PayloadAction<string>) {
      state.affiliateLink = action.payload;
    },
    setUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
      rememberMe(action.payload)
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
      localStorage.setItem(Defaults.Language, state.language);
    },
  },
})

export default preferenceSlice;
