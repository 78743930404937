
import { BuilderProp, require200Status } from "src/main/api/account";
import { CredentialModel, InviteModel, Meta, PersonModel, QueryResult } from "src/main/types";
import { SimpleMap } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";
import { QueryResultEntries } from "../../auth/endpoints";

export interface ListUserResponse {
  entries: PersonModel[];
  meta: Meta;
}

export interface MetaRequest {
  meta: Meta;
}


export interface CreateUserRequest {
  [index: string]: string;
}

export interface CreateUserResponse {
  model: PersonModel;
}

export interface GetUserRequest {
  personId: string;
}

export interface GetUserResponse {
  model: PersonModel;
}

export interface UpdateUserRequest {
  personId: string;
  data: SimpleMap<string>;
}

export interface UpdateUserResponse {
  model: PersonModel;
}

export interface UpdateUserPrivilegeRequest {
  privileges: string[];
  personId: string;
}

export interface UpdateUserPrivilegeResponse {
  model: PersonModel
}

export interface UpdateCredentialRequest {
  userId: string;
  data: {
    accessKey: string;
    secret: string;
  }
}

export interface UpdateCredentialResponse {
  model: CredentialModel;
}


export const listUser = (builder: BuilderProp) => {
  return builder.query<ListUserResponse, MetaRequest>({
    providesTags: ["user"],
    query: ({ meta }) => ({
      url: `/account/person/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListUserResponse>) => {
      return response.result
    }
  })
}

export const createUser = (builder: BuilderProp) => {
  return builder.mutation<CreateUserResponse, CreateUserRequest>({
    invalidatesTags: ["user"],
    query: (data) => ({
      url: `/account/person/create`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CreateUserResponse>) => {
      return response.result;
    },
  })

}

export const updateUser = (builder: BuilderProp) => {
  return builder.mutation<UpdateUserResponse, UpdateUserRequest>({
    invalidatesTags: ["user"],
    query: ({ personId, data }) => ({
      url: `/account/person/${personId}/update`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateUserResponse>) => {
      return response.result;
    },
  })

}

export const getUser = (builder: BuilderProp) => {
  return builder.query<GetUserResponse, GetUserRequest>({
    providesTags: ["user"],
    query: ({ personId }) => ({
      url: `/account/person/${personId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetUserResponse>) => {
      return response.result
    }
  })
}

export const updateUserPrivilege = (builder: BuilderProp) => {
  return builder.mutation<UpdateUserPrivilegeResponse, UpdateUserPrivilegeRequest>({
    invalidatesTags: ["user"],
    query: ({ personId, privileges }) => ({
      url: `/account/person/${personId}/privilege`,
      method: "POST",
      body: { privileges },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateUserPrivilegeResponse>) => {
      return response.result
    }
  })
}

export interface CreateInviteRequest {
  email: string;
  name: string;
}
export const createInvite = (builder: BuilderProp) => {
  return builder.mutation<any, CreateInviteRequest>({
    invalidatesTags: ["user"],
    query: ({ name, email }) => ({
      url: `/account/invite/create`,
      method: "POST",
      body: { name, email },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateUserPrivilegeResponse>) => {
      console.log("/account/invite/create", response);
      return response.result
    }
  })
}

export const listInvites = (builder: BuilderProp) => {
  return builder.query<QueryResultEntries<InviteModel[]>, MetaRequest>({
    providesTags: ["invites"],
    query: ({ meta }) => ({
      url: `/account/invite/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultEntries<InviteModel[]>>) => {
      return response.result
    }
  })
}

export interface CancelInviteRequest {
  inviteId: string;
}
export const cancelInvite = (builder: BuilderProp) => {
  return builder.mutation<any, CancelInviteRequest>({
    invalidatesTags: ["invites"],
    query: ({ inviteId }) => ({
      url: `/account/invite/:inviteId/cancel`.replace(":inviteId", inviteId),
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateUserPrivilegeResponse>) => {
      console.log("/account/invite/create", response);
      return response.result
    }
  })
}

export const updateCredential = (builder: BuilderProp) => {
  return builder.mutation<UpdateCredentialResponse, UpdateCredentialRequest>({
    invalidatesTags: ["user"],
    query: ({ userId, data }) => ({
      url: `/account/person/${userId}/credential`,
      method: "POST",
      body: data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<UpdateCredentialResponse>) => {
      return response.result
    }
  })
}
