import { createSvgIcon } from '@mui/material/utils';

const InfoIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.25C8.20979 3.25 6.4929 3.96116 5.22703 5.22703C3.96116 6.4929 3.25 8.20979 3.25 10C3.25 10.8864 3.42459 11.7642 3.76381 12.5831C4.10303 13.4021 4.60023 14.1462 5.22703 14.773C5.85382 15.3998 6.59794 15.897 7.41689 16.2362C8.23583 16.5754 9.11358 16.75 10 16.75C10.8864 16.75 11.7642 16.5754 12.5831 16.2362C13.4021 15.897 14.1462 15.3998 14.773 14.773C15.3998 14.1462 15.897 13.4021 16.2362 12.5831C16.5754 11.7642 16.75 10.8864 16.75 10C16.75 8.20979 16.0388 6.4929 14.773 5.22703C13.5071 3.96116 11.7902 3.25 10 3.25ZM4.16637 4.16637C5.71354 2.61919 7.81196 1.75 10 1.75C12.188 1.75 14.2865 2.61919 15.8336 4.16637C17.3808 5.71354 18.25 7.81196 18.25 10C18.25 11.0834 18.0366 12.1562 17.622 13.1571C17.2074 14.1581 16.5997 15.0675 15.8336 15.8336C15.0675 16.5997 14.1581 17.2074 13.1571 17.622C12.1562 18.0366 11.0834 18.25 10 18.25C8.91659 18.25 7.8438 18.0366 6.84286 17.622C5.84193 17.2074 4.93245 16.5997 4.16637 15.8336C3.40029 15.0675 2.7926 14.1581 2.37799 13.1571C1.96339 12.1562 1.75 11.0834 1.75 10C1.75 7.81196 2.61919 5.71354 4.16637 4.16637ZM9.25 6.32115C9.25 5.90694 9.58579 5.57115 10 5.57115H10.0083C10.4225 5.57115 10.7583 5.90694 10.7583 6.32115C10.7583 6.73536 10.4225 7.07115 10.0083 7.07115H10C9.58579 7.07115 9.25 6.73536 9.25 6.32115ZM8.41667 8.79125C8.41667 8.37703 8.75245 8.04125 9.16667 8.04125H10C10.4142 8.04125 10.75 8.37703 10.75 8.79125V12.5833H10.8333C11.2475 12.5833 11.5833 12.9191 11.5833 13.3333C11.5833 13.7475 11.2475 14.0833 10.8333 14.0833H10C9.58579 14.0833 9.25 13.7475 9.25 13.3333V9.54125H9.16667C8.75245 9.54125 8.41667 9.20546 8.41667 8.79125Z" fill="currentColor" />
  </svg>
  ,
  'InfoIcon'
);

export default InfoIcon;
