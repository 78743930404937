import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import React from "react";
import { TeamModel } from "src/main/types";
import { createStyles, joinSx } from "src/main/utils";
import MonoLabel from "../MonoLabel";

interface Props {
  teams?: TeamModel[];
  sx?: SxProps;
}

const TimePickerComp: React.FC<Props> = (props: Props) => {
  const { teams, sx } = props;

  return (
    <Tooltip title={<Box>
      {teams?.map((team) => (
        <Typography>{team.name}</Typography>
      ))}
    </Box>} sx={joinSx(styles.root, sx)}>
      <Box display="flex">
        {!!teams?.[0] && (
          <MonoLabel>{teams[0].name}</MonoLabel>
        )}
        {!!teams?.[1] && (
          <MonoLabel>, +{teams.length - 1}</MonoLabel>
        )}
      </Box>
    </Tooltip>
  );
};

const styles = createStyles({
  root: {
  },
});

export default TimePickerComp;
