import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
} from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CashTransfer } from "src/assets";
import { workerApi } from "src/main/api";
import { PortalLayout, Typography } from "src/main/components";
import { Defaults } from "src/main/constants";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { PersonModel, WorkerModel } from "src/main/types";
import {
  Paths,
  commonStyles,
  createStyles,
  IntlFormatter,
} from "src/main/utils";
import { WalletBalance } from "./components";
import WithdrawCard from "./components/WithdrawCard";

interface PayoutsPageProps extends React.PropsWithChildren {}

const default_currency = Defaults.WalletCurrency;
const PayoutsPage: React.FC<PayoutsPageProps> = (props) => {
  const navigate = useNavigate();
  const personInfo = useSelector<RootState, PersonModel | undefined>(
    (state) => state.auth.profile?.person
  );
  const worker = useSelector<RootState, WorkerModel | undefined | null>(
    (state) => state.auth.worker
  );

  const { currency } = useSelector<RootState, AuthState>((state) => state.auth);

  const earningsQuery = workerApi.useWorkerEarningsQuery(undefined);
  const earnings = useMemo(
    () => earningsQuery.data?.earnings,
    [earningsQuery.data]
  );

  const withdrawsResult = workerApi.useWorkerWithdrawListQuery({
    meta: { currency: currency?.default ?? default_currency },
  });
  const { withdraws } = useMemo(() => {
    const withdraws = withdrawsResult.data?.entries ?? [];

    return {
      withdraws,
    };
  }, [withdrawsResult]);

  return (
    <Container sx={styles.container}>
      <Typography
        formatId="payoutPage.payout_summary"
        sx={styles.title}
        variant="h5"
        mb={2}
      >
        Payout Summary
      </Typography>
      <Card sx={{ pb: 1 }}>
        <CardContent sx={commonStyles.cardContent}>
          <Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {personInfo?.firstname}
              </Typography>
              <Box display="flex" flexDirection="row" mt={1}>
                <Typography
                  formatId="payoutPage.my_total_withdraw_earnings"
                  variant="caption"
                  color="textSecondary"
                >
                  My total withdraw earnings:
                </Typography>
                <Typography
                  variant="caption"
                  paddingLeft={0.5}
                  color="textPrimary"
                >
                  {earnings?.totalEarnings.toFormat(2) ?? "0.00"}{" "}
                  {currency?.default}
                </Typography>
              </Box>
            </Box>
            <WalletBalance sx={{ backgroundColor: "secondary", mt: 1 }} />

            <Box mt={2} display="flex" flexDirection="row">
              <Typography
                formatId="payoutPage.gCashAccount"
                variant="caption"
                color="textSecondary"
              />
              <Typography
                variant="caption"
                paddingLeft={0.5}
                color="textPrimary"
              >
                {worker?.gCashAccount}
              </Typography>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Button
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => navigate(Paths.Portal.Withdraw)}
            >
              {IntlFormatter.getMessage("payoutPage.withdraw", "Withdraw")}
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Box display="flex" mt={2} sx={{ justifyContent: "space-between" }}>
        <PortalLayout.SectionTitle>
          {IntlFormatter.getMessage(
            "payoutPage.withdrawal_history",
            "Withdrawal History"
          )}
        </PortalLayout.SectionTitle>
        {withdraws.length > 0 && (
          <Button
            onClick={() => {
              navigate(Paths.Portal.WithdrawHistoryList);
            }}
            endIcon={
              <Box component="span" display="flex" alignItems="center">
                <ChevronRightIcon />
              </Box>
            }
          >
            {IntlFormatter.getMessage("payoutPage.view_all", "View All")}
          </Button>
        )}
      </Box>

      {withdraws.map((withdraw) => (
        <WithdrawCard key={withdraw.id} withdraw={withdraw} />
      ))}

      {!withdraws.length && (
        <PortalLayout.EmptyState
          cta={IntlFormatter.getMessage(
            "payoutPage.start_a_task",
            "Start a Task"
          )}
          graphic={
            <CashTransfer
              style={{ height: "150px", marginBottom: "1rem", opacity: "0.7" }}
            />
          }
          label={IntlFormatter.getMessage(
            "payoutPage.no_payputs_found",
            "No Payouts Found"
          )}
          onClick={() => navigate(Paths.Portal.Tasks)}
        />
      )}
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 2,
  },
  title: {
    fontSize: {
      xs: "1.5rem",
      md: "1.75rem",
    },
  },
});

export default PayoutsPage;
