import { Box } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface ContainerProps extends React.PropsWithChildren {

}

const Container: React.FC<ContainerProps> = (props) => {
  const { children } = props;
  return (
    <Box sx={styles.root}>
      {children}
    </Box>
  );
};

const styles = createStyles({
  root: {
    height: "100vh",
    backgroundColor: "background.default",
    display: "flex",
    flexDirection: "column",
  },
});

export default Container;
