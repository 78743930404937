import {
  Box,
  BoxProps,
  Chip,
  TablePagination,
  Typography,
  SxProps,
  Pagination,
  PaginationItem,
  useTheme,
  IconButton,
} from "@mui/material";
import { Dayjs } from "dayjs";
import moment from "moment";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InputField, LoadingContainer, Scrollbar } from "src/main/components";
import { Meta, Option } from "src/main/types";
import { joinSx, normalizeText, SimpleMap } from "src/main/utils";
import {
  DateFilter,
  DateSelectDialog,
  ListingEmptyState,
  Searchbar,
  Sortbar,
} from "./components";
import { DateSelectDialogDates } from "./components/DateSelectDialog/DateSelectDialog";
import {
  PaginationFirst,
  PaginationLast,
  PaginationLeft,
  PaginationRight,
} from "src/assets";
import { ChevronDown } from "src/assets";
import { Event as CalendarIcon } from "@mui/icons-material";

const ListingComponentContext = createContext<{
  persistMeta: Meta;
  updateList: (meta: Meta) => void;
  loading?: boolean;
  statusValue?: SimpleMap<string>;
  setStatusValue?: (val: SimpleMap<string>) => void;
  defaultValue?: SimpleMap<string>;
  setDefaultValue?: (val: SimpleMap<string>) => void;
}>({
  persistMeta: { count: 0, limit: 10, offset: 0 },
  updateList: () => {},
  loading: false,
  statusValue: {},
  setStatusValue: () => {},
  defaultValue: {},
  setDefaultValue: () => {},
});

interface StatusbarCompProps extends BoxProps {
  label?: string;
  options: Option[];
  onStatusUpdate?: (status: string, datesParam?: string) => void;
  defaultSelected?: string;
  onTimeChange?: (timeParam: string) => void;
  metaKey?: string;
}

const StatusbarComp = (props: StatusbarCompProps) => {
  const {
    label = "Status",
    options,
    onStatusUpdate,
    defaultSelected = "all",
    onTimeChange,
    metaKey = "status",
    ...rest
  } = props;
  const {
    persistMeta,
    updateList,
    statusValue,
    setStatusValue,
    setDefaultValue,
    defaultValue,
  } = useContext(ListingComponentContext);

  useEffect(() => {
    if (defaultValue?.[metaKey]) return;
    setDefaultValue?.({ ...defaultValue, [metaKey]: defaultSelected });
    // eslint-disable-next-line
  }, [setDefaultValue, defaultValue]);

  const onOptionChange = (opt: Option) => {
    onStatusUpdate?.(opt.value + "");

    setStatusValue?.({ ...statusValue, [metaKey]: opt.value + "" });

    if (!onStatusUpdate) {
      updateList({ ...persistMeta, [metaKey]: opt.value, offset: 0 });
    }
  };

  const selectedValue = useMemo(() => {
    return statusValue?.[metaKey] ?? defaultValue?.[metaKey] ?? defaultSelected;
    // eslint-disable-next-line
  }, [statusValue, defaultValue]);

  return (
    <Box
      sx={{
        minWidth: { sm: 130 },
      }}
      {...rest}
    >
      <Sortbar
        defaultSelected={defaultSelected}
        label={label}
        options={options}
        onOptionChange={onOptionChange}
        value={selectedValue}
      />
    </Box>
  );
};

interface DateLabelInputProps extends BoxProps {
  label: string;
  onDateChanged?: (timeParam: string) => void;
  dateChangeKey: string;
  resetStatus?: string[];
}

const DateLabelInput = (props: DateLabelInputProps) => {
  const { onDateChanged, dateChangeKey, resetStatus = [], ...rest } = props;
  const { persistMeta, updateList, setStatusValue, defaultValue, statusValue } =
    useContext(ListingComponentContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [dates, setDates] = useState<DateSelectDialogDates | null>(null);

  const { startDate, endDate } = useMemo(() => {
    if (!dates) return {};
    return {
      startDate: dates.start
        ? moment(dates.start.toDate()).format("YYYY-MM-DD")
        : null,
      endDate: dates.end
        ? moment(dates.end.toDate()).format("YYYY-MM-DD")
        : null,
    };
  }, [dates]);

  useMemo(() => {
    if (statusValue?.visibility && statusValue?.visibility !== "all") {
      return setDates(null);
    }

    if (statusValue?.status && statusValue?.status !== "all") {
      return setDates(null);
    }
  }, [statusValue?.visibility, statusValue?.status]);

  const onDatesConfirm = (dates: DateSelectDialogDates | null) => {
    setDates(dates);
    const newMeta = { ...persistMeta };

    delete newMeta[dateChangeKey];
    if (!dates || (!dates?.start && !dates?.end)) {
      return updateList({
        ...newMeta,
      });
    }

    if (newMeta.status === "expiry") {
      delete newMeta.expiry;
    }

    if (newMeta.status === "start") {
      delete newMeta.start;
    }

    delete newMeta.status;
    delete newMeta.ongoing;
    delete newMeta.visibility;
    delete newMeta.publishedAt;

    if (resetStatus.length) {
      const newStatus = { ...statusValue };
      resetStatus.forEach((stat) => {
        if (defaultValue?.[stat]) {
          newStatus[stat] = defaultValue?.[stat];
        }
      });
      setStatusValue?.(newStatus);
    }

    let updateStr = "";
    if (dates.start && dates.end) {
      updateStr = `${moment(dates.start.toDate()).unix()},${moment(
        dates.end.toDate()
      ).unix()}`;
    } else if (dates.start && !dates.end) {
      updateStr = `${moment(dates.start.toDate()).unix()},`;
    } else if (!dates.start && dates.end) {
      updateStr = `0,${moment(dates.end.toDate()).unix()}`;
    }

    updateList({
      ...newMeta,
      [dateChangeKey]: updateStr,
    });
  };

  const getChip = (label?: string | null, cursor = "pointer") => (
    <Chip
      sx={{
        cursor,
        backgroundColor: "primary.ghost",
        color: "neutral.900",
      }}
      label={label}
    />
  );

  const getAdornment = () => {
    const hasStart = Boolean(dates?.start);
    const hasEnd = Boolean(dates?.end);

    if (hasStart && hasEnd) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {getChip(startDate)}
          <Typography>-</Typography>
          {getChip(endDate)}
        </Box>
      );
    }

    if (hasStart) {
      return (
        <>
          <Typography sx={{ mr: 1 }}>From</Typography>
          {getChip(startDate)}
        </>
      );
    }

    if (hasEnd) {
      return (
        <>
          <Typography sx={{ mr: 1 }}>Until</Typography>
          {getChip(endDate)}
        </>
      );
    }

    return undefined;
  };

  return (
    <Box
      sx={{
        minWidth: { md: 180, sm: "100%" },
        maxWidth: { md: 280, sm: "100%" },
        backgroundColor: "background.paper",
        borderRadius: 1,
      }}
      {...rest}
    >
      <InputField
        onClick={() => setOpenDialog(true)}
        label={`${normalizeText(dateChangeKey)} dates`}
        placeholder={!dates?.start && !dates?.end ? "Select dates" : undefined}
        sx={{ cursor: "pointer" }}
        InputProps={{
          readOnly: true,
          startAdornment: !!dates && getAdornment(),
          endAdornment: (
            <IconButton
              sx={{
                pr: 0,
              }}
            >
              <CalendarIcon />
            </IconButton>
          ),
        }}
      />

      <DateSelectDialog
        open={openDialog}
        onConfirm={onDatesConfirm}
        onClose={() => setOpenDialog(false)}
      />
    </Box>
  );
};

interface SearchbarCompProps extends BoxProps {
  label?: string;
  onSearchChange?: (search: string) => void;
}

const SearchbarComp = (props: SearchbarCompProps) => {
  const { label, onSearchChange, ...rest } = props;
  const { persistMeta, updateList } = useContext(ListingComponentContext);

  const onChange = (search: string) => {
    onSearchChange?.(search);
    if (!onSearchChange) updateList({ ...persistMeta, search });
  };

  return (
    <Box
      sx={{ flexGrow: 1, backgroundColor: "background.paper", borderRadius: 1 }}
      {...rest}
    >
      <Searchbar label={label} onSearchChange={onChange} />
    </Box>
  );
};

interface FilterSectionProps extends BoxProps {
  children: ReactNode;
}

const FilterSection = (props: FilterSectionProps) => {
  const { children, sx: sxProps, p = "16px 0px 8px 0px", ...rest } = props;

  return (
    <Box
      sx={joinSx(
        {
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
        },
        sxProps
      )}
      gap={2}
      p={p}
      {...rest}
    >
      {children}
    </Box>
  );
};

interface ListingContentProps {
  children: ReactNode;
  mode?: "account" | "tasker";
  sx?: SxProps;
}

const ListingContent = (props: ListingContentProps) => {
  const { children, sx } = props;
  const { persistMeta } = useContext(ListingComponentContext);

  return (
    <>
      {!!(persistMeta.count && persistMeta.count > 0) && (
        <Scrollbar sx={sx}>{children}</Scrollbar>
      )}
      {!!(!persistMeta.count || persistMeta.count < 1) && (
        <ListingEmptyState
          message="No tasks were found matching your search"
          subMessage="Please ensure that all words are spelled correctly or try alternative keywords."
        />
      )}
    </>
  );
};

interface DateFilterCompProps extends BoxProps {
  onDateChange?: (string) => void;
  dateSearchKey: string;
}

const DateFilterComp = (props: DateFilterCompProps) => {
  const {
    onDateChange, //md = 12, sm = 12, lg = 12,
    dateSearchKey,
    ...rest
  } = props;
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const { persistMeta, updateList } = useContext(ListingComponentContext);

  const updateDates = (start: Dayjs | null, end: Dayjs | null) => {
    if (!start || !end) return;

    const dateParam = `${moment(start.toDate()).startOf("day").unix()},${moment(
      end.toDate()
    )
      .endOf("day")
      .unix()}`;

    onDateChange?.(dateParam);
    if (!onDateChange) {
      updateList({
        ...persistMeta,
        [dateSearchKey]: dateParam,
      });
    }
  };

  const onStartChange = (date: Dayjs | null) => {
    setStart(date);

    updateDates(date, end);
  };

  const onEndChange = (date: Dayjs | null) => {
    setEnd(date);

    updateDates(start, date);
  };

  return (
    <Box
      sx={{
        minWidth: { sm: 130 },
      }}
      {...rest}
    >
      <DateFilter
        startValue={start}
        endValue={end}
        onStartDateChange={onStartChange}
        onEndDateChange={onEndChange}
      />
    </Box>
  );
};

interface ListingProps {
  hidePagination?: boolean;
  updateList: (meta: Meta) => void;
  meta?: Meta;
  children: ReactNode;
  title?: string;
  loading?: boolean;
  sx?: SxProps;
  mode?: "admin" | "tasker";
}
const Listing = (props: ListingProps) => {
  const {
    loading,
    hidePagination = false,
    meta = { count: 0, limit: 10, offset: 0 },
    mode = "admin",
    updateList,
    children,
    // title,
    sx: sxProps,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(meta.limit);
  const [statusValue, setStatusValue] = useState<SimpleMap>({});
  const [defaultValue, setDefaultValue] = useState<SimpleMap>({});

  const { palette } = useTheme();

  const wrapUpdateList = useCallback(
    (newMeta: Meta) => {
      updateList({ ...newMeta });
    },
    [updateList]
  );

  const onRowsPerPageChange = (
    val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPage = parseInt(val.target.value) ?? 0;

    setRowsPerPage(newPage);
    wrapUpdateList({
      ...meta,
      limit: newPage,
      offset: 0,
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    wrapUpdateList({
      ...meta,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  };

  const onTaskerPageChange = (
    event: React.ChangeEvent<unknown> | null,
    page: number
  ) => {
    console.log({ page });
    wrapUpdateList({
      ...meta,
      limit: rowsPerPage,
      offset: rowsPerPage * (page - 1),
    });
  };

  const taskerPageCount = !!meta.count ? Math.ceil(meta.count / meta.limit) : 0;
  const taskerPage = meta.offset === 0 ? 1 : meta.offset / meta.limit + 1;

  return (
    <ListingComponentContext.Provider
      value={{
        defaultValue,
        setDefaultValue,
        statusValue,
        setStatusValue,
        persistMeta: meta,
        updateList: wrapUpdateList,
        loading,
      }}
    >
      <Box sx={joinSx({ m: 2, justifyContent: "center" }, sxProps)}>
        <LoadingContainer loading={loading}>
          {children}
          {!hidePagination && (
            <>
              {!!(!!meta.count && meta.count > 0) && mode === "admin" && (
                <TablePagination
                  component="div"
                  count={meta.count ?? 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  page={meta.offset === 0 ? 0 : meta.offset / meta.limit}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                  sx={{
                    "& .MuiTablePagination-spacer": {
                      display: "none",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "12px",
                    },
                    "& .MuiTablePagination-select": {
                      paddingLeft: 0,
                    },
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "12px",
                    },
                    justifyItems: "center",
                  }}
                  SelectProps={{
                    IconComponent: (props) => (
                      <ChevronDown fontSize="10px" {...props} />
                    ),
                    style: { fontSize: "12px" },
                  }}
                />
              )}
              {mode === "tasker" && (
                <Pagination
                  sx={{
                    "&.MuiPagination-root > ul": {
                      justifyContent: "center",
                    },
                  }}
                  page={taskerPage}
                  count={taskerPageCount}
                  onChange={onTaskerPageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: PaginationLeft,
                        next: PaginationRight,
                        first: PaginationFirst,
                        last: PaginationLast,
                      }}
                      {...item}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: palette.primary.hover,
                        },
                      }}
                    />
                  )}
                />
              )}
            </>
          )}
          {hidePagination && (
            <Typography variant="body2" color="textSecondary" p={2}>
              Showing {Math.min(meta.limit, meta.count ?? 0)} of{" "}
              {meta.count ?? 0} records…
            </Typography>
          )}
        </LoadingContainer>
      </Box>
    </ListingComponentContext.Provider>
  );
};

Listing.Statusbar = StatusbarComp;
Listing.Searchbar = SearchbarComp;
Listing.FilterSection = FilterSection;
Listing.Content = ListingContent;
Listing.DateFilter = DateFilterComp;
Listing.DateLabelInput = DateLabelInput;

export default Listing;
