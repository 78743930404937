import { useTheme } from "@mui/material/styles";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { accountApi } from "src/main/api";
import { ListPayoutStatsVolItem } from "src/main/api/account/Stats";
import { Chart } from "src/main/components";
import { ChartProps } from "src/main/components/Chart/Chart";
import { RootState } from "src/main/store";
import { Paths } from "src/main/utils";

interface PayoutOTProps extends Partial<ChartProps> {}
const PayoutOT: React.FC<PayoutOTProps> = (props) => {
  const [start] = useState(moment().add(-29, "day"));
  const [end] = useState(moment().endOf("day"));

  const theme = useTheme();

  const result = accountApi.useListPayoutVolStatsQuery({
    meta: { createdAt: `${start.unix()},${end.unix()}` },
  });
  const responsePayoutVolStats: ListPayoutStatsVolItem[] | undefined = useMemo(
    () => result.data ?? undefined,
    [result.data]
  );
  const currentTheme = useSelector<RootState>(
    (state) => state.preference.theme
  );

  const { chartOpts, series } = useMemo(() => {
    const chartOpts: ApexOptions = {
      chart: {
        background: "transparent",
        stacked: false,
        toolbar: { show: false },
      },
      dataLabels: { enabled: false },
      fill: { opacity: 1 },
      grid: {
        borderColor: theme.palette.divider,
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: true } },
      },
      legend: { show: false },
      markers: {
        hover: { size: undefined, sizeOffset: 2 },
        radius: 2,
        shape: "circle",
        size: 4,
        strokeWidth: 0,
      },
      stroke: { curve: "smooth", lineCap: "butt", width: 3 },
      theme: {
        mode: currentTheme === "light" ? "light" : "dark",
        monochrome: {
          enabled: true,
          color:
            currentTheme === "light"
              ? theme.palette.primary.light
              : theme.palette.primary.dark,
          shadeTo: "dark",
          shadeIntensity: 0.65,
        },
      },
      xaxis: { categories: [] },
    };
    const series = [
      {
        data: [] as number[],
        name: "Processed",
      },
    ];

    if (responsePayoutVolStats) {
      const Date_Format = "DD MMM";

      let currentDate = moment(start);
      while (
        currentDate.format(Date_Format) !==
        moment(end).add(1, "day").format(Date_Format)
      ) {
        let dateKey = currentDate.format(Date_Format);

        const item = responsePayoutVolStats.find(
          (serie) => serie.date.format(Date_Format) === dateKey
        );

        if (chartOpts.xaxis?.categories.indexOf(dateKey) < 0) {
          chartOpts.xaxis?.categories.push(dateKey);
          series[0].data.push(item?.amount ?? 0);
        }

        // if (chartOpts["xaxis"]?.categories.indexOf(dateKey) < 0) {
        //   series[0].data.push(item?.inprogress ?? 0);
        //   series[1].data.push(item?.approved ?? 0);
        //   series[2].data.push(item?.submitted ?? 0);

        //   chartOpts["xaxis"]?.categories.push(dateKey);
        // }
        currentDate = currentDate.add(1, "day");
      }
    }
    return { chartOpts, series };
  }, [responsePayoutVolStats, theme, currentTheme, start, end]);

  return (
    <Chart
      {...props}
      title="Payouts"
      subtitle="Last 30 days"
      chartOptions={chartOpts}
      chartSeries={series}
      height={300}
      type="line"
      shortcuts={{
        header: {
          label: "All Payouts",
          to: Paths.Management.Payouts,
        },
      }}
    />
  );
};

export default PayoutOT;
