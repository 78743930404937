import moment from "moment";
import { BuilderProp, require200Status } from "src/main/api/account";
import { AffiliatePageConfig, AffiliatePageModel, AffiliateTrackerModel, AffiliateTrackerShortLinkModel, QueryMeta, QueryResult } from "src/main/types";
import { parseMeta } from "src/main/utils/parseMeta";
import { QueryResultEntries, QueryResultModel } from "../../auth/endpoints";

export const transformAffiliatePage = (value: any) => {
  const model: AffiliatePageModel = {
    ...value,
    publishedAt: moment(value.publishedAt),
  };
  return model;
};

export const listAffiliatePage = (builder: BuilderProp) => {
  return builder.query<QueryResultEntries<AffiliatePageModel[]>, QueryMeta>({
    providesTags: ["affiliatePage"],
    query: ({ meta }) => ({
      url: `/account/affiliate/page/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultEntries<AffiliatePageModel[]>>) => {
      response.result.entries = response.result.entries.map(transformAffiliatePage);
      return response.result;
    }
  })
}

export interface CreateAffiliatePageRequest {
  data: {
    brandKey: string;
    name: string;
    config: AffiliatePageConfig
  }
}
export const createAffiliatePage = (builder: BuilderProp) => {
  return builder.mutation<AffiliatePageModel, CreateAffiliatePageRequest>({
    invalidatesTags: ["affiliatePage"],
    query: (props: CreateAffiliatePageRequest) => ({
      url: `/account/affiliate/page/create`,
      method: "POST",
      body: props.data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultModel<AffiliatePageModel>>) => {
      return transformAffiliatePage(response.result.model);
    },
  })
}
export interface UpdateAffiliatePageRequest {
  id: string;
  data: {
    name: string;
    config: AffiliatePageConfig
  }
}
export const updateAffiliatePage = (builder: BuilderProp) => {
  return builder.mutation<AffiliatePageModel, UpdateAffiliatePageRequest>({
    invalidatesTags: ["affiliatePage"],
    query: (props: UpdateAffiliatePageRequest) => ({
      url: `/account/affiliate/page/${props.id}/update`,
      method: "POST",
      body: props.data,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultModel<AffiliatePageModel>>) => {
      return transformAffiliatePage(response.result.model);
    },
  })
}
export const detailAffiliatePage = (builder: BuilderProp) => {
  return builder.query<AffiliatePageModel, string>({
    providesTags: ["affiliatePage"],
    query: (affiliateId: string) => ({
      url: `/account/affiliate/page/${affiliateId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultModel<AffiliatePageModel>>) => {
      return transformAffiliatePage(response.result.model);
    }
  })
}

export const listAffiliateTracker = (builder: BuilderProp) => {
  return builder.query<QueryResultEntries<AffiliateTrackerModel[]>, QueryMeta>({
    providesTags: ["affiliatePage"],
    query: ({ meta }) => ({
      url: `account/affiliate/tracker/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultEntries<AffiliateTrackerModel[]>>) => {
      return response.result;
    }
  })
}

export const detailAffiliateTracker = (builder: BuilderProp) => {
  return builder.query<AffiliateTrackerModel, string>({
    providesTags: ["affiliatePage"],
    query: (affiliateTrackerId) => ({
      url: `account/affiliate/tracker/${affiliateTrackerId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultModel<AffiliateTrackerModel>>) => {
      return response.result.model;
    }
  })
}

export const listAffiliateVisitTrackers = (builder: BuilderProp) => {
  return builder.query<QueryResultEntries<AffiliateTrackerShortLinkModel[]>, string>({
    providesTags: ["affiliatePage"],
    query: (affiliateTrackerId) => ({
      url: `account/affiliate/tracker/${affiliateTrackerId}/link/list`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<QueryResultEntries<AffiliateTrackerShortLinkModel[]>>) => {
      return response.result;
    }
  })
}
