import { createSvgIcon } from '@mui/material/utils';

const ChevronDown = createSvgIcon(
  <svg viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22714 0.292893C1.61767 -0.0976311 2.25083 -0.0976311 2.64136 0.292893L7.76758 5.41912L12.8938 0.292893C13.2843 -0.0976311 13.9175 -0.0976311 14.308 0.292893C14.6985 0.683417 14.6985 1.31658 14.308 1.70711L8.47469 7.54044C8.08417 7.93096 7.451 7.93096 7.06048 7.54044L1.22714 1.70711C0.836619 1.31658 0.836619 0.683417 1.22714 0.292893Z" fill="currentColor" />
  </svg>

  ,
  'ChevronRight'
);

export default ChevronDown;
