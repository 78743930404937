import BigNumber from "bignumber.js";
import React from "react";
import { DateDisplay, DetailCard, Table, TableRowWrap } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { WithdrawRequestModel } from "src/main/types";

interface WithdrawDetailCardProps extends React.PropsWithChildren {
  request: WithdrawRequestModel;
}

const WithdrawDetailCardComponent: React.FC<WithdrawDetailCardProps> = (props) => {
  const { request } = props;
  return (
    <DetailCard>
      <DetailCard.Header
        header="Request Information"
      />
      <DetailCard.Divider />
      <Table>
        <Table.Body>
          <TableRowWrap
            label="Amount"
            align="right"
            value={request.amount.toString()}
          />
          <TableRowWrap
            label="Currency"
            align="right"
            value={request.currency}
          />
          <TableRowWrap
            label="Amount (USD)"
            align="right"
            value={BigNumber(request.amountInUsd).toFixed(2)}
          />
          <TableRowWrap
            label="Withdraw Fee"
            align="right"
            value={BigNumber(request.withdrawFee).toFixed(2)}
          />
          <TableRowWrap
            label="Final Withdraw Amount"
            align="right"
            value={request.finalAmount.toString()}
          />
          <TableRowWrap
            label="Requested On"
            align="right"
            value={<DateDisplay format="YYYY-MM-DD HH:mm" value={request.createdAt} />}
          />
          <TableRowWrap
            label="Status"
            align="right"
            value={<StatusChip status={request.status} />}
          />
        </Table.Body>
      </Table>
    </DetailCard >
  );
};

export default WithdrawDetailCardComponent;
