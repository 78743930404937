import { Box, Switch, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { AccountVariableBooleanType, AccountVariableModel, AccountVariableType } from "src/main/types";
import { handleApiResponse, normalizeText } from "src/main/utils";

interface BrandCardProps extends React.PropsWithChildren {
  variable?: AccountVariableModel<AccountVariableBooleanType>;
  variableKey: string
  label?: {
    title: string,
    description?: string,
  },
}
const keyToLabel = (key: string) => {
  return normalizeText(key.replaceAll("-", " "), "letter");
}


const BrandCardPage: React.FC<BrandCardProps> = (props) => {
  const { label, variable, variableKey } = props;

  const [isChecked, setIsChecked] = useState(!!variable?.content?.value);

  useMemo(() => {
    if (variable?.content) setIsChecked(!!variable?.content?.value);
  }, [variable?.content])

  const [createAccountVariable, { isLoading: isCreateLoading }] = accountApi.useCreateAccountVariableMutation();
  const [updateAccountVariable, { isLoading: isUpdateLoading }] = accountApi.useUpdateAccountVariableMutation();

  const onCreate = useCallback(async (checked: boolean) => {

    const createRequest = {
      type: AccountVariableType.Generic,
      key: variableKey,
      content: { value: checked }
    }

    const result = await createAccountVariable(createRequest);

    const { error } = handleApiResponse(result);
    if (error?.data?.error) {
      throw new Error(error.data?.error?.message)
    }

    toast.success("Account settings updated");
    // eslint-disable-next-line
  }, [createAccountVariable, handleApiResponse])

  const onUpdate = useCallback(async (checked: boolean) => {
    if (!variable) return;
    const updateRequest = {
      type: AccountVariableType.Generic,
      key: variableKey,
      content: { value: checked }
    }
    const result = await updateAccountVariable({ accountVariableId: variable!.id, data: updateRequest })

    const { error } = handleApiResponse(result);
    if (error?.data?.error) {
      throw new Error(error.data?.error?.message)
    }

    toast.success("Account settings updated");
    // eslint-disable-next-line
  }, [updateAccountVariable, handleApiResponse, variable])

  const updateOrCreateVariable = (checked: boolean) => {
    setIsChecked(checked)
    if (variable) {
      onUpdate(checked);
    } else {
      onCreate(checked)
    }
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box flex={1}>
        <Typography variant="body2" fontWeight="bold">{label?.title ?? keyToLabel(variableKey)}</Typography>
        {label?.description && <Typography variant="body2" color="textSecondary">{label?.description}</Typography>}
      </Box>
      <Switch
        disabled={isCreateLoading || isUpdateLoading}
        checked={isChecked}
        onChange={(ev, check) => updateOrCreateVariable(check)}
      />
    </Box>
  )
};

export default BrandCardPage;
