import { Button, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { ChevronRight } from "src/assets";
import { TaskAttemptModel } from "src/main/types";
import { SimpleMap, IntlFormatter } from "src/main/utils";

interface AttemptStatusTextProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
}

const AttemptStatusText: React.FC<AttemptStatusTextProps> = (props) => {
  const { attempt } = props;
  const theme = useTheme();


  const default_obj = useMemo(() => {

    let default_obj: SimpleMap<string> = {};
    let defaultText = "View";
    if (attempt?.status === "in-progress") {
      if (!!attempt?.rejectReason) {
        defaultText = IntlFormatter.getMessage("taskAttemptPage.editNow", "Edit Now");
      } else {
        defaultText = IntlFormatter.getMessage("taskAttemptPage.continue", "Continue");
      }
    }
    default_obj = { text: defaultText };

    return default_obj
  }, [attempt])


  if (!attempt) return null;

  return (
    <Button
      endIcon={<ChevronRight fontSize="small" sx={{marginLeft: -1}} />}
      variant="text"
      style={{ color: theme.palette.primary.main, fontWeight: 700 }}
    >
      {default_obj.text}
    </Button>
  );
};

export default AttemptStatusText;
