import { Box, Typography, TypographyProps } from "@mui/material";
import moment, { Moment } from "moment";
import React from "react";
import { createStyles, joinSx } from "src/main/utils";
interface Props extends TypographyProps {
  value: Moment | string | null | undefined;
  format?: string;
  stacking?: boolean;
}

const DateDisplay: React.FC<Props> = (props: Props) => {
  const { value, variant = "body2", format = "YYYY-MM-DD", stacking = false, ...rest } = props;

  const getValue = () => {
    if (!moment(value).isValid()) return "-"

    if (stacking) {
      return (
        <>
          <Typography
            sx={joinSx(styles.root, props.sx)}
            variant={variant}
            {...rest}
          >
            {moment(value).format("YYYY-MM-DD")}
          </Typography>
          <Typography
            variant={variant}
            {...rest}
          >
            {moment(value).format("HH:mm:ss A")}
          </Typography>
        </>
      )
    } else {
      return (
        <Typography
          sx={joinSx(styles.root, props.sx)}
          variant={variant}
          {...rest}
        >
          {moment(value).format(format)}
        </Typography>
      )
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      {getValue()}
    </Box>
  );
};

const styles = createStyles({
  root: {
  },
});

export default DateDisplay;
