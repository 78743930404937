import React, { useState, useMemo } from "react";
import { SxProps, Typography } from "@mui/material";
import { Meta } from 'src/main/types';
import { DateDisplay, ListingComp, Table, WorkerNameDisplay } from "src/main/components";
import { accountApi } from "src/main/api";
import { parseCategory, parseLogMessage } from "src/main/utils";


interface ActivityLogProps extends React.PropsWithChildren {
  sx?: SxProps;
  ownerId: string;
  ownerName: string;
}

const TaskHeaders = [
  { value: "Category" },
  { value: "Actor" },
  { value: "Date Time/IP Address" },
  { value: "Description" },
]


const ActivityLogComponent: React.FC<ActivityLogProps> = (props) => {
  const { ownerName = "", ownerId = "", sx } = props;
  const [meta, setMeta] = useState<Meta>({ offset: 0, limit: 10, count: 0, ownerName: ownerName, ownerId: ownerId });
  const result = accountApi.useListActivityLogQuery({ meta });
  const listActivityLog = useMemo(() => result.data ?? undefined, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])

  return (
    <ListingComp sx={sx} title="Activity" loading={result.isLoading} updateList={(newMeta) => { setMeta(newMeta) }} meta={persistMeta}>
      <ListingComp.FilterSection>

      </ListingComp.FilterSection>
      <ListingComp.Content>
        <Table>
          <Table.Head headers={TaskHeaders} />
          <Table.Body>
            {listActivityLog?.entries.map((curLog) => (
              <Table.Row>
                <Table.Cell value={parseCategory(curLog.category)} />
                <Table.Cell value={<WorkerNameDisplay person={curLog.entity?.person} />} />
                <Table.Cell>
                  <DateDisplay value={curLog.createdAt} format="YYYY-MM-DD (h:mm:ss a)" />
                  <Typography variant="caption">{curLog.ipAddress ?? "-"}</Typography>
                </Table.Cell>
                <Table.Cell value={parseLogMessage(curLog.description)} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ListingComp.Content>
    </ListingComp>
  );
};

export default ActivityLogComponent;
