import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DetailCard, EmptyState } from "src/main/components";
import { AffiliatePageModel } from 'src/main/types';
import { Paths } from "src/main/utils";

interface AffiliatePageInfoCardProps extends React.PropsWithChildren {
  affiliatePage?: AffiliatePageModel;
}

const AffiliatePageInfoCardComponent: React.FC<AffiliatePageInfoCardProps> = (props) => {
  const { affiliatePage } = props;
  const navigate = useNavigate();

  return (
    <DetailCard>
      <DetailCard.Header
        header="Affiliate Page Information"
        endComp={
          !!affiliatePage
            ? <IconButton
              onClick={() => {
                navigate(Paths.Management.AffiliatePageDetail.replace(":affiliatePageId", affiliatePage.id));
              }}
            >
              <ChevronRightIcon />
            </IconButton>
            : undefined
        }
      />
      <DetailCard.Divider />
      {!affiliatePage && <EmptyState message='No affiliate page info' />}
      {!!affiliatePage && (
        <Box p={2} px={3} display="flex" justifyContent="space-between">
          <Typography>{affiliatePage.name}</Typography>
        </Box>
      )}
    </DetailCard >
  );
};

export default AffiliatePageInfoCardComponent;
