import { Circle } from "@mui/icons-material";
import { Box, BoxProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/main/store";
import { createStyles, DeployEnv, getAppEnv, joinSx } from "src/main/utils";

interface CardBannerProps extends BoxProps {

}
const appEnv = getAppEnv();

const CardBanner: React.FC<CardBannerProps> = (props) => {
  const { sx, ...rest } = props;
  const online = useSelector<RootState, boolean | null>(state => state.layout.apiHealth);
  const loading = online === null;

  const containerStyle = useMemo(() => {
    if (loading) return styles.loading;
    if (online) return styles.online;
    return styles.offline;
  }, [loading, online]);

  return (
    <Box {...rest} sx={joinSx(styles.root, sx)}>
      <Box sx={joinSx(containerStyle, styles.status)}>
        <Circle fontSize="inherit" color="inherit" />
        <Typography variant="overline" sx={{ ml: 1 }}>
          {appEnv.api !== DeployEnv.Production && (
            <>{appEnv.api ?? "unknown"} API -{" "}</>
          )}
          {loading ? "Checking status…" : (online ? "Online" : "Offline")}
        </Typography>
      </Box>

      <Box>
        <Typography variant="caption" sx={joinSx(styles.text)}>
          {appEnv.deploy !== DeployEnv.Production && (
            <>{appEnv.deploy ?? "unknown"}</>
          )}
          {" "}
          ({appEnv.build})
        </Typography>
      </Box>
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 2,
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 1,
    px: 1,
    fontSize: "10px",
  },
  online: {
    color: "success.alert.content",
    backgroundColor: "success.alert.background"
  },
  offline: {
    color: "error.alert.content",
    backgroundColor: "error.alert.background"
  },
  loading: {
    color: "warning.alert.content",
    backgroundColor: "warning.alert.background"
  },
  text: {
    ml: 1,
    color: "text.secondary",
  },
});

export default CardBanner;
