import { Chip, ChipProps, Typography } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { DuplicateIcon } from "src/assets";
import { commonStyles, createStyles, joinSx, IntlFormatter } from "src/main/utils";

interface TaskIdChipProps extends ChipProps {
  taskId: string;
}

const TaskIdChip: React.FC<TaskIdChipProps> = (props) => {
  const { taskId } = props;

  return (
    <Chip
      sx={joinSx(commonStyles.borderlessChip, styles.duplicateChip)}
      deleteIcon={(<DuplicateIcon viewBox="-4 -2 20 20" />)}
      label={<Typography variant="caption" color="text.secondary">T-${!!taskId && taskId.substring(0, 8)}</Typography>}
      variant="outlined"
      onDelete={() => {
        if (!taskId) {
          toast.error(IntlFormatter.getMessage("taskAttemptPage.invalidId", "Invalid ID"));
          return;
        }

        navigator.clipboard.writeText(`${taskId}`);
        toast.success(IntlFormatter.getMessage("taskAttemptPage.taskIdCopied", "Task ID Copied"));
      }}
    />
  );
}
const styles = createStyles({
  duplicateChip: {
    display: "flex",
    "& .MuiChip-label": {
      px: 0,
    },
    "&.MuiChip-outlinedDefault > .MuiChip-deleteIcon": {
      color: "primary.main",
      m: 0
    }
  }
});



export default TaskIdChip;