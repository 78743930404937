import { createSvgIcon } from '@mui/material/utils';

const CheckCircleIconFill = createSvgIcon(
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9" fill="currentColor"  />
    <path xmlns="http://www.w3.org/2000/svg" d="M4.16675 10.8334L7.50008 14.1667L15.8334 5.83337"
      stroke='#fff'
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      transform="scale(0.8) translate(2.5, 2.5)"
    />
  </svg>
  ,
  'CheckCircleIconFill'
);

export default CheckCircleIconFill;
