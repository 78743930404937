import { Tabs, Tab, TabsProps } from "@mui/material";
import { createStyles, joinSx, SimpleMap } from "src/main/utils";
import React from "react";

interface Props extends TabsProps {
  tabs: SimpleMap<string>[];
  updateTab: (val: string) => void
}

const TabsComponent: React.FC<Props> = (props: Props) => {
  const { tabs, sx, updateTab, ...rest } = props;

  return (
    <Tabs
      variant="scrollable"
      {...rest}
      onChange={(ev, value) => { updateTab(value) }}
      sx={joinSx(styles.root, sx)}
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
};

const styles = createStyles({
  root: {
    borderBottom: "1px solid #C4C4C4"
  },
});

export default TabsComponent;