import React, { useMemo } from "react";
import { accountApi } from 'src/main/api';
import { BigLabelCard } from "src/main/components";
import { Paths } from "src/main/utils";

interface ActiveTaskersProps {
}
const ActiveTaskers: React.FC<ActiveTaskersProps> = (props) => {
  const result = accountApi.useGetWorkerStatsQuery({});

  const { active } = useMemo(() => {
    if (!result.data) return {};
    return {
      active: result.data.worker.active.toString(),
      pending: result.data.worker.pending.toString()
    }
  }, [result]);

  return (
    <BigLabelCard
      label="Active Taskers" routeLabel="See All Taskers"
      value={active ?? "-"}
      route={Paths.Management.Workers} />
  );
}

export default ActiveTaskers;
