import { Box, Card, CardContent, CardHeader, Divider } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { accountApi } from 'src/main/api';
import { LoadingContainer, Page } from "src/main/components";
import { AccountVariableBooleanType, AccountVariableBrandType, AccountVariableModel } from "src/main/types";
import { SimpleMap } from "src/main/utils";
import { AccountCurrencyCard, BrandCard, VariableSwitch } from "./components";

interface AccountSettingProps extends React.PropsWithChildren {

}
const ACCOUNT_CURRENCY = "account-currency";

enum AccountVariableKey {
  PayoutItemSubmitRequired = "payout-item-submit-required",
  PayoutItemApprovalRequired = "payout-item-approval-required",
  PayoutItemProcessRequired = "payout-item-process-required",
}

const SETTING_KEYS = {
  [AccountVariableKey.PayoutItemSubmitRequired]: {
    title: "Submission required",
    description: "A payout item will be created as draft mode.",
  },
  [AccountVariableKey.PayoutItemApprovalRequired]: {
    title: "Approval required",
    description: "A submitted payout item has to be approved before it can be processed.",
  },
  [AccountVariableKey.PayoutItemProcessRequired]: {
    title: "Processing required",
    description: "An approved payout item has to be processed before it is marked completed.",
  },
}

const AccountSettingPage: React.FC<AccountSettingProps> = (props) => {

  const result = accountApi.useListAccountVariableQuery({ meta: { count: 0, limit: 1000, offset: 0 } });
  const accountVariables = useMemo(() => result.data?.entries ?? [], [result.data?.entries]);

  const { brandVariable, switchVariables, currencyVariable } = useMemo(() => {
    const brandVariable = accountVariables.find((variable) => variable.key === "worker-brands") as AccountVariableModel<AccountVariableBrandType> | undefined;
    const configKeys: string[] = Object.values(AccountVariableKey);
    const switchVariables = accountVariables.reduce((accum, variable) => {
      if (configKeys.includes(variable.key)) {
        accum[variable.key] = variable as AccountVariableModel<AccountVariableBooleanType>;
      }
      return accum;
    }, {} as SimpleMap<AccountVariableModel<AccountVariableBooleanType>>);

    const currencyVariable = accountVariables.find((vari) => vari.key === ACCOUNT_CURRENCY) as AccountVariableModel<SimpleMap<string>>

    return { brandVariable, switchVariables, currencyVariable }
  }, [accountVariables])

  useEffect(() => {
    if (result.error)
      toast.error("Failed to retrieve setting");
  }, [result.error]);

  return (
    <Page>
      <Page.TopSection title="Account Setting"></Page.TopSection>

      <LoadingContainer loading={result.isLoading}>

        <Box sx={{
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
          p: 2,
        }}
          gap={2}
        >
          <Box flex={3}>
            <BrandCard variable={brandVariable} />
          </Box>
          <Box flex={2} display="flex" flexDirection="column" gap={2}>
            <Card>
              <CardHeader title="Account configurations" />
              <CardContent>
                {Object.values(AccountVariableKey).map((variableKey) => (
                  <React.Fragment>
                    <VariableSwitch
                      key={variableKey}
                      label={SETTING_KEYS[variableKey]}
                      variableKey={variableKey}
                      variable={switchVariables[variableKey]} />
                    <Box py={1}>
                      <Divider />
                    </Box>
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
            {!!accountVariables && <AccountCurrencyCard variable={currencyVariable} />}
          </Box>
        </Box>
      </LoadingContainer>
    </Page>
  )
};

export default AccountSettingPage;
