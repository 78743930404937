import { Box, BoxProps } from "@mui/material";
import { Typography } from "src/main/components";
import React from "react";
import { createStyles, joinSx } from "src/main/utils";
import { PendingPayment, WalletBalance } from "./components";

interface WalletProps extends BoxProps {

}

const Benefit: React.FC<WalletProps> = (props) => {
  const { sx, ...boxProps } = props;

  return (
    <Box sx={joinSx(styles.root, sx)} {...boxProps}>
      <Typography formatId="overview.myWallet" variant="subtitle2" fontWeight="700">My Wallet</Typography>
      <Box sx={styles.container}>
        <Box sx={styles.card} mr={0.5}>
          <Box sx={styles.cardContent}>
            <WalletBalance flex={1} />
          </Box>
        </Box>
        <Box sx={styles.card} ml={0.5}>
          <Box flex={1} sx={styles.cardContent}>
            <PendingPayment flex={1} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = createStyles({
  root: {
    mb: 4,
  },
  container: {
    display: "flex",
    justifyContent: 'center',
  },
  card: {
    border: 1,
    borderColor: "neutral.200",
    boxShadow: 3,
    borderRadius: 1,
    p: 1,
    flexGrow: 1
  },
  cardContent: {
    display: "flex",
    justifyContent: 'space-between',
  },
});

export default Benefit;