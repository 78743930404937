import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import queryString from "query-string";
import { QueryFn } from "src/main/api/base";
import { QueryTags } from "src/main/constants";
import { Meta, TaskAttemptModel, TaskModel, WalletModel, WithdrawRequestModel } from "src/main/types";
import { bnOrZero, parseBN, parseMoment } from "src/main/utils";
import { parseMeta } from "src/main/utils/parseMeta";

export interface QueryResult<T> {
  result: T;
}
export interface QueryResultEntries<T> {
  entries: T;
  meta: any; // TODO: type meta
}
export interface QueryResultModel<T> {
  model: T;
}

export type WorkerApiBuilder<A extends string> = EndpointBuilder<QueryFn, typeof QueryTags.Worker[number], A>;

export interface WorkerTasksResponse extends QueryResult<QueryResultEntries<TaskModel[]>> {

}

const getQueryString = (query?: object) => {
  if (!query) return "";
  if (!Object.keys(query).length) return "";
  return "?" + queryString.stringify(query);
}

const transformTask = (input: any) => {
  if (input.payAmount)
    input.payAmount = parseBN(input.payAmount);
  if (input.start)
    input.start = parseMoment(input.start);
  if (input.expiry)
    input.expiry = parseMoment(input.expiry);
  if (input.publishedAt)
    input.publishedAt = parseMoment(input.publishedAt);
  if (input.recentSubmittedAttempt)
    input.recentSubmittedAttempt = transformAttempt(input.recentSubmittedAttempt);
  if (input.ongoingAttempt)
    input.ongoingAttempt = transformAttempt(input.ongoingAttempt);

  return input as TaskModel;
}

const transformAttempt = (input: any) => {
  if (input.task)
    input.task = transformTask(input.task);
  if (input.submittedAt)
    input.submittedAt = parseMoment(input.submittedAt);
  if (input.approvedAt)
    input.approvedAt = parseMoment(input.approvedAt);
  if (input.expiredAt)
    input.expiredAt = parseMoment(input.expiredAt);
  if (input.acceptedAt)
    input.acceptedAt = parseMoment(input.acceptedAt);
  return input as TaskAttemptModel;
}

const transformWithdrawRequest = (input: any) => {
  if (typeof input.amount === "string")
    input.amount = bnOrZero(input.amount)
  if (typeof input.amountInUsd === "string")
    input.amountInUsd = bnOrZero(input.amountInUsd)
  if (typeof input.withdrawFee === "string")
    input.withdrawFee = bnOrZero(input.withdrawFee)
  if (typeof input.finalAmount === "string")
    input.finalAmount = bnOrZero(input.finalAmount)
  if (input.createdAt)
    input.createdAt = parseMoment(input.createdAt)
  return input as WithdrawRequestModel;
}

export interface QueryTaskProps {
  limit?: string;
  available?: string;
}

export const queryTasks = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: (query?: QueryTaskProps) => ({
    url: `work/task/list${getQueryString(query)}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: WorkerTasksResponse) => {
    const result = response.result;
    result.entries = result.entries.map(transformTask);
    return result;
  },
})

export interface QueryAttemptsProps {
  status?: string;
  submittedAt?: string;
  limit?: string;
}
export const queryAttempts = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: (query?: QueryAttemptsProps) => ({
    url: `work/task/attempt/list${getQueryString(query)}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultEntries<TaskAttemptModel[]>>) => {
    console.log("work/task/attempt/list", response);
    const result = response.result;
    result.entries = result.entries.map(transformAttempt);
    return response.result;
  },
})

export const queryAttempt = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: (attemptId: string) => ({
    url: `work/task/attempt/${attemptId}/detail`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<TaskAttemptModel>>) => {
    console.log("work/task/attempt/:attemptId/detail", response);
    const result = response.result;
    result.model = transformAttempt(result.model);
    return result;
  },
})

export interface WorkerTaskDetailResponse extends QueryResult<QueryResultModel<TaskModel>> {

}
export const queryTask = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: (taskId: string) => ({
    url: `work/task/${taskId}/detail`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: WorkerTaskDetailResponse) => {
    console.log("work/task/:taskId/detail", response);
    return response.result;
  },
})

export interface WorkerTaskContentResponse {
  content: string;
}
export const queryTaskContent = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: (taskId: string) => ({
    url: `work/task/${taskId}/content`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<WorkerTaskContentResponse>) => {
    return response.result;
  },
})

export const acceptTask = <A extends string>(builder: WorkerApiBuilder<A>) => builder.mutation({
  invalidatesTags: ["tasks"],
  query: (taskId: string) => ({
    url: `work/task/${taskId}/accept`,
    method: "POST",
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<TaskModel>>) => {
    console.log("work/task/:taskId/accept", response);
    return response.result;
  },
})

export const submitTask = <A extends string>(builder: WorkerApiBuilder<A>) => builder.mutation({
  invalidatesTags: ["tasks"],
  query: (attemptId: string) => ({
    url: `work/task/attempt/${attemptId}/submit`,
    method: "POST",
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<TaskModel>>) => {
    console.log("work/task/attempt/:attemptId/submit", response);
    return response.result;
  },
})

export const cancelTask = <A extends string>(builder: WorkerApiBuilder<A>) => builder.mutation({
  invalidatesTags: ["tasks"],
  query: (attemptId: string) => ({
    url: `work/task/attempt/${attemptId}/cancel`,
    method: "POST",
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<TaskModel>>) => {
    return response.result;
  },
})

export const queryWallets = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["workerWallets"],
  query: () => ({
    url: `work/wallet/list`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultEntries<WalletModel[]>>) => {
    console.log("work/wallet/list", response);
    response.result.entries.forEach(entry => {
      entry.balance = bnOrZero(entry.balance);
    });
    return response.result;
  },
})

export interface QueryEarningsProps {
  earnings: {
    totalEarnings: string;
    pendingPayout: string;
    pendingWithdraw: string;
  };
  earningsUsd: {
    totalEarnings: string;
    pendingPayout: string;
    pendingWithdraw: string;
  };
}
export const queryEarnings = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: () => ({
    url: `work/stats/earnings`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryEarningsProps>) => {
    console.log("work/stats/earnings", response);
    return {
      earnings: {
        totalEarnings: bnOrZero(response.result.earnings.totalEarnings),
        pendingPayouts: bnOrZero(response.result.earnings.pendingPayout),
        pendingWithdraw: bnOrZero(response.result.earnings.pendingWithdraw),
      },
      earningsUsd: {
        totalEarnings: bnOrZero(response.result.earningsUsd.totalEarnings),
        pendingPayouts: bnOrZero(response.result.earningsUsd.pendingPayout),
        pendingWithdraw: bnOrZero(response.result.earningsUsd.pendingWithdraw),
      }
    };
  },
})

export interface QueryPotentialEarningsProps {
  earnings: {
    total: string;
  };
}
export const queryPotentialEarnings = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["tasks"],
  query: () => ({
    url: `work/wallet/potential/earning`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryPotentialEarningsProps>) => {
    console.log("work/wallet/potential/earning", response);

    return {
      earnings: {
        totalPotentialEarnings: bnOrZero(response.result.earnings.total),
      }
    };
  },
})

export interface SubmitProofProps {
  objectiveId: string;
  attemptId: string;
  proofBody: FormData
}
export const submitProof = <A extends string>(builder: WorkerApiBuilder<A>) => builder.mutation({
  invalidatesTags: ["tasks"],
  query: (props: SubmitProofProps) => ({
    url: `work/task/attempt/${props.attemptId}/objective/${props.objectiveId}/proof`,
    method: "POST",
    body: props.proofBody,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<TaskModel>>) => {
    console.log("work/task/attempt/:attemptId/objective/:objectiveId/submit", response);
    return response.result;
  },
})

export interface WithdrawRequestProps {
  amount: string;
  currency: string;
}
export const withdrawRequest = <A extends string>(builder: WorkerApiBuilder<A>) => builder.mutation({
  invalidatesTags: ["withdraw", 'withdraws', 'workerWallets'],
  query: (props: WithdrawRequestProps) => ({
    url: `work/withdraw/request`,
    method: "POST",
    body: props,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<WithdrawRequestModel>>) => {
    console.log("work/withdraw/request", response);
    const result = response.result;
    result.model = transformWithdrawRequest(result.model);
    return result;
  },
})

export const withdrawCancel = <A extends string>(builder: WorkerApiBuilder<A>) => builder.mutation({
  invalidatesTags: ["withdraw", "withdraws", 'workerWallets'],
  query: (withdrawRequestId: string) => ({
    url: `work/withdraw/${withdrawRequestId}/cancel`,
    method: "POST",
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<WithdrawRequestModel>>) => {
    console.log("work/withdraw/:withdrawRequestId/cancel", response);
    const result = response.result;
    result.model = transformWithdrawRequest(result.model);
    return result;
  },
})

export const withdrawDetail = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["withdraw"],
  query: (withdrawRequestId: string) => ({
    url: `work/withdraw/${withdrawRequestId}/detail`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultModel<WithdrawRequestModel>>) => {
    console.log("work/withdraw/:withdrawRequestId/detail", response);
    const result = response.result;
    result.model = transformWithdrawRequest(result.model);
    return result;
  },
})

export interface WithdrawListProps {
  meta?: Partial<Meta>;
}
export const withdrawList = <A extends string>(builder: WorkerApiBuilder<A>) => builder.query({
  providesTags: ["withdraws"],
  query: (props?: WithdrawListProps) => ({
    url: `work/withdraw/list?${parseMeta(props?.meta)}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryResultEntries<WithdrawRequestModel[]>>) => {
    console.log("work/withdraw/list", response);
    const result = response.result;
    result.entries = result.entries.map(transformWithdrawRequest);
    return result;
  },
})
