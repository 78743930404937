import React, { useEffect, useState } from "react";
import { Box, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { AffiliatePageImage, AffiliatePageImageComponent } from "src/main/types";
import { useFormState } from "src/main/hooks";
import { InputField } from "src/main/components";
import { bnOrZero, createStyles, SimpleMap } from "src/main/utils";
import { ImageTemplate } from "./components";
import { AffImageCompWithErr } from "src/main/views/dashboard/management/Main/AffiliatePage/AffiliatePageCreate/AffiliatePageCreate";


interface ImageComponentsProps extends React.PropsWithChildren {
  affiliateComps?: AffImageCompWithErr | null;
  onCompnentChange: (comp: AffImageCompWithErr) => void;
  maxComps?: number;
  indexBypass?: number;
  rowIndex: number;
  aspectRatio?: number;
}

type AffiliateButtonType = AffiliatePageImageComponent & { inUse: boolean[] }

const initialFormState: AffiliateButtonType = {
  type: "image",
  marginBottom: 0,
  marginTop: 0,
  images: [],
  inUse: [],
}

const WIDTH_OPTIONS = [
  { value: "none", label: "None" },
  { value: "xl", label: "Extra-Large (1536px)" },
  { value: "lg", label: "Large (1200px)" },
  { value: "md", label: "Medium (900px)" },
  { value: "sm", label: "Small (600px)" },
  { value: "xs", label: "Extra-Small (444px)" },
]

const ImageComponents: React.FC<ImageComponentsProps> = (props) => {
  const { rowIndex, affiliateComps, onCompnentChange, maxComps = 3, indexBypass = 1, aspectRatio = 1 } = props;

  const [formStates, { setInput }] = useFormState(initialFormState);
  const [errors, setErrors] = useState<SimpleMap<string>>({});

  const [errorArr, setErrorArr] = useState<SimpleMap<SimpleMap<string>>>({});

  useEffect(() => {
    if (affiliateComps) {

      let newImages: AffiliatePageImage[] = [];
      affiliateComps.images.forEach((img) => {
        let newImg = { ...img };
        newImg.aspectRatio = aspectRatio;
        newImages.push(newImg)
      })
      const newInused = affiliateComps.images.map((a) => !!a)
      while (newImages.length <= maxComps) {
        newImages.push({ type: "image", src: "", aspectRatio: aspectRatio });
        newInused.push(false);
      }

      newImages.forEach((img) => {
        if (!img.aspectRatio) img.aspectRatio = aspectRatio;
      })

      setInput((prev) => {
        return {
          ...prev,
          marginBottom: affiliateComps.marginBottom,
          marginTop: affiliateComps.marginTop,
          maxWidth: affiliateComps.maxWidth,
          images: newImages,
          inUse: newInused
        }
      })
    } else {
      const newImages: AffiliatePageImage[] = [];
      const inUsed: boolean[] = [];
      [...Array(maxComps)].forEach(() => {
        newImages.push({ type: "image", src: "", aspectRatio: aspectRatio });
        inUsed.push(false);
      })

      setInput((prev) => {
        return {
          ...prev,
          images: newImages,
          inUse: inUsed
        }
      })
    }
    // eslint-disable-next-line
  }, [])


  const onSetError = (map: SimpleMap<number>) => {

    return (err: SimpleMap<string>) => {
      let imgArrErrors: SimpleMap<SimpleMap<string>> = {}

      Object.entries(err).forEach(([key, value]) => {
        if (key.includes("images[")) {
          let [front, target] = key.split(".");

          const curIndex = parseInt(front.replace("images[", "").replace("]", ""));

          Object.entries(map).forEach(([key, val]) => {
            if (val === curIndex) {
              imgArrErrors[key] = {
                [target]: value
              }
            }
          })
        } else {
          setErrors((prev) => ({ ...prev, [key]: value }));
        }
      })

      setErrorArr(imgArrErrors)
    }
  }

  useEffect(() => {
    const { input } = formStates;
    if (input) {
      const newImages: AffiliatePageImage[] = [];
      let indexMap: SimpleMap<number> = {};
      let indexCounter = 0;
      input.inUse.forEach((bol, ind) => {
        if (bol) {
          indexMap[`${ind}`] = indexCounter;
          indexCounter++;
          newImages.push(input.images[ind]);
        }
      })
      onCompnentChange({
        type: "image",
        marginBottom: input.marginBottom,
        marginTop: input.marginTop,
        maxWidth: input.maxWidth,
        images: newImages,
        updateErr: onSetError(indexMap)
      });
    }
    // eslint-disable-next-line
  }, [formStates.input, formStates.input.images])

  return (
    <Box pt={2}>
      <Typography variant="h5">Row {rowIndex}</Typography>
      <Box pt={2} display="flex" gap={2}>
        <InputField
          label="Margin Top"
          type="number"
          value={formStates.input.marginTop}
          error={!!errors.marginTop}
          helperText={errors.marginTop}
          InputProps={{ endAdornment: (<InputAdornment position="end">px</InputAdornment>) }}
          onChange={(e) => setInput(inputs => ({
            ...inputs,
            marginTop: bnOrZero(e.target.value).toNumber(),
          }))}
        />
        <InputField
          label="Margin Bottom"
          type="number"
          value={formStates.input.marginBottom}
          error={!!errors.marginBottom}
          helperText={errors.marginBottom}
          InputProps={{ endAdornment: (<InputAdornment position="end">px</InputAdornment>) }}
          onChange={(e) => setInput(inputs => ({
            ...inputs,
            marginBottom: bnOrZero(e.target.value).toNumber(),
          }))}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel shrink>Max Width</InputLabel>
          <Select
            input={<OutlinedInput notched label="MaxWidth" />}
            value={formStates.input.maxWidth ?? "none"}
            sx={styles.select}
            error={!!errors.maxWidth}
            onChange={(ev) => setInput(inputs => ({
              ...inputs,
              maxWidth: ev.target.value === "none" ? undefined : ev.target.value,
            }))}
          >
            {WIDTH_OPTIONS.map((opt) => (
              <MenuItem value={opt.value}>{opt.label}</MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!errors.maxWidth}>{errors.maxWidth}</FormHelperText>
        </FormControl>
      </Box>
      <Box>
        {[...Array(maxComps)].map((ind, index) => (
          <ImageTemplate
            title={`Image ${index + indexBypass}`}
            inputKey={`images`}
            imageContent={formStates.input.images[index]}
            setInput={setInput}
            usable={!!formStates.input.inUse?.[index]}
            index={index}
            errors={errorArr[index]}
            updateErrorArr={setErrorArr}
            toggleDisable={() => setInput((prev) => {
              let newPrev = { ...prev }
              newPrev.inUse[index] = !newPrev.inUse[index];
              return newPrev;
            })}
          />
        ))}
      </Box>
    </Box>
  )
};


const styles = createStyles({
  root: {},
  select: {
    paddingY: "12px",
    paddingX: "10px",
    '& .MuiSelect-select': {
      p: 0,
      justifyContent: "center",
    }
  }
})

export default ImageComponents;
