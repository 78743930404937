
import { BuilderProp, require200Status } from "src/main/api/account";
import { Meta, QueryResult, WalletTxModel } from "src/main/types";
import { parseMeta } from "src/main/utils/parseMeta";

export interface MetaRequest {
  meta: Meta;
}

export interface ListWalletTxResponse {
  meta: Meta;
  entries: WalletTxModel[];
}

export interface GetWalletTxRequest {
  walletTxId: string
}

export interface GetWalletTxResponse {
  model: WalletTxModel;
}


export const listWalletTx = (builder: BuilderProp) => {
  return builder.query<ListWalletTxResponse, MetaRequest>({
    providesTags: ["walletTx"],
    query: ({ meta }) => ({
      url: `/account/wallet/tx/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListWalletTxResponse>) => {
      return response.result ?? null;
    }
  })
}

export const getWalletTxDetail = (builder: BuilderProp) => {
  return builder.query<GetWalletTxResponse, GetWalletTxRequest>({
    providesTags: ["walletTx"],
    query: ({ walletTxId }) => ({
      url: `/account/wallet/tx/${walletTxId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetWalletTxResponse>) => {
      return response.result ?? null;
    }
  })
}