import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DateDisplay, ListingComp, MonoLabel, Table, WorkerNameDisplay } from "src/main/components";
import StatusChip from 'src/main/components/StatusChip';
import { Paths, truncate } from "src/main/utils";
import { Meta, TaskAttemptModel } from 'src/main/types';

interface TaskAttemptProps extends React.PropsWithChildren {
  taskId?: string;
  workerId?: string;
  sx?: SxProps;
  defaultStatus?: string;
}

const TaskHeaders = [
  { value: "Task" },
  { value: "Worker" },
  { value: "Submitted Date" },
  { value: "Status" },
  { value: "" },
]

const TASK_ATTEMPT_STATUS_OPTION = [
  { value: "all", label: "All" },
  { value: "in-progress", label: "In-Progress" },
  { value: "submitted", label: "Submitted" },
  { value: "cancelled", label: "Cancelled" },
  { value: "approved", label: "Approved" },
  { value: "terminated", label: "Terminated" },
  { value: "rejected", label: "Rejected" }
]

const TaskAttempt: React.FC<TaskAttemptProps> = (props) => {
  const { defaultStatus, taskId, workerId, sx } = props;
  const [meta, setMeta] = useState<Meta>({ offset: 0, limit: 10, count: 0 });

  useEffect(() => {
    let newMeta = { ...meta };

    if (taskId) newMeta.task = taskId;
    if (workerId) newMeta.worker = workerId;
    if (defaultStatus) newMeta.status = defaultStatus;

    setMeta(newMeta);
    // eslint-disable-next-line 
  }, [taskId, workerId, defaultStatus])

  const result = accountApi.useListTaskAttemptQuery({ meta }, { refetchOnMountOrArgChange: true });
  const taskAttempts = useMemo(() => result.data ?? undefined, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])

  const onStatusUpdate = (status: string) => {
    let newMeta = { ...persistMeta };
    delete newMeta.status;
    delete newMeta.rejectReason;
    if (status === "all") {
    } else if (status === "rejected") {
      newMeta.rejectReason = "-null";
      newMeta.status = "in-progress"
    } else {
      newMeta.status = status
    }

    setMeta({ ...newMeta, offset: 0 });
  }

  const getStatus = (attempt: TaskAttemptModel) => {
    if (attempt.status === "in-progress" && !!attempt.rejectReason) return "rejected";
    return attempt.status;
  }

  return (
    <ListingComp sx={sx} loading={result.isLoading} title="Submissions" updateList={(newMeta) => { setMeta(newMeta) }} meta={persistMeta}>
      <ListingComp.FilterSection>
        <ListingComp.Searchbar />
        <ListingComp.Statusbar defaultSelected={defaultStatus} options={TASK_ATTEMPT_STATUS_OPTION} onStatusUpdate={onStatusUpdate} />
      </ListingComp.FilterSection>
      <ListingComp.Content>
        <Table>
          <Table.Head headers={TaskHeaders} />
          <Table.Body>
            {taskAttempts?.entries.map((taskAttempt) => (
              <Table.Row>
                <Table.Cell value={
                  <Box>
                    <Typography>{taskAttempt.task?.title}</Typography>
                    <MonoLabel>{truncate(taskAttempt.task?.id, 8)}</MonoLabel>
                  </Box>
                } />
                <Table.Cell value={
                  <Box>
                    <Typography><WorkerNameDisplay person={taskAttempt.worker?.person} /></Typography>
                    <MonoLabel>{truncate(taskAttempt.worker?.id, 8)}</MonoLabel>
                  </Box>
                } />
                <Table.Cell value={<DateDisplay value={taskAttempt.submittedAt} format="YYYY-MM-DD HH:mm:ss" />} />
                <Table.Cell value={
                  <StatusChip status={getStatus(taskAttempt)} />
                } />
                <Table.Cell align="right" value={(
                  <IconButton
                    component={RouterLink}
                    to={Paths.Management.TaskSubmission.replace(":submissionId", taskAttempt.id)}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                )} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ListingComp.Content>
    </ListingComp>
  );
};

export default TaskAttempt;
