import moment, { Moment } from "moment";
import preval from "preval.macro";
import { Variables } from "./type";

export const reduceVariables = (
  variables: Variables,
  accessors: string[] = []
): [string, string][] => {
  const pairs: [string, string][] = [];
  for (const key in variables) {
    const a = accessors.concat(key);
    const v = variables[key];
    if (typeof v === "number" || typeof v === "string") {
      pairs.push([a.join("."), v]);
    } else if (v instanceof moment) {
      pairs.push([a.join("."), moment(v).format("YYYY-MM-DD HH:mm:ss")]);
    } else {
      const p = reduceVariables(v, a);
      pairs.push(...p);
    }
  }
  return pairs;
};

export const wait = (duration: number) => {
  return new Promise((res) => setTimeout(res, duration));
};

export const parseMoment = <T = Moment>(
  input: undefined | null | number | string | Date | Moment,
  defaultValue?: T
): Moment | T | undefined => {
  if (input === null || input === undefined) return defaultValue;
  if (moment.isMoment(input)) return input as Moment;
  if (typeof input === "number") return moment.unix(input);
  if (typeof input === "string" && input.match(/^\d{0,10}$/))
    return moment.unix(parseInt(input));
  try {
    return moment(input);
  } catch (error) {
    console.warn("parse moment failed");
    console.warn(error);
    return undefined;
  }
};

export enum DeployEnv {
  Production = "production",
  Staging = "staging",
  Local = "local",
}
export interface AppEnv {
  deploy: DeployEnv | null;
  api: DeployEnv | null;
  build: string;
}
export const getDeployEnv = () => {
  const hostname = window.location.hostname ?? "";
  if (
    hostname === "portal.gigrewards.co" ||
    hostname === "portal.task4coins.com"
  ) {
    return DeployEnv.Production;
  } else if (hostname === "staging-portal.gigrewards.co") {
    return DeployEnv.Staging;
  } else if (hostname === "localhost") {
    return DeployEnv.Local;
  } else {
    return null;
  }
};
export const getApiEnv = () => {
  const apiEndpoint = process.env.REACT_APP_TASK_API_BASE_URI ?? "";
  if (apiEndpoint.startsWith("https://api.gigrewards.co")) {
    return DeployEnv.Production;
  } else if (apiEndpoint.startsWith("https://staging-task-api.gigrewards.co")) {
    return DeployEnv.Staging;
  } else if (apiEndpoint.startsWith("http://localhost:")) {
    return DeployEnv.Local;
  } else {
    return null;
  }
};
// evaluates at yarn build
const BUILD_TIMESTAMP: string = preval`module.exports = require('dayjs')().format('YYYYMMDD/HHmmss')`;
const appEnv: AppEnv = {
  deploy: getDeployEnv(),
  api: getApiEnv(),
  build: BUILD_TIMESTAMP,
};
export const getAppEnv = () => {
  return appEnv;
};
