import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { QueryTags } from "src/main/constants";
import { QueryFn } from "../../base";
import { QueryResult } from "./invite";
import { Meta, AccountInviteModel } from "src/main/types";
import { parseMeta } from "src/main/utils/parseMeta";


type AuthApiBuilder<A extends string> = EndpointBuilder<QueryFn, typeof QueryTags.Worker[number], A>;

export interface PendingNotificationQueryResponse {
  entries: AccountInviteModel[];
  meta: Meta;
}
export interface MetaRequest {
  meta: Meta;
}
export interface AcceptPendingInviteRequest {
  accountInviteId: string;
}
export const queryPendingNotification = <A extends string>(builder: AuthApiBuilder<A>) => {
  return builder.query<PendingNotificationQueryResponse, MetaRequest>({
    providesTags: ["pendingInvite"],
    query: ({ meta }) => ({
      url: `self/account/invitations?${parseMeta(meta)}`,
      validateStatus: (response: Response) => response.status === 200,
    }),
    transformResponse: (response: QueryResult<PendingNotificationQueryResponse>) => {
      //console.log("self/invitations", response);
      return response.result;
    },
  })
}
export const acceptPendingNotification = <A extends string>(builder: AuthApiBuilder<A>) => {
  return builder.mutation<AccountInviteModel, AcceptPendingInviteRequest>({
    invalidatesTags: ["pendingInvite"],
    query: ({ accountInviteId }) => ({
      url: `self/account/invite/${accountInviteId}/accept`,
      method: "POST",
      validateStatus: (response: Response) => response.status === 200,
    }),
    transformResponse: (response: QueryResult<AccountInviteModel>) => {
      //console.log("self/account/:accountInviteId/accept", response);
      return response.result;
    }
  })
}
