import { Box, BoxProps, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { TerminateTaskAttemptRequest } from "src/main/api/account/Task";
import { ActionButton, InputField, LoadingButton } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { TaskAttemptModel } from "src/main/types";
import { createStyles, joinSx } from "src/main/utils";

interface TerminateContentProps extends BoxProps {
  taskAttempt: TaskAttemptModel;
  onClose: () => void;
  onCancel: () => void;
}

const TerminateContent: React.FC<TerminateContentProps> = (props) => {
  const { taskAttempt, onCancel, onClose, sx } = props;

  const [runTerminateAttempt] = useAsyncTask("termiate/attempt");
  const [terminateTaskAttempt, { isLoading: isTerminateLoading }] = accountApi.useTerminateTaskAttemptMutation();
  const [reason, setReason] = React.useState<string>("");

  const handleConfirm = useCallback(() => {
    runTerminateAttempt(async () => {
      if (!taskAttempt) return;

      let terminateContent = {
        attemptId: taskAttempt.id,
      } as TerminateTaskAttemptRequest;

      if (!!reason) {
        terminateContent.data = { reason };
      }

      const terminated = await terminateTaskAttempt(terminateContent);

      if ("data" in terminated) {
        toast.success("Task submission terminated!");
        onClose?.();
      } else {
        toast.error((terminated.error as any).data?.error?.message)
        throw new Error((terminated.error as any).data?.error?.message)
      }
    })
  }, [taskAttempt, onClose, terminateTaskAttempt, runTerminateAttempt, reason])


  return (
    <>
      <DialogTitle>Terminate Submission?</DialogTitle>
      <DialogContent sx={joinSx(styles.root, sx)}>
        <Typography>This submission will be terminated.</Typography>

        <Box display="flex" p={1}>
          <InputField
            multiline
            label="Terminate reason"
            value={reason}
            onChange={(ev) => setReason(ev.target.value)}
          />
        </Box>

        <Box sx={styles.buttonBox}>
          <ActionButton disabled={isTerminateLoading} color="inherit" onClick={onCancel}>Cancel</ActionButton>
          <LoadingButton
            fullWidth
            variant="contained"
            loading={isTerminateLoading} onClick={handleConfirm}>Confirm</LoadingButton>
        </Box>
      </DialogContent>
    </>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 2
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    mt: 3,
  }
})

export default TerminateContent;
