import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { ListingComp, MonoLabel, Table, WorkerNameDisplay } from "src/main/components";
import { AccountVariableModel, Meta } from "src/main/types";
import { Paths, SimpleMap, truncate } from "src/main/utils";

interface AffiliateTrackerListCompProps extends React.PropsWithChildren {
  brandKey?: string;
  affiliatePageKey?: string;
}

const HEADERS = [
  { value: "ID" },
  { value: "Brand" },
  { value: "Affiliate Page Key" },
  { value: "Worker" },
  { value: "Clicks" },
  { value: "Views" },
  { value: "" }
]

const getInitialMeta = (affiliatePageKey?: string) => {
  let meta: Meta = { count: 0, limit: 10, offset: 0 };
  if (affiliatePageKey) {
    meta.page = affiliatePageKey;
  }
  return meta;
}

const AffiliateTrackerListComp: React.FC<AffiliateTrackerListCompProps> = (props) => {
  const [meta, setMeta] = useState<Meta>(getInitialMeta(props.affiliatePageKey));
  const result = accountApi.useListAffiliateTrackerQuery({ meta });
  const affiliateTrackerList = useMemo(() => result.data ?? null, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...affiliateTrackerList?.meta }), [affiliateTrackerList, meta])

  const accVariableResult = accountApi.useListAccountVariableQuery({ meta: { count: 0, limit: 1000, offset: 0 } });
  const accountVariables: AccountVariableModel<any>[] = useMemo(() => accVariableResult.data?.entries ?? [], [accVariableResult.data?.entries]);

  const brandKeyOptions = useMemo(() => {
    if (!accountVariables) return {};

    let newOptions: SimpleMap<string> = {};

    accountVariables.forEach((accVar) => {
      if ("items" in accVar.content) {
        accVar.content.items.forEach((item: SimpleMap<string>) => {
          newOptions[item.key] = item.label;

          // newOptions.push({ value: item.key ?? "", label: item.label ?? "", key: item.key ?? "" });
        })
      }
    })

    return newOptions
  }, [accountVariables])

  return (
    <ListingComp
      loading={result.isLoading} title="Affiliates"
      updateList={(newMeta) => { setMeta(newMeta) }}
      meta={persistMeta}
    >
      <Box p={1}></Box>
      <ListingComp.Content>
        <Table>
          <Table.Head headers={HEADERS} />
          <Table.Body>
            {affiliateTrackerList?.entries.map((affiliate) => (
              <Table.Row key={`affiliate-tracker-${affiliate.id}`}>
                <Table.Cell><MonoLabel>{truncate(affiliate.id, 8)}</MonoLabel></Table.Cell>
                <Table.Cell>{brandKeyOptions[affiliate.brandKey] ?? "-"}</Table.Cell>
                <Table.Cell>{affiliate.affiliatePage?.affiliatePageKey}</Table.Cell>
                <Table.Cell><WorkerNameDisplay person={affiliate.worker?.person} /></Table.Cell>
                <Table.Cell>{affiliate.totalVisit?.click ?? "-"}</Table.Cell>
                <Table.Cell>{affiliate.totalVisit?.view ?? "-"}</Table.Cell>
                <Table.Cell align="right">
                  <IconButton
                    component={RouterLink}
                    to={Paths.Management.AffiliateTrackerDetail.replaceAll(":affiliateTrackerId", affiliate.id)}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ListingComp.Content>
    </ListingComp>
  );
};

export default AffiliateTrackerListComp;
