import { Box, CardProps, Typography } from "@mui/material";
import React, { useCallback } from "react";
import {
  DetailCard,
  LoadingButton,
  Table,
  TableRowWrap,
} from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { WorkerModel } from "src/main/types";
import OnboardInfo from "../OnboardInfo";
import CredentialInfo from "../CredentialInfo";
import { accountApi } from "src/main/api";
import { useAsyncTask } from "src/main/hooks";
import { handleApiResponse } from "src/main/utils";
import { toast } from "react-hot-toast";

interface DetailInfoProps extends Omit<CardProps, "children"> {
  worker: WorkerModel;
}

const DetailInfo: React.FC<DetailInfoProps> = (props) => {
  const { worker } = props;
  const { person } = worker;

  const [generateWorkerAffiliate, { isLoading }] =
    accountApi.useGenerateWorkerAffiliateMutation();
  const [runGenerateAffiliate] = useAsyncTask("generate/affiliate/key");

  const onGenerate = useCallback(() => {
    runGenerateAffiliate(async () => {
      const result = await generateWorkerAffiliate(worker.id);

      const { data, error } = handleApiResponse<WorkerModel>(result);

      if (data) {
        toast.success("Affiliate key generated!");
      }
      if (error) {
        toast.error(
          error.data?.error.message ?? "Error generating affiliate key!"
        );
      }
    });
  }, [generateWorkerAffiliate, runGenerateAffiliate, worker.id]);

  const getWorkerStatus = (worker: WorkerModel) => {
    if (worker.onboardAt) return worker.status;
    if (worker.status === "suspended") return worker.status;
    return "pending";
  };

  console.log("worker", worker, person);

  return (
    <>
      <Box flex={1}>
        <DetailCard>
          <DetailCard.Header header="Personal Info" />
          <DetailCard.Divider />

          {person && (
            <Table>
              <Table.Body>
                <TableRowWrap label="Firstname" value={person.firstname} />
                <TableRowWrap label="Middlename" value={person.middlename} />
                <TableRowWrap label="Lastname" value={person.lastname} />
                <TableRowWrap label="Email" value={person.primaryEmail} />
                <TableRowWrap label="Phone" value={person.primaryPhone} />
                <TableRowWrap
                  label="GCash Account"
                  value={worker.gCashAccount}
                />
                <TableRowWrap label="Facebook" value={person.facebookHandle} />
                <TableRowWrap
                  label="Facebook (Alt)"
                  value={person.facebookAltHandle}
                />
                <TableRowWrap label="Discord" value={person.discordHandle} />
                <TableRowWrap
                  label="Instagram"
                  value={person.instagramHandle}
                />
                <TableRowWrap label="TikTok" value={person.tiktokHandle} />
                <TableRowWrap label="Twitter" value={person.twitterHandle} />
                <TableRowWrap label="Telegram" value={person.telegramHandle} />
                <TableRowWrap label="Gender" value={person.gender} />
                <TableRowWrap label="Salutation" value={person.salutation} />
              </Table.Body>
            </Table>
          )}
        </DetailCard>
      </Box>
      <Box flex={1}>
        <DetailCard>
          <DetailCard.Header header="Tasker Info" />
          <DetailCard.Divider />
          <Table>
            <Table.Body>
              <TableRowWrap
                label="Score"
                align="center"
                value={
                  !!worker.score || worker.score === 0 ? (
                    <Typography>{worker.score}</Typography>
                  ) : (
                    "-"
                  )
                }
              />
              <TableRowWrap
                label="Status"
                align="center"
                value={<StatusChip status={getWorkerStatus(worker)} />}
              />
              <TableRowWrap
                label="Affiliate Key"
                align="center"
                value={
                  !!worker.affiliateKey ? (
                    worker.affiliateKey
                  ) : (
                    <LoadingButton
                      loading={isLoading}
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => onGenerate()}
                    >
                      Generate Affiliate Key
                    </LoadingButton>
                  )
                }
              />

              <TableRowWrap label="Remark" align="left" value={worker.remark} />
            </Table.Body>
          </Table>
        </DetailCard>
        <Box mt={2}>
          <OnboardInfo worker={worker} />
        </Box>
        <Box mt={2}>
          <CredentialInfo credential={person.entity?.credentials?.[0]} />
        </Box>
      </Box>
    </>
  );
};

// const styles = createStyles({
//   root: {

//   },
// })

export default DetailInfo;
