import React, { useMemo } from "react";
import { accountApi } from "src/main/api";
import { GetAvailableTaskStatsResponse } from "src/main/api/account/Stats";
import { BigLabelCard } from "src/main/components";
import { Paths } from "src/main/utils";

interface AvailableTasksProps {
}
const AvailableTasks: React.FC<AvailableTasksProps> = (props) => {

  const result = accountApi.useGetAvailableTaskStatsQuery({});
  const listResult: GetAvailableTaskStatsResponse | undefined = useMemo(() => result.data ?? undefined, [result.data]);
  return (
    <BigLabelCard
      label="Available Tasks" routeLabel="See All Tasks"
      value={!!listResult?.active ? listResult.active + "" : "0"}
      route={Paths.Management.Tasks} />
  );
}

export default AvailableTasks;
