import { Box, CardProps, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";
import toast from "react-hot-toast";
import { accountApi } from "src/main/api";
import { DetailCard, LoadingButton, Table, TableRowWrap } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { WorkerModel } from "src/main/types";
import { createStyles, handleApiResponse, joinSx } from "src/main/utils";

interface OnboardInfoProps extends Omit<CardProps, "children"> {
  worker: WorkerModel
}

const OnboardInfo: React.FC<OnboardInfoProps> = (props) => {
  const { worker, sx, ...rest } = props;
  const [onboardWorker] = accountApi.useOnboardWorkerMutation();
  const [runSubmitOnboard, loading] = useAsyncTask("account/worker/onboard");

  const {
    onboardStatus
  } = useMemo(() => {
    const onboarded = moment.isMoment(worker.onboardAt);
    const isPending = typeof worker.onboardToken === "string";
    const onboardStatus = onboarded ? "Completed" : isPending ? "Pending registration" : "Not started";

    return { onboardStatus }
  }, [worker.onboardAt, worker.onboardToken]);

  const onSendOnboarding = (evt: React.MouseEvent<HTMLButtonElement>) => {
    runSubmitOnboard(async () => {
      const result = await onboardWorker(worker.id);
      const {error} = handleApiResponse(result);
      if (error?.data?.error) {
        throw new Error(error?.data?.error?.message);
      }
      toast.success("Onboarding instructions sent!");
    });
  }

  return (
    <DetailCard {...rest} sx={joinSx(styles.root, sx)}>
      <DetailCard.Header header="Onboarding" />
      <DetailCard.Divider />
      <Table>
        <Table.Body>
          <TableRowWrap label="Onboard status" align="center" value={onboardStatus} />

          {onboardStatus === "Not started" && (
            <TableRow>
              <TableCell colSpan={2} sx={{ padding: "16px 16px 16px 24px", maxWidth: "200px" }}>
                <Typography color="text.secondary" variant="body2">
                  Send an email to worker's registered email address <strong>{worker.person.primaryEmail}</strong>
                  with instructions for worker to register on this platform.
                </Typography>
                <Box mt={1} />
                <LoadingButton variant="contained" loading={loading} onClick={onSendOnboarding}>Send Instructions</LoadingButton>
              </TableCell>
            </TableRow>
          )}

          {onboardStatus === "Pending registration" && (
            <TableRow>
              <TableCell colSpan={2} sx={{ padding: "16px 16px 16px 24px", maxWidth: "200px" }}>
                <Typography color="text.secondary" variant="body2">
                  An email with registration link has been sent to <strong>{worker.person.primaryEmail}</strong>,
                  you may click on the button below to send another email.
                </Typography>
                <Box mt={1} />
                <LoadingButton variant="contained" loading={loading} onClick={onSendOnboarding}>Resend Instructions</LoadingButton>
              </TableCell>
            </TableRow>
          )}
        </Table.Body>
      </Table>
    </DetailCard>
  );
};

const styles = createStyles({
  root: {

  },
})

export default OnboardInfo;
