import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, Typography } from "@mui/material";
import moment from 'moment';
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { DateDisplay, ListingComp, Table } from "src/main/components";
import StatusChip from 'src/main/components/StatusChip';
import { Meta, TaskModel } from "src/main/types";
import { Paths, truncate } from "src/main/utils";


interface TaskListProps extends React.PropsWithChildren {
  teamId: string;
}

const TaskHeaders = [
  { value: "Task Name" },
  { value: "Payout" },
  { value: "Type" },
  { value: "Duration" },
  { value: "Limit" },
  { value: "Status" },
  { value: "Ongoing" },
  { value: "Submitted" },
  { value: "Completed" },
  { value: "" },
]

const STATUS_OPTIONS = [
  { value: "all", label: "All" },
  { value: "not-started", label: "Not Started" },
  { value: "ongoing", label: "Ongoing" },
  { value: "expired", label: "Expired" },
]

const VISIBILITY_OPTIONS = [
  { value: "all", label: "All" },
  { value: "published", label: "Published" },
  { value: "draft", label: "Draft" },
]

const checkStatus = (task: TaskModel) => {
  if (task.publishedAt && moment().isAfter(task.expiry)) return "expired";
  if (!task.publishedAt) return "draft"
  return "published";
}

const TaskList: React.FC<TaskListProps> = (props) => {
  const { teamId } = props;
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })

  const result = accountApi.useListTeamTaskQuery({ teamId, meta });
  const taskListResult = useMemo(() => result.data ?? null, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])

  const onStatusUpdate = (status: string) => {
    const newMeta = { ...persistMeta };
    delete newMeta.status;
    delete newMeta.start;
    delete newMeta.expiry;
    delete newMeta.ongoing;

    if (status === "all") { }
    else if (status === "not-started") {
      newMeta.start = `${moment().unix()},`;
    } else if (status === "ongoing") {
      newMeta.ongoing = "true";
    } else if (status === "expired") {
      newMeta.expiry = `0,${moment().unix()}`;
    }

    setMeta(newMeta);
  }

  const onVisibilityUpdate = (status: string) => {
    const newMeta = { ...persistMeta };
    delete newMeta.visibility;
    delete newMeta.publishedAt;

    if (status === "all") { }
    else if (status === "published") {
      newMeta.publishedAt = "0,";
    } else if (status === "draft") {
      newMeta.publishedAt = "null";
    }

    setMeta(newMeta);
  }

  return (
    <ListingComp
      loading={result.isLoading} title="Task List"
      updateList={(newMeta) => { setMeta(newMeta) }}
      meta={persistMeta}
    >
      <ListingComp.FilterSection>
        <ListingComp.Searchbar />
        <ListingComp.Statusbar defaultSelected="all" options={STATUS_OPTIONS} onStatusUpdate={onStatusUpdate} />
        <ListingComp.Statusbar defaultSelected="all" label="Visibility" options={VISIBILITY_OPTIONS} metaKey="visibility" onStatusUpdate={onVisibilityUpdate} />
        <ListingComp.DateLabelInput resetStatus={["status", "visibility"]} placeholder="Select start range" label="Start" dateChangeKey="start" />
        <ListingComp.DateLabelInput resetStatus={["status", "visibility"]} placeholder="Select expiry range" label="Expiry" dateChangeKey="expiry" />
      </ListingComp.FilterSection>
      <ListingComp.Content>
        <Table>
          <Table.Head headers={TaskHeaders} />
          <Table.Body>
            {taskListResult?.entries.map((task) => (
              <Table.Row key={`task-listing-${task.id}`}>
                <Table.Cell>
                  <Box>{task.title}</Box>
                  <Typography variant="caption">{truncate(task.id, 8, undefined, true)}</Typography>
                </Table.Cell>
                <Table.Cell>{task.payAmount?.toFormat(0) ?? "0"} {task.payCurrency ?? "USD"}</Table.Cell>
                <Table.Cell>{task.periodType ? "Recurring" : "One-time"}</Table.Cell>
                <Table.Cell>
                  <DateDisplay value={task.start} />
                  <DateDisplay value={task.expiry} />
                </Table.Cell>
                <Table.Cell align="center">{task.maxSubmission ?? "-"}</Table.Cell>
                <Table.Cell>
                  <StatusChip status={checkStatus(task)} />
                </Table.Cell>
                <Table.Cell align="center">{task.attemptStat?.statusInProgress ?? "-"}</Table.Cell>
                <Table.Cell align="center">{task.attemptStat?.statusSubmitted ?? "-"}</Table.Cell>
                <Table.Cell align="center">{task.attemptStat?.statusApproved ?? "-"}</Table.Cell>
                <Table.Cell align="right">
                  <IconButton
                    component={RouterLink}
                    to={Paths.Management.TasksDetail.replaceAll(":taskId", task.id)}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ListingComp.Content>
    </ListingComp>
  );
};

export default TaskList;
