import { createSvgIcon } from '@mui/material/utils';

const PencilAlt = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2011 3.298C16.0884 3.25191 15.9677 3.22871 15.846 3.22977C15.7243 3.23083 15.604 3.25612 15.4922 3.30416C15.3803 3.3522 15.2792 3.42203 15.1946 3.50958L15.1856 3.51896L8.25016 10.4542V11.7502H9.54617L16.4907 4.80567C16.5783 4.72111 16.6482 4.62004 16.6962 4.50821C16.7443 4.39637 16.7696 4.27608 16.7706 4.15437C16.7717 4.03266 16.7485 3.91195 16.7024 3.79929C16.6563 3.68664 16.5882 3.58429 16.5022 3.49822C16.4161 3.41215 16.3137 3.34409 16.2011 3.298ZM15.833 1.72983C16.1539 1.72704 16.4721 1.78819 16.7691 1.9097C17.0661 2.03121 17.3359 2.21065 17.5628 2.43756C17.7897 2.66447 17.9692 2.93429 18.0907 3.23129C18.2122 3.52829 18.2733 3.84652 18.2706 4.1674C18.2678 4.48829 18.2011 4.8054 18.0744 5.10024C17.9487 5.3929 17.7664 5.6578 17.538 5.87972L10.3872 13.0305C10.2465 13.1712 10.0557 13.2502 9.85683 13.2502H7.50016C7.08595 13.2502 6.75016 12.9144 6.75016 12.5002V10.1436C6.75016 9.94464 6.82918 9.75387 6.96983 9.61322L14.1206 2.46241C14.3426 2.23398 14.6075 2.05166 14.9001 1.92594C15.195 1.79928 15.5121 1.73262 15.833 1.72983ZM3.29132 4.12471C3.74453 3.6715 4.35922 3.41688 5.00016 3.41688H9.16683C9.58104 3.41688 9.91683 3.75267 9.91683 4.16688C9.91683 4.5811 9.58104 4.91688 9.16683 4.91688H5.00016C4.75705 4.91688 4.52389 5.01346 4.35198 5.18537C4.18007 5.35728 4.0835 5.59043 4.0835 5.83355V15.0002C4.0835 15.2433 4.18007 15.4765 4.35198 15.6484C4.52389 15.8203 4.75705 15.9169 5.00016 15.9169H14.1668C14.4099 15.9169 14.6431 15.8203 14.815 15.6484C14.9869 15.4765 15.0835 15.2433 15.0835 15.0002V10.8336C15.0835 10.4193 15.4193 10.0836 15.8335 10.0836C16.2477 10.0836 16.5835 10.4193 16.5835 10.8336V15.0002C16.5835 15.6412 16.3289 16.2558 15.8757 16.7091C15.4225 17.1623 14.8078 17.4169 14.1668 17.4169H5.00016C4.35922 17.4169 3.74453 17.1623 3.29132 16.7091C2.83811 16.2558 2.5835 15.6412 2.5835 15.0002V5.83355C2.5835 5.19261 2.83811 4.57792 3.29132 4.12471Z" fill="currentColor" />
    </svg>
    ,
    'PencilAlt'
);

export default PencilAlt;
