import { FiberManualRecord, Done, Clear } from "@mui/icons-material";
import {
  Chip,
  ChipPropsColorOverrides,
  SvgIconPropsColorOverrides,
} from "@mui/material";
import React, { useMemo } from "react";
import { TaskAttemptModel } from "src/main/types";
import { OverridableStringUnion } from "@mui/types";
import { SimpleMap, createStyles, IntlFormatter } from "src/main/utils";

interface AttemptStatusChipProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
  isExpired?: boolean;
}

type IconOverride =
  | OverridableStringUnion<
      | "warning"
      | "success"
      | "primary"
      | "error"
      | "disabled"
      | "action"
      | "inherit"
      | "secondary"
      | "info",
      SvgIconPropsColorOverrides
    >
  | undefined;
type ChipOverride =
  | OverridableStringUnion<
      | "default"
      | "warning"
      | "success"
      | "primary"
      | "error"
      | "secondary"
      | "info",
      ChipPropsColorOverrides
    >
  | undefined;

const AttemptStatusChip: React.FC<AttemptStatusChipProps> = (props) => {
  const { attempt, isExpired } = props;
  // eslint-disable-next-line
  const attemptStatusLabel = {
    "to-resubmit": IntlFormatter.getMessage(
      "attempStatusChip.to_resubmit",
      "To Resubmit"
    ),
    "in-progress": IntlFormatter.getMessage(
      "attempStatusChip.in_progress",
      "In-Progress.."
    ),
    submitted: IntlFormatter.getMessage(
      "attempStatusChip.under_review",
      "Under Review..."
    ),
    approved: IntlFormatter.getMessage("attempStatusChip.approved", "Approved"),
    terminated: IntlFormatter.getMessage(
      "attempStatusChip.terminated",
      "Terminated"
    ),
    expired: IntlFormatter.getMessage("attempStatusChip.expired", "Expired"),
    cancelled: IntlFormatter.getMessage(
      "attempStatusChip.cancelled",
      "Cancelled"
    ),
  };

  const default_obj = useMemo(() => {
    let default_obj: SimpleMap<string | IconOverride | ChipOverride> = {};
    if (isExpired) {
      default_obj.color = "error";
      default_obj.text = attemptStatusLabel["expired"];
      return default_obj;
    }
    default_obj.text = attemptStatusLabel[attempt?.status || ""];

    switch (attempt?.status) {
      case "in-progress":
        if (!!attempt?.rejectReason) {
          default_obj.color = "warning";
          if (!!attempt?.rejectReason) {
            default_obj.text = attemptStatusLabel["to-resubmit"];
          }
          break;
        }
        default_obj.color = "primary";
        break;
      case "submitted":
      case "approved":
        default_obj.color = "success";
        break;
      case "cancelled":
      case "terminated":
      case "expired":
        default_obj.color = "error";
        break;
    }

    return default_obj;
  }, [attempt, attemptStatusLabel, isExpired]);

  const icon = useMemo(() => {
    switch (attempt?.status) {
      case "approved":
        return (
          <Done
            style={{ fontSize: "0.9rem" }}
            color={default_obj.color as IconOverride}
          />
        );
      case "terminated":
      case "cancelled":
      case "expired":
        return (
          <Clear
            style={{ fontSize: "0.9rem" }}
            color={default_obj.color as IconOverride}
          />
        );
      default:
        return (
          <FiberManualRecord
            style={{ fontSize: "0.6rem" }}
            color={default_obj.color as IconOverride}
          />
        );
    }
  }, [attempt, default_obj]);

  const chipStyle = useMemo(() => {
    switch (attempt?.status) {
      case "approved":
      case "terminated":
      case "cancelled":
      case "expired":
        return styles.borderless;
      default:
        return styles.chip;
    }
  }, [attempt]);

  if (!attempt) return null;

  return (
    <Chip
      icon={icon}
      size="small"
      label={default_obj.text}
      variant="outlined"
      color={default_obj.color as ChipOverride}
      sx={chipStyle}
    />
  );
};

const styles = createStyles({
  chip: {},
  borderless: {
    border: 0,
    pl: 0,
  },
});

export default AttemptStatusChip;
