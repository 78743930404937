import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { LoadingContainer, Page } from "src/main/components";
import { useParams } from "react-router-dom";
import { accountApi } from "src/main/api";
import { WalletTxListing, WalletInfoCard } from "./components";
import { Paths } from "src/main/utils";

interface WalletDetailPageProps extends React.PropsWithChildren {
}

const WalletDetailPage: React.FC<WalletDetailPageProps> = (props) => {
  const { workerId = "", walletId = "" } = useParams();
  const result = accountApi.useGetWorkerWalletDetailQuery({ workerId, walletId });
  const walletDetail = useMemo(() => result?.data?.model ?? undefined, [result.data?.model]);

  return (
    <Page>
      <Page.TopSection
        title="Wallet Detail"
        crumbs={[
          { label: "Worker Details", path: Paths.Management.WorkerDetail.replace(":workerId", workerId) + "?tab=wallets" },
          { label: "detail" }
        ]}
      />
      <LoadingContainer loading={result.isLoading}>
        {walletDetail && (
          <Box display="flex" flexDirection="column">
            <Box display="flex" flex={1} p={2} pb={0}>
              <WalletInfoCard wallet={walletDetail} sx={{ flex: 1, maxWidth: { md: 400 } }} />
            </Box>
            <Box>
              <WalletTxListing walletId={walletDetail.id} />
            </Box>
          </Box>
        )}
      </LoadingContainer>
    </Page>
  );
};

export default WalletDetailPage;
