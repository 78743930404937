import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { DateDisplay, ListingComp, LoadingButton, Page, Table } from "src/main/components";
import { Link as RouterLink } from "react-router-dom";
import { exportList, Paths, truncate } from "src/main/utils";
import { accountApi } from 'src/main/api';
import { QueryWorkerResponse } from "src/main/api/account/Worker";
import StatusChip from "src/main/components/StatusChip";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Meta, WorkerModel } from "src/main/types";
import { useAsyncTask } from "src/main/hooks";
import moment from "moment";


interface WorkerExportPageProps extends React.PropsWithChildren {

}

const WORKER_LIST_HEADERS = [
  { value: "Worker" },
  { value: "Date Joined" },
  // { value: "Total Payout" },
  // { value: "Total Jobs Accepted" },
  { value: "Total Submitted" },
  { value: "Total Rejected" },
  { value: "Completed" },
  { value: "Telegram" },
  { value: "Status" },
  { value: "Current Ongoing" },
  // { value: "Current Pending Approval" },
  { value: "" }
]

const STATUS_OPTION = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "onboarded", label: "Onboarded" },
  { value: "pendingonboard", label: "Pending" },
  { value: "suspended", label: "Suspended" },
]

const WorkerExportPage: React.FC<WorkerExportPageProps> = () => {
  //TODO: customized sort
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 100000, offset: 0 })

  const [listWorkerRequest, { isLoading: exportListLoading }] = accountApi.useLazyListWorkerQuery();
  const [runListWorkers] = useAsyncTask("list/workers");

  const result = accountApi.useListWorkerQuery({ meta });
  const workerListResult: QueryWorkerResponse | undefined = useMemo(() => result.data ?? undefined, [result.data]);

  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta])


  const getWorkerStatus = (worker: WorkerModel) => {
    if (worker.onboardAt) return worker.status;
    if (worker.status === "suspended") return worker.status;
    return "pending";
  }

  const onStatusUpdate = (status: string) => {
    let newMeta = { ...persistMeta };
    delete newMeta.onboardAt;
    delete newMeta.status;
    if (status === "all") {
    } else if (status === "pendingonboard") {
      newMeta.onboardAt = "null"
    } else if (status === "onboarded") {
      newMeta.onboardAt = "0,"
    } else if (status === "suspended") {
      newMeta.status = status
    } else {
      // newMeta.onboardAt = "0,"
      newMeta.status = status
    }

    setMeta({ ...newMeta, offset: 0 });
  }

  const onExport = useCallback(() => {
    runListWorkers(async () => {
      const exportResult = await listWorkerRequest({ meta: { ...persistMeta, limit: 100000 } });

      const entries = exportResult.data?.entries ?? [];

      const newEntries = entries.map((ent) => {
        const newEnt = { ...ent };

        if (newEnt.status === "active" && !newEnt.onboardAt) {
          newEnt.status = "pending";
        }

        return newEnt;
      })

      exportList(newEntries, [
        { key: "id" },
        { key: "person.firstname" },
        { key: "person.middlename" },
        { key: "person.lastname" },
        { key: "affiliateKey" },
        { key: "onboardAt", type: "date" },
        { key: "person.primaryEmail" },
        { key: "status" },
        { key: "score" },
        { key: "lastSeenAt", type: "date" },
        { key: "person.facebookHandle" },
        { key: "person.telegramHandle" },
        { key: "person.tiktokHandle" },
        { key: "person.twitterHandle" },
        { key: "person.discordHandle" },
        { key: "person.instagramHandle" },
      ],
        ["Tasker ID", "Firstname", "Middlename", "Lastname", "Affiliate Key", "Date Joined", "Email", "Status", "Score", "Last Active", "Facebook", "Telegram", "Tik Tok", "Twitter", "Discord", "Instagram"],
        `Taskers_${moment().format("YYYY-MM-DD")}.csv`
      )
    })

  }, [persistMeta, listWorkerRequest, runListWorkers]);

  return (
    <Page>
      <Page.TopSection title="Export Taskers">
        <LoadingButton
          onClick={() => onExport()}
          size="large"
          loading={exportListLoading}
          variant="contained"
        >
          <Typography color="#fff">Export</Typography>
        </LoadingButton>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading} title="Taskers"
        updateList={(newMeta) => { setMeta(newMeta) }}
        meta={persistMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar />
          <ListingComp.Statusbar options={STATUS_OPTION} onStatusUpdate={onStatusUpdate} />
        </ListingComp.FilterSection>
        <ListingComp.Content>
          <Table>
            <Table.Head headers={WORKER_LIST_HEADERS} />
            <Table.Body>
              {workerListResult?.entries.map((worker) => (
                <Table.Row>
                  <Table.Cell
                    value={(
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="body1">{worker.person.firstname}</Typography>
                        <Typography variant="body2" color="text.secondary">{worker.person.primaryEmail}</Typography>
                      </Box>
                    )}
                  />
                  <Table.Cell>
                    <DateDisplay value={worker.onboardAt} />
                    <Typography variant="caption">{truncate(worker.id, 8, undefined, true)}</Typography>
                  </Table.Cell>
                  {/* <Table.Cell></Table.Cell> */}
                  {/* <Table.Cell>{ }</Table.Cell> */}
                  <Table.Cell align="center">{worker.workerAttemptStat?.totalSubmitted ?? "-"}</Table.Cell>
                  <Table.Cell align="center">{worker.workerAttemptStat?.totalRejected ?? "-"}</Table.Cell>
                  <Table.Cell align="center">{worker.workerAttemptStat?.totalApproved ?? "-"}</Table.Cell>
                  <Table.Cell>{worker.person.telegramHandle}</Table.Cell>
                  <Table.Cell>
                    <StatusChip status={getWorkerStatus(worker)} />
                  </Table.Cell>
                  <Table.Cell align="center">{worker.workerAttemptStat?.totalInProgress ?? "-"}</Table.Cell>
                  {/* <Table.Cell>{ }</Table.Cell> */}
                  <Table.Cell align="right">
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.WorkerDetail.replaceAll(":workerId", worker.id)}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default WorkerExportPage;
