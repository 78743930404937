import BigNumber from "bignumber.js";
import { Moment } from "moment";
import { SimpleMap, Variables } from "../utils";

export interface BaseModel {
  createdAt?: Moment;
  updatedAt?: Moment;
  deletedAt?: Moment;
  id: string;
}

export type EditModel<T extends BaseModel> = Partial<
  Omit<T, "id" | "createdAt" | "updatedAt" | "deletedAt">
>;

export interface WorkerModel extends BaseModel {
  id: string;
  personId: string;
  accountId: string;
  status: string;
  onboardAt?: Moment;
  onboardToken?: string;
  variables?: Variables;
  affiliateKey?: string;

  person: PersonModel;
  workerAttemptStat?: WorkerAttemptStats;
  account?: AccountModel;
  score?: number | null;
  remark?: string | null;
  workerScore: WorkerScore;
  gCashAccount?: string;
}

export interface PersonModel extends BaseModel {
  profileImageId?: string;

  firstname: string;
  primaryEmail: string;

  middlename?: string;
  lastname?: string;
  nickname?: string;
  salutation?: string;
  gender?: string;
  primaryPhone?: string;
  discordHandle?: string;
  instagramHandle?: string;
  tiktokHandle?: string;
  twitterHandle?: string;
  facebookHandle?: string;
  facebookAltHandle?: string;
  telegramHandle?: string;
  profileImage?: string;

  worker?: WorkerModel;
  entity?: EntityModel;
  teams?: TeamModel[];
}

export interface InviteModel extends BaseModel {
  accountId: string;
  personId: string;
  inviteeId?: string;
  accountEntityId?: string;
  status: "pending" | "accepted" | "cancelled";
  name: string;
  email: string;
  token: string;

  inviter?: PersonModel;
}

export interface EntityModel extends BaseModel {
  lastSeenAt?: string;
  accountEntities?: AccountEntityModel[];
  credentials?: CredentialModel[];
}

export interface SelfEntityModel extends EntityModel {
  person?: PersonModel;

  privileges?: string[];
}

export interface ProfileModel {
  self: SelfEntityModel;
  workspace?: AccountEntityModel;
  accounts: AccountModel[];
  worker?: WorkerModel;
  currency?: AccountCurrencyModel;
}

export interface AccountModel extends BaseModel {
  profileImageId?: string;
  ownerId: string;
  name: string;
  status: string;
  accountVariables?: SimpleMap<SimpleMap<string>>[];
}

export interface AccountEntityModel extends BaseModel {
  accountId: string;
  entityId: string;

  status?: string;
  privileges?: string[];

  account?: AccountModel;
  person?: PersonModel;
}

export interface TaskObjectiveModel extends BaseModel {
  enabledAt: Moment;
  id: string;
  taskId: string;
  instruction: string;
  type: string;
  sortPriority: number;
}

export interface TaskProofModel extends BaseModel {
  taskAttemptId: string;
  taskObjectiveId: string;
  content?: string;
  note?: string;
  proofImageId?: string;

  proofImage?: AssetModel;
  rejectReason?: string | null;
  isResubmitted?: boolean;
}

export interface TaskModel extends BaseModel {
  start?: Moment;
  expiry?: Moment;
  publishedAt?: Moment;
  id: string;
  accountId: string;
  title: string;
  description: string;
  notionUrl?: string;
  maxSubmission?: number;
  maxAccept?: number;
  maxPeriodAccept?: number;
  maxPeriodSubmission?: number;
  periodType?: "day" | "week" | "month";
  periodStartOffset?: number;

  payAmount?: BigNumber;
  payCurrency?: string;

  taskObjectives?: TaskObjectiveModel[];

  ongoingAttempt?: TaskAttemptModel;
  recentSubmittedAttempt?: TaskAttemptModel;
  attemptStat?: TaskAttemptStats;
  teams?: TeamModel[];
  timeAllowed?: number;
  resubmitTimeAllowed?: number;
}

export enum TaskStatus {
  InProgress = "in-progress",
  Submitted = "submitted",
  Approved = "approved",
  Cancelled = "cancelled",
  Terminated = "terminated",
  Expired = "expired",

  // This will be calculated by the frontend
  // in-progress + reason rejected
  // However reason rejected will stay there even when the task is approved, so we need to check the status
  // to determine if the task is rejected
  Rejected = "rejected",
}

export interface TaskAttemptModel extends BaseModel {
  submittedAt?: Moment;
  approvedAt?: Moment;
  expiredAt?: Moment;
  acceptedAt?: Moment;
  id: string;
  taskId: string;
  workerId: string;
  message?: string;
  rejectReason?: string;
  submitCount: number;
  status: TaskStatus;
  rejectCount: number;

  task?: TaskModel;
  taskProofs?: TaskProofModel[];
  payAmount?: BigNumber;
  payCurrency?: string;

  worker?: WorkerModel;
  score?: number | null;
}

export interface WalletModel extends BaseModel {
  entityId: string;
  currency: string;
  balance: BigNumber;
}

export interface WalletTxModel extends BaseModel {
  walletId: string;
  amount: BigNumber;
  description: string;
}

export interface AssetModel {
  url: string;
}

export interface PrivilegeModel {
  name: string;
  label: string;
  description: string;
  managemable: boolean;
  domain: PrivilegeDomain;
}

export enum PrivilegeDomain {
  Account = "account",
  Platform = "platform",
}

export interface AccountVariableModel<T = unknown> {
  AccountId: string;
  type: AccountVariableType;
  key: string;
  content: T;
  id: string;
}

export interface AccountVariableBooleanType {
  value: boolean;
}
export interface AccountVariableBrandType {
  items: {
    key: string;
    label: string;
  }[];
}

export enum AccountVariableType {
  Generic = "generic",
}

export enum Currency {
  USD = "USD",
  PHP = "PHP",
  THB = "THB",
}

export interface WithdrawRequestModel extends BaseModel {
  entityId: string;
  payoutItemId: string;
  accountId: string;
  amount: BigNumber;
  amountInUsd: BigNumber;
  currency: Currency;

  withdrawFee: BigNumber;
  finalAmount: BigNumber;
  workerId: string;
  worker?: WorkerModel;

  status: string;

  payoutItem?: PayoutItemModel;
}

export interface PayoutItemModel extends BaseModel {
  workerId: string;
  accountId: string;
  payoutId: string | string;

  amount: BigNumber;
  currency: string;
  amountInUsd: BigNumber;
  description: string;

  status: string;
  type: string;

  referenceNo: string | null;
  message: string | null;
  notes: string | null;

  worker?: WorkerModel;
}

export interface CredentialModel extends BaseModel {
  entityId: string | null;
  type: string | null;
  accessKey: string | null;
  lastChangedAt: Moment | null;
  entity?: EntityModel;
}

export interface WorkerAttemptStats {
  totalApproved: string;
  totalAttempts: string;
  totalCancelled: string;
  totalInProgress: string;
  totalRejected: string;
  totalSubmitted: string;
  weekApproved: string;
  weekCancelled: string;
  weekRejected: string;
}

export interface TaskAttemptStats {
  periodSubmissions: string;
  statusApproved: string;
  statusInProgress: string;
  statusSubmitted: string;
  totalAttempts: string;
}

export interface ActivityLogModel extends BaseModel {
  code: string;
  category: string;
  actorId: string;
  ownerId: string;
  ownerName: string;
  description: string;
  ipAddress: string;
  entity?: AccountEntityModel;
}

export interface PayoutStatsCountModel {
  submitted: number;
  processed: number;
}
export interface PayoutStatsModel extends BaseModel {
  count: PayoutStatsCountModel;
}

export interface PayoutVolStatsModel {}

export interface WorkerStatsModel extends BaseModel {
  active: number;
  pending: number;
}

export interface WorkerStatsOnboardSeriesModel {}
export interface TeamModel extends BaseModel {
  parentTeamId: string | null;
  name: string;
  description: string;
  accountId: string;

  parentTeam: TeamModel | null;
  account: AccountModel | null;
}

export interface TeamPersonModel extends BaseModel {
  accountId: string;
  personId: string;
  teamId: string;
}
export interface AffiliatePageModel extends BaseModel {
  brandKey: string;
  accountId: string;
  name: string;
  publishedAt?: Moment;
  config?: AffiliatePageConfig;
  affiliatePageKey: string;
}

export interface AffiliatePageMeta {
  title?: string;
  favicon?: string;
  backgroundImageSrc?: string;
  logoImageSrc?: string;
}
export interface AffiliatePageButton {
  type: "button";
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  label: string;
  link: string;
  useReferralLink?: boolean;
  startIcon?: string;
  endIcon?: string;
}
export interface AffiliatePageImage {
  type: "image";
  src: string;
  link?: string;
  useReferralLink?: boolean;
  maxWidth?: number;
  aspectRatio?: number;
}
export interface AffiliatePageComponentBase {
  marginTop?: number;
  marginBottom?: number;
  maxWidth?: string;
}
export interface AffiliatePageImageComponent
  extends AffiliatePageComponentBase {
  type: "image";
  images: AffiliatePageImage[];
}
export interface AffiliatePageButtonComponent
  extends AffiliatePageComponentBase {
  type: "button";
  buttons: AffiliatePageButton[];
}
export type AffiliatePageComponent =
  | AffiliatePageImageComponent
  | AffiliatePageButtonComponent;
export interface AffiliatePageConfig {
  meta?: AffiliatePageMeta;
  components?: AffiliatePageComponent[];
}

export interface DayVisitModel extends BaseModel {
  ownerType: string;
  ownerId: string;

  count1d: number;
  count7d: number;
  count30d: number;

  ucount1d: number;
  ucount7d: number;
  ucount30d: number;

  timestamp: Moment;
}

export interface AffiliateTrackerDailyStats {
  date?: Moment;
  view: number;
  uview: number;
  click: number;
  uclick: number;
}

export interface TotalVisitModel extends BaseModel {
  ownerType: string;
  ownerId: string;

  // count1d: number;
  // count7d: number;
  // count30d: number;
  click: number;
  view: number;
}

export interface VisitModel extends BaseModel {
  timestamp: Moment;
  ipAddress: string;
  ipLocation: string;
  resourceType: string | null;
  resourceId: string | null;
  identifier: string | null;
}

export interface ShortLinkModel extends BaseModel {
  url: string;
  code: string;
  accountId: string;
  publishedAt: Moment | null;
  expiredAt: Moment | null;
}

export interface AffiliateTrackerShortLinkModel extends BaseModel {
  affiliateTrackerId: string;
  shortLinkId: string;

  shortLink?: ShortLinkModel;
  dayVisit?: DayVisitModel;
}

export interface AffiliateTrackerModel extends BaseModel {
  workerId: string;
  accountId: string;
  shortLinkId: string;
  brandKey: string;

  affiliatePage?: AffiliatePageModel;
  totalVisit?: TotalVisitModel | null;
  worker?: WorkerModel;
}

export interface AccountCurrencyModel {
  default: string;
  withdrawLimit: number;
}
interface AccountInfo {
  name: string;
}
interface AccountInviter {
  firstname: string;
  lastname: string;
}
export interface AccountInviteModel {
  account: AccountInfo;
  accountId: string;
  createdAt: Moment;
  email: string;
  id: string;
  inviteeId: string;
  inviter: AccountInviter;
  name: string;
  personId: string;
  status: "pending" | "accepted" | "cancelled";
  token: string;
}

export interface WorkerTaskStatistic {
  approved?: number;
  available?: number;
  cancelled?: number;
  inprogress?: number;
  rejected?: number;
  terminated?: number;
  submitted?: number;
  weekApproved?: number;
  weekCancelled?: number;
}

export interface WorkerScore extends BaseModel {
  attempt: string;
  final: string;
  profile: string;
  workerId: string;
}
