import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { Box, CardProps, IconButton, Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import moment from 'moment';
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { DetailCard, ListingComp, MonoLabel, Table } from "src/main/components";
import { RootState } from "src/main/store";
import { ThemeMode } from "src/main/store/preference/types";
import { Meta, PersonModel } from "src/main/types";
import { commonStyles, Paths, truncate } from "src/main/utils";

const TaskHeaders = [
  { value: "Task" },
  { value: "Tasker" },
  { value: "" },
  { value: "" },
];
interface RecentTaskSubmissionProps extends CardProps {

}

const RecentTaskSubmission = (props: RecentTaskSubmissionProps) => {
  const theme = useTheme();
  const curTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 6, offset: 0, submittedAt: "0," });

  const result = accountApi.useListTaskAttemptQuery({ meta });
  const listResult = useMemo(() => result.data ?? undefined, [result.data]);
  const persistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);

  const getStatusIcon = (status: string, color?: string) => {
    switch (status.toLowerCase()) {
      case "draft":
      case "pending":
        return <PendingOutlinedIcon sx={{ mr: .5, color }} />
      case "expired":
        return <AccessTimeIcon sx={{ mr: .5, color }} />
      case "rejected":
      case "cancelled":
      case "terminated":
        return <CancelIcon sx={{ mr: .5, color }} />
      case "published":
      case "completed":
      default:
        return <CheckCircleIcon sx={{ mr: .5, color }} />
    }
  };
  const getStatusColor = (status: string, theme: Theme, curTheme: ThemeMode) => {
    const { palette } = theme;
    switch (status.toLowerCase()) {
      case "expired":
      case "suspended":
      case "cancelled":
        return palette.grey[500];
      case "draft":
      case "pending":
        return palette.warning[curTheme];
      case "rejected":
      case "terminated":
        return palette.error[curTheme];
      case "published":
      case "active":
        return palette.success[curTheme];
      case "submitted":
        return palette.info[curTheme];
      default:
        return palette.success[curTheme];
    }
  };

  const getWorkerName = (person?: PersonModel) => {
    if (!person) return "-"
    const { firstname, middlename, lastname } = person;
    const parsedMiddle = !!middlename ? ` ${middlename}` : "";
    const parsedLast = !!lastname ? ` ${lastname}` : ""
    return firstname + parsedMiddle + parsedLast;
  }

  return (
    <DetailCard {...props} variant="elevation" >
      <DetailCard.Header
        sx={commonStyles.cardHeader}
        header="Recent Task Submissions"
        routeLabel="All Tasks"
        routeTo={Paths.Management.Tasks}
      />
      <DetailCard.Divider />
      <ListingComp hidePagination
        loading={result.isLoading} title=""
        updateList={(newMeta) => { setMeta(newMeta) }} meta={persistMeta}
        sx={{margin:0}}
      >
        <ListingComp.Content>
          <Table>
            <Table.Head headers={TaskHeaders} />
            <Table.Body>
              {listResult?.entries.map((task) => (
                <Table.Row>
                  <Table.Cell><MonoLabel>{truncate(task.id, 8)}</MonoLabel></Table.Cell>
                  <Table.Cell sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">{getWorkerName(task.worker?.person)}</Typography>
                      <Typography color="text.secondary" sx={{ fontSize: 13 }}>Submitted&nbsp;{moment(task.submittedAt).fromNow()}</Typography>
                    </Box>
                  </Table.Cell>
                  <Table.Cell align='right' sx={{ color: getStatusColor(task.status, theme, curTheme) }}>
                    {
                      // <StatusChip status={task.rejectReason ? "rejected" : task.status} />
                      getStatusIcon(task.status, getStatusColor(task.status, theme, curTheme))
                    }
                  </Table.Cell>
                  <Table.Cell align='right'>
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.TaskSubmission.replace(":submissionId", task.id)}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <DetailCard.Divider />
        </ListingComp.Content>
      </ListingComp>
    </DetailCard>
  );
}

export default RecentTaskSubmission;
