import { Box, Button, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { FormStates } from "src/main/hooks/useFormState";
import { createStyles, joinSx } from "src/main/utils";

type FormFieldProps<T> = TextFieldProps & {
  fieldKey: keyof T;
  formStates: FormStates<T>;
  shortcutValue?: string;
  shortcutLabel?: string;
  onValueChange: (key: keyof T) => ((string) => void);
  clearErrorOnChange?: () => void;
}

const FormField = <T extends unknown>(props: FormFieldProps<T>) => {
  const { formStates, fieldKey, onValueChange, shortcutValue, shortcutLabel, clearErrorOnChange, sx, ...rest } = props
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange?.(fieldKey)(evt.target.value);
    clearErrorOnChange?.();
  }

  const onShortcutClick = () => {
    if (!shortcutValue) return;
    onValueChange?.(fieldKey)(shortcutValue);
  }

  return (
    <Box sx={joinSx(styles.root, sx)}>
      {typeof shortcutValue === "string" && (
        <Box display="flex">
          <Box flex={1} />
          <Button sx={styles.maxButton} variant="text" size="small" onClick={onShortcutClick}>
            {shortcutLabel ?? shortcutValue}
          </Button>
        </Box>
      )}
      <TextField
        fullWidth
        InputLabelProps={{ shrink: true }}
        sx={styles.input}
        name={fieldKey as string}
        value={formStates.input[fieldKey] ?? ""}
        onChange={onChange}
        error={!!formStates.error[fieldKey]}
        helperText={formStates.error[fieldKey] ?? ""}
        {...rest}
      />
    </Box>
  )
}

const styles = createStyles({
  root: {
    mt: 2,
  },
  input: {
    display: "block",
    mt: 0,
    mb: 1,
    "& -webkit-autofill, .MuiInputBase-root:has(> input:-webkit-autofill) ": {
      backgroundColor: "rgb(232, 240, 254)",
    },
  },
  maxButton: {
    fontSize: "10px",
    paddingY: "2px",
  },
})

export default FormField;
