import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DetailCard, MonoLabel, TimePicker } from "src/main/components";
import { SimpleMap } from "src/main/utils";

interface SubmissionConfigProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  handleChange?: (e: string | React.ChangeEvent<any>) => void;
  errors?: SimpleMap<any>;
  touched?: SimpleMap<any>;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  formErrors?: SimpleMap<string>;
  setFormErrors?: (val: SimpleMap<string>) => void;
}

const FREQ = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
]

const MONTH_OPTS = [...Array(30).keys()].map((val) => ({ value: val + 1, label: val + 1 }));
const WEEK_OPTS = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
]

const HOUR_SEC = 60 * 60;
const MINUTE_SEC = 60;

type timeAllowedType = number | undefined | null

const getInitialTime = (time: timeAllowedType, type: allowedTimeType) => {
  if (!time) return null
  const hours = (time >= HOUR_SEC) ? parseInt((time / HOUR_SEC) + "") : null;
  const minutes = (time - (hours ?? 0) * HOUR_SEC) / 60;

  switch (type) {
    case "hour":
      return hours;
    case "minute":
      return minutes;
    default:
      return null;
  }
}

type allowedTimeType = "hour" | "minute"

const SubmissionConfigComponent: React.FC<SubmissionConfigProps> = (props) => {
  const { values, handleChange, errors = {}, touched = {}, handleBlur, setFieldValue, formErrors, setFormErrors } = props;
  const [maxSubmissionValid, setMaxSubmissionValid] = useState(!!values.maxSubmission);
  const [maxAcceptValid, setMaxAcceptValid] = useState(!!values.maxAccept);

  const [timeLimitValid, setTimeLimitValid] = useState(!!values.timeAllowed);

  const [hourLimit, setHourLimit] = useState<number | null>(getInitialTime(values.timeAllowed as timeAllowedType, "hour"));
  const [minuteLimit, setMinuteLimit] = useState<number | null>(getInitialTime(values.timeAllowed as timeAllowedType, "minute"));


  const [resubmitHourLimit, setResubmitHourLimit] = useState<number | null>(getInitialTime(values.resubmitTimeAllowed as timeAllowedType, "hour"));
  const [resubmitMinuteLimit, setResubmitMinuteLimit] = useState<number | null>(getInitialTime(values.resubmitTimeAllowed as timeAllowedType, "minute"));

  const setAndCalculateTime = useCallback((timeValue: number, type: allowedTimeType) => {
    let totalTime = 0;
    if (timeValue !== 0 && !timeValue) return;

    switch (type) {
      case "hour":
        totalTime = timeValue * HOUR_SEC + (minuteLimit ?? 0) * MINUTE_SEC
        setHourLimit(timeValue);
        break;
      case "minute":
        totalTime = (hourLimit ?? 0) * HOUR_SEC + timeValue * MINUTE_SEC
        setMinuteLimit(timeValue);
        break;
    }

    let newFormErrors = { ...formErrors };
    delete newFormErrors.timeAllowed
    setFormErrors?.(newFormErrors)
    setFieldValue("timeAllowed", totalTime);
  }, [hourLimit, minuteLimit, setFieldValue, formErrors, setFormErrors])

  const setAndCalculateResubmitTime = useCallback((timeValue: number, type: allowedTimeType) => {
    let totalTime = 0;
    if (timeValue !== 0 && !timeValue) return;

    switch (type) {
      case "hour":
        totalTime = timeValue * HOUR_SEC + (resubmitMinuteLimit ?? 0) * MINUTE_SEC
        setResubmitHourLimit(timeValue);
        break;
      case "minute":
        totalTime = (resubmitHourLimit ?? 0) * HOUR_SEC + timeValue * MINUTE_SEC
        setResubmitMinuteLimit(timeValue);
        break;
    }

    let newFormErrors = { ...formErrors };
    delete newFormErrors.resubmitTimeAllowed
    setFormErrors?.(newFormErrors)
    setFieldValue("resubmitTimeAllowed", totalTime);
  }, [resubmitHourLimit, resubmitMinuteLimit, setFieldValue, formErrors, setFormErrors])


  const [daytime, setDaytime] = useState<Dayjs | null>(null);
  const [weektime, setWeektime] = useState<number | null>(null);
  const [monthtime, setMonthtime] = useState<number | null>(null);

  useEffect(() => {
    if (values) {
      if (values.periodStartOffset) {

        const withoutUtcOffset = values.periodStartOffset + moment().utcOffset();

        if (values.periodType === "day") {
          setDaytime(dayjs(moment().startOf("day").add(withoutUtcOffset, "minutes").format("YYYY-MM-DD HH:mm:ss")));
        }
        const offsetPerDay = 60 * 24;
        const days = parseInt((parseInt(withoutUtcOffset) / offsetPerDay) + "");

        if (values.periodType === "month") {
          setMonthtime(days)
        }
        if (values.periodType === "week") {
          setWeektime(days)
        }
      }
    }
    // eslint-disable-next-line
  }, [])


  const refreshResetStates = useCallback((type: string) => {
    if (!values.periodStartOffset) return;

    const utcOffset = moment().utcOffset();
    const daytimeOffset = !!daytime ? moment(daytime.format("YYYY-MM-DD HH:mm:ss")).diff(moment().startOf("day"), "minutes") : 0;
    const offsetPerDay = 60 * 24;


    if (type === "month") {
      const monthOffset = offsetPerDay * (monthtime ?? 0);
      return setFieldValue("periodStartOffset", monthOffset + daytimeOffset - utcOffset);
    }
    if (type === "week") {
      const weekOffset = offsetPerDay * (weektime ?? 0);
      return setFieldValue("periodStartOffset", weekOffset + daytimeOffset - utcOffset);
    }
    return setFieldValue("periodStartOffset", daytimeOffset - utcOffset);
  }, [daytime, weektime, monthtime, setFieldValue, values])

  const getResetMessage = () => {
    if (values.periodType === "week")
      return `Task will reset weekly on ${WEEK_OPTS.find((opt) => { if (opt.value === weektime) return opt; return undefined })?.label ?? '-'} at ${daytime ? moment(daytime?.toString()).format("hh:mm A") : "-"}`;

    if (values.periodType === "month")
      return `Task will reset monthly on ${MONTH_OPTS.find((opt) => { if (opt.value === monthtime) return opt; return undefined })?.label ?? '-'}th at ${daytime ? moment(daytime?.toString()).format("hh:mm A") : "-"}`;

    return `Task will reset daily at ${daytime ? moment(daytime?.toString()).format("hh:mm A") : "-"}`;
  }

  const timeLimitOverRecurringTime = values.periodType === "day" && (values.timeAllowed > (HOUR_SEC * 24))
  const resubmitTimeLimitOverRecurringTime = values.periodType === "day" && (values.resubmitTimeAllowed > (HOUR_SEC * 24))

  return (
    <DetailCard>
      <DetailCard.Header
        header="Submission Configurations"
      />
      <DetailCard.Divider />
      <Box p={2} gap={2}>
        <Box gap={2} display="flex" flexDirection="column">

          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Limit Total Accepts</Typography>
              <Switch checked={maxAcceptValid} onChange={(ev, check) => {
                setMaxAcceptValid(check);
                if (!check) setFieldValue("maxAccept", null);
              }} />
            </Box>
            <Typography color="text.secondary" variant="body2">Set a maximum number of workers available to accept the task.</Typography>
          </Box>
          {maxAcceptValid && (
            <TextField
              value={values.maxAccept}
              name={"maxAccept"}
              label="Total accept limit"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ flex: 1 }}
              error={!!errors.maxAccept && !!touched.maxAccept}
              helperText={!!touched.maxAccept && errors.maxAccept}
              type="number"
            />
          )}

          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Limit Submissions</Typography>
              <Switch checked={maxSubmissionValid} onChange={(ev, check) => {
                setMaxSubmissionValid(check);
                if (!check) setFieldValue("maxSubmission", null);

              }} />
            </Box>
            <Typography color="text.secondary" variant="body2">Set a maximum number of submissions available to workers. A worker will not be able to accept or submit his task if the limit has been reached. Rejected and ongoing tasks are not included in the count for submission limit.</Typography>
          </Box>

          {maxSubmissionValid && (
            <TextField
              value={values.maxSubmission}
              name={"maxSubmission"}
              label="Total submission limit"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ flex: 1 }}
              error={!!errors.maxSubmission && !!touched.maxSubmission}
              helperText={!!touched.maxSubmission && errors.maxSubmission}
              type="number"
            />
          )}

          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Set Task Time Limit</Typography>
              <Switch checked={timeLimitValid} onChange={(ev, check) => {
                setTimeLimitValid(check)
                if (!check) {
                  setFieldValue("timeAllowed", null);
                  setFieldValue("resubmitTimeAllowed", null);
                  setHourLimit(null);
                  setMinuteLimit(null);
                  setResubmitHourLimit(null);
                  setResubmitMinuteLimit(null);
                }
              }} />
            </Box>
            <Typography color="text.secondary" variant="body2">Set a time limit for the amount of time given to a Tasker to complete a task. Failing to complete task before the time frame will opens up the slot for other Tasker to accept task.</Typography>
          </Box>
          {timeLimitValid && (
            <Grid container spacing={2}>
              <Grid item md={12}><Typography sx={{ opacity: 0.8 }} variant="body2">Tasker will have {hourLimit ?? 0} hour(s) {minuteLimit ?? 0} minute(s) to complete their task.</Typography></Grid>
              {timeLimitOverRecurringTime && (<Grid item md={12}><Typography color="error" variant="body2">Time limit is over recurring time.</Typography></Grid>)}
              <Grid item md={6}>
                <FormControl error={timeLimitOverRecurringTime || !!formErrors?.timeAllowed} variant="outlined" fullWidth>
                  <InputLabel shrink>Hour</InputLabel>
                  <Select
                    value={hourLimit ?? 0}
                    input={<OutlinedInput notched label="Hour" />}
                    onChange={(ev) => {
                      setAndCalculateTime(parseInt(ev.target.value + ""), "hour")
                    }}
                  >
                    {[...Array(24)].map((val, index) => (
                      <MenuItem sx={{ py: 0 }} value={index}>
                        <MonoLabel>{index}</MonoLabel>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl error={timeLimitOverRecurringTime || !!formErrors?.timeAllowed} variant="outlined" fullWidth>
                  <InputLabel shrink>Minutes</InputLabel>
                  <Select
                    value={minuteLimit ?? 0}
                    input={<OutlinedInput notched label="Minutes" />}
                    onChange={(ev) => {
                      setAndCalculateTime(parseInt(ev.target.value + ""), "minute")
                    }}
                  >
                    {[...Array(60)].map((val, index) => (
                      <MenuItem sx={{ py: 0 }} value={index}>
                        <MonoLabel>{index}</MonoLabel>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <Typography variant="body2">Resubmit Time Limit</Typography>
                <Typography sx={{ opacity: 0.8 }} variant="body2">Tasker will have {resubmitHourLimit ?? 0} hour(s) {resubmitMinuteLimit ?? 0} minute(s) to resubmit their task after rejected.</Typography>
              </Grid>
              {resubmitTimeLimitOverRecurringTime && (<Grid item md={12}><Typography color="error" variant="body2">Resubmit Time limit is over recurring time.</Typography></Grid>)}

              <Grid item md={6}>
                <FormControl error={resubmitTimeLimitOverRecurringTime || !!formErrors?.resubmitTimeAllowed} variant="outlined" fullWidth>
                  <InputLabel shrink>Hour</InputLabel>
                  <Select
                    value={resubmitHourLimit ?? 0}
                    input={<OutlinedInput notched label="Hour" />}
                    onChange={(ev) => {
                      setAndCalculateResubmitTime(parseInt(ev.target.value + ""), "hour")
                    }}
                  >
                    {[...Array(24)].map((val, index) => (
                      <MenuItem sx={{ py: 0 }} value={index}>
                        <MonoLabel>{index}</MonoLabel>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl error={resubmitTimeLimitOverRecurringTime || !!formErrors?.resubmitTimeAllowed} variant="outlined" fullWidth>
                  <InputLabel shrink>Minutes</InputLabel>
                  <Select
                    value={resubmitMinuteLimit ?? 0}
                    input={<OutlinedInput notched label="Minutes" />}
                    onChange={(ev) => {
                      setAndCalculateResubmitTime(parseInt(ev.target.value + ""), "minute")
                    }}
                  >
                    {[...Array(60)].map((val, index) => (
                      <MenuItem sx={{ py: 0 }} value={index}>
                        <MonoLabel>{index}</MonoLabel>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Recurring Submission</Typography>
              <Switch checked={values.isRecurring} onChange={(ev, check) => {
                setFieldValue("isRecurring", check);
                if (!values.periodType) setFieldValue("periodType", "day");
              }} />
            </Box>
            <Typography variant="body2" color="text.secondary">Enable this to allow resubmissions from the same worker.</Typography>
          </Box>
        </Box>
        {values.isRecurring && (
          <>
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item md={4} xs={12} >
                <FormControl variant="outlined" sx={{ flex: 1 }} fullWidth>
                  <InputLabel shrink>Frequency</InputLabel>
                  <Select
                    input={<OutlinedInput notched label="Frequency" />}
                    sx={{ flex: 1, minWidth: "150px", }}
                    value={values.periodType}
                    onChange={(ev) => {
                      setFieldValue("periodType", ev.target.value);
                      refreshResetStates(ev.target.value);
                    }}
                    disabled={!values.isRecurring}
                  >
                    {FREQ.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={6} >
                <TextField
                  value={values.maxPeriodAccept}
                  label="Max Total Accept / Period"
                  name="maxPeriodAccept"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ flex: 1 }}
                  fullWidth
                  error={!!errors.maxPeriodAccept && !!touched.maxPeriodAccept}
                  helperText={!!touched.maxPeriodAccept && errors.maxPeriodAccept}
                  disabled={!values.isRecurring}
                  type="number"
                />
              </Grid>
              <Grid item md={4} xs={6} >
                <TextField
                  value={values.maxPeriodSubmission}
                  label="Tasker / Period Submission"
                  name="maxPeriodSubmission"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ flex: 1 }}
                  fullWidth
                  error={!!errors.maxPeriodSubmission && !!touched.maxPeriodSubmission}
                  helperText={!!touched.maxPeriodSubmission && errors.maxPeriodSubmission}
                  disabled={!values.isRecurring}
                  type="number"
                />
              </Grid>
            </Grid>
            <Box pt={2}>
              <Typography variant="body2" color="text.secondary">This configuration allows a worker to submit: </Typography>
              <Typography variant="body2" color="text.secondary">up to <b><u>{!values.maxPeriodSubmission ? "1" : values.maxPeriodSubmission}</u></b> time every <b><u>{values.periodType}</u></b>.</Typography>
            </Box>
            <Box pt={2} alignContent="center">
              <Typography variant="subtitle1">Reset time</Typography>
              <Box pt={1} gap={2} display="flex" alignItems="center">
                {values.periodType === "month" && (
                  <Box flex={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Day of month</InputLabel>
                      <Select
                        input={<OutlinedInput notched label="Day of month" />}
                        value={monthtime}
                        onChange={(ev) => {
                          setMonthtime(ev.target.value as number)
                          const offsetPerDay = 60 * 24;
                          const monthOffset = (ev.target.value as number ?? 0) * offsetPerDay;

                          let daytimeOffset = 0;
                          if (daytime) {
                            daytimeOffset = daytime.diff(daytime.startOf("day"), "minutes");
                          }

                          const utcOffset = moment().utcOffset();

                          setFieldValue("periodStartOffset", monthOffset + daytimeOffset - utcOffset);
                        }}
                      >
                        {MONTH_OPTS.map((opt) => (
                          <MenuItem value={opt.value}>{opt.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {values.periodType === "week" && (
                  <Box flex={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Day of week</InputLabel>
                      <Select
                        input={<OutlinedInput notched label="Day of week" />}
                        value={weektime}
                        onChange={(ev) => {
                          setWeektime(ev.target.value as number)
                          const offsetPerDay = 60 * 24;
                          const weekOffset = (ev.target.value as number ?? 0) * offsetPerDay;
                          let daytimeOffset = 0;
                          if (daytime) {
                            daytimeOffset = daytime.diff(daytime.startOf("day"), "minutes");
                          }
                          const utcOffset = moment().utcOffset();

                          setFieldValue("periodStartOffset", daytimeOffset + weekOffset - utcOffset);

                        }}
                      >
                        {WEEK_OPTS.map((opt) => (
                          <MenuItem value={opt.value}>{opt.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <Box flex={1}>
                  <TimePicker
                    label="Offset time"
                    value={daytime}
                    flex={1}
                    timeFormat="min"
                    handleChange={(date: Dayjs | null) => {
                      setDaytime(date)
                      const offsetPerDay = 60 * 24;
                      const utcOffset = moment().utcOffset();
                      let typeOffset = 0;
                      if (values.periodType === "week") {
                        typeOffset = (weektime ?? 0) * offsetPerDay;
                      }
                      if (values.periodType === "month") {
                        typeOffset = (monthtime ?? 0) * offsetPerDay;
                      }

                      setFieldValue("periodStartOffset", !!date ? date.diff(date.startOf("day"), "minutes") + typeOffset - utcOffset : "")
                    }}
                  />
                </Box>
                <Box flex={1} pl={2}>
                  <Typography>{getResetMessage()}</Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </DetailCard>
  );
};

export default SubmissionConfigComponent;
