import { CloseOutlined, LaunchRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { CashBillIcon } from "src/assets";
import { workerApi } from "src/main/api";
import {
  AttemptStatusChip,
  LoadingButton,
  TaskExpiryChip,
  Typography,
} from "src/main/components";
import { Breadcrumb } from "src/main/components/Page/components";
import { CrumbsProps } from "src/main/components/Page/components/Breadcrumb/Breadcrumb";
import { useAsyncTask, useIsXs } from "src/main/hooks";
import { TaskObjectiveModel, TaskProofModel, TaskStatus } from "src/main/types";
import {
  IntlFormatter,
  Paths,
  commonStyles,
  createStyles,
  handleApiResponse,
  joinSx,
  sortByPriority,
} from "src/main/utils";
import {
  TaskGoal,
  TaskIdChip,
  TaskInfoIcons,
} from "../TaskDetailPage/components";
import { TimeCompleteInfoDialog } from "../components";
import {
  TaskObjectiveForm,
  TaskStatusComp,
  TaskSubmittedComp,
} from "./components";

interface TaskAttemptPageProps extends React.PropsWithChildren {}

interface SelectedObjective {
  objective: TaskObjectiveModel;
  proof?: TaskProofModel;
}

const TaskAttemptPage: React.FC<TaskAttemptPageProps> = (props) => {
  const { attemptId = "" } = useParams<{ attemptId: string }>();
  const isXs = useIsXs();
  const taskAttemptResult = workerApi.useWorkerTaskAttemptQuery(attemptId, {
    skip: !attemptId.length,
  });
  const [submitTask] = workerApi.useWorkerTaskSubmitMutation();
  const [runSubmitAttempt, loading] = useAsyncTask("worker/attempt/submit");
  const [cancelTask] = workerApi.useWorkerTaskCancelMutation();
  const [runCancelTask, cancelLoading] = useAsyncTask("worker/attempt/cancel");
  const [selection, setSelection] = useState<SelectedObjective | null>(null);

  const [showAbortDialog, setShowAbortDialog] = useState(false);

  const calculateDuration = (expiry: moment.Moment) => {
    const start = moment();
    const end = moment(expiry);
    const duration = moment.duration(end.diff(start));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    return (
      (duration.years() > 0 ? duration.years().toString() + "y " : "") +
      days.toString() +
      "d " +
      hours.toString() +
      "h " +
      minutes.toString() +
      "m"
    );
  };
  const taskInfo = useMemo(() => {
    const taskAttempt = taskAttemptResult.data?.model;
    const task = taskAttempt?.task;

    if (!task) return null;

    const title =
      task.title ??
      IntlFormatter.getMessage("taskAttemptPage.task_details", "Task Detail");
    const reference = task.id.substring(0, 8);
    const description =
      task.description ??
      IntlFormatter.getMessage(
        "taskAttemptPage.this_task_does_not_have_description",
        "This task does not have a description"
      );
    const taskObjectives = task.taskObjectives?.slice() ?? [];
    const submitted = taskAttempt.status === "submitted";
    const pay =
      task.payAmount && task.payCurrency
        ? task.payAmount + " " + task.payCurrency
        : "N/A";

    taskObjectives.sort(sortByPriority);

    let canSubmit = true;
    const objectives: {
      objective: TaskObjectiveModel;
      proof?: TaskProofModel;
    }[] = [];

    for (const objective of taskObjectives) {
      const proof = taskAttempt.taskProofs?.find(
        (proof) => proof.taskObjectiveId === objective.id
      );
      objectives.push({
        objective,
        proof,
      });
      if (canSubmit && !proof) canSubmit = false;
    }

    let approvedDaysAgo: string | boolean = false;
    if (taskAttempt.status === "approved")
      approvedDaysAgo = moment(taskAttempt.approvedAt).fromNow();

    const noSubmissions = !taskAttempt.taskProofs?.length;
    const expiry = moment(task.expiry).isValid() ? moment(task.expiry) : null;
    const now = moment();
    const end = moment(expiry);
    let isExpired = end.isBefore(now);

    const attemptExpired = taskAttempt.expiredAt;
    const notionUrl = task.notionUrl;

    let status = taskAttempt.status;

    if (status === "in-progress" && taskAttempt.rejectReason)
      status = TaskStatus.Rejected;

    return {
      ogTask: task,
      ogAttempt: taskAttempt,
      title,
      reference,
      description,
      approvedDaysAgo,
      objectives,
      submitted,
      canSubmit,
      noSubmissions,
      pay,
      expiry,
      attemptExpired,
      status,
      notionUrl,
      isExpired,
    };
  }, [taskAttemptResult.data]);

  const onSelectObjective = (objective: TaskObjectiveModel) => {
    const proof = taskInfo?.ogAttempt.taskProofs?.find(
      (p) => p.taskObjectiveId === objective.id
    );
    setSelection({
      objective,
      proof,
    });
  };

  const toastMessages = useMemo(() => {
    return {
      incompletedTask: IntlFormatter.getMessage(
        "taskAttemptPage.please_complete_all_goals_before_submit_task",
        "Please complete all goals before submitting task."
      ),
      taskSubmitted: IntlFormatter.getMessage(
        "taskAttemptPage.task_submitted_successfully",
        "Task submitted successfully"
      ),
      taskAborted: IntlFormatter.getMessage(
        "taskAttemptPage.task_aborted_successfully",
        "Task aborted successfully"
      ),
    };
  }, []);

  const onSubmitTask = useCallback(async () => {
    runSubmitAttempt(async () => {
      if (!taskInfo) return;
      if (!taskInfo.canSubmit) {
        toast.error(toastMessages.incompletedTask);
        return;
      }
      const attemptId = taskInfo.ogAttempt.id;
      const result = await submitTask(attemptId);

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        throw new Error(error.data?.error?.message);
      }

      toast.success(toastMessages.taskSubmitted);
    });
  }, [runSubmitAttempt, submitTask, taskInfo, toastMessages]);

  const handleCloseAbortDialog = useCallback(() => {
    setShowAbortDialog(false);
  }, []);

  const onAbortTask = useCallback(async () => {
    runCancelTask(async () => {
      if (!taskInfo) return;
      const attemptId = taskInfo.ogAttempt.id;
      const result = await cancelTask(attemptId);

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        throw new Error(error.data?.error?.message);
      }
      toast.success(toastMessages.taskAborted);

      handleCloseAbortDialog();
    });
  }, [
    cancelTask,
    handleCloseAbortDialog,
    runCancelTask,
    taskInfo,
    toastMessages.taskAborted,
  ]);

  const hasUnsubmittedError = useMemo(() => {
    let hasErr = false;
    if (!taskInfo?.ogAttempt) return hasErr;

    taskInfo.ogAttempt.taskProofs?.forEach((proof) => {
      if (!!proof.rejectReason && !proof.isResubmitted) hasErr = true;
    });

    return hasErr;
    // eslint-disable-next-line
  }, [taskInfo?.ogAttempt, taskInfo?.ogAttempt.taskProofs]);

  const taskerStatistic = useMemo(() => {
    let acceptedSubmittedSlot = 0;

    if (taskInfo?.ogTask.attemptStat)
      acceptedSubmittedSlot =
        parseInt(taskInfo?.ogTask.attemptStat?.statusSubmitted) +
        parseInt(taskInfo?.ogTask.attemptStat?.statusApproved);

    return (
      acceptedSubmittedSlot + "/" + (taskInfo?.ogTask.maxSubmission ?? "0")
    );
  }, [taskInfo]);

  
  const getCrumbs = useCallback(() => {
    const currentPage = { label: "Task Detail" };
    const crumb: CrumbsProps[] = [];
    switch (taskInfo?.status) {
      case "terminated":
      case "approved":
      case "expired":
      case "cancelled":
        crumb.push({
          label: IntlFormatter.getMessage(
            "taskAttemptPage.closed_tasks",
            "Closed Tasks"
          ),
          path: Paths.Portal.TasksClosed,
        });
        break;
      case "in-progress":
        crumb.push({
          label: IntlFormatter.getMessage(
            "taskAttemptPage.in_progress_tasks",
            "In Progress Tasks"
          ),
          path: Paths.Portal.TasksInProgress,
        });
        break;
      case "rejected":
        crumb.push({
          label: IntlFormatter.getMessage(
            "taskAttemptPage.rejected_tasks",
            "Rejected Tasks"
          ),
          path: Paths.Portal.TasksRejected,
        });
        break;
      case "submitted":
        crumb.push({
          label: IntlFormatter.getMessage(
            "taskAttemptPage.under_review_tasks",
            "Under Review Tasks"
          ),
          path: Paths.Portal.TasksSubmitted,
        });
        break;
      default:
        crumb.push({
          label: IntlFormatter.getMessage(
            "taskAttemptPage.available_tasks",
            "Available Tasks"
          ),
          path: Paths.Portal.TasksAvailable,
        });
    }

    crumb.push(currentPage);
    return crumb;
  }, [taskInfo]);

  if (!taskInfo) return null;

  const getStartingText = () => {
    const isAfter = !!taskInfo.attemptExpired
      ? moment().isAfter(taskInfo.attemptExpired)
      : undefined;

    return (
      <>
        {!!taskInfo.attemptExpired &&
          (taskInfo.ogAttempt.status === "in-progress" ||
            taskInfo.ogAttempt.status === "expired") && (
            <Box
              sx={{
                backgroundColor: "error.alert.background",
                borderRadius: "10px",
                mt: 2,
                p: 2,
                px: 2,
              }}
            >
              <Box
                mb={-0.5}
                color="error.main"
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {!!taskInfo.attemptExpired && (
                  <>
                    <Typography
                      formatId="taskAttemptPage.submit_within"
                      color="text.primary"
                      noWrap
                      variant="body2"
                      lineHeight={1.375}
                    >
                      Submit within:
                    </Typography>
                    {isAfter && (
                      <Typography
                        formatId="taskAttemptPage.times_up"
                        variant="body2"
                        fontWeight="bold"
                        lineHeight={1.375}
                      >
                        Time's Up!
                      </Typography>
                    )}
                    {!isAfter && (
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        lineHeight={1.375}
                      >{`:  ${calculateDuration(
                        moment(taskInfo.attemptExpired)
                      )}`}</Typography>
                    )}
                    <TimeCompleteInfoDialog color="error" />
                  </>
                )}
              </Box>
              {!isAfter && (
                <Typography
                  formatId="taskAttemptPage.to_earn_and_improve_quality"
                  variant="caption"
                  color="textSecondary"
                >
                  to earn your reward and improve your quality score.
                </Typography>
              )}
              {isAfter && (
                <Typography
                  formatId="taskAttemptPage.sorry_missed_deadline_to_submit"
                  variant="caption"
                  color="textSecondary"
                >
                  Sorry, you missed the deadline to submit your task.
                </Typography>
              )}
            </Box>
          )}
      </>
    );
  };

  return (
    <Container sx={styles.container}>
      <Breadcrumb crumbs={getCrumbs()} />
      <Card sx={{ mt: 2 }}>
        <TaskStatusComp
          attempt={taskInfo.ogAttempt}
          isExpired={taskInfo.isExpired}
        />
        <CardContent sx={joinSx(styles.content, { p: 0 })}>
          <Box sx={styles.rowBox}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                {/*HIDE FOR FUTURE <Chip label="Task difficulty" size="small" deleteIcon={<InfoIcon fontSize="small" />} onDelete={() => { }} sx={styles.chipDifficulty} /> */}
                <Box>
                  <AttemptStatusChip
                    attempt={taskInfo.ogAttempt}
                    isExpired={taskInfo.isExpired}
                  />
                </Box>
              </Box>
              {!!taskInfo.ogTask.expiry && (
                <TaskExpiryChip expiry={taskInfo.ogTask.expiry} />
              )}
            </Box>
            <Typography variant="h5" my={1}>
              {taskInfo.title}
            </Typography>
            <TaskInfoIcons
              pay={taskInfo.pay}
              submissionSlot={taskerStatistic}
            />
            {getStartingText()}
            <Box display="flex" alignItems="center" pt={2}>
              <Typography variant="body2" color="textSecondary">
                {taskInfo.description}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
            >
              <Typography
                formatId="taskAttemptPage.taskId"
                mr={1}
                variant="subtitle2"
              />
              <TaskIdChip taskId={taskInfo.ogTask.id} />
            </Box>
          </Box>
          <Divider />
          {typeof taskInfo.notionUrl === "string" && (
            <>
              <Box sx={{ textAlign: "center", p: 2 }}>
                <Typography
                  formatId="taskAttemptPage.recommended_to_review_instruction_before_accept_task"
                  variant="caption"
                >
                  It is recommended to review instruction and goals before
                  accepting a task.
                </Typography>
                <Button
                  sx={{ mt: 1 }}
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    window.open(taskInfo.notionUrl, "_blank");
                  }}
                  endIcon={
                    <Box display="flex" alignItems="center">
                      <LaunchRounded fontSize="small" />
                    </Box>
                  }
                >
                  {IntlFormatter.getMessage(
                    "taskAttemptPage.full_instructions",
                    "Full Instructions"
                  )}
                </Button>
              </Box>
              <Divider />
            </>
          )}

          <TaskSubmittedComp displayWrapper={taskInfo.submitted}>
            <TaskGoal
              isExpired={taskInfo.isExpired}
              editable={!taskInfo.isExpired}
              status={taskInfo.status}
              goals={taskInfo.objectives}
              pay={taskInfo.pay}
              onClickEvt={onSelectObjective}
            />
          </TaskSubmittedComp>
        </CardContent>
      </Card>
      {["submitted", "in-progress"].includes(taskInfo.ogAttempt.status) && (
        <Box
          mt={2}
          textAlign={{
            xs: "center",
            sm: "right",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{
              xs: "space-between",
              sm: "flex-end",
            }}
            my={1}
          >
            <Typography variant="caption" mr={1}>
              {!!taskInfo.attemptExpired &&
                taskInfo.ogAttempt.status === "in-progress" &&
                `${IntlFormatter.getMessage(
                  "taskAttemptPage.submit_within",
                  "Submit within:"
                )} ${calculateDuration(moment(taskInfo.attemptExpired))}`}
              {taskInfo.submitted &&
                IntlFormatter.getMessage(
                  "taskAttemptPage.earnings_will_be_paid_upon_approval",
                  "Earnings will be paid upon approval"
                )}
            </Typography>
            <Box display="flex" alignItems="center">
              <CashBillIcon fontSize="small" sx={{ color: "text.secondary" }} />
              <Typography variant="subtitle2" sx={{ fontWeight: 700, pl: 0.5 }}>
                +{taskInfo.pay}
              </Typography>
            </Box>
          </Box>
          {!taskInfo.isExpired && (
            <LoadingButton
              fullWidth={isXs}
              variant="contained"
              color="secondary"
              onClick={onSubmitTask}
              loading={loading}
              disabled={
                taskInfo.submitted || !taskInfo.canSubmit || hasUnsubmittedError
              }
            >
              {taskInfo.submitted
                ? IntlFormatter.getMessage(
                    "taskAttemptPage.pending_approval",
                    "Pending Approval"
                  )
                : IntlFormatter.getMessage(
                    "taskAttemptPage.submit_task",
                    "Submit Task"
                  )}
            </LoadingButton>
          )}
          {!taskInfo.canSubmit && (
            <Typography variant="body2" color="textSecondary">
              {IntlFormatter.getMessage(
                "taskAttemptPage.task_goals_incomplete_please_comlete_all",
                "Task goals are incomplete. Please complete all task goals to submit this task."
              )}
            </Typography>
          )}
        </Box>
      )}

      <Box
        mt={6}
        textAlign={{
          xs: "center",
          sm: "right",
        }}
      >
        {[TaskStatus.InProgress].includes(taskInfo.status) && !taskInfo.isExpired && (
          <LoadingButton
            fullWidth={isXs}
            variant="outlined"
            color="error"
            onClick={() => {
              setShowAbortDialog(true);
            }}
            loading={loading}
          >
            {IntlFormatter.getMessage(
              "taskAttemptPage.abort_task",
              "Abort Task"
            )}
          </LoadingButton>
        )}

        {/* {["approved", "terminated"].includes(taskInfo.status) ?
          <Typography
            id="taskAttemptPage.task_goals_incomplete_please_comlete_all"
            variant="body2"
            color="textSecondary"
            textAlign={{
              xs: "center",
              sm: "right"
            }}
          >
            You cannot retract your submission for this task as it has already been
            {<Typography variant="body2" color={taskInfo.status === "terminated" ? "error" : taskInfo.status === "approved" ? "secondary" : "inherit"}>{taskInfo.status}</Typography>}.
          </Typography> :
          ["rejected"].includes(taskInfo?.status) && (<Typography
            variant="body2"
            color="textSecondary"
            textAlign={{
              xs: "center",
              sm: "right"
            }}
          >
            {IntlFormatter.getMessage("taskAttemptPage.make_changes_to_retract_submission", "To make changes to your task, you may retract your submission.")}
          </Typography>)
        }
        {
          ["approved", "terminated", "rejected"].includes(taskInfo?.status) && (<LoadingButton
            fullWidth={isXs}
            variant="outlined"
            color="error"
            onClick={() => { setShowAbortDialog(true) }}
            loading={loading}
            disabled
          >
            {IntlFormatter.getMessage("taskAttemptPage.retract_submission", "Retract Submission")}
          </LoadingButton>)
        } */}
      </Box>

      <TaskObjectiveForm
        objective={selection?.objective ?? null}
        proof={selection?.proof}
        attempt={taskInfo.ogAttempt}
        onClose={() => setSelection(null)}
      />

      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={(e) => {
          if (!cancelLoading) {
            handleCloseAbortDialog();
          }
        }}
        open={showAbortDialog}
      >
        <IconButton
          disabled={cancelLoading}
          sx={commonStyles.dialogCloseButton}
          onClick={(e) => {
            handleCloseAbortDialog();
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogTitle>
          <Typography formatId="taskAttemptPage.abort_task" variant="h6">
            Abort Task
          </Typography>
        </DialogTitle>
        <DialogContent>
          {IntlFormatter.getMessage(
            "taskAttemptPage.ask_confirm_abort_task",
            "Confirm abort this task ?"
          )}{" "}
          <strong>
            {IntlFormatter.getMessage(
              "taskAttemptPage.ask_confirm_abort_task_2",
              "This cannot be undone"
            )}
          </strong>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={cancelLoading}
            onClick={() => {
              setShowAbortDialog(false);
            }}
          >
            {IntlFormatter.getMessage("general.cancel", "Cancel")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={cancelLoading}
            onClick={onAbortTask}
            color="error"
          >
            {IntlFormatter.getMessage("taskAttemptPage.confirm", "Confirm.")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },
  content: {
    p: 2,
  },
  chip: {
    "& .MuiSvgIcon-root": {
      color: "#23A576",
      backgroundColor: "#23A576",
    },
    fontSize: "8px",
  },
  icon: {
    ml: 1,
    fontSize: {
      xs: "14px",
      md: "20px",
    },
  },
  chipDifficulty: {
    "&.MuiChip-root": {
      backgroundColor: "primary.ghost",
      color: "primary.main",
    },
    "&.MuiChip-root > .MuiChip-deleteIcon": {
      color: "info.main",
      cursor: "default",
    },
  },
  chipExpiry: {
    "&.MuiChip-root": {
      backgroundColor: "inherit",
    },
  },
  rowBox: {
    p: 2,
  },
});

export default TaskAttemptPage;
