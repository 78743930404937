import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { LoadingContainer, EmptyState } from "src/main/components";
import { PersonModel } from "src/main/types";
import { Detail, Edit, Privilege } from "./components";

interface UserDetailProps extends React.PropsWithChildren {

}

const tabs = [
  { value: "details", label: "Details" },
  // { value: "credentials", label: "Credentials" },
]

const UserDetailPage: React.FC<UserDetailProps> = (props) => {
  const { userId } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPrivilege, setShowEditPrivilege] = useState(false);

  const [tabVal, setTabVal] = useState("details");

  const result = accountApi.useGetUserQuery({ personId: userId ?? "" });

  const privResult = accountApi.useListPrivilegesQuery();

  const person: PersonModel | undefined = useMemo(() => result?.data?.model ?? undefined, [result?.data?.model]);

  if (result.error) {
    toast.error("Failed to retrieve worker detail");
  }
  const privAcc = person?.entity?.accountEntities?.find((accEntity) => accEntity.status === "active");

  const hasPerson = !result.isLoading && person;
  return (
    <LoadingContainer loading={result.isLoading}>
      {!result.isLoading && !person && (<EmptyState />)}
      {!!person && (
        <>
          {tabVal === "details" && (
            <>
              {!showEditPrivilege && (
                <>
                  {(hasPerson && showEdit)
                    ? <Edit toggleEdit={() => setShowEdit(false)} person={person!} />
                    : <Detail
                      tabs={tabs} currentTab={tabVal} updateTabs={setTabVal}
                      toggleEdit={() => setShowEdit(true)}
                      person={person!}
                      activeAcc={privAcc}
                      toggleEditPrivilege={() => setShowEditPrivilege(true)}
                    />
                  }
                </>
              )}
              {
                !!(hasPerson && showEditPrivilege) && (
                  <LoadingContainer loading={privResult.isLoading}>
                    <Privilege
                      toggleEdit={() => setShowEditPrivilege(false)}
                      privilegesResponse={privResult.data}
                      activeAcc={privAcc}
                      personId={person.id}
                    />
                  </LoadingContainer>
                )
              }
            </>
          )}
          {/* {tabVal === "credentials" && (
            <Credentials tabs={tabs} currentTab={tabVal} updateTabs={setTabVal} credential={person!.entity?.credentials?.[0]} userId={person.id} />
          )} */}
        </>
      )}
    </LoadingContainer>
  )

};

export default UserDetailPage;