import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Card, CardContent, Container, useTheme, Link, InputAdornment, Divider } from '@mui/material';
import queryString from "query-string";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { authApi } from 'src/main/api';
import { AuthLayout, FormField, LoadingContainer, Typography } from "src/main/components";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { commonStyles, createStyles, handleApiResponse, joinSx, Paths, IntlFormatter } from "src/main/utils";
import * as Yup from "yup";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface PasswordResetProps extends React.PropsWithChildren {

}

interface FormState {
  secret: string;
  confirm: string;
}
const initialFormState: FormState = {
  secret: "",
  confirm: "",
};
const Field = FormField<FormState>;

const PasswordReset: React.FC<PasswordResetProps> = (props) => {
  const location = useLocation()
  const token = useMemo(() => {
    const query = queryString.parse(location.search);
    if (Array.isArray(query.token))
      return query.token[0] ?? "";
    if (typeof query.token === "string")
      return query.token;
    return "";
  }, [location.search]);

  const validationSchema = Yup.object().shape({
    secret: Yup.string().required("Password is required"),
    confirm: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("secret"), null], "Password doesn't match"),
  });

  const navigate = useNavigate();
  const [formStates, { onValueChange, consumeAPIError, setError, validate }] = useFormState(initialFormState, validationSchema);
  const [isSuccess, setIsSuccess] = useState(false);
  const result = authApi.usePasswordRequestTokenQuery(token);
  const [passwordRequest] = authApi.usePasswordResetMutation();
  const isInitialising = result.isLoading || result.isFetching;
  const passwordResetSuccessMessage = IntlFormatter.getMessage("passwordResetPage._password_reset_success", "Success! Password reset request submitted.");


  const { request } = useMemo(() => {
    let request = result.data?.result ?? undefined
    return {
      request,
    }
  }, [result.data]);


  const [runPasswordReset, loading, error] = useAsyncTask("password/request");
  const { palette } = useTheme();

  const onNavigateLogin = () => {
    navigate(Paths.Auth.Login);
  }

  const onNavigateResetPassword = () => {
    navigate(Paths.Auth.RequestPassword);
  }


  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = useCallback(() => {
    runPasswordReset(async () => {
      setError({});
      if (validate()) return;

      const result = await passwordRequest({
        token,
        secret: formStates.input.secret,
      });

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null)
          return;
        throw new Error(error.data?.error?.message)
      }

      setIsSuccess(true);
      toast.success(passwordResetSuccessMessage);
    });
  }, [runPasswordReset, passwordRequest, consumeAPIError, formStates.input, token, setError, validate, passwordResetSuccessMessage]);

  const getNewPasswordMessage = () => {
    if (!isSuccess) {
      return (
        <Typography variant="body2" >Hi <Box component="strong">{request?.firstname}</Box> (<Link href={Paths.Auth.Login}>{IntlFormatter.getMessage("passwordResetPage.not_me", "not me")}</Link>), {IntlFormatter.getMessage("passwordResetPage._password_reset_instruction", "to create a strong password, please use unique words, at least 12 characters, and a combination of uppercase letters, lowercase letters, numbers, and symbols.")}</Typography>
      )
    }
    return (
      <Typography variant="body2" color="textSecondary">
        Hi <Box component="strong">{request?.firstname}</Box> {IntlFormatter.getMessage("passwordResetPage._password_successfully_updated", "Your password was successfully updated.")}
      </Typography>
    )
  }

  return (
    <Container sx={joinSx(commonStyles.authContainer, styles.root)} maxWidth="sm">
      <LoadingContainer loading={isInitialising}>
        <Card>
          <AuthLayout.CardBanner />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div>
                {!!request && (
                  <Typography formatId={isSuccess ? "passwordResetPage.success" : "passwordResetPage.create_new_password"} variant="h5">
                    {isSuccess ? "Success" : "Create New Password"}
                  </Typography>
                )}
                {!request && (
                  <Typography formatId="passwordResetPage.reset_password" variant="h5">
                    Reset Password
                  </Typography>
                )}
                {!!request && getNewPasswordMessage()}
                {!request && (

                  <Typography formatId="passwordResetPage._reset_password_link_invalid" variant="body2" color="textSecondary">
                    The reset password link you clicked on has either expired or has already been used.
                  </Typography>
                )}
              </div>
            </Box>
          </CardContent>
          {!!request && (
            <CardContent sx={{ p: 3, pt: 1 }}>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {!isSuccess && (
                  <form onSubmit={(event) => event.preventDefault()}>
                    <Field
                      label={IntlFormatter.getMessage("passwordResetPage.password", "Password")}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      fieldKey="secret"
                      onValueChange={onValueChange}
                      formStates={formStates}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Field
                      label={IntlFormatter.getMessage("passwordResetPage.confirm_password", "Confirm Password")}
                      name="confirm-password"
                      type={showPassword ? 'text' : 'password'}
                      fieldKey="confirm"
                      onValueChange={onValueChange}
                      formStates={formStates}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {typeof error?.message === "string" && (
                      <Typography color="red">{error.message}</Typography>
                    )}
                    <Box sx={{ mt: 2 }}>
                      <LoadingButton
                        fullWidth
                        loading={loading}
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={onSubmit}
                        sx={{ background: palette.gradient?.main }}
                      >
                        {IntlFormatter.getMessage("passwordResetPage.reset_password", "Reset password")}
                      </LoadingButton>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: "center" }}>
                      <Button onClick={onNavigateLogin}>{IntlFormatter.getMessage("passwordRequestPage.back_to_log_in", "Back to Log In")}</Button>
                    </Box>
                  </form>
                )}
                {isSuccess && (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={onNavigateLogin}
                  >
                    {IntlFormatter.getMessage("passwordResetPage.login_now", "Login now")} 🚀
                  </Button>
                )}
              </Box>
            </CardContent>
          )}
          {!request && (
            <CardContent sx={{ p: 3, pt: 0 }}>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <LoadingButton
                  fullWidth
                  loading={loading}
                  size="large"
                  type="submit"
                  variant="outlined"
                  onClick={onNavigateResetPassword}
                  sx={{ color: palette.primary.main }}
                >
                  {IntlFormatter.getMessage("passwordResetPage.request_new_reset_password_link", "Request New Reset Password Link")}
                </LoadingButton>
                <Divider sx={{ px: 3, py: 1.5 }}>
                  <Typography variant="overline" fontWeight={600} color="text.secondary">OR</Typography>
                </Divider>

                <LoadingButton
                  fullWidth
                  loading={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={onNavigateLogin}
                >
                  {IntlFormatter.getMessage("passwordRequestPage.back_to_log_in", "Back to Log In")}
                </LoadingButton>
              </Box>
            </CardContent>
          )}
          <AuthLayout.EnvStatus />
        </Card>
      </LoadingContainer>
    </Container >
  );
};

const styles = createStyles({
  root: {
  },
});

export default PasswordReset;
