import { Box, BoxProps, Typography } from "@mui/material";
import { createStyles, joinSx } from "src/main/utils";
import React from "react";
import EmptyStatePng from "./empty-search.png";


interface Props extends BoxProps {
  message?: string;
  subMessage?: string;
  graphic?: React.ReactNode;
}

const ListingEmptyState: React.FC<Props> = (props: Props) => {
  const { message = "No Results Found", subMessage, graphic, children, sx, ...rest } = props;

  return (
    <Box p={6} textAlign="center" width="100%" justifyContent="center" display="flex" flexDirection="column" {...rest} sx={joinSx(styles.root, sx)}>
      <Typography lineHeight="33px" variant="h6" >{message}</Typography>
      {subMessage && <Typography variant="caption" color="textSecondary" >{subMessage}</Typography>}
      <img alt="empty-search" style={{ width: "150px", alignSelf: "center" }} src={EmptyStatePng} />
    </Box>
  );
};

const styles = createStyles({
  root: {

  },
});

export default ListingEmptyState;
