import { BoxProps } from "@mui/material";
import React, { useMemo } from "react";
import { CalculatorIcon } from "src/assets";
import { workerApi } from "src/main/api";
import { LoadingContainer, NavigateButton, Typography } from "src/main/components";
import { Paths, IntlFormatter } from "src/main/utils";

interface WalletBalanceProps extends BoxProps {

}

const WalletBalance: React.FC<WalletBalanceProps> = (props) => {
  const { sx, ...boxProps } = props;

  const withdrawsResult = workerApi.useWorkerWithdrawListQuery({ meta: { status: "submitted", currency: "PHP" } });

  const { count } = useMemo(() => {
    const withdraws = withdrawsResult.data?.entries ?? [];
    const count = withdraws.length;
    return { count };
  }, [withdrawsResult])

  return (
    <LoadingContainer loading={withdrawsResult.isLoading} sx={sx}  {...boxProps}>
      <NavigateButton.Vertical
        icon={(<CalculatorIcon color="primary" />)}
        title={IntlFormatter.getMessage("overview.pendingPayout", "pending payout")}
        content={(
          <Typography variant="h6">{count}</Typography>
        )}
        linkTitle={IntlFormatter.getMessage("overview.view", "View")}
        path={Paths.Portal.WithdrawHistoryList + "?tab=submitted"}
      />
    </LoadingContainer >
  );
};

export default WalletBalance;