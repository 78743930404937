import { Box, BoxProps } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyTasksGraphic } from "src/assets";
import { workerApi } from "src/main/api";
import {
  ListingComp,
  PortalLayout,
  WorkerAttemptList,
} from "src/main/components";
import { Meta, TaskAttemptModel } from "src/main/types";
import { createStyles, IntlFormatter, Paths } from "src/main/utils";

interface RejectedTasksProps extends BoxProps {
  updateNoTaskByStatus: (count: number) => void;
}

const RejectedTasks: React.FC<RejectedTasksProps> = ({
  updateNoTaskByStatus,
}) => {
  const navigate = useNavigate();

  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 20, offset: 0 });
  const result = workerApi.useWorkerAttemptsQuery({
    ...meta,
    status: "in-progress",
    limit: "20",
  });
  const persisMeta = useMemo(
    () => ({ ...meta, ...result.data?.meta }),
    [result.data, meta]
  );

  const tasks = useMemo(() => {
    // temperorary filter tasks that has not been accepted by tasker
    const rejectedTasks = result.data?.entries ?? [];

    return rejectedTasks.reduce((acc: TaskAttemptModel[], curr) => {
      if (curr.rejectReason !== null) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }, [result.data]);

  useEffect(() => {
    updateNoTaskByStatus(tasks.length);
  }, [tasks.length, updateNoTaskByStatus]);

  return (
    <Box>
      {tasks.length > 0 && (
        <ListingComp
          title=""
          loading={result.isLoading}
          updateList={(newMeta) => {
            setMeta(newMeta);
          }}
          meta={persisMeta}
          sx={styles.list}
          mode="tasker"
        >
          <ListingComp.Content>
            <WorkerAttemptList attempts={tasks} />
          </ListingComp.Content>
        </ListingComp>
      )}

      {tasks.length === 0 && !result.isUninitialized && (
        <PortalLayout.EmptyState
          cta={IntlFormatter.getMessage(
            "taskPage.available",
            "Accept Task Now"
          )}
          graphic={
            <EmptyTasksGraphic
              style={{ height: "100px", marginBottom: "1rem", opacity: "0.7" }}
            />
          }
          label={IntlFormatter.getMessage(
            "taskPage.have_not_accept_tasks",
            "You have not accepted any tasks yet."
          )}
          onClick={() => navigate(Paths.Portal.Home)}
        />
      )}
    </Box>
  );
};
const styles = createStyles({
  list: {
    m: 0,
    backgroundColor: "inherit",
    boxShadow: "inherit",
  },
});
export default RejectedTasks;
