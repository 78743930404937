import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Dayjs } from "dayjs";
import moment from "moment";
import React from "react";
import { DateInput, DetailCard, TimePicker } from "src/main/components";
import { SimpleMap } from "src/main/utils";

interface TaskScheduleProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const TaskScheduleComponent = (props: TaskScheduleProps) => {
  const { values, setFieldValue } = props;

  const updateDate = (date: Dayjs | null, field: string) => {
    if (!date) {
      setFieldValue(field, "");
    } else {
      setFieldValue(field, moment(date.toString()).format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  return (
    <DetailCard>
      <DetailCard.Header header="Task Schedule" />
      <DetailCard.Divider />
      <Box p={2} gap={2} >
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Set a start or expiry</Typography>
            {/* <Switch onChange={(ev, check) => setFieldValue( )} /> */}
          </Box>
          <Typography variant="body2" color="text.secondary">Enable this to set when the task will be visible (Start) to workers, or when the task will be hidden (Expiry) from the workers. Note: the task will not be visible if the status is not Published.</Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-evenly" gap={2} pt={2}>
          <FormControlLabel control={
            <Switch
              defaultChecked={!!values.start}
              onChange={(ev, check) => setFieldValue("enableStart", check)}
            />
          } label="Start" sx={{ width: 100 }} />
          <DateInput
            label="Start Date"
            value={values.start}
            handleChange={(date: Dayjs | null) => updateDate(date, "start")}
            flex={1}
            disabled={!values.enableStart}
          />
          <TimePicker
            label="Start Time"
            value={values.start}
            handleChange={(date: Dayjs | null) => updateDate(date, "start")}
            flex={1}
            disabled={!values.enableStart}
          />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-evenly" gap={2} pt={2}>
          <FormControlLabel control={
            <Switch
              defaultChecked={values.expiry}
              onChange={(ev, check) => setFieldValue("enableExpiry", check)}
            />
          } label="Expiry" sx={{ width: 100 }} />
          <DateInput
            label="Expiry Date"
            value={values.expiry}
            handleChange={(date: Dayjs | null) => updateDate(date, "expiry")}
            flex={1}
            disabled={!values.enableExpiry}
          />
          <TimePicker
            label="Expiry Time"
            value={values.expiry}
            handleChange={(date: Dayjs | null) => updateDate(date, "expiry")}
            flex={1}
            disabled={!values.enableExpiry}
          />
        </Box>
      </Box>
    </DetailCard>

  )
}

export default TaskScheduleComponent;