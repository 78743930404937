import { createSvgIcon } from '@mui/material/utils';

const DuplicateIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.81538 1.67715C2.59658 1.67715 2.38674 1.76407 2.23202 1.91879C2.0773 2.0735 1.99038 2.28334 1.99038 2.50215V8.50215C1.99038 8.72095 2.0773 8.93079 2.23202 9.08551C2.38674 9.24023 2.59658 9.32715 2.81538 9.32715H3.64038V5.50215C3.64038 4.9253 3.86953 4.37208 4.27742 3.96419C4.68532 3.5563 5.23853 3.32715 5.81538 3.32715H9.64038V2.50215C9.64038 2.28335 9.55346 2.0735 9.39874 1.91879C9.24403 1.76407 9.03418 1.67715 8.81538 1.67715H2.81538ZM10.9904 3.32715V2.50215C10.9904 1.9253 10.7612 1.37208 10.3533 0.964191C9.94545 0.5563 9.39223 0.327148 8.81538 0.327148H2.81538C2.23854 0.327148 1.68532 0.556299 1.27742 0.964191C0.869532 1.37208 0.640381 1.9253 0.640381 2.50215V8.50215C0.640381 9.07899 0.869532 9.63221 1.27742 10.0401C1.68531 10.448 2.23853 10.6771 2.81538 10.6771H3.64038V11.5021C3.64038 12.079 3.86953 12.6322 4.27742 13.0401C4.68532 13.448 5.23853 13.6771 5.81538 13.6771H11.8154C12.3922 13.6771 12.9454 13.448 13.3533 13.0401C13.7612 12.6322 13.9904 12.079 13.9904 11.5021V5.50215C13.9904 4.9253 13.7612 4.37208 13.3533 3.96419C12.9454 3.5563 12.3922 3.32715 11.8154 3.32715H10.9904ZM5.81538 4.67715C5.59658 4.67715 5.38674 4.76407 5.23202 4.91879C5.0773 5.0735 4.99038 5.28335 4.99038 5.50215V11.5021C4.99038 11.721 5.0773 11.9308 5.23202 12.0855C5.38674 12.2402 5.59658 12.3271 5.81538 12.3271H11.8154C12.0342 12.3271 12.244 12.2402 12.3987 12.0855C12.5535 11.9308 12.6404 11.721 12.6404 11.5021V5.50215C12.6404 5.28335 12.5535 5.0735 12.3987 4.91879C12.244 4.76407 12.0342 4.67715 11.8154 4.67715H5.81538Z" fill="currentColor" />
  </svg>
  ,
  'DuplicateIcon'
);

export default DuplicateIcon;

