import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { accountApi } from "src/main/api";
import { DetailCard, FormField, LoadingButton, LoadingContainer, Page, Table, TableRowWrap } from "src/main/components";
import { handleApiResponse, Paths } from "src/main/utils";
import EditIcon from '@mui/icons-material/Edit';
import { useAsyncTask, useFormState } from "src/main/hooks";
import { TeamModel } from "src/main/types";
import { toast } from "react-hot-toast";

interface EditTeamPageProps extends React.PropsWithChildren {
}


interface FormState {
  name: string;
  description: string;
}

const initialFormState: FormState = {
  name: "",
  description: "",
}

const Field = FormField<FormState>;

const EditTeamPage: React.FC<EditTeamPageProps> = (props) => {
  const { teamId = "" } = useParams();

  const navigate = useNavigate();

  const result = accountApi.useGetTeamQuery({ teamId });
  const teamData = useMemo(() => result.data?.model ?? undefined, [result?.data?.model]);
  const [formState, { onValueChange, consumeAPIError, setInput }] = useFormState(initialFormState);

  const [updateTeam] = accountApi.useUpdateTeamMutation();
  const [runUpdateTeam, loading] = useAsyncTask("update/team");

  useEffect(() => {
    if (teamData) {
      setInput((prev) => {
        return {
          ...prev,
          name: teamData.name,
          description: teamData.description
        }
      })
    }
    // eslint-disable-next-line
  }, [teamData, setInput])

  const onUpdate = useCallback(() => {
    runUpdateTeam(async () => {

      const result = await updateTeam({
        teamId: teamId,
        data: {
          name: formState.input.name,
          description: formState.input.description
        }
      })

      const { data, error } = handleApiResponse<TeamModel>(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null) return;
        throw new Error(error.data?.error?.message)
      }
      if (!data?.id)
        throw new Error("Invalid response");
      toast.success("Team updated!");
      navigate(Paths.Management.TeamDetail.replace(":teamId", data.id));
    });
    // eslint-disable-next-line
  }, [runUpdateTeam, updateTeam, formState, consumeAPIError, navigate, setInput, teamId])

  return (
    <Page>
      <Page.TopSection title={"Team Detail"}>
        <Box>
          <Button
            type="submit"
            variant="contained"
            onClick={() => navigate(Paths.Management.TeamDetail.replace(":teamId", teamId))}
          >
            <Typography>Cancel</Typography>
          </Button>
          {teamData && (
            <LoadingButton
              loading={loading}
              sx={{ marginLeft: 2 }}
              type="submit"
              variant="contained"
              onClick={() => onUpdate()}
            >
              <EditIcon />&nbsp;<Typography>Update</Typography>
            </LoadingButton>
          )}
        </Box>
      </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        <Page.Content>
          <Box flex={1} display="flex">
            <DetailCard sx={{ maxWidth: 600, flex: 1 }} >
              <DetailCard.Header header="Team Information" />
              <DetailCard.Divider />
              <Box p={2}>
                <Table>
                  <Table.Body>
                    <TableRowWrap
                      label="Name"
                      value={
                        <Field
                          name="name"
                          type="string"
                          fieldKey="name"
                          formStates={formState}
                          onValueChange={onValueChange}
                        />
                      }
                    />

                    <TableRowWrap
                      label="Description"
                      value={
                        <Field
                          name="description"
                          type="string"
                          fieldKey="description"
                          formStates={formState}
                          onValueChange={onValueChange}
                        />
                      }
                    />
                  </Table.Body>
                </Table>
              </Box>
            </DetailCard>
          </Box>
        </Page.Content>
      </LoadingContainer>
    </Page>
  );
};

export default EditTeamPage;