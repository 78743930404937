import React, { useEffect } from "react";


interface EnforceDomainProps {
}

const EnforceDomain: React.FC<EnforceDomainProps> = () => {  
  useEffect(() => {
    const forcedDomain = process.env.REACT_APP_ENFORCE_DOMAIN;
    if (typeof forcedDomain === "string") {
      const location = window.location.href;
      if (!location.startsWith(forcedDomain))
        window.location.href = `${forcedDomain}${window.location.pathname}`;
    }
  }, [])
  
  return <></>;
};
export default EnforceDomain;
