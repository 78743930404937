import {
  Container,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InformationCircleIcon, PencilAltIcon } from "src/assets";
import {
  Table as CustomTable,
  PortalLayout,
  Typography,
} from "src/main/components";
import { BackButton } from "src/main/components/PortalLayout/components";
import { RootState } from "src/main/store";
import preference from "src/main/store/preference";
import { CredentialModel, PersonModel, WorkerModel } from "src/main/types";
import { IntlFormatter, createStyles } from "src/main/utils";
import { Enquiry, ProfileForm, ProfileNameForm } from "./components";

interface SettingsPageProps extends React.PropsWithChildren {}

interface FieldInfo {
  label: string;
  placeholder: string;
  updateKey?: string;
}

const SettingsPage: React.FC<SettingsPageProps> = (props) => {
  const dispatch = useDispatch();
  const worker = useSelector<RootState, WorkerModel | undefined | null>(
    (state) => state.auth.worker
  );
  const personInfo = useSelector<RootState, PersonModel | undefined>(
    (state) => state.auth.profile?.person
  );
  const credentials = useSelector<RootState, CredentialModel[] | undefined>(
    (state) => state.auth.profile?.credentials
  );

  const theme = useSelector<RootState>((state) => state.preference.theme);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogValue, setEditDialogValue] = useState("");
  const [editField, setEditField] = useState<FieldInfo | undefined>();

  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editNameDialogValue, setEditNameDialogValue] = useState({
    firstName: personInfo?.firstname ?? "",
    middleName: personInfo?.middlename ?? "",
    lastName: personInfo?.lastname ?? "",
  });
  const [editNameDialogLabel, setEditNameDialogLabel] = useState("");

  const handleEditClick = (value: string, label: FieldInfo) => {
    setEditDialogValue(value);
    setEditField(label);
    setEditDialogOpen(true);
  };

  const handleEditNameClick = (
    firstName: string,
    middleName: string,
    lastName: string,
    label: FieldInfo
  ) => {
    setEditNameDialogValue({
      firstName,
      middleName,
      lastName,
    });
    setEditNameDialogLabel(label.label);
    setEditNameDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditNameDialogClose = () => {
    setEditNameDialogOpen(false);
  };

  const onChangeTheme = (evt: React.ChangeEvent, darkMode: boolean) => {
    dispatch(
      preference.slice.actions.setThemeMode(darkMode ? "dark" : "light")
    );
  };

  const fieldLabels = useMemo(() => {
    return {
      username: {
        label: IntlFormatter.getMessage("settingPage.username", "Username"),
        placeholder: "e.g: tomcruise",
        updateKey: "credential.accessKey",
      },
      full_name: {
        label: IntlFormatter.getMessage("settingPage.full_name", "Full Name"),
        placeholder: "e.g: Tom Cruise",
      },
      email: {
        label: IntlFormatter.getMessage("settingPage.email", "Email"),
        placeholder: "e.g: tom@example.com",
        updateKey: "primaryEmail",
      },
      mobile: {
        label: IntlFormatter.getMessage("settingPage.mobile", "Mobile"),
        placeholder: "e.g: 1234567890",
      },
      telegram: {
        label: IntlFormatter.getMessage("settingPage.telegram", "Telegram"),
        placeholder: "e.g: tomcruise",
        updateKey: "telegramHandle",
      },
      facebook: {
        label: IntlFormatter.getMessage("settingPage.facebook", "Facebook"),
        placeholder: "e.g: tomcruise",
        updateKey: "facebookHandle",
      },
      facebook_alt: {
        label: IntlFormatter.getMessage(
          "settingPage.facebook_alt",
          "Facebook(Alt)"
        ),
        placeholder: "e.g: tomcruise",
        updateKey: "facebookAltHandle",
      },
      instagram: {
        label: IntlFormatter.getMessage("settingPage.instagram", "Instagram"),
        placeholder: "e.g: tomcruise",
        updateKey: "instagramHandle",
      },
      tiktok: {
        label: IntlFormatter.getMessage("settingPage.tiktok", "TikTok"),
        placeholder: "e.g: @tomcruise",
        updateKey: "tiktokHandle",
      },
      twitter: {
        label: IntlFormatter.getMessage("settingPage.twitter", "Twitter"),
        placeholder: "e.g: tomcruise",
        updateKey: "twitterHandle",
      },
      discord: {
        label: IntlFormatter.getMessage("settingPage.discord", "Discord"),
        placeholder: "e.g: tomcruise#3079",
        updateKey: "discordHandle",
      },
    };
  }, []);

  return (
    <Container sx={styles.container}>
      <BackButton
        label={IntlFormatter.getMessage("settingPage.back", "Back")}
      />
      <Typography formatId="settingPage.settings" variant="h5" pb={2}>
        Settings
      </Typography>
      <PortalLayout.SectionTitle sx={{ color: "text.secondary" }}>
        {IntlFormatter.getMessage("settingPage.profile", "PROFILE")}
      </PortalLayout.SectionTitle>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.user_id",
                  "User ID"
                )}
                value={worker?.id.substring(0, 8) ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <Tooltip
                  title={IntlFormatter.getMessage(
                    "settingPage.not_editable",
                    "Not editable"
                  )}
                  placement="top"
                >
                  <IconButton
                    sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  >
                    <InformationCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.username",
                  "Username"
                )}
                value={credentials?.[0].accessKey ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      credentials?.[0].accessKey ?? "",
                      fieldLabels.username
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.full_name",
                  "Full Name"
                )}
                value={`${personInfo?.firstname ?? ""} ${
                  personInfo?.lastname ?? ""
                }`}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditNameClick(
                      personInfo?.firstname ?? "",
                      personInfo?.middlename ?? "",
                      personInfo?.lastname ?? "",
                      fieldLabels.full_name
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <PortalLayout.SectionTitle>
        {IntlFormatter.getMessage("settingPage.contact", "CONTACT")}
      </PortalLayout.SectionTitle>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage("settingPage.email", "Email")}
                value={personInfo?.primaryEmail ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.primaryEmail ?? "",
                      fieldLabels.email
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage("settingPage.mobile", "Mobile")}
                value={personInfo?.primaryPhone ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <Tooltip
                  title={IntlFormatter.getMessage(
                    "settingPage.not_editable",
                    "Not editable"
                  )}
                  placement="top"
                >
                  <IconButton
                    sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  >
                    <InformationCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.telegram",
                  "Telegram"
                )}
                value={personInfo?.telegramHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.telegramHandle ?? "",
                      fieldLabels.telegram
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.gCashAccount",
                  "GCash Account"
                )}
                value={worker?.gCashAccount ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <Tooltip
                  title={IntlFormatter.getMessage(
                    "settingPage.not_editable",
                    "Not editable"
                  )}
                  placement="top"
                >
                  <IconButton
                    sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  >
                    <InformationCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <PortalLayout.SectionTitle>
        {IntlFormatter.getMessage("settingPage.social", "SOCIAL")}
      </PortalLayout.SectionTitle>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.facebook",
                  "Facebook"
                )}
                value={personInfo?.facebookHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.facebookHandle ?? "",
                      fieldLabels.facebook
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.facebook_alt",
                  "Facebook(Alt)"
                )}
                value={personInfo?.facebookAltHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.facebookAltHandle ?? "",
                      fieldLabels.facebook_alt
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.instagram",
                  "Instagram"
                )}
                value={personInfo?.instagramHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.instagramHandle ?? "",
                      fieldLabels.instagram
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage("settingPage.tiktok", "TikTok")}
                value={personInfo?.tiktokHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.tiktokHandle ?? "",
                      fieldLabels.tiktok
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.twitter",
                  "Twitter"
                )}
                value={personInfo?.twitterHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.twitterHandle ?? "",
                      fieldLabels.twitter
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <CustomTable.LabelValueCell
                label={IntlFormatter.getMessage(
                  "settingPage.discord",
                  "Discord"
                )}
                value={personInfo?.discordHandle ?? ""}
                sx={styles.customTableCell}
              />
              <TableCell align="right">
                <IconButton
                  sx={{ padding: 0, mt: 1, justifyContent: "flex-end" }}
                  onClick={() =>
                    handleEditClick(
                      personInfo?.discordHandle ?? "",
                      fieldLabels.discord
                    )
                  }
                >
                  <PencilAltIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <PortalLayout.SectionTitle>
        {IntlFormatter.getMessage("settingPage.preferences", "Preferences")}
      </PortalLayout.SectionTitle>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                {IntlFormatter.getMessage("settingPage.dark_mode", "Dark Mode")}
              </TableCell>
              <TableCell align="right">
                <Switch checked={theme === "dark"} onChange={onChangeTheme} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Enquiry />
      <ProfileForm
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        defaultValue={editDialogValue}
        label={editField?.label || ""}
        placeholder={editField?.placeholder || ""}
        updateKey={editField?.updateKey || ""}
      />
      <ProfileNameForm
        open={editNameDialogOpen}
        onClose={handleEditNameDialogClose}
        defaultValue={editNameDialogValue}
        label={editNameDialogLabel}
      />
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },
  content: {
    p: 2,
  },
  button: {
    ml: -2,
  },
  customTableCell: {
    wordBreak: "break-word",
    p: 2,
    py: 1,
  },
});

export default SettingsPage;
