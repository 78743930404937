import { createSvgIcon } from '@mui/material/utils';

const ClipBoardCheck = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50008 4.16667H5.83341C5.39139 4.16667 4.96746 4.34226 4.6549 4.65482C4.34234 4.96738 4.16675 5.39131 4.16675 5.83333V15.8333C4.16675 16.2754 4.34234 16.6993 4.6549 17.0118C4.96746 17.3244 5.39139 17.5 5.83341 17.5H14.1667C14.6088 17.5 15.0327 17.3244 15.3453 17.0118C15.6578 16.6993 15.8334 16.2754 15.8334 15.8333V5.83333C15.8334 5.39131 15.6578 4.96738 15.3453 4.65482C15.0327 4.34226 14.6088 4.16667 14.1667 4.16667H12.5001M7.50008 4.16667C7.50008 4.60869 7.67568 5.03262 7.98824 5.34518C8.3008 5.65774 8.72472 5.83333 9.16675 5.83333H10.8334C11.2754 5.83333 11.6994 5.65774 12.0119 5.34518C12.3245 5.03262 12.5001 4.60869 12.5001 4.16667M7.50008 4.16667C7.50008 3.72464 7.67568 3.30072 7.98824 2.98816C8.3008 2.67559 8.72472 2.5 9.16675 2.5H10.8334C11.2754 2.5 11.6994 2.67559 12.0119 2.98816C12.3245 3.30072 12.5001 3.72464 12.5001 4.16667M7.50008 11.6667L9.16675 13.3333L12.5001 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  ,
  'ClipBoardCheck'
);

export default ClipBoardCheck;
