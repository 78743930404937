import { createSvgIcon } from '@mui/material/utils';

const DesktopIcon = createSvgIcon(
  <svg viewBox="0 0 20 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.45783 2.45783C2.91104 2.00461 3.52573 1.75 4.16667 1.75H15.8333C16.4743 1.75 17.089 2.00461 17.5422 2.45783C17.9954 2.91104 18.25 3.52573 18.25 4.16667V12.5C18.25 13.1409 17.9954 13.7556 17.5422 14.2088C17.089 14.6621 16.4743 14.9167 15.8333 14.9167H12.8356L13.1772 16.2832L13.8637 16.9697C14.0782 17.1842 14.1423 17.5068 14.0262 17.787C13.9102 18.0673 13.6367 18.25 13.3333 18.25H6.66667C6.36332 18.25 6.08984 18.0673 5.97376 17.787C5.85767 17.5068 5.92184 17.1842 6.13634 16.9697L6.82278 16.2832L7.16442 14.9167H4.16667C3.52573 14.9167 2.91104 14.6621 2.45783 14.2088C2.00461 13.7556 1.75 13.1409 1.75 12.5V4.16667C1.75 3.52573 2.00461 2.91104 2.45783 2.45783ZM3.25 11.5833V12.5C3.25 12.7431 3.34658 12.9763 3.51849 13.1482C3.69039 13.3201 3.92355 13.4167 4.16667 13.4167H15.8333C16.0764 13.4167 16.3096 13.3201 16.4815 13.1482C16.6534 12.9763 16.75 12.7431 16.75 12.5V11.5833H3.25ZM16.75 10.0833H3.25V4.16667C3.25 3.92355 3.34658 3.69039 3.51849 3.51849C3.69039 3.34658 3.92355 3.25 4.16667 3.25H15.8333C16.0764 3.25 16.3096 3.34658 16.4815 3.51849C16.6534 3.69039 16.75 3.92355 16.75 4.16667V10.0833ZM8.71058 14.9167L8.25225 16.75H11.7478L11.2894 14.9167H8.71058Z" fill="currentColor" />
  </svg>
  ,
  'DesktopIcon'
);

export default DesktopIcon;
