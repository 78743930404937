import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { RootState } from "../store";
import auth from "../store/auth";
import { transformAccessToken } from "../utils";

export const queryWithAuth = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_TASK_API_BASE_URI}`,
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState;
    if (
      state.auth.oauth?.accessToken
      && state.auth.oauth?.expiresAt?.isAfter(moment())
      && api.endpoint !== "/oauth/access_token"
    )
      headers.set("Authorization", `Bearer ${state.auth.oauth.accessToken}`)
    else
      console.debug("header not set", api, state)
  },
});

export type QueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;
export const queryWithRefresh: QueryFn = async (args, api, extraOptions) => {
  let result = await queryWithAuth(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const state = api.getState() as RootState;
    let tokenReloadSuccess = false;
    if (
      state.auth.oauth?.refreshToken
      && state.auth.oauth?.refreshExpiresAt?.isAfter(moment())
    ) {
      const refreshToken = state.auth.oauth.refreshToken;
      const refreshResult = await queryWithAuth({
        method: "POST",
        url: "/oauth/access_token",
        body: {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        },
      }, api, extraOptions);
      if (refreshResult.data) {
        const authToken = transformAccessToken(refreshResult.data);
        api.dispatch(auth.slice.actions.updateAuthToken(authToken));
        result = await queryWithAuth(args, api, extraOptions);
        tokenReloadSuccess = true;
      }
    }

    if (!tokenReloadSuccess) {
      toast.error("Your session has expired, please log in again.");
      api.dispatch(auth.slice.actions.logout());
    }
  }
  return result;
};
