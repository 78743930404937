import React from "react";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export interface CrumbsProps {
  label: string;
  path?: string;
}

interface BreadcrumbProps extends React.PropsWithChildren {
  crumbs: CrumbsProps[]
}

const BreadcrumbComponent: React.FC<BreadcrumbProps> = (props) => {
  const { crumbs } = props;
  return (
    <Breadcrumbs
      separator="/"
      sx={{ mt: 1 }}
    >
      {crumbs.map((crumb: CrumbsProps) => (
        <>
          {!crumb.path && (
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {crumb.label}
            </Typography>
          )}
          {!!crumb.path && (
            <Link
              component={RouterLink}
              to={crumb.path}
            >
              <Typography
                color="primary"
                variant="subtitle2"
              >
                {crumb.label}
              </Typography>
            </Link>
          )}
        </>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbComponent;
