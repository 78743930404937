import LaunchIcon from '@mui/icons-material/Launch';
import {
  Link, CircularProgress, useTheme, Box, FormControl, Button, IconButton,
  InputLabel, MenuItem, OutlinedInput, Select, Typography
} from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { NotionIcon } from "src/assets";
import { accountApi } from 'src/main/api';
import { DetailCard, InputField, LoadingButton } from "src/main/components";
import { SimpleMap, createStyles, truncate, handleApiResponse } from "src/main/utils";
import { ContentCopy } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { useSelector } from "react-redux";
import { ThemeMode } from "src/main/store/preference/types";
import { RootState } from "src/main/store";
import { toast } from "react-hot-toast";
import { FormikErrors } from "formik";
import { useAsyncTask } from 'src/main/hooks';

interface TaskDetailProps extends React.PropsWithChildren {
  values: SimpleMap<any>;
  handleChange?: (e: string | React.ChangeEvent<any>) => void;
  errors?: SimpleMap<any>;
  touched?: SimpleMap<any>;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setErrors?: (errors: FormikErrors<{ notionUrl: string; }>) => void
}

interface Options {
  value: string;
  label: string;
}


const styles = createStyles({
  title: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})
const TaskDetailComponent = (props: TaskDetailProps) => {
  const { values, handleChange, handleBlur, errors = {}, touched = {}, setFieldValue, setErrors } = props;
  const currencyListResult = accountApi.useGetCurrencyListQuery({});

  const currencyListOption = useMemo(() => {

    const options: Options[] = [];
    const list = Object.keys(currencyListResult?.data?.currencies ?? {});

    list.forEach((curr) => {
      options.push({ value: curr, label: curr });
    })

    return options

  }, [currencyListResult.data]);
  const theme = useTheme();
  const curTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  const [runCheckNotion] = useAsyncTask("check/notion/url");
  const [runInvalidateNotionUrl] = useAsyncTask("invalidate/notion/url");

  const [getNotionUrl] = accountApi.useNotionUrlMutation();
  const [invalidateNotionUrl] = accountApi.useInvalidateNotionUrlMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const [notionUrlStatus, setNotionUrlStatus] = useState<string>("");
  const [notionUrlIcon, setNotionUrlIcon] = useState<JSX.Element>((<></>));
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const getStatusIcon = (status: string, color?: string) => {
    switch (status.toLowerCase()) {
      case "failed":
        return <CancelIcon sx={{ mr: .5, color }} />
      case "success":
      default:
        return <CheckCircleIcon sx={{ mr: .5, color }} />
    }
  };
  const getStatusColor = (status: string) => {
    const { palette } = theme;
    switch (status.toLowerCase()) {
      case "failed":
        return palette.error[curTheme];
      case "success":
        return palette.success[curTheme];
    }
  };

  const configIconStatus = (status: string, extraText?: string) => {
    switch (status) {
      case "loading":
        setLoading(true);
        setNotionUrlStatus("Checking..");
        break;
      case "failed":
        setNotionUrlIcon(getStatusIcon("failed", getStatusColor("failed")));
        setNotionUrlStatus("");
        break;
      case "success":
        setNotionUrlIcon(getStatusIcon("success", getStatusColor("success")));
        setNotionUrlStatus("Connected page title: " + extraText);
        break;
    }
  }
  const getStatusNotionUrl = (url: string) => {
    runCheckNotion(async () => {
      try {
        const result = await getNotionUrl({ url: url });
        const { error } = handleApiResponse(result);
        if (error?.data?.error) {
          throw new Error("URL Not Accessible")
        }
        else {
          configIconStatus("success", result["data"]["title"]);
        }
      } catch (error) {
        configIconStatus("failed");

        let newErr = { ...errors };
        newErr.notionUrl = "URL Not Accessible";
        setErrors?.(newErr);
      } finally {
        setLoading(false);
      }
    })
  }

  const onInvalidateNotionUrl = useCallback(() => {
    runInvalidateNotionUrl(async () => {
      if (!values.notionUrl) return;
      const result = await invalidateNotionUrl({ url: values.notionUrl });
      const { error } = handleApiResponse(result);
      if (error?.data?.error.message) {
        toast.error(error?.data?.error.message)
      }
    })
  }, [values.notionUrl, runInvalidateNotionUrl, invalidateNotionUrl])

  const debounceNotionUrlCheck = useCallback((value?: string) => {
    clearTimeout(timeoutId);

    let newErr = { ...errors };
    delete newErr.notionUrl

    setErrors?.(newErr);

    if (!value) return setLoading(false);

    configIconStatus("loading");

    setTimeoutId(setTimeout(() => {
      getStatusNotionUrl(value);
    }, 700))
    // eslint-disable-next-line
  }, [timeoutId, errors])

  const onChange = (event: React.ChangeEvent<any>) => {
    handleChange?.(event);
    debounceNotionUrlCheck(event.target.value);
  };

  useEffect(() => {
    if (values.notionUrl !== "") {
      debounceNotionUrlCheck(values.notionUrl);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DetailCard>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box sx={styles.title} flex={1}>
          <Typography>
            <DetailCard.Header header="Basic Detail" sx={{ minHeight: "fit-content" }} />
          </Typography>
        </Box>
        {!!values?.taskId && (
          <Button onClick={() => {
            navigator.clipboard.writeText(values?.taskId);
            toast.success("ID Copied");
          }}
            endIcon={<ContentCopy />} color="primary">
            ID: {"T-" + truncate(values?.taskId, 4)}
          </Button>
        )}
      </Box>
      <DetailCard.Divider />
      <Box p={2} gap={2} display="flex" flexDirection="column">

        <InputField
          onChange={handleChange}
          onBlur={handleBlur}
          label="Task Title"
          name="title"
          required
          InputLabelProps={{
            shrink: true,
          }}
          value={values.title}
          error={!!errors.title && !!touched.title}
          helperText={!!touched.title && errors.title}
        />

        <InputField
          multiline
          onChange={handleChange}
          onBlur={handleBlur}
          label="Task Description"
          name="description"
          required
          value={values.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description && !!touched.description}
          // helperText={!!touched.description && errors.description}
          helperText={
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{
                fontSize: 12,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}>{errors.description}</Typography>
              <Typography sx={{ fontSize: 12, textAlign: "end" }}>
                {`${(values?.description + "").length}/250 characters`}
              </Typography>
            </Box>
          }
        />

        <Box py={2} display="flex" gap={2}>
          <Box flex={1}>
            <InputField
              onChange={handleChange}
              onBlur={handleBlur}
              name="payAmount"
              label="Task Amount($)"
              required
              value={values.payAmount}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.payAmount && !!touched.payAmount}
              type="number"
            />
          </Box>
          <Box flex={1}>
            <FormControl required variant="outlined" sx={{ flex: 1 }} fullWidth>
              <InputLabel shrink>Currency</InputLabel>
              <Select
                input={<OutlinedInput notched label="Currency" />}
                value={values.payCurrency}
                error={!!errors.payCurrency}
                onChange={(ev) => setFieldValue("payCurrency", ev.target.value)}
                sx={{
                  paddingY: "12px",
                  paddingX: "10px",
                  '& .MuiSelect-select': {
                    p: 0,
                  }
                }}
              >
                {currencyListOption.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box py={2} gap={2}>
          <Typography>Task Instructions</Typography>
          <Typography color="text.secondary" variant="subtitle2">
            This service uses Notion integration to provide best editing and viewing experience. Custom content can be included in the final render to the worker by using encoded tags. <Link href="https://99tech.notion.site/Guide-GR-Smart-Codes-80d00a1a0cdc40889cc522531acb32a6" sx={{ fontWeight: "bold" }} target="_blank" >Learn more</Link>.
          </Typography>
        </Box>

        <InputField
          onChange={onChange}
          name="notionUrl"
          label="Task Detail"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <NotionIcon sx={{ marginRight: 2 }} />,
            endAdornment: (
              <>
                {loading && <Box sx={styles.loaderContainer}>
                  <CircularProgress thickness={5} size={20} sx={{ mr: .5 }} />
                </Box>}
                {(!loading && values.notionUrl !== "") && notionUrlIcon}
                <IconButton
                  component="a"
                  href={values.notionUrl}
                  target="_blank"
                >
                  <LaunchIcon />
                </IconButton>
              </>
            )
          }}
          value={values.notionUrl}
          error={errors.notionUrl}
          helperText={errors.notionUrl ?? (!!values.notionUrl && notionUrlStatus)}
        />

        <Box pt={2} gap={2}>
          <Typography color="text.secondary" variant="subtitle2">
            The content on the Notion page may be cached for a certain period of time. To ensure that any changes you make are immediately reflected, click on the update cache button.
          </Typography>
        </Box>

        <LoadingButton
          fullWidth
          disabled={!values.notionUrl}
          loading={loading}
          size="large"
          type="submit"
          variant="contained"
          onClick={() => onInvalidateNotionUrl()}
        >
          <CloudSyncIcon />&nbsp; Update Cache
        </LoadingButton>
      </Box>
    </DetailCard>
  )
}

export default TaskDetailComponent;
