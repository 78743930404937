import { Box, BoxProps, Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accountApi } from "src/main/api";
import { EmptyState, LoadingContainer, Page, Tabs } from "src/main/components";
import { Paths } from "src/main/utils";
import { AffiliateTrackerListComp } from "../AffiliateTrackerList/components";
import { GenerateAffiliateLinkDialog } from "./components";
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import ShareBrand from "src/main/views/affiliate/Main/ShareBrand";

interface AffiliateDetailPageProps extends BoxProps {

}

const tabs = [
  { value: "details", label: "Details" },
  { value: "preview", label: "Preview" },
]

const AffiliateDetailPage: React.FC<AffiliateDetailPageProps> = (props) => {

  const navigate = useNavigate();
  const { affiliatePageId = "" } = useParams();
  const { search } = useLocation();

  const result = accountApi.useDetailAffiliatePageQuery(affiliatePageId);
  const affiliatePageContent = useMemo(() => result.data ?? undefined, [result.data]);
  const [openDialog, setOpenDialog] = useState(false);


  const tabVal = useMemo(() => {
    const vals = new URLSearchParams(search)

    if (vals.get("tab")) return vals.get("tab")
    return "details"
  }, [search]);

  const updateTab = (tab: string) => {
    navigate(Paths.Management.AffiliatePageDetail.replace(":affiliatePageId", affiliatePageId) + `?tab=${tab}`, { replace: true });
  }

  return (
    <Page>
      <Page.TopSection title="Affiliate Page Detail">
        <Box>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => { setOpenDialog(true); }}
          >
            <AddIcon />&nbsp;<Typography>Generate Affiliate Link</Typography>
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={() => navigate(Paths.Management.AffiliatePageEdit.replace(":affiliatePageId", affiliatePageId))}
          >
            <EditIcon />&nbsp;<Typography>Edit</Typography>
          </Button>
        </Box>
      </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        {!affiliatePageContent && (<EmptyState message="No affiliate page info" />)}
        {affiliatePageContent && (
          <Box>
            <Tabs value={tabVal} updateTab={updateTab} tabs={tabs} sx={{ paddingX: 2 }} />
            {tabVal === "details" && (
              <AffiliateTrackerListComp affiliatePageKey={affiliatePageContent.affiliatePageKey} brandKey={affiliatePageContent.brandKey} />
            )}
            {tabVal === "preview" && (
              <ShareBrand minHeight={600} affiliateComponents={affiliatePageContent.config?.components} affiliateMeta={affiliatePageContent.config?.meta} />
            )}
            <GenerateAffiliateLinkDialog
              affiliatePageKey={affiliatePageContent.affiliatePageKey}
              brandKey={affiliatePageContent.brandKey}
              open={openDialog}
              onClose={() => setOpenDialog(false)}
            />
          </Box>
        )}
      </LoadingContainer>
    </Page>
  );
};


export default AffiliateDetailPage;
