import { Box, Card, CardActions, CardContent, CardProps, Chip, Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { CashBillIcon, CashCheckedIcon } from "src/assets";
import { AttemptStatusChip, AttemptStatusText, TaskExpiryChip } from "src/main/components";
import { CurrencyDecimals } from "src/main/constants";
import { TaskAttemptModel } from "src/main/types";
import { commonStyles, createStyles } from "src/main/utils";
import { TaskSubmissionSlot } from "src/main/views/portal/Main/TaskDetailPage/components";

interface TaskCardProps extends CardProps {
  attempt: TaskAttemptModel;
}

const TaskCard: React.FC<TaskCardProps> = (props) => {
  const { attempt, ...rest } = props;
  const task = attempt.task;
  const theme = useTheme();
  const { pay } = useMemo(() => {
    const { payAmount, payCurrency = "?" } = task ?? {};
    const pay: { amount?: string, currency?: string } = {};
    if (task?.payAmount) {
      pay.amount = payAmount?.toFormat(CurrencyDecimals[payCurrency] ?? 2);
      pay.currency = payCurrency;
    }

    return { pay };
  }, [task]);

  const taskerStatistic = useMemo(() => {
    let acceptedSubmittedSlot = 0;

    if (task?.attemptStat) {
      acceptedSubmittedSlot = parseInt(task.attemptStat?.statusSubmitted) + parseInt(task.attemptStat?.statusApproved);
    }

    return acceptedSubmittedSlot + "/" + (task?.maxSubmission ?? "0");

  }, [task]);

  const getTaskAmount = (attempt: TaskAttemptModel, pay: { amount?: string, currency?: string }) => {
    switch (attempt.status) {
      case "approved": return <Box component="span" color="text.primary">{pay?.amount ?? "-"} {pay?.currency} Paid</Box>;
      case "terminated":
      case "cancelled":
      case "expired":
        return <Box component="span" color="text.primary">0 {pay?.currency} Paid</Box>;
      default: return <Box component="span" color="primary.main">+{pay?.amount ?? "-"} {pay?.currency}</Box>
    }
  }

  const getTaskCashIcon = (attempt: TaskAttemptModel) => {
    switch (attempt.status) {
      case "approved":
      case "terminated":
      case "cancelled":
      case "expired":
        return <CashCheckedIcon fontSize="small" style={{ color: theme.palette.neutral?.[500] }} />;
      default: return <CashBillIcon fontSize="small" style={{ color: theme.palette.neutral?.[500] }} />
    }
  }

  if (!task) return null;
  return (
    <Card {...rest} sx={{ mb: 2, mt: 2 }}>
      <CardContent sx={styles.content}>
        <Box display="flex" flexDirection="row">
          <Stack
            direction="row"
            alignItems="center"
          >
            <AttemptStatusChip attempt={attempt} />
          </Stack>
          <Box flex={1} />
          <TaskExpiryChip expiry={attempt.task?.expiry} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 1
          }}
        >
          <div>
            <Box display="flex">
              <Typography variant="h6" sx={styles.title}>
                {task.title}
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary" lineHeight={1.375} sx={styles.descripion}>
              {task.description}
            </Typography>
          </div>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 0, pl: 2, mb: 1 }}>
        <Chip
          sx={commonStyles.borderlessChip}
          icon={getTaskCashIcon(attempt)}
          label={getTaskAmount(attempt, pay)}
          variant="outlined"
        />
        {
          (attempt.status === "in-progress" ||
            attempt.status === "submitted" ||
            attempt.status === "rejected") && (<TaskSubmissionSlot submissionSlot={taskerStatistic} />)}

        <Box flex={1} />

        <AttemptStatusText attempt={attempt} />
      </CardActions>
    </Card>
  );
};

const styles = createStyles({
  title: {
    fontSize: {
      xs: "16px",
      md: "18px",
    },
  },
  descripion: {
    fontSize: {
      xs: "12px",
      md: "14px"
    },
    WebkitLineClamp: "2",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  content: {
    position: "relative",
    p: 2,
    "&:last-child": {
      pb: 1,
    }
  },
});

export default TaskCard;
