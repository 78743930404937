import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import queryString from "query-string";
import { QueryTags } from "src/main/constants";
import { QueryFn } from "../../base";
import { QueryResult } from "./invite";

type AuthApiBuilder<A extends string> = EndpointBuilder<QueryFn, typeof QueryTags.Worker[number], A>;

const getQueryString = (query?: object) => {
  if (!query) return "";
  if (!Object.keys(query).length) return "";
  return "?" + queryString.stringify(query);
}

export interface PasswordRequestRequest {
  email: string;
}
export const requestPasswordRequest = <A extends string>(builder: AuthApiBuilder<A>) => builder.mutation({
  query: (req: PasswordRequestRequest) => ({
    url: `password/request`,
    method: "POST",
    body: req,
    validateStatus: (response: Response) => response.status === 200,
  }),
})

export interface PasswordTokenQueryResponse {
  email: string;
  firstname: string;
  lastname?: string;
  middlename?: string;
  salutation?: string;
}

export const queryPasswordToken = <A extends string>(builder: AuthApiBuilder<A>) => builder.query({
  query: (token: string) => ({
    url: `password/query${getQueryString({ token })}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<PasswordTokenQueryResponse>) => {
    console.log("password/query", response);
    return response;
  },
})

export interface PasswordResetRequest {
  token: string;

  secret: string;
}
export const submitPasswordReset = <A extends string>(builder: AuthApiBuilder<A>) => builder.mutation({
  query: (req: PasswordResetRequest) => ({
    url: `password/reset`,
    method: "POST",
    body: req,
    validateStatus: (response: Response) => response.status === 200,
  }),
})
