import { fork, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { accountApi, authApi } from "src/main/api";
import { ProfileResponse } from "src/main/api/auth";
import { Defaults } from "src/main/constants";
import { execQuery } from "src/main/utils/saga";
import { default as authSlice, default as slice } from "./slice";
import { AuthToken, InitAuthPayload } from "./types";
import { default as preferenceSlice } from "src/main/store/preference/slice";

function* loadAccessToken() {
  try {
    const accessToken = localStorage.getItem(Defaults.AuthTokenKey);
    if (typeof accessToken !== "string") return null;

    const [, _payload] = accessToken.split(".");
    const payload = JSON.parse(atob(_payload));

    if (typeof payload.exp !== "number")
      throw new Error("cannot validate token expiry");

    const expiresAt = moment.unix(payload.exp);
    if (expiresAt.isBefore(moment())) {
      console.debug("token expired");
      return null;
    }

    const response: ProfileResponse = yield* execQuery(
      authApi.endpoints.authenticate.initiate(accessToken)
    );
    if (!response) {
      console.debug("cannot load profile from token");
      return null;
    }

    const result: InitAuthPayload = {
      profile: response.self,
      accounts: response.accounts,
      workspace: response.workspace,
      worker: response.worker,
      token: {
        accessToken,
        expiresAt,
        tokenType: "bearer",
      },
      currency: response.currency,
    };

    return result;
  } catch (error) {
    console.error("error loading access token");
    console.error(error);
    return null;
  }
}

function* getProfile() {
  const profile: ProfileResponse = yield* execQuery(
    authApi.endpoints.profile.initiate()
  );
  yield put(authSlice.actions.updateProfile(profile));
}

function* handleAction(action: PayloadAction<AuthToken>) {
  if (
    action.payload.accessToken &&
    action.payload.expiresAt.isAfter(moment())
  ) {
    yield getProfile();
  }
}

function* handleActionUpdateProfileAction(
  action: PayloadAction<{ refreshProfile?: boolean }>
) {
  if (action.payload.refreshProfile) {
    yield getProfile();
  }
}

function* queryAffiliateLink() {
  try {
    const affiliateResult = yield* execQuery(
      accountApi.endpoints.getAffiliateLink.initiate()
    );
    const { affiliate } = affiliateResult;
    if (affiliate) {
      yield put(preferenceSlice.actions.setAffiliateLink(affiliate));
    }
  } catch (error) {
    console.error(error);
  }
}

function* init() {
  const result = yield* loadAccessToken();
  if (!result) {
    yield put(authSlice.actions.initAuthState(null));
  } else {
    yield put(authSlice.actions.initAuthState(result));
    if (!result.worker) {
      yield* queryAffiliateLink();
    }
  }
}

function* saga() {
  yield takeLatest(slice.actions.updateAuthToken.type, handleAction);
  yield yield takeLatest(
    slice.actions.updateProfilePerson.type,
    handleActionUpdateProfileAction
  );

  yield fork(init);
}

export default saga;
