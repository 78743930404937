import { createSvgIcon } from "@mui/material/utils";

export const BrIcon = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2671_45085)">
      <path fillRule="evenodd" clip-rule="evenodd" d="M-3.72546 0.966675H24.2745V20.9667H-3.72546V0.966675Z" fill="url(#paint0_linear_2671_45085)" />
      <path fillRule="evenodd" clip-rule="evenodd" d="M-3.72546 0.966675H24.2745V20.9667H-3.72546V0.966675Z" fill="url(#paint1_linear_2671_45085)" />
      <path fillRule="evenodd" clip-rule="evenodd" d="M-0.496762 11.3303C-0.807158 11.1295 -0.800778 10.7997 -0.496762 10.603L9.71253 3.99699C10.0229 3.79615 10.5326 3.80027 10.8366 3.99699L21.0459 10.603C21.3563 10.8038 21.3499 11.1336 21.0459 11.3303L10.8366 17.9363C10.5262 18.1372 10.0165 18.1331 9.71253 17.9363L-0.496762 11.3303Z" fill="#FDD216" />
      <path d="M10.2745 15.6333C12.8518 15.6333 14.9411 13.5439 14.9411 10.9666C14.9411 8.38926 12.8518 6.29993 10.2745 6.29993C7.69713 6.29993 5.60779 8.38926 5.60779 10.9666C5.60779 13.5439 7.69713 15.6333 10.2745 15.6333Z" fill="url(#paint2_linear_2671_45085)" />
      <g mask="url(#mask0_2671_45085)">
        <path d="M5.47401 10.2649C5.82772 9.97846 7.42455 10.2116 10.1868 10.9484C12.1523 11.4727 14.4173 12.6303 14.9355 13.2703L15.3551 13.7884L16.3913 12.9494L15.9717 12.4313C15.2383 11.5256 12.7435 10.2505 10.5304 9.66013C7.08247 8.74038 5.51969 8.51222 4.63492 9.22869L4.11682 9.64824L4.95592 10.6844L5.47401 10.2649Z" fill="white" />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_2671_45085" x1="1396.27" y1="0.966675" x2="1396.27" y2="2000.97" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient id="paint1_linear_2671_45085" x1="1396.27" y1="0.966675" x2="1396.27" y2="2000.97" gradientUnits="userSpaceOnUse">
        <stop stopColor="#05AB41" />
        <stop offset="1" stopColor="#019C39" />
      </linearGradient>
      <linearGradient id="paint2_linear_2671_45085" x1="472.274" y1="6.29993" x2="472.274" y2="939.633" gradientUnits="userSpaceOnUse">
        <stop stopColor="#053087" />
        <stop offset="1" stopColor="#012877" />
      </linearGradient>
      <clipPath id="clip0_2671_45085">
        <rect y="0.966675" width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>


  ,
  'BrIcon'
);

export default BrIcon;

