import { Box, BoxProps, Typography } from "@mui/material";
import { createStyles, joinSx } from "src/main/utils";
import React from "react";

interface Props extends BoxProps {
  message?: string;
  subMessage?: string;
  graphic?: React.ReactNode;
}

const EmptyState: React.FC<Props> = (props: Props) => {
  const { message = "No Results Found", subMessage, graphic, children, sx, ...rest } = props;

  return (
    <Box p={6} textAlign="center" width="100%" justifyContent="center" display="flex" flexDirection="column" {...rest} sx={joinSx(styles.root, sx)}>
      <Typography lineHeight="33px" color="textSecondary" variant="h5" marginBottom="16px">{message}</Typography>
      {graphic}
      {subMessage && <Typography variant="body2" sx={{ color: "text.disabled" }} marginBottom="16px">{subMessage}</Typography>}
    </Box>
  );
};

const styles = createStyles({
  root: {

  },
});

export default EmptyState;
