import { Box, Card, Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { TaskModel } from "src/main/types";
import { createStyles } from "src/main/utils";

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from "moment";

interface TaskObjectiveDetailProps extends React.PropsWithChildren {
  task: TaskModel;
}


const TaskObjectiveDetailComponent = (props: TaskObjectiveDetailProps) => {
  const { task } = props;

  const noStartDate = !task.start;
  const noExpiryDate = !task.expiry;

  const started = moment(task.start).isValid() && moment().isAfter(task.start);
  const expired = moment(task.expiry).isValid() && moment().isAfter(task.expiry);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Card variant="outlined">
          <Grid sx={styles.baseCard} >
            <Box sx={styles.cardBox}>
              <Typography
                color={started ? "primary" : "textSecondary"}
                gutterBottom
                variant="overline"
              >
                {started ? `Task started ${moment(task.start).fromNow()}` : (noStartDate ? "Date not set" : `Task starting ${moment(task.start).fromNow()}`)}
              </Typography>
            </Box>
            <EventAvailableIcon color={started ? "primary" : (noExpiryDate ? "action" : "disabled")} />
          </Grid>
          <Grid sx={styles.baseCard} >
            <Box sx={styles.cardBox}>
              <Typography
                color={expired ? "error" : "textSecondary"}
                gutterBottom
                variant="overline"
              >
                {expired ? "Task expired" : (noExpiryDate ? "Date not set" : `Task expires ${moment(task.expiry).fromNow()}`)}
              </Typography>
            </Box>
            <EventBusyIcon color={expired ? "error" : (noExpiryDate ? "disabled" : "warning")} />
          </Grid>
          <Divider />
          <Box p={2} gap={2} display="flex" flexDirection="column">
            
            <Typography
              color="textSecondary"
              gutterBottom
              sx={{ pl: 1, fontSize: 12 }}
            >
              *Proceed to schedule to update start/expiry time
            </Typography>
          </Box>
        </Card>
      </Grid>

      
    </Grid >
  )
}

const styles = createStyles({
  baseCard: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    p: 3,
  },
  cardBox: {
    flexGrow: 1,
  }
})

export default TaskObjectiveDetailComponent;