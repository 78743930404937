import { createSvgIcon } from '@mui/material/utils';

const Verified = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.26698 3.92446C6.91024 3.87311 7.52089 3.6201 8.01198 3.20146C8.56664 2.72906 9.27141 2.4696 9.99998 2.4696C10.7286 2.4696 11.4333 2.72906 11.988 3.20146C12.4791 3.6201 13.0897 3.87311 13.733 3.92446C14.4594 3.98252 15.1414 4.29743 15.6567 4.81273C16.172 5.32803 16.4869 6.01003 16.545 6.73646C16.596 7.37946 16.849 7.99046 17.268 8.48146C17.7404 9.03613 17.9998 9.74089 17.9998 10.4695C17.9998 11.198 17.7404 11.9028 17.268 12.4575C16.8494 12.9486 16.5963 13.5592 16.545 14.2025C16.4869 14.9289 16.172 15.6109 15.6567 16.1262C15.1414 16.6415 14.4594 16.9564 13.733 17.0145C13.0897 17.0658 12.4791 17.3188 11.988 17.7375C11.4333 18.2099 10.7286 18.4693 9.99998 18.4693C9.27141 18.4693 8.56664 18.2099 8.01198 17.7375C7.52089 17.3188 6.91024 17.0658 6.26698 17.0145C5.54055 16.9564 4.85855 16.6415 4.34325 16.1262C3.82794 15.6109 3.51304 14.9289 3.45498 14.2025C3.40362 13.5592 3.15061 12.9486 2.73198 12.4575C2.25957 11.9028 2.00012 11.198 2.00012 10.4695C2.00012 9.74089 2.25957 9.03613 2.73198 8.48146C3.15061 7.99038 3.40362 7.37972 3.45498 6.73646C3.51304 6.01003 3.82794 5.32803 4.34325 4.81273C4.85855 4.29743 5.54055 3.98252 6.26698 3.92446V3.92446ZM13.707 9.17646C13.8891 8.98786 13.9899 8.73526 13.9877 8.47306C13.9854 8.21087 13.8802 7.96005 13.6948 7.77465C13.5094 7.58924 13.2586 7.48407 12.9964 7.48179C12.7342 7.47951 12.4816 7.58031 12.293 7.76246L8.99998 11.0555L7.70698 9.76246C7.51838 9.58031 7.26578 9.47951 7.00358 9.48179C6.74138 9.48407 6.49057 9.58924 6.30516 9.77465C6.11976 9.96005 6.01459 10.2109 6.01231 10.4731C6.01003 10.7353 6.11082 10.9879 6.29298 11.1765L8.29298 13.1765C8.48051 13.3639 8.73482 13.4693 8.99998 13.4693C9.26515 13.4693 9.51945 13.3639 9.70698 13.1765L13.707 9.17646V9.17646Z" />
  </svg>
  ,
  'Verified'
);

export default Verified;
