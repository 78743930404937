import { Box, Button, Card, CardActions, CardContent, CardProps, Chip, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { CashBillIcon, ChevronRight } from "src/assets";
import { TaskExpiryChip } from "src/main/components/_taskerPortal";
import { CurrencyDecimals } from "src/main/constants";
import { TaskModel } from "src/main/types";
import { commonStyles, createStyles } from "src/main/utils";
import { TaskSubmissionSlot } from "src/main/views/portal/Main/TaskDetailPage/components";

interface TaskCardProps extends CardProps {
  task: TaskModel;
}

const TaskCard: React.FC<TaskCardProps> = (props) => {
  const { task, ...rest } = props;
  const theme = useTheme();
  const { pay } = useMemo(() => {
    const { payAmount, payCurrency = "?" } = task;
    const pay: { amount?: string, currency?: string } = {};
    if (task.payAmount) {
      pay.amount = payAmount?.toFormat(CurrencyDecimals[payCurrency] ?? 2);
      pay.currency = payCurrency;
    }
    return {
      pay
    };
  }, [task]);

  const taskerStatistic = useMemo(() => {
    let acceptedSubmittedSlot = 0;

    if (task.attemptStat) {
      acceptedSubmittedSlot = parseInt(task.attemptStat?.statusInProgress) + parseInt(task.attemptStat?.statusSubmitted) + parseInt(task.attemptStat?.statusApproved);
    }

    return acceptedSubmittedSlot + "/" + (task?.maxAccept ?? "0");

  }, [task]);

  return (
    <Card {...rest} sx={{ mb: 2, mt: 2 }}>
      <CardContent sx={styles.content}>
        <Box display="flex" flexDirection="row">
          {/*HIDE FOR FUTURE <Stack
            direction="row"
            alignItems="center"
          >
            <Chip
              style={{ backgroundColor: "#EBF2FD", color: theme.palette.info.main }}
              label="Easy"
              size="small"
              deleteIcon={<InfoIcon style={{ color: theme.palette.info.main }} />}
              onDelete={() => { }}
            />
          </Stack> */}
          <Box flex={1} />
          <TaskExpiryChip expiry={task.expiry} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 2
          }}
        >
          <div>
            <Box display="flex">
              <Typography variant="h6" sx={styles.title}>
                {task.title}
              </Typography>
            </Box>
            <Typography variant="body2" sx={styles.descripion}>
              {task.description}
            </Typography>
          </div>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 0, pl: 2, mb: 1 }}>
        <Chip
          sx={commonStyles.borderlessChip}
          icon={(<CashBillIcon sx={styles.chipIcon} fontSize="small" style={{ color: theme.palette.neutral?.[500] }} />)}
          label={<Box component="span" color="primary.main">+{pay?.amount ?? "-"} {pay?.currency}</Box>}
          variant="outlined"
        />
        <TaskSubmissionSlot submissionSlot={taskerStatistic} />
        <Box flex={1} />
        <Button
          endIcon={<ChevronRight fontSize="small" sx={{ px: 0 }} />}
          variant="text"
          style={{ color: theme.palette.primary.main, fontWeight: 700, }}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

const styles = createStyles({
  properties: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    ml: -3,
    "& > *": {
      ml: 3,
      mt: 1
    }
  },
  rejectMessage: {
    fontSize: {
      xs: "11px",
      md: "14px",
    },
  },
  title: {
    fontSize: {
      xs: "16px",
      md: "18px",
    },
  },
  descripion: {
    fontSize: {
      xs: "12px",
      md: "14px"
    },
    color: "neutral.500",
    WebkitLineClamp: "2",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  chip: {
    height: {
      xs: "20px",
      md: "24px"
    },
    ml: 1,
  },
  icon: {
    fontSize: {
      xs: "15px",
      md: "20px",
    },
  },
  payIcon: {
    position: "relative",
    top: "2px",
    mr: .5,
    fontSize: "13px",
  },
  label: {
    opacity: 0.7,
    fontWeight: "bold",
    fontSize: {
      xs: "11px",
      md: "14px",
    },
  },
  reference: {
    opacity: .5,
    fontFamily: "'Roboto Mono', monospace",
    fontSize: "10px",
  },
  status: {
    fontSize: "10px",
    background: "neutral.400"
  },
  content: {
    position: "relative",
    p: 2,
    pt: 1,
    "&:last-child": {
      pb: 1,
    }
  },
  expiryTime: {
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
  },
  chipBox: {
    width: 'fit-content',
    padding: '0px 10px',
    borderRadius: "12px",
  },
  chipIcon: {
    mr: { xs: 0.2, md: 1 }
  }

});

export default TaskCard;
