import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PlusCircleIcon, RefreshIcon } from "src/assets";
import { accountApi } from "src/main/api";
import { ListTaskResponse } from "src/main/api/account/Task";
import { DateDisplay, ListingComp, Page, Table } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { Meta, TaskModel } from "src/main/types";
import { Paths, truncate } from "src/main/utils";

interface TaskPageProps extends React.PropsWithChildren {}

const TaskHeaders = [
  { value: "Task Name" },
  { value: "Payout" },
  { value: "Type" },
  { value: "Duration" },
  { value: "Limit" },
  { value: "Status" },
  { value: "Ongoing" },
  { value: "Submitted" },
  { value: "Completed" },
  { value: "" },
];

const STATUS_OPTIONS = [
  { value: "all", label: "All" },
  { value: "not-started", label: "Not Started" },
  { value: "ongoing", label: "Ongoing" },
  { value: "expired", label: "Expired" },
];

const VISIBILITY_OPTIONS = [
  { value: "all", label: "All" },
  { value: "published", label: "Published" },
  { value: "draft", label: "Draft" },
];

const checkStatus = (task: TaskModel) => {
  if (task.publishedAt && moment().isAfter(task.expiry)) return "expired";
  if (!task.publishedAt) return "draft";
  if (task.publishedAt && moment().isBefore(task.start)) return "not-started";
  return "published";
};

const TaskPage: React.FC<TaskPageProps> = () => {
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 });

  const result = accountApi.useListTaskQuery({ meta });
  const listResult: ListTaskResponse | undefined = useMemo(
    () => result.data ?? undefined,
    [result.data]
  );
  const persisMeta = useMemo(
    () => ({ ...meta, ...result.data?.meta }),
    [result.data, meta]
  );

  const onStatusUpdate = (status: string) => {
    const newMeta = { ...persisMeta };
    delete newMeta.status;
    delete newMeta.start;
    delete newMeta.expiry;
    delete newMeta.ongoing;

    if (status === "all") {
    } else if (status === "not-started") {
      newMeta.start = `${moment().unix()},`;
    } else if (status === "ongoing") {
      newMeta.ongoing = "true";
    } else if (status === "expired") {
      newMeta.expiry = `0,${moment().unix()}`;
    }

    setMeta(newMeta);
  };

  const onVisibilityUpdate = (status: string) => {
    const newMeta = { ...persisMeta };
    delete newMeta.visibility;
    delete newMeta.publishedAt;

    if (status === "all") {
    } else if (status === "published") {
      newMeta.publishedAt = "0,";
    } else if (status === "draft") {
      newMeta.publishedAt = "null";
    }

    setMeta(newMeta);
  };

  return (
    <Page>
      <Page.TopSection title="Tasks">
        <Button
          component={RouterLink}
          to={Paths.Management.CreateTask}
          size="large"
          type="submit"
          variant="contained"
          startIcon={<PlusCircleIcon />}
        >
          <Typography color="#fff">Create</Typography>
        </Button>
      </Page.TopSection>
      <ListingComp
        loading={result.isLoading}
        updateList={(newMeta) => {
          setMeta(newMeta);
        }}
        meta={persisMeta}
      >
        <ListingComp.FilterSection>
          <ListingComp.Searchbar
            label="Find Existing Tasks"
            placeholder="Search by task name, keywords..."
          />
          <ListingComp.Statusbar
            defaultSelected="all"
            options={STATUS_OPTIONS}
            onStatusUpdate={onStatusUpdate}
          />
          <ListingComp.Statusbar
            defaultSelected="all"
            label="Visibility"
            options={VISIBILITY_OPTIONS}
            metaKey="visibility"
            onStatusUpdate={onVisibilityUpdate}
          />
          <ListingComp.DateLabelInput
            resetStatus={["status", "visibility"]}
            placeholder="Select start range"
            label="Start"
            dateChangeKey="start"
          />
          <ListingComp.DateLabelInput
            resetStatus={["status", "visibility"]}
            placeholder="Select expiry range"
            label="Expiry"
            dateChangeKey="expiry"
          />
        </ListingComp.FilterSection>

        <ListingComp.Content>
          <Table>
            <Table.Head headers={TaskHeaders} />
            <Table.Body>
              {listResult?.entries.map((task) => (
                <Table.Row>
                  <Table.Cell>
                    <Typography variant="body2" fontWeight={700}>
                      {task.title}
                    </Typography>
                    <Typography variant="caption">
                      {truncate(task.id, 8, undefined, true)}
                    </Typography>
                  </Table.Cell>
                  <Table.Cell>
                    {task.payAmount?.toFormat(0) ?? "0"}{" "}
                    {task.payCurrency ?? "USD"}
                  </Table.Cell>
                  <Table.Cell>
                    <Box color="neutral.500">
                      {task.periodType ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          Recurring
                          <RefreshIcon />
                        </Box>
                      ) : (
                        "One-time"
                      )}
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <DateDisplay value={task.start} />
                    <DateDisplay value={task.expiry} />
                  </Table.Cell>
                  <Table.Cell>{task.maxSubmission ?? "-"}</Table.Cell>
                  <Table.Cell>
                    <StatusChip status={checkStatus(task)} />
                  </Table.Cell>
                  <Table.Cell>
                    {task.attemptStat?.statusInProgress ?? "-"}
                  </Table.Cell>
                  <Table.Cell>
                    {task.attemptStat?.statusSubmitted ?? "-"}
                  </Table.Cell>
                  <Table.Cell>
                    {task.attemptStat?.statusApproved ?? "-"}
                  </Table.Cell>
                  <Table.Cell align="right">
                    <IconButton
                      component={RouterLink}
                      to={Paths.Management.TasksDetail.replaceAll(
                        ":taskId",
                        task.id
                      )}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ListingComp.Content>
      </ListingComp>
    </Page>
  );
};

export default TaskPage;
