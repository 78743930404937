import { Box, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { DetailCard, EmptyState, LoadingContainer, MonoLabel, Page, Table, TableRowWrap, WorkerCard } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { truncate } from "src/main/utils";

interface PayoutItemsDetailPageProps extends React.PropsWithChildren {
}

const PayoutItemsDetailPage: React.FC<PayoutItemsDetailPageProps> = () => {
  const { payoutItemId = "" } = useParams();

  const result = accountApi.useGetPayoutItemQuery({ payoutItemId: payoutItemId });
  const payoutItem = useMemo(() => result?.data?.model ?? undefined, [result.data?.model]);

  if (!result.isLoading && !payoutItem) return <EmptyState />

  return (
    <Page>
      <Page.TopSection title="Payout Details"> </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        <Page.Content>
          <Box flex={1}>
            <DetailCard>
              <DetailCard.Header header="Payout Information" />
              <DetailCard.Divider />
              <Table>
                <Table.Body>
                  <TableRowWrap
                    label="ID"
                    value={<MonoLabel>{truncate(payoutItem?.id, 8)}</MonoLabel>}
                  />
                  <TableRowWrap
                    label="Amount"
                    value={<MonoLabel>{BigNumber(payoutItem?.amount ?? 0).toFixed(2)}</MonoLabel>}
                  />
                  <TableRowWrap
                    label="Currency"
                    value={<MonoLabel>{payoutItem?.currency}</MonoLabel>}
                  />
                  <TableRowWrap
                    label="Amount (USD)"
                    value={<MonoLabel>{BigNumber(payoutItem?.amountInUsd ?? 0).toFixed(2)}</MonoLabel>}
                  />
                  <TableRowWrap
                    label="Description"
                    value={<Typography>{payoutItem?.description}</Typography>}
                  />
                  <TableRowWrap
                    label="Type"
                    value={<MonoLabel>{payoutItem?.type?.toLowerCase()}</MonoLabel>}
                  />
                  <TableRowWrap
                    label="Status"
                    value={<StatusChip status={payoutItem?.status ?? ""} />}
                  />
                  <TableRowWrap
                    label="Reference No."
                    value={<MonoLabel>{payoutItem?.referenceNo}</MonoLabel>}
                  />
                </Table.Body>
              </Table>
            </DetailCard>
          </Box>
          <Box flex={1}>
            {!!payoutItem?.workerId
              ? <WorkerCard worker={payoutItem?.worker} />
              : <EmptyState message="No worker ID" />
            }
          </Box>
        </Page.Content>
      </LoadingContainer>
    </Page>
  );
};


export default PayoutItemsDetailPage; 
