import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box, Dialog, DialogActions,
  DialogContent, DialogProps,
  DialogTitle,
  IconButton,
  List, ListItem, ListItemIcon,
  SvgIconPropsColorOverrides
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { useState } from "react";
import { InfoIcon } from "src/assets";
import { createStyles, IntlFormatter } from "src/main/utils";
import { Typography } from 'src/main/components';

const styles = createStyles({
  icon: {
    ml: 1,
    fontSize: {
      xs: "14px",
      md: "20px",
    },
    color: "primary.main",
    cursor: "pointer"
  },
})
interface TimeCompleteInfoDialogProps extends Omit<DialogProps, "open"> {
  color?: OverridableStringUnion<"inherit" | "disabled" | "action" | "error" | "primary" | "secondary" | "info" | "success" | "warning", SvgIconPropsColorOverrides> | undefined;
}
const TimeCompleteInfoDialog: React.FC<TimeCompleteInfoDialogProps> = (props) => {
  const { color, ...dialogProps } = props;
  const [openFlag, setOpenFlag] = useState<boolean>(false);
  const instructions = [
    IntlFormatter.getMessage("taskCompleteDialog.time_counter_shows_how_much_time_each_task", "A time counter shows you how much time you have for each task."),
    IntlFormatter.getMessage("taskCompleteDialog.timer_start_when_accept_task", "The timer start when you accept the task."),
    IntlFormatter.getMessage("taskCompleteDialog.finish_and_earn_reward_improve_score", "Finish in time to earn your reward and improve your quality score.")
  ]

  return (
    <>
      <InfoIcon sx={styles.icon} color={color} onClick={() => { setOpenFlag(true) }} />
      <Dialog fullWidth {...dialogProps} open={openFlag} onClose={() => { setOpenFlag(false) }}>
        <DialogTitle>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography formatId="taskCompleteDialog.taskCompletionTimeLimit" variant="h6" sx={{}}>Task Completion Time Limit</Typography>
            <IconButton aria-label="close" onClick={() => { setOpenFlag(false) }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
          </Grid> */}
        </DialogTitle>
        <DialogContent>
          <Typography formatId="taskCompleteDialog.finish_task_before_time_runs_out" variant="body2" fontWeight={700}>Finish the task before time runs out to claim your reward</Typography>
          <List>
            {instructions.map(item => (<ListItem sx={{ px: 0, }} alignItems="flex-start">
              <ListItemIcon sx={{ mt: .6 }}><FiberManualRecordIcon sx={{ fontSize: "8px", }} /></ListItemIcon>
              <Typography variant="body2" lineHeight={1.375} ml={-1} color="text.secondary">{item}</Typography>
            </ListItem>))}
          </List>
        </DialogContent>
        <DialogActions>
          {/* <Button
            variant="contained"
            onClick={() => {setOpenFlag(false)}}
          >
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}


export default TimeCompleteInfoDialog;
