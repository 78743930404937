import { Box, Card, CardActions, IconButton, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import * as XLSX from "xlsx";

import { CancelOutlined, CheckOutlined, InfoOutlined } from "@mui/icons-material";
import ErrorIcon from '@mui/icons-material/Error';
import { Alert } from "@mui/lab";
import moment from "moment";
import { toast } from "react-hot-toast";
import { accountApi } from "src/main/api";
import { FileDropZone, LoadingButton, LoadingContainer, MonoLabel, Page, Scrollbar, Table } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { APIBadRequestErrorItem, handleFormikApiResponse } from "src/main/utils";

interface BulkWithdrawProcessProps extends React.PropsWithChildren {
}

const getSheet = (file: XLSX.WorkBook) => {
  const firstSheetName = file.SheetNames[0];
  const firstSheet = file.Sheets[firstSheetName];
  return firstSheet;
};

const FILE_COLUMN_KEY_MAP = {
  "A": "id",
  // "B": "amount",
  // "C": "currency",
  // "D": "amountInUsd",
  // "E": "finalAmount",
  // "F": "withdrawFee",
  // "G": "status",
  // "H": "workerId",
  // "I": "username",
}

interface ImportType {
  id: string;
  extra: string[];
  error: string;
}

const BulkWithdrawProcess: React.FC<BulkWithdrawProcessProps> = () => {

  const [imported, setImported] = useState<XLSX.WorkBook | undefined>();
  const [processed, setProcessed] = useState<ImportType[]>([]);
  const [showProcessButton, setShowProcessButton] = useState(false);

  const [fileProcessed, setFileProcessed] = useState(false);

  const [runProcessWithdrawRequest] = useAsyncTask("process/withdrawRequest");

  const [bulkProcessWithdraw, { isLoading: bulkProcessLoading }] = accountApi.useBulkProcessWithdrawMutation();

  const setUploadedFiles = (imports: XLSX.WorkBook) => {
    setImported(imports);
  };

  useMemo(() => {
    if (!imported) return;
    setShowProcessButton(false);
    const sheet = getSheet(imported);
    const getValueData = (value: any) => (value.t === "s" ? value.v : value.w)?.trim().toLowerCase();

    const newSorted: ImportType[] = [];
    Object.entries(sheet).forEach(([key, value]) => {
      if (key.includes("!")) return;

      const lineNo = key.match(/\d+/g)?.map(Number);
      let cellAp = key.replace(lineNo + "", "");

      if (!lineNo || (lineNo?.length > 1)) return;

      const line = lineNo[0] - 1

      const val = getValueData(value);
      if (!newSorted[line]) newSorted[line] = {} as ImportType;

      if (!FILE_COLUMN_KEY_MAP[cellAp]) {
        if (!newSorted[line].extra) newSorted[line].extra = [] as string[];
        (newSorted[line].extra as string[]).push(val)
      } else {
        newSorted[line].id = val
      }
    })

    newSorted.filter((n) => n)
    const withdrawIdArr: string[] = [];
    let hasError = false;
    newSorted.forEach((record, index) => {
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      const check = regexExp.test(record.id);

      if (withdrawIdArr.includes(record.id)) {
        record.error = "Withdraw Request ID is duplicated!";
        hasError = true
      } else {
        withdrawIdArr.push(record.id);
      }

      if (!check) {
        record.error = "Withdraw Request ID is invalid!";
        hasError = true
      }

    })

    setShowProcessButton(!hasError);
    setFileProcessed(false);
    setProcessed(newSorted);
  }, [imported]);

  const exportProcessed = () => {
    const toExport: string[][] = [];

    processed.forEach((a) => {
      toExport.push([a.id]);
    })

    const worksheet = XLSX.utils.aoa_to_sheet(toExport);
    const workbook = {
      SheetNames: ["sheet1"],
      Sheets: {
        sheet1: worksheet,
      },
    };
    return XLSX.writeFile(workbook, `${moment().format("YYYY-MM-DD")}_Withdraw-Request-Processed.csv`);
  }

  const onProcess = useCallback(async () => {

    runProcessWithdrawRequest(async () => {
      const withdrawRequestIds = processed.map(proc => proc.id);
      const result = await bulkProcessWithdraw({ entries: { withdrawRequestIds } });

      const { error: apiErr } = handleFormikApiResponse(result);

      const newProcessed = [...processed];
      if (apiErr) {
        const reducedErrors = apiErr?.data?.error.errors ?? {};

        withdrawRequestIds.forEach((id, index) => {
          if (reducedErrors[index]) {
            const errMessage = (reducedErrors[index] as unknown as APIBadRequestErrorItem).message as unknown as string

            newProcessed.forEach((a) => {
              if (a.id === id) {
                a.error = errMessage
              }
            });

          }
        })

        toast.error("Batch process failed, please check individual error and submit again");

        setProcessed(newProcessed);
        setShowProcessButton(false);
        return;
      }
      exportProcessed();
      setShowProcessButton(false);
      setFileProcessed(true);

      toast.success("Request process completed");
    })
    // eslint-disable-next-line
  }, [bulkProcessWithdraw, processed, runProcessWithdrawRequest])


  const removeImported = () => {
    setImported(undefined);
    setProcessed([]);
  };

  const removeRow = (index: number) => {
    const newProcessed = [...processed];
    newProcessed.splice(index, 1);
    let hasError = false;
    newProcessed.forEach((a) => {
      if (!!a.error) hasError = true;
    })

    if (hasError) setShowProcessButton(false);
    else setShowProcessButton(true);

    setProcessed(newProcessed);
  }

  return (
    <Page>
      <Page.TopSection title="Bulk Withdraw Process">
      </Page.TopSection>
      <LoadingContainer>
        <Page.Content>
          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flexDirection="column" flex={1} gap={2}>
              <FileDropZone
                maxFiles={1}
                files={imported && [imported]}
                setImportFiles={setUploadedFiles}
                onRemove={removeImported}
              />
              <Alert variant="standard" color="info" icon={<InfoOutlined />}>
                Import .csv/.xlsx file with Withdraw Request ID in the first row.
                <div>Example of Withdraw Request ID: <code style={{ color: "GrayText" }}>09ebdf07-27c4-47f0-98d9-653a71c80da8</code></div>
              </Alert>
            </Box>
            <Box flex={1} >
              <Card>
                <Scrollbar>
                  <Table
                    sx={{
                      ".MuiTableCell-root": {
                        width: 0
                      }
                    }}
                  >
                    {!!processed.length && (
                      <Table.Head>
                        <Table.Row>
                          <Table.Cell></Table.Cell>
                          <Table.Cell>Withdraw ID</Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          {/* <Table.Cell>{row.amount}</Table.Cell>
                                      <Table.Cell>{row.currency}</Table.Cell>
                                      <Table.Cell>{row.amountInUsd}</Table.Cell>
                                      <Table.Cell>{row.finalAmount}</Table.Cell>
                                      <Table.Cell>{row.withdrawFee}</Table.Cell>
                                      <Table.Cell>{row.status}</Table.Cell>
                                      <Table.Cell>{row.workerId}</Table.Cell>
                                      <Table.Cell>{row.username}</Table.Cell> */}
                        </Table.Row>
                      </Table.Head>
                    )}
                    {processed?.map((row, index) => (

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{index}</Table.Cell>
                          <Table.Cell ><MonoLabel>{row.id}</MonoLabel></Table.Cell>
                          <Table.Cell>
                            {!!row.error && (

                              <Box display="flex" gap={2}>
                                <ErrorIcon color="error" />
                                <Typography>{row.error}</Typography>
                              </Box>
                              // <Tooltip
                              //   componentsProps={{
                              //     tooltip: {
                              //       style: { background: "none" },
                              //     }
                              //   }}
                              //   placement="top"
                              //   title={
                              //     <Box
                              //       sx={{
                              //         border: "1px solid #00000020",
                              //         bgcolor: "background.default",
                              //         display: "flex",
                              //         flexDirection: "column",
                              //         padding: 2,
                              //         borderRadius: 1,
                              //         fontSize: "11px",
                              //       }}
                              //     >
                              //       {row.error && (
                              //         <Typography color="text.primary">{row.error}</Typography>
                              //       )}
                              //     </Box>
                              //   }
                              // >
                              //   <ErrorIcon sx={{ cursor: "pointer" }} color="error" />
                              // </Tooltip>
                            )}
                          </Table.Cell>
                          <Table.Cell align="right">
                            {!fileProcessed && (
                              <IconButton
                                onClick={() => removeRow(index)}
                              >
                                <CancelOutlined />
                              </IconButton>
                            )}
                            {fileProcessed && (
                              <CheckOutlined color="success" />
                            )}
                          </Table.Cell>
                          {/* <Table.Cell><MonoLabel>{row.amount}</MonoLabel></Table.Cell>
                        <Table.Cell><MonoLabel>{row.currency}</MonoLabel></Table.Cell>
                        <Table.Cell><MonoLabel>{row.amountInUsd}</MonoLabel></Table.Cell>
                        <Table.Cell><MonoLabel>{row.finalAmount}</MonoLabel></Table.Cell>
                        <Table.Cell><MonoLabel>{row.withdrawFee}</MonoLabel></Table.Cell>
                        <Table.Cell><StatusChip status={row.status} /></Table.Cell>
                        <Table.Cell><MonoLabel>{truncate(row.workerId, 8)}</MonoLabel></Table.Cell>
                        <Table.Cell><MonoLabel>{row.username}</MonoLabel></Table.Cell> */}
                        </Table.Row>
                      </Table.Body>
                    ))}

                  </Table>
                  {!!processed.length && (
                    <CardActions sx={{ justifyContent: "flex-end" }}>
                      {showProcessButton && (
                        <LoadingButton
                          loading={bulkProcessLoading}
                          onClick={() => onProcess()}
                        >
                          Process All
                        </LoadingButton>
                      )}
                    </CardActions>
                  )}

                </Scrollbar>
              </Card>
            </Box>
          </Box>

        </Page.Content>
      </LoadingContainer>
    </Page>
  );
};

export default BulkWithdrawProcess; 
