import { createSvgIcon } from '@mui/material/utils';

const LogoutIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 4.08331C4.53587 4.08331 4.09075 4.26769 3.76256 4.59588C3.43437 4.92406 3.25 5.36918 3.25 5.83331V14.1666C3.25 14.6308 3.43437 15.0759 3.76256 15.4041C4.09075 15.7323 4.53587 15.9166 5 15.9166H8.33333C8.79746 15.9166 9.24258 15.7323 9.57077 15.4041C9.89896 15.0759 10.0833 14.6308 10.0833 14.1666V13.3333C10.0833 12.9191 10.4191 12.5833 10.8333 12.5833C11.2475 12.5833 11.5833 12.9191 11.5833 13.3333V14.1666C11.5833 15.0286 11.2409 15.8552 10.6314 16.4647C10.0219 17.0742 9.19529 17.4166 8.33333 17.4166H5C4.13805 17.4166 3.3114 17.0742 2.7019 16.4647C2.09241 15.8552 1.75 15.0286 1.75 14.1666V5.83331C1.75 4.97136 2.09241 4.14471 2.7019 3.53522C3.3114 2.92572 4.13805 2.58331 5 2.58331H8.33333C9.19529 2.58331 10.0219 2.92572 10.6314 3.53522C11.2409 4.14471 11.5833 4.97136 11.5833 5.83331V6.66665C11.5833 7.08086 11.2475 7.41665 10.8333 7.41665C10.4191 7.41665 10.0833 7.08086 10.0833 6.66665V5.83331C10.0833 5.36918 9.89896 4.92406 9.57077 4.59588C9.24258 4.26769 8.79746 4.08331 8.33333 4.08331H5ZM13.6363 6.13632C13.9292 5.84342 14.4041 5.84342 14.697 6.13632L18.0303 9.46965C18.171 9.6103 18.25 9.80107 18.25 9.99998C18.25 10.1989 18.171 10.3897 18.0303 10.5303L14.697 13.8636C14.4041 14.1565 13.9292 14.1565 13.6363 13.8636C13.3434 13.5707 13.3434 13.0959 13.6363 12.803L15.6893 10.75H5.83333C5.41912 10.75 5.08333 10.4142 5.08333 9.99998C5.08333 9.58577 5.41912 9.24998 5.83333 9.24998H15.6893L13.6363 7.19698C13.3434 6.90408 13.3434 6.42921 13.6363 6.13632Z" />
  </svg>
  ,
  'Info'
);

export default LogoutIcon;
