import EditIcon from '@mui/icons-material/Edit';
import {
  Box, Button, Chip, Container, Grid, Typography
} from "@mui/material";
import React from "react";
import { DetailCard, EmptyState, InputField, Page, Tabs } from "src/main/components";
import { AccountEntityModel, PersonModel } from "src/main/types";
import { SimpleMap } from 'src/main/utils';

interface PersonDetailProps extends React.PropsWithChildren {
  toggleEdit: () => void;
  person?: PersonModel;
  activeAcc?: AccountEntityModel;
  toggleEditPrivilege: () => void;

  tabs: SimpleMap<string>[];
  updateTabs: (tab: string) => void;
  currentTab: string;
}

const PersonDetail: React.FC<PersonDetailProps> = (props) => {
  const { toggleEdit, person, activeAcc, toggleEditPrivilege, tabs, currentTab, updateTabs } = props;

  if (!person) return <EmptyState />;

  return (
    <Page>
      <Page.TopSection title="Detail">
        <Box display="flex">
          <Box >
            <Button
              size="large"
              variant="contained"
              onClick={toggleEdit}
              sx={{ mr: 2 }}
            >
              <EditIcon />&nbsp;<Typography>Edit Details</Typography>
            </Button>
          </Box>
          <Box >
            <Button
              size="large"
              variant="contained"
              onClick={toggleEditPrivilege}
            >
              <EditIcon />&nbsp;<Typography>Edit Privilege</Typography>
            </Button>
          </Box>
        </Box>
      </Page.TopSection>
      <Tabs value={currentTab} updateTab={updateTabs} tabs={tabs} sx={{ paddingX: 2 }} />
      <Page.Content>
        <Container >
          <DetailCard>
            <DetailCard.Header header="Personal Info" />

            <Grid container spacing={2} p={2}>
              <Grid container item md={6} xs={12}>
                <InputField
                  name="firstname"
                  label="Firstname"
                  required
                  value={person.firstname}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={6} xs={12}>
                <InputField
                  name="middlename"
                  label="Middlename"
                  value={person.middlename}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>

              <Grid container item md={6} xs={12}>
                <InputField
                  name="lastname"
                  label="Lastname"
                  value={person.lastname}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>

              <Grid container item md={3} xs={12}>
                <InputField
                  name="gender"
                  label="Gender"
                  value={person.gender}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>

              <Grid container item md={3} xs={12}>
                <InputField
                  name="salutation"
                  label="Salutation"
                  value={person.salutation}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
            </Grid>

            <DetailCard.Header header="Contact Info" />

            <Grid container spacing={2} p={2}>
              <Grid container item md={6} xs={12}>
                <InputField
                  name="primaryEmail"
                  label="Email"
                  required
                  value={person.primaryEmail}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={6} xs={12}>
                <InputField
                  name="primaryPhone"
                  label="Phone"
                  value={person.primaryPhone}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
            </Grid>

            <DetailCard.Header header="Social Media" />

            <Grid container spacing={2} p={2}>
              <Grid container item md={6} xs={12}>
                <InputField
                  name="facebookHandle"
                  label="Facebook Handle"
                  value={person.facebookHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={6} xs={12}>
                <InputField
                  name="facebookHandle"
                  label="Facebook Handle (Alt)"
                  value={person.facebookAltHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={4} xs={12}>
                <InputField
                  name="discordHandle"
                  label="Discord Handle"
                  value={person.discordHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={4} xs={12}>
                <InputField
                  name="instagramHandle"
                  label="Instagram Handle"
                  value={person.instagramHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={4} xs={12}>
                <InputField
                  name="tiktokHandle"
                  label="TikTok Handle"
                  value={person.tiktokHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={4} xs={12}>
                <InputField
                  name="twitterHandle"
                  label="Twitter Handle"
                  value={person.twitterHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid container item md={4} xs={12}>
                <InputField
                  name="telegramHandle"
                  label="Telegram Handle"
                  value={person.telegramHandle}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
            </Grid>

            <DetailCard.Header header="Privileges" />

            <Box px={2} pb={5} >
              {activeAcc?.privileges?.map((privilege) => (
                <Chip color="primary" sx={{ marginRight: 2 }} label={privilege.split(":")[1]} />
              ))}
            </Box>


            <DetailCard.Header header="Credentials" />

            <Box px={2} pb={5} >
              <InputField
                name="username"
                label="Username"
                value={person.entity?.credentials?.[0].accessKey}
                InputProps={{
                  readOnly: true
                }}
              />
            </Box>
          </DetailCard>
        </Container>
      </Page.Content>
    </Page>
  );
};

export default PersonDetail;