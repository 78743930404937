import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";
import { IntlProvider } from 'react-intl';
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RootState } from "src/main/store";
import { Paths } from "src/main/utils";
import { Toaster } from "./main/components";
import { ThemeMode } from "./main/store/preference/types";
import { createTheme } from "./main/theme";
import { browserLanguageSetting, messages } from './main/utils/localization';
import { Affiliate, Auth, Dashboard, Portal } from "./main/views";

export interface AppProps extends PropsWithChildren {

}


const App: React.FC<AppProps> = (props) => {
  const savedLanguage = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;
  const savedTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  const theme = createTheme({ mode: savedTheme ?? "dark" });
  return (
    <IntlProvider locale={savedLanguage} messages={messages[savedLanguage]}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster theme={theme} />
        <BrowserRouter>
          <Routes>
            {/* Authentication */}
            <Route path={`${Paths.Auth.Index}/*`} element={<Auth.Main />} />

            <Route path={`${Paths.Overview.Index}/*`} element={<Dashboard.General />} />
            <Route path={`${Paths.Management.Index}/*`} element={<Dashboard.Management />} />
            <Route path={`${Paths.Platform.Index}/*`} element={<Dashboard.Platform />} />
            <Route path={`${Paths.Portal.Index}/*`} element={<Portal.Main />} />
            <Route path={`${Paths.Affiliate.Index}/*`} element={<Affiliate.Main />} />

            <Route path="*" element={<Navigate to={Paths.Auth.Index} replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
