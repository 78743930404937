import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { ActivityLog, DetailCard, EmptyState, LoadingButton, LoadingContainer, Page, Tabs, WorkerCard } from "src/main/components";
import StatusChip from "src/main/components/StatusChip";
import { Paths } from "src/main/utils";
import { TaskActionDialog, TaskObjectiveProofCard } from "./components";

interface TaskSubmissionDetailProps extends React.PropsWithChildren {
}

const tabs = [
  { value: "details", label: "Details" },
  { value: "activity", label: "Activity" },
]

// get different color depending of the score.
const getOverrideColor = (score?: number) => {
  if (!score) return "cancelled";
  if (score < 4) return "rejected";
  if (score >= 4 && score <= 5) return "pending";
  if (score > 5 && score <= 7) return "submitted";
  return "active";
}

const TaskSubmissionDetailComponent: React.FC<TaskSubmissionDetailProps> = (props) => {
  const { submissionId = "" } = useParams();
  const result = accountApi.useGetTaskAttemptQuery({ attemptId: submissionId });
  const taskAttempt = useMemo(() => result?.data?.model ?? undefined, [result.data?.model]);
  const [showProcessDialog, setShowProcessDialog] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const tabVal = useMemo(() => {
    const vals = new URLSearchParams(search)

    if (vals.get("tab")) return vals.get("tab")
    return "details"
  }, [search]);

  const updateTab = (tab: string) => {
    navigate(Paths.Management.TaskSubmission.replace(":submissionId", submissionId) + `?tab=${tab}`, { replace: true });
  }

  return (
    <Page>
      <Page.TopSection title="Submission" crumbs={[
        { label: "Submission", path: Paths.Management.TasksDetail.replace(":taskId", taskAttempt?.task?.id ?? "") + "?tab=submissions" },
        { label: "Detail" }
      ]}>
        <Box display="flex">
          {(taskAttempt?.status === "in-progress" || taskAttempt?.status === "submitted") && (
            <Box>
              {/* <LoadingButton
                variant="contained"
                loading={isTerminateLoading}
                onClick={() => setShowTerminateDialog(true)}
                sx={{ display: "flex" }}
              >
                Terminate
              </LoadingButton> */}
              <LoadingButton
                variant="contained"
                onClick={() => setShowProcessDialog(true)}
                sx={{ display: "flex" }}
              >
                {taskAttempt.status === "submitted" ? "Process" : "Terminate"}
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Page.TopSection>

      <LoadingContainer loading={result.isLoading}>
        {!result && <EmptyState message="Submission details not found!" />}
        {result && !result.isLoading && (
          <>
            <Tabs value={tabVal} updateTab={updateTab} tabs={tabs} sx={{ paddingX: 2 }} />
            {tabVal === "details" && (
              <Grid p={2} container spacing={2}>
                <Grid item md={8}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {taskAttempt?.task?.taskObjectives &&
                      taskAttempt?.taskProofs?.map((tProof) => {
                        const task = taskAttempt!.task!.taskObjectives!.find((obj) => obj.id === tProof.taskObjectiveId)
                        return {
                          ...tProof,
                          sortPriority: task?.sortPriority ?? 0,
                        }
                      }).sort((proofA, proofB) => proofA.sortPriority - proofB.sortPriority).map((proof, index) => (
                        <TaskObjectiveProofCard taskAttempt={taskAttempt} taskObjs={taskAttempt!.task!.taskObjectives!} taskProof={proof} index={index} />
                      ))}
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <DetailCard sx={{ flex: 1 }}>
                      <DetailCard.Header
                        header="Information"
                      />
                      <DetailCard.Divider />
                      <Box p={3} display="flex" justifyContent="space-between">
                        <Typography>Submission Date</Typography>
                        <Typography>{!!taskAttempt?.submittedAt ? moment(taskAttempt?.submittedAt).format("YYYY-MM-DD HH:mm:ss") : "-"}</Typography>
                      </Box>
                      <DetailCard.Divider />
                      <Box p={3} display="flex" justifyContent="space-between">
                        <Typography>Submission Attempts</Typography>
                        <Typography>{taskAttempt?.submitCount ?? "-"}</Typography>
                      </Box>
                      <DetailCard.Divider />
                      <Box p={3} display="flex" justifyContent="space-between">
                        <Typography>Times Rejected</Typography>
                        <Typography>{taskAttempt?.rejectCount ?? "-"}</Typography>
                      </Box>
                      <DetailCard.Divider sx={{ mx: 2 }} />
                      {!!taskAttempt?.rejectReason && (
                        <Box p={3}>
                          <StatusChip status={taskAttempt.status === "submitted" ? "Previously Rejected" : "rejected"} overrideColor="error" />
                          <Box p={2}>
                            <Typography>
                              {taskAttempt.rejectReason}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <Box display="flex" p={3} gap={2}>
                        <StatusChip status={taskAttempt?.status ?? ""} />
                        {(!!taskAttempt?.score || taskAttempt?.score === 0) && (
                          <StatusChip overrideColor={getOverrideColor(taskAttempt.score)} status={`Score: ${taskAttempt?.score}/10`} />
                        )}
                      </Box>
                    </DetailCard>
                    <WorkerCard workerId={taskAttempt!.workerId} />
                  </Box>
                </Grid>
              </Grid>
            )}
            {tabVal === "activity" && (
              <ActivityLog ownerId={submissionId} ownerName="taskAttempt" />
            )}
          </>
        )}
      </LoadingContainer>
      {/* <TerminateConfirmation
        onConfirm={() => onTerminateAttempt()}
        open={showTerminateDialog}
        onClose={() => setShowTerminateDialog(false)}
      /> */}
      {taskAttempt && (
        <TaskActionDialog
          taskAttempt={taskAttempt}
          open={showProcessDialog}
          onClose={() => setShowProcessDialog(false)}
        />
      )}
    </Page >
  );
};

export default TaskSubmissionDetailComponent;
