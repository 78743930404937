import { createSvgIcon } from '@mui/material/utils';

const OnGoingIcon = createSvgIcon(
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6726 6.93336L8.53231 5.5092C8.3392 5.38046 8.11391 5.30804 7.88058 5.29195C7.65528 5.28391 7.42195 5.33218 7.2208 5.44483C7.01964 5.54943 6.84263 5.71839 6.72998 5.9115C6.60929 6.11265 6.54492 6.33794 6.54492 6.57128V9.42765C6.54492 9.66098 6.60929 9.88627 6.72998 10.0794C6.85068 10.2725 7.01964 10.4415 7.2208 10.5461C7.40586 10.6426 7.61505 10.6989 7.82425 10.6989C7.84035 10.6989 7.86448 10.6989 7.88058 10.6989C8.11391 10.6909 8.33116 10.6104 8.52426 10.4897L10.6726 9.05753C10.8415 8.94488 10.9864 8.78396 11.0829 8.60694C11.1795 8.42188 11.2358 8.21269 11.2358 8.00349C11.2358 7.79429 11.1795 7.58509 11.0829 7.40003C10.9944 7.21497 10.8496 7.05405 10.6726 6.93336ZM10.0047 8.05176L7.82425 9.49202L7.75988 9.42765V6.57128L7.8323 6.50691C7.84839 6.50691 7.85644 6.51496 7.86448 6.51496L10.0289 7.9713C10.0208 7.96326 10.0047 8.05176 10.0047 8.05176Z" fill="currentColor" />
    <path d="M13.1829 3.30458C11.9358 2.04939 10.2622 1.35742 8.49204 1.35742C6.72189 1.35742 5.04831 2.04939 3.80116 3.30458C2.54597 4.55172 1.854 6.22531 1.854 7.99545C1.854 8.87248 2.02297 9.72537 2.36091 10.538C2.69884 11.3426 3.18161 12.0668 3.80116 12.6944C4.42071 13.3139 5.14486 13.7967 5.95751 14.1346C6.77017 14.4726 7.62306 14.6415 8.50008 14.6415C9.37711 14.6415 10.23 14.4726 11.0426 14.1346C11.8473 13.7967 12.5795 13.3139 13.199 12.6944C13.8186 12.0748 14.3013 11.3507 14.6393 10.538C14.9772 9.72537 15.1462 8.87248 15.1462 7.99545C15.1301 6.22531 14.4381 4.55172 13.1829 3.30458ZM13.5128 10.0713C13.2392 10.7311 12.845 11.3265 12.3381 11.8334C11.8312 12.3403 11.2358 12.7346 10.576 13.0082C9.24837 13.5553 7.74375 13.5553 6.41614 13.0082C5.75636 12.7346 5.16095 12.3403 4.65405 11.8334C4.14714 11.3265 3.75288 10.7311 3.47932 10.0713C3.1977 9.41157 3.06092 8.71156 3.06092 7.99545C3.06092 6.54716 3.62415 5.17932 4.65405 4.15746C5.6759 3.12756 7.04374 2.56434 8.49204 2.56434C9.94033 2.56434 11.3082 3.12756 12.33 4.15746C13.3599 5.17932 13.9232 6.54716 13.9232 7.99545C13.9232 8.71156 13.7864 9.41157 13.5128 10.0713Z" fill="currentColor" />
  </svg>

  ,
  'OnGoingIcon'
);

export default OnGoingIcon;