import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import queryString from "query-string";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { authApi } from 'src/main/api';
import { AuthLayout, FormField, LoadingContainer } from "src/main/components";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { commonStyles, createStyles, handleApiResponse, joinSx, Paths } from "src/main/utils";
import * as Yup from "yup";

interface AdminInviteProps extends React.PropsWithChildren {

}

interface FormState {
  accessKey: string;
  secret: string;
  confirm: string;
  firstname: string;
  primaryEmail: string;
}
const initialFormState: FormState = {
  accessKey: "",
  secret: "",
  confirm: "",
  firstname: "",
  primaryEmail: "",
};
const Field = FormField<FormState>;

const validationSchema = Yup.object().shape({
  accessKey: Yup.string().required("Username is required"),
  secret: Yup.string().required("Password is required"),
  confirm: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("secret"), null], "Password doesn't match"),
  firstname: Yup.string().required("Firstname is required"),
  primaryEmail: Yup.string().email("Email is invalid").required("Email is required")
});

const AdminInvite: React.FC<AdminInviteProps> = (props) => {
  const location = useLocation()
  const token = useMemo(() => {
    const query = queryString.parse(location.search);
    if (Array.isArray(query.token))
      return query.token[0] ?? "";
    if (typeof query.token === "string")
      return query.token;
    return "";
  }, [location.search]);

  const navigate = useNavigate();
  const [formState, { onValueChange, consumeAPIError, setError, validate }] = useFormState(initialFormState, validationSchema);
  const [isSuccess, setIsSuccess] = useState(false);
  const result = authApi.useInviteTokenQuery(token);
  const [acceptInvite] = authApi.useInviteSubmitMutation();
  const isInitialising = result.isLoading || result.isFetching;
  const invite = useMemo(() => result.data?.result.model, [result.data]);
  const [runAcceptInvite, loading, error] = useAsyncTask("invite/accept");

  const onNavigateLogin = () => {
    navigate(Paths.Auth.Login);
  }

  const onSubmit = useCallback(() => {
    runAcceptInvite(async () => {
      setError({});
      if (validate()) return;

      const { accessKey, secret, primaryEmail, firstname } = formState.input;
      const result = await acceptInvite({
        token,
        accessKey,
        secret,
        primaryEmail,
        firstname,
      });

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null)
          return;
        throw new Error(error.data?.error?.message)
      }

      setIsSuccess(true);
      toast.success("Success! You may now login with your credentials.");
    });
  }, [runAcceptInvite, acceptInvite, consumeAPIError, formState.input, token, setError, validate]);

  return (
    <Container sx={joinSx(commonStyles.authContainer, styles.root)} maxWidth="sm">
      <LoadingContainer loading={isInitialising}>
        <Card>
          <AuthLayout.CardBanner />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <Typography variant="h5">
                  Welcome to Gig Rewards!
                </Typography>
                {!!invite && (
                  <Typography variant="body2" color="textSecondary">
                    You have been invited by <strong>{invite.inviter ?? "unknown user"}</strong> to join <strong>{invite.account}</strong>.
                    Simply create a login account below to get started!
                  </Typography>
                )}
              </div>
            </Box>
          </CardContent>
          <CardContent sx={{ pt: 2 }}>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              {!isSuccess && (
                <form onSubmit={(event) => event.preventDefault()}>
                  <Field
                    label="First name"
                    type="firstname"
                    fieldKey="firstname"
                    formStates={formState}
                    onValueChange={onValueChange}
                  />
                  <Field
                    label="Email address"
                    fieldKey="primaryEmail"
                    type="email"
                    formStates={formState}
                    onValueChange={onValueChange}
                  />
                  <Field
                    label="Username"
                    fieldKey="accessKey"
                    name="username"
                    type="string"
                    formStates={formState}
                    onValueChange={onValueChange}
                  />
                  <Field
                    fullWidth
                    label="Password"
                    name="password"
                    fieldKey="secret"
                    type="password"
                    formStates={formState}
                    onValueChange={onValueChange}
                  />
                  <Field
                    fullWidth
                    label="Confirm password"
                    fieldKey="confirm"
                    name="confirm-password"
                    type="password"
                    formStates={formState}
                    onValueChange={onValueChange}
                  />
                  {typeof error?.message === "string" && (
                    <Typography color="red">{error.message}</Typography>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={onSubmit}
                    >
                      Create Account
                    </LoadingButton>
                  </Box>
                </form>
              )}
              {isSuccess && (
                <>
                  <Alert variant="standard" color="success" icon={<CheckCircleOutlineOutlined />}>
                    Account created! You may now access <strong>{invite?.account}</strong>.
                  </Alert>
                  <Box mt={2} />
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={onNavigateLogin}
                  >
                    Login now 🚀
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
          <AuthLayout.EnvStatus />
        </Card>
      </LoadingContainer>
    </Container >
  );
};

const styles = createStyles({
  root: {
  },
});

export default AdminInvite;
