import { Box, Grid, Paper, useTheme } from "@mui/material";
import moment from 'moment';
import React, { useMemo } from "react";
import { useSelector } from 'react-redux';
import { accountApi } from 'src/main/api';
import { Chart } from "src/main/components";
import { RootState } from 'src/main/store';

interface AffiliateTrackerDailyStatChartProps extends React.PropsWithChildren {
  affiliateTrackerId: string;
}

const AffiliateTrackerDailyStatChartComponent: React.FC<AffiliateTrackerDailyStatChartProps> = (props) => {
  const { affiliateTrackerId } = props;
  const theme = useTheme();
  const currentTheme = useSelector<RootState>(state => state.preference.theme);

  const startQueryDate = moment().add(-29, "day");
  const endQueryDate = moment().endOf("day");

  const result = accountApi.useGetAffiliateTrackerDailyStatsQuery({ affiliateTrackerId })

  const { viewsOpts, viewsSeries, clickOpts, clickSeries } = useMemo(() => {
    const series = result.data?.series;
    let viewsOpts: ApexCharts.ApexOptions = {
      chart: {
        stacked: true,
        background: 'transparent',
        toolbar: { show: false },
      },
      plotOptions: {
        bar: { horizontal: false, columnWidth: '55%', }
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: { fontSize: '12px', colors: ['#fff'] },
      },
      legend: {
        show: true, position: "top",
      },
      stroke: {
        // show: true, width: 1, colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      theme: {
        mode: currentTheme === "light" ? "light" : "dark",
        monochrome: {
          enabled: true,
          color: currentTheme === "light" ? theme.palette.primary.light : theme.palette.primary.dark,
          shadeTo: 'dark', shadeIntensity: 0.65
        }
      },
      xaxis: { categories: [] as string[] }, /** */
    };
    let viewsSeries = [
      { data: [] as number[], name: "Views" },
      { data: [] as number[], name: "Unique Views" },
    ]

    let clickOpts: ApexCharts.ApexOptions = {
      chart: {
        stacked: true,
        background: 'transparent',
        toolbar: { show: false },
      },
      plotOptions: {
        bar: { horizontal: false, columnWidth: '55%', }
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: { fontSize: '12px', colors: ['#fff'] },
      },
      legend: {
        show: true, position: "top",
      },
      stroke: {
        // show: true, width: 1, colors: ['#fff'],
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      theme: {
        mode: currentTheme === "light" ? "light" : "dark",
        monochrome: {
          enabled: true,
          color: currentTheme === "light" ? theme.palette.primary.light : theme.palette.primary.dark,
          shadeTo: 'dark', shadeIntensity: 0.65
        }
      },
      xaxis: { categories: [] as string[] }, /** */
    };
    let clickSeries = [
      { data: [] as number[], name: "Clicks" },
      { data: [] as number[], name: "Unique Clicks" },
    ]

    if (series) {
      const newSeries = [...series];
      const DATE_FORMAT = "DD MMM";
      const startDate = startQueryDate.format(DATE_FORMAT);
      const endDate = endQueryDate.format(DATE_FORMAT);
      let currentDate = moment(startDate);

      while (currentDate.format(DATE_FORMAT) !== moment(endDate).add(1, "day").format(DATE_FORMAT)) {
        let dateKey = currentDate.format(DATE_FORMAT)

        const item = newSeries.find((serie) => serie.date?.format(DATE_FORMAT) === dateKey)

        if (viewsOpts["xaxis"]?.categories.indexOf(dateKey) < 0) {
          viewsSeries[0].data.push(item?.view ?? 0);
          viewsSeries[1].data.push(item?.uview ?? 0);
          viewsOpts["xaxis"]?.categories.push(dateKey);
        }


        if (clickOpts["xaxis"]?.categories.indexOf(dateKey) < 0) {
          clickSeries[0].data.push(item?.click ?? 0);
          clickSeries[1].data.push(item?.uclick ?? 0);
          clickOpts["xaxis"]?.categories.push(dateKey);
        }

        currentDate = currentDate.add(1, "day");
      }
    }

    return { viewsOpts, viewsSeries, clickOpts, clickSeries }
  }, [result, currentTheme, startQueryDate, endQueryDate, theme])

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12} md={6}>
        <Box flex={1} component={Paper} variant="outlined" elevation={1}>
          <Chart
            title="Views"
            subtitle="Last 30 days"
            chartOptions={viewsOpts}
            chartSeries={viewsSeries}
            height={280}
            type="line"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box flex={1} component={Paper} variant="outlined" elevation={1}>
          <Chart
            title="Clicks"
            subtitle="Last 30 days"
            chartOptions={clickOpts}
            chartSeries={clickSeries}
            height={280}
            type="line"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AffiliateTrackerDailyStatChartComponent;
