import { createSvgIcon } from '@mui/material/utils';

const Bell = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.29122 2.45783C8.74443 2.00461 9.35912 1.75 10.0001 1.75C10.641 1.75 11.2557 2.00461 11.7089 2.45783C12.109 2.85794 12.3543 3.38391 12.4063 3.94269C13.3203 4.36402 14.1108 5.01931 14.6952 5.84617C15.3817 6.81733 15.7502 7.9774 15.7501 9.16667C15.7501 9.1667 15.7501 9.16664 15.7501 9.16667V11.7983C15.75 11.9222 15.7744 12.045 15.8218 12.1595C15.8692 12.2739 15.9387 12.3779 16.0262 12.4655C16.0262 12.4655 16.0262 12.4655 16.0262 12.4655L17.1971 13.6363C17.4116 13.8508 17.4757 14.1734 17.3596 14.4537C17.2436 14.7339 16.9701 14.9167 16.6667 14.9167H13.2501V15C13.2501 15.862 12.9077 16.6886 12.2982 17.2981C11.6887 17.9076 10.862 18.25 10.0001 18.25C9.13811 18.25 8.31146 17.9076 7.70196 17.2981C7.09247 16.6886 6.75006 15.862 6.75006 15V14.9167H3.3334C3.03005 14.9167 2.75657 14.7339 2.64049 14.4537C2.5244 14.1734 2.58857 13.8508 2.80307 13.6363L3.9739 12.4655C4.15076 12.2886 4.25006 12.0486 4.25006 11.7992V9.16667C4.25006 6.84947 5.62103 4.85347 7.59378 3.94282C7.64574 3.38399 7.89107 2.85797 8.29122 2.45783ZM8.25006 14.9167V15C8.25006 15.4641 8.43444 15.9092 8.76263 16.2374C9.09081 16.5656 9.53593 16.75 10.0001 16.75C10.4642 16.75 10.9093 16.5656 11.2375 16.2374C11.5657 15.9092 11.7501 15.4641 11.7501 15V14.9167H8.25006ZM10.0001 3.25C9.75695 3.25 9.52379 3.34658 9.35188 3.51849C9.17997 3.69039 9.0834 3.92355 9.0834 4.16667V4.45083C9.0834 4.76865 8.88308 5.05196 8.58346 5.15792C6.93194 5.74198 5.75006 7.31775 5.75006 9.16667V11.7992C5.75006 12.3962 5.53108 12.9714 5.13752 13.4167H14.8626C14.6838 13.2143 14.5395 12.9835 14.4359 12.7333C14.3132 12.4369 14.25 12.1192 14.2501 11.7983C14.2501 11.7983 14.2501 11.7984 14.2501 11.7983V9.16667C14.2502 8.28753 13.9777 7.42988 13.4703 6.71198C12.9629 5.99407 12.2454 5.45112 11.4166 5.15788C11.117 5.05189 10.9167 4.76861 10.9167 4.45083V4.16667C10.9167 3.92355 10.8202 3.69039 10.6482 3.51849C10.4763 3.34658 10.2432 3.25 10.0001 3.25Z" fill="currentColor" />
  </svg>
  ,
  'Bell'
);

export default Bell;
