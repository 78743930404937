import { Box, BoxProps, Button, Link } from "@mui/material";
import React from "react";
import { ChatIcon, InquiryGraphic } from "src/assets";
import { SUPPORT_LINK } from "src/constant";
import { Typography } from "src/main/components";
import { IntlFormatter, createStyles, joinSx } from "src/main/utils";

interface EnquiryProps extends BoxProps {}

const Enquiry: React.FC<EnquiryProps> = (props) => {
  const { sx } = props;

  return (
    <Box sx={joinSx(styles.root, sx)}>
      <Box component="img" src={InquiryGraphic} />
      <Typography
        formatId="enquiryComp._if_information_not_accurate"
        variant="body2"
        color="neutral.700"
        lineHeight={1.375}
        align="center"
        sx={{ py: 2 }}
      >
        If any of these information is not accurate, contact support
        immediately.
      </Typography>
      <Button
        variant="text"
        size="small"
        startIcon={<ChatIcon sx={{ mr: -0.75, mb: 0.25 }} />}
      >
        <Link href={SUPPORT_LINK}>
          {IntlFormatter.getMessage(
            "bottomBar.contact_support",
            "Contact Support"
          )}
        </Link>
      </Button>
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: {
      xs: "flex",
      md: "none",
    },
    flexDirection: "column",
    mt: 2,
  },
});

export default Enquiry;
