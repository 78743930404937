import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Page } from "src/main/components";
import { RootState } from "src/main/store";
import { AccountEntityModel } from "src/main/types";
import { createStyles } from "src/main/utils";
import { ActiveTaskers, AvailableTasks, PayoutOT, RecentTaskSubmission, SubmissionsChart, WalletBalance, WithdrawReq, WithdrawToday, WorkerOT } from "./components";

interface OverViewPageProps extends React.PropsWithChildren {

}

const OverViewPage: React.FC<OverViewPageProps> = (props: OverViewPageProps) => {
  const workspace = useSelector<RootState, AccountEntityModel | null | undefined>(state => state.auth.workspace);
  return (
    <Page>
      <Page.TopSection title={workspace?.account?.name ?? ""} />
      <Grid sx={styles.container} container spacing={2} px={2}>
        <Grid item xs={12} md={4}>
          <ActiveTaskers />
        </Grid>
        <Grid item xs={12} md={4}>
          <WithdrawToday />
        </Grid>
        <Grid item xs={12} md={4}>
          <AvailableTasks />
        </Grid>
        <Grid item xs={12} md={8}>
          <WorkerOT sx={styles.flex} />
        </Grid>
        <Grid item xs={12} md={4}>
          <WalletBalance sx={styles.flex} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PayoutOT sx={styles.flex} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SubmissionsChart sx={styles.flex} />
        </Grid>
        <Grid item xs={12} md={6}>
          <WithdrawReq />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentTaskSubmission />
        </Grid>
      </Grid>
    </Page>
  );
};

const styles = createStyles({
  container: {
    "&.MuiGrid-container .MuiGrid-root": {
      display: "flex",
      flexDirection: "column",
    },
  },
  flex: {
    flex: 1,
  }
});

export default OverViewPage;
