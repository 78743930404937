import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { QueryTags } from "src/main/constants";
import { AffiliatePageConfig, QueryResult } from "src/main/types";
import { QueryFn } from "../../base";

type PublicApiBuilder<A extends string> = EndpointBuilder<QueryFn, typeof QueryTags.Worker[number], A>;

export interface QueryPageContentResponse {
  page: AffiliatePageConfig | null;
}
export interface QueryPageContentRequest {
  affiliateKey?: string;
  affiliatePageKey: string;
  brand?: string;
}
export const queryPageContent = <A extends string>(builder: PublicApiBuilder<A>) => builder.query({
  query: (params: QueryPageContentRequest) => ({
    url: `/share/page/${params.affiliatePageKey}/content?view=${params.affiliateKey}`,
    validateStatus: (response: Response) => response.status === 200,
  }),
  transformResponse: (response: QueryResult<QueryPageContentResponse>) => {
    return response.result;
  },
})
