import { createSvgIcon } from "@mui/material/utils";

const StopIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 6.75C7.91341 6.75 7.51068 6.91682 7.21375 7.21375C6.91682 7.51068 6.75 7.91341 6.75 8.33333V11.6667C6.75 12.0866 6.91682 12.4893 7.21375 12.7863C7.51068 13.0832 7.91341 13.25 8.33333 13.25H11.6667C12.0866 13.25 12.4893 13.0832 12.7863 12.7863C13.0832 12.4893 13.25 12.0866 13.25 11.6667V8.33333C13.25 7.91341 13.0832 7.51068 12.7863 7.21375C12.4893 6.91682 12.0866 6.75 11.6667 6.75H8.33333ZM8.27441 8.27441C8.29004 8.25878 8.31123 8.25 8.33333 8.25H11.6667C11.6888 8.25 11.71 8.25878 11.7256 8.27441C11.7412 8.29004 11.75 8.31123 11.75 8.33333V11.6667C11.75 11.6888 11.7412 11.71 11.7256 11.7256C11.71 11.7412 11.6888 11.75 11.6667 11.75H8.33333C8.31123 11.75 8.29004 11.7412 8.27441 11.7256C8.25878 11.71 8.25 11.6888 8.25 11.6667V8.33333C8.25 8.31123 8.25878 8.29004 8.27441 8.27441Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.75C7.81196 1.75 5.71354 2.61919 4.16637 4.16637C2.61919 5.71354 1.75 7.81196 1.75 10C1.75 11.0834 1.96339 12.1562 2.37799 13.1571C2.7926 14.1581 3.40029 15.0675 4.16637 15.8336C4.93245 16.5997 5.84192 17.2074 6.84286 17.622C7.8438 18.0366 8.91659 18.25 10 18.25C11.0834 18.25 12.1562 18.0366 13.1571 17.622C14.1581 17.2074 15.0675 16.5997 15.8336 15.8336C16.5997 15.0675 17.2074 14.1581 17.622 13.1571C18.0366 12.1562 18.25 11.0834 18.25 10C18.25 7.81196 17.3808 5.71354 15.8336 4.16637C14.2865 2.61919 12.188 1.75 10 1.75ZM5.22703 5.22703C6.4929 3.96116 8.20979 3.25 10 3.25C11.7902 3.25 13.5071 3.96116 14.773 5.22703C16.0388 6.4929 16.75 8.20979 16.75 10C16.75 10.8864 16.5754 11.7642 16.2362 12.5831C15.897 13.4021 15.3998 14.1462 14.773 14.773C14.1462 15.3998 13.4021 15.897 12.5831 16.2362C11.7642 16.5754 10.8864 16.75 10 16.75C9.11358 16.75 8.23583 16.5754 7.41689 16.2362C6.59794 15.897 5.85382 15.3998 5.22703 14.773C4.60023 14.1462 4.10303 13.4021 3.76381 12.5831C3.42459 11.7642 3.25 10.8864 3.25 10C3.25 8.20979 3.96116 6.4929 5.22703 5.22703Z"
      fill="currentColor"
    />
  </svg>,

  "StopIcon"
);

export default StopIcon;
