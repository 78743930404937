import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import BigNumber from "bignumber.js";
import React, { useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { workerApi } from "src/main/api";
import { QueryResultModel } from "src/main/api/auth/endpoints";
import {
  InputField,
  LoadingButton,
  PortalLayout,
  Typography,
} from "src/main/components";
import { BN_ZERO, CurrencyDecimals, Defaults } from "src/main/constants";
import { useAsyncTask, useFormState, useIsXs } from "src/main/hooks";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { WithdrawRequestModel, WorkerModel } from "src/main/types";
import {
  Paths,
  SimpleMap,
  bnOrZero,
  commonStyles,
  createStyles,
  handleApiResponse,
  IntlFormatter,
} from "src/main/utils";
import * as Yup from "yup";
import { WalletBalance } from "../PayoutsPage/components";
import { ConfirmWithdrawRequestDialog } from "./components";
import { WithdrawalModel } from "./components/ConfirmWithdrawRequestDialog/ConfirmWithdrawRequestDialog";

interface WithdrawRequestPageProps extends React.PropsWithChildren {}

interface FormState {
  withdrawInput: string;
  withdrawAmount: BigNumber;
}
const initialFormState: FormState = {
  withdrawAmount: BN_ZERO,
  withdrawInput: "",
};
const validationSchema = Yup.object().shape({
  withdrawInput: Yup.string(),
});

const fieldKeyMap = {
  amount: "withdrawAmount",
};
// const withdrawCutoff = Defaults.WithdrawCutoffTime.format("h:mma");
const withdrawCutoff = "11:59pm";
const initialWithdrawalDetails: WithdrawalModel = {
  amount: "0",
  currency: "PHP",
};

const WithdrawRequestPage: React.FC<WithdrawRequestPageProps> = (props) => {
  const worker = useSelector<RootState, WorkerModel | undefined | null>(
    (state) => state.auth.worker
  );

  const intl = useIntl();
  const navigate = useNavigate();
  const isXs = useIsXs();
  const [formStates, { setInput, consumeAPIError }] = useFormState(
    initialFormState,
    validationSchema
  );
  const [errors, setErrors] = useState<SimpleMap<string>>({});
  const walletsResult = workerApi.useWorkerWalletListQuery(undefined);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const { palette } = useTheme();
  const [withdrawalDetails, setWithdrawalDetails] = useState<WithdrawalModel>(
    initialWithdrawalDetails
  );

  const { currency: accountCurrency } = useSelector<RootState, AuthState>(
    (state) => state.auth
  );

  const { currency, withdrawLimit } = useMemo(() => {
    return {
      currency: accountCurrency?.default ?? Defaults.WalletCurrency,
      withdrawLimit: accountCurrency?.withdrawLimit ?? 200,
    };
    // eslint-disable-next-line
  }, [accountCurrency, Defaults.WalletCurrency]);

  const withdrawsResult = workerApi.useWorkerWithdrawListQuery({
    meta: { status: "submitted", currency },
  });
  const [submitRequest] = workerApi.useWorkerWithdrawRequestMutation();
  const [runSubmitRequest, loading] = useAsyncTask("worker/withdraw/request");

  const phpWallet = useMemo(
    () =>
      walletsResult.data?.entries.find(
        (wallet) => wallet.currency === currency
      ),
    [walletsResult, currency]
  );
  const { sufficientFunds, maxAmount, formatDecimals } = useMemo(() => {
    const withdraws = withdrawsResult.data?.entries ?? [];
    const lockedAmount = withdraws.reduce((accum, request) => {
      return accum.plus(request.amount);
    }, BN_ZERO);

    const balance = bnOrZero(phpWallet?.balance);
    const maxAmount = balance.minus(lockedAmount);
    const formatDecimals = CurrencyDecimals[currency] ?? 0;

    return {
      sufficientFunds: balance.gte(100),
      maxAmount,
      lockedAmount,
      balance,
      formatDecimals,
    };
  }, [phpWallet, withdrawsResult, currency]);

  const toastMessages = useMemo(() => {
    return {
      withdrawSubmitted: IntlFormatter.getMessage(
        "withdrawRequestPage.success_withdraw_request_submitted",
        "Success! Withdraw request submitted."
      ),
    };
  }, []);

  const onSubmitRequest = () => {
    runSubmitRequest(async () => {
      const amount = formStates.input.withdrawAmount;
      const result = await submitRequest({
        amount: amount.toString(10),
        currency,
      });

      type Response = QueryResultModel<WithdrawRequestModel>;
      const { data, error } = handleApiResponse<Response>(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error, fieldKeyMap) !== null) return;

        let newErrMssg = error.data?.error?.message
          ? error.data.error.message
          : "";
        if (error.data?.error?.errors?.min) {
          newErrMssg +=
            " (" + error.data.error.errors.min + " " + currency + ")";
        }

        throw new Error(newErrMssg);
      }

      if (!data?.model.id) throw new Error("Invalid response");
      navigate(
        Paths.Portal.WithdrawRequestDetail.replace(
          ":withdrawId",
          data?.model?.id
        )
      );
      toast.success(toastMessages.withdrawSubmitted);
    });
  };

  return (
    <Container sx={styles.container}>
      <PortalLayout.BackButton
        url={Paths.Portal.Payouts}
        label={IntlFormatter.getMessage(
          "payoutPage.payout_summary",
          "Payout Summary"
        )}
      />
      {!sufficientFunds && (
        <Alert severity="warning">
          <AlertTitle>
            {IntlFormatter.getMessage(
              "withdrawRequestPage.insufficient_funds",
              "Insufficient Funds"
            )}
          </AlertTitle>
          <Typography
            formatId="withdrawRequestPage._minumum_required_request_withdrawal"
            variant="body2"
            formatValues={{ withdrawLimit: withdrawLimit, currency: currency }}
          >
            You need a minimum of {withdrawLimit} {currency} to request for a
            withdrawal.
          </Typography>
        </Alert>
      )}
      <Card>
        <CardContent sx={commonStyles.cardContent}>
          <Typography formatId="withdrawRequestPage.withdrawal" variant="h6">
            Withdrawal
          </Typography>
          <Box display="flex" flexDirection="row" mt={1}>
            <Typography
              formatId="withdrawRequestPage.gCashAccount"
              variant="caption"
              color="textSecondary"
            />
            <Typography variant="caption" paddingLeft={0.5} color="textPrimary">
              {worker?.gCashAccount}
            </Typography>
          </Box>

          <WalletBalance sx={{ backgroundColor: "secondary", mt: 2 }} />
          <Typography
            formatId="withdrawRequestPage._withdrawal_amount"
            variant="body2"
            sx={{ fontWeight: 700, mt: 2 }}
            formatValues={{ currency: currency }}
          />
          <Box display="flex" justifyContent="space-between">
            <Button
              fullWidth
              sx={{ mt: 2, mr: { md: 1, sm: 0 }, marginRight: 1 }}
              variant="outlined"
              onClick={() => {
                setErrors({});
                setInput((formStates) => ({
                  ...formStates,
                  withdrawAmount: bnOrZero(withdrawLimit),
                  withdrawInput: withdrawLimit.toString(),
                }));
              }}
            >
              {IntlFormatter.getMessage(
                "withdrawRequestPage._min_withdraw_limit",
                `Min. ${withdrawLimit}`,
                { withdrawLimit: withdrawLimit }
              )}
            </Button>
            <Button
              fullWidth
              sx={{ mt: 2, ml: { md: 1, sm: 0 } }}
              variant="outlined"
              onClick={() => {
                setErrors({});
                const mxAmt = parseFloat(
                  maxAmount.toFormat(formatDecimals).replace(/,/g, "")
                );
                setInput((formStates) => ({
                  ...formStates,
                  withdrawAmount: bnOrZero(mxAmt),
                  withdrawInput: mxAmt.toString(),
                }));
              }}
            >
              {IntlFormatter.getMessage(
                "withdrawRequestPage._max_withdraw_limit",
                `Max. ${maxAmount.toFormat(formatDecimals)}`,
                { maxAmount: maxAmount.toFormat(formatDecimals) }
              )}
            </Button>
          </Box>
          <InputField
            sx={{ mt: 2 }}
            type="number"
            label={IntlFormatter.getMessage(
              "withdrawRequestPage.enter_withdrawal_amount",
              "Enter Withdrawal Amount"
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {accountCurrency?.default}
                </InputAdornment>
              ),
            }}
            value={formStates.input.withdrawInput}
            onChange={(e) => {
              setErrors({});
              if (BigNumber(e.target.value).isLessThan(withdrawLimit)) {
                const curError = intl.formatMessage(
                  {
                    id: "withdrawRequestPage._minimum_withdrawal_amount",
                    defaultMessage:
                      "Minimum withdrawal amount: {withdrawLimit} {currency}",
                  },
                  { withdrawLimit: withdrawLimit, currency: currency }
                );
                setErrors({
                  withdrawInput: curError,
                });
              }
              if (BigNumber(e.target.value).isGreaterThan(maxAmount)) {
                const curError = intl.formatMessage({
                  id: "withdrawRequestPage._withdrawal_amount_cannot_exceed",
                  defaultMessage:
                    "Withdrawal amount cannot exceed available balance. Please enter a lower amount.",
                });
                setErrors({
                  withdrawInput: curError,
                });
              }
              setInput((inputs) => ({
                ...inputs,
                withdrawAmount: bnOrZero(e.target.value),
                withdrawInput: e.target.value,
              }));
            }}
            error={!!errors.withdrawInput}
            helperText={errors.withdrawInput}
          />
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <LoadingButton
              fullWidth={isXs}
              loading={loading}
              size="large"
              sx={{ background: palette.gradient?.main, mt: 2 }}
              variant="contained"
              disabled={
                BigNumber(formStates.input.withdrawInput).isLessThan(
                  withdrawLimit
                ) ||
                BigNumber(formStates.input.withdrawInput).isGreaterThan(
                  maxAmount
                ) ||
                formStates.input.withdrawInput === ""
              }
              onClick={() => {
                setWithdrawalDetails({
                  amount: formStates.input.withdrawInput,
                  currency: currency,
                });
                setOpenNotificationDialog(!openNotificationDialog);
              }}
            >
              {IntlFormatter.getMessage(
                "withdrawRequestPage.confirm_withdrawal",
                "Confirm Withdrawal"
              )}
            </LoadingButton>
          </Box>
          <ConfirmWithdrawRequestDialog
            onClose={() => setOpenNotificationDialog(false)}
            open={openNotificationDialog}
            confirmWithdrawal={onSubmitRequest}
            withdrawalDetails={withdrawalDetails}
          />

          <Box sx={{ mt: 4 }}>
            <Typography
              formatId="withdrawRequestPage.please_note"
              variant="body2"
              sx={{ fontWeight: 700 }}
            >
              Please Note
            </Typography>
            <List>
              <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                <ListItemIcon sx={{}}>
                  <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      formatId="withdrawRequestPage._withdrawal_request_proccesess_days"
                      variant="caption"
                      display="block"
                      lineHeight={1.375}
                      ml={-1}
                      color="text.secondary"
                    >
                      Withdrawal request will be processed within 1-2 working
                      days.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="caption"
                      display="block"
                      lineHeight={1.375}
                      ml={-1}
                      color="text.secondary"
                    >
                      <FormattedMessage
                        id="withdrawRequestPage._withdrawal_request_cutoff"
                        defaultMessage={`Withdrawal request submitted after <Box component="span" color="text.primary">${withdrawCutoff}</Box> will be processed the next working day`}
                        values={{
                          withdrawCutoff: withdrawCutoff,
                          code: (withdrawCutoff) => (
                            <Box component="span" color="text.primary">
                              {withdrawCutoff}
                            </Box>
                          ),
                        }}
                      />
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

const styles = createStyles({
  container: {
    pt: 1,
  },
});

export default WithdrawRequestPage;
