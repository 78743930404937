import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { accountApi } from "src/main/api";
import { DetailCard, InputField, Page } from "src/main/components";
import { PersonModel } from "src/main/types";
import * as Yup from "yup";
import { handleApiResponse } from "src/main/utils";
import { UpdateUserResponse } from "src/main/api/account/User";
import { useCallbackRef } from "src/main/hooks";

interface UserEditPageProps extends React.PropsWithChildren {
  toggleEdit: () => void;
  person: PersonModel;
}

const SALUTATION_OPTIONS = [
  { value: "Mr.", label: "Mr" },
  { value: "Ms.", label: "Ms" },
  { value: "Mrs.", label: "Mrs" },
  { value: "Mdm.", label: "Mdm" },
  { value: "Dr.", label: "Dr" },
];

const GENDER_OPTIONS = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "others", label: "Others" },
];

const UserEditPage: React.FC<UserEditPageProps> = (props) => {
  const { person, toggleEdit } = props;

  const cbToggleEdit = useCallbackRef(toggleEdit);

  const [updateUserMutation, { isLoading: isSubmitLoading }] =
    accountApi.useUpdateUserMutation();

  const initial_values = {
    firstname: person.firstname ?? "",
    middlename: person.middlename ?? "",
    lastname: person.lastname ?? "",
    salutation: person.salutation ?? "",
    gender: person.gender ?? "",
    primaryEmail: person.primaryEmail ?? "",
    primaryPhone: person.primaryPhone ?? "",
    discordHandle: person.discordHandle ?? "",
    instagramHandle: person.instagramHandle ?? "",
    tiktokHandle: person.tiktokHandle ?? "",
    twitterHandle: person.twitterHandle ?? "",
    telegramHandle: person.telegramHandle ?? "",
    facebookHandle: person.facebookHandle ?? "",
    facebookAltHandle: person.facebookAltHandle ?? "",
  };

  const onSubmit = useCallback(
    async (values: FormikValues, { setErrors }) => {
      const val = {
        firstname: values.firstname,
        middlename: values.middlename,
        lastname: values.lastname,
        salutation: values.salutation,
        gender: values.gender,
        primaryEmail: values.primaryEmail,
        primaryPhone: values.primaryPhone,
        discordHandle: values.discordHandle,
        instagramHandle: values.instagramHandle,
        tiktokHandle: values.tiktokHandle,
        twitterHandle: values.twitterHandle,
        telegramHandle: values.telegramHandle,
        facebookHandle: values.facebookHandle,
        facebookAltHandle: values.facebookAltHandle,
      };

      const result = await updateUserMutation({
        personId: person.id,
        data: val,
      });
      const { error } = handleApiResponse<UpdateUserResponse>(result);

      if ("data" in result) {
        toast.success("User updated");
        cbToggleEdit();
      } else {
        if (error && error.data?.error) {
          let err = error.data?.error;
          let newErr = {};

          if (err.errors) {
            Object.entries(err.errors).forEach(([key, value]) => {
              newErr[key] = value.msg;
            });
            setErrors(newErr);
          }

          toast.error(err.message);
        }

        throw new Error((result.error as any).data?.error?.message);
      }

    },
    [person.id, cbToggleEdit, updateUserMutation]
  );

  return (
    <Page>
      <Formik
        initialValues={initial_values}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required("First name is required"),
          lastname: Yup.string(),
          gender: Yup.string(),
          primaryEmail: Yup.string()
            .email("Email is invalid")
            .required("Email address is required"),

          salutation: Yup.string(),
          primaryPhone: Yup.string(),
          discordHandle: Yup.string(),
          instagramHandle: Yup.string(),
          tiktokHandle: Yup.string(),
          twitterHandle: Yup.string(),
          telegramHandle: Yup.string(),
          facebookHandle: Yup.string(),
          facebookAltHandle: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          validateForm,
          setErrors,
        }): JSX.Element => (
          <Form
            onSubmit={(e) => {
              validateForm().then((err: any) => {
                Object.keys(err).forEach((err) => {
                  toast.error(`error validating ${err}`);
                });
              });
              handleSubmit(e);
            }}
          >
            <Page.TopSection title="Edit User">
              <Box display="flex">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  disabled={isSubmitLoading}
                  onClick={toggleEdit}
                  sx={{ marginRight: 2, flex: 1 }}
                >
                  Cancel
                </Button>

                <Button
                  disabled={isSubmitLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {}}
                  sx={{ flex: 1 }}
                >
                  <EditIcon />
                  &nbsp;<Typography>Update</Typography>
                </Button>
              </Box>
            </Page.TopSection>
            <Container>
              <Page.Content>
                <Box flex={1}>
                  <DetailCard>
                    <DetailCard.Header header="Personal Info" />
                    {/* <DetailCard.Divider /> */}
                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="firstname"
                          label="Firstname"
                          required
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.firstname && !!touched.firstname}
                          helperText={!!touched.firstname && errors.firstname}
                          placeholder="e.g. John"
                        />
                      </Grid>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="middlename"
                          label="Middlename"
                          value={values.middlename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.middlename && !!touched.middlename}
                          helperText={!!touched.middlename && errors.middlename}
                          placeholder="e.g. Doe"
                        />
                      </Grid>

                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="lastname"
                          label="Lastname"
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.lastname && !!touched.lastname}
                          helperText={!!touched.lastname && errors.lastname}
                          placeholder="e.g. Doe"
                        />
                      </Grid>

                      <Grid container item md={3} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Gender</InputLabel>
                          <Select
                            displayEmpty
                            input={<OutlinedInput notched label="Gender" />}
                            value={values.gender}
                            sx={{
                              paddingY: "12px",
                              paddingX: "10px",
                              "& .MuiSelect-select": {
                                p: 0,
                              },
                            }}
                            onChange={(ev) =>
                              setFieldValue("gender", ev.target.value)
                            }
                          >
                            <MenuItem value="" disabled>
                              None
                            </MenuItem>
                            {GENDER_OPTIONS.map((gender) => (
                              <MenuItem value={gender.value}>
                                {gender.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid container item md={3} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Salutation</InputLabel>
                          <Select
                            input={<OutlinedInput notched label="Salutation" />}
                            value={values.salutation}
                            sx={{
                              paddingY: "12px",
                              paddingX: "10px",
                              "& .MuiSelect-select": {
                                p: 0,
                              },
                            }}
                            onChange={(ev) =>
                              setFieldValue("salutation", ev.target.value)
                            }
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              None
                            </MenuItem>
                            {SALUTATION_OPTIONS.map((salutation) => (
                              <MenuItem value={salutation.value}>
                                {salutation.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <DetailCard.Header header="Contact Info" />

                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="primaryEmail"
                          label="Email"
                          required
                          value={values.primaryEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.primaryEmail && !!touched.primaryEmail
                          }
                          helperText={
                            !!touched.primaryEmail && errors.primaryEmail
                          }
                          placeholder="e.g: tom@example.com"
                        />
                      </Grid>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="primaryPhone"
                          label="Phone"
                          value={values.primaryPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.primaryPhone && !!touched.primaryPhone
                          }
                          helperText={
                            !!touched.primaryPhone && errors.primaryPhone
                          }
                          placeholder="e.g: 1234567890"
                        />
                      </Grid>
                    </Grid>

                    <DetailCard.Header header="Social Media" />

                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="facebookHandle"
                          label="Facebook Handle"
                          value={values.facebookHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.facebookHandle && !!touched.facebookHandle
                          }
                          helperText={
                            !!touched.facebookHandle && errors.facebookHandle
                          }
                          placeholder="e.g: tomcruise"
                        />
                      </Grid>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="facebookAltHandle"
                          label="Facebook Handle (Alt)"
                          value={values.facebookAltHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.facebookAltHandle &&
                            !!touched.facebookAltHandle
                          }
                          helperText={
                            !!touched.facebookAltHandle &&
                            errors.facebookAltHandle
                          }
                          placeholder="e.g: tomcruise"
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="discordHandle"
                          label="Discord Handle"
                          value={values.discordHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.discordHandle && !!touched.discordHandle
                          }
                          helperText={
                            !!touched.discordHandle && errors.discordHandle
                          }
                          placeholder="e.g: tomcruise#1234"
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="instagramHandle"
                          label="Instagram Handle"
                          value={values.instagramHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.instagramHandle &&
                            !!touched.instagramHandle
                          }
                          helperText={
                            !!touched.instagramHandle && errors.instagramHandle
                          }
                          placeholder="e.g: tomcruise"
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="tiktokHandle"
                          label="TikTok Handle"
                          value={values.tiktokHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.tiktokHandle && !!touched.tiktokHandle
                          }
                          helperText={
                            !!touched.tiktokHandle && errors.tiktokHandle
                          }
                          placeholder="e.g: @tomcruise"
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="twitterHandle"
                          label="Twitter Handle"
                          value={values.twitterHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.twitterHandle && !!touched.twitterHandle
                          }
                          helperText={
                            !!touched.twitterHandle && errors.twitterHandle
                          }
                          placeholder="e.g: tomcruise"
                        />
                      </Grid>
                      <Grid container item md={4} xs={12}>
                        <InputField
                          name="telegramHandle"
                          label="Telegram Handle"
                          value={values.telegramHandle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!errors.telegramHandle && !!touched.telegramHandle
                          }
                          helperText={
                            !!touched.telegramHandle && errors.telegramHandle
                          }
                          placeholder="e.g: tomcruise"
                        />
                      </Grid>
                    </Grid>

                    {/* <DetailCard.Header header="Credentials" />

                    <Grid container spacing={2} p={2}>
                      <Grid container item md={6} xs={12}>
                        <InputField
                          name="accessKey"
                          label="Username"
                          value={values.accessKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.accessKey && !!touched.accessKey}
                          helperText={!!touched.accessKey && errors.accessKey}
                        />
                      </Grid>
                    </Grid> */}
                  </DetailCard>
                </Box>
              </Page.Content>
            </Container>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default UserEditPage;
