import { createSvgIcon } from '@mui/material/utils';

const Archive = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 4.08331C3.92355 4.08331 3.69039 4.17989 3.51849 4.3518C3.34658 4.52371 3.25 4.75686 3.25 4.99998C3.25 5.24309 3.34658 5.47625 3.51849 5.64816C3.69039 5.82007 3.92355 5.91665 4.16667 5.91665H15.8333C16.0764 5.91665 16.3096 5.82007 16.4815 5.64816C16.6534 5.47625 16.75 5.24309 16.75 4.99998C16.75 4.75686 16.6534 4.52371 16.4815 4.3518C16.3096 4.17989 16.0764 4.08331 15.8333 4.08331H4.16667ZM2.45783 3.29114C2.91104 2.83793 3.52573 2.58331 4.16667 2.58331H15.8333C16.4743 2.58331 17.089 2.83793 17.5422 3.29114C17.9954 3.74435 18.25 4.35904 18.25 4.99998C18.25 5.64092 17.9954 6.25561 17.5422 6.70882C17.2708 6.98021 16.9415 7.18039 16.5833 7.29732V15C16.5833 15.6409 16.3287 16.2556 15.8755 16.7088C15.4223 17.162 14.8076 17.4166 14.1667 17.4166H5.83333C5.19239 17.4166 4.5777 17.162 4.12449 16.7088C3.67128 16.2556 3.41667 15.6409 3.41667 15V7.29732C3.05851 7.18039 2.72922 6.98021 2.45783 6.70882C2.00461 6.25561 1.75 5.64092 1.75 4.99998C1.75 4.35904 2.00461 3.74435 2.45783 3.29114ZM4.91667 7.41665V15C4.91667 15.2431 5.01324 15.4763 5.18515 15.6482C5.35706 15.8201 5.59022 15.9166 5.83333 15.9166H14.1667C14.4098 15.9166 14.6429 15.8201 14.8148 15.6482C14.9868 15.4763 15.0833 15.2431 15.0833 15V7.41665H4.91667ZM7.58333 9.99998C7.58333 9.58577 7.91912 9.24998 8.33333 9.24998H11.6667C12.0809 9.24998 12.4167 9.58577 12.4167 9.99998C12.4167 10.4142 12.0809 10.75 11.6667 10.75H8.33333C7.91912 10.75 7.58333 10.4142 7.58333 9.99998Z" fill="currentColor" />
    </svg>
    ,
    'Archive'
);

export default Archive;