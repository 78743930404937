import { Box, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { accountApi } from "src/main/api";
import { EmptyState, ListingComp, LoadingContainer, Page, Table, WorkerCard } from "src/main/components";
import { AffiliateTrackerModel, AffiliateTrackerShortLinkModel, Meta } from "src/main/types";
import { AffiliatePageInfoCard, AffiliateTrackerStatsCard, AffiliateTrackerDailyStatsChart } from "./components";


interface AffiliateTrackerDetailProps extends React.PropsWithChildren {

}

const HEADERS = [
  { value: "Link" },
  { value: "1D" },
  { value: "7D" },
  { value: "30D" },
  { value: "1D(U)" },
  { value: "7D(U)" },
  { value: "30D(U)" },
]

const AffiliateTrackerDetail: React.FC<AffiliateTrackerDetailProps> = () => {
  const { affiliateTrackerId = "" } = useParams();
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 })
  const result = accountApi.useDetailAffiliateTrackerQuery(affiliateTrackerId);
  const linkTrackersResult = accountApi.useListAffiliateVisitTrackersQuery(affiliateTrackerId);

  const affiliateTracker: AffiliateTrackerModel | undefined = useMemo(() => result?.data ?? undefined, [result?.data]);
  const linkTrackers = useMemo(() => linkTrackersResult?.data?.entries ?? [] as AffiliateTrackerShortLinkModel[], [linkTrackersResult.data?.entries]);

  const persistMeta = useMemo(() => ({ ...meta, ...linkTrackersResult.data?.meta }), [linkTrackersResult.data?.meta, meta])

  return (
    <Page>
      <Page.TopSection title="Affiliate Tracker">
      </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        {!affiliateTracker && <EmptyState message="No Affiliate Tracker" />}
        {affiliateTracker && (
          <Page.Content>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <AffiliateTrackerDailyStatsChart affiliateTrackerId={affiliateTracker.id} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box >
                  <AffiliateTrackerStatsCard affiliateTracker={affiliateTracker} />
                </Box>
                <ListingComp
                  loading={result.isLoading} title="Clicks"
                  updateList={(newMeta) => { setMeta(newMeta) }}
                  meta={persistMeta}
                  sx={{ mx: 0 }}
                >
                  <Box pt={2} />
                  <ListingComp.Content>
                    <Table>
                      <Table.Head headers={HEADERS} />
                      <Table.Body>
                        {linkTrackers.map((trackerShortLink) => (
                          <Table.Row key={trackerShortLink.id}>
                            <Table.Cell>
                              {trackerShortLink.shortLink?.url}
                            </Table.Cell>
                            <Table.Cell>{trackerShortLink.dayVisit?.count1d ?? 0}</Table.Cell>
                            <Table.Cell>{trackerShortLink.dayVisit?.count7d ?? 0}</Table.Cell>
                            <Table.Cell>{trackerShortLink.dayVisit?.count30d ?? 0}</Table.Cell>
                            <Table.Cell>{trackerShortLink.dayVisit?.ucount1d ?? 0}</Table.Cell>
                            <Table.Cell>{trackerShortLink.dayVisit?.ucount7d ?? 0}</Table.Cell>
                            <Table.Cell>{trackerShortLink.dayVisit?.ucount30d ?? 0}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </ListingComp.Content>
                </ListingComp>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Box pb={2}>
                    <WorkerCard worker={affiliateTracker?.worker} />
                  </Box>
                  <AffiliatePageInfoCard affiliatePage={affiliateTracker.affiliatePage} />
                </Box>
              </Grid>
            </Grid>
          </Page.Content>
        )}
      </LoadingContainer>
    </Page >
  );
};

export default AffiliateTrackerDetail;
