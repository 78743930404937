import { createSvgIcon } from "@mui/material/utils";

export const AntGameIcon = createSvgIcon(
  <svg
    viewBox="0 0 43 46" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7857 8.12305C14.5338 8.25196 14.3287 8.5977 14.3287 8.87897C14.3287 9.35362 14.7623 9.75795 15.278 9.75795C15.6706 9.75795 16.2859 9.91031 16.6961 10.1095C17.4461 10.4611 18.0966 11.1526 18.3954 11.9085C18.6474 12.5414 18.6533 12.5238 18.2196 12.7348C16.7957 13.438 16.3855 15.1256 17.2352 16.8015C17.4168 17.159 17.4696 17.1824 17.6571 17.0125C18.1024 16.5964 19.0635 16.1042 19.8077 15.8991C20.4815 15.7175 21.8879 15.7175 22.5618 15.8991C23.306 16.1042 24.267 16.5964 24.7124 17.0125C24.9116 17.1941 24.9819 17.1473 25.1929 16.6785C25.9547 14.985 25.5503 13.438 24.1674 12.7465C23.9623 12.641 23.7865 12.5473 23.7748 12.5414C23.7338 12.5062 24.0209 11.7562 24.1909 11.4573C24.7358 10.4963 25.9898 9.76381 27.0915 9.75795C27.4489 9.75795 27.7771 9.57044 27.9236 9.28916C28.2459 8.65629 27.8064 7.99999 27.0563 7.99999C26.5993 7.99999 25.7847 8.16407 25.3101 8.34572C24.0092 8.84967 22.931 9.88687 22.3801 11.1585C22.2688 11.4046 22.1516 11.7445 22.1165 11.9085L22.0461 12.2191H21.1906H20.3292L20.2296 11.8148C20.1007 11.3108 19.7022 10.5139 19.3682 10.0861C18.5536 9.02547 17.3875 8.31056 16.1159 8.08789C15.4303 7.97069 15.0553 7.97655 14.7857 8.12305Z" fill="white" />
    <path d="M9.61735 13.5258C9.35365 13.6723 9.172 13.977 9.172 14.2583C9.172 14.4986 9.35365 14.8677 9.53531 14.9849C9.61149 15.0318 10.3967 15.3131 11.2874 15.6119L12.8989 16.1452L13.4087 17.4226C13.69 18.12 13.9712 18.7763 14.0298 18.8759C14.1177 19.0165 14.3404 19.163 14.9616 19.4795L15.7819 19.8955L15.8874 19.5849C15.9402 19.4209 16.0925 19.0693 16.2273 18.8114C16.3562 18.5536 16.4734 18.3309 16.4851 18.3133C16.491 18.2958 16.2625 18.161 15.9695 18.0203L15.4479 17.7625L14.9674 16.5612C14.3873 15.108 14.2584 14.8443 14.0826 14.7271C13.8775 14.5923 10.2209 13.391 10.0217 13.391C9.92206 13.391 9.74041 13.4496 9.61735 13.5258Z" fill="white" />
    <path d="M30.2968 14.0121C29.3006 14.3461 28.4158 14.6508 28.3396 14.6918C28.1287 14.8032 28.0349 14.9907 27.4548 16.4439L26.9215 17.7624L26.4 18.0202C26.107 18.1609 25.8785 18.2957 25.8843 18.3132C25.896 18.3308 26.0132 18.5535 26.1422 18.8113C26.2769 19.0692 26.4176 19.3856 26.4527 19.5145C26.4938 19.6434 26.5348 19.7841 26.5524 19.8251C26.5641 19.8778 26.8278 19.7723 27.402 19.4793C28.029 19.1629 28.2517 19.0164 28.3396 18.8758C28.3982 18.7762 28.6795 18.1199 28.9608 17.4225L29.4706 16.1451L30.9941 15.6411C31.838 15.3599 32.6232 15.0844 32.7404 15.02C32.9924 14.8969 33.1975 14.5571 33.1975 14.2582C33.1975 13.8187 32.758 13.3909 32.3185 13.3968C32.2071 13.3968 31.2989 13.6722 30.2968 14.0121Z" fill="white" />
    <path d="M20.3643 17.5867C19.3154 17.8094 18.2724 18.6239 17.7918 19.6025C17.4754 20.253 17.3524 20.8214 17.3934 21.507C17.4285 22.0637 17.575 22.6497 17.6747 22.6497C17.7098 22.6497 17.8153 22.5969 17.909 22.5266C18.2196 22.3098 19.0986 21.9348 19.6612 21.7824C20.1534 21.6535 20.335 21.6359 21.1847 21.6359C22.0344 21.6359 22.2161 21.6535 22.7083 21.7824C23.2708 21.9348 24.1498 22.3098 24.4604 22.5266C24.5542 22.5969 24.6596 22.6497 24.6948 22.6497C24.7944 22.6497 24.9409 22.0637 24.9761 21.507C25.0815 19.6728 23.7748 17.9559 21.9758 17.5809C21.4719 17.4754 20.8683 17.4754 20.3643 17.5867Z" fill="white" />
    <path d="M14.3638 22.5326C13.7544 22.6908 13.186 22.849 13.1098 22.8959C12.893 23.0073 12.8227 23.1479 12.2719 24.5191L11.762 25.7848L10.1857 26.0544C9.32433 26.2009 8.52739 26.365 8.42777 26.4177C8.2051 26.5349 8 26.8982 8 27.1619C8 27.5838 8.42777 28.0409 8.8321 28.0409C9.18956 28.0409 12.5648 27.4432 12.7875 27.3377C13.0864 27.2029 13.145 27.0975 13.7427 25.5797C13.9771 24.982 14.1998 24.4664 14.2349 24.4312C14.2701 24.396 14.6451 24.2789 15.0612 24.1792C15.4831 24.0738 15.9226 23.9683 16.0398 23.9331L16.2566 23.8804L16.0866 23.5053C15.9929 23.3003 15.864 22.9369 15.8054 22.6967C15.7175 22.3685 15.6647 22.2689 15.5827 22.2572C15.5182 22.2513 14.9733 22.3744 14.3638 22.5326Z" fill="white" />
    <path d="M26.6696 22.3097C26.6579 22.3507 26.5993 22.5558 26.5465 22.7667C26.4879 22.9777 26.3707 23.3117 26.2829 23.5109L26.1129 23.8801L26.3297 23.9329C26.4469 23.968 26.8923 24.0735 27.3083 24.179C27.7302 24.2786 28.0994 24.3958 28.1346 24.4309C28.1697 24.4661 28.3924 24.9818 28.6268 25.5795C29.2245 27.0972 29.2831 27.2027 29.582 27.3374C29.8867 27.4781 33.4319 28.0699 33.6722 28.0172C34.0531 27.941 34.3695 27.5543 34.3695 27.1616C34.3695 26.898 34.1644 26.5346 33.9417 26.4174C33.8362 26.3588 33.0862 26.2006 32.1779 26.0483L30.6074 25.7846L30.1269 24.5833C29.5116 23.048 29.4941 23.0246 29.1952 22.8839C28.9784 22.7843 26.9216 22.2393 26.7516 22.2393C26.7165 22.2393 26.6813 22.2745 26.6696 22.3097Z" fill="white" />
    <path d="M20.4405 23.4115C18.6533 23.7338 17.036 25.2398 16.239 27.3083C14.4693 31.92 16.6726 37.3463 20.5577 37.9498C23.1185 38.3483 25.5679 36.3384 26.4293 33.1272C27.5544 28.9549 25.6031 24.3901 22.3274 23.5052C21.8997 23.388 20.8566 23.3412 20.4405 23.4115Z" fill="white" />
    <path d="M13.2446 28.8552C12.4477 29.3943 12.2894 29.5291 12.2015 29.7166C12.1488 29.8455 12.0082 30.4433 11.891 31.0585C11.7738 31.668 11.6449 32.2012 11.6097 32.2364C11.5745 32.2715 11.0296 32.4005 10.4026 32.5235C9.77555 32.6466 9.16027 32.7931 9.04307 32.8575C8.79109 32.9806 8.586 33.3205 8.586 33.6076C8.586 34.0295 9.01963 34.4866 9.4181 34.4866C9.69938 34.4866 12.5531 33.8947 12.7875 33.7834C12.9399 33.7131 13.0747 33.5959 13.1333 33.4787C13.2387 33.2794 14.2818 28.2106 14.2173 28.2224C14.1998 28.2224 13.7603 28.5095 13.2446 28.8552Z" fill="white" />
    <path d="M28.6268 30.748C28.9022 32.1426 29.1776 33.3732 29.2362 33.4787C29.2948 33.5959 29.4296 33.7131 29.5819 33.7834C29.8163 33.8947 32.6701 34.4866 32.9514 34.4866C33.3498 34.4866 33.7835 34.0295 33.7835 33.6076C33.7835 33.3205 33.5784 32.9806 33.3264 32.8575C33.2092 32.7931 32.5939 32.6466 31.9669 32.5235C31.3399 32.4005 30.7949 32.2715 30.7598 32.2364C30.7246 32.2012 30.5957 31.668 30.4785 31.0585C30.3613 30.4433 30.2207 29.8455 30.1679 29.7166C30.08 29.5233 29.9218 29.3943 29.1131 28.8552C28.5916 28.5036 28.1521 28.2165 28.1404 28.2165C28.1287 28.2165 28.3455 29.3592 28.6268 30.748Z" fill="white" />
  </svg>
  ,
  'AntGame'
);

export default AntGameIcon;