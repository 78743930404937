import { Box, BoxProps, Button, styled } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { accountApi } from "src/main/api";
import { Page } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { PersonModel, WorkerModel } from "src/main/types";
import { handleFormikApiResponse, Paths, SimpleMap } from "src/main/utils";
import * as Yup from "yup";
import {
  PersonalInformation,
  WorkerInformation,
} from "../CreateWorker/components";
import { CredentialInformation } from "./components";

interface WorkerEditProps extends React.PropsWithChildren {}

const CreateContentBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 8,
  gap: 16,
  display: "flex",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const WorkerEdit: React.FC<WorkerEditProps> = (props) => {
  const { workerId = "" } = useParams();
  const result = accountApi.useGetWorkerQuery({ workerId });
  const worker: WorkerModel | undefined = useMemo(
    () => result?.data?.model ?? undefined,
    [result?.data?.model]
  );

  const [runUpdateWorker] = useAsyncTask("update/worker");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const person = (worker?.person ?? {}) as PersonModel;

  const initial_values = useMemo(() => {
    return {
      firstname: person?.firstname ?? "",
      middlename: person?.middlename ?? "",
      lastname: person?.lastname ?? "",
      salutation: person?.salutation ?? "",
      gender: person?.gender ?? "",
      primaryEmail: person?.primaryEmail ?? "",
      primaryPhone: person?.primaryPhone ?? "",
      discordHandle: person?.discordHandle ?? "",
      instagramHandle: person?.instagramHandle ?? "",
      tiktokHandle: person?.tiktokHandle ?? "",
      twitterHandle: person?.twitterHandle ?? "",
      telegramHandle: person?.telegramHandle ?? "",
      facebookHandle: person?.facebookHandle ?? "",
      facebookAltHandle: person?.facebookAltHandle ?? "",
      status: worker?.status ?? "",
      username: person?.entity?.credentials?.[0]?.accessKey ?? "",
      password: "",
      remark: worker?.remark ?? "",
      gCashAccount: worker?.gCashAccount ?? "",
    };

    // eslint-disable-next-line
  }, [worker, person, result.data]);

  const [updateWorkerMutation, { isLoading: isUpdateWorkerLoading }] =
    accountApi.useUpdateWorkerMutation();
  const [
    updateWorkerPersonMutation,
    { isLoading: isUpdateWorkerPersonLoading },
  ] = accountApi.useUpdateWorkerPersonMutation();

  const onSubmit = useCallback(
    async (values: FormikValues) => {
      if (!worker) return;

      runUpdateWorker(async () => {
        const person_data = {
          firstname: values.firstname,
          middlename: values.middlename,
          lastname: values?.lastname,
          salutation: !!values?.salutation ? values.salutation : undefined,
          gender: !!values?.gender ? values.gender : undefined,
          primaryEmail: values?.primaryEmail,
          primaryPhone: values?.primaryPhone,
          discordHandle: values?.discordHandle,
          instagramHandle: values?.instagramHandle,
          tiktokHandle: values?.tiktokHandle,
          twitterHandle: values?.twitterHandle,
          telegramHandle: values?.telegramHandle,
          facebookHandle: values?.facebookHandle,
          facebookAltHandle: values?.facebookAltHandle,
        } as SimpleMap<string>;

        const worker_data: any = {
          person: person_data,
          status: values.status,
          remark: values?.remark,
          gCashAccount: values?.gCashAccount,
        };

        if (values.username) {
          if (!worker_data.credential) worker_data.credential = {};
          worker_data.credential.accessKey = values.username;
        }

        if (values.password) {
          if (!worker_data.credential) worker_data.credential = {};
          worker_data.credential.secret = values.password;
        }

        const workerResult = await updateWorkerMutation({
          workerId: worker.id,
          model: worker_data,
        });
        const { error: workerError } = handleFormikApiResponse(workerResult);
        if (workerError?.data?.error) {
          throw new Error(workerError.data?.error?.message);
        }
        toast.success("Worker details updated!");

        result.refetch();
      });
    },
    // eslint-disable-next-line
    [dispatch, updateWorkerMutation, updateWorkerPersonMutation, worker]
  );

  return (
    <Page>
      <Formik
        initialValues={initial_values}
        enableReinitialize
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required("First name is required"),
          lastname: Yup.string(),
          gender: Yup.string(),
          primaryEmail: Yup.string()
            .email("Email is invalid")
            .required("Email address is required"),

          salutation: Yup.string(),
          primaryPhone: Yup.string(),
          discordHandle: Yup.string(),
          instagramHandle: Yup.string(),
          tiktokHandle: Yup.string(),
          twitterHandle: Yup.string(),
          telegramHandle: Yup.string(),
          facebookHandle: Yup.string(),
          facebookAltHandle: Yup.string(),
          username: Yup.string(),
          password: Yup.string(),
          gCashAccount: Yup.string()
            .matches(
              /^0[0-9]+/,
              "GCash account must be a number leading with 0"
            )
            .length(11, "GCash account must be 11 digits")
            .required("GCash account is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }): JSX.Element => (
          <Form noValidate onSubmit={handleSubmit}>
            <Page.TopSection title="Update Worker">
              <Box display="flex">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  disabled={
                    isUpdateWorkerLoading || isUpdateWorkerPersonLoading
                  }
                  onClick={() =>
                    navigate(
                      Paths.Management.WorkerDetail.replace(
                        ":workerId",
                        workerId
                      )
                    )
                  }
                  sx={{ marginRight: 2 }}
                >
                  Cancel
                </Button>

                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={
                    isUpdateWorkerLoading || isUpdateWorkerPersonLoading
                  }
                >
                  Update
                </Button>
              </Box>
            </Page.TopSection>

            <CreateContentBox>
              <Box flex={1} pb={2}>
                <PersonalInformation
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <Box flex={1} pb={2}>
                <Box>
                  <WorkerInformation
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Box>
                <Box>
                  <CredentialInformation
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </Box>
            </CreateContentBox>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default WorkerEdit;
