import { CloseOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { toast } from "react-hot-toast";
import { authApi } from "src/main/api";
import { commonStyles, handleApiResponse } from "src/main/utils";

interface ProfileNameFormProps {
  open: boolean;
  onClose: () => void;
  defaultValue: { firstName: string; middleName: string; lastName: string };
  label: string;
}

const ProfileNameForm: React.FC<ProfileNameFormProps> = (props) => {
  const { open, onClose, defaultValue, label } = props;
  const [value, setValue] = React.useState(defaultValue);

  const [originalValue, setOriginalValue] = React.useState(defaultValue);

  const [submitPersonInfo] = authApi.useWorkerPersonInfoMutation();
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (open) {
      setValue(defaultValue);
      setOriginalValue(defaultValue);
      setError("");
    }
  }, [defaultValue, open]);

  const onSubmit = useCallback(async () => {
    const formData = new FormData();

    formData.append("firstname", value.firstName);
    formData.append("middlename", value.middleName);
    formData.append("lastname", value.lastName);

    const result = await submitPersonInfo({ personInfoBody: formData });
    // Handle the successful response or perform any necessary actions
    const { error } = handleApiResponse(result);

    if (error?.data?.error) {
      toast.error(error.data?.error?.message);
      setError(error.data?.error?.message);
      return;
    }

    onClose();
    toast.success("Profile updated!");
  }, [onClose, submitPersonInfo, value]);

  const handleReset = () => {
    setValue(originalValue);
  };

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((prevValues) => ({
        ...prevValues,
        [field]: event.target.value,
      }));
    };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <IconButton sx={commonStyles.dialogCloseButton} onClick={handleClose}>
        <CloseOutlined />
      </IconButton>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          label="First name"
          value={value.firstName}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange("firstName")}
        />
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          label="Middle name (optional)"
          value={value.middleName}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange("middleName")}
        />
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          label="Last name"
          value={value.lastName}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange("lastName")}
        />
        {error && <>{error}</>}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleReset}>Reset</Button>
        <Button variant="contained" onClick={onSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileNameForm;
