import { BackButton, BottomBar, Container, Content, EmptyState, SectionTitle, SideBar, TopBar } from "./components";

const PortalLayout = {
  TopBar,
  BackButton,
  BottomBar,
  SideBar,
  Content,
  Container,
  EmptyState,
  SectionTitle,
}

export default PortalLayout;
