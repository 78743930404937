import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Card, CardContent, Container } from '@mui/material';
import queryString from "query-string";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { authApi } from 'src/main/api';
import { AuthLayout, FormField, LoadingContainer, Typography } from "src/main/components";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { Paths, commonStyles, createStyles, handleApiResponse, joinSx, IntlFormatter } from "src/main/utils";
import * as Yup from "yup";

interface WorkerOnboardProps extends React.PropsWithChildren {

}

interface FormState {
  accessKey: string;
  secret: string;
  confirm: string;
}
const initialFormState: FormState = {
  accessKey: "",
  secret: "",
  confirm: "",
};
const Field = FormField<FormState>;

const validationSchema = Yup.object().shape({
  accessKey: Yup.string().required("Username is required"),
  secret: Yup.string().required("Password is required"),
  confirm: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("secret"), null], "Password doesn't match"),
});

const WorkerOnboard: React.FC<WorkerOnboardProps> = (props) => {
  const location = useLocation()
  const token = useMemo(() => {
    const query = queryString.parse(location.search);
    if (Array.isArray(query.token))
      return query.token[0] ?? "";
    if (typeof query.token === "string")
      return query.token;
    return "";
  }, [location.search]);

  const navigate = useNavigate();
  const [formStates, { onValueChange, consumeAPIError, setError, validate }] = useFormState(initialFormState, validationSchema);
  const [isSuccess, setIsSuccess] = useState(false);
  const result = authApi.useOnboardTokenQuery(token);
  const [onboardSubmit] = authApi.useOnboardSubmitMutation();
  const isInitialising = result.isLoading || result.isFetching;
  const invite = useMemo(() => result.data?.result.model, [result.data]);
  const [runOnboardSubmit, loading, error] = useAsyncTask("onboard/submit");

  const onNavigateLogin = () => {
    navigate(Paths.Auth.Login);
  }

  const onSubmit = useCallback(() => {
    runOnboardSubmit(async () => {
      setError({});
      if (validate()) return;

      const result = await onboardSubmit({
        token,
        accessKey: formStates.input.accessKey,
        secret: formStates.input.secret,
      });

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null)
          return;
        throw new Error(error.data?.error?.message)
      }

      setIsSuccess(true);
      toast.success("Success! You may now login with your credentials.");
    });
  }, [runOnboardSubmit, onboardSubmit, consumeAPIError, formStates.input, token, setError, validate]);

  return (
    <Container sx={joinSx(commonStyles.authContainer, styles.root)} maxWidth="sm">
      <LoadingContainer loading={isInitialising}>
        <Card>
          <AuthLayout.CardBanner />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <Typography formatId="workerOnBoardPage.welcome_to_gig_rewards" variant="h5">
                  Welcome to Task4coins!
                </Typography>
                {!!invite && (
                  <Typography variant="body2" color="textSecondary">
                    {IntlFormatter.getMessage("general.hi", "Hi")} <strong>{invite.name}</strong>, {IntlFormatter.getMessage("workerOnBoardPage._you_invited_join", "you have been invited to join")} <strong>{invite.account}</strong> {IntlFormatter.getMessage("workerOnBoardPage._exclusive_earning_platform", "on this exclusive earning platform.")}
                    {IntlFormatter.getMessage("workerOnBoardPage._create_account_to_start", "Simply create a login account below to get started!")}
                  </Typography>
                )}
              </div>
            </Box>
          </CardContent>
          <CardContent sx={{ p: 4, pt: 2 }}>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              {!isSuccess && (
                <form onSubmit={(event) => event.preventDefault()}>
                  <Field
                    label={IntlFormatter.getMessage("workerOnBoardPage.username", "Username")}
                    name="username"
                    type="string"
                    fieldKey="accessKey"
                    onValueChange={onValueChange}
                    formStates={formStates}
                  />
                  <Field
                    label={IntlFormatter.getMessage("workerOnBoardPage.password", "Password")}
                    name="password"
                    type="password"
                    fieldKey="secret"
                    onValueChange={onValueChange}
                    formStates={formStates}
                  />
                  <Field
                    label={IntlFormatter.getMessage("workerOnBoardPage.confirm_password", "Confirm password")}
                    name="confirm-password"
                    type="password"
                    fieldKey="confirm"
                    onValueChange={onValueChange}
                    formStates={formStates}
                  />
                  {typeof error?.message === "string" && (
                    <Typography color="red">{error.message}</Typography>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={onSubmit}
                    >
                      {IntlFormatter.getMessage("workerOnBoardPage.create_account", "Create Account")}
                    </LoadingButton>
                  </Box>
                </form>
              )}
              {isSuccess && (
                <>
                  <Alert variant="standard" color="success" icon={<CheckCircleOutlineOutlined />}>
                    {IntlFormatter.getMessage("workerOnBoardPage._account_created_start_earning", "Account created! Login now to start earning with")} <strong>{invite?.account}</strong>.
                  </Alert>
                  <Box mt={2} />
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={onNavigateLogin}
                  >
                    {IntlFormatter.getMessage("workerOnBoardPage.login_now", "Login now")} 🚀
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
          <AuthLayout.EnvStatus />
        </Card>
      </LoadingContainer>
    </Container >
  );
};

const styles = createStyles({
  root: {
  },

});

export default WorkerOnboard;
