
import { BuilderProp, require200Status } from "src/main/api/account";
import { Meta, PrivilegeModel, QueryResult } from "src/main/types";

export interface ListPrivilegeResponse {
  entries: PrivilegeModel[];
  meta: Meta;
}

export const listPrivileges = (builder: BuilderProp) => {
  return builder.query<ListPrivilegeResponse, void>({
    providesTags: ["privilege"],
    query: () => ({
      url: "/account/privilege/list",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListPrivilegeResponse>) => {
      return response.result
    }
  })
}