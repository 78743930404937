import React from "react";
import { AppBar, AppBarProps, Box, IconButton, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import { MenuIcon } from "src/assets";

interface TopBarProps extends AppBarProps {
  onOpenSideBar?: () => void;
}

const TopBarRoot = styled(AppBar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    ...(
      theme.palette.mode === 'light'
        ? {
          boxShadow: theme.shadows[3]
        }
        : {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          boxShadow: 'none'
        }
    )
  })
);

const TopBar: React.FC<TopBarProps> = (props) => {
  const { onOpenSideBar } = props;
  return (
    <TopBarRoot
      sx={{
        left: {
          lg: 280
        },
        width: {
          lg: 'calc(100% - 280px)'
        }
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2
        }}
      >
        <IconButton
          onClick={onOpenSideBar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />



      </Toolbar>
    </TopBarRoot>
  );
};

export default TopBar;
