import { Box, BoxProps, Button, styled } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { accountApi } from "src/main/api";
import { CreateTaskRequest } from "src/main/api/account/Task";
import { Page } from "src/main/components";
import { RootState } from "src/main/store";
import { AccountCurrencyModel } from "src/main/types";
import { Paths, SimpleMap } from "src/main/utils";
import * as Yup from "yup";
import { TaskDetail, TaskObjectiveDetail, SubmissionConfig } from "./components";

interface CreateTaskProps extends React.PropsWithChildren {

}

const CreateContextBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 8,
  gap: 16,
  display: "flex",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

const initial_values = {
  title: "",
  description: "",
  notionUrl: "",
  start: null,
  expiry: null,
  publishedAt: null,
  payAmount: "",
  payCurrency: "",
  maxSubmission: null,
  maxAccept: null,
  maxPeriodAccept: null,
  maxPeriodSubmission: null,
  periodType: "",
  isRecurring: false,
  objectives: []
}


const CreateTaskComponent: React.FC<CreateTaskProps> = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createTaskMutation, { isLoading: isSubmitLoading }] = accountApi.useCreateTaskMutation();
  const currency = useSelector<RootState, AccountCurrencyModel | null | undefined>(state => state.auth.currency);

  const [formErrors, setFormErrors] = useState<SimpleMap<string>>({});

  const updatedValues = useMemo(() => {
    let newValues = { ...initial_values };
    let defaultCurrency = currency?.default ?? "PHP";

    newValues.payCurrency = defaultCurrency;
    return newValues;
  }, [currency])


  const onSubmit = useCallback(async (values: FormikValues) => {

    if (values.objectives.length) {
      let err = false;
      values.objectives.forEach((obj, index) => {
        if (obj.error || !obj.instruction) {
          toast.error(obj.error + ` Please update objective ${index + 1}`);
          err = true;
        }
      })

      if (err) {
        return;
      }
    }

    if (values.timeAllowed || values.resubmitTimeAllowed) {
      if (!values.resubmitTimeAllowed) {
        toast.error("Please update resubmit time limit fields.");
        setFormErrors((prev) => ({
          ...prev,
          resubmitTimeAllowed: "required field"
        }))
        return;
      }
      if (!values.timeAllowed) {
        toast.error("Please update time limit fields.");
        setFormErrors((prev) => ({
          ...prev,
          timeAllowed: "required field"
        }))
        return;
      }
    }

    console.log({ values });

    const submitData: CreateTaskRequest = {
      title: values.title,
      description: values.description,
      notionUrl: values.notionUrl,
      objectives: values.objectives,
      payAmount: values.payAmount,
      payCurrency: values.payCurrency,
      maxSubmission: (!values.maxSubmission || values.maxSubmission === 0) ? null : values.maxSubmission,
      maxAccept: (!values.maxAccept || values.maxAccept === 0) ? null : values.maxAccept,
      timeAllowed: values.timeAllowed,
      resubmitTimeAllowed: values.resubmitTimeAllowed,
      maxPeriodAccept: values.maxPeriodAccept,
    }

    if (values.isRecurring) {
      submitData.maxPeriodSubmission = values.maxPeriodSubmission;
      submitData.periodType = values.periodType;
      if (values.periodStartOffset) {
        submitData.periodStartOffset = values.periodStartOffset;
      }
    }

    const result = await createTaskMutation(submitData);
    if ("data" in result) {
      navigate(Paths.Management.TasksDetail.replace(":taskId", result.data.model.id));
    } else {
      toast.error((result.error as any).data?.error?.message)
      throw new Error((result.error as any).data?.error?.message)
    }
    // eslint-disable-next-line
  }, [dispatch, createTaskMutation]);

  return (
    <Page>
      <Formik
        initialValues={updatedValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
          description: Yup.string()
            .required("Description is required")
            .max(250, "Max 250 characters"),
          maxSubmission: Yup.number()
            .positive("Max submission should be positive value")
            .nullable(),
          maxAccept: Yup.number()
            .positive("Max accept should be positive value")
            .nullable(),
          maxPeriodAccept: Yup.number()
            .positive("Max accept should be positive value")
            .nullable(),
          notionUrl: Yup.string(),
          payAmount: Yup.number()
            .required("Amount is required")
            .test("payAmount", "", function (value, context) {
              if ((!value && value !== 0) || value < 0) {
                return this.createError({
                  path: context.path,
                  message: `${context.path} must be a valid amount`,
                });
              }
              return true;
            }),
          payCurrency: Yup.string().required("Currency is required"),
          objectives: Yup.array(),
          maxPeriodSubmission: Yup.number()
            .positive("Max period submission should be positive value")
            .nullable(),
          periodType: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          validateForm,
          setErrors,
        }): JSX.Element => (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Page.TopSection title="New Task">
              <Box>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitLoading}
                >
                  Create
                </Button>
              </Box>
            </Page.TopSection>
            <CreateContextBox>
              <Box flex={1} gap={2}>
                <Box pb={2}>
                  <TaskDetail
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setErrors={setErrors}
                  />
                </Box>
              </Box>
              <Box flex={1} gap={2}>
                <Box pb={2}>
                  <SubmissionConfig
                    setFormErrors={(val: SimpleMap<string>) =>
                      setFormErrors(val)
                    }
                    formErrors={formErrors}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Box>
                <Box>
                  <TaskObjectiveDetail
                    values={values}
                    errors={errors}
                    // setObjectives={(objectives: Partial<TaskObjectiveModel>[]) => setObjectives(objectives)}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </Box>
            </CreateContextBox>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateTaskComponent; 
