import { Box, BoxProps } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { PlaceholderIcon } from "src/assets";
import { createStyles, joinSx } from "src/main/utils";

interface ShareImageProps extends BoxProps {
  aspectRatio?: number;
  imageSx?: SxProps;
  sx?: SxProps;
  maxWidth?: string;
  src?: string
  link?: string
}

const ShareImage: React.FC<ShareImageProps> = (props) => {
  const { aspectRatio = 2, src, link, sx, imageSx, ...rest } = props;
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const height = useMemo(() => (1 / aspectRatio) * 100, [aspectRatio]);

  useEffect(() => {
    if (!src) return;
    const image = new Image();
    image.onload = () => {
      setImageUrl(src);
    };
    image.src = src;
  }, [src]);

  const onClick = () => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <Box onClick={onClick} width="100%" {...rest} sx={joinSx(styles.root, typeof link === "string" ? styles.clickable : undefined, sx)}>
      <Box paddingTop={`${height}%`} width="100%" />
      <Box
        component="img"
        src={imageUrl ?? PlaceholderIcon}
        sx={joinSx(styles.image, imageSx)}
      />
    </Box>
  );
};

const styles = createStyles({
  root: {
    position: "relative",
  },
  image: {
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    borderRadius: 1,
  },
  clickable: {
    cursor: "pointer",
  }
});

export default ShareImage;
