import { createSvgIcon } from '@mui/material/utils';

const AndroidIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
    <path d="M0.833374 15C0.958374 13.5139 1.41337 12.1458 2.19837 10.8958C2.98337 9.64583 4.02837 8.65278 5.33337 7.91667L3.79171 5.25C3.70837 5.125 3.68754 4.99306 3.72921 4.85417C3.77087 4.71528 3.86115 4.61111 4.00004 4.54167C4.11115 4.47222 4.23615 4.45833 4.37504 4.5C4.51393 4.54167 4.62504 4.625 4.70837 4.75L6.25004 7.41667C7.44449 6.91667 8.69449 6.66667 10 6.66667C11.3056 6.66667 12.5556 6.91667 13.75 7.41667L15.2917 4.75C15.375 4.625 15.4862 4.54167 15.625 4.5C15.7639 4.45833 15.8889 4.47222 16 4.54167C16.1389 4.61111 16.2292 4.71528 16.2709 4.85417C16.3125 4.99306 16.2917 5.125 16.2084 5.25L14.6667 7.91667C15.9723 8.65278 17.0173 9.64583 17.8017 10.8958C18.5862 12.1458 19.0412 13.5139 19.1667 15H0.833374ZM5.83337 12.7083C6.12504 12.7083 6.37171 12.6075 6.57337 12.4058C6.77504 12.2042 6.8756 11.9578 6.87504 11.6667C6.87504 11.375 6.77421 11.1283 6.57254 10.9267C6.37087 10.725 6.12449 10.6244 5.83337 10.625C5.54171 10.625 5.29504 10.7258 5.09337 10.9275C4.89171 11.1292 4.79115 11.3756 4.79171 11.6667C4.79171 11.9583 4.89254 12.205 5.09421 12.4067C5.29587 12.6083 5.54226 12.7089 5.83337 12.7083ZM14.1667 12.7083C14.4584 12.7083 14.705 12.6075 14.9067 12.4058C15.1084 12.2042 15.2089 11.9578 15.2084 11.6667C15.2084 11.375 15.1075 11.1283 14.9059 10.9267C14.7042 10.725 14.4578 10.6244 14.1667 10.625C13.875 10.625 13.6284 10.7258 13.4267 10.9275C13.225 11.1292 13.1245 11.3756 13.125 11.6667C13.125 11.9583 13.2259 12.205 13.4275 12.4067C13.6292 12.6083 13.8756 12.7089 14.1667 12.7083Z" fill="currentColor"/>
  </svg>
  ,
  'AndroidIcon'
);

export default AndroidIcon;
