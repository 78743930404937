import { Box, } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { accountApi } from 'src/main/api';
import { EmptyState, LoadingButton, LoadingContainer, Page, WorkerCard } from "src/main/components";
import { WithdrawDetailCard } from "./components";

interface WithdrawDetailPageProps extends React.PropsWithChildren {
}

const WithdrawDetailPage: React.FC<WithdrawDetailPageProps> = () => {
  const { withdrawRequestId } = useParams();

  const [processWithdrawRequest, { isLoading: isProcessLoading }] = accountApi.useProcessWithdrawRequestMutation();
  const result = accountApi.useGetWithdrawRequestQuery({ withdrawRequestId: withdrawRequestId ?? "" });
  const withdrawRequest = useMemo(() => result?.data?.model ?? undefined, [result.data?.model]);

  const onProcess = useCallback(async () => {

    const processedResult = await processWithdrawRequest({
      withdrawRequestId: withdrawRequest!.id
    })

    if ("data" in processedResult) {
      toast.success("Withdraw Request Processing!");
      result.refetch()
    } else {
      toast.error((processedResult.error as any).data?.error?.message)
      throw new Error((processedResult.error as any).data?.error?.message)
    }
  }, [processWithdrawRequest, withdrawRequest, result])

  if (!result.isLoading && !withdrawRequest) return <EmptyState />

  return (
    <Page>
      <Page.TopSection title="Withdraw Request">
        {withdrawRequest?.status === "submitted" && (
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{ flexShrink: 0 }}
            loading={isProcessLoading}
            onClick={() => onProcess()}
          >
            Process
          </LoadingButton>
        )}
      </Page.TopSection>
      <LoadingContainer loading={result.isLoading}>
        <Page.Content>
          <Box flex={1}>
            {!!withdrawRequest && <WithdrawDetailCard request={withdrawRequest!} />}
          </Box>
          <Box flex={1}>
            {!!withdrawRequest?.workerId && <WorkerCard workerId={withdrawRequest.workerId} />}
          </Box>
        </Page.Content>
      </LoadingContainer>
    </Page>
  );
};


export default WithdrawDetailPage; 
