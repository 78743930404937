import { BuilderProp, require200Status } from "src/main/api/account";
import { Meta, PayoutItemModel, QueryResult } from "src/main/types";
import { parseMeta } from "src/main/utils/parseMeta";

export interface MetaRequest {
  meta: Meta;
}

export interface ListPayoutItemResponse {
  entries: PayoutItemModel[];
  meta: Meta;
}

export interface ApprovePayoutItemRequest {
  payoutItemId: string;
}

export interface ProcessPayoutItemRequest {
  payoutItemId: string;
}

export interface CancelPayoutItemRequest {
  payoutItemId: string;
}

export interface GetPayoutItemRequest {
  payoutItemId: string;
}

export interface PayoutItemReResponse {
  model: PayoutItemModel;
}

export const getPayoutItem = (builder: BuilderProp) => {
  return builder.query<PayoutItemReResponse, GetPayoutItemRequest>({
    providesTags: ["payout"],
    query: ({ payoutItemId }) => ({
      url: `/account/payout/item/${payoutItemId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<PayoutItemReResponse>) => {
      return response.result;
    }
  })
}

export const listPayoutItem = (builder: BuilderProp) => {
  return builder.query<ListPayoutItemResponse, MetaRequest>({
    providesTags: ["payout"],
    query: ({ meta }) => ({
      url: `/account/payout/item/list?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListPayoutItemResponse>) => {
      return response.result;
    }
  })
}

export const approvePayoutItem = (builder: BuilderProp) => {
  return builder.mutation<PayoutItemReResponse, ApprovePayoutItemRequest>({
    invalidatesTags: ["payout"],
    query: ({ payoutItemId }) => ({
      url: `/account/payout/item/${payoutItemId}/approve`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<PayoutItemReResponse>) => {
      return response.result;
    }
  })
}

export const processPayoutItem = (builder: BuilderProp) => {
  return builder.mutation<PayoutItemReResponse, ProcessPayoutItemRequest>({
    invalidatesTags: ["payout"],
    query: ({ payoutItemId }) => ({
      url: `/account/payout/item/${payoutItemId}/process`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<PayoutItemReResponse>) => {
      return response.result;
    }
  })
}

export const cancelPayoutItem = (builder: BuilderProp) => {
  return builder.mutation<PayoutItemReResponse, CancelPayoutItemRequest>({
    invalidatesTags: ["payout"],
    query: ({ payoutItemId }) => ({
      url: `/account/payout/item/${payoutItemId}/cancel`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<PayoutItemReResponse>) => {
      return response.result;
    }
  })
}