import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { makeRelativePath, Paths } from "src/main/utils";
import ShareBrand from "./ShareBrand";
import LegacyShareBrand from "./LegacyShareBrand";

interface MainProps extends React.PropsWithChildren {

}
const Main: React.FC<MainProps> = (props) => {
  const p = makeRelativePath(Paths.Affiliate.Index);

  return (
    <Routes>
      <Route path={p(Paths.Affiliate.ShareBrand)} element={<ShareBrand />} />
      <Route path={p(Paths.Affiliate.LegacyShareBrand)} element={<LegacyShareBrand />} />
      <Route path="*" element={<Navigate to={Paths.Portal.Home} replace />} />
    </Routes>
  );
};


export default Main;
