import {
  BoxProps,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import React from "react";
import Info from "src/assets/info";

interface Props extends BoxProps {
  label: string;
  value?: string | number | null | undefined | JSX.Element;
  align?: "right" | "left" | "inherit" | "center" | "justify" | undefined;
  variant?:
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
  labelVariant?:
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
  valueColor?: string;
  tooltip?: React.ReactNode;
}

const TableRowWrap: React.FC<Props> = (props: Props) => {
  const {
    label,
    value,
    align,
    variant = "body1",
    labelVariant = "subtitle2",
    valueColor = "textPrimary",
    children,
    tooltip,
  } = props;

  const valueOrChildren = value ?? children;
  const content =
    typeof valueOrChildren === "string" ? (
      <Typography color={valueColor} variant={variant}>
        {valueOrChildren}
      </Typography>
    ) : (
      valueOrChildren
    );

  return (
    <TableRow>
      <TableCell sx={{ padding: "16px 16px 16px 24px", maxWidth: "200px" }}>
        <Box display="flex" alignItems="center">
          <Typography
            sx={{ fontWeight: 500 }}
            color="textPrimary"
            variant={labelVariant}
          >
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <IconButton size="small">
                  <Info fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          color: valueColor,
          padding: "16px 24px",
          fontWeight: 700,
          overflowY: "scroll",
          height: "54px",
          wordBreak: "break-word",
          "&::-webkit-scrollbar": {
            height: "0.4rem",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aaa",
            borderRadius: 12,
          },
        }}
      >
        {content}
      </TableCell>
    </TableRow>
  );
};

export default TableRowWrap;
