import { put } from "@redux-saga/core/effects";
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";

export function* execQuery<D extends QueryDefinition<any, any, any, any, any>>(action: ThunkAction<QueryActionCreatorResult<D>, any, any, AnyAction>) {
  // @ts-ignore
  const promise = yield put(action);
  const result = yield promise;
  promise.unsubscribe();

  return result.data;
};
