import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Card, CardProps, SxProps, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink, To } from "react-router-dom";
import { DetailCard } from "src/main/components";
import { createStyles, joinSx } from 'src/main/utils';


interface BigLabelCardProps extends CardProps {
  sx?: SxProps;
  value: string;
  label: string;
  routeLabel: string;
  route: To;
}

const BigLabelCard: React.FC<BigLabelCardProps> = (props) => {
  const { sx, value, label, route, routeLabel, ...rest } = props;

  return (
    <Card {...rest} sx={joinSx(styles.root, sx)}>
      <Box sx={styles.labelContent}>
        <Typography fontWeight="700" color="primary" variant="h2">{value}</Typography>
        <Typography color="textSecondary" variant="body2">
          {label}
        </Typography>
      </Box>
      <DetailCard.Divider />
      <Box px={2} py={1}>
        <Button component={RouterLink} to={route} color="primary" endIcon={<ArrowForwardIcon />}>
          <Typography fontWeight="700">{routeLabel}</Typography>
        </Button>
      </Box>
    </Card>
  )
};

const styles = createStyles({
  root: {

  },
  labelContent: {
    display: 'flex',
    flexDirection: 'column',
    px: 4, py: 3,
  },
});

export default BigLabelCard;
