import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { accountApi } from "src/main/api";
import {
  DetailCard,
  FormField,
  LoadingButton,
  Page,
} from "src/main/components";
import { useAsyncTask, useFormState } from "src/main/hooks";
import { handleApiResponse } from "src/main/utils";
import * as Yup from "yup";

interface UserInvitePageProps extends React.PropsWithChildren {}

const initialFormState = {
  name: "",
  email: "",
};

type FormState = typeof initialFormState;

const Field = FormField<FormState>;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email is invalid")
    .required("Email address is required"),
});

const UserInvitePage: React.FC<UserInvitePageProps> = (props) => {
  const [runInviteUser, loading] = useAsyncTask("user/invite");
  const [submitCreateInvite] = accountApi.useCreateInviteMutation();
  const [
    formStates,
    { setError, setInput, onValueChange, consumeAPIError, validate },
  ] = useFormState(initialFormState, validationSchema);

  const onSubmit = useCallback(() => {
    runInviteUser(async () => {
      setError({});
      if (validate()) return;

      const result = await submitCreateInvite({
        email: formStates.input.email,
        name: formStates.input.name,
      });

      const { error } = handleApiResponse(result);

      if (error?.data?.error) {
        if (consumeAPIError(error.data?.error) !== null) return;
        throw new Error(error.data?.error?.message);
      }

      setInput({ ...initialFormState });
      toast.success("Invitation created");
    });
  }, [
    runInviteUser,
    consumeAPIError,
    submitCreateInvite,
    validate,
    setError,
    setInput,
    formStates.input,
  ]);

  return (
    <Page>
      <Page.TopSection title="Invite User">
        <LoadingButton
          loading={loading}
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
          Send Invite
        </LoadingButton>
      </Page.TopSection>
      <Container>
        <Page.Content>
          <Box flex={1}>
            <DetailCard>
              <DetailCard.Header header="Invite user to account" />
              <Divider />
              <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    A invitation email will be sent to the provided email
                    address. The user can then use the link in the email to
                    register. You will be able to assign user privileges after
                    they accepted the invitation.
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    label="Name"
                    required
                    fieldKey="name"
                    formStates={formStates}
                    onValueChange={onValueChange}
                    placeholder="e.g: Tom"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Field
                    label="Email"
                    required
                    fieldKey="email"
                    formStates={formStates}
                    onValueChange={onValueChange}
                    placeholder="e.g: tom@example.com"
                  />
                </Grid>
              </Grid>
            </DetailCard>
          </Box>
        </Page.Content>
      </Container>
    </Page>
  );
};

export default UserInvitePage;
