import { Box } from "@mui/material";
import BigNumber from "bignumber.js";
import React, { useMemo, useState } from "react";
import { accountApi } from "src/main/api";
import { DateDisplay, ListingComp, MonoLabel, Table } from "src/main/components";
import { Meta } from "src/main/types";

interface WalletTxListingProps extends React.PropsWithChildren {
  walletId: string;
}

const walletTxHeader = [
  { value: "Amount" },
  { value: "Description" },
  { value: "Time" },
]

const WalletTxListing: React.FC<WalletTxListingProps> = (props) => {
  const { walletId } = props;
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0, wallet: walletId });
  const result = accountApi.useListWalletTxQuery({ meta });
  const walletTxResult = useMemo(() => result.data ?? undefined, [result.data]);
  const presistMeta = useMemo(() => ({ ...meta, ...result.data?.meta }), [result.data, meta]);

  return (
    <ListingComp
      loading={result.isLoading} title="Wallet Transactions"
      updateList={(newMeta) => { setMeta(newMeta) }}
      meta={presistMeta}
    >
      <Box p={1} />
      <ListingComp.Content>
        <Table>
          <Table.Head headers={walletTxHeader} />
          <Table.Body>
            {walletTxResult?.entries.map((walletTx) => (
              <Table.Row>
                <Table.Cell><MonoLabel>{BigNumber(walletTx.amount).toFixed(2)}</MonoLabel></Table.Cell>
                <Table.Cell>{walletTx.description}</Table.Cell>
                <Table.Cell><DateDisplay format="YYYY-MM-DD HH:mm" value={walletTx.createdAt} /></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ListingComp.Content>
    </ListingComp>
  );
};

export default WalletTxListing;
