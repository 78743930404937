import { createSvgIcon } from '@mui/material/utils';

const Gift = createSvgIcon(
  <svg viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.80007 5.99982C6.80009 5.30487 7.00125 4.62479 7.37927 4.04165C7.75729 3.45851 8.29602 2.99725 8.93042 2.71355C9.56482 2.42984 10.2678 2.33583 10.9544 2.44284C11.6411 2.54985 12.2821 2.85332 12.8001 3.31662C13.3957 2.78274 14.1523 2.46246 14.9502 2.40634C15.7481 2.35021 16.542 2.56143 17.2066 3.00666C17.8711 3.45189 18.3684 4.10577 18.6201 4.86507C18.8717 5.62437 18.8632 6.44585 18.5961 7.19982H20.0001C20.6366 7.19982 21.247 7.45268 21.6971 7.90276C22.1472 8.35285 22.4001 8.9633 22.4001 9.59982C22.4001 10.2363 22.1472 10.8468 21.6971 11.2969C21.247 11.747 20.6366 11.9998 20.0001 11.9998H14.0001V10.7998C14.0001 10.4816 13.8736 10.1763 13.6486 9.95129C13.4236 9.72625 13.1183 9.59982 12.8001 9.59982C12.4818 9.59982 12.1766 9.72625 11.9515 9.95129C11.7265 10.1763 11.6001 10.4816 11.6001 10.7998V11.9998H5.60007C4.96355 11.9998 4.3531 11.747 3.90302 11.2969C3.45293 10.8468 3.20007 10.2363 3.20007 9.59982C3.20007 8.9633 3.45293 8.35285 3.90302 7.90276C4.3531 7.45268 4.96355 7.19982 5.60007 7.19982H7.00407C6.87207 6.82422 6.80007 6.41982 6.80007 5.99982ZM11.6001 7.19982V5.99982C11.6001 5.76248 11.5297 5.53047 11.3978 5.33313C11.266 5.1358 11.0786 4.98199 10.8593 4.89116C10.64 4.80034 10.3987 4.77657 10.166 4.82288C9.93319 4.86918 9.71937 4.98347 9.55155 5.15129C9.38372 5.31911 9.26943 5.53293 9.22313 5.76571C9.17683 5.99849 9.20059 6.23977 9.29142 6.45904C9.38224 6.67831 9.53605 6.86572 9.73339 6.99758C9.93073 7.12944 10.1627 7.19982 10.4001 7.19982H11.6001ZM15.2001 7.19982C15.4374 7.19982 15.6694 7.12944 15.8668 6.99758C16.0641 6.86572 16.2179 6.67831 16.3087 6.45904C16.3996 6.23977 16.4233 5.99849 16.377 5.76571C16.3307 5.53293 16.2164 5.31911 16.0486 5.15129C15.8808 4.98347 15.667 4.86918 15.4342 4.82288C15.2014 4.77657 14.9601 4.80034 14.7409 4.89116C14.5216 4.98199 14.3342 5.1358 14.2023 5.33313C14.0705 5.53047 14.0001 5.76248 14.0001 5.99982V7.19982H15.2001Z" />
    <path d="M11.6 13.2002H4.40002V19.2002C4.40002 19.8367 4.65288 20.4472 5.10297 20.8973C5.55306 21.3473 6.1635 21.6002 6.80002 21.6002H11.6V13.2002ZM14 21.6002H18.8C19.4365 21.6002 20.047 21.3473 20.4971 20.8973C20.9472 20.4472 21.2 19.8367 21.2 19.2002V13.2002H14V21.6002Z" />
  </svg>
  ,
  'Gift'
);

export default Gift;
