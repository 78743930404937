import { Box, Chip, ChipProps } from "@mui/material";
import { Home, AccountCircle, Bolt, LocalShipping, Payment, People, Groups, AccountBox } from "@mui/icons-material";
import { Paths } from "src/main/utils";

interface CustomChipProps extends ChipProps {
  bgColor?: string;
}

const CustomChip: React.FC<CustomChipProps> = (props) => {

  return (
    <Chip
      size="small"
      label={
        <Box sx={{ display: "flex", alignItems: "center", fontSize: 10.5, color: "#fff" }}>{props.label}</Box>
      }
      sx={{
        '&.MuiChip-root': {
          backgroundColor: "transparent",
          borderRadius: "16px",
          // border: `1px solid ${color ?? statusColor}`
        },
        "&.MuiChip-filledDefault": {
          backgroundColor: props.bgColor ?? "transparent",
        },
        ...props.sx
      }}
    />
  )
}

export const SideBarNav = [
  {
    title: "GENERAL",
    items: [
      {
        title: "Overview",
        path: Paths.Overview.Overview,
        icon: <Home />,
      },
      {
        title: "Account",
        // path: Paths.Overview.Account,
        icon: <AccountCircle />,
        chip: <CustomChip label="NEW" bgColor="#10B981" />,
        items: [
          {
            title: "Users",
            path: Paths.Overview.Users,
            icon: <People />
          },
        ]
      },
    ]
  },
  {
    title: "MANAGEMENT",
    items: [
      {
        title: "Workers",
        path: Paths.Management.Workers,
        icon: <Bolt />,
        chip: <CustomChip label="Beta" bgColor="#828DF8" />
      },
      {
        title: "Tasks",
        path: Paths.Management.Tasks,
        icon: <LocalShipping />,
        chip: <CustomChip label="WIP" bgColor="#1F2937" />
      },
      {
        title: "Payouts",
        path: Paths.Management.Payouts,
        icon: <Payment />,
        chip: <CustomChip label="WIP" bgColor="#1F2937" />
      },
    ]
  },
  {
    title: "PLATFORM",
    items: [
      {
        title: "Accounts",
        items: [
          {
            title: "All Accounts",
            path: Paths.Platform.AllAccounts,
          },
          {
            title: "Create Account",
            path: Paths.Platform.CreateAccount,
          }
        ],
        icon: <AccountBox />
      },
      {
        title: "Administrators",
        path: Paths.Platform.Administrators,
        icon: <People />,
      },
      {
        title: "Workers",
        path: Paths.Platform.Workers,
        icon: <Groups />,
      },
    ]
  }
]