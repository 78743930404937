import React from "react";
import { createStyles } from "src/main/utils";
import { Box } from "@mui/material";
import { BgImage } from "src/assets";

interface ContentProps extends React.PropsWithChildren {

}

const Content: React.FC<ContentProps> = (props) => {
  const { children } = props;
  return (
    <Box sx={styles.root} >
      {children}
    </Box>

  );
};

const styles = createStyles({
  root: {
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100%",
  },
});

export default Content;
