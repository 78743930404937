import { createSvgIcon } from '@mui/material/utils';

const CashBill = createSvgIcon(
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20">
    <g>
      <path d="M16.9,8c-0.4-0.9-0.9-1.7-1.6-2.4c-0.7-0.7-1.4-1.2-2.2-1.7c-0.8-0.5-1.7-1.1-2.5-1.9c-0.3-0.3-0.7-0.3-1-0.1
		C7.7,3.4,7,5.4,6.8,7C6.2,6.4,6,5.9,6,5.8C5.9,5.6,5.7,5.4,5.4,5.4c-0.2-0.1-0.5,0-0.7,0.2C3.4,7,2.6,8.9,2.6,10.8
		c0,2,0.8,3.8,2.2,5.2c1.4,1.4,3.3,2.2,5.2,2.2c2,0,3.9-0.8,5.2-2.2c0.7-0.7,1.2-1.5,1.6-2.4c0.4-0.9,0.6-1.9,0.6-2.8
		C17.4,9.9,17.2,8.9,16.9,8z M15.5,13.1c-0.3,0.7-0.7,1.4-1.3,1.9c-1.1,1.1-2.6,1.7-4.2,1.7c-1.6,0-3.1-0.6-4.2-1.7
		c-1.1-1.1-1.7-2.6-1.7-4.2c0-1.3,0.4-2.4,1.1-3.4c0.4,0.5,1,1.1,2,1.6c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.4-0.4,0.4-0.6
		C8.2,6.9,8.6,5,10,3.5c0.8,0.7,1.5,1.2,2.2,1.6c0.7,0.5,1.4,0.9,2,1.5c0.6,0.6,1,1.2,1.3,1.9c0.3,0.7,0.5,1.5,0.4,2.3
		C15.9,11.6,15.8,12.4,15.5,13.1z" fill="currentColor" />
      <path d="M11.5,8.8c-0.5-0.3-1-0.4-1.5-0.4c-0.3,0-0.6,0.2-0.7,0.5l-0.7,2H7.5c-0.4,0-0.8,0.3-0.8,0.8
		c0,0.9,0.3,1.7,1,2.3c0.4,0.4,0.8,0.7,1.4,0.8c0,0,0,0,0,0c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.6-0.1c0.5-0.1,1-0.3,1.4-0.7
		c0.4-0.3,0.7-0.8,0.9-1.3c0.2-0.5,0.3-1,0.2-1.6c-0.1-0.5-0.2-1-0.5-1.5C12.4,9.4,12,9.1,11.5,8.8z M11.6,12.3
		c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0-0.8,0c-0.3-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.3-0.3-0.3-0.5h0.7
		c0.3,0,0.6-0.2,0.7-0.5l0.6-1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.2,0.3,0.5,0.3,0.8
		C11.8,11.8,11.7,12.1,11.6,12.3z" fill="currentColor" />
    </g>
  </svg>

  ,
  'CashBill'
);

export default CashBill;