import { Typography } from "@mui/material";
import React from "react";
import { TaskAttemptModel } from "src/main/types";
import { IntlFormatter } from "src/main/utils";
import { BaseContent } from "../BaseContent";

interface ExpireContentProps extends React.PropsWithChildren {
  attempt?: TaskAttemptModel;
}
const ExpireContent: React.FC<ExpireContentProps> = (props) => {
  const { attempt } = props;

  const getExpireText = () => {
    if (!!attempt?.rejectCount)
      return IntlFormatter.getMessage(
        "expiredContentComp.task_expired_terminated_no_resubmit",
        "This task has expired and has been terminated. There was no re-submission before the expiry date."
      );
    return IntlFormatter.getMessage(
      "expiredContentComp.task_expired_terminated_no_submit",
      "This task has expired and has been terminated. There was no submission before the expiry date."
    );
  };

  return (
    <BaseContent status="error" withMoreTasks={true}>
      <Typography variant="body2">{getExpireText()}</Typography>
    </BaseContent>
  );
};

export default ExpireContent;
