import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Card, CardContent, CardProps, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon, CircleXIcon, ClockIcon } from "src/assets";
import { Typography } from "src/main/components";
import { CurrencyDecimals, Defaults } from "src/main/constants";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slice";
import { WithdrawRequestModel } from "src/main/types";
import { Paths, commonStyles, createStyles, joinSx, parseLogMessage } from "src/main/utils";

interface WithdrawCardProps extends CardProps {
  withdraw: WithdrawRequestModel;
}

const WithdrawCard: React.FC<WithdrawCardProps> = (props) => {
  const { withdraw, sx, ...rest } = props;
  const navigate = useNavigate();
  const { palette } = useTheme();

  const { currency } = useSelector<RootState, AuthState>(state => state.auth);

  const decimals = useMemo(() => {
    return CurrencyDecimals[currency?.default ?? Defaults.WalletCurrency]
    // eslint-disable-next-line
  }, [currency, CurrencyDecimals, Defaults.WalletCurrency])

  const { status } = useMemo(() => {
    const reference = withdraw.id.substring(0, 8);
    const status = withdraw.status;

    return {
      reference,
      status,
    }
  }, [withdraw])

  return (
    <Card onClick={() => navigate(Paths.Portal.WithdrawRequestDetail.replace(":withdrawId", withdraw.id))} sx={joinSx(styles.root, sx)} {...rest}>
      <CardContent sx={commonStyles.cardContent}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box mr={1}>
            {status === "processed" && (
              <CheckCircleIcon color="success" />
            )}
            {status === "submitted" && (
              <ClockIcon color="info" />
            )}
            {status === "cancelled" && (
              <CircleXIcon sx={{ color: palette.neutral?.[500] }} />
            )}
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <Box>
              <Typography formatId={`payoutPage.withdrawal_` + withdraw.status} variant="body2" fontWeight="bold" lineHeight={1.375}>
                {parseLogMessage('withdrawal ' + withdraw.status)}
              </Typography>
              <Box display="flex" flexDirection="row" color="text.secondary">
                <Typography variant="caption" lineHeight={1.375}>
                  {withdraw.createdAt?.format("DD MMM YYYY") ?? "-"}
                </Typography>
                &nbsp;<Typography variant="caption" color="neutral.300" fontWeight={700}>|</Typography>&nbsp;
                <Typography variant="caption" lineHeight={1.375}>
                  {withdraw.createdAt?.format("HH:mm A") ?? ""}
                </Typography>
              </Box>
            </Box>
          </Box >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2">
              {withdraw.amount.toFormat(decimals)} {withdraw.currency}
            </Typography>
            <ChevronRightIcon sx={{ color: 'neutral.500' }} />
          </Box>
        </Box >
      </CardContent >
    </Card >
  );
};

const styles = createStyles({
  root: {
    mb: 2,
    cursor: "pointer"
  },
});

export default WithdrawCard;
