import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, CardProps, IconButton, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DetailCard, EmptyState, MonoLabel, Table } from "src/main/components";
import { TaskModel } from "src/main/types";
import { Paths, truncate } from "src/main/utils";
import { TeamAssignDialog } from "./components";

interface TeamAssignCardProps extends Omit<CardProps, "children"> {
  task: TaskModel;
}

const TEAM_HEADER = [
  { value: "Team" },
  { value: "Description" },
  { value: "" }
]

const TeamAssignCard: React.FC<TeamAssignCardProps> = (props) => {
  const { task, ...rest } = props;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <DetailCard {...rest}>
      <DetailCard.Header
        header="Teams"
        endComp={(
          <Button sx={{cursor: "pointer"}} onClick={() => setOpenDialog(true)} variant="contained">
          Update
        </Button>
        )}
      />
      <DetailCard.Divider />
      {!task?.teams?.length && <EmptyState message="Not assigned to a specific team" />}
      {!!task?.teams?.length &&
        <Table>
          <Table.Head headers={TEAM_HEADER} />
          <Table.Body>
            {task.teams?.map((team) => (
              <Table.Row
                sx={{ '& .MuiTableCell-root': { padding: 1, paddingX: 2 } }}
              >
                <Table.Cell>
                  <Box display="flex" flexDirection="column" >
                    <Typography variant="body1">{team.name}</Typography>
                    <Typography variant="body2"><MonoLabel>{truncate(team.id, 8)}</MonoLabel></Typography>
                  </Box>
                </Table.Cell>
                <Table.Cell>
                  <Typography variant="body1">{team.description}</Typography>
                </Table.Cell>
                <Table.Cell align="right">
                  <IconButton
                    component={RouterLink}
                    to={Paths.Management.TeamDetail.replace(":teamId", team.id)}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>}
      <TeamAssignDialog taskId={task.id} teams={task?.teams ?? []} open={openDialog} onClose={() => setOpenDialog(false)} />
    </DetailCard>
  );
};

export default TeamAssignCard;